/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-21
 * @description Implementation of LocalStorageKeys class.
 * @filename local-storage-keys.ts
 */

/**
 * The keys used for local storage.
 */
export class LocalStorageKeys {
  /**
   * The name of the key to the local storage which stores the Twitter ID.
   *
   * @type {string}
   */
  static KEY_LOCAL_STORAGE_COGNITO_TWITTER_ID_CREDENTIALS: string =
    "5kC22mb9Q3iKPA3GfBDgEQ";

  /**
   * The name of the key to the local storage which stores the LinkedIn ID.
   *
   * @type {string}
   */
  static KEY_LOCAL_STORAGE_COGNITO_LINKEDIN_ID_CREDENTIALS: string =
    "U80C8i2fT060O0GtySJFMA";

  /**
   * The name of the key to the local storage which stores the AWS Cognito logins map.
   *
   * @type {string}
   */
  static readonly KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP: string =
    "jMv4GpYwRjOcEwz4HCaonw";

  /**
   * The name of the key to the local storage which stores the user credentials.
   *
   * @type {string}
   */
  static readonly KEY_LOCAL_STORAGE_USER_ID: string = "zpMaPG7KpUORA1275qILWA";

  /**
   * The name of the key to the local storage which identifies if the user is logged in with username and password.
   *
   * @type {string}
   */
  static readonly KEY_LOCAL_STORAGE_LOGIN_WITH_EMAIL_PASSWORD_IDENTIFIER: string =
    "jXquVszClU2HpqjYrDOdgQ";

  /**
   * The name of the key to the local storage which stores the current GraphQL user.
   *
   * @type {string}
   */
  static readonly KEY_LOCAL_STORAGE_GRAPHQL_USER: string =
    "du371TIzRwWIiLVxacW2tA";

  static readonly KEY_LOCAL_STORAGE_USER: string = "user";
}
