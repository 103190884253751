/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type EventStatusKind = "canceled" | "completed" | "draft" | "ended" | "live" | "started" | "%future added value";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type CreateUpdateEventInput = {
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly externalId?: string | null;
    readonly name?: string | null;
    readonly status?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly url?: string | null;
    readonly end?: string | null;
    readonly start?: string | null;
    readonly summary?: string | null;
    readonly id?: string | null;
    readonly revision?: string | null;
    readonly entities?: ReadonlyArray<string> | null;
    readonly peopleEntities?: ReadonlyArray<CreateEntityPersonInput> | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type CreateEntityPersonInput = {
    readonly entity?: string | null;
    readonly person?: string | null;
};
export type createEventMutationVariables = {
    readonly input: CreateUpdateEventInput;
};
export type createEventMutationResponse = {
    readonly createEvent: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly event: {
            readonly __typename: string;
            readonly id: string;
            readonly url: string | null;
            readonly summary: string | null;
            readonly description: string | null;
            readonly created: unknown | null;
            readonly revision: string | null;
            readonly status: EventStatusKind | null;
            readonly tags: ReadonlyArray<{
                readonly id: string;
                readonly label: string | null;
            }> | null;
            readonly previews: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly id: string;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
        } | null;
    } | null;
};
export type createEventMutation = {
    readonly response: createEventMutationResponse;
    readonly variables: createEventMutationVariables;
};



/*
mutation createEventMutation(
  $input: CreateUpdateEventInput!
) {
  createEvent(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    event {
      __typename
      id
      url
      summary
      description
      created
      revision
      status
      tags {
        id
        label
      }
      previews {
        count
        edges {
          cursor
          preview {
            link
            kind
            mediaAsset {
              id
              files {
                id
                name
                path
              }
              topLevelMediaType {
                kind
                label
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUpdateEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": null,
  "args": null,
  "concreteType": "Tag",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v13/*: any*/)
  ]
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "path",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createEventMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createEvent",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateEventPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "previews",
                "storageKey": null,
                "args": null,
                "concreteType": "PreviewsConnection",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewEdge",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "preview",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Preview",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v18/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaAsset",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaAsset",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v19/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "topLevelMediaType",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TopLevelMediaType",
                                "plural": false,
                                "selections": [
                                  (v18/*: any*/),
                                  (v13/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createEventMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createEvent",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateEventPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "previews",
                "storageKey": null,
                "args": null,
                "concreteType": "PreviewsConnection",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewEdge",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "preview",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Preview",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v18/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaAsset",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaAsset",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v19/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "topLevelMediaType",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TopLevelMediaType",
                                "plural": false,
                                "selections": [
                                  (v18/*: any*/),
                                  (v13/*: any*/),
                                  (v4/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "createEventMutation",
    "id": null,
    "text": "mutation createEventMutation(\n  $input: CreateUpdateEventInput!\n) {\n  createEvent(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    event {\n      __typename\n      id\n      url\n      summary\n      description\n      created\n      revision\n      status\n      tags {\n        id\n        label\n      }\n      previews {\n        count\n        edges {\n          cursor\n          preview {\n            link\n            kind\n            mediaAsset {\n              id\n              files {\n                id\n                name\n                path\n              }\n              topLevelMediaType {\n                kind\n                label\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ed889971008f816fa9be9c403d376dc9';
export default node;
