/**
 * @author Maria Osama
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-06-20 11:41:36
 * @description Implementation of the modal react component
 * @filename modal.tsx
 */
import $ from "jquery";
import React from "react";

const LOGO = require("../../../static/rv_logo.png");

/**
 * The props of the Modal class.
 *
 * @interface
 */
interface ModalProps {
  /**
   * Optional properties of a child control used in composition.
   *
   * @type {Object<string, any>}
   */
  children?: { [index: string]: any };

  /**
   * The name of the css class for the modal - overrides the default.
   *
   * @type {string}
   */
  className?: string;

  /**
   * if modal has to be confirmed dismissing
   *
   * @type {string}
   */
  confirmModal?: string;

  /**
   * the state which defines display the confirm dismiss modal or not
   *
   * @type {boolean}
   */
  showConfirmDismiss?: boolean;

  /**
   * The footer for the modal.
   *
   * @type {React.ReactNode}
   */
  footer?: React.ReactNode;

  /**
   * The ID of the parent div element.
   *
   * @type {string}
   */
  id: string;

  /**
   * A flag which is true if the Modal Header has a Radivision logo.
   *
   * @type {boolean}
   */
  logo?: boolean;

  /**
   * Modal title (in case there is no logo)
   *
   * @type {string}
   */
  title?: any;
}

interface ModalState {
  cleared: boolean;
}
/**
 * A React component that renders modals. The class is a base class which aggregates specific modals.
 *
 * @extends {React.Component<ModalProps>}
 */
export class Modal extends React.Component<ModalProps, ModalState> {
  /**
   * A reference to the modal element.
   *
   * @type {React.RefObject<HTMLButtonElement>}
   */
  modalRef: React.RefObject<HTMLButtonElement>;

  /**
   * Constructor.
   *
   * @param {ModalProps} props The props of the component.
   */
  constructor(props: ModalProps) {
    super(props);
    this.modalRef = React.createRef();
    this.close = this.close.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      cleared: false
    };
  }

  /**
   * Closes the modal
   */
  close() {
    this.modalRef.current.click();
  }
  /**
   *
   * @memberof Modal
   */
  closeModal(e) {
    const form: any = document.querySelector(".modal-body form");
    if (form !== null && form !== undefined) form.reset();
    $(`#${this.props.id}`).modal("hide");
    this.setState({
      cleared: !this.state.cleared
    });
  }
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let modalDialogClassName = "modal-dialog modal-dialog-centered modal-lg";

    if (this.props.className) {
      modalDialogClassName = `${modalDialogClassName} ${this.props.className}`;
    }
    return (
      <div
        className="modal fade"
        id={this.props.id}
        role="dialog"
        aria-hidden="true"
      >
        <div className={modalDialogClassName} role="document">
          <div className="modal-content">
            <div className="modal-header">
              {this.props.logo ? (
                <img loading="lazy"
                  src={LOGO}
                  alt="logo"
                  className="radivisionLogo d-inline-block"
                />
              ) : (
                <p>{this.props.title}</p>
              )}
              <button
                type="button"
                className="close close-lg"
                aria-label="Close"
                ref={this.modalRef}
                onClick={this.props.showConfirmDismiss ? null : this.closeModal}
                data-toggle={this.props.showConfirmDismiss ? "modal" : ""}
                data-target={
                  this.props.showConfirmDismiss
                    ? this.props.confirmModal
                    : undefined
                }
              >
                <span aria-hidden="true">
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 100 40"
                    style={{
                      margin: "auto"
                    }}
                  >
                    <title>close</title>
                    <path d="M2.74,0,36,33.26,69.13.14l2.73,2.73L38.74,36,72,69.26,69.26,72,36,38.74,2.87,71.86.14,69.13,33.26,36,0,2.74Z" />
                  </svg>
                </span>
              </button>
            </div>
            <div className="modal-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
