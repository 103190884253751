import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: any) => ({
  widgetWrapper: {
    display: "flex",
    minHeight: "100%"
  },
  widgetHeader: {
    padding: 15,
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  widgetBody: {
    paddingBottom: 15,
    paddingRight: 15,
    paddingLeft: 15
  },
  noPadding: {
    padding: 0
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden"
  },
  moreButton: {
    margin: -5,
    padding: 0,
    width: 40,
    height: 40,
    "&:hover": {
      color: "rgba(255, 255, 255, 0.35)"
    }
  }
}));
