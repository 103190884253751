/**
 * @author Ahmed Samer Moahmed
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-05-13
 * @description Implementation of the SignInModal react component
 * @filename social-media-icons.ts
 *
 */
import React from "react";

export const FACEBOOK_ICON_SVG = (
    <div className="facebookIcon">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="-50 20 100 25"
        style={{ fill: "white" }}
      >
        <title>facebook</title>
        <path
          className="cls-1"
          d="M22.23,21.56V15.78c0-2.77,1.83-3.43,3.16-3.43h8.09V0H22.35C10,0,7.19,9.14,7.19,15.08v6.48H0V36H7.27V72H21.68V36H32.35l.47-5.66.86-8.76Z"
        />
      </svg>
    </div>
);

export const LINKED_IN_ICON_SVG = (
    <div className="linkedinIcon">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="-10 30 100 25"
        style={{ fill: "white" }}
      >
        <title>linkedin</title>
        <path
          id="LinkedIn"
          d="M72,44.07V70.68H56.57V45.85c0-6.24-2.23-10.5-7.82-10.5A8.45,8.45,0,0,0,40.83,41a10.47,10.47,0,0,0-.51,3.76V70.68H24.88s.21-42.06,0-46.42H40.32v6.58l-.1.15h.1v-.15c2-3.16,5.71-7.67,13.91-7.67C64.39,23.17,72,29.81,72,44.07ZM8.74,1.88C3.45,1.88,0,5.35,0,9.9s3.35,8,8.53,8h.1c5.39,0,8.73-3.57,8.73-8S14,1.88,8.74,1.88ZM.92,70.68H16.35V24.26H.92Z"
        />
      </svg>
    </div>
);

export const TWITTER_ICON_SVG = (
    <div className="twitterIcon">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="-10 10 100 25"
        style={{ fill: "white" }}
      >
        <title>twitter</title>
        <path d="M72,6.83a31.7,31.7,0,0,1-8.47,2.3,14.56,14.56,0,0,0,6.52-8.08A29.93,29.93,0,0,1,60.64,4.6a14.84,14.84,0,0,0-25.58,9.92,15.46,15.46,0,0,0,.43,3.32A41.94,41.94,0,0,1,5,2.65,14.76,14.76,0,0,0,3,10,14.54,14.54,0,0,0,9.56,22.06a14.34,14.34,0,0,1-6.67-1.8v.19a13.73,13.73,0,0,0,1,5.23,14.72,14.72,0,0,0,10.86,9,14.2,14.2,0,0,1-3.91.55,13.51,13.51,0,0,1-2.77-.31A14.89,14.89,0,0,0,21.87,45.05a29.92,29.92,0,0,1-18.32,6.2A35.18,35.18,0,0,1,0,51.06a42.65,42.65,0,0,0,22.65,6.52c23.07,0,37.32-16,41-32.56a39.65,39.65,0,0,0,1-8.78V14.36A30.35,30.35,0,0,0,72,6.83Z" />
      </svg>
    </div>
 
);
