/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-07-01
 * @description Implementation of Tag utilities to be used in the app.
 * @filename suggestion.ts
 */

import { CreateSuggestionInput, DeleteSuggestionInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { SuggestionPageInput } from "../../component-configuration/cms-pages";
import { CREATE_SUGGESTION_GRAPHQL_MUTATION } from "../../relay/mutations/create-suggestion";
import { DELETE_SUGGESTION_GRAPHQL_MUTATION } from "../../relay/mutations/delete-suggestion";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class Tag
 */
export class Suggestion {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ArticlePageState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addSuggestion(
    issueInput: SuggestionPageInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateTagInput
    let createInput: CreateSuggestionInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      issueInput.label === undefined ||
      issueInput.label === null ||
      issueInput.label.length < 1
    ) {
      promise = Promise.reject(new Error("Hy4pNN22gEyM8zxtXlW6mQ"));
    } else {
      // initialize create person graphQl input
      createInput = {
        clientMutationId: getClientMutationId(),
        content:
          issueInput.description !== undefined &&
          issueInput.description !== null &&
          issueInput.description.length > 0
            ? issueInput.description.trim()
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_SUGGESTION_GRAPHQL_MUTATION,
        variables: {
          input: createInput
        }
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createSuggestion.hasErrors === false
          ? Promise.resolve(mutationResults.createSuggestion.suggestion)
          : Promise.reject(mutationResults.createSuggestion.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteSuggestion(
    issueInput: SuggestionPageInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  UpdateTagInput
    let deleteSuggestionInput: DeleteSuggestionInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[deleteSuggestion] provided input ", JSON.stringify(issueInput));
    if (
      issueInput.id === undefined ||
      issueInput.id === null ||
      issueInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("JWKEwzAGjUa0lbE4eei71w"));
    } else {
      // initialize create person graphQl input
      deleteSuggestionInput = {
        clientMutationId: getClientMutationId(),
        id: issueInput.id,
        revision: issueInput.revision
      };

      // initialize mutation configuration
      config = {
        mutation: DELETE_SUGGESTION_GRAPHQL_MUTATION,
        variables: {
          input: deleteSuggestionInput
        }
      };

      // attempt to commit update person mutation
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.deleteSuggestion.hasErrors === false
          ? Promise.resolve(mutationResults.deleteSuggestion.suggestion)
          : Promise.reject(mutationResults.deleteSuggestion.errors);
      });
    }

    return promise;
  }
}
