/**
 * @author Sarah Nouh
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-20-09 5:00
 * @description Implementation of loader.
 * @filename loader.tsx
 */
import React from "react";

// const LOADER_GIF = require("../../../static/Pre-loader-with-bckg.gif");
const LOGO = require("../../../static/rv_logo.png");

/**
 * The props of the loader component.
 *
 * @interface
 */
interface LoaderProps {
  /**
   * Indicates whether the loader should be active or not.
   *
   * @type boolean
   */
  isActive: boolean;

  /**
   *
   *
   * @type {boolen}
   * @memberof LoaderProps
   */
  splash?: boolean;
}

/**
 *  A React component that renders the loader.
 *
 * @export
 * @class Loader
 * @extends {React.Component<LoaderProps>}
 */
export class Loader extends React.Component<LoaderProps> {
  /**
   * Constructor.
   *
   * @param {LoaderProps} props The props of the component.
   */
  constructor(props: LoaderProps) {
    super(props);
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render() {


    return this.props.isActive ? (
      this.props.splash && this.props.splash === true ? (
        <div className="loader" style={{ background: "#2C3E50" }}>
          <div className="overlay" style={{ background: "#2C3E50" }} />

          <div id="breathing-image">
            <img src={LOGO} />
          </div>
        </div>
      ) : (
        <div className="loader">
          <div className="overlay" />
          {/* <div className="spinner">
            <div className="rect1"></div>
            
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div> */}
          {/* <img className="spinner" src={LOADER_GIF} alt="loader" /> */}
        </div>
      )
    ) : null;
  }
}
