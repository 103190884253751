/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type poolGroupsQueryVariables = {};
export type poolGroupsQueryResponse = {
    readonly ListPoolGroups: {
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly hasErrors: boolean | null;
        readonly group: ReadonlyArray<{
            readonly name: string;
            readonly description: string | null;
            readonly created: unknown | null;
            readonly lastModified: unknown | null;
        }> | null;
    } | null;
};
export type poolGroupsQuery = {
    readonly response: poolGroupsQueryResponse;
    readonly variables: poolGroupsQueryVariables;
};



/*
query poolGroupsQuery {
  ListPoolGroups {
    errors {
      id
      location
    }
    hasErrors
    group {
      name
      description
      created
      lastModified
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "ListPoolGroups",
    "storageKey": null,
    "args": null,
    "concreteType": "CognitoGroupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "group",
        "storageKey": null,
        "args": null,
        "concreteType": "CognitoGroup",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastModified",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "poolGroupsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "poolGroupsQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "poolGroupsQuery",
    "id": null,
    "text": "query poolGroupsQuery {\n  ListPoolGroups {\n    errors {\n      id\n      location\n    }\n    hasErrors\n    group {\n      name\n      description\n      created\n      lastModified\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '5314e4a405a497ab2133c10389870f11';
export default node;
