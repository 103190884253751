/**
 * @author Linkon Islam
 * @date   2019-01-14
 * @description mange preview panel
 * @filename ManagePreviewPanel.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import ManagePreviewItem from "./manage-preview-item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../modal/modal";
import ManagePreviewAdd from "./manage-preview-add";
import { PreviewKind, CreatePreviewInput } from "@radivision/graphql";
import { isValidUrl } from "../../../../utilities/general";
import { PreviewItem } from "../../../../component-configuration/cms-pages";

/**
 *
 *
 * @export
 * @interface ManagePreviewPanelProps
 */
export interface ManagePreviewPanelProps {
  /**
   *
   *
   * @type {any[]}
   * @memberof ManagePreviewPanelProps
   */
  previewsList?: PreviewItem[];
}
/**
 *
 *
 * @interface ManagePreviewPanelState
 */
interface ManagePreviewPanelState {
  /**
   *
   *
   * @type {({
   *     id: number;
   *     previewKind: PreviewKind;
   *     previewSource: "link" | "mediaAsset";
   *     previewDetails:string;
   *   }[])}
   * @memberof ManagePreviewPanelState
   */
  previewItems?: {
    /**
     *
     *
     * @type {number}
     */
    id?: number;

    /**
     *
     *
     * @type {PreviewKind}
     */
    previewKind?: PreviewKind;

    /**
     *
     *
     * @type {("link" | "mediaAsset")}
     */
    previewSource?: "link" | "mediaAsset";

    /**
     *
     *
     * @type {string}
     */
    previewDetails?: {
      mediaAsset?: { id: string; file?: string };
      link?: string;
    };
  }[];

  /**
   *
   *
   * @type {boolean}
   * @memberof ManagePreviewPanelState
   */
  actionModal: boolean;
  /**
   *
   *
   * @type {boolean}
   * @memberof ManagePreviewPanelState
   */
  confirmModal: boolean;
  /**
   *
   *
   * @type {boolean}
   * @memberof ManagePreviewPanelState
   */
  addPreviewModal: boolean;
  /**
   *
   *
   * @type {number}
   * @memberof ManagePreviewPanelState
   */
  willDelete: number;
}

/**
 *
 *
 * @export
 * @class ManagePreviewPanel
 * @extends {React.Component<{}, ManagePreviewPanelState>}
 */
export class ManagePreviewPanel extends React.Component<
  ManagePreviewPanelProps,
  ManagePreviewPanelState
> {
  /**
   *
   *
   * @type {*}
   * @memberof ManagePreviewAdd
   */
  mediaModalRef: any;

  /**
   *Creates an instance of ManagePreviewPanel.
   * @param {{}} props
   * @memberof ManagePreviewPanel
   */
  constructor(props: ManagePreviewPanelProps) {
    super(props);

    // ... bind need function to component class

    this.ModalClose = this.ModalClose.bind(this);
    this.extractPreviews = this.extractPreviews.bind(this);
    this.addPreviewItem = this.addPreviewItem.bind(this);
    this.resetComponent = this.resetComponent.bind(this);
    this.addCustomPreviewItem = this.addCustomPreviewItem.bind(this);

    this.mediaModalRef = React.createRef();

    //... initial component state
    this.state = this.handleComponentStateInitialize(this.props);
  }

  /**
   *
   *
   * @param {TagProps} tags
   * @returns {TagState}
   * @memberof Tag
   */
  handleComponentStateInitialize(
    props: ManagePreviewPanelProps
  ): ManagePreviewPanelState {
    let state: ManagePreviewPanelState = {
      previewItems: [],
      actionModal: false,
      confirmModal: false,
      addPreviewModal: false,
      willDelete: 0,
    };

    // ... validate provided tags list
    if (
      props.previewsList !== undefined &&
      props.previewsList !== null &&
      props.previewsList.length > 0
    ) {
      state.previewItems = props.previewsList;
    }
    return state;
  }

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  ModalClose() {
    this.setState({
      actionModal: false,
      confirmModal: false,
      addPreviewModal: false,
    });
  }

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  deleteActionYes = () => {
    const previewItems = this.state.previewItems.filter((item, index) => {
      return index !== this.state.willDelete;
    });

    this.setState({
      previewItems,
      actionModal: false,
      confirmModal: true,
    });
  };

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  deleteAction = (id: number): void => {
    this.setState({
      actionModal: true,
      willDelete: id,
    });
  };

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  openAddPreview = () => {
    this.setState({
      addPreviewModal: true,
    });
  };

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  addPreviewItem(item: any) {
    let previewItems = this.state.previewItems;
    let previewItem: {
      id?: number;
      previewKind?: PreviewKind;
      previewSource?: "link" | "mediaAsset";
      previewDetails?: {
        mediaAsset?: { id: string; file?: string };
        link?: string;
      };
    } = {};

    if (item) {
      if (isValidUrl(item.previewInfo)) {
        previewItem.previewSource = "link";
        previewItem.previewDetails = {
          link: item.previewInfo,
        };
      } else {
        previewItem.previewSource = "mediaAsset";
        previewItem.previewDetails = {
          mediaAsset: {
            id: item.previewInfo,
          },
        };
      }

      previewItem.id = previewItems.length + 1;
      previewItem.previewKind = item.previewKind;

      previewItems.push(previewItem);
    }

    this.setState({
      previewItems: previewItems,
      addPreviewModal: false,
    });
  }

  addCustomPreviewItem(item: any) {
    this.setState({ addPreviewModal: true }, () => {
      this.mediaModalRef.current?.changeState(
        { state: "UPLOAD" },
        item.previewInfo
      );
    });
  }

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  closeModal = () => {
    this.setState({
      addPreviewModal: false,
    });
  };

  /**
   *
   *
   * @returns
   * @memberof ManagePreviewPanel
   */
  render() {
    return (
      <div className="PreviewPanel">
        {this.state.previewItems.length
          ? this.state.previewItems.map((item, index) => {
              return (
                <div className="col-md-2" key={index}>
                  <ManagePreviewItem
                    id={index}
                    previewKind={item.previewKind}
                    previewSource={item.previewSource}
                    deletePreviewItem={this.deleteAction}
                    onItemClickAction={this.handlePreviewClick}
                  />
                </div>
              );
            })
          : null}

        <div className="PreviewPanel--add" onClick={this.openAddPreview}>
          <FontAwesomeIcon icon="plus-circle" />
        </div>

        {this.state.actionModal ? (
          <Modal title="Confirm Delete" show="true" close={this.ModalClose}>
            <label>Do you wish to delete the Preview?</label>
            <div className="action-btn">
              <button
                onClick={this.ModalClose}
                className="btn btn-radivision dark"
              >
                No
              </button>
              <button
                onClick={this.deleteActionYes}
                className="btn btn-radivision dark"
              >
                Yes
              </button>
            </div>
          </Modal>
        ) : (
          <div />
        )}

        {this.state.confirmModal ? (
          <Modal title="Delete Complete" show="true" close={this.ModalClose}>
            <label>Delete is Complete</label>
            <div className="action-btn">
              <button
                onClick={this.ModalClose}
                className="btn btn-radivision dark"
              >
                Ok
              </button>
            </div>
          </Modal>
        ) : (
          <div />
        )}
        {this.state.addPreviewModal ? (
          <Modal title="Add Preview" show="true" close={this.ModalClose}>
            <ManagePreviewAdd
              ref={this.mediaModalRef}
              previewKind={PreviewKind.BACKGROUND}
              previewInfo=""
              addItem={this.addPreviewItem}
              closeModal={this.closeModal}
            />
          </Modal>
        ) : (
          <div />
        )}
      </div>
    );
  }

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  extractPreviews(): CreatePreviewInput[] {
    let previewsInput: CreatePreviewInput[] = [];
    this.state.previewItems.map((item) => {
      let itemData: { kind: any; link?: string; mediaAsset?: string } = {
        kind: item.previewKind,
      };

      switch (item.previewSource) {
        case "link":
          itemData.link = item.previewDetails.link;
          break;

        case "mediaAsset":
          itemData.mediaAsset = item.previewDetails.mediaAsset.id;
          break;
      }
      previewsInput.push(itemData);
    });

    return previewsInput.length > 0 ? previewsInput : undefined;
  }

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  handlePreviewClick = (id: number): Promise<any> => {
    let promise: Promise<any> = Promise.resolve();

    if (id === undefined || id === null) {
      throw new Error("invalid preview id");
    }

    promise = promise.then(() => {
      let previewItems = this.state.previewItems;
      let previewLink: string;

      switch (previewItems[id].previewSource) {
        case "link":
          previewLink = previewItems[id].previewDetails.link;
          break;

        case "mediaAsset":
          previewLink = `${process.env.URL_MEDIA_IMAGE}/${previewItems[id].previewDetails.mediaAsset.file}`;
          break;
      }

      if (previewLink.includes("undefined")) {
        throw new Error("invalid media asset file or link url");
      }

      return Promise.resolve(
        window.open(
          previewLink,
          "_blank",
          `menubar=no,toolbar=no,centerscreen=yes,location=no,status=1,resizable=no,scrollbars=yes,width=1280,height=720`
        )
      );
    });

    return promise;
  };

  /**
   *
   *
   * @memberof ManagePreviewPanel
   */
  resetComponent() {
    let state: ManagePreviewPanelState = this.handleComponentStateInitialize(
      this.props
    );
    this.setState(state);
  }
}
