/**
 * @author Ahmed Samer
 * @date   2020-03-02
 * @description page to list user  group
 * @filename company-report-page.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { Loader } from "../../../page/loader";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { FloatingMenu } from "../floating-button/floating-menu";
import { CSVLink } from "react-csv";
import { Entity } from "@radivision/graphql";

/**
 *
 */
const DOWNLOAD_SVG = require("../../../../../static/download.png");

/**
 *
 */
const DataTable = require("react-data-components").DataTable;

/**
 *
 *
 * @export
 * @interface GroupPageProps
 */
export interface CompanyReportProps {
  /**
   *
   *
   * @type {cognitoUser[]}
   * @memberof UsersReportProps
   */
  companyReport: Entity[];
}

/**
 *
 *
 * @interface GroupPageState
 */
interface CompanyReportState {
  /**
   *
   *
   * @type {any []}
   * @memberof GroupPageState
   */
  items: any[];

  /**
   *
   *
   * @type {boolean}
   * @memberof GroupPageState
   */
  isLoading: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof GroupPageState
   */
  selected?: string;

  /**
   *
   *
   * @type {boolean}
   * @memberof GroupPageState
   */
  addModal: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof GroupPageState
   */
  email?: string;

  /**
   *
   *
   * @type {any[]}
   * @memberof GroupPageState
   */
  headers: any[];
}

/**
 *
 *
 * @export
 * @class GroupPage
 * @extends {React.Component<CompanyReportProps, CompanyReportState>}
 */
export class CompanyReportPage extends React.Component<
  CompanyReportProps,
  CompanyReportState
> {
  /**
   * Creates an instance of AddItemPage.
   * @param {*} props
   * @memberof AddItemPage
   */
  constructor(props: CompanyReportProps) {
    super(props);

    this.closeModalAction = this.closeModalAction.bind(this);
    this.state = this.handleComponentStateInitialize(this.props);
  }

  /**
   *
   *
   * @param {GroupPageProps} props
   * @returns {GroupPageState}
   * @memberof GroupUsersPage
   */
  handleComponentStateInitialize(
    props: CompanyReportProps
  ): CompanyReportState {
    const HEADERS = [
      {
        title: "name",
        prop: "name",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        title: "website",
        prop: "website",
        options: {
          filter: false,
          sort: false
        }
      },
      {
        title: "createDate",
        prop: "createDate",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        title: "landing page",
        prop: "landingUrl",
        options: {
          filter: false,
          sort: false
        }
      }
    ];

    let state: CompanyReportState = {
      headers: HEADERS,
      items: [],
      addModal: false,
      isLoading: false
    };

    if (props.companyReport) {
      if (props.companyReport.length) {
        const registeredItem = props.companyReport.map(item => {
          return {
            id: item.id,
            name: item.name,
            website: item.websiteUrl,
            createDate: item.created,
            landingUrl: item.landingPageUrl
          };
        });

        state.items = [...registeredItem];
      }
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof GroupUsersPage
   */
  render() {
    return (
      <div className="dark-bg">
        <div className="AddItemPage container">
          <div className="row">
            <div className="col-md-10">
              <h1 className="text-center">
                Company Report Page
              </h1>
            </div>
            <div className="col-md-2">
              <CSVLink
                data={this.state.items}
                filename={`${new Date().toISOString}_company_Report.csv`}
                className="btn"
                target="_blank"
              >
                <img src={DOWNLOAD_SVG} />
              </CSVLink>
            </div>
          </div>

          <div className="row">
            <DataTable
              className="table table-bordered"
              buildRowOptions={this.buildRowOptions}
              keys="id"
              columns={this.state.headers}
              initialData={this.state.items}
              initialPageLength={50}
              initialSortBy={{ prop: "createDate", order: "descending" }}
              pageLengthOptions={[50, 500, 1000]}
            />
          </div>
        </div>
        <Loader isActive={this.state.isLoading} />
        <FloatingMenu menuItems={{}} />
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in email state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof GroupUsersPage
   */
  handleEmailChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let email: string = this.state.email;
    let eventValue: string = changeEvent.currentTarget.value;

    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    email = eventValue;
    this.setState({ email });
  }

  /**
   *
   *
   * @memberof GroupUsersPage
   */
  closeModalAction() {
    this.setState({ addModal: false });
  }

  getInitialState = () => {
    return { selected: "" };
  };

  selectRow = (row: any) => {};

  buildRowOptions = (row: any) => {
    return {
      onClick: this.selectRow.bind(this, row),
      className: this.state.selected === row.id ? "active" : null
    };
  };
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class CompanyReportContainer extends React.Component<
  CompanyReportProps,
  CompanyReportState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;

    node = (
      <QueryRenderer
        environment={ENVIRONMENT}
        query={graphql`
          query companyReportPageQuery {
            companyReport {
              errors {
                id
                location
              }
              hasErrors
              companyReport {
                id
                created
                name
                websiteUrl
                landingPageUrl
              }
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            if (props.companyReport.hasErrors) {
              return (
                <div>
                  <p>
                    unexpected error : {JSON.stringify(props.errors)}, contact
                    Radivision Technical team.
                  </p>{" "}
                </div>
              );
            } else if (props.companyReport.companyReport) {
              let pageProps: CompanyReportProps = {
                ...this.props,
                companyReport: props.companyReport.companyReport
              };
              return <CompanyReportPage {...pageProps} />;
            }
          }
          return <Loader isActive={true} />;
        }}
      />
    );

    return node;
  }
}
