/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type QuestionTypeKind = "MUTLIPLE_CHOICE" | "TEXT" | "%future added value";
export type UpdateQuestionnaireInput = {
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly force?: boolean | null;
    readonly id?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly questions?: ReadonlyArray<CreateQuestionInput> | null;
    readonly revision?: string | null;
    readonly subTitle?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly title?: string | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type CreateQuestionInput = {
    readonly choices?: ReadonlyArray<CreateMultipleChoiceQuestionChoiceInput> | null;
    readonly content?: string | null;
    readonly maximumNumberOfChoices?: string | null;
    readonly minimumNumberOfChoices?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly questionType: QuestionTypeKind;
    readonly subTitle?: string | null;
    readonly title?: string | null;
};
export type CreateMultipleChoiceQuestionChoiceInput = {
    readonly acceptsText?: boolean | null;
    readonly entityId?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly personId?: string | null;
    readonly storyId?: string | null;
    readonly subTitle?: string | null;
    readonly title?: string | null;
};
export type updateQuestionnaireMutationVariables = {
    readonly input: UpdateQuestionnaireInput;
};
export type updateQuestionnaireMutationResponse = {
    readonly updateQuestionnaire: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly questionnaire: {
            readonly id: string;
            readonly title: string | null;
        } | null;
    } | null;
};
export type updateQuestionnaireMutation = {
    readonly response: updateQuestionnaireMutationResponse;
    readonly variables: updateQuestionnaireMutationVariables;
};



/*
mutation updateQuestionnaireMutation(
  $input: UpdateQuestionnaireInput!
) {
  updateQuestionnaire(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    questionnaire {
      id
      title
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateQuestionnaireInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateQuestionnaire",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateQuestionnairePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "questionnaire",
        "storageKey": null,
        "args": null,
        "concreteType": "Questionnaire",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateQuestionnaireMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateQuestionnaireMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateQuestionnaireMutation",
    "id": null,
    "text": "mutation updateQuestionnaireMutation(\n  $input: UpdateQuestionnaireInput!\n) {\n  updateQuestionnaire(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    questionnaire {\n      id\n      title\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3d62f0dc627a6fd50b8a89ff332f67c3';
export default node;
