/**
 * @author Linkon Islam
 * @date   2019-01-10
 * @description Tag component
 * @filename tag.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
const ENTER_KEY = 13;
const COMMA_KEY = 188;
const BACKSPACE_KEY = 8;

/**
 *
 *
 * @export
 * @interface TagProps
 */
export interface TagProps {
  /**
   *
   *
   * @type {string}
   * @memberof TagProps
   */
  type?: string;
  /**
   *
   *
   * @type {any[]}
   * @memberof TagProps
   */
  tagsList?: { id: string; text: string }[];

  /**
   *
   *
   * @type {any[]}
   * @memberof TagProps
   */
  suggestionsList?: any[];
}

/**
 *
 *
 * @interface TagState
 */
interface TagState {
  /**
   *
   *
   * @type {any[]}
   * @memberof TagState
   */
  tags: any[];

  /**
   *
   *
   * @type {string}
   * @memberof TagState
   */
  value?: string;
}

/**
 *
 *
 * @export
 * @class Tag
 * @extends {React.Component<{}, TagState>}
 */
export default class Tag extends React.Component<TagProps, TagState> {
  /**
   * Creates an instance of Tag.
   * @param {{}} props
   * @memberof Tag
   */
  constructor(props: TagProps) {
    super(props);

    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    // ... bind need function to component class
    this.extractTagsString = this.extractTagsString.bind(this);
    this.resetComponent = this.resetComponent.bind(this);

    //... initial component state
    this.state = this.handleComponentStateInitialize(props);
  }

  /**
   *
   *
   * @param {TagProps} tags
   * @returns {TagState}
   * @memberof Tag
   */
  handleComponentStateInitialize(tags: TagProps): TagState {
    let state: TagState = {
      tags: []
    };

    // ... validate provided tags list
    if (
      tags.tagsList !== undefined &&
      tags.tagsList !== null &&
      tags.tagsList.length > 0
    ) {
      state.tags = tags.tagsList;
    }
    return state;
  }

  /**
   *
   *
   * @memberof Tag
   */
  handleDelete = (id: any) => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== id)
    });
  };

  /**
   *
   *
   * @param {*} e
   * @memberof Tag
   */
  handleChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  /**
   *
   *
   * @param {*} e
   * @memberof Tag
   */
  handleKeyUp(e) {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) {
      this.addTag();
    }
  }

  /**
   *
   *
   * @param {*} e
   * @memberof Tag
   */
  handleKeyDown(e) {
    const key = e.keyCode;
    if (key === BACKSPACE_KEY && !this.state.value) {
      this.editPrevTag();
    }
  }

  /**
   *
   *
   * @returns
   * @memberof Tag
   */
  addTag() {
    const { tags, value } = this.state;
    let tag = value.trim();

    tag = tag.replace(/,/g, "");

    if (!tag) {
      return;
    }

    this.setState({
      tags: [...tags, { id: "", text: tag }],
      value: ""
    });
  }

  editPrevTag() {
    let { tags } = this.state;

    const tag = tags.pop();

    this.setState({ tags, value: tag.text });
  }

  /**
   *
   *
   * @returns
   * @memberof Tag
   */
  render() {
    const { tags, value } = this.state;
    return (
      <div className="form">
        <div className="tags">
          <ul>
            {tags.map((tag, i) => (
              <li key={tag + i} className="tag">
                {tag.text}
              </li>
            ))}
          </ul>
          <input
            type="text"
            placeholder="Add tag..."
            value={value}
            onChange={this.handleChange}
            ref="tag"
            className="tag-input"
            onKeyUp={this.handleKeyUp}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        <small>
          Press <code>enter</code> to add a tag. Press <code>backspace</code> to
          edit previous tag.
        </small>
      </div>
    );
  }

  /**
   *
   *
   * @returns {{id:string,text:string}[]}
   * @memberof Tag
   */
  extractTagsString(): string[] {
    let tags: string[] = [];

    tags = this.state.tags.map(item => {
      return item.text;
    });
    return tags && tags.length > 0 ? tags : undefined;
  }

  /**
   *
   *
   * @memberof Tag
   */
  resetComponent() {
    let state: TagState = this.handleComponentStateInitialize(this.props);
    this.setState(state);
  }
}
