import * as React from "react";
import { CarouselItem } from "../../../../component-configuration/carousel-item";
import ProgressiveImage from "react-progressive-image";
import { mapTypeToSearch } from "../../../../utilities/search";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";
import { CreateListItemInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-list-item-input";
import ModalConductor from "../modal/modal-conductor";
const cloneDeep = require("lodash.clonedeep");
import isEqual from "lodash.isequal";
import { DEFAULTS } from "../../../../utilities/defaults";

/**
 *
 *
 * @interface ListItemPanelProps
 */
interface ListItemPanelProps {
  /**
   *
   *
   * @type {CarouselItem[]}
   * @memberof ListItemPanelProps
   */
  items?: CarouselItem[];

  /**
   *
   *
   * @type {boolean}
   * @memberof ListItemPanelProps
   */
  readonly: boolean;

  /**
   * search filter for list item panel
   *
   * @type {{ field: string; values: string[] }[]}
   * @memberof ListItemPanelProps
   */
  filter: { field: string; values: string[] }[];
}

/**
 *
 *
 * @interface ManageSearchPreview
 */
interface ListItemPanelState {
  /**
   *
   *
   * @type {boolean}
   * @memberof ListItemPanelState
   */
  viewSearch: boolean;

  /**
   *
   *
   * @type {any[]}
   * @memberof ListItemPanelState
   */
  listItems: CarouselItem[];

  /**
   *
   *
   * @type {boolean}
   * @memberof ListItemPanelState
   */
  isLoading: boolean;

  /**
   *
   *
   * @type {boolean}
   * @memberof ListItemPanelState
   */
  manualAdd: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof ListItemPanelState
   */
  manualId?: string;
}

/**
 *
 *
 * @export
 * @class ListItemPanel
 * @extends {React.Component<{}, ManageSearchPreview>}
 */
export default class ListItemPanel extends React.Component<
  ListItemPanelProps,
  ListItemPanelState
> {
  /**
   * Creates an instance of ListItemPanel.
   * @param {{}} props
   * @memberof ListItemPanel
   */
  constructor(props: ListItemPanelProps) {
    super(props);

    this.ModalClose = this.ModalClose.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.extractListItems = this.extractListItems.bind(this);
    this.extractLists = this.extractLists.bind(this);

    this.state = this.initializeComponentState(this.props);
  }

  /**
   *
   *
   * @memberof ListItemPanel
   */
  ModalClose() {
    this.setState({
      viewSearch: false,
    });
  }

  /**
   *
   *
   * @param {ListItemPanelProps} prevProps
   * @memberof ListItemPanel
   */
  componentDidUpdate(prevProps: ListItemPanelProps) {
    if (!isEqual(this.props, prevProps)) {
      this.setState(this.initializeComponentState(this.props));
    }
  }

  /**
   *
   *
   * @param {ManageMediaPanelProps} props
   * @returns {ManageMediaPanelState}
   * @memberof ManageMediaPanel
   */
  initializeComponentState(props: ListItemPanelProps): ListItemPanelState {
    let state: ListItemPanelState = {
      listItems: [],
      viewSearch: false,
      manualAdd: false,
      isLoading: false,
    };

    if (props !== undefined && props !== null) {
      if (
        props.items !== undefined &&
        props.items !== null &&
        props.items.length > 0
      ) {
        state.listItems = cloneDeep(props.items);
      }
    }
    return state;
  }

  /**
   *
   *
   * @memberof ListItemPanel
   */
  addItemToPanel = (item: any) => {
    let listItems: CarouselItem[] = this.state.listItems;

    // ... validate if suggestion is valid media item
    if (item !== undefined && item !== null) {
      if (item.id) {
        listItems.push(item);
      }
    }

    this.setState({
      listItems,
      viewSearch: false,
    });
  };

  /**
   *
   *
   * @memberof ListItemPanel
   */
  handleClick = () => {
    this.setState({
      viewSearch: true,
    });
  };

  /**
   *
   *
   * @memberof ListItemPanel
   */
  handleLabelChanges = (
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void => {
    const eventValue: string = changeEvent.currentTarget.value;
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    // ... assign value if valid
    this.setState({
      manualId: eventValue,
    });
  };

  /**
   *
   *
   * @memberof ListItemPanel
   */
  handleManualAddClick = () => {
    this.setState({
      manualAdd: true,
    });
  };

  /**
   *
   *
   * @param {*} e
   * @memberof ListItemPanel
   */
  handleRemoveItem(index: any): void {
    let listItems = this.state.listItems;
    let items: CarouselItem[];

    items = listItems.filter((item: CarouselItem, id) => {
      return index !== id;
    });

    listItems = items;
    this.setState({ listItems });
  }

  /**
   *
   *
   * @memberof ListItemPanel
   */
  handleManualAddSaveAction = (): void => {
    let listItems = this.state.listItems;

    listItems.push({
      id: this.state.manualId,
      title: undefined,
      previewImageUrl: DEFAULTS["DEFAULT_Person_SQUARE"],
    });

    this.setState({ listItems });
  };

  /**
   *
   *
   * @returns
   * @memberof ListItemPanel
   */
  render() {
    return (
      <div className="container">
        <div
          className="row six-by-one-grid justify-content-md-center"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {this.state.listItems.length ? (
            this.state.listItems.map((item, index) => {
              return (
                <div
                  className="text-center"
                  key={index}
                  style={{ margin: "10px" }}
                  draggable
                  onDragStart={(e) => this.handleDragStart(e, index)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => this.handleDrop(e, index)}
                >
                  <div className="image-slide">
                    <ProgressiveImage
                      src={
                        item.previewImageUrl &&
                        item.previewImageUrl.requestedResolutionUrl
                          ? item.previewImageUrl.requestedResolutionUrl
                          : ""
                      }
                      placeholder={
                        item.previewImageUrl &&
                        item.previewImageUrl.placeHolderUrl
                          ? item.previewImageUrl.placeHolderUrl
                          : ""
                      }
                    >
                      {(src: any, _loading: any, srcSetData: any) => (
                        <img alt="Slide" className="hero-image" src={src} />
                      )}
                    </ProgressiveImage>
                  </div>

                  <div
                    className="row justify-content-md-center"
                    style={{ cursor: "pointer" }}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      this.handleOnItemClick(e, index);
                    }}
                  >
                    <p> {item.title ? item.title.substr(0, 40) : ""} </p>
                  </div>

                  {!this.props.readonly ? (
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        this.handleRemoveItem(index);
                      }}
                    >
                      <i className="material-icons">Delete</i>
                    </button>
                  ) : null}
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>
        {!this.props.readonly ? (
          <div className="row justify-content-md-center">
            <button
              className="btn btn-info btn-sm mt-3"
              style={{ height: "30px" }}
              onClick={this.handleClick}
            >
              Add
            </button>

            <button
              className="btn btn-outline-info btn-sm mt-3"
              style={{ height: "30px", margin: "10px" }}
              onClick={this.handleManualAddClick}
            >
              Manual Add
            </button>
          </div>
        ) : null}

        {this.state.viewSearch ? (
          <ModalConductor
            currentModal={"SearchModal"}
            SearchModalProps={{
              filters: this.props.filter,
              handleSearchResults: this.addItemToPanel,
            }}
            close={this.ModalClose}
          />
        ) : (
          <div />
        )}

        {this.state.manualAdd ? (
          <div className="Page--Label">
            <div className="Page-Field">
              <hr /> <label>Enter valid item id </label>
              <input
                type="text"
                name="Label"
                value={this.state.manualId}
                className="form-control"
                onChange={this.handleLabelChanges}
              />
              <button
                className="btn btn-danger btn-sm mt-3"
                style={{ height: "30px" }}
                onClick={this.handleManualAddSaveAction}
              >
                Insert item
              </button>
              <hr />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }

  /**
   *
   *
   * @memberof ManageMediaPanel
   */
  resetComponent() {
    let state: ListItemPanelState = this.initializeComponentState(this.props);
    this.setState(state);
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} index
   * @memberof ManageMediaPanel
   */
  handleDrop(e: any, dropIndex: number) {
    let listItems = this.state.listItems;
    const draggedItemId = e.dataTransfer.getData("text");

    if (draggedItemId < listItems.length && dropIndex < listItems.length) {
      const temp = listItems[dropIndex];

      listItems[dropIndex] = listItems[draggedItemId];
      listItems[draggedItemId] = temp;
    }
    this.setState({ listItems });
  }

  /**
   *
   *f
   * @param {*} e
   * @param {*} order
   * @memberof ManageMediaPanel
   */
  handleDragStart(e: any, order: any) {
    e.dataTransfer.setData("text/plain", order);
  }

  /**
   *
   *
   * @memberof ListItemPanel
   */
  handleOnItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    let pageAlias: string;
    let itemType: string;

    event.preventDefault();

    itemType = mapTypeToSearch(this.state.listItems[index].type).toUpperCase();


    console.log(itemType);
    
    if (itemType) {
      pageAlias = CMS_PAGE_CONSTANT[itemType].alias;
      window.open(`/${pageAlias}/${this.state.listItems[index].id}`);
    }
  };

  /**
   *
   *
   * @memberof ListItemPanel
   */
  extractListItems(): CreateListItemInput[] {
    let listItemInput: CreateListItemInput[] = [];
    let itemList = this.state.listItems;

    listItemInput = itemList.map((item) => {
      return {
        itemId: item.id,
      };
    });

    return listItemInput.length > 0 ? listItemInput : undefined;
  }

  /**
   *
   *
   * @memberof ListItemPanel
   */
  extractLists(): string[] {
    let listItemInput: string[] = [];
    let itemList = this.state.listItems;

    listItemInput = itemList.map((item) => {
      return item.id;
    });

    return listItemInput.length > 0 ? listItemInput : undefined;
  }
}
