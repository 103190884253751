/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-21
 * @description Implementation of constants related to CMS
 * @filename constant.ts
 */

export interface CMSPageConstant {
  /**
   *
   *
   * @type {string}
   * @memberof CMSPageConstant
   */
  alias: string;

  message: {
    /**
     *
     *
     * @type {string}
     */
    confirmation: { add: string; update: string };

    /**
     *
     *
     * @type {string}
     */
    error: { add: string; update: string };
  };
}

/**
 *
 *
 * @param {("confirm" | "error")} type
 * @param {string} item
 * @returns {string}
 */
function constructMessage(
  type: "confirm" | "error",
  item: string
): { add: string; update: string } {
  let message: { add: string; update: string };

  switch (type) {
    case "confirm":
      message = {
        add: `Adding ${item} is complete successfully`,
        update: `Updating ${item} is complete successfully`
      };
      break;

    case "error":
      message = {
        add: `Failed to perform operation on ${item}, Please contact Technical Radivision team`,
        update: `Failed to perform operation on ${item}, Please contact Technical Radivision team`
      };
      break;

    default:
  }

  return message;
}

/**
 *
 */
export const CMS_PAGE_CONSTANT: {
  [index: string]: CMSPageConstant;
} = {
  ARTICLE: {
    alias: `cms-article-page`,
    message: {
      confirmation: constructMessage("confirm", "Article"),
      error: constructMessage("error", "Article")
    }
  },
  BOOK: {
    alias: `cms-book-story-page`,
    message: {
      confirmation: constructMessage("confirm", "Book"),
      error: constructMessage("error", "Book")
    }
  },
  COMPONENT: {
    alias: `cms-component-page`,
    message: {
      confirmation: constructMessage("confirm", "Component"),
      error: constructMessage("error", "Component")
    }
  },
  DYNAMIC_LIST: {
    alias: `cms-dynamic-list-page`,
    message: {
      confirmation: constructMessage("confirm", "Dynamic-list"),
      error: constructMessage("error", "Dynamic-list")
    }
  },
  FIXED_LIST: {
    alias: `cms-fixed-list-page`,
    message: {
      confirmation: constructMessage("confirm", "Fixed-list"),
      error: constructMessage("error", "Fixed-list")
    }
  },
  LIST_COLLECTION: {
    alias: `cms-list-collection-page`,
    message: {
      confirmation: constructMessage("confirm", "List-collection"),
      error: constructMessage("error", "List-collection")
    }
  },
  ENTITY: {
    alias: `cms-entity-page`,
    message: {
      confirmation: constructMessage("confirm", "Entity"),
      error: constructMessage("error", "Entity")
    }
  },
  HTML_DOCUMENT: {
    alias: `cms-html-page`,
    message: {
      confirmation: constructMessage("confirm", "Html-document"),
      error: constructMessage("error", "Html-document")
    }
  },
  NEWSLETTER: {
    alias: `cms-newsletter-story-page`,
    message: {
      confirmation: constructMessage("confirm", "Newsletter"),
      error: constructMessage("error", "Newsletter")
    }
  },
  MEDIA_ASSET: {
    alias: `cms-media-asset-page`,
    message: {
      confirmation: constructMessage("confirm", "Media-Asset"),
      error: constructMessage("error", "Media-Asset")
    }
  },
  MEDIA_ASSET_FILE: {
    alias: `cms-media-asset-file-page`,
    message: {
      confirmation: constructMessage("confirm", "Media-Asset-File"),
      error: constructMessage("error", "Media-Asset-File")
    }
  },
  ORIGINAL_CONTENT: {
    alias: `cms-original-content-page`,
    message: {
      confirmation: constructMessage("confirm", "Original-Content"),
      error: constructMessage("error", "Original-Content")
    }
  },
  ONLINE_COURSE: {
    alias: `cms-online-course-story-page`,
    message: {
      confirmation: constructMessage("confirm", "Online-Course"),
      error: constructMessage("error", "Online-Course")
    }
  },
  PERSON: {
    alias: `cms-person-page`,
    message: {
      confirmation: constructMessage("confirm", "Person"),
      error: constructMessage("error", "Person")
    }
  },
  PODCAST: {
    alias: `cms-podcast-story-page`,
    message: {
      confirmation: constructMessage("confirm", "Podcast"),
      error: constructMessage("error", "Podcast")
    }
  },
  POSTER: {
    alias: `cms-poster-page`,
    message: {
      confirmation: constructMessage("confirm", "Poster"),
      error: constructMessage("error", "Poster")
    }
  },
  URL: {
    alias: `cms-url-page`,
    message: {
      confirmation: constructMessage("confirm", "Url-Route"),
      error: constructMessage("error", "Url-Route")
    }
  },
  SUGGEST: {
    alias: `cms-suggestion-page`,
    message: {
      confirmation: constructMessage("confirm", "Suggestion"),
      error: constructMessage("error", "Suggestion")
    }
  },
  SUPPORT: {
    alias: `cms-support-issue-page`,
    message: {
      confirmation: constructMessage("confirm", "Support-Issues"),
      error: constructMessage("error", "Support-Issues")
    }
  },
  TAG: {
    alias: `cms-tag-page`,
    message: {
      confirmation: constructMessage("confirm", "Tag"),
      error: constructMessage("error", "Tag")
    }
  },
  QUESTIONNAIRE: {
    alias: `cms-questionnaire-page`,
    message: {
      confirmation: constructMessage("confirm", "QUESTIONNAIRE"),
      error: constructMessage("error", "QUESTIONNAIRE")
    }
  },
  EVENT: {
    alias: `cms-event-page`,
    message: {
      confirmation: constructMessage("confirm", "EVENT"),
      error: constructMessage("error", "EVENT")
    }
  },
  COGNITO_POOL: { alias: `cms-cognito-pool`, message: undefined },
  COGNITO_POOL_GROUP: { alias: `cms-cognito-pool-groups`, message: undefined },
  COGNITO_POOL_GROUP_USERS: { alias: `cms-cognito-group`, message: undefined },
  COGNITO_POOL_USERS: { alias: `cms-user-report`, message: undefined }
};
