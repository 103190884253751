/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-17
 * @description Implementation of media asset file utilities to be used in the app.
 * @filename media-asset-file.ts
 */
import { CreateMediaAssetFileInput, UpdateMediaAssetFileInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { MediaAssetFilePageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_MEDIA_ASSET_FILE_GRAPHQL_MUTATION } from "../../relay/mutations/create-media-asset-file";
import { UPDATE_MEDIA_ASSET_FILE_GRAPHQL_MUTATION } from "../../relay/mutations/update-media-asset-file";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class MediaAsset
 */
export class MediaAssetFile {
  /**
   * Returns a promise to add new article to database.
   *
   * @param {ArticlePageState} mediaAssetInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addMediaAssetFile(
    mediaAssetInput: MediaAssetFilePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateMediaAssetInput
    let createMediaInput: CreateMediaAssetFileInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      mediaAssetInput.name === undefined ||
      mediaAssetInput.name === null ||
      mediaAssetInput.name.length < 1
    ) {
      promise = Promise.reject(new Error("rMjEhpL8UkSCghe79lDSQg"));
    } else {
      // initialize create article graphQl input
      createMediaInput = {
        clientMutationId: getClientMutationId(),
        description:
          mediaAssetInput.description !== undefined &&
          mediaAssetInput.description !== null &&
          mediaAssetInput.description.length > 0
            ? mediaAssetInput.description.trim()
            : undefined,
        name:
          mediaAssetInput.name !== undefined &&
          mediaAssetInput.name !== null &&
          mediaAssetInput.name.length > 0
            ? mediaAssetInput.name.trim()
            : undefined,
        format:
          mediaAssetInput.format !== undefined &&
          mediaAssetInput.format !== null &&
          mediaAssetInput.format.length > 0
            ? mediaAssetInput.format.trim()
            : undefined,
        path:
          mediaAssetInput.path !== undefined &&
          mediaAssetInput.path !== null &&
          mediaAssetInput.path.length > 0
            ? mediaAssetInput.path.trim()
            : undefined,

        widthInPixels:
          mediaAssetInput.widthInPixels !== undefined &&
          mediaAssetInput.widthInPixels !== null &&
          !isNaN(mediaAssetInput.widthInPixels)
            ? mediaAssetInput.widthInPixels
            : undefined,

        heightInPixels:
          mediaAssetInput.heightInPixels !== undefined &&
          mediaAssetInput.heightInPixels !== null &&
          !isNaN(mediaAssetInput.heightInPixels)
            ? mediaAssetInput.heightInPixels
            : undefined,

        sizeInBytes:
          mediaAssetInput.sizeInBytes !== undefined &&
          mediaAssetInput.sizeInBytes !== null &&
          !isNaN(mediaAssetInput.sizeInBytes)
            ? mediaAssetInput.sizeInBytes
            : undefined,
        tags:
          mediaAssetInput.tags !== undefined &&
          mediaAssetInput.tags !== null &&
          mediaAssetInput.tags.length > 0
            ? mediaAssetInput.tags
            : undefined,

        copies:
          mediaAssetInput.copies !== undefined &&
          mediaAssetInput.copies !== null &&
          mediaAssetInput.copies.length > 0
            ? mediaAssetInput.copies
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_MEDIA_ASSET_FILE_GRAPHQL_MUTATION,
        variables: {
          input: createMediaInput
        }
      };
      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        // console.log(mutationResults);
        return mutationResults.createMediaAssetFile.hasErrors === false
          ? Promise.resolve(mutationResults.createMediaAssetFile.mediaAssetFile)
          : Promise.reject(mutationResults.createMediaAssetFile.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updateMediaAssetFile(
    mediaAssetInput: MediaAssetFilePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let updateMediaAssetInput: UpdateMediaAssetFileInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      mediaAssetInput.id === undefined ||
      mediaAssetInput.id === null ||
      mediaAssetInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("AxUsvXknO0yxvMPvzBBYoQ"));
    } else {
      // initialize create article graphQl input
      updateMediaAssetInput = {
        clientMutationId: getClientMutationId(),
        id: mediaAssetInput.id,
        revision: mediaAssetInput.revision,
        name:
          mediaAssetInput.name !== undefined &&
          mediaAssetInput.name !== null &&
          mediaAssetInput.name.length > 0
            ? mediaAssetInput.name.trim()
            : undefined,
        format:
          mediaAssetInput.format !== undefined &&
          mediaAssetInput.format !== null &&
          mediaAssetInput.format.length > 0
            ? mediaAssetInput.format.trim()
            : undefined,
        tags:
          mediaAssetInput.tags !== undefined &&
          mediaAssetInput.tags !== null &&
          mediaAssetInput.tags.length > 0
            ? mediaAssetInput.tags
            : undefined,

        copies:
          mediaAssetInput.copies !== undefined &&
          mediaAssetInput.copies !== null &&
          mediaAssetInput.copies.length > 0
            ? mediaAssetInput.copies
            : undefined,
        state: undefined
      };

      // console.log("[updateMediaAssetFile] mutation input ", JSON.stringify(updateMediaAssetInput));

      // initialize mutation configuration
      config = {
        mutation: UPDATE_MEDIA_ASSET_FILE_GRAPHQL_MUTATION,
        variables: {
          input: updateMediaAssetInput
        }
      };

      //
      promise = commitMutation(environment, config);

      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateMediaAssetFile.hasErrors === false
          ? Promise.resolve(mutationResults.updateMediaAssetFile.mediaAssetFile)
          : Promise.reject(mutationResults.updateMediaAssetFile.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteMediaAssetFile(
    mediaAssetInput: MediaAssetFilePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
