/**
 * @author Linkon Islam
 * @date   2019-01-10
 * @description Modal class component
 * @filename Modal.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";

/**
 *
 *
 * @interface ModalProps
 */
interface ModalProps {
  /**
   *
   *
   * @type {string}
   * @memberof ModalProps
   */
  title: string;

  /**
   *
   *
   * @type {string}
   * @memberof ModalProps
   */
  show: string;

  /**
   *
   *
   * @type {Function}
   * @memberof ModalProps
   */
  close: Function;
}

/**
 *
 *
 * @interface ModalState
 */
interface ModalState {
  /**
   *
   *
   * @type {string}
   * @memberof ModalState
   */
  show: string;
}

/**
 *
 *
 * @class Modal
 * @extends {React.Component<ModalProps, ModalState>}
 */
class Modal extends React.Component<ModalProps, ModalState> {
  /**
   *Creates an instance of Modal.
   * @param {*} props
   * @memberof Modal
   */
  constructor(props: any) {
    super(props);
    this.state = {
      show: this.props.show
    };

    this.closeModal = this.closeModal.bind(this);

    // // console.log("Modal");
  }

  public closeModal() {
    this.props.close();
  }

  /**
   *
   *
   * @returns
   * @memberof Modal
   */
  render() {
    return (
      <div className="modal_" onClick={this.closeModal}>
        <div className="modal--container" onClick={e => e.stopPropagation()}>
          <div className="modal--header">
            <p>{this.props.title}</p>
            <p className="modal--close" onClick={this.closeModal}>
              x
            </p>
          </div>
          <div className="modal--body">
            <div>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
