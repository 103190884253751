/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-26
 * @description Implementation of the GraphQL mutation to question panel
 * @filename question-panel.tsx
 */

import * as React from "react";
import Tag from "../tag/tag";
import { ManagePreviewPanel } from "../manage-preview/manage-preview-panel";
import QuestionType from "./question-type";

import ModalConductor from "../modal/modal-conductor";
import { FloatingMenu } from "../floating-button/floating-menu";
import { Validator } from "../../../../utilities/cms-operation/validator";
import {
  Questionnaire as graphQuestionnaire,
  CreateQuestionInput,
  GRAPHQL_TYPE_QUESTIONNAIRE,
  PreviewKind
} from "@radivision/graphql";
import { Questionnaire } from "../../../../utilities/cms-operation/questionnaire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "../../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";
import {
  QuestionPageFormInput,
  FormGroupInput,
  PreviewItem
} from "../../../../component-configuration/cms-pages";
import { getNewUniqueId } from "@radivision/common";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";

/**
 *
 *
 * @export
 * @interface QuestionnaireSPageProps
 */
export interface QuestionnaireSPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof MediaAssetPageProps
   */
  string?: string;
  /**
   *
   *
   * @type {string}
   * @memberof MediaAssetPageProps
   */
  Questionnaire?: graphQuestionnaire;
}

/**
 *
 *
 * @interface QuestionState
 */
export interface QuestionnaireSPageState {
  /**
   *
   *
   * @type {{id:string; content:}[]}
   * @memberof QuestionnaireSPageState
   */
  allQuestion: { id: string; content: any }[];

  /**
   *
   *
   * @type {QuestionPageFormInput}
   * @memberof QuestionState
   */
  formInput: QuestionPageFormInput;

  /**
   *
   *
   * @type {FormGroupInput}
   * @memberof QuestionState
   */
  formGroupInput: FormGroupInput;

  /**
   *
   *
   * @type {string []}
   * @memberof QuestionState
   */
  errorMessage: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof ManageHTMLState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof QuestionPanelState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class QuestionPanel
 * @extends {React.Component<{}, QuestionState>}
 */
export class QuestionPanel extends React.Component<
  QuestionnaireSPageProps,
  QuestionnaireSPageState
> {
  /**
   *
   *
   * @type {*}
   * @memberof QuestionPanel
   */
  previewPanelRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof QuestionPanel
   */
  tagsRef: any;
  /**
   *
   *
   * @type {*}
   * @memberof QuestionPanel
   */
  questionsNodes: Map<string, QuestionType>;

  /**
   * Creates an instance of QuestionPanel.
   * @param {QuestionnaireSPageProps} props
   * @memberof QuestionPanel
   */
  constructor(props: QuestionnaireSPageProps) {
    super(props);

    //... bind needed function to class

    this.toggleLoader = this.toggleLoader.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleQuestionnaireInput = this.handleQuestionnaireInput.bind(this);

    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();
    this.questionsNodes = new Map();

    //... initial component state

    this.state = this.handleComponentStateInitialize(this.props.Questionnaire);
  }

  /**
   *
   *
   * @memberof QuestionPanel
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @param {graphQuestionnaire} questionnaireProps
   * @returns {QuestionnaireSPageState}
   * @memberof QuestionPanel
   */
  handleComponentStateInitialize(
    questionnaireProps: graphQuestionnaire
  ): QuestionnaireSPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: QuestionPageFormInput = {
      id: undefined,
      label: "",
      title: "",
      subTitle: "",
      description: "",
      isDeleted: false
    };

    let state: QuestionnaireSPageState = {
      modal,
      formInput,
      isLoading: false,
      errorMessage: new Map(),
      allQuestion: [],
      formGroupInput: {}
    };

    if (questionnaireProps !== null && questionnaireProps !== undefined) {
      // validate provided label

      if (questionnaireProps.id) {
        state.formInput.id = questionnaireProps.id;
      }

      if (questionnaireProps.revision) {
        state.formInput.revision = questionnaireProps.revision;
      }

      if (questionnaireProps.title) {
        state.formInput.title = questionnaireProps.title;
      }

      if (questionnaireProps.subTitle) {
        state.formInput.subTitle = questionnaireProps.subTitle;
      }

      if (questionnaireProps.description) {
        state.formInput.description = questionnaireProps.description;
      }

      if (questionnaireProps.isDeleted) {
        state.formInput.isDeleted = questionnaireProps.isDeleted;
      }

      //........................
      //initialize form group inputs
      //........................

      if (
        questionnaireProps.tags !== null &&
        questionnaireProps.tags !== undefined &&
        questionnaireProps.tags.length > 0
      ) {
        let tagsList: {
          id: string;
          text: string;
        }[] = questionnaireProps.tags.map(tag => {
          return { id: tag.id, text: tag.label };
        });
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        questionnaireProps.previews !== null &&
        questionnaireProps.previews !== undefined &&
        questionnaireProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = questionnaireProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`
                  }
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }

      if (
        questionnaireProps.questions !== null &&
        questionnaireProps.questions !== undefined &&
        questionnaireProps.questions.length > 0
      ) {
        questionnaireProps.questions.map(question => {
          let nodeId = getNewUniqueId();
          const questionType = (
            <QuestionType
              ref={node => this.questionsNodes.set(nodeId, node)}
              input={question}
              key={nodeId}
              QID={nodeId}
              removeClick={this.removeQuestion}
            />
          );

          state.allQuestion.push({ id: nodeId, content: questionType });
        });
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof QuestionPanel
   */
  removeQuestion = (id: string) => {
    // console.log("question remove id: ", id);
    let allQuestion = this.state.allQuestion;

    let filterArray = allQuestion.filter((question, index) => {
      return question.id !== id;
    });

    this.questionsNodes.delete(id);

    this.setState({
      allQuestion: filterArray
    });
  };

  /**
   *
   *
   * @memberof QuestionPanel
   */
  addMore = () => {
    let allQuestion = this.state.allQuestion;
    let nodeId = getNewUniqueId();

    const questionType = (
      <QuestionType
        ref={node => this.questionsNodes.set(nodeId, node)}
        key={nodeId}
        QID={nodeId}
        removeClick={this.removeQuestion}
      />
    );

    allQuestion.push({ id: nodeId, content: questionType });

    this.setState({
      allQuestion
    });
  };

  /**
   *
   *
   * @memberof QuestionPanel
   */
  handleChange = (event: any) => {
    // console.log("here");
    // console.log("in main", event.target.value);

    if (event.target.value === "text_question") {
      // console.log(event.target.value);
    }
    if (event.target.value === "multiple_choice") {
    }
  };

  /**
   *
   *
   * @returns
   * @memberof QuestionPanel
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Manage Questionnaire</h2>
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    name="Subtitle"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.formInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Summary</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.description}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <label>Tag Panel</label>
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <label>Preview Panel</label>
              <ManagePreviewPanel
                previewsList={this.state.formGroupInput.previewsProps}
                ref={this.previewPanelRef}
              />
            </div>
            {this.state.allQuestion.length > 0 ? (
              this.state.allQuestion.map((question, index) => {
                return (
                  <div
                    style={{ border: "1px solid", margin: "10px" }}
                    key={index}
                  >
                    {question.content}
                  </div>
                );
              })
            ) : (
              <p>click to add question</p>
            )}
            <a className="btn add-btn" onClick={this.addMore}>
              <FontAwesomeIcon icon="plus-circle" />
            </a>
          </div>
          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
          <Loader isActive={this.state.isLoading} />
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof QuestionPanel
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: QuestionPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.title = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof QuestionPanel
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: QuestionPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.subTitle = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof QuestionPanel
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: QuestionPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.description = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {*} event
   * @memberof QuestionPanel
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: QuestionPageFormInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    // // console.log("[handleIsDeletedChanges] is article deleted", isDeleted);
    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @memberof QuestionPanel
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof QuestionPanel
   */
  handleSaveAction() {
    let questionnaireInput: QuestionnaireSPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      questionnaireInput.formInput.tags = this.tagsRef.current.extractTagsString();
      // extract questionnaire input
      questionnaireInput.formInput.questions = this.handleQuestionnaireInput();
      // extract previews list
      questionnaireInput.formInput.previews = this.previewPanelRef.current.extractPreviews();

      // validate input errors
      errorMessage = Validator.validateQuestionnaireInput(
        questionnaireInput.formInput
      );

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (this.props.string !== null && this.props.string !== undefined) {
          internalPromise = Questionnaire.updateQuestionnaire(
            questionnaireInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["QUESTIONNAIRE"].message.confirmation.update;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Questionnaire.addQuestionnaire(
            questionnaireInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["QUESTIONNAIRE"].message.confirmation.add;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["QUESTIONNAIRE"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });

    return promise;
  }

  /**
   *
   *
   * @memberof QuestionPanel
   */
  revertPageInput(input?: graphQuestionnaire): void {
    let state: QuestionnaireSPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.Questionnaire
    );
    this.setState(state);

    //... reset children state
    this.tagsRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.questionsNodes.clear();
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof QuestionPanel
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }

  /**
   *
   *
   * @returns {CreateQuestionInput}
   * @memberof QuestionPanel
   */
  handleQuestionnaireInput(): CreateQuestionInput[] {
    let questions: CreateQuestionInput[] = [];

    // extract question inputs

    for (const propertyRef of this.questionsNodes.values()) {
      if (propertyRef) {
        questions.push(propertyRef.extractQuestionInput());
      }
    }

    return questions.length > 0 ? questions : undefined;
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class QuestionnairePageContainer extends React.Component<
  QuestionnaireSPageProps,
  QuestionnaireSPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let questionnaireId: string;

    // console.log("[QuestionnairePageContainer] provided props", this.props);

    if (this.props.string === null || this.props.string === undefined) {
      node = <QuestionPanel />;
    } else {
      questionnaireId = this.props.string;
      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query questionPanelQuery($id: ID!) {
              questionnaire(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                questionnaire {
                  __typename
                  id
                  revision
                  description
                  title
                  subTitle
                  tags {
                    id
                    label
                  }
                  questions {
                    __typename
                    ... on MultipleChoiceQuestion {
                      id
                      title
                      subTitle
                      maximumNumberOfChoices
                      minimumNumberOfChoices
                      choices {
                        id
                        title
                        subTitle
                        acceptsText
                        person {
                          __typename
                          id
                        }
                        previews {
                          count
                          edges {
                            cursor
                            preview {
                              mediaAsset {
                                files {
                                  id
                                  name
                                  path
                                }
                                topLevelMediaType {
                                  kind
                                  label
                                }
                              }
                              kind
                            }
                          }
                        }
                      }
                      previews {
                        count
                        edges {
                          cursor
                          preview {
                            mediaAsset {
                              files {
                                id
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                                label
                              }
                            }
                            kind
                          }
                        }
                      }
                    }
                    ... on TextQuestion {
                      content
                      id
                      title
                      subTitle
                      previews {
                        count
                        edges {
                          cursor
                          preview {
                            mediaAsset {
                              files {
                                id
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                                label
                              }
                            }
                            kind
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          variables={{
            id: questionnaireId
          }}
          render={({ error, props }) => {
            if (error) {
              // console.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console.log(props);
              if (props.questionnaire.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.questionnaire.questionnaire.__typename !==
                GRAPHQL_TYPE_QUESTIONNAIRE
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid questionnaire id, {questionnaireId} belongs to
                      type {props.questionnaire.questionnaire.__typename}, Need
                      more help? contact Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.questionnaire.questionnaire) {
                let pageProps: QuestionnaireSPageProps = {
                  ...this.props,
                  Questionnaire: props.questionnaire.questionnaire
                };
                return <QuestionPanel {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
