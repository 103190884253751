/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-05
 * @description Implementation of the GraphQL mutation to update original content story.
 * @filename update-online-course-story.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update original content
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_ONLINE_COURSE_STORY_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateOnlineCourseStoryMutation(
    $input: UpdateOnlineCourseStoryInput!
  ) {
    updateOnlineCourseStory(input: $input) {
      hasErrors
      errors {
        id
        location
      }
      story {
        __typename
        ... on OnlineCourseStory {
          id
          publishedDate
          timeRequiredInSeconds
          publisher
          description
          created
          label
          isDeleted
          isNew
          revision
          tags {
            id
            label
          }
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  id
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                  }
                }
              }
            }
          }
          subTitle
          title
        }
      }
    }
  }
`;
