/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-07-01
 * @description Implementation of Tag utilities to be used in the app.
 * @filename support-issues.ts
 */

import { CreateSupportInput, DeleteSupportIssuesInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { SupportIssuesPageInput } from "../../component-configuration/cms-pages";
import { CREATE_SUPPORT_ISSUE_GRAPHQL_MUTATION } from "../../relay/mutations/create-support-issue";
import { DELETE_SUPPORT_ISSUE_GRAPHQL_MUTATION } from "../../relay/mutations/delete-support-issue";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class Tag
 */
export class SupportIssue {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ArticlePageState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addSupportIssue(
    issueInput: SupportIssuesPageInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateTagInput
    let createInput: CreateSupportInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      issueInput.label === undefined ||
      issueInput.label === null ||
      issueInput.label.length < 1
    ) {
      promise = Promise.reject(new Error("lKCd0D0fO0Gw5I4VKCHsHA"));
    } else {
      // initialize create person graphQl input
      createInput = {
        clientMutationId: getClientMutationId(),
        description:
          issueInput.description !== undefined &&
          issueInput.description !== null &&
          issueInput.description.length > 0
            ? issueInput.description.trim()
            : undefined,
        browserAndDeviceType:
          issueInput.browserAndDeviceType !== undefined &&
          issueInput.browserAndDeviceType !== null &&
          issueInput.description.length > 0
            ? issueInput.description.trim()
            : undefined,
        kind:
          issueInput.type !== undefined && issueInput.type !== null
            ? issueInput.type
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_SUPPORT_ISSUE_GRAPHQL_MUTATION,
        variables: {
          input: createInput
        }
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createSupportIssue.hasErrors === false
          ? Promise.resolve(mutationResults.createSupportIssue.supportIssue)
          : Promise.reject(mutationResults.createSupportIssue.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteIssue(
    issueInput: SupportIssuesPageInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  UpdateTagInput
    let deleteIssueInput: DeleteSupportIssuesInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[deleteIssue] provided input ", JSON.stringify(issueInput));
    if (
      issueInput.id === undefined ||
      issueInput.id === null ||
      issueInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("sNkBKEsQxUSfNzAlsN8lcg"));
    } else {
      // initialize create person graphQl input
      deleteIssueInput = {
        clientMutationId: getClientMutationId(),
        id: issueInput.id,
        revision: issueInput.revision
      };

      // initialize mutation configuration
      config = {
        mutation: DELETE_SUPPORT_ISSUE_GRAPHQL_MUTATION,
        variables: {
          input: deleteIssueInput
        }
      };

      // attempt to commit update person mutation
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.deleteSupportIssue.hasErrors === false
          ? Promise.resolve(mutationResults.deleteSupportIssue.supportIssue)
          : Promise.reject(mutationResults.deleteSupportIssue.errors);
      });
    }

    return promise;
  }
}
