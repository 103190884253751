/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-27
 * @description Implementation of the GraphQL mutation to update html document.
 * @filename update-html-document-template.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update html document.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_HTML_DOCUMENT_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateHtmlDocumentMutation($input: UpdateHtmlDocumentInput!) {
    updateHtmlDocument(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      htmlDocument {
        __typename
        id
        label
        description
        panels {
          id
          component {
            id
            key
          }
        }
      }
    }
  }
`;
