import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
const user = require("../../../../static/user_icon.png");

/**
 *
 *
 * @interface PublisherDetails
 */
export interface PublisherDetailsProps {
  /**
   *
   *
   * @type {*}
   * @memberof PublisherDetailsProps
   */
  publisher?: { name: string; type?: string };

  /**
   *
   *
   * @type {{haveError:boolean , message:string}}
   * @memberof PublisherDetailsProps
   */
  error: { haveError: boolean; message: string };
}

/**
 *
 *
 * @interface IPublisherDetails
 */
interface PublisherDetailsState {
  /**
   *
   *
   * @type {string}
   * @memberof PublisherDetailsState
   */
  publisherName: string;

  /**
   *
   *
   * @type {string}
   * @memberof PublisherDetailsState
   */
  publisherImage?: string;

  /**
   *
   *
   * @type {string}
   * @memberof PublisherDetailsState
   */
  publisherLink?: string;

  /**
   *
   *
   * @type {string}
   * @memberof PublisherDetailsState
   */
  type?: string;
}

/**
 *
 *
 * @export
 * @class PublisherDetails
 * @extends {React.Component<{}, IPublisherDetails>}
 */
export default class PublisherDetails extends React.Component<
  PublisherDetailsProps,
  PublisherDetailsState
> {
  /**
   *Creates an instance of PublisherDetails.
   * @param {{}} props
   * @memberof PublisherDetails
   */
  constructor(props: PublisherDetailsProps) {
    super(props);

    this.state = this.initializeComponentState(this.props);

    //... bind needed function to class

    this.extractPublisherDetails = this.extractPublisherDetails.bind(this);
    this.addCustomPublisher = this.addCustomPublisher.bind(this);
  }

  initializeComponentState(
    props: PublisherDetailsProps
  ): PublisherDetailsState {
    let state: PublisherDetailsState = {
      publisherImage: "",
      publisherLink: "",
      publisherName: "",
      type: "",
    };

    // .. validate provided props
    if (props !== null && props !== undefined) {
      if (props.publisher && props.publisher.name) {
        state.publisherName = props.publisher.name;
      }

      if (props.publisher && props.publisher.type) {
        state.type = props.publisher.type;
      }
    }

    return state;
  }

  addCustomPublisher(name: string) {
    const state: PublisherDetailsState = this.state;
    if (name) {
      state.publisherName = name;
    }
    this.setState(state);
  }

  /**
   *
   *
   * @memberof PublisherDetails
   */
  deleteAuthor = () => {
    this.setState({
      publisherImage: "",
      publisherLink: "",
      publisherName: "",
    });
  };

  /**
   *
   *
   * @memberof PublisherDetails
   */
  handleChange = (e: any) => {
    // // console.log(e.target.value);
    this.setState({
      publisherLink: "",
      publisherName: $("#publisherName")
        .val()
        .toString(),
    });
  };

  /**
   *
   *
   * @returns
   * @memberof PublisherDetails
   */
  render() {
    return (
      <div className="publisher-view ">
        <div className="">
          <a
            className="waves-effect waves-light"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.deleteAuthor();
            }}
          >
            <FontAwesomeIcon icon="times-circle" />
          </a>
        </div>
        <div>
          <img className="publisher-image" src={user} alt="NoImage" />
        </div>

        <div className="publisher-input">
          <input
            type="text"
            id="publisherName"
            className={`form-control ${
              this.props.error.haveError ? "is-invalid" : ""
            }`}
            value={this.state.publisherName}
            onChange={this.handleChange}
          />
          <span className="invalid-feedback">{this.props.error.message}</span>
        </div>

        <div className="publisher-link">
          <a href={this.state.publisherLink || "#"} target="_blank">
            <FontAwesomeIcon icon="link" />
          </a>
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @returns {{
   *
   *   }}
   * @memberof PublisherDetails
   */
  extractPublisherDetails(): string {
    return this.state.publisherName;
  }

  /**
   *
   *
   * @memberof PublisherDetails
   */
  resetComponent() {
    this.deleteAuthor();
  }
}
