/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-08-05
 * @description Implementation of the GraphQL mutation to add an book story.
 * @filename update-list-collection.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an list-collection.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_LIST_COLLECTION_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateListCollectionMutation($input: UpdateListCollectionInput!) {
    updateListCollection(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      listCollection {
        description
        id
        label
        lists {
          __typename
          ... on FixedList {
            description
            id
            items {
              pageInfo {
                hasNextPage
              }
              edges {
                listItem {
                  item {
                    __typename
                    ... on Entity {
                      id
                      name
                      landingPageUrl
                      previews(kinds: [SQUARE, LOGO]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                    }
                    ... on Person {
                      id
                      fullName
                      landingPageUrl
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      responsibility
                    }
                    ... on ArticleStory {
                      link
                      id
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }

                    ... on BookStory {
                      link
                      id
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                    ... on NewsletterStory {
                      link
                      id
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                    ... on PodcastStory {
                      link
                      id
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      publisher
                      description
                      title
                    }
                    ... on OriginalContentStory {
                      id
                      landingPageUrl
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                    ... on OnlineCourseStory {
                      link
                      id
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                  }
                }
              }
            }
            label
            name
            previews {
              edges {
                preview {
                  link
                  mediaAsset {
                    id
                    files {
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                    }
                  }
                }
              }
            }
            subTitle
            title
            revision
          }
          ... on DynamicList {
            description
            id
            label
            name
            items {
              edges {
                listItem {
                  item {
                    __typename
                    ... on SearchHit {
                      id
                      kind
                      label
                      linkUrl
                      name
                      previewUrl
                      landingPageUrl
                      publisher
                      responsibility
                      subTitle
                      title
                    }
                  }
                }
              }
            }
            previews {
              edges {
                preview {
                  link
                  mediaAsset {
                    id
                    files {
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                    }
                  }
                  kind
                }
              }
            }
            subTitle
            title
            revision
          }
        }
        name
        subTitle
        title
        revision
      }
    }
  }
`;
