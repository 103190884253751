/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type ComponentPropertyKind = "ARTICLE_STORY" | "BOOK_STORY" | "BOOLEAN" | "DYNAMIC_LIST" | "ENTITY" | "FIXED_LIST" | "LIST" | "NEWSLETTER_STORY" | "NUMBER" | "ONLINE_COURSE" | "ORIGINAL_CONTENT" | "PERSON" | "PODCAST" | "QUERY_PARAMETER" | "STORY" | "STRING" | "USER" | "%future added value";
declare const _dynamicComponent_component$ref: unique symbol;
export type dynamicComponent_component$ref = typeof _dynamicComponent_component$ref;
export type dynamicComponent_component = {
    readonly key: string | null;
    readonly properties: ReadonlyArray<{
        readonly kind: ComponentPropertyKind | null;
        readonly isRequired: boolean | null;
        readonly isQueryParameterName: boolean | null;
        readonly name: string | null;
    } | null> | null;
    readonly " $refType": dynamicComponent_component$ref;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "dynamicComponent_component",
  "type": "Component",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "key",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "properties",
      "storageKey": null,
      "args": null,
      "concreteType": "ComponentProperty",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "kind",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isRequired",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isQueryParameterName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'f20883bf8f8262764b92fa86992a4c0a';
export default node;
