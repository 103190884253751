/**
 * @author Linkon Islam
 * @date   2019-01-08
 * @description single item component for add item page
 * @filename SingleItem.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * the properties of SingleItem component
 *
 * @interface SingleItemProps
 */
interface SingleItemProps {
  /**
   * String representing title of item
   *
   * @type {string}
   * @memberof SingleItemProps
   */
  title: string;
  /**
   * Font-Awesome representing icon of item
   *
   * @type {*}
   * @memberof SingleItemProps
   */
  icon: any;
  /**
   * String representing title of item
   *
   * @type {string}
   * @memberof SingleItemProps
   */
  pageAlias: string;
}

/**
 *
 *
 * @class SingleItem
 * @extends {React.Component<SingleItemProps, any>}
 */
class SingleItem extends React.Component<SingleItemProps, any> {
  /**
   * Creates an instance of SingleItem.
   * @param {SingleItemProps} props
   * @memberof SingleItem
   */
  constructor(props: SingleItemProps) {
    super(props);
  }

  /**
   *
   *
   * @returns
   * @memberof SingleItem
   */
  render() {
    return (
      <div className="SingleItem">
        <a href={`/${this.props.pageAlias}`}>
          <div className="SingleItem--icon">
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
          <div className="SingleItem--text">
            <h4>{this.props.title}</h4>
          </div>
        </a>
      </div>
    );
  }
}

export default SingleItem;
