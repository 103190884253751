/**
 * @author Ahmed Samer
 * @date   2019-08-18
 * @description page to list user pool group
 * @filename floating-menu.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import FloatingMenuItem from "./floating-menu-item";
import { FloatingMenuItemInput } from "../../../../component-configuration/floating-menu";
import { Navigator } from "../../../../utilities/cms-operation/navigation";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

/**
 *
 *
 * @interface FloatingMenuProps
 */
interface FloatingMenuProps {
  menuItems: {
    /**
     *
     *
     * @type {boolean}
     */
    initialPage?: { removeAction: Function };
    /**
     *
     *
     * @type {Function}
     */
    cloneable?: Function;
    /**
     *
     *
     * @type {{ deleteAction: Function; unDeleteAction: Function }}
     */
    deleteAble?: { deleteAction: Function; unDeleteAction: Function };
    /**
     *
     *
     * @type {Function}
     */
    saveable?: Function;
    /**
     *
     *
     * @type {Function}
     */
    revertible?: Function;

    /**
     *
     *
     * @type {Function}
     */
    backAction?: Function;

    /**
     *
     *
     * @type {{ name: string; action: Function }}
     */
    other?: { name: string; action: Function };
  };
}

/**
 *
 *
 * @interface IFloatingMenuState
 */
interface FloatingMenuState {
  /**
   *
   *
   * @type {boolean}
   * @memberof FloatingMenuState
   */
  toggled: boolean;

  /**
   *
   *
   * @type {{label:string ,icon:any, action:Function,id:string } []}
   * @memberof FloatingMenuState
   */
  buttonItems: { btn: FloatingMenuItemInput; toggledTime: number }[];
}

/**
 *
 *
 * @export
 * @class FloatingMenu
 * @extends {React.Component<{}, FloatingMenuState>}
 */
export class FloatingMenu extends React.Component<
  FloatingMenuProps,
  FloatingMenuState
> {
  /**
   *Creates an instance of FloatingMenu.
   * @param {{}} props
   * @memberof FloatingMenu
   */
  constructor(props: FloatingMenuProps) {
    super(props);
    let buttonItems: { btn: FloatingMenuItemInput; toggledTime: number }[];

    if (
      this.props.menuItems.initialPage !== undefined &&
      this.props.menuItems.initialPage !== null
    ) {
      buttonItems = [
        {
          btn: { title: "Import", action: null, icon: "arrow-circle-down" },
          toggledTime: 0
        },
        {
          btn: {
            title: "User Management",
            action: () => {
              window.location.assign("/cms-cognito-pool");
            },
            icon: "theater-masks"
          },
          toggledTime: 0
        },
        {
          btn: {
            title: "Add",
            action: Navigator.navigateToAddItemsPage,
            icon: "paperclip"
          },
          toggledTime: 0
        },
        {
          btn: {
            title: "Remove",
            action: this.props.menuItems.initialPage.removeAction,
            icon: "times"
          },
          toggledTime: 0
        }
      ];
    } else {
      buttonItems = [
        {
          btn: {
            title: "Return",
            action: this.props.menuItems.backAction
              ? this.props.menuItems.backAction
              : Navigator.navigateToAddItemsPage,
            icon: "undo-alt"
          },
          toggledTime: 0
        }
      ];

      // check if revert button is provided
      if (
        this.props.menuItems.revertible !== undefined &&
        this.props.menuItems.revertible !== null
      ) {
        buttonItems.push({
          btn: {
            action: this.props.menuItems.revertible,
            title: "Revert",
            icon: "undo-alt"
          },
          toggledTime: 0
        });
      }

      // check if clone button is provided
      if (
        this.props.menuItems.cloneable !== undefined &&
        this.props.menuItems.cloneable !== null
      ) {
        buttonItems.push({
          btn: {
            action: this.props.menuItems.cloneable,
            title: "Clone",
            icon: "clone"
          },
          toggledTime: 0
        });
      }

      // check if save button is provided
      if (
        this.props.menuItems.saveable !== undefined &&
        this.props.menuItems.saveable !== null
      ) {
        buttonItems.push({
          btn: {
            action: this.props.menuItems.saveable,
            title: "Save",
            icon: "download"
          },
          toggledTime: 0
        });
      }

      // check if delete button is provided
      if (
        this.props.menuItems.deleteAble !== undefined &&
        this.props.menuItems.deleteAble !== null
      ) {
        if (
          this.props.menuItems.deleteAble.deleteAction !== undefined &&
          this.props.menuItems.deleteAble.deleteAction
        ) {
          buttonItems.push({
            btn: {
              action: this.props.menuItems.deleteAble.deleteAction,
              title: "Delete",
              icon: "times"
            },
            toggledTime: 0
          });
        }

        if (
          this.props.menuItems.deleteAble.unDeleteAction !== undefined &&
          this.props.menuItems.deleteAble.unDeleteAction
        ) {
          buttonItems.push({
            btn: {
              action: this.props.menuItems.deleteAble.unDeleteAction,
              title: "unDelete",
              icon: "backspace"
            },
            toggledTime: 0
          });
        }
      }
      if (
        this.props.menuItems.other !== undefined &&
        this.props.menuItems.other !== null
      ) {
        if (
          this.props.menuItems.other.name !== undefined &&
          this.props.menuItems.other.name
        ) {
          buttonItems.push({
            btn: {
              action: this.props.menuItems.other.action,
              title: this.props.menuItems.other.name,
              icon: "user-plus"
            },
            toggledTime: 0
          });
        }
      }
    }
    this.state = {
      buttonItems,
      toggled: false
    };
  }
  /**
   *
   *
   * @memberof FloatingMenu
   */
  toggleMenu = () => {
    this.setState({
      toggled: !this.state.toggled
    });
  };

  /**
   *
   *
   * @returns
   * @memberof FloatingMenu
   */
  render() {
    let className = "floating-menu";
    let icon: IconProp = "plus";
    if (this.state.toggled === true) {
      className += " open";
    }
    return (
      <div>
        <div className={className}>
          {this.state.buttonItems.map((item, index) => {
            return (
              <FloatingMenuItem
                label={item.btn.title}
                icon={item.btn.icon}
                action={() => {
                  const TIME = new Date().getTime();
                  let buttonItems = this.state.buttonItems;
                  if (TIME - item.toggledTime >= 1000 * 3) {
                    buttonItems[index].toggledTime = TIME;
                    this.setState({ buttonItems });

                    if (item.btn.action) {
                      item.btn.action();
                    }
                  } else {
                    return undefined;
                  }
                }}
                key={index}
              />
            );
          })}
          <FloatingMenuItem label={""} icon={icon} key={icon} />
        </div>
      </div>
    );
  }
}
