/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-11
 * @description Implementation of poster utilities to be used in the app.
 * @filename poster.ts
 */

import { CreateUrlRouteInput, UpdateUrlRouteInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { UrlPageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_URL_ROUTE_GRAPHQL_MUTATION } from "../../relay/mutations/create-url-route";
import { UPDATE_URL_ROUTE_GRAPHQL_MUTATION } from "../../relay/mutations/update-url-route";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

export class UrlRoute {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ArticlePageState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addUrlRoute(
    urlRouteInput: UrlPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePosterInput
    let createInput: CreateUrlRouteInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      urlRouteInput.alias === undefined ||
      urlRouteInput.alias === null ||
      urlRouteInput.alias.length < 1
    ) {
      promise = Promise.reject(new Error("nM9MPJCxdUKTbtO1v6C27g"));
    } else {
      // initialize create person graphQl input
      createInput = {
        clientMutationId: getClientMutationId(),
        alias: urlRouteInput.alias.trim(),
        // description:
        //   urlRouteInput.description !== undefined &&
        //     urlRouteInput.description !== null
        //     && urlRouteInput.description.length > 0
        //     ? urlRouteInput.description.trim()
        //     : undefined,
        label:
          urlRouteInput.label !== undefined &&
          urlRouteInput.label !== null &&
          urlRouteInput.label.length > 0
            ? urlRouteInput.label.trim()
            : undefined,
        rules:
          urlRouteInput.rules !== undefined &&
          urlRouteInput.rules !== null &&
          urlRouteInput.rules.length > 0
            ? urlRouteInput.rules
            : undefined,
        tags:
          urlRouteInput.tags !== undefined &&
          urlRouteInput.tags !== null &&
          urlRouteInput.tags.length > 0
            ? urlRouteInput.tags
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_URL_ROUTE_GRAPHQL_MUTATION,
        variables: {
          input: createInput
        }
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createUrlRoute.hasErrors === false
          ? Promise.resolve(mutationResults.createUrlRoute.urlRoute)
          : Promise.reject(mutationResults.createUrlRoute.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updateUrlRoute(
    urlRouteInput: UrlPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  UpdateUrlRouteInput
    let updatePosterInput: UpdateUrlRouteInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[updateUrlRoute] provided input ", JSON.stringify(urlRouteInput));
    if (
      urlRouteInput.id === undefined ||
      urlRouteInput.id === null ||
      urlRouteInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("fAKJagAyME2veNZFkMk93Q"));
    } else {
      // initialize create person graphQl input
      updatePosterInput = {
        clientMutationId: getClientMutationId(),
        id: urlRouteInput.id,
        revision: urlRouteInput.revision,
        alias:
          urlRouteInput.alias !== undefined &&
          urlRouteInput.alias !== null &&
          urlRouteInput.alias.length > 0
            ? urlRouteInput.alias.trim()
            : undefined,
        description:
          urlRouteInput.description !== undefined &&
          urlRouteInput.description !== null &&
          urlRouteInput.description.length > 0
            ? urlRouteInput.description.trim()
            : undefined,
        label:
          urlRouteInput.label !== undefined &&
          urlRouteInput.label !== null &&
          urlRouteInput.label.length > 0
            ? urlRouteInput.label.trim()
            : undefined,
        rules:
          urlRouteInput.rules !== undefined &&
          urlRouteInput.rules !== null &&
          urlRouteInput.rules.length > 0
            ? urlRouteInput.rules
            : undefined,
        tags:
          urlRouteInput.tags !== undefined &&
          urlRouteInput.tags !== null &&
          urlRouteInput.tags.length > 0
            ? urlRouteInput.tags
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: UPDATE_URL_ROUTE_GRAPHQL_MUTATION,
        variables: {
          input: updatePosterInput
        }
      };

      // attempt to commit update person mutation
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateUrlRoute.hasErrors === false
          ? Promise.resolve(mutationResults.updateUrlRoute.urlRoute)
          : Promise.reject(mutationResults.updateUrlRoute.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deletePerson(
    urlRouteInput: UrlPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
