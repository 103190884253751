/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-8-19
 * @description Implementation of the GraphQL mutation to remove user from cognito group.
 * @filename remove-user-from-cognito-group.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update person.
 *
 * @type {GraphQLTaggedNode}
 */
export const REMOVE_USER_FROM_COGNITO_GROUP_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation removeUserFromCognitoGroupMutation(
    $input: AddRemoveUserToGroupInput!
  ) {
    removeUserFromCognitoGroup(input: $input) {
      clientMutationId
      errors {
        id
        location
      }
      hasErrors
    }
  }
`;
