/**
 * @author Linkon Islam
 * @date   2019-01-14
 * @description Manage preview item
 * @filename ManagePreviewItem.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PreviewKind } from "@radivision/graphql";

/**
 *
 *
 * @export
 * @interface ManagePreviewItemProps
 */
export interface ManagePreviewItemProps {
  /**
   *
   *
   * @type {PreviewKind}
   * @memberof ManagePreviewItemProps
   */
  previewKind: PreviewKind;

  /**
   *
   *
   * @type {string}
   * @memberof ManagePreviewItemProps
   */
  previewSource: string;

  /**
   *
   *
   * @type {number}
   * @memberof ManagePreviewItemProps
   */
  id: number;

  /**
   *
   *
   * @param {number} id
   * @memberof ManagePreviewItemProps
   */
  deletePreviewItem(id: number): void;

  /**
   *
   *
   * @type {Function}
   * @memberof ManagePreviewItemProps
   */
  onItemClickAction(index: number): void;
}

/**
 *
 *
 * @export
 * @param {ManagePreviewItemProps} { id,previewKind, previewSource, deletePreviewItem}
 * @returns
 */
export default function ManagePreviewItem(props: ManagePreviewItemProps) {
  return (
    <div className="InitialPageItem">
      <div
        className="row"
        onClick={(event: any) => {
          props.onItemClickAction(props.id);
        }}
      >
        {props.previewSource}
      </div>

      <label>{props.previewKind.toString()}</label>

      <span
        onClick={() => {
          props.deletePreviewItem(props.id);
        }}
      >
        <FontAwesomeIcon icon="times-circle" />
      </span>
    </div>
  );
}
