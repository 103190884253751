/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { dynamicComponent_component$ref } from "./dynamicComponent_component.graphql";
declare const _htmlPagePanels_panels$ref: unique symbol;
export type htmlPagePanels_panels$ref = typeof _htmlPagePanels_panels$ref;
export type htmlPagePanels_panels = ReadonlyArray<{
    readonly component: {
        readonly " $fragmentRefs": dynamicComponent_component$ref;
    } | null;
    readonly properties: ReadonlyArray<{
        readonly name: string | null;
        readonly article: {
            readonly id: string;
        } | null;
        readonly book: {
            readonly id: string;
        } | null;
        readonly boolean: boolean | null;
        readonly dynamicList: {
            readonly id: string;
        } | null;
        readonly entity: {
            readonly id: string;
        } | null;
        readonly fixedList: {
            readonly id: string;
        } | null;
        readonly id: string | null;
        readonly int: number | null;
        readonly json: string | null;
        readonly listCollection: {
            readonly id: string;
        } | null;
        readonly newsletter: {
            readonly id: string;
        } | null;
        readonly number: number | null;
        readonly onlineCourse: {
            readonly id: string;
        } | null;
        readonly originalContent: {
            readonly id: string;
        } | null;
        readonly person: {
            readonly id: string;
        } | null;
        readonly podcast: {
            readonly id: string;
        } | null;
        readonly poster: {
            readonly id: string;
        } | null;
        readonly event: {
            readonly id: string;
        } | null;
        readonly string: string | null;
    } | null> | null;
    readonly " $refType": htmlPagePanels_panels$ref;
}>;



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "htmlPagePanels_panels",
  "type": "HtmlDocumentPanel",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "component",
      "storageKey": null,
      "args": null,
      "concreteType": "Component",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "dynamicComponent_component",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "properties",
      "storageKey": null,
      "args": null,
      "concreteType": "ComponentPropertyValue",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "article",
          "storageKey": null,
          "args": null,
          "concreteType": "ArticleStory",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "book",
          "storageKey": null,
          "args": null,
          "concreteType": "BookStory",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "boolean",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "dynamicList",
          "storageKey": null,
          "args": null,
          "concreteType": "DynamicList",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "entity",
          "storageKey": null,
          "args": null,
          "concreteType": "Entity",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "fixedList",
          "storageKey": null,
          "args": null,
          "concreteType": "FixedList",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "int",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "json",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "listCollection",
          "storageKey": null,
          "args": null,
          "concreteType": "ListCollection",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "newsletter",
          "storageKey": null,
          "args": null,
          "concreteType": "NewsletterStory",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "number",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "onlineCourse",
          "storageKey": null,
          "args": null,
          "concreteType": "OnlineCourseStory",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "originalContent",
          "storageKey": null,
          "args": null,
          "concreteType": "OriginalContentStory",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "person",
          "storageKey": null,
          "args": null,
          "concreteType": "Person",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "podcast",
          "storageKey": null,
          "args": null,
          "concreteType": "PodcastStory",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "poster",
          "storageKey": null,
          "args": null,
          "concreteType": "Poster",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "event",
          "storageKey": null,
          "args": null,
          "concreteType": "Event",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "string",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'fc8f3ca8a0acad3e3234bae1e6d121f6';
export default node;
