/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-07
 * @description Implementation of the GraphQL mutation to add an online-course story.
 * @filename create-online-course-story
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an online-course.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_ONLINE_COURSE_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createOnlineCourseStoryMutation(
    $input: CreateOnlineCourseStoryInput!
  ) {
    createOnlineCourseStory(input: $input) {
      clientMutationId
      story {
        ... on OnlineCourseStory {
          id
          title
          publishedDate
          timeRequiredInSeconds
          publisher
          description
        }
      }
      hasErrors
    }
  }
`;
