/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-05-13
 * @filename questionnaire.ts
 */

export class ArraysHelper {
  /**
   *
   *
   * @static
   * @param {[]} firstArray
   * @param {[]} secondArray
   * @param {string} [key=null]
   * @memberof ArraysHelper
   */
  public static unionBy(
    firstArray: {}[],
    secondArray: {}[],
    key: string = null
  ): {}[] {
    const KEY = key ? key : "id";
    const mergedArray = [...secondArray, ...firstArray];
    let set = new Set();
    const unionArray = mergedArray.filter((item) => {
      if (!set.has(item[KEY])) {
        set.add(item[KEY]);
        return true;
      }
      return false;
    }, set);

    return unionArray;
  }
}
