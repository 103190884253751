/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-12
 * @description Implementation of the GraphQL mutation to add an book story.
 * @filename create-dynamic-list
 */

import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an book.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_DYNAMIC_LIST_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createDynamicListMutation($input: CreateDynamicListInput!) {
    createDynamicList(input: $input) {
      clientMutationId
      dynamicList {
        __typename
        ... on DynamicList {
          id
          title
        }
      }
      hasErrors
    }
  }
`;
