/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Initial page component
 * @filename InitialPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import ManageBadge from "./badge/manage-badge-panel";
import ListPanel from "./list-item-panel/list-item-panel";
import {
  DynamicList as graphDynamicList,
  GRAPHQL_TYPE_DYNAMIC_LIST,
  PreviewKind
} from "@radivision/graphql";
import { Validator } from "../../../utilities/cms-operation/validator";

import { DynamicList } from "../../../utilities/cms-operation/dynamic-list";
import { FloatingMenu } from "./floating-button/floating-menu";
import ModalConductor from "./modal/modal-conductor";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import {
  DynamicListPageFormInput,
  FormGroupInput,
  PreviewItem
} from "../../../component-configuration/cms-pages";
import { getCarouselItem } from "../../../utilities/general";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3
};

/**
 *
 *
 * @export
 * @interface DynamicListPageProps
 */

export interface DynamicListPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof MediaAssetPageProps
   */
  dynamicList?: { id: string };
  /**
   *
   *
   * @type {string}
   * @memberof MediaAssetPageProps
   */
  graphList?: graphDynamicList;
}

/**
 *
 *
 * @interface DynamicListPageState
 */

export interface DynamicListPageState {
  /**
   *
   *
   * @type {DynamicListPageFormInput}
   * @memberof DynamicListPageState
   */
  formInput: DynamicListPageFormInput;

  /**
   *
   *
   * @type {*}
   * @memberof BookPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   * array of error messages
   *
   * @type {{[index:string]:string}}
   * @memberof DynamicListPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof DynamicListPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof DynamicListPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class DynamicListPage
 * @extends {React.Component<DynamicListPageProps, DynamicListPageState>}
 */
export class DynamicListPage extends React.Component<
  DynamicListPageProps,
  DynamicListPageState
> {
  /**
   * References to the Author component in the DynamicListPage.
   *
   * @type {*}
   * @memberof DynamicListPage
   */
  badgePanelRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof DynamicListPage
   */
  listPanelRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof DynamicListPage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the DynamicListPage.
   *
   * @type {*}
   * @memberof DynamicListPage
   */
  tagsRef: any;

  /**
   * Creates an instance of DynamicListPage.
   * @param {DynamicListPageProps} props
   * @memberof DynamicListPage
   */
  constructor(props: DynamicListPageProps) {
    super(props);

    //... bind needed function to class

    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handleExpressionChanges = this.handleExpressionChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleAddDynamicList = this.handleAddDynamicList.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    //... initialize required components references

    this.badgePanelRef = React.createRef();
    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();
    this.listPanelRef = React.createRef();

    //... initial component state
    this.state = this.handleComponentStateInitialize(this.props.graphList);
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @param {posterStory} listProps
   * @returns {posterPageState}
   * @memberof posterPage
   */
  handleComponentStateInitialize(
    listProps: graphDynamicList
  ): DynamicListPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: DynamicListPageFormInput = {
      id: undefined,
      title: "",
      label: "",
      subTitle: "",
      summary: "",
      isDeleted: false
    };

    let state: DynamicListPageState = {
      modal,
      formInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    if (listProps !== null && listProps !== undefined) {
      if (listProps.id) {
        state.formInput.id = listProps.id;
      }

      if (listProps.label) {
        state.formInput.label = listProps.label;
      }

      if (listProps.title) {
        state.formInput.title = listProps.title;
      }

      if (listProps.subTitle) {
        state.formInput.subTitle = listProps.subTitle;
      }

      if (listProps.name) {
        state.formInput.name = listProps.name;
      }

      if (listProps.description) {
        state.formInput.summary = listProps.description;
      }

      if (listProps.isDeleted) {
        state.formInput.isDeleted = listProps.isDeleted;
      }

      if (listProps.revision) {
        state.formInput.revision = listProps.revision;
      }

      // ........................
      // initialize form group inputs
      //...........................

      if (
        listProps.tags !== null &&
        listProps.tags !== undefined &&
        listProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = listProps.tags.map(
          tag => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        listProps.previews !== null &&
        listProps.previews !== undefined &&
        listProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = listProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`
                  }
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }

      if (
        listProps.badges !== null &&
        listProps.badges !== undefined &&
        listProps.badges.length > 0
      ) {
        state.formGroupInput.badgesProps = listProps.badges;
      }

      if (
        listProps.items !== null &&
        listProps.items !== undefined &&
        listProps.items.count > 0
      ) {
        state.formGroupInput.mediaItemProps = listProps.items.edges.map(
          (edge: any) => {
            return getCarouselItem(
              edge.listItem.item,
              listProps,
              0,
              {
                containerWidthRatio: 6 / 12,
                numberOfItems: 6
              },
              PreviewKind.SQUARE
            );
          }
        );
      }
      // console\.log(state.formGroupInput.mediaItemProps);
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof DynamicListPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Dynamic List</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    name="Subtitle"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.formInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>

            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.formInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Summary</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.summary}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
              <div className="Page-Field">
                <label className="Page-Require">Expression</label>
                <input
                  type="text"
                  name="expression"
                  value={this.state.formInput.expression}
                  onChange={this.handleExpressionChanges}
                  className="form-control"
                />
              </div>
            </div>
            {/* Story Status */}

            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="isdeleted"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>
            <div className="Page--Badge">
              <label>Badges</label>
              <ManageBadge
                badgeList={this.state.formGroupInput.badgesProps}
                ref={this.badgePanelRef}
              />
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <label>Tags</label>
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <label>Preview</label>
              <ManagePreviewPanel
                ref={this.previewPanelRef}
                previewsList={this.state.formGroupInput.previewsProps}
              />
            </div>
            <div className="Page--Lists">
              <label>Items</label>
              <ListPanel
                ref={this.listPanelRef}
                items={this.state.formGroupInput.mediaItemProps}
                filter={null}
                readonly={true}
              />
            </div>
          </div>
          <Loader isActive={this.state.isLoading} />

          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              cloneable: () => {},
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                }
              },
              saveable: this.handleAddDynamicList
            }}
          />
        </div>
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof DynamicListPage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: DynamicListPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.title = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  sub-title state
   *
   * @memberof DynamicListPage
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: DynamicListPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.subTitle = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  Publish Date state
   *
   * @memberof DynamicListPage
   */
  handleExpressionChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: DynamicListPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.expression = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof DynamicListPage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: DynamicListPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.summary = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof DynamicListPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: DynamicListPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  is deleted state
   *
   * @memberof DynamicListPage
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: DynamicListPageFormInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    formInput.isDeleted = isDeleted;
    // // console\.log("[handleIsDeletedChanges] is  deleted", isDeleted);

    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling submitting new
   *
   * @memberof DynamicListPage
   */
  handleAddDynamicList(): void {
    let posterInput: DynamicListPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      posterInput.formInput.tags = this.tagsRef.current.extractTagsString();
      // extract previews list
      posterInput.formInput.previews = this.previewPanelRef.current.extractPreviews();

      // validate errors
      errorMessage = Validator.validateListInput(posterInput.formInput);

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console\.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (
          this.props.dynamicList !== null &&
          this.props.dynamicList !== undefined
        ) {
          internalPromise = DynamicList.updateDynamicList(
            posterInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["DYNAMIC_LIST"].message.confirmation.update;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = DynamicList.addDynamicList(
            posterInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["DYNAMIC_LIST"].message.confirmation.add;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["DYNAMIC_LIST"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @memberof DynamicListPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof DynamicListPage
   */
  revertPageInput(input?: graphDynamicList): void {
    let state: DynamicListPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.graphList
    );
    this.setState(state);

    //... reset children state
    this.tagsRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.listPanelRef.current.resetComponent();
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof DynamicListPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 *
 *
 * @export
 * @class DynamicListPageContainer
 * @extends {React.Component<DynamicListPageProps, DynamicListPageState>}
 */
export class DynamicListPageContainer extends React.Component<
  DynamicListPageProps,
  DynamicListPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let listId: string;

    // console\.log("[DynamicListPageContainer] provided props", this.props);

    if (
      this.props.dynamicList === null ||
      this.props.dynamicList === undefined
    ) {
      node = <DynamicListPage />;
    } else {
      listId = this.props.dynamicList.id;
      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query dynamicListPageQuery($id: ID!) {
              list(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                list {
                  __typename
                  ... on DynamicList {
                    id
                    description
                    label
                    revision
                    name
                    filter {
                      expression
                      tags {
                        clauses {
                          operator {
                            description
                            label
                          }
                        }
                        operator {
                          description
                          label
                        }
                        tags {
                          id
                          label
                        }
                      }
                    }
                    items {
                      count
                      edges {
                        cursor
                        listItem {
                          item {
                            __typename
                            ... on SearchHit {
                              id
                              kind
                              label
                              linkUrl
                              name
                              previewUrl
                              publisher
                              responsibility
                              landingPageUrl
                              subTitle
                              title
                            }
                          }
                        }
                      }
                    }
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            files {
                              id
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                              label
                            }
                          }
                        }
                      }
                    }

                    subTitle
                    title
                    revision
                  }
                }
              }
            }
          `}
          variables={{
            id: listId
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.list.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.list.list.__typename !== GRAPHQL_TYPE_DYNAMIC_LIST
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid dynamicList id, {listId} belongs to type{" "}
                      {props.list.list.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.list.list) {
                let pageProps: DynamicListPageProps = {
                  ...this.props,
                  graphList: props.list.list
                };
                return <DynamicListPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
