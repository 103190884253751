/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to add an article story.
 * @filename create-article-story
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an article.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_MARKET_STORY_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createMarketStoryMutation($input: CreateMarketEntryStoryInput!) {
    createMarketEntryStory(input: $input) {
      clientMutationId
      story {
        ... on MarketEntryStory {
          __typename
          id
          revision
          landingPageUrl
          offerType
          description
          promoCode
          subTitle
          title
          label
          link
           tags {
             id
             label
           }
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                  }
                }
              }
            }
          }
          revision
          title
        }
      }
      hasErrors
      errors {
        id
        location
      }
    }
  }
`;
