/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MediaAssetFileStatusKind = "AVAILABLE" | "CREATED" | "DELETED" | "ERROR" | "PROCESSING" | "%future added value";
export type UpdateMediaAssetFileInput = {
    readonly clientMutationId: string;
    readonly copies?: ReadonlyArray<CreateNetworkLocationInput> | null;
    readonly force?: boolean | null;
    readonly format?: string | null;
    readonly id: string;
    readonly label?: string | null;
    readonly name?: string | null;
    readonly revision?: string | null;
    readonly status?: MediaAssetFileStatusKind | null;
    readonly tags?: ReadonlyArray<string | null> | null;
};
export type CreateNetworkLocationInput = {
    readonly addresses?: ReadonlyArray<CreateNetworkLocationAddressInput> | null;
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly label?: string | null;
    readonly supportsPath?: boolean | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreateNetworkLocationAddressInput = {
    readonly uri: string;
    readonly tags?: ReadonlyArray<string> | null;
    readonly zone: string;
};
export type updateMediaAssetFileMutationVariables = {
    readonly input: UpdateMediaAssetFileInput;
};
export type updateMediaAssetFileMutationResponse = {
    readonly updateMediaAssetFile: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly mediaAssetFile: {
            readonly id: string;
            readonly name: string | null;
            readonly copies: ReadonlyArray<{
                readonly id: string;
            }> | null;
        } | null;
    } | null;
};
export type updateMediaAssetFileMutation = {
    readonly response: updateMediaAssetFileMutationResponse;
    readonly variables: updateMediaAssetFileMutationVariables;
};



/*
mutation updateMediaAssetFileMutation(
  $input: UpdateMediaAssetFileInput!
) {
  updateMediaAssetFile(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    mediaAssetFile {
      id
      name
      copies {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateMediaAssetFileInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMediaAssetFile",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateMediaAssetFilePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mediaAssetFile",
        "storageKey": null,
        "args": null,
        "concreteType": "MediaAssetFile",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "copies",
            "storageKey": null,
            "args": null,
            "concreteType": "NetworkLocation",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateMediaAssetFileMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateMediaAssetFileMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateMediaAssetFileMutation",
    "id": null,
    "text": "mutation updateMediaAssetFileMutation(\n  $input: UpdateMediaAssetFileInput!\n) {\n  updateMediaAssetFile(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    mediaAssetFile {\n      id\n      name\n      copies {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '54380177a36bf3af0f4084a6a60ab2b1';
export default node;
