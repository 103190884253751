/**
 * @author William Alexander Livesley
 * @date   2018-06-30
 * @description Declaration of the relay environment supported by AppSync.
 * @filename relay-environment.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import {
  Environment,
  Network,
  RecordSource,
  RequestNode,
  Store,
  Variables
} from "relay-runtime";
import { ApiClient } from "../authentication/api-client";

/**
 * The relay environment.
 *
 * @constant
 * @type {Environment}
 */
export const ENVIRONMENT: Environment = new Environment({
  network: Network.create(executeGraphQuery),
  store: new Store(new RecordSource())
});

/**
 * Returns a promise to fetch the response to a request containing a given GraphQL query.
 *
 * @param {RequestNode} operation The GraphQL query.
 *
 * @param {Variables} variables An arbitrary set of variables associated with the query.
 *
 * @return {Promise<{}>} A promise containing the response to the request containing a given GraphQL query.
 */
function executeGraphQuery(
  operation: RequestNode,
  variables: Variables
): Promise<{}> {
  // Try to get data from cache on queries
  return ApiClient.post(operation.text, variables)
    .then(res => {
      // Update cache on queries
      return Promise.resolve(res);
    })
    .catch((err: any) => {
      console.error("[fetchQuery] ApiClient error :", err);
      return Promise.resolve({ errors: err.errors });
    });
}
