import * as React from "react";
import { ManagePreviewPanel } from "../../manage-preview/manage-preview-panel";
import {
  CreateMultipleChoiceQuestionChoiceInput,
  CreateUpdatePreviewInput,
  MultipleChoiceQuestionChoice
} from "@radivision/graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 *
 *
 * @interface ChoiceProps
 */
interface ChoiceProps {
  /**
   *
   *
   * @type {MultipleChoiceQuestionChoice}
   * @memberof ChoiceProps
   */
  input?: MultipleChoiceQuestionChoice;

  /**
   *
   *
   * @type {number}
   * @memberof ChoiceProps
   */
  choiceId: string;

  /**
   *
   *
   * @memberof ChoiceProps
   */
  handleChange?: void;

  /**
   *
   *
   * @param {number} id
   * @memberof ChoiceProps
   */
  removeChoiceClick(id: string): void;
}

interface ChoiceState {
  /**
   *
   *
   * @type {string}
   * @memberof ChoiceState
   */
  title: string;

  /**
   *
   *
   * @type {string}
   * @memberof ChoiceState
   */
  subTitle: string;

  /**
   *
   *
   * @type {*}
   * @memberof ChoiceState
   */
  item: string;

  /**
   *
   *
   * @type {boolean}
   * @memberof ChoiceState
   */
  acceptText: boolean;

  /**
   *
   *
   * @type {[]}
   * @memberof ArticlePageState
   */
  previews?: CreateUpdatePreviewInput[];
}

/**
 *
 *
 * @export
 * @class Choices
 * @extends {React.Component<ChoiceProps, ChoiceState>}
 */
export default class Choices extends React.Component<ChoiceProps, ChoiceState> {
  /**
   *
   *
   * @type {*}
   * @memberof Choices
   */
  previewPanelRef: any;

  /**
   * Creates an instance of Choices.
   * @param {ChoiceProps} props
   * @memberof Choices
   */
  constructor(props: ChoiceProps) {
    super(props);

    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSubTitleChange = this.handleSubTitleChange.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleAcceptTextChange = this.handleAcceptTextChange.bind(this);
    this.previewPanelRef = React.createRef();

    this.state = this.handleComponentInitialize(this.props.input);
  }

  /**
   *
   *
   * @param {MultipleChoiceQuestionChoice} input
   * @returns {ChoiceState}
   * @memberof Choices
   */
  handleComponentInitialize(input: MultipleChoiceQuestionChoice): ChoiceState {
    let state: ChoiceState = {
      title: "",
      subTitle: "",
      item: "",
      acceptText: false
    };

    if (input !== undefined && input !== null) {
      if (input.acceptsText) {
        state.acceptText = input.acceptsText;
      }

      if (input.title) {
        state.title = input.title;
      }

      if (input.subTitle) {
        state.subTitle = input.subTitle;
      }

      // state.item = input.person.id | input.story.id | input.entity.id | input.poster.id ;
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof Choices
   */
  render() {
    return (
      <div style={{ border: "1px solid", margin: "20px" , padding:"15px" }}>
        <label>Choices Panel</label>
        <button
          className="btn add-btn"
          onClick={() => {
            this.props.removeChoiceClick(this.props.choiceId);
          }}
        >
          <FontAwesomeIcon icon="times-circle" /> close
        </button>
        <div className="input-field">
          <label>Title</label>

          <input
            placeholder="your title"
            id="title"
            type="text"
            value={this.state.title}
            className="validate form-control"
            onChange={this.handleTitleChange}
          />
        </div>
        <div className="input-field">
          <label>Sub title</label>
          <input
            placeholder="your subtitle"
            id="subtitle"
            type="text"
            value={this.state.subTitle}
            className="validate form-control"
            onChange={this.handleSubTitleChange}
          />
        </div>
        <div className="input-field mt-5">
          <label>Item</label>

          <input
            id="search"
            type="search"
            className="inline-input form-control"
            required={true}
            value={this.state.item}
            onChange={this.handleItemChange}
          />
          <button className="btn add-btn">
            <FontAwesomeIcon icon="search" /> search
          </button>
        </div>
        <div className="input-field">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              onChange={this.handleAcceptTextChange}
              value={this.state.acceptText ? "checked" : "unchecked"}
              className="custom-control-input"
            />
            <label className="custom-control-label">Accepts Text</label>
          </div>
        </div>

        {/* preview section */}
        <div className="Page--Preview">
          <ManagePreviewPanel ref={this.previewPanelRef} />
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof Choices
   */
  handleTitleChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();

    this.setState({
      title: changeEvent.currentTarget.value
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof Choices
   */
  handleSubTitleChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();

    this.setState({
      subTitle: changeEvent.currentTarget.value
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof Choices
   */
  handleItemChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();

    this.setState({
      item: changeEvent.currentTarget.value
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof Choices
   */
  handleAcceptTextChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();
    let value = changeEvent.currentTarget.value === "checked" ? true : false;
    this.setState({
      acceptText: value
    });
  }

  /**
   *
   *
   * @returns {CreateMultipleChoiceQuestionChoiceInput}
   * @memberof Choices
   */
  extractChoiceInput(): CreateMultipleChoiceQuestionChoiceInput {
    let componentState: ChoiceState = this.state;
    let choiceInput: CreateMultipleChoiceQuestionChoiceInput;

    componentState.previews = this.previewPanelRef.current.extractPreviews();

    choiceInput = {
      title: componentState.title,
      previews: componentState.previews,
      acceptsText: componentState.acceptText,
      subTitle: componentState.subTitle
    };

    return choiceInput;
  }
}
