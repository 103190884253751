/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DeleteSupportIssueInput = {
    readonly clientMutationId: string;
    readonly force?: boolean | null;
    readonly id: string;
    readonly revision?: string | null;
};
export type deleteSupportIssueMutationVariables = {
    readonly input: DeleteSupportIssueInput;
};
export type deleteSupportIssueMutationResponse = {
    readonly deleteSupportIssue: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly supportIssue: {
            readonly __typename: string;
            readonly browserAndDeviceType: string | null;
            readonly created: unknown | null;
            readonly description: string | null;
            readonly email: string | null;
            readonly id: string;
            readonly isDeleted: boolean | null;
            readonly name: string | null;
            readonly person: {
                readonly id: string;
            } | null;
            readonly revision: string | null;
            readonly lastModified: unknown | null;
        } | null;
    } | null;
};
export type deleteSupportIssueMutation = {
    readonly response: deleteSupportIssueMutationResponse;
    readonly variables: deleteSupportIssueMutationVariables;
};



/*
mutation deleteSupportIssueMutation(
  $input: DeleteSupportIssueInput!
) {
  deleteSupportIssue(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    supportIssue {
      __typename
      browserAndDeviceType
      created
      description
      email
      id
      isDeleted
      name
      person {
        id
      }
      revision
      lastModified
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteSupportIssueInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteSupportIssue",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteSupportIssuePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "supportIssue",
        "storageKey": null,
        "args": null,
        "concreteType": "SupportIssue",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "browserAndDeviceType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isDeleted",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "person",
            "storageKey": null,
            "args": null,
            "concreteType": "Person",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastModified",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "deleteSupportIssueMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "deleteSupportIssueMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "deleteSupportIssueMutation",
    "id": null,
    "text": "mutation deleteSupportIssueMutation(\n  $input: DeleteSupportIssueInput!\n) {\n  deleteSupportIssue(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    supportIssue {\n      __typename\n      browserAndDeviceType\n      created\n      description\n      email\n      id\n      isDeleted\n      name\n      person {\n        id\n      }\n      revision\n      lastModified\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '616697e1ed81508ba61832434d69b324';
export default node;
