/**
 * @author Sarah Nouh
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-8-28 03:33
 * @description Implementation of the image section in the media modals.
 * @filename media-image-section.tsx
 */
import { GRAPHQL_TYPE_ARTICLE_STORY } from "@radivision/graphql/lib/ts/graphql/article-story";

import React from "react";
import {
  ACCEPT_IMAGE_FORMATS,
  ADD_IMAGE_LINK,
  CUSTOM_IMAGE_SELECT,
} from "../../constants/general-constants";
import { DEFAULTS } from "../../utilities/defaults";
import { getCleanUuid, isValidUrl } from "../../utilities/general";
import { ImageHelper } from "../../utilities/image-helper";
import { MEDIA_TYPE_TO_EXTENSION } from "../../utilities/media-asset";
import { MediaAssetFile } from "../../utilities/media-asset-file";
import { RdvButton } from "../page-framework/rdv-button";
import { GRAPHQL_TYPE_ENTITY } from "@radivision/graphql/lib/ts/graphql/entity";
import { GRAPHQL_TYPE_PERSON } from "@radivision/graphql/lib/ts/graphql/person";
import { GRAPHQL_TYPE_RADIVISION_POST } from "@radivision/graphql/lib/ts/graphql/radivision-post";
import { GRAPHQL_TYPE_BOOK_STORY } from "@radivision/graphql/lib/ts/graphql/book-story";
import { GRAPHQL_TYPE_NEWSLETTER_STORY } from "@radivision/graphql/lib/ts/graphql/newsletter-story";
import { GRAPHQL_TYPE_PODCAST_STORY } from "@radivision/graphql/lib/ts/graphql/podcast-story";
import { GRAPHQL_TYPE_ONLINE_COURSE_STORY } from "@radivision/graphql/lib/ts/graphql/online-course-story";
const AVATAREDITOR = require("react-avatar-editor");
const omit = require("lodash.omit");
const isEqual = require("lodash.isequal");

const ADDIMAGE = (
  <svg
    version="1.1"
    id="Plus"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
                                v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
const addImageButton = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 135.2 135.2"
    className="addImageButton"
  >
    <title>-</title>
    <path d="M113.6,135.2h-92A21.6,21.6,0,0,1,0,113.6v-92A21.6,21.6,0,0,1,21.6,0h92a21.6,21.6,0,0,1,21.6,21.6v92A21.6,21.6,0,0,1,113.6,135.2ZM21.6,6A15.6,15.6,0,0,0,6,21.6v92a15.6,15.6,0,0,0,15.6,15.6h92a15.6,15.6,0,0,0,15.6-15.6v-92A15.6,15.6,0,0,0,113.6,6Z" />
    <polygon points="130.1 102.1 103.1 75.2 78.7 99.6 37.7 58.6 6.4 89.9 2.2 85.6 37.7 50.1 78.7 91.1 103.1 66.7 134.3 97.9 130.1 102.1" />
    <path d="M95,55a16.5,16.5,0,1,1,16.4-16.5A16.5,16.5,0,0,1,95,55Zm0-26.9a10.5,10.5,0,1,0,10.4,10.4A10.5,10.5,0,0,0,95,28.1Z" />
  </svg>
);

/**
 * number defining the width avatar editor boarder
 */
const EDITOR_WIDTH: number = 320;

/**
 * number defining the height avatar editor boarder
 */
const EDITOR_HEIGHT: number = 320;

/**
 * number defining maximum allowed file size
 */
const MAX_FILE_SIZE: number = 20971520;
/**
 * number defining maximum allowed file size
 */
const DEFAULT_IMAGE = require("../../../static/no-image-icon.png");
/**
 *
 */
const COMPONENT_NAME = "MediaImageSection";

/**
 * The props of the MediaImageSection component.
 *
 * @interface
 */
interface MediaImageSectionProps {
  avatarProps?: {
    /**
     *
     *
     * @type {number}
     */
    border?: number;

    /**
     *
     *
     * @type {number}
     */
    borderRadius?: number;

    /**
     *
     *
     * @type {number}
     */
    width?: number;

    /**
     *
     *
     * @type {number}
     */
    height?: number;

    /**
     *
     *
     * @type {boolean}
     */
    fitToImageSize?: boolean;
  };

  /**
   * a Flag for edit state of the modal
   *
   * @type {Boolean}
   */
  editMode?: boolean;

  /**
   * the GRAPHQL_TYPE type of parent modal
   *
   * @type {string}
   */
  formType?: string;

  /**
   * string representing image source provided to avatar editor
   * @type {string}
   * @memberof MediaImageSectionProps
   */
  imageSrc?: string;

  /**
   * used in ProfileHeader to indicate if the new uploaded bg is selected
   *
   * @type {boolean}
   */
  isSelected?: boolean;

  /**
   * Function triggers when user attempts to cancel image manipulating
   * @type {Function}
   * @memberof MediaImageSectionProps
   */
  onCancel?: Function;

  /**
   * when the new custom Background is selected
   *
   * @type {Function}
   */
  onSelect?: Function;

  /**
   * Function triggers when user finish manipulating image
   * and ready to submit its content
   *
   * @type {Function}
   * @memberof MediaImageSectionProps
   */
  onSubmit: (
    staticFileInfo: {
      /**
       * blob contains the actual bytes of original file | manipulated one
       */
      fileBinaryContent: Blob;
      /**
       * File object contains needed information of uploaded file
       */
      imageObject: File | string;
      /**
       * number defining file size in bytes
       */
      fileSize: number;
      /**
       * string defining file name
       */
      fileName: string;
      /**
       * string defining file type defined in file header not file extension
       */
      mimeType: string;
    },
    onSubmitSuccess: Function
  ) => void;

  /**
   * Function triggers when submitting image finished and
   * user should be updated with submit result
   * @type {Function}
   * @memberof MediaImageSectionProps
   */
  onUploadSuccess?: Function;
  /**
   * The text of the image section title
   *
   * @type {string}
   *
   */
  titleText?: string;

  /**
   *
   *
   * @type {boolean}
   * @memberof MediaImageSectionProps
   */
  restartComponent?: boolean;

  /**
   *
   *
   * @type {boolean}
   * @memberof MediaImageSectionProps
   */
  isBackground?: boolean;
}

/**
 *
 *
 * @interface StaticFile
 */
interface StaticFile {
  /**
   * blob contains the actual bytes of original file | manipulated one
   */
  fileBinaryContent: Blob;
  /**
   * File object contains needed information of uploaded file
   */
  imageObject: File | string | any;
  /**
   * number defining file size in bytes
   */
  fileSize: number;
  /**
   * string defining file name
   */
  fileName: string;
  /**
   * string defining file type defined in file header not file extension
   */
  mimeType: string;
}

/**
 * The states of the MediaImageSection component.

 * @interface MediaImageSectionState
 */
interface MediaImageSectionState {
  showImage: boolean;
  /**
   * object include every props avatar editor component need to
   * work probably
   */
  avatarEditor: {
    /**
     *  number define avatar editor border size
     */
    borderSize: number;
    /**
     * number define avatar editor border radius
     */
    borderRadius: number;
    /**
     * object defining the rectangle of cropping area
     */
    croppingPosition: {
      /**
       * number defining the width of cropping rectangle
       */
      x: number;
      /**
       * number defining the height of cropping rectangle
       */
      y: number;
    };
    /**
     * number defining the whole height of avatar component
     */
    editorHeight: number;
    /**
     * number defining the whole width of avatar component
     */
    editorWidth: number;
    /**
     * number define the rotation angle of cropping rectangle
     */
    rotateValue: number;
    /**
     * number define the zooming factor of cropping rectangle
     */
    zoomValue: number;
  };
  /**
   * object defining all needed argument to upload static file
   */
  staticFileInformation: StaticFile;

  /**
   * display error message if something happens
   *
   * @type {string}
   */
  errorMsg: string;

  /**
   * url of the preview connected to this newsletter
   *
   * @type {string}
   */
  previewUrl: string;

  /**
   * url of the preview connected to this newsletter
   *
   * @type {boolean}
   */
  showImageSection: boolean;
}

/**
 *  A React component that renders the image section in the media modals.
 *
 * @export
 * @class MediaImageSection
 * @extends {React.Component<MediaImageSectionProps>}
 */
export class MediaImageSection extends React.Component<
  MediaImageSectionProps,
  MediaImageSectionState
> {
  avatarEditorRef: React.RefObject<any>;
  /**
   * class member variable to generate unique id for
   * selecting custom image
   *
   * @type {string}
   * @memberof MediaImageSection
   */
  inputComponentsIds: Map<string, string>;

  /**
   * Constructor.
   *
   * @param {MediaImageSectionProps} props The props of the component.
   */
  constructor(props: MediaImageSectionProps) {
    super(props);
    this.avatarEditorRef = React.createRef();

    const avatarEditor: {
      zoomValue: number;
      rotateValue: number;
      croppingPosition: {
        x: number;
        y: number;
      };
      borderSize: number;
      borderRadius: number;
      editorHeight: number;
      editorWidth: number;
    } = this.getDefaultAvatarSetting();

    const staticFileInformation: StaticFile = this.getDefaultImage();

    this.state = {
      avatarEditor,
      staticFileInformation,
      errorMsg: "",
      previewUrl: "",
      showImage: false,
      showImageSection: false,
    };

    this.getDefaultImage = this.getDefaultImage.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onLoadImage = this.onLoadImage.bind(this);
    this.onPositionChange = this.onPositionChange.bind(this);
    this.onRevert = this.onRevert.bind(this);
    this.onExtractManipulateImage = this.onExtractManipulateImage.bind(this);
    this.generateImageFileName = this.generateImageFileName.bind(this);
    this.collapseImageSection = this.collapseImageSection.bind(this);
    this.closeImageSection = this.closeImageSection.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.changeLink = this.changeLink.bind(this);

    // allocate empty map to hold inputs id
    this.inputComponentsIds = new Map();
    // generate id for select custom image button
    this.inputComponentsIds.set(
      CUSTOM_IMAGE_SELECT,
      `${COMPONENT_NAME}-${getCleanUuid()}`
    );
    // generate id for select image link url
    this.inputComponentsIds.set(
      ADD_IMAGE_LINK,
      `${COMPONENT_NAME}-${getCleanUuid()}`
    );
  }

  changeLink(link) {
    this.setState(
      {
        showImageSection: true,
        showImage: true,
      },
      () => {
        this.handlePreviewChange({ currentTarget: { value: link } });
      }
    );
  }

  /**
   *Invoked when Component received a change in props or state
   *
   * @param {MediaImageSectionProps} prevProps
   * @param {MediaImageSectionState} prevState
   * @memberof MediaImageSection
   */
  componentDidUpdate(prevProps: MediaImageSectionProps) {
    // if (
    //   !isEqual(
    //     omit(prevProps, ["isSelected"]),
    //     omit(this.props, ["isSelected"])
 
    //   )
    // ) {
    //   const defaultImage: StaticFile = this.getDefaultImage();
    //   this.setState({
    //     staticFileInformation: defaultImage,
    //     showImageSection: true,
    //   });
    // }
  }

  /**
   * assign a default image according to active parent modal when initialization and when update
   *
   * @returns {StaticFile} the default image object
   */
  getDefaultImage(): StaticFile {
    let imageSourceUrl: string = "";
    switch (this.props.formType) {
      case GRAPHQL_TYPE_ENTITY:
        imageSourceUrl = DEFAULTS["DEFAULT_Entity_SQUARE"];
        break;
      case GRAPHQL_TYPE_PERSON:
        imageSourceUrl = DEFAULTS["DEFAULT_Person_SQUARE"];
        break;
      case GRAPHQL_TYPE_RADIVISION_POST:
        imageSourceUrl = DEFAULTS["DEFAULT_RadivisionPost_SQUARE"];
        break;
      case GRAPHQL_TYPE_ARTICLE_STORY:
        imageSourceUrl = DEFAULTS["DEFAULT_ArticleStory_SQUARE"];
        break;
      case GRAPHQL_TYPE_BOOK_STORY:
        imageSourceUrl = DEFAULTS["DEFAULT_BookStory_SQUARE"];
        break;
      case GRAPHQL_TYPE_NEWSLETTER_STORY:
        imageSourceUrl = DEFAULTS["DEFAULT_NewsletterStory_SQUARE"];
        break;
      case GRAPHQL_TYPE_PODCAST_STORY:
        imageSourceUrl = DEFAULTS["DEFAULT_PodcastStory_SQUARE"];
        break;
      case GRAPHQL_TYPE_ONLINE_COURSE_STORY:
        imageSourceUrl = DEFAULTS["DEFAULT_OnlineCourseStory_SQUARE"];
        break;
      default:
        imageSourceUrl = DEFAULT_IMAGE;
    }

    imageSourceUrl =
      imageSourceUrl === DEFAULT_IMAGE
        ? DEFAULT_IMAGE
        : `${process.env.URL_MEDIA_IMAGE}/${imageSourceUrl}?wip=450`;

    if (this.props.imageSrc !== undefined && this.props.imageSrc !== null) {
      imageSourceUrl = this.props.imageSrc;
    }

    return {
      fileBinaryContent: undefined,
      imageObject: imageSourceUrl,
      fileSize: -1,
      fileName: "",
      mimeType: "",
    };
  }

  /**
   * Function to indicate if user doesn't tends to add image in media image section
   * so no need to extract it from the canvas and create needles upload
   * @returns
   * @memberof MediaImageSection
   */
  isDefaultImage(): boolean {
    const defaultImage: StaticFile = this.getDefaultImage();
    return isEqual(this.state.staticFileInformation, defaultImage);
  }

  /**
   * Invoked when the preview value is changed.
   *
   * @param {React.FormEvent<HTMLInputElement>} e The event.
   */
  handlePreviewChange = (
    e?: React.FormEvent<HTMLInputElement>,
    type: "REMOTE" | "CONTENT" = "REMOTE"
  ) => {
    const previewUrl: string = e
      ? e.currentTarget.value
      : this.state.previewUrl;
    const staticFileInformation = this.state.staticFileInformation;
    let imageUrl: string;

    debugger;
    // check if provided image link is a valid url then attempt
    //
    if (isValidUrl(previewUrl)) {
      switch (type) {
        case "REMOTE":
          imageUrl = ImageHelper.fetchOptimizedImageUrl({
            imageType: "REMOTE",
            remote: { url: previewUrl },
            desiredDimensions: {
              containerWidthRatio: 7 / 12,
              numberOfItems: 4,
            },
            revision: undefined,
          }).originalUrl;

          break;

        case "CONTENT":
          imageUrl = previewUrl;
          break;

        default:
      }

      if (imageUrl !== null && imageUrl !== undefined) {
        staticFileInformation.imageObject = imageUrl;
      }
    }

    this.setState({
      staticFileInformation,
      previewUrl,
    });
  };

  /**
   * Invoked after a given file is selected to handle displaying the file.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e The source event.
   */
  onSelectFile(e: React.ChangeEvent<HTMLInputElement>): void {
    let reader: FileReader;
    let fileSize: number;
    const staticFileInformation: StaticFile = this.state.staticFileInformation;
    e.preventDefault();
    let errorMsg: string = "";
    if (e.target.files && e.target.files[0]) {
      // validate file size before actual load event
      fileSize = e.target.files[0].size;
      // validate zero bytes image (corrupted image file)
      if (fileSize <= 0) {
        errorMsg = "Invalid file size";
        this.setState({ errorMsg });
        return;
      }
      // validate image size before actual upload
      if (fileSize > MAX_FILE_SIZE) {
        errorMsg = "File size exceeds maximum file size!";
        this.setState({ errorMsg });
        return;
      }
      staticFileInformation.imageObject = e.target.files[0];
      reader = new FileReader();
      // read entire file as bytes array
      reader.readAsArrayBuffer(e.target.files[0]);
      // register on load callback
      reader.onload = (event) => {
        // assign target event
        const targetEvent: any = event.target;
        // Get access to the result bytes
        const fileRawData: DataView = new DataView(targetEvent["result"]);
        // extract first 4 bytes to indicate file mime-type
        const fileMagicHeader: string = fileRawData
          .getUint32(0, false)
          .toString(16);
        // assign loaded image mime-type
        staticFileInformation.mimeType = MediaAssetFile.extractFileMimeType(
          fileMagicHeader
        );
        // validate extracted mime-type
        if (staticFileInformation.mimeType === "unknown") {
          errorMsg = "Unknown file type!";
          this.setState({ errorMsg });
          return;
        }
        // assign loaded image size
        staticFileInformation.fileSize = fileSize;
        // assign loaded image (binary image content)
        staticFileInformation.fileBinaryContent = targetEvent["result"];
        // generate a unique name for image
        const generatedFileName: string = this.generateImageFileName(
          staticFileInformation.mimeType
        );
        // assign new name for uploaded image
        staticFileInformation.fileName = generatedFileName;
      };
      this.setState({
        staticFileInformation,
        showImage: true,
        errorMsg: "",
      });
    }
  }

  /**
   * callback invoked when image is loaded in avatar editor canvas
   * @param {*} loadedImageData
   * @memberof MediaImageSection
   */
  onLoadImage(loadedImageData: any): void {
    const defaultAvatarSetting = this.getDefaultAvatarSetting();

    if (this.props.avatarProps && this.props.avatarProps.fitToImageSize) {
      defaultAvatarSetting.editorWidth = loadedImageData.width;
      defaultAvatarSetting.editorHeight = loadedImageData.height;
    }

    this.setState({
      avatarEditor: defaultAvatarSetting,
    });
  }

  /**
   * callback invoked when a change on position occurs on cropping boarder
   * @param {{ x: number, y: number }} borderPosition
   * @memberof MediaImageSection
   */
  onPositionChange(borderPosition: { x: number; y: number }): void {
    const avatarEditor = this.state.avatarEditor;
    avatarEditor.croppingPosition = borderPosition;
    this.setState({
      avatarEditor,
    });
  }

  /**
   * A function which reverts the changes to the image and restores the original one.
   */
  onRevert(): void {
    // // console.log("[onRevert] attempt to revert all manipulation");
    const avatarEditor: {
      zoomValue: number;
      rotateValue: number;
      croppingPosition: {
        x: number;
        y: number;
      };
      borderSize: number;
      borderRadius: number;
      editorHeight: number;
      editorWidth: number;
    } = this.getDefaultAvatarSetting();

    this.setState({
      avatarEditor,
    });
  }

  /**
   * A function which straightens the image.
   *
   * @type {function(event:React.ChangeEvent)}
   */
  onRotate: (event: React.ChangeEvent) => void = (event: any) => {
    const rotationAngel: number = Number(event.target.value);
    const avatarEditor = this.state.avatarEditor;
    avatarEditor.rotateValue = rotationAngel;
    this.setState({
      avatarEditor,
    });
  };

  /**
   * A function which is called after a given file is selected to handle displaying the file
   *
   * @type {function(event:React.ChangeEvent)}
   */
  onImageChange() {
    // TODO
  }

  /**
   * A function which zooms into the image; triggered on dragging the zoom range input
   *
   * @type {function(event:React.ChangeEvent)}
   */
  onZoom: (event: React.ChangeEvent) => void = (event: any) => {
    const zoomValue: number = Number(event.target.value);
    const avatarEditor = this.state.avatarEditor;
    avatarEditor.zoomValue = zoomValue;
    this.setState({
      avatarEditor,
    });
  };

  /**
   * A function used to set default parameter's to avatar
   * editor setting
   */
  getDefaultAvatarSetting(): {
    zoomValue: number;
    rotateValue: number;
    croppingPosition: {
      x: number;
      y: number;
    };
    borderSize: number;
    borderRadius: number;
    editorHeight: number;
    editorWidth: number;
  } {
    return {
      zoomValue: 1,
      rotateValue: 0,
      croppingPosition: {
        x: 0.5,
        y: 0.5,
      },
      borderSize: 2,
      borderRadius: 0,
      editorHeight: EDITOR_HEIGHT,
      editorWidth: EDITOR_WIDTH,
    };
  }

  /**
   *
   */
  generateImageFileName(imageMimeType: string): string {
    // generate a unique name for image
    const generatedFileName: string = `${getCleanUuid()}.${
      MEDIA_TYPE_TO_EXTENSION[imageMimeType]
    }`;
    return generatedFileName;
  }

  /**
   * A function used to extract manipulated image from avatar-editor canvas
   *
   */
  onExtractManipulateImage(): void {

    console.log(this.isDefaultImage());
    debugger;
    if (!this.isDefaultImage()) {
      // validate current reference to avatar editor
      if (this.avatarEditorRef.current) {
        // extract current static file status
        const staticFileInformation: StaticFile = this.state
          .staticFileInformation;
        // This returns a HTMLCanvasElement,
        // it can be made into a data URL or a blob
        const canvas: HTMLCanvasElement = this.avatarEditorRef.current.getImage();
        // define blob to extract manipulate image
        let manipulatedImage: Blob;
        // attempt to convert manipulate image to blob
        canvas.toBlob((blob) => {
          // assign extracted blob
          manipulatedImage = blob;

          // attempt to create in-memory file to pass it to avatar editor
          const fileObject: File = new File(
            [blob],
            staticFileInformation.fileName
          );
          console.log(fileObject);
          // assign binary content to ease upload
          staticFileInformation.fileBinaryContent = manipulatedImage;

          staticFileInformation.imageObject = fileObject;
          if (staticFileInformation.fileSize <= 0) {
            staticFileInformation.fileSize = manipulatedImage.size;
          }

          staticFileInformation.mimeType = manipulatedImage.type;

          staticFileInformation.fileName = this.generateImageFileName(
            manipulatedImage.type
          );
          // upon extraction success update current static file state.
          this.setState(
            {
              staticFileInformation,
              // after state update pass a callback to upload manipulated image.
            },
            () => {
              // submit current manipulated image to provided submit function.
              this.props.onSubmit(
                this.state.staticFileInformation,
                this.props.onUploadSuccess
              );
            }
          );
        }, staticFileInformation.mimeType);
      }
    } else {
      this.props.onSubmit(undefined, this.props.onUploadSuccess);
    }
  }

  /**
   * Function used to reset component attributes
   *
   * @memberof MediaImageSection
   */
  resetComponent(): void {
    let staticFileInformation: StaticFile = this.state.staticFileInformation;
    const defaultImage = this.getDefaultImage().imageObject;

    staticFileInformation = {
      fileBinaryContent: undefined,
      imageObject: defaultImage,
      fileSize: -1,
      fileName: "",
      mimeType: "",
    };

    this.setState({
      staticFileInformation,
      previewUrl: "",
      showImageSection: false,
    });
    this.onRevert();
  }

  /**
   *
   *
   * @memberof MediaImageSection
   */
  onCancel() {
    if (this.props.onCancel !== undefined && this.props.onCancel !== null) {
      this.props.onCancel();
    }
  }

  /**
   *
   *
   * @memberof MediaImageSection
   */
  collapseImageSection() {
    this.setState({ showImageSection: true });
    // $(".media-image-section").addClass("animateIn");
  }

  /**
   *
   *
   * @memberof MediaImageSection
   */
  closeImageSection() {
    this.setState({ showImageSection: false });
    // $(".media-image-section")
    //   .removeClass("animateIn")
    //   .addClass("animateOut");
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered list.
   */
  render(): React.ReactNode {
    return (
      <div
        className={
          this.props.isBackground
            ? "background-section col-12 col-md-6 col-lg-6"
            : "background-section"
        }
      >
        {this.props.isBackground ? (
          <div className="background">
            {this.state.showImage ? (
              <div className="col-6 col-md-6 col-lg-6">
                <div className="bg-img" onClick={(e) => this.props.onSelect(e)}>
                  <AVATAREDITOR
                    crossOrigin="anonymous"
                    ref={this.avatarEditorRef}
                    image={this.state.staticFileInformation.imageObject}
                    className={this.props.isSelected ? "selected" : ""}
                    color={[255, 255, 255, 0.6]}
                    border={null}
                    borderRadius={null}
                    onLoadSuccess={this.onLoadImage}
                    width={150}
                    height={150}
                  />
                </div>
              </div>
            ) : null}
            <div className="col-6 col-md-6 col-lg-6">
              <div className="add-img" onClick={(e) => this.props.onSelect(e)}>
                <input
                  type="file"
                  className="custom-file-input"
                  accept={ACCEPT_IMAGE_FORMATS}
                  id={this.inputComponentsIds.get(CUSTOM_IMAGE_SELECT)}
                  onChange={(event) => this.onSelectFile(event)}
                />
                {ADDIMAGE}
                <span>ADD COVER IMAGE</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12 col-md-12 col-lg-12 no-padding">
            {!this.state.showImageSection && !this.props.editMode ? (
              <RdvButton
                isOutline={true}
                extraClassName="add-image-btn"
                onClick={this.collapseImageSection}
              >
                {addImageButton}{" "}
                {this.props.formType === GRAPHQL_TYPE_ENTITY
                  ? "ADD Company Logo"
                  : this.props.formType === GRAPHQL_TYPE_PERSON
                  ? "ADD Your Profile IMAGE"
                  : this.props.formType === GRAPHQL_TYPE_PODCAST_STORY
                  ? "ADD VIDEO IMAGE"
                  : this.props.formType === GRAPHQL_TYPE_ARTICLE_STORY
                  ? "ADD ARTICLE IMAGE"
                  : this.props.formType === GRAPHQL_TYPE_BOOK_STORY
                  ? "ADD BOOK IMAGE"
                  : this.props.formType === GRAPHQL_TYPE_ONLINE_COURSE_STORY
                  ? "ADD ONLINE COURSE IMAGE"
                  : this.props.formType === GRAPHQL_TYPE_RADIVISION_POST
                  ? "ADD POST IMAGE"
                  : "ADD Image"}
              </RdvButton>
            ) : (
              <div className="media-image-section">
                <h6 className="img-header">
                  {addImageButton}
                  {this.props.titleText}
                  <div className="close-btn">
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 72 72"
                      onClick={this.closeImageSection}
                    >
                      <title>close-sm</title>
                      <path
                        className="cls-1"
                        d="M46.77,36l23-23A7.62,7.62,0,0,0,59,2.24l-23,23-23-23A7.62,7.62,0,0,0,2.23,13l23,23-23,23A7.62,7.62,0,0,0,13,69.78l23-23,23,23A7.62,7.62,0,0,0,69.77,59Z"
                      />
                    </svg>
                  </div>
                </h6>
                {this.state.errorMsg ? (
                  <p className="alert alert-danger">{this.state.errorMsg}</p>
                ) : null}
                <div className="form-group row space-divider">
                  <div className="col-sm-6 col-6">
                    <AVATAREDITOR
                      crossOrigin="anonymous"
                      ref={this.avatarEditorRef}
                      image={this.state.staticFileInformation.imageObject}
                      color={[255, 255, 255, 0.6]}
                      border={
                        this.props.avatarProps &&
                        this.props.avatarProps.border !== undefined
                          ? this.props.avatarProps.border
                          : this.state.avatarEditor.borderSize
                      }
                      borderRadius={
                        this.props.avatarProps &&
                        this.props.avatarProps.borderRadius !== undefined
                          ? this.props.avatarProps.borderRadius
                          : this.state.avatarEditor.borderRadius
                      }
                      position={this.state.avatarEditor.croppingPosition}
                      rotate={this.state.avatarEditor.rotateValue}
                      scale={this.state.avatarEditor.zoomValue}
                      onLoadSuccess={this.onLoadImage}
                      onPositionChange={this.onPositionChange}
                      width={this.state.avatarEditor.editorWidth}
                      height={this.state.avatarEditor.editorHeight}
                    />
                    {/* <p>Drag to reposition image</p> */}
                  </div>
                  <div className="col-sm-6 col-6">
                    <div className="form-group row">
                      <div className="col-12">
                        <label
                          className="has-float-label top"
                          htmlFor={this.inputComponentsIds.get(ADD_IMAGE_LINK)}
                        >
                          <input
                            type="url"
                            className="form-control"
                            id={this.inputComponentsIds.get(ADD_IMAGE_LINK)}
                            value={this.state.previewUrl}
                            onChange={this.handlePreviewChange}
                            placeholder="Link to Image"
                          />
                          <span>Image Link</span>
                        </label>
                      </div>
                    </div>
                    <div className="custom-file add-image-btn">
                      <input
                        type="file"
                        className="custom-file-input"
                        accept={ACCEPT_IMAGE_FORMATS}
                        id={this.inputComponentsIds.get(CUSTOM_IMAGE_SELECT)}
                        onChange={(event) => this.onSelectFile(event)}
                      />
                      <label
                        className="custom-file-label btn-radivision add-image-btn"
                        htmlFor={this.inputComponentsIds.get(
                          CUSTOM_IMAGE_SELECT
                        )}
                      >
                        OR / Add custom image
                      </label>
                    </div>
                    <label className="col-form-label" htmlFor="zoom">
                      Zoom
                    </label>
                    <input
                      type="range"
                      className="form-control-range custom-range"
                      value={this.state.avatarEditor.zoomValue}
                      min="1"
                      max="3"
                      step="0.1"
                      id="zoom"
                      onChange={(event) => this.onZoom(event)}
                    />
                    <label className="col-form-label" htmlFor="straighten">
                      Straighten
                    </label>
                    <input
                      type="range"
                      className="form-control-range custom-range"
                      value={this.state.avatarEditor.rotateValue}
                      min="0"
                      max="270"
                      step="10"
                      id="straighten"
                      onChange={(event) => this.onRotate(event)}
                    />

                    <RdvButton
                      isOutline={true}
                      extraClassName="outline-light btn-block revertDefault"
                      text="Revert to default"
                      onClick={this.onRevert}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
