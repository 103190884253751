/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-17
 * @description Implementation of the GraphQL mutation to create html document.
 * @filename create-html-document-template.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an html document.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_HTML_DOCUMENT_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createHtmlDocumentMutation($input: CreateHtmlDocumentInput!) {
    createHtmlDocument(input: $input) {
      clientMutationId
      htmlDocument {
        __typename
        id
        label
        description
        panels {
          id
          component {
            id
            key
          }
        }
      }

      hasErrors
    }
  }
`;
