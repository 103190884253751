/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type suggestionPageQueryVariables = {
    readonly id: string;
};
export type suggestionPageQueryResponse = {
    readonly suggestion: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly suggestion: {
            readonly __typename: string;
            readonly content: string | null;
            readonly created: unknown | null;
            readonly id: string;
            readonly isDeleted: boolean | null;
            readonly person: {
                readonly id: string;
            } | null;
            readonly revision: string | null;
        } | null;
    } | null;
};
export type suggestionPageQuery = {
    readonly response: suggestionPageQueryResponse;
    readonly variables: suggestionPageQueryVariables;
};



/*
query suggestionPageQuery(
  $id: ID!
) {
  suggestion(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    suggestion {
      __typename
      content
      created
      id
      isDeleted
      person {
        id
      }
      revision
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "suggestion",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "SuggestionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "suggestion",
        "storageKey": null,
        "args": null,
        "concreteType": "Suggestion",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "content",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isDeleted",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "person",
            "storageKey": null,
            "args": null,
            "concreteType": "Person",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "suggestionPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "suggestionPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "suggestionPageQuery",
    "id": null,
    "text": "query suggestionPageQuery(\n  $id: ID!\n) {\n  suggestion(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    suggestion {\n      __typename\n      content\n      created\n      id\n      isDeleted\n      person {\n        id\n      }\n      revision\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '1024af773c89da098758e2fb3e4ac956';
export default node;
