/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-11-11
 * @description Implementation of the GraphQL query to retrieve the versioning of recordss.
 * @filename versioning-query.tsx
 */
import {
  graphql,
  GraphQLTaggedNode,
  createFragmentContainer as _createFragmentContainer
} from "react-relay";

/**
 * The GraphQL query to retrieve the current user.
 *
 * @type {GraphQLTaggedNode}
 */
export const VERSIONING_QUERY: GraphQLTaggedNode = graphql`
  query versioningQuery {
    versioning {
      hasErrors
      errors {
        id
        location
      }
      version {
        item {
          __typename
          ... on ArticleStory {
            id
            revision
            title
            created
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
          }
          ... on BookStory {
            id
            revision
            title
            created
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
          }
          ... on OnlineCourseStory {
            id
            link
            revision
            publishedDate
            publisher
            description
            created
            label
            isDeleted
            isNew
            tags {
              id
              label
            }
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
            subTitle
            title
          }
          ... on OriginalContentStory {
            id
            revision
            externalId
            durationInSeconds
            publishedDate
            description
            created
            label
            isDeleted
            isNew
            landingPageUrl
            tags {
              id
              label
            }
            mediaItem {
              id
              files {
                id
                heightInPixels
                widthInPixels
                name
                path
              }
            }
            peopleEntities {
              entity {
                __typename
                description
                hasPublicPage
                landingPageUrl
                id
                name
                websiteUrl
                locations {
                  id
                  label
                }
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
              }
              person {
                __typename
                hasPublicPage
                landingPageUrl
                description
                id
                fullName
                locations {
                  id
                  label
                }
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                responsibility
                revision
              }
            }
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
            subTitle
            title
          }
          ... on OnlineCourseStory {
            id
            link
            revision
            publishedDate
            publisher
            description
            created
            label
            isDeleted
            isNew
            tags {
              id
              label
            }
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
            subTitle
            title
          }
          ... on NewsletterStory {
            id
            revision
            link
            timeToReadInSeconds
            publisher
            publishedDate
            description
            created
            label
            cost
            isDeleted
            isNew
            tags {
              id
              label
            }
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
            subTitle
            title
          }
          ... on PodcastStory {
            id
            revision
            link
            publisher
            durationInSeconds
            publishedDate
            description
            created
            label
            isDeleted
            isNew
            tags {
              id
              label
            }
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
            subTitle
            title
          }
          ... on Entity {
            description
            hasPublicPage
            id
            name
            created
            landingPageUrl
            previews(kinds: [SQUARE, LOGO]) {
              count
              edges {
                cursor
                preview {
                  mediaAsset {
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                  link
                  kind
                }
              }
            }
          }
          ... on Person {
            hasPublicPage
            id
            fullName
            created
            previews(kinds: [SQUARE, HEADSHOT]) {
              count
              edges {
                cursor
                preview {
                  mediaAsset {
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                  link
                  kind
                }
              }
            }
            landingPageUrl
            responsibility
          }
        }
        versions {
          count
          versionList {
            __typename
            ... on ArticleStory {
              id
              revision
              title
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
            }
            ... on BookStory {
              id
              revision
              title
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
            }
            ... on OnlineCourseStory {
              id
              link
              revision
              publishedDate
              publisher
              description
              created
              label
              isDeleted
              isNew
              tags {
                id
                label
              }
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
              subTitle
              title
            }
            ... on OriginalContentStory {
              id
              revision
              externalId
              durationInSeconds
              publishedDate
              description
              created
              label
              isDeleted
              isNew
              landingPageUrl
              tags {
                id
                label
              }
              mediaItem {
                id
                files {
                  id
                  heightInPixels
                  widthInPixels
                  name
                  path
                }
              }
              peopleEntities {
                entity {
                  __typename
                  description
                  hasPublicPage
                  landingPageUrl
                  id
                  name
                  websiteUrl
                  locations {
                    id
                    label
                  }
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        kind
                        mediaAsset {
                          files {
                            id
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                            label
                          }
                        }
                      }
                    }
                  }
                }
                person {
                  __typename
                  hasPublicPage
                  landingPageUrl
                  description
                  id
                  fullName
                  locations {
                    id
                    label
                  }
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        kind
                        mediaAsset {
                          files {
                            id
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                            label
                          }
                        }
                      }
                    }
                  }
                  responsibility
                  revision
                }
              }
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
              subTitle
              title
            }
            ... on OnlineCourseStory {
              id
              link
              revision
              publishedDate
              publisher
              description
              created
              label
              isDeleted
              isNew
              tags {
                id
                label
              }
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
              subTitle
              title
            }
            ... on NewsletterStory {
              id
              revision
              link
              timeToReadInSeconds
              publisher
              publishedDate
              description
              created
              label
              cost
              isDeleted
              isNew
              tags {
                id
                label
              }
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
              subTitle
              title
            }
            ... on PodcastStory {
              id
              revision
              link
              publisher
              durationInSeconds
              publishedDate
              description
              created
              label
              isDeleted
              isNew
              tags {
                id
                label
              }
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
              subTitle
              title
            }
            ... on Entity {
              description
              hasPublicPage
              id
              name
              created
              landingPageUrl
              previews(kinds: [SQUARE, LOGO]) {
                count
                edges {
                  cursor
                  preview {
                    mediaAsset {
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                    link
                    kind
                  }
                }
              }
            }
            ... on Person {
              hasPublicPage
              id
              fullName
              created
              previews(kinds: [SQUARE, HEADSHOT]) {
                count
                edges {
                  cursor
                  preview {
                    mediaAsset {
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                    link
                    kind
                  }
                }
              }
              landingPageUrl
              responsibility
            }
          }
        }
      }
    }
  }
`;
