/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Initial page component
 * @filename InitialPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";

import { TagPageFormInput } from "../../../component-configuration/cms-pages";
import { Tag as GraphTag, GRAPHQL_TYPE_TAG } from "@radivision/graphql";
import { Tag } from "../../../utilities/cms-operation/tag";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import { Validator } from "../../../utilities/cms-operation/validator";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import { Loader } from "../../page/loader";
import { FloatingMenu } from "./floating-button/floating-menu";
import ModalConductor from "./modal/modal-conductor";

/**
 *
 *
 * @export
 * @interface TagPageProps
 */
export interface TagPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof posterPageProps
   */
  string?: string;

  /**
   *
   *
   * @type {string}
   * @memberof posterPageProps
   */
  graphTag?: GraphTag;
}

/**
 *
 *
 * @interface TagPageState
 */
interface TagPageState {
  /**
   *
   *
   * @type {PosterPageFormInput}
   * @memberof posterPageState
   */
  formInput: TagPageFormInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof posterPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof posterPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof posterPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class TagPage
 * @extends {React.Component<TagPageProps, TagPageState>}
 */
export default class TagPage extends React.Component<
  TagPageProps,
  TagPageState
> {
  /**
   * Creates an instance of TagPage.
   * @param {TagPageProps} props
   * @memberof TagPage
   */
  constructor(props: TagPageProps) {
    super(props);

    //... bind needed function to class
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleIsGraphChanges = this.handleIsGraphChanges.bind(this);
    this.handleDescriptionChanges = this.handleDescriptionChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    /// handle provided props in case of edit already added tag
    this.state = this.handleComponentStateInitialize(this.props.graphTag);
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @param {posterStory} tagProps
   * @returns {posterPageState}
   * @memberof posterPage
   */
  handleComponentStateInitialize(tagProps: GraphTag): TagPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: TagPageFormInput = {
      id: undefined,
      label: "",
      description: "",
      isDeleted: false,
      isGraph: false
    };

    let state: TagPageState = {
      modal,
      formInput,
      isLoading: false,
      errorMessage: new Map()
    };

    if (tagProps !== null && tagProps !== undefined) {
      if (tagProps.id) {
        state.formInput.id = tagProps.id;
      }

      if (tagProps.label) {
        state.formInput.label = tagProps.label;
      }

      if (tagProps.description) {
        state.formInput.description = tagProps.description;
      }

      if (tagProps.isDeleted) {
        state.formInput.isDeleted = tagProps.isDeleted;
      }

      if (tagProps.isGraphQlType) {
        state.formInput.isGraph = tagProps.isGraphQlType;
      }

      if (tagProps.revision) {
        state.formInput.revision = tagProps.revision;
      }
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof TagPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title"> Manage Tag</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Label</label>
                  <input
                    type="text"
                    className={`form-control ${
                      this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.label}
                    name="label"
                    onChange={this.handleLabelChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("LABEL")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label className="">Description</label>
                  <input
                    type="text"
                    className={`form-control ${
                      this.state.errorMessage.get("DESCRIPTION")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.formInput.description}
                    name="description"
                    onChange={this.handleDescriptionChanges}
                  />
                </div>
              </div>
            </div>

            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="form-control readonly"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="form-control readonly"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}

            {/* Story Status */}
            <div className="col mt-3">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>

            <div className="col mt-3">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={this.handleIsGraphChanges}
                  checked={this.state.formInput.isGraph}
                  readOnly
                />
                <label className="custom-control-label">Is isGraph</label>
              </div>
            </div>
            <Loader isActive={this.state.isLoading} />
            <ModalConductor
              currentModal={this.state.modal.modalName}
              ConfirmModalProps={{
                title: this.state.modal.modalTile,
                body: this.state.modal.modalBody,
                closeAction: this.state.modal.modalCloseAction
              }}
              close={() => {}}
            />

            <FloatingMenu
              menuItems={{
                revertible: this.revertPageInput,
                deleteAble: {
                  deleteAction: () => {
                    let formInput = this.state.formInput;
                    formInput.isDeleted = true;
                    this.setState({ formInput });
                  },
                  unDeleteAction: () => {
                    let formInput = this.state.formInput;
                    formInput.isDeleted = false;
                    this.setState({ formInput });
                  }
                },
                saveable: this.handleSaveAction
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof TagPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: TagPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof TagPage
   */
  handleDescriptionChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: TagPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.description = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {*} event
   * @memberof TagPage
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: TagPageFormInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    // // console.log("[handleIsDeletedChanges] is article deleted", isDeleted);
    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {*} event
   * @memberof TagPage
   */
  handleIsGraphChanges(event: any): void {
    let formInput: TagPageFormInput = this.state.formInput;

    let isGraph: boolean = event.target.checked ? true : false;

    // // console.log("[handleIsGraphChanges] is article deleted", isDeleted);
    formInput.isGraph = isGraph;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling submitting new tag
   *
   * @memberof posterPage
   */
  handleSaveAction(): void {
    let posterInput: TagPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();

      // validate errors
      errorMessage = Validator.validateTagInput(posterInput.formInput);

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (this.props.string !== null && this.props.string !== undefined) {
          internalPromise = Tag.updateTag(posterInput.formInput).then(
            (results: any) => {
              // ... handle mutation success
              // console.log(results);
              modal.modalName = "ConfirmModal";
              modal.modalBody =
                CMS_PAGE_CONSTANT["TAG"].message.confirmation.update;
              modal.modalTile = "Action Complete";
              this.revertPageInput(results);
            }
          );
        } else {
          internalPromise = Tag.addTag(posterInput.formInput).then(
            (results: any) => {
              // ... handle mutation success
              // console.log(results);
              modal.modalName = "ConfirmModal";
              modal.modalBody =
                CMS_PAGE_CONSTANT["TAG"].message.confirmation.add;
              modal.modalTile = "Action Complete";
              this.revertPageInput();
            }
          );
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["TAG"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof posterPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }

  /**
   *
   *
   * @memberof posterPage
   */
  revertPageInput(input?: GraphTag): void {
    let state: TagPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.graphTag
    );
    this.setState(state);
  }

  /**
   *
   *
   * @memberof posterPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }
}

export class TagPageContainer extends React.Component<
  TagPageProps,
  TagPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let tagId: string;

    // console.log("[TagPageContainer] provided props", this.props);

    if (this.props.string === null || this.props.string === undefined) {
      node = <TagPage />;
    } else {
      tagId = this.props.string;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query tagPageQuery($id: ID!) {
              tag(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                tag {
                  __typename
                  id
                  description
                  label
                  isDeleted
                  isGraphQlType
                  revision
                }
              }
            }
          `}
          variables={{
            id: tagId
          }}
          render={({ error, props }) => {
            if (error) {
              // console.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console.log(props);
              if (props.tag.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (props.tag.tag.__typename !== GRAPHQL_TYPE_TAG) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Graph id, {tagId} belongs to type{" "}
                      {props.tag.tag.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.tag.tag) {
                let pageProps: TagPageProps = {
                  ...this.props,
                  graphTag: props.tag.tag
                };
                return <TagPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
