/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @description Implementation of the HtmlDocumentPanels react component and declaration of its related interfaces.
 * @Date: 2018-03-08
 * @filename html-page-panels.tsx
 */
import { HtmlDocumentPanel as GraphQlHtmlDocumentPanel } from "@radivision/graphql/lib/ts/graphql/html-document-panel";
import React from "react";
import { createFragmentContainer as _createFragmentContainer, graphql } from "react-relay";
import { RelayProps } from "../../relay/relay-props";
import DynamicComponent from "./dynamic-component";

/**
 * The props of the HtmlPagePanels class.
 *
 * @export
 * @extends {RelayProps}
 * @interface HtmlPagePanelsProps
 */
export interface HtmlPagePanelsProps extends RelayProps {
  /**
   * The collection of panels in the page.
   *
   * @type {Array<HtmlDocumentPanelInterface>}
   */
  panels: GraphQlHtmlDocumentPanel[];
}

/**
 *  A React component that renders a control in an HTML panel.
 *
 * @class HtmlDocumentPanel
 * @extends {React.Component<HtmlPagePanelsProps, HtmlPagePanelsState>}
 */
class HtmlPagePanels extends React.Component<HtmlPagePanelsProps, {}> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return this.props.panels.map(
      (panel: GraphQlHtmlDocumentPanel, index: number) => {
        return (
          <DynamicComponent
            key={index}
            component={panel.component}
            properties={panel.properties}
          />
        );
      }
    );
  }
}

/**
 * Returns a relay fragment container created from the panel and a GraphQL fragment.
 */
export default _createFragmentContainer(HtmlPagePanels, {
  panels: graphql`
    fragment htmlPagePanels_panels on HtmlDocumentPanel @relay(plural: true) {
      component {
        ...dynamicComponent_component
      }
      properties {
        name
        article {
          id
        }
        book {
          id
        }
        boolean
        dynamicList {
          id
        }
        entity {
          id
        }
        fixedList {
          id
        }
        id
        int
        json
        listCollection {
          id
        }
        name
        newsletter {
          id
        }
        number
        onlineCourse {
          id
        }
        originalContent {
          id
        }
        person {
          id
        }
        podcast {
          id
        }
        poster {
          id
        }
        event {
          id
        }
        string
      }
    }
  `
});
