import * as React from "react";
import $ from"jquery";

interface IAddAuthor {
  addAuthor({ author }: any): any;
}
interface IAuthorData {
  authorName: any;
  authorImageUrl: string;
  authorLink: any;
}
export default class AuthorAdd extends React.Component<
  IAddAuthor,
  IAuthorData
  > {
  constructor(props: IAddAuthor) {
    super(props);

    this.state = {
      authorImageUrl: "",
      authorLink: "",
      authorName: ""
    };
  }
  
   handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.addAuthor(this.state);
    this.setState({
      authorImageUrl: "",
      authorLink: "",
      authorName: ""
    });
  };

   handleChange = (e: any) => {

    this.setState({
      authorImageUrl: $("#authorImageurl")
        .val()
        .toString(),
      authorLink: $("#authorLink")
        .val()
        .toString(),
      authorName: $("#authorName")
        .val()
        .toString()
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>Add an author</label>
        <label>Name</label>
        <input
          id="authorName"
          type="text"
          className="validate form-control"
          onChange={this.handleChange}
          value={this.state.authorName}
        />

        <label>Link</label>
        <input
          id="authorLink"
          type="text"
          className="validate form-control"
          onChange={this.handleChange}
          value={this.state.authorLink}
        />
        <label>Photo</label>
        <input
          id="authorImageurl"
          type="file"
          className="validate form-control"
          onChange={this.handleChange}
          value={this.state.authorImageUrl}
        />
        <div className="row center">
          <div className="input-field col s2">
            <button className="btn btn-radivision" type="submit">
              Add
              {/* <i className="material-icons right">send</i> */}
            </button>
          </div>
        </div>
      </form>
    );
  }
}
