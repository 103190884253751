/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-07
 * @description Implementation of newsletter utilities to be used in the app.
 * @filename newsletter.ts
 */

import { CreateNewsletterStoryInput, CreateStoryInput, UpdateNewsletterStoryInput, UpdateStoryInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { NewsLetterPageFormInput } from "../component-configuration/cms-pages";
import { CREATE_NEWSLETTER_GRAPHQL_MUTATION } from "../relay/mutations/create-newsletter-story";
import { UPDATE_NEWSLETTER_STORY_GRAPHQL_MUTATION } from "../relay/mutations/update-newsletter-story";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { Story } from "./cms-operation/story";
import { commitMutation } from "./relay";

export class Newsletter {
  /**
   * Returns a promise to add new article to database.
   *
   * @param {ArticlePageState} newsletterInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addNewsletter(
    newsletterInput: NewsLetterPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateArticleStoryInput
    let createNewsletterStoryInput: CreateNewsletterStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: CreateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      newsletterInput.link === undefined ||
      newsletterInput.link === null ||
      newsletterInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("Evac2J7xxEGmOtZz45L8Pw"));
    } else {
      storyInput = Story.createStoryInput(newsletterInput);
      // initialize create article graphQl input

      delete storyInput.publishedDate;

      createNewsletterStoryInput = {
        ...storyInput,
        link: encodeURI(newsletterInput.link.trim()),
        timeToReadInSeconds: newsletterInput.timeToReadInSeconds,
        cost:
          newsletterInput.cost && newsletterInput.cost.length > 0
            ? newsletterInput.cost.trim()
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_NEWSLETTER_GRAPHQL_MUTATION,
        variables: {
          input: createNewsletterStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createNewsletterStory.hasErrors === false
          ? Promise.resolve(mutationResults.createNewsletterStory.story)
          : Promise.reject(mutationResults.createNewsletterStory.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updateNewsletter(
    newsletterInput: NewsLetterPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let updateNewsletterStoryInput: UpdateNewsletterStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: UpdateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      newsletterInput.link === undefined ||
      newsletterInput.link === null ||
      newsletterInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("B84NqrjyHEyrKjX9oyrZVQ"));
    } else {
      storyInput = Story.updateStoryInput(newsletterInput);

      // initialize create article graphQl input
      updateNewsletterStoryInput = {
        ...storyInput,
        link: encodeURI(newsletterInput.link.trim()),
        timeToReadInSeconds: newsletterInput.timeToReadInSeconds,
        cost:
          newsletterInput.cost && newsletterInput.cost.length > 0
            ? newsletterInput.cost.trim()
            : undefined
      };

      // console.log("[updateNewsletter] mutation input ", JSON.stringify(updateNewsletterStoryInput));

      // initialize mutation configuration
      config = {
        mutation: UPDATE_NEWSLETTER_STORY_GRAPHQL_MUTATION,
        variables: {
          input: updateNewsletterStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateNewsletterStory.hasErrors === false
          ? Promise.resolve(mutationResults.updateNewsletterStory.story)
          : Promise.reject(mutationResults.updateNewsletterStory.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteNewsletter(
    newsletterInput: NewsLetterPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
