/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ContentModerationStateKind = "APPROVED" | "BANNED" | "TO_BE_APPROVED" | "%future added value";
export type CreateOnlineCourseStoryInput = {
    readonly authors?: ReadonlyArray<string> | null;
    readonly badges?: ReadonlyArray<string> | null;
    readonly clientMutationId: string;
    readonly cost?: string | null;
    readonly description?: string | null;
    readonly entities?: ReadonlyArray<string> | null;
    readonly label?: string | null;
    readonly link?: string | null;
    readonly moderation?: ContentModerationStateKind | null;
    readonly people?: ReadonlyArray<CreateEntityPersonInput> | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly publishedDate?: unknown | null;
    readonly publisher?: string | null;
    readonly subTitle?: string | null;
    readonly summary?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly timeRequiredInSeconds?: number | null;
    readonly title?: string | null;
};
export type CreateEntityPersonInput = {
    readonly entity?: string | null;
    readonly person?: string | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type createOnlineCourseStoryMutationVariables = {
    readonly input: CreateOnlineCourseStoryInput;
};
export type createOnlineCourseStoryMutationResponse = {
    readonly createOnlineCourseStory: {
        readonly clientMutationId: string | null;
        readonly story: ({
            readonly id?: string;
            readonly title?: string | null;
            readonly publishedDate?: unknown | null;
            readonly timeRequiredInSeconds?: number | null;
            readonly publisher?: string | null;
            readonly description?: string | null;
        } & ({
            readonly id: string;
            readonly title: string | null;
            readonly publishedDate: unknown | null;
            readonly timeRequiredInSeconds: number | null;
            readonly publisher: string | null;
            readonly description: string | null;
        } | {
            /*This will never be '% other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        })) | null;
        readonly hasErrors: boolean | null;
    } | null;
};
export type createOnlineCourseStoryMutation = {
    readonly response: createOnlineCourseStoryMutationResponse;
    readonly variables: createOnlineCourseStoryMutationVariables;
};



/*
mutation createOnlineCourseStoryMutation(
  $input: CreateOnlineCourseStoryInput!
) {
  createOnlineCourseStory(input: $input) {
    clientMutationId
    story {
      __typename
      ... on OnlineCourseStory {
        id
        title
        publishedDate
        timeRequiredInSeconds
        publisher
        description
      }
      ... on Node {
        id
      }
    }
    hasErrors
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateOnlineCourseStoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedDate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timeRequiredInSeconds",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publisher",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createOnlineCourseStoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createOnlineCourseStory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateStoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "story",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "OnlineCourseStory",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          },
          (v9/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createOnlineCourseStoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createOnlineCourseStory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateStoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "story",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "OnlineCourseStory",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          },
          (v9/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "createOnlineCourseStoryMutation",
    "id": null,
    "text": "mutation createOnlineCourseStoryMutation(\n  $input: CreateOnlineCourseStoryInput!\n) {\n  createOnlineCourseStory(input: $input) {\n    clientMutationId\n    story {\n      __typename\n      ... on OnlineCourseStory {\n        id\n        title\n        publishedDate\n        timeRequiredInSeconds\n        publisher\n        description\n      }\n      ... on Node {\n        id\n      }\n    }\n    hasErrors\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '38179f571a44a912a9c0be4af88d2a9f';
export default node;
