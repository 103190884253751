/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-05-11
 * @description Implementation of the GraphQL mutation to add a poster story.
 * @filename update-poster.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an podcast.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_POSTER_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updatePosterMutation($input: UpdatePosterInput!) {
    updatePoster(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      poster {
        __typename
        id
        publishedDate
        description
        actionDescription
        actionTitle
        created
        label
        isDeleted
        tags {
          id
          label
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
        subTitle
        title
        revision
      }
    }
  }
`;
