/**
 * @author Linkon Islam
 * @date   2019-01-14
 * @description mange preview add
 * @filename ManagePreviewAdd.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { PreviewKind } from "@radivision/graphql";
import { MediaImageSection } from "../../../modals/media-image-section";
import { Loader } from "../../../page/loader";
import { ImageHelper } from "../../../../utilities/image-helper";
import {
  ImageUpload,
  MediaAssetFileFunctionInput,
} from "../../../../utilities/image-uploader";

/**
 *
 *
 * @interface ManagePreviewAddProps
 */
interface ManagePreviewAddProps {
  /**
   *
   *
   * @type {string}
   * @memberof ManagePreviewAddProps
   */
  previewKind: PreviewKind;
  /**
   *
   *
   * @type {string}
   * @memberof ManagePreviewAddProps
   */
  previewInfo: string;
  /**
   *
   *
   * @type {Function}
   * @memberof ManagePreviewAddProps
   */
  addItem: Function;
  /**
   *
   *
   * @memberof ManagePreviewAddProps
   */
  closeModal(): void;
}

/**
 *
 *
 * @interface ManagePreviewAddState
 */
export interface ManagePreviewAddState {
  /**
   *
   *
   * @type {("DEFAULT" | "UPLOAD" | "SUCCESS")}
   * @memberof ManagePreviewAddState
   */
  state: "DEFAULT" | "UPLOAD" | "SUCCESS";

  /**
   *
   *
   * @type {string}
   * @memberof ManagePreviewAddState
   */
  previewKind: PreviewKind;
  /**
   *
   *
   * @type {string}
   * @memberof ManagePreviewAddState
   */
  previewInfo: string;

  /**
   *
   *
   * @type {boolean}
   * @memberof ManagePreviewAddState
   */
  loading: boolean;
}

/**
 *
 *
 * @export
 * @class ManagePreviewAdd
 * @extends {React.Component<ManagePreviewAddProps, ManagePreviewAddState>}
 */
export default class ManagePreviewAdd extends React.Component<
  ManagePreviewAddProps,
  ManagePreviewAddState
> {
  /**
   *
   *
   * @type {*}
   * @memberof ManagePreviewAdd
   */
  mediaModalRef: any;

  /**
   * Creates an instance of ManagePreviewAdd.
   * @param {ManagePreviewAddProps} props
   * @memberof ManagePreviewAdd
   */
  constructor(props: ManagePreviewAddProps) {
    super(props);

    this.state = {
      state: "DEFAULT",
      previewKind: this.props.previewKind,
      previewInfo: this.props.previewInfo,
      loading: false,
    };

    this.mediaModalRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.changeState = this.changeState.bind(this);

    this.onSubmitImage = this.onSubmitImage.bind(this);
  }

  changeState(state: ManagePreviewAddState,link:string) {
    this.setState(state,()=>{
      this.mediaModalRef?.current.changeLink(link);
    });
  }

  /**
   *
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @memberof ManagePreviewAdd
   */
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let previewInfo = this.state.previewInfo;
    previewInfo = event.target.value;

    // // console.log(event.target.value);

    this.setState({
      previewInfo,
    });
  }

  /**
   *
   *
   * @memberof ManagePreviewAdd
   */
  handleSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    // // console.log("[handleSelect] select preview kind : ",event.target.value);

    let typedColorString: any;

    typedColorString = event.target.value;

    this.setState({
      previewKind: typedColorString,
    });
  };

  /**
   *
   *
   * @memberof ManagePreviewAdd
   */
  handleAddPreview = () => {
    let preview: any;

    if (
      this.state.previewInfo !== undefined &&
      this.state.previewInfo !== null &&
      this.state.previewInfo.length > 1
    ) {
      preview = {
        ...this.state,
        loading: undefined,
      };
    }
    this.props.addItem(preview);
  };

  /**
   *
   *
   * @returns
   * @memberof ManagePreviewAdd
   */
  render() {
    return (
      <div className="ManagePreviewAdd">
        {this.state.state === "UPLOAD" ? (
          <div>
            <MediaImageSection
              ref={this.mediaModalRef}
              onSubmit={this.onSubmitImage}
              onUploadSuccess={() => {}}
              onCancel={null}
              avatarProps={{
                border: 0,
                borderRadius: 0,
                height: 200,
                width: 200,
                fitToImageSize: true,
              }}
            />

            <div className="modal-footer">
              <div className="row">
                <div className="col-sm-12 col-md-6 text-md-left">
                  <button
                    onClick={() => {
                      this.setState({ state: "DEFAULT" });
                    }}
                    className="btn btn-radivision dark"
                  >
                    Back
                  </button>
                </div>
                <div className="col-sm-12 col-md-6 text-md-right">
                  <button
                    onClick={() => {
                      this.setState({ loading: true });
                      this.mediaModalRef.current.onExtractManipulateImage();
                    }}
                    className="btn btn-radivision dark"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <Loader isActive={this.state.loading} />
          </div>
        ) : (
          <div>
            <div className="ManagePreviewAdd--search Page-Field">
              <input
                type="text"
                placeholder="search"
                className="form-control"
              />
            </div>
            <div className="ManagePreviewAdd--link Page-Field">
              <input
                type="text"
                placeholder="link or media asset id"
                onChange={this.handleChange}
                value={this.state.previewInfo}
                className="form-control"
              />
            </div>
            <div className="ManagePreviewAdd--check Page-Field">
              <label>Preview Kind</label>
            </div>
            <div className="ManagePreviewAdd--select">
              <select
                name="type"
                id=""
                onChange={this.handleSelect}
                value={this.state.previewKind}
                className="form-control mb-3"
              >
                {Object.keys(PreviewKind).map((key: any, index) => {
                  return (
                    <option key={index} value={key.toString()}>
                      {key.toString()}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="ManagePreviewAdd--Action">
              <button
                onClick={this.props.closeModal}
                className="btn btn-radivision dark"
              >
                Cancel
              </button>

              <button
                onClick={this.handleAddPreview}
                className="btn btn-radivision dark"
              >
                Ok
              </button>
              <button
                onClick={() => {
                  this.setState({ state: "UPLOAD" });
                }}
                className="btn btn-radivision dark"
              >
                Upload
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * triggers when user finish editing his profile Image
   * @param staticFileInformation object contains manipulated image needed arguments
   */
  onSubmitImage(
    staticFileInformation: {
      fileBinaryContent: Blob;
      fileSize: number;
      fileName: string;
      mimeType: string;
    },
    onUploadSuccess: Function
  ) {
    let promise: Promise<any>;

    // // console.log("[onSubmitProfileImage] provided input :", staticFileInformation);
    // validate provided media file information before attempt to use it as this object is provided
    // from mediaImageSection components
    if (staticFileInformation !== null && staticFileInformation !== undefined) {
      // promise chain
      // 1) upload file to s3 bucket
      // 2) createMediaAssetFromFile
      // 3) update previews in person input
      // 4) set state to indicate update in person then update user
      // attempt to upload manipulated image to s3 bucket
      promise = ImageUpload.uploadImageToS3(staticFileInformation);
      // attempt to handle upload return data then create media-asset
      promise = promise.then((result: AWS.S3.ManagedUpload.SendData) => {
        // // console.log(
        //   "[onSubmitProfileImage] profile Image uploaded successfully"
        // );
        // // console.log(result);
        let input: MediaAssetFileFunctionInput = {
          attribution: ``,
          description: `The image for `,
          fileName: staticFileInformation.fileName,
          title: `'s Image`,
          fileSize: staticFileInformation.fileSize,
          format: staticFileInformation.mimeType,
        };
        return ImageUpload.createMediaAssetFile(input);
      });
    } else {
      window.scrollTo(0, 0);
      promise = Promise.resolve();
    }

    // attempt to update person's previews with updated media asset
    promise = promise.then(
      (mediaAssetId: string): Promise<void> => {
        // // console.log("[onSubmitProfileImage] media Asset id ", mediaAssetId);

        this.setState({
          state: "SUCCESS",
          previewInfo: mediaAssetId,
        });
        return Promise.resolve();
      }
    );

    promise = promise.catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });

    promise = promise.finally(() => {
      this.setState({
        loading: false,
      });
    });

    return promise;
  }
}
