/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-07-08 03:10
 * @description Implementation of general utilities to be used in the app.
 * @filename general.ts
 */
import { getNewUniqueId as _getNewUniqueId, Types } from "@radivision/common";
import { Badge as GraphQlBadge } from "@radivision/graphql/lib/ts/graphql/badge";
import $ from "jquery";
import { CarouselItem } from "../component-configuration/carousel-item";
import { OptimizedImageUrls } from "../component-configuration/optimized-image";
import { SixByOneGridItem } from "../component-configuration/six-by-one-grid-item";
import { ACTION_MODAL_ID } from "../constants/general-constants";
// import { UPCOMING_SHOWS_QUESTIONNAIRE } from "./questionnaire";
import { ImageHelper } from "./image-helper";
import { List } from "@radivision/graphql/lib/ts/graphql/list";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import {
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  OriginalContentStory,
} from "@radivision/graphql/lib/ts/graphql/original-content-story";
import {
  GRAPHQL_TYPE_SEARCH_HIT,
  SearchHit,
  HitItemKind,
} from "@radivision/graphql/lib/ts/graphql/search-hit";
import {
  GRAPHQL_TYPE_ENTITY,
  Entity,
} from "@radivision/graphql/lib/ts/graphql/entity";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { GRAPHQL_TYPE_ARTICLE_STORY } from "@radivision/graphql/lib/ts/graphql/article-story";
import { GRAPHQL_TYPE_BOOK_STORY } from "@radivision/graphql/lib/ts/graphql/book-story";
import { GRAPHQL_TYPE_NEWSLETTER_STORY } from "@radivision/graphql/lib/ts/graphql/newsletter-story";
import { GRAPHQL_TYPE_ONLINE_COURSE_STORY } from "@radivision/graphql/lib/ts/graphql/online-course-story";
import {
  GRAPHQL_TYPE_PERSON,
  Person,
} from "@radivision/graphql/lib/ts/graphql/person";
import { GRAPHQL_TYPE_PODCAST_STORY } from "@radivision/graphql/lib/ts/graphql/podcast-story";
import { GRAPHQL_TYPE_FIXED_LIST } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { GRAPHQL_TYPE_DYNAMIC_LIST } from "@radivision/graphql/lib/ts/graphql/dynamic-list";
import { GRAPHQL_TYPE_LIST_COLLECTION } from "@radivision/graphql/lib/ts/graphql/list-collection";
import { Questionnaire } from "@radivision/graphql/lib/ts/graphql/questionnaire";
import { MultipleChoiceQuestion } from "@radivision/graphql/lib/ts/graphql/multiple-choice-question";
import { Follows } from "@radivision/graphql/lib/ts/graphql/follows";
import { SocialMediaPlatformKind } from "@radivision/graphql/lib/ts/graphql/social-media-platform";
import { MediaAsset } from "@radivision/graphql/lib/ts/graphql/media-asset";
import { InvestmentByPerson } from "@radivision/graphql/lib/ts/graphql/investment-by-person";
import { Investment } from "@radivision/graphql/lib/ts/graphql/investment";
import { Inspiration } from "@radivision/graphql/lib/ts/graphql/inspiration";
import { Preview } from "@radivision/graphql/lib/ts/graphql/preview";
import { PersonEntity } from "@radivision/graphql/lib/ts/graphql/person-entity";
import { Range } from "@radivision/graphql/lib/ts/graphql/range";

/**
 * An array of alphanumeric characters.
 *
 * @const
 * @type {string}
 */
const ALPHANUMERIC: string =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";

/**
 * Formats a number to a string with given length padded by zeroes.
 *
 * @param {number} value The number.
 *
 * @param {number} length The required length.
 *
 * @returns {string} The number formatted as a string.
 */
function formatInteger(value: number, length: number): string {
  return (value / Math.pow(10, length)).toFixed(length).substr(2);
}

/**
 *
 */
export function getTodayDate(): string {
  return new Date().toISOString().split("T")[0];
}

/**
 * Convert milliseconds to minutes:sec format
 *
 * @param {number} milliseconds The number of milliseconds.
 *
 * @returns {string} A string of format minutes:sec
 */
export function mapMillisecondsToMinutes(milliseconds: number): string {
  let minutes: number = 0;
  let seconds: number = 0;

  if (milliseconds > 1000) {
    seconds = Math.floor(milliseconds / 1000);
    if (seconds >= 60) {
      minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
    }
  }
  return `${formatInteger(minutes, 2)}:${formatInteger(seconds, 2)}`;
}

/**
 * Print time to minutes:sec format
 *
 * @param {number} seconds The number of milliseconds.
 *
 * @returns {string} A string of format minutes:sec
 */
export function printFormattedTime(seconds: number): string {
  let minutes: number = 0;
  let second: number = seconds;
  if (second >= 60) {
    minutes = Math.floor(second / 60);
    second = seconds % 60;
  }
  return `${formatInteger(minutes, 2)}:${formatInteger(second, 2)}`;
}

/**
 * Returns a carousel item populated from an underlying GraphQL item.
 *
 * @param {*} item The GraphQL item.
 * @param {List} list The parent list that the item belongs to.
 * @param {number} index The index of the item in the list.
 * @param {{ containerWidthRatio: number, numberOfItems: number}} requestedImageDimensions The preview image dimensions.
 * @param {PreviewKind} itemPreviewType The Preview Kind of item preview
 * @param {Person} user The current logged in user value
 * @param {boolean} oneTabDetails The for Original Content Story episode details to gather all info in one tab
 * @param {boolean} getDetails return details to the list items or not
 *
 * @returns {CarouselItem} The populated carousel item.
 */
export function getCarouselItem(
  item: any,
  list: List,
  index: number,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
  itemPreviewType: PreviewKind,
  oneTabDetails?: boolean,
  getDetails?: boolean
): CarouselItem {
  let authors: string[];
  let cost: string;
  let description: string;
  let episodeInfo: string[];
  let id: string;
  let link: string;
  let location: string;
  let previewImageUrl: OptimizedImageUrls = {};
  let publishDate: string;
  let publisher: string;
  let summary: string;
  let subtitle: string;
  let timeLabel: string;
  let timeRequiredInSeconds: number;
  let title: string;
  let type: string;
  let video;
  let youTubeVideo;
  let content: string;
  const tags: string[] = [];
  let itemKind: any;
  let permalink: any;
  let websiteUrl: string;

  console.log(item);
  
  if (Types.isObject(item)) {
    if (Types.toString(item.__typename)) {
      type = item.__typename;
      if (item.__typename === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY) {
        video = getVideoLinksFromMediaItem(item.mediaItem);
        youTubeVideo = getVideoLinksFromExternalId(item.externalId);
        episodeInfo = [
          list ? list.name : "",
          `Episode ${index + 1}`,
          mapMillisecondsToMinutes(item.durationInSeconds),
        ];
      }
      if (item.__typename === GRAPHQL_TYPE_SEARCH_HIT) {
        return getCarouselItemFromSearchHit(
          item,
          requestedImageDimensions,
          itemPreviewType
        );
      }
      if (item.__typename === GRAPHQL_TYPE_ENTITY) {
        location = getLocation(item);
        websiteUrl = item.websiteUrl;
      } else if (item.link !== null && item.link !== undefined) {
        link = item.link;
      }
    }
    if (item.previews !== null && item.previews !== undefined) {
      previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: item.previews,
          previewKind: itemPreviewType,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: requestedImageDimensions,
        isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
        revision: item.revision,
      });
    } else {
      previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
        imageType: "DEFAULT",
        default: {
          previewType: itemPreviewType,
          mediaType: TopLevelMediaKind.IMAGE,
          type: item.__typename,
        },
        desiredDimensions: requestedImageDimensions,
        isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
        revision: item.revision,
      });
    }

    if (item.title !== null && item.title !== undefined) {
      title = item.title;
    } else if (item["fullName"] !== null && item["fullName"] !== undefined) {
      title = item["fullName"];
    } else if (item["name"] !== null && item["name"] !== undefined) {
      title = item["name"];
    } else if (item.label !== null && item.label !== undefined) {
      title = item.label;
    }
    if (item.id !== null && item.id !== undefined) {
      id = item.id;
    }
    if (item["subTitle"] !== null && item["subTitle"] !== undefined) {
      description = item["description"];
      subtitle = item["description"];
    } else if (
      item["description"] !== null &&
      item["description"] !== undefined
    ) {
      description = item["description"];
      summary = item["description"];
    }
    if (item["publisher"] !== null && item["publisher"] !== undefined) {
      publisher = item["publisher"];
    }
    if (item["publishedDate"] !== null && item["publishedDate"] !== undefined) {
      publishDate = item["publishedDate"];
    }

    if (item["authors"] !== null && item["authors"] !== undefined) {
      authors = item["authors"];
    }

    if (item["cost"] !== null && item["cost"] !== undefined) {
      cost = item["cost"];
    }

    if (
      item["responsibility"] !== null &&
      item["responsibility"] !== undefined
    ) {
      description = item["responsibility"];
    }

    if (
      item["timeRequiredInSeconds"] !== null &&
      item["timeRequiredInSeconds"] !== undefined
    ) {
      timeRequiredInSeconds = item["timeRequiredInSeconds"];
      timeLabel = item["timeRequiredInSeconds"];
    }

    if (
      item["timeToReadInSeconds"] !== null &&
      item["timeToReadInSeconds"] !== undefined
    ) {
      timeLabel = item["timeToReadInSeconds"];
    }
    if (
      item["durationInSeconds"] !== null &&
      item["durationInSeconds"] !== undefined
    ) {
      timeRequiredInSeconds = item["durationInSeconds"];
      timeLabel = item["durationInSeconds"];
    }

    if (item["content"] !== null && item["content"] !== undefined) {
      content = item["content"];
    }
    if (item["tags"] !== null && item["tags"] !== undefined) {
      for (const TAG of item.tags) {
        tags.push(TAG.label);
      }
    }
    if (item.kind !== null && item.kind !== undefined) {
      itemKind = item.kind;
    }
    if (item.permalink !== null && item.permalink !== undefined) {
      permalink = item.permalink;
    }
  }

  const details: TabsDetails = getDetails
    ? getCarouselItemDetails(
        item,
        list,
        index,
        requestedImageDimensions,
        oneTabDetails
      )
    : null;

  return {
    previewImageUrl,
    title,
    description,
    details,
    link,
    type,
    location,
    timeLabel,
    publisher,
    publishDate,
    id,
    authors,
    cost,
    timeRequiredInSeconds,
    summary,
    video,
    youTubeVideo,
    episodeInfo,
    subtitle,
    content,
    tags,
    itemKind,
    permalink,
    websiteUrl,
    landingPageUrl: item.landingPageUrl,
  };
}

/**
 *
 *
 * @export
 * @param {SearchHit} hit
 * @param {{
 *     requestedImageDimensions: {
 *       containerWidthRatio: number;
 *       numberOfItems: number;
 *     };
 *     isSquareImage: boolean;
 *   }} imageOptions
 * @returns {CarouselItem}
 */
export function getCarouselItemFromSearchHit(
  hit: SearchHit,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
  itemPreviewType: PreviewKind
): CarouselItem {
  const item: CarouselItem = {
    title: hit.title ? hit.title : hit.name,
    link: hit.linkUrl,
    id: hit.id,
    previewImageUrl: {},
    landingPageUrl: hit.landingPageUrl,
    tags: hit.tags,
  };

  if (hit.kind !== undefined && hit.kind !== null) {
    switch (hit.kind) {
      case HitItemKind.ARTICLE:
        item.type = GRAPHQL_TYPE_ARTICLE_STORY;
        item.subtitle = hit.subTitle;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ARTICLE_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.BOOK:
        item.type = GRAPHQL_TYPE_BOOK_STORY;
        item.subtitle = hit.subTitle;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_BOOK_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.ENTITY:
        item.type = GRAPHQL_TYPE_ENTITY;
        item.subtitle = hit.tagLine ? hit.tagLine : hit.subTitle;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ENTITY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.NEWSLETTER:
        item.subtitle = hit.subTitle ? hit.subTitle : hit.publisher;
        item.type = GRAPHQL_TYPE_NEWSLETTER_STORY;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_NEWSLETTER_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.ONLINE_COURSE:
        item.subtitle = hit.subTitle ? hit.subTitle : hit.published;
        item.timeLabel = `${(
          Number(hit.durationInSeconds) / 60
        ).toFixed()} minutes duration`;
        item.type = GRAPHQL_TYPE_ONLINE_COURSE_STORY;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ONLINE_COURSE_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.ORIGINAL_CONTENT:
        item.type = GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.PERSON:
        item.type = GRAPHQL_TYPE_PERSON;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_PERSON,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.PODCAST:
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_PODCAST_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });
        item.timeLabel = `${(
          Number(hit.durationInSeconds) / 60
        ).toFixed()} minutes duration`;
        item.type = GRAPHQL_TYPE_PODCAST_STORY;
        break;
      case HitItemKind.FIXED_LIST:
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_FIXED_LIST,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });
        item.type = GRAPHQL_TYPE_FIXED_LIST;
        break;
      case HitItemKind.DYNAMIC_LIST:
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_DYNAMIC_LIST,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });
        item.type = GRAPHQL_TYPE_DYNAMIC_LIST;
        break;
      case HitItemKind.LIST_COLLECTION:
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_DYNAMIC_LIST,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });
        item.type = GRAPHQL_TYPE_LIST_COLLECTION;
        break;
      default:
    }
  }

  if (hit.previewUrl !== undefined && hit.previewUrl !== null) {
    if (hit.previewUrl.indexOf("http") < 0) {
      item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
        imageType: "MEDIA_ASSET",
        mediaAsset: {
          mediaAssetPath: hit.previewUrl,
          type: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: requestedImageDimensions,
        isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
        revision: undefined,
      });
    } else {
      item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
        imageType: "REMOTE",
        remote: {
          url: hit.previewUrl,
        },
        desiredDimensions: requestedImageDimensions,
        isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
        revision: undefined,
      });
    }
  }
  return item;
}

function getCarouselItemDetails(
  item: any,
  list: List,
  index: number,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
  oneTabDetails: boolean
): TabsDetails {
  if (item.__typename === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY) {
    return getEpisodeDetails(
      item,
      list,
      index + 1,
      requestedImageDimensions,
      oneTabDetails
    );
  }
  return getGenericDetails(item, requestedImageDimensions);
}

/**
 * Returns a a UUID cleaned of special characters.
 *
 * @return {string} The cleaned UUID.
 */
export function getCleanUuid(): string {
  return _getNewUniqueId()
    .replace(/\+/g, (): string => {
      return getRandomCharacter();
    })
    .replace(/\//g, (): string => {
      return getRandomCharacter();
    })
    .replace(/-/g, (): string => {
      return getRandomCharacter();
    })
    .replace(/_/g, (): string => {
      return getRandomCharacter();
    });
}

/**
 * Returns a random character.
 *
 * @return {string} The random character.
 */
function getRandomCharacter(): string {
  return ALPHANUMERIC[Math.floor(Math.random() * ALPHANUMERIC.length)];
}

/**
 *
 * @param rawData
 * @param fileName
 */
export function blobToFile(rawData: Blob, fileName: string): File {
  const BLOB: any = rawData;
  // A Blob() is almost a File()
  // it's just missing the two properties below which we will add
  BLOB.lastModifiedDate = new Date();
  BLOB.name = fileName;
  // cast to a File() type
  return BLOB;
}

/**
 * Check if there is a stored mutationId and generate new one if not.
 */
export function getClientMutationId(): string {
  let clientMutationId: string;

  if (
    localStorage.getItem("clientMutationId") !== undefined &&
    localStorage.getItem("clientMutationId") !== null
  ) {
    clientMutationId = localStorage.getItem("clientMutationId");
  } else {
    clientMutationId = _getNewUniqueId();
    localStorage.setItem("clientMutationId", clientMutationId);
  }
  return clientMutationId;
}

/**
 * Returns the carousel item from a questionnaire.
 *
 * @param {Questionnaire} questionnaire The questionnaire.
 *
 * @returns {CarouselItem} The carousel item populated from the questionnaire.
 */
export function getDrivenNominateItem(
  questionnaire: Questionnaire,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  }
): CarouselItem {
  let details: TabsDetails;
  let item: CarouselItem;
  const QUESTION: MultipleChoiceQuestion = questionnaire.questions[0];

  const choices = QUESTION.choices.map((choice) => {
    let c: any;

    if (
      choice !== null &&
      choice !== undefined &&
      choice.person !== undefined &&
      choice.person !== null
    ) {
      c = {
        id: choice.id,
        title: choice.person.fullName,
        image: ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: choice.person.previews,
            previewKind: PreviewKind.SQUARE,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: GRAPHQL_TYPE_PERSON,
          },
          desiredDimensions: requestedImageDimensions,
          revision: undefined,
        }),
      };
    }
    return c;
  });

  details = {
    tabs: [
      {
        tabName: "NOMINATE A MENTOR",
        component: NominatePanelComponent,
        tabDetails: {
          choices,
          question: QUESTION.id,
          questionnaire: questionnaire.id,
        },
      },
    ],
  };

  item = {
    details,
    type: "Nominate",
    title: "Nominate the Next Celebrity Advisors on Driven!",
    previewImageUrl: {},
    landingPageUrl: undefined,
  };

  return item;
}

/**
 * Returns the details of an episode in a series as a carousel item.
 *
 * @param {OriginalContentStory} episode The episode.
 *
 * @param {List} show The show for which this is an episode.
 *
 * @param {number} episodeNumber The number of the episode.
 *
 * @returns {TabsDetails} The carousel item created from the episode.
 */
export function getEpisodeDetails(
  episode: OriginalContentStory,
  show: List,
  episodeNumber: number,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
  oneTabDetails?: boolean
): TabsDetails {
  const video = getVideoLinksFromMediaItem(episode.mediaItem);
  const youTubeVideo = getVideoLinksFromExternalId(episode.externalId);
  const videoTab: VideoTabDetails = {
    // The background image is used here because
    // the logo is optimized for white - background for black
    video,
    youTubeVideo,
    videoId: episode.id,
    showLogo: show.previews
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: show.previews,
            previewKind: PreviewKind.HERO_LOGO,
            topLevelMedia: TopLevelMediaKind.IMAGE,
          },
          desiredDimensions: requestedImageDimensions,
          revision: undefined,
        })
      : undefined,
    episodeTitle: episode.title,
    episodeSubtitle: episode.subTitle,
    backgroundImage: episode.previews
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: episode.previews,
            previewKind: PreviewKind.BACKGROUND,
            topLevelMedia: TopLevelMediaKind.IMAGE,
          },
          desiredDimensions: { containerWidthRatio: 0.75, numberOfItems: 1 },
          revision: show.revision,
        })
      : null,
    episodeDescription: episode.peopleEntities[0].person.description,
    episodeDetails: [
      show.name,
      `Episode ${episodeNumber}`,
      mapMillisecondsToMinutes(episode.durationInSeconds),
    ],
    landingPageUrl: episode.landingPageUrl,
    showLandingPage: show.landingPageUrl,
    showDescription: show.description,
    badges: episode.badges
      ? episode.badges.map((award: GraphQlBadge) => {
          if (Types.isObject(award.previews)) {
            const MEDIA_ASSERT = extractBadge(award);
            return {
              link: award.link,
              imgUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "MEDIA_ASSET",
                mediaAsset: {
                  files: MEDIA_ASSERT.files,
                  type: TopLevelMediaKind.IMAGE,
                },
                desiredDimensions: {
                  containerWidthRatio: 3 / 12,
                  numberOfItems: 4,
                },
                revision: show.revision,
              }),
            };
          }
        })
      : null,
  };

  const thePeopleTab: ThePeopleTabDetails = {};
  const theCompaniesTab: ThePeopleTabDetails = {};

  thePeopleTab.people = episode.peopleEntities
    ? episode.peopleEntities.map((personEntity: PersonEntity): {
        name: string;
        personId?: string;
        companyId: string;
        companyName?: string;
        companyUrl?: string;
        description?: string;
        preview: OptimizedImageUrls;
        responsibility: string;
        landingPageUrl: string;
      } => {
        return {
          name: personEntity.person ? personEntity.person.fullName : null,
          companyId: personEntity.entity ? personEntity.entity.id : null,
          personId: personEntity.person ? personEntity.person.id : null,
          description: personEntity.person
            ? personEntity.person.description
            : null,
          companyName: personEntity.entity ? personEntity.entity.name : null,
          companyUrl: personEntity.entity
            ? personEntity.entity.websiteUrl
            : null,
          preview: ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: personEntity.person.previews,
              previewKind: PreviewKind.SQUARE,
              topLevelMedia: TopLevelMediaKind.IMAGE,
            },
            desiredDimensions: {
              containerWidthRatio: 0.75,
              numberOfItems: 1,
            },
            revision: show.revision,
          }),
          responsibility: personEntity.person.responsibility,
          landingPageUrl: personEntity.person.landingPageUrl,
        };
      })
    : [];

  theCompaniesTab.companies = episode.peopleEntities
    ? episode.peopleEntities.map((personEntity: PersonEntity): {
        name: string;
        companyName?: string;
        companyUrl?: string;
        description?: string;
        companyId: string;
        preview: OptimizedImageUrls;
        landingPageUrl: string;
      } => {
        return {
          name: null,
          companyId: personEntity.entity ? personEntity.entity.id : null,
          description: personEntity.entity
            ? personEntity.entity.description
            : null,
          companyName: personEntity.entity ? personEntity.entity.name : null,
          companyUrl: personEntity.entity
            ? personEntity.entity.websiteUrl
            : null,
          preview: ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: personEntity.entity
                ? personEntity.entity.previews
                : null,
              previewKind: PreviewKind.SQUARE,
              topLevelMedia: TopLevelMediaKind.IMAGE,
            },
            desiredDimensions: {
              containerWidthRatio: 0.75,
              numberOfItems: 1,
            },
            revision: show.revision,
          }),
          landingPageUrl: personEntity.entity
            ? personEntity.entity.landingPageUrl
            : "",
        };
      })
    : [];

  let details: any;
  details = oneTabDetails
    ? {
        tabs: [
          {
            component: EpisodeDetails,
            tabName: "Overview",
            tabDetails: {
              video: videoTab,
              person: thePeopleTab,
              company: theCompaniesTab,
            },
          },
        ],
      }
    : {
        tabs: [
          {
            component: EpisodeDetails,
            tabName: "Overview",
            tabDetails: {
              video: videoTab,
              person: thePeopleTab,
              company: theCompaniesTab,
            },
          },
        ],
      };

  return details;
}

/**
 * get lists of followed entities/people in for Carousel.
 *
 * @export
 * @param {Follows} follows
 * @returns {{
 *   people?: CarouselItem[],
 *   entities?: CarouselItem[],
 * }}
 */
export function getFollowingItems(
  follows: Follows[],
  imageOptions: {
    requestedImageDimensions: {
      containerWidthRatio: number;
      numberOfItems: number;
    };
    isSquareImage: boolean;
  }
): {
  people?: CarouselItem[];
  entities?: CarouselItem[];
} {
  const followedItems: {
    people?: CarouselItem[];
    entities?: CarouselItem[];
  } = {
    people: [],
    entities: [],
  };
  if (follows !== undefined && follows !== null) {
    for (const f of follows) {
      if (f.__typename === GRAPHQL_TYPE_PERSON || "fullName" in f) {
        followedItems.people.push(
          getCarouselItem(
            f,
            null,
            0,
            imageOptions.requestedImageDimensions,
            PreviewKind.SQUARE
          )
        );
      } else {
        followedItems.entities.push(
          getCarouselItem(
            f,
            null,
            0,
            imageOptions.requestedImageDimensions,
            PreviewKind.SQUARE
          )
        );
      }
    }
  }
  return followedItems;
}

/**
 * Returns the header details of an Entity profile.
 *
 * @export
 * @param {Entity} item The Entity GraphQl Object.
 *
 * @returns {ProfileHeaderDetails} The profile header details.
 */
export function getEntityHeaderDetails(
  item: any,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  }
): ProfileHeaderDetails {
  let socialMediaProfiles: {
    FacebookID: string;
    InstagramID: string;
    LinkedInID: string;
    TwitterID: string;
  } = null;
  // the reason why we have item as any is that the relay compiler fails when we use type assertion
  let preview: OptimizedImageUrls;
  preview =
    item.previews !== null || item.previews !== undefined
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: item.previews,
            previewKind: PreviewKind.LOGO,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: item.__typename,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: true,
          revision: item.revision,
        })
      : ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ENTITY,
          },
          desiredDimensions: { containerWidthRatio: 1, numberOfItems: 5 },
          isSquareImage: true,
          revision: undefined,
        });

  if (!preview) {
    preview = ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: item.previews,
        previewKind: PreviewKind.SQUARE,
        topLevelMedia: TopLevelMediaKind.IMAGE,
        isDefaultPreview: true,
        type: item.__typename,
      },
      desiredDimensions: requestedImageDimensions,
      isSquareImage: true,
      revision: item.revision,
    });
  }
  if (item.socialMediaProfiles && item.socialMediaProfiles.length > 0) {
    socialMediaProfiles = {
      FacebookID: "",
      InstagramID: "",
      LinkedInID: "",
      TwitterID: "",
    };
    item.socialMediaProfiles.map((profile) => {
      switch (profile.platform.kind) {
        case SocialMediaPlatformKind.FACEBOOK:
          socialMediaProfiles.FacebookID =
            profile.link !== null && profile.link !== undefined
              ? profile.link
              : "";
          break;
        case SocialMediaPlatformKind.TWITTER:
          socialMediaProfiles.TwitterID =
            profile.link !== null && profile.link !== undefined
              ? profile.link
              : "";
          break;
        case SocialMediaPlatformKind.INSTAGRAM:
          socialMediaProfiles.InstagramID =
            profile.link !== null && profile.link !== undefined
              ? profile.link
              : "";
          break;

        case SocialMediaPlatformKind.LINKED_IN:
          socialMediaProfiles.LinkedInID =
            profile.link !== null && profile.link !== undefined
              ? profile.link
              : "";
          break;
        default:
      }
    });
  }
  let avatarMediaAssetId: string = ImageHelper.getPreviewMediaAssetId(
    item.previews,
    PreviewKind.LOGO
  );
  if (!avatarMediaAssetId) {
    avatarMediaAssetId = ImageHelper.getPreviewMediaAssetId(
      item.previews,
      PreviewKind.SQUARE
    );
  }

  let bgMediaAsset: MediaAsset;
  item.previews.edges.map((media) => {
    if (media.preview.kind === PreviewKind.BACKGROUND) {
      bgMediaAsset = media.preview.mediaAsset;
    }
  });

  let profileHeader: ProfileHeaderDetails;
  profileHeader = {
    socialMediaProfiles,
    type: item.__typename,
    location: getLocation(item),
    websiteUrl: item.websiteUrl,
    name: item.name,
    follows: item.follows,
    title: item.dba,
    description: item.description,
    landingPageUrl: item.landingPageUrl,
    profileImgUrl: preview,
    profileMediaAssetId: avatarMediaAssetId,
    industryId: item.industry ? item.industry.id : undefined,
    industry: item.industry ? item.industry.id : undefined,
    founded: item.founded
      ? new Date(item.founded).toISOString().split("T")[0]
      : "",
    stage: item.fundingRound,
    fundarisingStatus: item.isFundraising,
    noOfEmployees: item.noOfEmployees ? item.noOfEmployees : undefined,
    hasPortfolio: item.hasPortfolio,
    portfolioMarketCap: item.portfolioMarketCap,
    headquarters:
      item.headquarters && item.headquarters.location
        ? item.headquarters.location.label
        : undefined,
    noOfPortfolioCompanies: item.noOfPortfolioCompanies
      ? item.noOfPortfolioCompanies
      : undefined,

    backgroundImgUrl: ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: item.previews,
        previewKind: PreviewKind.BACKGROUND,
        topLevelMedia: TopLevelMediaKind.IMAGE,
        isDefaultPreview: true,
        type: item.__typename,
      },
      desiredDimensions: {
        containerWidthRatio: 1,
        numberOfItems: 1,
      },
      revision: item.revision,
    }),
    backgroundMediaAsset: bgMediaAsset,
    isFeedBaseEntity: item.isFeedBaseEntity,
  };

  return profileHeader;
}

/**
 * Returns the name of an inspirer from a GraphQL inspirer item.
 *
 * @param {*} inspirer The original inspirer.
 *
 * @returns {string} The name of the inspirer.
 */
function getInspirerName(inspirer: any): string {
  return inspirer.__typename === GRAPHQL_TYPE_ENTITY
    ? inspirer.name
    : inspirer.fullName;
}

/**
 * Return a collection of grid items created from a collection of investments.
 *
 * @export
 * @param {Array<InvestmentByPerson>} investments The collection of investments.
 *
 * @returns {Array<SixByOneGridItem>} The collection of grid items created from the investments.
 */
export function getItemsFromInvestments(
  investments: (InvestmentByPerson | Investment)[],
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  }
): SixByOneGridItem[] {
  let items: SixByOneGridItem[];

  items = investments.map(
    (investment): SixByOneGridItem => {
      if (investment.investee !== undefined && investment.investee !== null) {
        return {
          type: investment.investee.__typename,
          id: investment.investee.id,
          description: investment.investee.description,
          previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: investment.investee.previews,
              previewKind: PreviewKind.SQUARE,
              topLevelMedia: TopLevelMediaKind.IMAGE,
              isDefaultPreview: true,
              type: investment.__typename,
            },
            desiredDimensions: requestedImageDimensions,
            revision: investment.revision,
          }),
          title: investment.investee.name,
          landingPageUrl: investment.investee.landingPageUrl,
          websiteUrl: investment.investee.websiteUrl,
        };
      }
      if (investment.entity !== undefined && investment.entity !== null) {
        return {
          type: investment.entity.__typename,
          id: investment.entity.id,
          description: investment.investee.description,
          previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: investment.entity.previews,
              previewKind: PreviewKind.SQUARE,
              topLevelMedia: TopLevelMediaKind.IMAGE,
              isDefaultPreview: true,
              type: investment.__typename,
            },
            desiredDimensions: requestedImageDimensions,
            revision: investment.revision,
          }),
          title: investment.entity.name,
          landingPageUrl: investment.entity.landingPageUrl,
          websiteUrl: investment.entity.websiteUrl,
        };
      }
    }
  );
  return items;
}

/**
 * Returns a collection of grid items from an experience GraphQL connection.
 *
 * @param {Connection<string, Position>} experience A person's experience.
 *
 * @returns {Array<SixByOneGridItem>} The collection of grid items created from the experiences.
 */
export function getItemsFromExperiences(
  experience: any,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  }
): SixByOneGridItem[] {
  let items: SixByOneGridItem[] = [];

  if (
    experience.edges !== undefined &&
    experience.edges !== null &&
    experience.edges.length > 0
  ) {
    for (const EDGE of experience.edges) {
      if (EDGE !== undefined && EDGE !== null && EDGE.position) {
        items.push({
          type: EDGE.position.__typename,
          description: EDGE.position.entity
            ? EDGE.position.entity.description
            : undefined,

          previewImageUrl: EDGE.position.entity
            ? ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: EDGE.position.entity.previews,
                  previewKind: PreviewKind.SQUARE,
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  isDefaultPreview: true,
                  type: EDGE.position.entity.__typename,
                },
                desiredDimensions: requestedImageDimensions,
                revision: undefined,
              })
            : undefined,
          id: EDGE.position.id,
          title: EDGE.position.entity ? EDGE.position.entity.name : undefined,
          landingPageUrl: EDGE.position.entity
            ? EDGE.position.entity.landingPageUrl
            : undefined,
          websiteUrl: EDGE.position.entity
            ? EDGE.position.entity.websiteUrl
            : undefined,
        });
      }
    }
  } else {
    items = [];
  }
  return items;
}

/**
 * Returns the collection of grid items from a collection of inspirations.
 *
 * @param {Array<Inspiration>} inspirations The collection of inspirations.
 *
 * @returns {Array<SixByOneGridItem>} The collection of grid items.
 */
export function getItemFromInspirations(
  inspirations: Inspiration[],
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  }
): SixByOneGridItem[] {
  let items: SixByOneGridItem[];

  items = inspirations.map(
    (inspiration: Inspiration): SixByOneGridItem => {
      return {
        type: inspiration.inspirer.__typename,
        description: inspiration.inspirer.description,

        previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: inspiration.inspirer.previews,
            previewKind: PreviewKind.SQUARE,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: inspiration.__typename,
          },
          desiredDimensions: requestedImageDimensions,
          revision: undefined,
        }),
        id: inspiration.inspirer.id,
        title: getInspirerName(inspiration.inspirer),
        landingPageUrl: inspiration.inspirer.landingPageUrl,
        websiteUrl: inspiration.inspirer.websiteUrl,
      };
    }
  );
  return items;
}

/**
 * Returns the person or entity's default location if provided and any other location if the
 * default isn't added.
 *
 * @export
 * @param {(Person | Entity)} personOrEntity The person or entity.
 *
 * @returns {string} The location.
 */
export function getLocation(personOrEntity: Person | Entity): string {
  let location: string = null;

  if (
    personOrEntity.defaultLocation !== null &&
    personOrEntity.defaultLocation !== undefined
  ) {
    location = personOrEntity.defaultLocation.label;
  } else if (
    personOrEntity.locations !== undefined &&
    personOrEntity.locations !== null &&
    personOrEntity.locations.length > 0 &&
    personOrEntity.locations[0] !== null
  ) {
    location = personOrEntity.locations[0].label;
  }
  return location;
}

/**
 * Returns a range translated into a string.
 *
 * @param {Range} range The range.
 *
 * @returns {string} The range translated into a string.
 */
export function getNumberFromRange(range: Range): string {
  let num: string;

  if (range.singleValue !== undefined && range.singleValue !== null) {
    num = `${range.singleValue}`;
  } else {
    if (
      range.minimum !== null &&
      range.minimum !== undefined &&
      range.maximum !== null &&
      range.maximum !== undefined
    ) {
      num = `${range.minimum}~${range.maximum}`;
    } else if (range.minimum !== null && range.minimum !== undefined) {
      num = range.isGreaterThan ? `>${range.minimum}` : `${range.minimum}`;
    } else if (range.maximum !== null && range.maximum !== undefined) {
      num = `<${range.maximum}`;
    }
  }
  return num;
}

interface NumRange {
  /**
   * The typename of the Range.
   *
   * @type {"Range"}
   */
  __typename?: "Range";

  /**
   * The maximum value.
   *
   * @type {number}
   * @memberof Range
   */
  maximum?: number;

  /**
   * The minimum value.
   *
   * @type {number}
   * @memberof Range
   */
  minimum?: number;

  /**
   * A flag which is true if the range is greater than the minimum.
   *
   * @type {boolean}
   * @memberof Range
   */
  isGreaterThan?: boolean;

  /**
   * A flag which is true if the range is less than the maximum.
   *
   * @type {boolean}
   * @memberof Range
   */
  isLessThan?: boolean;

  /**
   * The range has a single value and its difference is, therefore, 0.
   *
   * @type {number}
   * @memberof Range
   */
  singleValue?: number;
}
/**
 * Returns a range value from a string.
 *
 * @param {string} number the number in string format.
 *
 * @returns {NumRange} The range value.
 */
export function getRangeFromNumber(number: string): NumRange {
  let range: NumRange = {
    minimum: undefined,
    maximum: undefined,
  };
  let safeNum: string[] = [];
  if (number !== null && number !== undefined && number !== "") {
    if (number.includes(">")) {
      safeNum = number.substr(number.indexOf(">") + 1).match(/(\d+)/);
      if (safeNum && safeNum.length) {
        range.minimum = parseInt(safeNum[0], 10);
        range.isGreaterThan = true;
      }
    } else if (number.includes("<")) {
      safeNum = number.substr(number.indexOf("<") + 1).match(/(\d+)/);
      if (safeNum && safeNum.length) {
        range.maximum = parseInt(safeNum[0], 10);
        range.isLessThan = true;
      }
    } else if (number.includes("~") || number.includes("-")) {
      let rangeArr: string[];
      if (number.includes("~")) {
        rangeArr = number.split("~");
      } else {
        rangeArr = number.split("-");
      }
      if (rangeArr.length && rangeArr.length >= 1) {
        safeNum = rangeArr[0].match(/(\d+)/);
        const safeNum2: string[] = rangeArr[1].match(/(\d+)/);
        if (safeNum && safeNum.length && safeNum2 && safeNum2.length) {
          const num1: number = parseInt(safeNum[0], 10);
          const num2: number = parseInt(safeNum2[0], 10);
          range.minimum = num1 > num2 ? num2 : num1;
          range.maximum = num1 > num2 ? num1 : num2;
        }
      }
    } else {
      safeNum = number.match(/(\d+)/);
      if (safeNum && safeNum.length) {
        range.minimum = parseInt(safeNum[0], 10);
      } else {
        range = undefined;
      }
    }
  }
  return range;
}

/**
 * Return Person profile header details.
 *
 * @export
 * @param {Person} item The graphQl Person Object.
 *
 * @returns {ProfileHeaderDetails} The profile header details.
 */
export function getPersonHeaderDetails(
  item: any,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  }
): ProfileHeaderDetails {
  // the reason why we have item as any is that the relay compiler fails when we use type assertion
  let profileHeader: ProfileHeaderDetails;
  let socialMediaProfiles: {
    FacebookID: string;
    InstagramID: string;
    LinkedInID: string;
    TwitterID: string;
  } = null;
  const avatarMediaAssetId: string = ImageHelper.getPreviewMediaAssetId(
    item.previews,
    PreviewKind.HEADSHOT
  );
  let profileImgUrl: OptimizedImageUrls;
  profileImgUrl =
    item.preview !== null || item.previews !== undefined
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: item.previews,
            previewKind: PreviewKind.HEADSHOT,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: GRAPHQL_TYPE_PERSON,
          },
          isSquareImage: true,
          desiredDimensions: requestedImageDimensions,
          revision: item.revision,
        })
      : ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_PERSON,
          },
          desiredDimensions: { containerWidthRatio: 1, numberOfItems: 5 },
          isSquareImage: true,
          revision: undefined,
        });
  let bgMediaAsset: MediaAsset;

  if (item && item.previews) {
    item.previews.edges.map((media) => {
      if (media.preview.kind === PreviewKind.BACKGROUND) {
        bgMediaAsset = media.preview.mediaAsset;
      }
    });
  }
  if (item.socialMediaProfiles && item.socialMediaProfiles.length > 0) {
    socialMediaProfiles = {
      FacebookID: "",
      InstagramID: "",
      LinkedInID: "",
      TwitterID: "",
    };
    item.socialMediaProfiles.map((profile) => {
      switch (profile.platform.kind) {
        case SocialMediaPlatformKind.FACEBOOK:
          socialMediaProfiles.FacebookID =
            profile.link !== null && profile.link !== undefined
              ? profile.link
              : "";
          break;
        case SocialMediaPlatformKind.TWITTER:
          socialMediaProfiles.TwitterID =
            profile.link !== null && profile.link !== undefined
              ? profile.link
              : "";
          break;
        case SocialMediaPlatformKind.INSTAGRAM:
          socialMediaProfiles.InstagramID =
            profile.link !== null && profile.link !== undefined
              ? profile.link
              : "";
          break;

        case SocialMediaPlatformKind.LINKED_IN:
          socialMediaProfiles.LinkedInID =
            profile.link !== null && profile.link !== undefined
              ? profile.link
              : "";
          break;
        default:
      }
    });
  }

  profileHeader = {
    profileImgUrl,
    socialMediaProfiles,
    location: getLocation(item),
    name: item.fullName,
    title: item.responsibility,
    type: item.__typename,
    follows: item.follows,
    description: item.description,
    landingPageUrl: item.landingPageUrl,
    websiteUrl: item.websiteUrl,
    profileMediaAssetId: avatarMediaAssetId,
    backgroundImgUrl: ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: item.previews,
        previewKind: PreviewKind.BACKGROUND,
        topLevelMedia: TopLevelMediaKind.IMAGE,
        isDefaultPreview: true,
        type: GRAPHQL_TYPE_PERSON,
      },
      desiredDimensions: {
        containerWidthRatio: 1,
        numberOfItems: 1,
      },
      revision: item.revision,
    }),
    backgroundMediaAsset: bgMediaAsset,
  };
  return profileHeader;
}

/**
 * Returns the poster details as a carousel item.
 *
 * @export
 * @param {*} poster The poster.
 *
 * @returns {TabsDetails} The poster details as a carousel item.
 */
export function getGenericDetails(
  item: any,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  }
): TabsDetails {
  const details = {
    landingPageUrl: item.permalink ? item.permalink : undefined,
    // questionnaire:
    //   item.__typename === GRAPHQL_TYPE_POSTER && UPCOMING_SHOWS_QUESTIONNAIRE.id
    //     ? UPCOMING_SHOWS_QUESTIONNAIRE.id
    //     : undefined,
    // questionId:
    //   item.__typename === GRAPHQL_TYPE_POSTER &&
    //   UPCOMING_SHOWS_QUESTIONNAIRE.questions[item.id]
    //     ? UPCOMING_SHOWS_QUESTIONNAIRE.questions[item.id].id
    //     : undefined,
    // choicesIds:
    //   item.__typename === GRAPHQL_TYPE_POSTER &&
    //   UPCOMING_SHOWS_QUESTIONNAIRE.questions[item.id]
    //     ? UPCOMING_SHOWS_QUESTIONNAIRE.questions[item.id].choicesIds
    //     : undefined,
    showBackground: item.previews
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: item.previews,
            previewKind: PreviewKind.BACKGROUND,
            topLevelMedia: TopLevelMediaKind.IMAGE,
          },
          desiredDimensions: { containerWidthRatio: 0.75, numberOfItems: 1 },
          revision: item.revision,
        })
      : null,
    showLogo:
      item.previews !== undefined && item.previews !== null
        ? ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: item.previews,
              previewKind: PreviewKind.LOGO,
              topLevelMedia: TopLevelMediaKind.IMAGE,
            },
            desiredDimensions: requestedImageDimensions,
            revision: item.revision,
          })
        : null,
    showTitle: item.label,
    showDescription: item.description,
  };
  return {
    tabs: [
      {
        component: GeneralDetailsComponent,
        tabName: "Overview",
        tabDetails: details,
      },
    ],
  };
}

/**
 * open the actionModal from the jQuery.
 *
 * @export
 */
export function showActionModal() {
  const actionModal = document.getElementById(ACTION_MODAL_ID);
  console.log(actionModal);
  if (actionModal !== undefined && actionModal !== null) {
    $(`#${ACTION_MODAL_ID}`).modal({
      keyboard: false,
      backdrop: "static",
      show: true,
    });
  } else {
    return import("../authentication/cognito-client").then((module) => {
      //  return module.CognitoClient.signOut();
    });
  }
}

/**
 *
 * @param ExternalId
 */
export function getVideoLinksFromExternalId(externalId: string) {
  let videoUrls: string;
  if (externalId !== null && externalId !== undefined) {
    videoUrls = externalId;
  } else {
    null;
  }
  return videoUrls;
}

/**
 * Exports videos URLs from a given media item
 *
 * @param {MediaAsset} mediaItem The media item.
 *
 * @returns {Array<string>} The video links in a media ite,.
 */
export function getVideoLinksFromMediaItem(
  mediaItem: MediaAsset
): {
  src: string;
  name: string;
}[] {
  let videoUrls: {
    src: string;
    name: string;
  }[] = [];

  if (mediaItem !== null && mediaItem !== undefined) {
    videoUrls = mediaItem.files.map((file) => {
      if (file !== null && file !== undefined) {
        return {
          src: ImageHelper.fetchOptimizedImageUrl({
            imageType: "MEDIA_ASSET",
            mediaAsset: { files: [file], type: TopLevelMediaKind.VIDEO },
            desiredDimensions: { containerWidthRatio: 1, numberOfItems: 1 },
            revision: file.revision,
          }).originalUrl,
          name: `${file.widthInPixels}`,
        };
      }
      return null;
    });
  }
  videoUrls.reverse();
  return videoUrls;
}

/**
 *
 *
 * @export
 * @param {Badge} badges
 * @param {PreviewKind} [target=PreviewKind.A_ROLE]
 * @returns {MediaAsset}
 */
export function extractBadge(
  badges: GraphQlBadge,
  target: PreviewKind = PreviewKind.BADGE_DARK
): MediaAsset {
  let targetBadge: MediaAsset;

  if (badges && badges.previews) {
    const TARGET_PREVIEW = badges.previews.edges.filter(
      (preview: { preview: Preview }) => {
        return preview.preview.kind === target;
      }
    );

    if (TARGET_PREVIEW && TARGET_PREVIEW[0]) {
      targetBadge = (TARGET_PREVIEW as any)[0].preview.mediaAsset;
    }
  }

  return targetBadge;
}
/**
 * Function used to validate if provided url is valid url
 *
 * @export
 * @param {string} targetUrl
 * @returns {boolean}
 */
export function isValidUrl(targetUrl: string): boolean {
  const regexp = /^^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  return targetUrl === undefined || targetUrl === null
    ? false
    : regexp.test(targetUrl);
}

/**
 *
 *
 * @export
 * @returns {boolean}
 */
 export function isYoutube(url: string): boolean {
  const YOUTUBE_REG = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;

  try {
    return YOUTUBE_REG.test(url);
  } catch (e) {
    return false;
  }
}
