/**
 * @author Maria Osama
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-06-20 11:41:36
 * @description Implementation of the SignInModal react component
 * @filename sign-in-modal.tsx
 */
import { getNewUniqueId } from "@radivision/common";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import React from "react";
import { LinkedInAuthenticator } from "../../../authentication/linkedin-authenticator";
import { TwitterAuthenticator } from "../../../authentication/twitter-authenticator";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { GOOGLE_LOGO_IMAGE_NAME } from "../../../constants/general-constants";
import { ImageHelper } from "../../../utilities/image-helper";
import { RdvButton } from "../../page-framework/rdv-button";
import { Loader } from "../../page/loader";
import { AccountVerificationModal } from "./account-verification-modal";
import { CreateAccountModal } from "./create-account-modal";
import { RequestPasswordResetModal } from "./request-password-reset-modal";
import {
  FACEBOOK_ICON_SVG,
  LINKED_IN_ICON_SVG,
  TWITTER_ICON_SVG,
} from "./social-media-icons";

/**
 * The properties of the SignInModal component.
 *
 * @interface
 */
interface SignInModalProps {
  /**
   * To be called when Clicking Create an Account instead
   *
   * @type {Function}
   */
  changeModal: Function;

  /**
   *The ID of the EmailLogin class
   *
   * @type {string}
   */
  id: string;

  /**
   * title of the modal.
   *
   * @type {string}
   * @memberof SignInModalProps
   */
  title?: string;

  /**
   * A function that navigates to the technical support pages and closes the modal.
   *
   * @type {function():void}
   */
  navigateToTechnicalSupport: () => void;

  /**
   * A function that returns a promise to resend the account verification email.
   *
   * @type {function(email: string):Promise<any>}
   */
  resendAccountVerificationEMail: (email: string) => Promise<any>;

  /**
   * A function that sets the title of the modal.
   *
   * @type {function(email: string):Promise<any>}
   */
  setTitle: (title?: string) => void;
}

/**
 * The state of the SignInModal component.
 *
 * @interface
 */
interface SignInModalState {
  /**
   * A current error.
   *
   * @type {AWS.AWSError}
   */
  error?: any;

  /**
   * The URL of the Google logo.
   *
   * @type {string}
   */
  googleLogoPreview: OptimizedImageUrls;

  /**
   * A flag which is true if there is an error.
   *
   * @type {boolean}
   */
  hasError: boolean;

  /**
   * fire the progress overlay when submit button is clicked
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * A flag which identifies if the passwords are hidden or shown.
   *
   * @type {{"Hide" | "Show"}}
   */
  wording: "Hide" | "Show";

  /**
   * The type of the field "text" | "password"
   *
   * @type {string}
   */
  type: string;
}

/**
 * A React component that renders the SignInModal modal.
 *
 * @extends {React.Component}
 */
export class SignInModal extends React.Component<
  SignInModalProps,
  SignInModalState
> {
  /**
   * The HTML element to receive email input.
   *
   * @type {React.RefObject<HTMLInputElement>}
   */
  private emailInput: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();

  /**
   * The HTML element to receive password input.
   *
   * @type {React.RefObject<HTMLInputElement>}
   */
  private passwordInput: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();
  /**
   * Constructor.
   *
   * @param {SignInModalProps} props The props of the component.
   */
  constructor(props: SignInModalProps) {
    super(props);

    const googleLogoPreview: OptimizedImageUrls = ImageHelper.fetchOptimizedImageUrl(
      {
        imageType: "MEDIA_ASSET",
        mediaAsset: {
          type: TopLevelMediaKind.IMAGE,
          mediaAssetPath: GOOGLE_LOGO_IMAGE_NAME,
        },
        desiredDimensions: { containerWidthRatio: 4 / 12, numberOfItems: 4 },
        revision: undefined,
      }
    );

    this.changeState = this.changeState.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.navigateToCreateAccount = this.navigateToCreateAccount.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.contactTechnicalSupport = this.contactTechnicalSupport.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
    this.state = {
      googleLogoPreview,
      hasError: false,
      loading: false,
      type: "password",
      wording: "Show",
    };
  }

  /**
   * Opens an authentication window and authenticates user with LinkedIn or Twitter.
   *
   * @param {"linkedin" | "twitter"} provider The provider used for social media sign in.
   */
  signInWithSocialMedia(provider: "linkedin" | "twitter"): void {
    if (provider === "linkedin") {
      LinkedInAuthenticator.requestLinkedInAuthorizationCode();
    } else if (provider === "twitter") {
      TwitterAuthenticator.authenticateTwitterUser();
    }
  }

  /**
   * Changes the type of password input field from password to text and vice versa
   */
  changeState(): void {
    const oldState: string = this.state.type;
    const isTextOrHide: boolean = oldState === "password";
    const newState: "text" | "password" = isTextOrHide ? "text" : "password";
    const newWord: "Hide" | "Show" = isTextOrHide ? "Hide" : "Show";

    this.setState({
      type: newState,
      wording: newWord,
    });
  }

  /**
   * Invokes the technical support and closes the modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  contactTechnicalSupport(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.props.navigateToTechnicalSupport();
  }

  /**
   * Invoked by the React framework after the component output has been rendered to the DOM
   */
  componentDidMount() {
    this.props.setTitle("Sign In");
    this.setState({
      error: null,
      hasError: false,
    });
  }

  /**
   * Changes the current modal to the create account modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  navigateToCreateAccount(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();
    this.props.changeModal(CreateAccountModal);
  }
  /**
   * A method that sends user credentials to cognito to be authenticated
   *
   * @param {React.FormEvent<HTMLFormElement>} event the form submit event
   */
  onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    this.setState({ loading: true });
    event.preventDefault();
    this.setState({
      error: null,
      hasError: false,
    });
    import("../../../authentication/cognito-client").then((module) => {
      module.CognitoClient.loginWithEmailAndPassword(
        this.emailInput.current.value,
        this.passwordInput.current.value
      )
        .then(() => {
          this.setState({ loading: false });
          location.reload();
        })
        .catch((error: any) => {
          this.setState({
            error,
            hasError: true,
            loading: false,
          });
        });
    });
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    const MODAL_ID: string = `l${getNewUniqueId()}`;

    return (
      <div id="emailLogin">
        <Loader isActive={this.state.loading} />
        <h1>{this.props.title ? this.props.title : "Welcome to CMS Portal"}</h1>
        {/* <h5>Sign in to resume your journey.</h5> */}
        <p className="font-14">*Required fields</p>
        <div className="row">
          <div className="col-md-6">
            <form id={MODAL_ID} onSubmit={this.onSubmit}>
              <label className="form-group has-float-label top">
                <input
                  type="email"
                  className="form-control"
                  id={`${
                    this.props.title
                      ? "login-email-submodal"
                      : "login-email-mainmodal"
                  }`}
                  placeholder="Enter Email"
                  ref={this.emailInput}
                  required={true}
                />
                <span>
                  Email
                  <span className="required-star">*</span>
                </span>
              </label>
              <label className="form-group has-float-label">
                <input
                  type={this.state.type}
                  className="form-control"
                  id={`${this.props.title ? "pwd-submodal" : "pwd-mainmodal"}`}
                  placeholder="Enter Password"
                  ref={this.passwordInput}
                  required={true}
                />
                <span>
                  Password
                  <span className="required-star">*</span>
                </span>
              </label>
              <div className="form-group top">
                <a href="#" onClick={this.resetPassword}>
                  I forgot my password.
                </a>
              </div>

              {this.state.hasError ? this.renderErrorMessage() : null}

              <RdvButton form={MODAL_ID} type="submit" text="sign in" />
            </form>
          </div>

          {/* <div className="col-md-6 socialmediaLogin">
            <div
              className="social-login-btn"
              onClick={() => {
                window.location.href = process.env.FB_URI;
              }}
            >
              {FACEBOOK_ICON_SVG}
              <a>
                <span>
                  <span className="d-none d-lg-inline">Sign in with </span>
                  Facebook
                </span>
              </a>
            </div>

            <div
              className="social-login-btn"
              onClick={() => {
                window.location.href = process.env.GOOGLE_URI;
              }}
            >
              <div className="googleIcon">
                <ProgressiveImage
                  src={
                    this.state.googleLogoPreview.requestedResolutionUrl !==
                      undefined &&
                    this.state.googleLogoPreview.requestedResolutionUrl !== null
                      ? this.state.googleLogoPreview.requestedResolutionUrl
                      : this.state.googleLogoPreview.screenResolutionUrl
                  }
                  placeholder={this.state.googleLogoPreview.placeHolderUrl}
                >
                  {(src: any, LOADING: any, srcSetData: any) => (
                    <img alt="Google Logo" src={src} />
                  )}
                </ProgressiveImage>
              </div>
              <a>
                <span>
                  <span className="d-none d-lg-inline">Sign in with </span>
                  Google
                </span>
              </a>
            </div>

            <div
              className="social-login-btn"
              onClick={() => {
                this.signInWithSocialMedia("linkedin");
              }}
            >
              {LINKED_IN_ICON_SVG}
              <a href="#">
                <span className="d-none d-lg-inline">Sign in with </span>
                LinkedIn
              </a>
            </div>

            <div
              className="social-login-btn"
              onClick={() => {
                this.signInWithSocialMedia("twitter");
              }}
            >
              {TWITTER_ICON_SVG}
              <a>
                <span className="d-none d-lg-inline">Sign in with </span>Twitter
              </a>
            </div>
          </div>
       */}
        </div>
      </div>
    );
  }

  /**
   * Returns an element containing an error message determined by the current error.
   *
   * @returns {JSX.Element} The element containing the details of the error.
   */
  renderErrorMessage(): JSX.Element {
    let element: JSX.Element;

    if (this.state.hasError) {
      switch (this.state.error.code) {
        case "NotAuthorizedException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The password provided does not match that of the account. Would
                you like to{" "}
                <a href="#" onClick={this.resetPassword}>
                  reset your password
                </a>
                , or{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;
        case "UserNotFoundException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The email address provided is not recognized or has not been
                verified. Please{" "}
                <a href="#" onClick={this.navigateToCreateAccount}>
                  create a new account
                </a>
                ,{" "}
                <a href="#" onClick={this.verifyAccount}>
                  verify your account
                </a>{" "}
                or{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;
        case "UserNotConfirmedException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                Your account is not activated, don’t worry. All accounts need to
                be activated by an activation code that arrives via email to the
                address you provided{" "}
                <a href="#" onClick={this.verifyAccount}>
                  Re-send Activation Code
                </a>
                , or{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;
        default:
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                An unexpected error has occurred. Please{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
      }
    }
    return element;
  }

  /**
   * Changes the current modal to the PasswordResetModal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  resetPassword(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();
    this.props.changeModal(RequestPasswordResetModal, {
      email: this.emailInput.current.value,
    });
  }

  /**
   * Returns a promise to verify the account.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  verifyAccount(e: React.MouseEvent<HTMLAnchorElement>): Promise<any> {
    e.preventDefault();
    return this.props
      .resendAccountVerificationEMail(this.emailInput.current.value)
      .then(
        (): Promise<void> => {
          this.props.changeModal(AccountVerificationModal, {
            email: this.emailInput.current.value,
          });
          return Promise.resolve();
        }
      )
      .catch((e: any): void => {
        this.setState({
          error: e,
          hasError: true,
        });
      });
  }
  /**
   * Changes the current modal to the create account modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  signUp(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();
    this.props.changeModal(CreateAccountModal);
  }
}
