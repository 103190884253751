/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-11-25
 * @description Implementation of the GraphQL mutation to create support issue.
 * @filename create-support-issue.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an online-course.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_SUPPORT_ISSUE_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createSupportIssueMutation($input: CreateSupportIssueInput!) {
    createSupportIssue(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      supportIssue {
        id
        name
      }
    }
  }
`;
