/**
 * @author Linkon Islam
 * @date   2019-03-18
 * @description ManageComponent class
 * @filename ManageComponent.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import ModalConductor from "../modal/modal-conductor";
import { FloatingMenu } from "../floating-button/floating-menu";
import {
  FormGroupInput,
  ComponentPageFromInput
} from "../../../../component-configuration/cms-pages";
import {
  Component as GraphComponent,
  GRAPHQL_TYPE_COMPONENT
} from "@radivision/graphql";
import Tag from "../tag/tag";
import { Validator } from "../../../../utilities/cms-operation/validator";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";
import { Component } from "../../../../utilities/cms-operation/component";
import { Loader } from "../../../page/loader";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";

/**
 *
 *
 * @interface ManageComponentState
 */
interface ManageComponentProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof posterPageProps
   */
  component?: { id: string };

  /**
   *
   *
   * @type {string}
   * @memberof posterPageProps
   */
  graphComponent?: GraphComponent;
}

/**
 *
 *
 * @interface ManageComponentState
 */
interface ManageComponentState {
  /**
   *
   *
   * @type {PosterPageFormInput}
   * @memberof posterPageState
   */
  formInput: ComponentPageFromInput;

  /**
   *
   *
   * @type {*}
   * @memberof BookPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof posterPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof ArticlePageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof posterPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class ManageComponent
 * @extends {React.Component<{}, ManageComponentState>}
 */
export class ManageComponent extends React.Component<
  ManageComponentProps,
  ManageComponentState
> {
  /**
   * References to the Tags component in the posterPage.
   *
   * @type {*}
   * @memberof posterPage
   */
  tagsRef: any;

  /**
   * Creates an instance of ManageComponent.
   * @param {{}} props
   * @memberof ManageComponent
   */
  constructor(props: ManageComponentProps) {
    super(props);

    this.handleNameChanges = this.handleNameChanges.bind(this);
    this.handleDescriptionChanges = this.handleDescriptionChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.resetModalState = this.resetModalState.bind(this);

    //... initialize required components references
    this.tagsRef = React.createRef();

    //... initial component state
    this.state = this.handleComponentStateInitialize(this.props.graphComponent);
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @param {posterStory} posterProps
   * @returns {posterPageState}
   * @memberof posterPage
   */
  handleComponentStateInitialize(
    componentProps: GraphComponent
  ): ManageComponentState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: ComponentPageFromInput = {
      id: undefined,
      name: "",
      description: "",
      label: "",
      isDeleted: false
    };

    let state: ManageComponentState = {
      modal,
      formInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    if (componentProps !== null && componentProps !== undefined) {
      if (componentProps.id) {
        state.formInput.id = componentProps.id;
      }

      if (componentProps.label) {
        state.formInput.label = componentProps.label;
      }

      if (componentProps.description) {
        state.formInput.description = componentProps.description;
      }

      if (componentProps.isDeleted) {
        state.formInput.isDeleted = componentProps.isDeleted;
      }

      if (componentProps.revision) {
        state.formInput.revision = componentProps.revision;
      }

      // ........................
      // initialize form group inputs
      //...........................

      if (
        componentProps.tags !== null &&
        componentProps.tags !== undefined &&
        componentProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = componentProps.tags.map(
          tag => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }
    }

    return state;
  }

  // /**
  //  *
  //  *
  //  * @memberof ManageComponent
  //  */
  // handleRemoveProperty = (id: any) => {
  //   // console.log(" remove call for property id : ", id);

  //   let propertyList = this.state.propertyList;
  //   let filteredList = propertyList.filter((property, index) => {
  //     return property.id !== id;
  //   });

  //   this.setState({
  //     propertyList: filteredList
  //   });
  // };

  // /**
  //  *
  //  *
  //  * @memberof ManageComponent
  //  */
  // addMoreProperty = () => {
  //   const properties = (
  //     <Properties
  //       propertyId={this.state.propertyId}
  //       removeClick={this.handleRemoveProperty}
  //       handleChange={this.handleChange}
  //     />
  //   );
  //   this.setState({
  //     ...this.state,
  //     propertyId: this.state.propertyId + 1,
  //     propertyList: [
  //       ...this.state.propertyList,
  //       { id: this.state.propertyId, content: properties }
  //     ]
  //   });
  // };

  /**
   *
   *
   * @returns
   * @memberof ManageComponent
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Manage Component</h2>

            <div className="row">
              <div className="input-field col m8">
                <label>
                  Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="component name"
                  id="name"
                  type="text"
                  className="validate form-control"
                  required={true}
                  value={this.state.formInput.name}
                  onChange={this.handleNameChanges}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-field col m8">
                <label>Description</label>
                <input
                  placeholder="component description"
                  id="description"
                  type="text"
                  className="validate form-control"
                  value={this.state.formInput.description}
                  onChange={this.handleDescriptionChanges}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-field col m4">
                <label>Created</label>
                <input
                  id="created"
                  type="text"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                  className="form-control"
                />
              </div>
              <div className="input-field col m4">
                <label>Last Modified</label>
                <input
                  id="lastmodified"
                  type="text"
                  className="lastmodified form-control"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col mt-3">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    value="isdeleted"
                    checked={this.state.formInput.isDeleted}
                    readOnly
                    className="custom-control-input"
                  />
                  <label className="custom-control-label">Is Deleted</label>
                </div>
              </div>
            </div>
            <div className="Page--Tag">
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* <div>
              {this.state.propertyList.length ? (
                this.state.propertyList.map((property, index) => {
                  return <div key={index}>{property.content}</div>;
                })
              ) : (
                <p style={{ display: "none" }}>nothing to show</p>
              )}
              <a className="add-btn" onClick={this.addMoreProperty}>
                <span className="mr-1" />
                <FontAwesomeIcon icon="plus-circle" />
              </a>
            </div> */}
          </div>

          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />

          <FloatingMenu
            menuItems={{
              revertible: null,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
        </div>
        <Loader isActive={this.state.isLoading} />
      </div>
    );
  }

  /**
   * Function Responsible for handling submitting new poster
   *
   * @memberof posterPage
   */
  handleSaveAction(): void {
    let componentInput: ManageComponentState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      componentInput.formInput.tags = this.tagsRef.current.extractTagsString();

      // validate errors
      errorMessage = Validator.validateComponentInput(componentInput.formInput);

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (
          this.props.component !== null &&
          this.props.component !== undefined
        ) {
          internalPromise = Component.updateComponent(
            componentInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["COMPONENT"].message.confirmation.update;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Component.addComponent(
            componentInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["COMPONENT"].message.confirmation.add;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["COMPONENT"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @memberof posterPage
   */
  revertPageInput(input?: GraphComponent): void {
    let state: ManageComponentState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.graphComponent
    );
    this.setState(state);

    //... reset children state
    this.tagsRef.current.resetComponent();
  }

  /**
   *
   *
   * @memberof posterPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof posterPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @memberof posterPage
   */
  handleNameChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ComponentPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.name = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  label state
   *
   * @memberof posterPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ComponentPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleLabelChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  label state
   *
   * @memberof posterPage
   */
  handleDescriptionChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: ComponentPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleLabelChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.description = eventValue;
    this.setState({ formInput });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class ComponentPageContainer extends React.Component<
  ManageComponentProps,
  ManageComponentState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let componentId: string;

    // console.log("[ComponentPageContainer] provided props", this.props);

    if (this.props.component === null || this.props.component === undefined) {
      node = <ManageComponent />;
    } else {
      componentId = this.props.component.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query manageComponentQuery($id: ID!) {
              component(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                component {
                  __typename
                  key
                  description
                  id
                  revision
                }
              }
            }
          `}
          variables={{
            id: componentId
          }}
          render={({ error, props }) => {
            if (error) {
              // console.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console.log(props);
              if (props.story.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.component.component.__typename !== GRAPHQL_TYPE_COMPONENT
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Article id, {componentId} belongs to type{" "}
                      {props.component.component.__typename}, Need more help?
                      contact Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.component.component) {
                let pageProps: ManageComponentProps = {
                  ...this.props,
                  graphComponent: props.component.component
                };
                return <ManageComponent {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
