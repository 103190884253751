/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type tagPageQueryVariables = {
    readonly id: string;
};
export type tagPageQueryResponse = {
    readonly tag: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly tag: {
            readonly __typename: string;
            readonly id: string;
            readonly description: string | null;
            readonly label: string | null;
            readonly isDeleted: boolean | null;
            readonly isGraphQlType: boolean | null;
            readonly revision: string | null;
        } | null;
    } | null;
};
export type tagPageQuery = {
    readonly response: tagPageQueryResponse;
    readonly variables: tagPageQueryVariables;
};



/*
query tagPageQuery(
  $id: ID!
) {
  tag(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    tag {
      __typename
      id
      description
      label
      isDeleted
      isGraphQlType
      revision
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "tag",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "TagPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tag",
        "storageKey": null,
        "args": null,
        "concreteType": "Tag",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "label",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isDeleted",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isGraphQlType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "tagPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "tagPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "tagPageQuery",
    "id": null,
    "text": "query tagPageQuery(\n  $id: ID!\n) {\n  tag(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    tag {\n      __typename\n      id\n      description\n      label\n      isDeleted\n      isGraphQlType\n      revision\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '02644ea0d9a22df091567821743435ff';
export default node;
