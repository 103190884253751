/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-07
 * @description Implementation of the GraphQL mutation to add an podcast story.
 * @filename create-podcast-story
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an podcast.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_PODCAST_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createPodcastStoryMutation($input: CreatePodcastStoryInput!) {
    createPodcastStory(input: $input) {
      clientMutationId
      story {
        ... on PodcastStory {
          id
          title
        }
      }
      hasErrors
    }
  }
`;
