import * as React from "react";

/**
 *
 *
 * @interface ModeratorProps
 */
interface ModeratorProps {
  moderatorId: number;
  roleName: string;
  roleAction: string;
  changeAction(id: number, action: string): void;
}

export default class ModeratorView extends React.Component<ModeratorProps> {
  constructor(props: ModeratorProps) {
    super(props);
  }
  render() {
    return (
      <div className="ModerationPage">
        <label>{this.props.roleName}</label>
        {this.props.roleAction === "" ? (
          <div>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="approve"
                name={this.props.roleName + "actiongroup"}
                type="radio"
                value={this.props.roleAction}
                onChange={() => {
                  this.props.changeAction(
                    this.props.moderatorId,
                    "approve"
                  );
                }}
              />
              <label className="custom-control-label">
                Approved
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="block"
                name={this.props.roleName + "actiongroup"}
                type="radio"
                value={this.props.roleAction}
                onChange={() => {
                  this.props.changeAction(
                    this.props.moderatorId,
                    "block"
                  );
                }}
              />
              <label className="custom-control-label">
                Blocked
              </label>
            </div>
          </div> // else
        ) : this.props.roleAction === "approve" ? (
          <div>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="approve"
                name={this.props.roleName + "actiongroup"}
                type="radio"
                value={this.props.roleAction}
                onChange={() => {
                  this.props.changeAction(
                    this.props.moderatorId,
                    "approve"
                  );
                }}
              />
              <label className="custom-control-label">
                Approved
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="block"
                name={this.props.roleName + "actiongroup"}
                type="radio"
                value={this.props.roleAction}
                onChange={() => {
                  this.props.changeAction(
                    this.props.moderatorId,
                    "block"
                  );
                }}
              />
              <label className="custom-control-label">
                Blocked
              </label>
            </div>
          </div>
        ) : (
              <div>
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="approve"
                    name={this.props.roleName + "actiongroup"}
                    type="radio"
                    value={this.props.roleAction}
                    onChange={() => {
                      this.props.changeAction(
                        this.props.moderatorId,
                        "approve"
                      );
                    }}
                  />
                  <label className="custom-control-label">
                    Approved
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="block"
                    name={this.props.roleName + "actiongroup"}
                    type="radio"
                    value={this.props.roleAction}
                    checked={true}
                    onChange={() => {
                      this.props.changeAction(
                        this.props.moderatorId,
                        "block"
                      );
                    }}
                  />
                  <label className="custom-control-label">
                    Blocked
                  </label>
                </div>
              </div>
            )
          // { roleAction === "approve" ? <label className="approve">
          //     <input className="with-gap approve" id="approve" name={roleName + "actiongroup"} type="radio" value="approved" checked={true} />
          //     <span>Approved</span>
          // </label> : <p>Nothing</p>}
        }
      </div>
    );
  }
}
