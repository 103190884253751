/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-07-04
 * @description Implementation of poster utilities to be used in the app.
 * @filename search.ts
 */

import { PreviewKind, SearchHit } from "@radivision/graphql";
import { fetchQuery } from "react-relay";
import { CarouselItem } from "../../component-configuration/carousel-item";
import { SEARCH_QUERY } from "../../relay/queries/search-query";
import { ENVIRONMENT } from "../../relay/relay-environment";
import { getCarouselItem } from "../general";
import { getFilterQuery, getSearchQuery } from "../search";
const windowSize = window.innerWidth;

export class CloudSearchHelper {
  /**
   *
   *
   * @param {string} text
   * @param {string} [type]
   * @returns {Promise<any>}
   * @memberof CloudSearchHelper
   */
  static search(
    text: string,
    filters?: { field: string; values: string[] }[]
  ): Promise<CarouselItem[]> {
    let promise: Promise<any> = Promise.resolve();
    let items: CarouselItem[];
    let filter: string;

    let searchQueryInput: {
      query: string;
      filterQuery?: string;
      facets?: [
        {
          field: string;
        }
      ];
    };

    console.log(searchQueryInput);

    promise = promise.then(() => {
      if (text === undefined || text === null || text.length < 1) {
        throw new Error("invalid search query");
      }

      if (filters !== undefined && filters !== null && filters.length > 0) {
        filter = getFilterQuery(filters);
      }

      searchQueryInput = {
        query: getSearchQuery(text),
        filterQuery: filter,
      };
      console.log(searchQueryInput);

      return fetchQuery(ENVIRONMENT, SEARCH_QUERY, searchQueryInput);
    });

    promise = promise.then((results) => {
      return results.search.hasErrors === false
        ? Promise.resolve(results.search.connection)
        : Promise.reject(results.errors);
    });

    promise = promise.then((connection: any) => {
      items = connection.edges.map(
        (item: { cursor: string; node: SearchHit }) => {
          return getCarouselItem(
            item.node,
            null,
            0,
            {
              containerWidthRatio: windowSize > 1920 ? 1.2 : 4 / 4,
              numberOfItems: windowSize > 800 ? 4 : 1,
            },
            PreviewKind.SQUARE
          );
        }
      );

      return Promise.resolve(items);
    });

    promise = promise.catch((err) => {
      console.error(err);
      return Promise.resolve();
    });

    return promise;
  }
}
