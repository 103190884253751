/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-05-26
 * @description Implementation of original content utilities to be used in the app.
 * @filename online-course.ts
 */

import { CreateOnlineCourseStoryInput, CreateStoryInput, UpdateOnlineCourseStoryInput, UpdateStoryInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { OnlineCoursePageFormInput } from "../component-configuration/cms-pages";
import { CREATE_ONLINE_COURSE_GRAPHQL_MUTATION } from "../relay/mutations/create-online-course-story";
import { UPDATE_ONLINE_COURSE_STORY_GRAPHQL_MUTATION } from "../relay/mutations/update-online-course-story";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { Story } from "./cms-operation/story";
import { commitMutation } from "./relay";

export class OnlineCourse {
  /**
   * Returns a promise to add new article to database.
   *
   * @param {ArticlePageState} onlineCourseInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addOnlineCourse(
    onlineCourseInput: OnlineCoursePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateOnlineCourseStoryInput
    let createOriginalContentStoryInput: CreateOnlineCourseStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: CreateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      onlineCourseInput.title === undefined ||
      onlineCourseInput.title === null ||
      onlineCourseInput.title.length < 1
    ) {
      promise = Promise.reject(new Error("ewKPtbNTikqyml6GabAI"));
    } else {
      // attempt to create story input
      storyInput = Story.createStoryInput(onlineCourseInput);

      // cause we don't need content moderation
      delete storyInput.moderation;
      // initialize create article graphQl input
      createOriginalContentStoryInput = {
        ...storyInput,
        timeRequiredInSeconds: Number(onlineCourseInput.timeRequiredInSeconds),
        link: onlineCourseInput.link.trim()
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_ONLINE_COURSE_GRAPHQL_MUTATION,
        variables: {
          input: createOriginalContentStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createOnlineCourseStory.hasErrors === false
          ? Promise.resolve(mutationResults.createOnlineCourseStory.story)
          : Promise.reject(mutationResults.createOnlineCourseStory.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updateOnlineCourse(
    onlineCourseInput: OnlineCoursePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateOnlineCourseStoryInput
    let updateOriginalContentStoryInput: UpdateOnlineCourseStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: UpdateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    storyInput = Story.updateStoryInput(onlineCourseInput);

    // initialize create article graphQl input
    updateOriginalContentStoryInput = {
      ...storyInput,
      timeRequiredInSeconds: Number(onlineCourseInput.timeRequiredInSeconds),
      link:
        onlineCourseInput.link && onlineCourseInput.link.length > 0
          ? onlineCourseInput.link.trim()
          : undefined,
      description:
        onlineCourseInput.description &&
        onlineCourseInput.description.length > 0
          ? onlineCourseInput.description.trim()
          : undefined
    };

    // initialize mutation configuration
    config = {
      mutation: UPDATE_ONLINE_COURSE_STORY_GRAPHQL_MUTATION,
      variables: {
        input: updateOriginalContentStoryInput
      }
    };

    //
    promise = commitMutation(environment, config);
    //
    promise = promise.then((mutationResults: any) => {
      return mutationResults.updateOnlineCourseStory.hasErrors === false
        ? Promise.resolve(mutationResults.updateOnlineCourseStory.story)
        : Promise.reject(mutationResults.updateOnlineCourseStory.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteOriginalContent(
    onlineCourseInput: OnlineCoursePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
