/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-08-19
 * @description Implementation of poster utilities to be used in the app.
 * @filename user-management.ts
 */

import { AddUserToGroupInput, RemoveUserFromGroupInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { ADD_USER_FROM_COGNITO_GROUP_GRAPHQL_MUTATION } from "../../relay/mutations/add-user-to-cognito-group";
import { REMOVE_USER_FROM_COGNITO_GROUP_GRAPHQL_MUTATION } from "../../relay/mutations/remove-user-from-cognito-group";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class UserManagement
 */
export class UserManagement {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ArticlePageState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addUserToGroup(
    userName: string,
    groupName: string,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePosterInput
    let addInput: AddUserToGroupInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (userName === undefined || userName === null || userName.length < 1) {
      promise = Promise.reject(new Error("4De2s9yAuk20ERIYmTDxKw"));
    } else {
      // initialize create person graphQl input
      addInput = {
        userName,
        groupName,
        clientMutationId: getClientMutationId()
      };

      // initialize mutation configuration
      config = {
        mutation: ADD_USER_FROM_COGNITO_GROUP_GRAPHQL_MUTATION,
        variables: {
          input: addInput
        }
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.addUserToCognitoGroup.hasErrors === false
          ? Promise.resolve(mutationResults.addUserToCognitoGroup)
          : Promise.reject(mutationResults.addUserToCognitoGroup.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static removeUserFromGroup(
    userName: string,
    groupName: string,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  UpdateUrlRouteInput
    let updatePosterInput: RemoveUserFromGroupInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (userName === undefined || userName === null || userName.length < 1) {
      promise = Promise.reject(new Error("pZeJYWPy3UuFYqrHYkDy"));
    } else {
      // initialize create person graphQl input
      updatePosterInput = {
        userName,
        groupName,
        clientMutationId: getClientMutationId()
      };

      // initialize mutation configuration
      config = {
        mutation: REMOVE_USER_FROM_COGNITO_GROUP_GRAPHQL_MUTATION,
        variables: {
          input: updatePosterInput
        }
      };

      // attempt to commit update person mutation
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.removeUserFromCognitoGroup.hasErrors === false
          ? Promise.resolve(mutationResults.removeUserFromCognitoGroup)
          : Promise.reject(mutationResults.removeUserFromCognitoGroup.errors);
      });
    }

    return promise;
  }
}
