/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-12-24
 * @description Implementation of the GraphQL mutation to add a tag.
 * @filename delete-person.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to delete a person.
 *
 * @type {GraphQLTaggedNode}
 */
export const DELETE_PERSON_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation deletePersonMutation($input: DeletePersonInput!) {
    deletePerson(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        created
      }
      person {
        id
        isDeleted
      }
    }
  }
`;
