/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-05-11
 * @description Implementation of the GraphQL mutation to add a poster story.
 * @filename create-poster.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an podcast.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_POSTER_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createPosterMutation($input: CreatePosterInput!) {
    createPoster(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      poster {
        id
        title
      }
    }
  }
`;
