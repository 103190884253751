import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AuthorIcon = require("../../../../../static/user_icon.png");

interface AuthorDetails {
  authorId: number;
  authorName: string;
  authorImageUrl?: string;
  authorLink?: string;
  deleteAuthor(id: number): any;
}
export default function AuthorView({
  authorId,
  authorName,
  authorImageUrl,
  authorLink,
  deleteAuthor
}: AuthorDetails) {
  return (
    <div className="author-view">
      <div className="">
        <a
          className="waves-effect waves-light"
          href="#"
          onClick={e => {
            e.preventDefault();

            deleteAuthor(authorId);
          }}
        >
          <FontAwesomeIcon icon="times-circle" />
        </a>
      </div>
      <div className="">
        <img className="author-image" src={AuthorIcon} alt="NoImage" />
      </div>
      <div className="author-input">
        <input
          type="text"
          id="author_Name"
          value={authorName}
          readOnly={true}
        />
      </div>
      <div className="">
        <a href={authorLink || "#"} target="_blank">
          <FontAwesomeIcon icon="link" />
        </a>
      </div>
    </div>
  );
}
