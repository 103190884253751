/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SocialMediaPlatformKind = "ANGEL_LIST" | "FACEBOOK" | "INSTAGRAM" | "LINKED_IN" | "MEDIUM" | "OTHER" | "PINTEREST" | "REDDIT" | "SNAPCHAT" | "TUMBLR" | "TWITTER" | "WEIBO" | "WHATS_APP" | "YOU_TUBE" | "%future added value";
export type usersReportQueryVariables = {};
export type usersReportQueryResponse = {
    readonly usersReport: {
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly hasErrors: boolean | null;
        readonly usersReport: {
            readonly confirmed: ReadonlyArray<{
                readonly fullName: string | null;
                readonly id: string;
                readonly created: unknown | null;
                readonly landingPageUrl: string | null;
                readonly emailAddresses: ReadonlyArray<string> | null;
                readonly lastModified: unknown | null;
                readonly lastLogin: unknown | null;
                readonly entities: ReadonlyArray<{
                    readonly __typename: string;
                    readonly id: string;
                    readonly name: string | null;
                }> | null;
                readonly socialMediaProfiles: ReadonlyArray<{
                    readonly id: string;
                    readonly link: string | null;
                    readonly platform: {
                        readonly id: string;
                        readonly kind: SocialMediaPlatformKind | null;
                    } | null;
                }> | null;
            }> | null;
            readonly unconfirmed: ReadonlyArray<{
                readonly name: string | null;
                readonly email: string | null;
                readonly status: string | null;
                readonly createDate: unknown | null;
                readonly lastModifiedDate: unknown | null;
            }> | null;
        } | null;
    } | null;
};
export type usersReportQuery = {
    readonly response: usersReportQueryResponse;
    readonly variables: usersReportQueryVariables;
};



/*
query usersReportQuery {
  usersReport {
    errors {
      id
      location
    }
    hasErrors
    usersReport {
      confirmed {
        fullName
        id
        created
        landingPageUrl
        emailAddresses
        lastModified
        lastLogin
        entities {
          __typename
          id
          name
        }
        socialMediaProfiles {
          id
          link
          platform {
            id
            kind
          }
        }
      }
      unconfirmed {
        name
        email
        status
        createDate
        lastModifiedDate
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "usersReport",
    "storageKey": null,
    "args": null,
    "concreteType": "UserReportPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "usersReport",
        "storageKey": null,
        "args": null,
        "concreteType": "UserReportItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "confirmed",
            "storageKey": null,
            "args": null,
            "concreteType": "Person",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "created",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "landingPageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "emailAddresses",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastModified",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastLogin",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "entities",
                "storageKey": null,
                "args": null,
                "concreteType": "Entity",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  (v1/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "socialMediaProfiles",
                "storageKey": null,
                "args": null,
                "concreteType": "SocialMediaProfile",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "link",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "platform",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SocialMediaPlatform",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "unconfirmed",
            "storageKey": null,
            "args": null,
            "concreteType": "CognitoUser",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastModifiedDate",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "usersReportQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "usersReportQuery",
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "usersReportQuery",
    "id": null,
    "text": "query usersReportQuery {\n  usersReport {\n    errors {\n      id\n      location\n    }\n    hasErrors\n    usersReport {\n      confirmed {\n        fullName\n        id\n        created\n        landingPageUrl\n        emailAddresses\n        lastModified\n        lastLogin\n        entities {\n          __typename\n          id\n          name\n        }\n        socialMediaProfiles {\n          id\n          link\n          platform {\n            id\n            kind\n          }\n        }\n      }\n      unconfirmed {\n        name\n        email\n        status\n        createDate\n        lastModifiedDate\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4d27c28f3fc2a8137fecae3cf7fa54e4';
export default node;
