/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CategoryKind = "ANGEL" | "ENTREPRENEUR" | "FAN" | "INSTITUTIONAL_INVESTOR" | "INVESTMENT_ADVISOR" | "SERIAL_ENTREPRENEUR" | "%future added value";
export type SocialMediaPlatformKind = "ANGEL_LIST" | "FACEBOOK" | "INSTAGRAM" | "LINKED_IN" | "MEDIUM" | "OTHER" | "PINTEREST" | "REDDIT" | "SNAPCHAT" | "TUMBLR" | "TWITTER" | "WEIBO" | "WHATS_APP" | "YOU_TUBE" | "%future added value";
export type WellKnownPositionKind = "BOARD_MEMBER" | "CBO" | "CCO" | "CEO" | "CFO" | "CHAIRMAN" | "CINO" | "CIO" | "CMO" | "COO" | "CRO" | "CSO" | "CTO" | "CXOs" | "DIRECTOR" | "MANAGING_DIRECTOR" | "MANAGING_PARTNER" | "PARTNER" | "PRESIDENT" | "%future added value";
export type CreatePersonInput = {
    readonly activities?: ReadonlyArray<CategoryKind> | null;
    readonly badges?: ReadonlyArray<string> | null;
    readonly clientMutationId: string;
    readonly emailAddresses?: ReadonlyArray<string> | null;
    readonly entities?: ReadonlyArray<string> | null;
    readonly experience?: ReadonlyArray<CreatePositionInput> | null;
    readonly follows?: ReadonlyArray<string> | null;
    readonly fullName?: string | null;
    readonly greeting?: string | null;
    readonly hasPublicPage?: boolean | null;
    readonly inspirations?: ReadonlyArray<CreateInspirationInput> | null;
    readonly investmentsBy?: ReadonlyArray<CreateInvestmentInput> | null;
    readonly locations?: ReadonlyArray<CreateGeographicalLocationInput> | null;
    readonly mediaAbout?: ReadonlyArray<string> | null;
    readonly northStar?: ReadonlyArray<CreateQuestionResponseInput> | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly ratings?: ReadonlyArray<number> | null;
    readonly questionsResponses?: ReadonlyArray<CreateQuestionResponseInput> | null;
    readonly responsibility?: string | null;
    readonly saved?: ReadonlyArray<string> | null;
    readonly socialMediaCredentials?: ReadonlyArray<CreateUpdateSocialMediaCredentialInput> | null;
    readonly socialMediaProfiles?: ReadonlyArray<CreateUpdateSocialMediaProfileInput> | null;
    readonly subscriptions?: ReadonlyArray<string> | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly websiteUrl?: string | null;
};
export type CreatePositionInput = {
    readonly description?: string | null;
    readonly end?: unknown | null;
    readonly entity?: string | null;
    readonly isCurrent?: boolean | null;
    readonly isFounder?: boolean | null;
    readonly start?: unknown | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly title?: string | null;
    readonly wellKnownPositions?: ReadonlyArray<WellKnownPositionKind> | null;
};
export type CreateInspirationInput = {
    readonly inspirer?: string | null;
    readonly why?: string | null;
};
export type CreateInvestmentInput = {
    readonly amount?: string | null;
    readonly currency?: string | null;
    readonly link?: string | null;
    readonly deckUrl?: string | null;
    readonly email?: string | null;
    readonly entity?: string | null;
    readonly fundingRound?: string | null;
    readonly investee?: string | null;
    readonly investmentDate?: unknown | null;
    readonly investmentEndDate?: unknown | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreateGeographicalLocationInput = {
    readonly address?: string | null;
    readonly isDefault?: boolean | null;
    readonly label?: string | null;
    readonly openLocationCode?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreateQuestionResponseInput = {
    readonly choices?: ReadonlyArray<string> | null;
    readonly content?: string | null;
    readonly entityId?: string | null;
    readonly question: string;
    readonly questionnaire: string;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type CreateUpdateSocialMediaCredentialInput = {
    readonly credentials: string;
    readonly platform: SocialMediaPlatformKind;
};
export type CreateUpdateSocialMediaProfileInput = {
    readonly platform: SocialMediaPlatformKind;
    readonly url: string;
};
export type createPersonMutationVariables = {
    readonly input: CreatePersonInput;
};
export type createPersonMutationResponse = {
    readonly createPerson: {
        readonly clientMutationId: string;
        readonly person: {
            readonly __typename: string;
            readonly emailAddresses: ReadonlyArray<string> | null;
            readonly fullName: string | null;
            readonly id: string;
        } | null;
        readonly hasErrors: boolean | null;
    } | null;
};
export type createPersonMutation = {
    readonly response: createPersonMutationResponse;
    readonly variables: createPersonMutationVariables;
};



/*
mutation createPersonMutation(
  $input: CreatePersonInput!
) {
  createPerson(input: $input) {
    clientMutationId
    person {
      __typename
      emailAddresses
      fullName
      id
    }
    hasErrors
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePersonInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createPerson",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdatePersonPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "person",
        "storageKey": null,
        "args": null,
        "concreteType": "Person",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "emailAddresses",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fullName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createPersonMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "createPersonMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "createPersonMutation",
    "id": null,
    "text": "mutation createPersonMutation(\n  $input: CreatePersonInput!\n) {\n  createPerson(input: $input) {\n    clientMutationId\n    person {\n      __typename\n      emailAddresses\n      fullName\n      id\n    }\n    hasErrors\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '55c497d2e3469dbdf943fcb8fb86f2e5';
export default node;
