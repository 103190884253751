/**
 * @author Ahmed Samer
 * @date   2019-08-18
 * @description page to list user  group
 * @filename group.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { Loader } from "../../../page/loader";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FloatingMenu } from "../floating-button/floating-menu";
import ModalConductor from "../modal/modal-conductor";
import { UserManagement } from "../../../../utilities/cms-operation/user-management";
import Modal from "../modal/modal";

const DataTable = require("react-data-components").DataTable;

/**
 *
 *
 * @export
 * @interface GroupPageProps
 */
export interface GroupPageProps {
  /**
   *
   *
   * @type {string}
   * @memberof GroupPageProps
   */
  string: string;

  users: {
    name?: string;
    username: string;
    email?: string;
    created?: string;
    lastModified?: string;
  }[]; // to be replaced with user type;
}

/**
 *
 *
 * @interface GroupPageState
 */
interface GroupPageState {
  /**
   *
   *
   * @type {any []}
   * @memberof GroupPageState
   */
  items: any[];

  /**
   *
   *
   * @type {boolean}
   * @memberof GroupPageState
   */
  isLoading: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof GroupPageState
   */
  selected?: string;

  /**
   *
   *
   * @type {boolean}
   * @memberof GroupPageState
   */
  addModal: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof GroupPageState
   */
  email?: string;

  /**
   *
   *
   * @type {any[]}
   * @memberof GroupPageState
   */
  headers: any[];
}

/**
 *
 *
 * @export
 * @class GroupPage
 * @extends {React.Component<GroupPageProps, GroupPageState>}
 */
export class GroupUsersPage extends React.Component<
  GroupPageProps,
  GroupPageState
> {
  /**
   * Creates an instance of AddItemPage.
   * @param {*} props
   * @memberof AddItemPage
   */
  constructor(props: GroupPageProps) {
    super(props);

    this.addUserAction = this.addUserAction.bind(this);
    this.closeModalAction = this.closeModalAction.bind(this);
    this.deleteUserAction = this.deleteUserAction.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    this.state = this.handleComponentStateInitialize(this.props);
  }

  /**
   *
   *
   * @param {GroupPageProps} props
   * @returns {GroupPageState}
   * @memberof GroupUsersPage
   */
  handleComponentStateInitialize(props: GroupPageProps): GroupPageState {
    const HEADERS = [
      {
        title: "name",
        prop: "name",
        options: {
          filter: true,
          sort: true
        }
      },

      {
        title: "username",
        prop: "username",
        options: {
          filter: true,
          sort: true
        }
      },

      {
        title: "email",
        prop: "email",
        options: {
          filter: false,
          sort: false
        }
      },

      {
        title: "userStatus",
        prop: "userStatus",
        options: {
          filter: false,
          sort: true
        }
      },

      {
        title: "created",
        prop: "created",
        options: {
          filter: true,
          sort: true
        }
      },

      { title: "  ", render: this.deleteBtn, className: "text-center" }
    ];

    let state: GroupPageState = {
      headers: HEADERS,
      items: [],
      addModal: false,
      isLoading: false
    };

    if (props.users) {
      state.items = this.props.users;
    }

    return state;
  }

  deleteBtn = (val, row) => (
    <button
      className="btn add-btn"
      type="button"
      onClick={() => {
        this.deleteUserAction(row.email);
      }}
    >
      <FontAwesomeIcon icon="times-circle" />
    </button>
  );

  /**
   *
   *
   * @param {string} email
   * @returns {Promise<any>}
   * @memberof GroupUsersPage
   */
  deleteUserAction(email: string): Promise<any> {
    this.setState({ isLoading: true });
    return UserManagement.removeUserFromGroup(email, this.props.string)
      .then(res => {
        let items = this.state.items;
        items = items.filter(item => {
          return item.email != email;
        });
        this.setState({ items, isLoading: false });
      })
      .catch(err => {
        // ... handle errors
        this.setState({ isLoading: false });
      });
  }

  /**
   *
   *
   * @param {string} email
   * @returns {Promise<any>}
   * @memberof GroupUsersPage
   */
  addUserAction(email: string): Promise<any> {
    this.setState({ isLoading: true });
    return UserManagement.addUserToGroup(email, this.props.string)
      .then(res => {
        this.setState({ isLoading: false, email: "" });
        window.location.reload();
      })
      .catch(err => {
        // ... handle errors
        this.setState({ isLoading: false, email: "" });
      });
  }

  /**
   *
   *
   * @returns
   * @memberof GroupUsersPage
   */
  render() {
    return (
      <div className="dark-bg">
        <div className="AddItemPage container">
          <div className="row">
            <h1 className="text-center">
              Users List for {'"'}
              {this.props.string}
              {'"'} Group
            </h1>
          </div>
          <div className="row">
            <DataTable
              className="table table-bordered"
              buildRowOptions={this.buildRowOptions}
              keys="username"
              columns={this.state.headers}
              initialData={this.state.items}
              initialPageLength={24}
              initialSortBy={{ prop: "userName", order: "descending" }}
              pageLengthOptions={[20, 40, 60]}
            />
          </div>
        </div>
        <Loader isActive={this.state.isLoading} />
        {this.state.addModal ? (
          <Modal
            title={`Add New User to ${this.props.string} Group`}
            show="true"
            close={this.closeModalAction}
          >
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="User Email"
              value={this.state.email}
              onChange={this.handleEmailChange}
              required
            />

            <div className="action-btn">
              <button
                onClick={() => {
                  this.closeModalAction();
                  this.addUserAction(this.state.email);
                }}
                className="btn btn-radivision dark"
              >
                Add User
              </button>
            </div>
          </Modal>
        ) : (
          <div />
        )}
        <FloatingMenu
          menuItems={{
            other: {
              name: "Add User",
              action: () => {
                this.setState({ addModal: true });
              }
            }
          }}
        />
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in email state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof GroupUsersPage
   */
  handleEmailChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let email: string = this.state.email;
    let eventValue: string = changeEvent.currentTarget.value;

    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    email = eventValue;
    this.setState({ email });
  }

  /**
   *
   *
   * @memberof GroupUsersPage
   */
  closeModalAction() {
    this.setState({ addModal: false });
  }

  getInitialState = () => {
    return { selected: "" };
  };

  selectRow = (row: any) => {};

  buildRowOptions = (row: any) => {
    return {
      onClick: this.selectRow.bind(this, row),
      className: this.state.selected === row.id ? "active" : null
    };
  };
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class GroupPageContainer extends React.Component<
  GroupPageProps,
  GroupPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let name: string;
    name = this.props.string;

    node = (
      <QueryRenderer
        environment={ENVIRONMENT}
        query={graphql`
          query groupQuery($name: String!) {
            ListGroupUsers(name: $name) {
              errors {
                id
                location
              }
              hasErrors
              groupUser {
                name
                email
                username
                userStatus
                created
                lastModified
              }
            }
          }
        `}
        variables={{
          name: name
        }}
        render={({ error, props }) => {
          if (error) {
            // console\.log(error);
            return <div>{error.message}</div>;
          } else if (props) {
            // console\.log(props);
            if (props.ListGroupUsers.hasErrors) {
              return (
                <div>
                  <p>
                    unexpected error : {JSON.stringify(props.errors)}, contact
                    Radivision Technical team.
                  </p>{" "}
                </div>
              );
            }
            //  else if (
            //   props.story.node.__typename !== GRAPHQL_TYPE_ARTICLE_STORY
            // ) {
            //   return (
            //     <div>
            //       <h2 className="font-weight-bold text-left">
            //         {" "}
            //         Invalid Article id, {articleId} belongs to type{" "}
            //         {props.story.node.__typename}, Need more help? contact
            //         Radivision Technical team.
            //       </h2>
            //     </div>
            //   );
            // }
            else if (props.ListGroupUsers.groupUser) {
              let pageProps: GroupPageProps = {
                ...this.props,
                users: props.ListGroupUsers.groupUser
              };
              return <GroupUsersPage {...pageProps} />;
            }
          }
          return <Loader isActive={true} />;
        }}
      />
    );

    return node;
  }
}
