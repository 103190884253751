/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type marketEntryPageQueryVariables = {
    readonly id: string;
};
export type marketEntryPageQueryResponse = {
    readonly story: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly node: ({
            readonly __typename: string;
            readonly id?: string;
            readonly revision?: string | null;
            readonly landingPageUrl?: string | null;
            readonly offerType?: string | null;
            readonly description?: string | null;
            readonly promoCode?: string | null;
            readonly subTitle?: string | null;
            readonly isPromoted?: boolean | null;
            readonly limited?: boolean | null;
            readonly expires?: unknown | null;
            readonly title?: string | null;
            readonly label?: string | null;
            readonly link?: string | null;
            readonly tags?: ReadonlyArray<{
                readonly id: string;
                readonly label: string | null;
            }> | null;
            readonly peopleEntities?: ReadonlyArray<{
                readonly person: {
                    readonly landingPageUrl: string | null;
                    readonly id: string;
                } | null;
                readonly entity: {
                    readonly landingPageUrl: string | null;
                    readonly id: string;
                } | null;
            }> | null;
            readonly previews?: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly id: string;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
        } & ({
            readonly __typename: "MarketEntryStory";
            readonly id: string;
            readonly revision: string | null;
            readonly landingPageUrl: string | null;
            readonly offerType: string | null;
            readonly description: string | null;
            readonly promoCode: string | null;
            readonly subTitle: string | null;
            readonly isPromoted: boolean | null;
            readonly limited: boolean | null;
            readonly expires: unknown | null;
            readonly title: string | null;
            readonly label: string | null;
            readonly link: string | null;
            readonly tags: ReadonlyArray<{
                readonly id: string;
                readonly label: string | null;
            }> | null;
            readonly peopleEntities: ReadonlyArray<{
                readonly person: {
                    readonly landingPageUrl: string | null;
                    readonly id: string;
                } | null;
                readonly entity: {
                    readonly landingPageUrl: string | null;
                    readonly id: string;
                } | null;
            }> | null;
            readonly previews: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly id: string;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
        } | {
            /*This will never be '% other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        })) | null;
    } | null;
};
export type marketEntryPageQuery = {
    readonly response: marketEntryPageQueryResponse;
    readonly variables: marketEntryPageQueryVariables;
};



/*
query marketEntryPageQuery(
  $id: ID!
) {
  story(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    node {
      __typename
      ... on MarketEntryStory {
        __typename
        id
        revision
        landingPageUrl
        offerType
        description
        promoCode
        subTitle
        isPromoted
        limited
        expires
        title
        label
        link
        tags {
          id
          label
        }
        peopleEntities {
          person {
            landingPageUrl
            id
          }
          entity {
            landingPageUrl
            id
          }
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                  id
                }
              }
            }
          }
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageUrl",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "offerType",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "promoCode",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isPromoted",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "limited",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expires",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": null,
  "args": null,
  "concreteType": "Tag",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v16/*: any*/)
  ]
},
v19 = [
  (v7/*: any*/),
  (v3/*: any*/)
],
v20 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "peopleEntities",
  "storageKey": null,
  "args": null,
  "concreteType": "EntityPerson",
  "plural": true,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "person",
      "storageKey": null,
      "args": null,
      "concreteType": "Person",
      "plural": false,
      "selections": (v19/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "entity",
      "storageKey": null,
      "args": null,
      "concreteType": "Entity",
      "plural": false,
      "selections": (v19/*: any*/)
    }
  ]
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "path",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "marketEntryPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "story",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "MarketEntryStory",
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "previews",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewsConnection",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewEdge",
                        "plural": true,
                        "selections": [
                          (v22/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "preview",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Preview",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v23/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mediaAsset",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "MediaAsset",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v24/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v23/*: any*/),
                                      (v16/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "marketEntryPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "story",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "MarketEntryStory",
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "previews",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewsConnection",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewEdge",
                        "plural": true,
                        "selections": [
                          (v22/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "preview",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Preview",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v23/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mediaAsset",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "MediaAsset",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v24/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v23/*: any*/),
                                      (v16/*: any*/),
                                      (v3/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "marketEntryPageQuery",
    "id": null,
    "text": "query marketEntryPageQuery(\n  $id: ID!\n) {\n  story(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    node {\n      __typename\n      ... on MarketEntryStory {\n        __typename\n        id\n        revision\n        landingPageUrl\n        offerType\n        description\n        promoCode\n        subTitle\n        isPromoted\n        limited\n        expires\n        title\n        label\n        link\n        tags {\n          id\n          label\n        }\n        peopleEntities {\n          person {\n            landingPageUrl\n            id\n          }\n          entity {\n            landingPageUrl\n            id\n          }\n        }\n        previews {\n          count\n          edges {\n            cursor\n            preview {\n              link\n              kind\n              mediaAsset {\n                id\n                files {\n                  id\n                  name\n                  path\n                }\n                topLevelMediaType {\n                  kind\n                  label\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '6a766f67cb777633cef6bb569da99b30';
export default node;
