/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { htmlPagePanels_panels$ref } from "./htmlPagePanels_panels.graphql";
export type MediaAssetFileStatusKind = "AVAILABLE" | "CREATED" | "DELETED" | "ERROR" | "PROCESSING" | "%future added value";
export type MediaAssetStateKind = "AVAILABLE" | "CREATED" | "DELETED" | "PROCESSING" | "%future added value";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type SocialMediaPlatformKind = "ANGEL_LIST" | "FACEBOOK" | "INSTAGRAM" | "LINKED_IN" | "MEDIUM" | "OTHER" | "PINTEREST" | "REDDIT" | "SNAPCHAT" | "TUMBLR" | "TWITTER" | "WEIBO" | "WHATS_APP" | "YOU_TUBE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
declare const _htmlPage_htmlDocument$ref: unique symbol;
export type htmlPage_htmlDocument$ref = typeof _htmlPage_htmlDocument$ref;
export type htmlPage_htmlDocument = {
    readonly urlRouteByAlias: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly created: unknown | null;
            readonly id: string;
            readonly location: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly node: {
            readonly id: string;
            readonly panels: ReadonlyArray<{
                readonly " $fragmentRefs": htmlPagePanels_panels$ref;
            }> | null;
            readonly user: {
                readonly __typename: string;
                readonly description: string | null;
                readonly emailAddresses: ReadonlyArray<string> | null;
                readonly fullName: string | null;
                readonly hasPublicPage: boolean | null;
                readonly landingPageUrl: string | null;
                readonly id: string;
                readonly revision: string | null;
                readonly requests: ReadonlyArray<{
                    readonly __typename: "ClaimRequest";
                    readonly id?: string;
                    readonly created?: unknown | null;
                    readonly expired?: unknown | null;
                    readonly description?: string | null;
                    readonly item?: ({
                        readonly __typename: "Person";
                        readonly id?: string;
                    } & ({
                        readonly __typename: "Person";
                        readonly id: string;
                    } | {
                        /*This will never be '% other', but we need some
                        value in case none of the concrete values match.*/
                        readonly __typename: "%other";
                    })) | null;
                    readonly revision?: string | null;
                    readonly status?: string | null;
                    readonly audioMediaAsset?: {
                        readonly id: string;
                        readonly files: ReadonlyArray<{
                            readonly id: string;
                            readonly name: string | null;
                            readonly heightInPixels: number | null;
                            readonly widthInPixels: number | null;
                            readonly path: string | null;
                            readonly status: {
                                readonly kind: MediaAssetFileStatusKind | null;
                            } | null;
                        }> | null;
                        readonly state: {
                            readonly kind: MediaAssetStateKind | null;
                        } | null;
                        readonly topLevelMediaType: {
                            readonly kind: TopLevelMediaKind | null;
                        } | null;
                    } | null;
                    readonly metaData?: {
                        readonly full_name: string | null;
                        readonly title: string | null;
                        readonly company_name: string | null;
                        readonly company_url: string | null;
                        readonly term_agreement: boolean | null;
                    } | null;
                    readonly previews?: {
                        readonly count: number | null;
                        readonly edges: ReadonlyArray<{
                            readonly cursor: string | null;
                            readonly preview: {
                                readonly link: string | null;
                                readonly mediaAsset: {
                                    readonly id: string;
                                    readonly files: ReadonlyArray<{
                                        readonly id: string;
                                        readonly name: string | null;
                                        readonly path: string | null;
                                    }> | null;
                                    readonly topLevelMediaType: {
                                        readonly kind: TopLevelMediaKind | null;
                                        readonly label: string | null;
                                    } | null;
                                } | null;
                                readonly kind: PreviewKind | null;
                            } | null;
                        }> | null;
                    } | null;
                    readonly sample?: {
                        readonly id: string;
                        readonly files: ReadonlyArray<{
                            readonly id: string;
                            readonly name: string | null;
                            readonly heightInPixels: number | null;
                            readonly widthInPixels: number | null;
                            readonly path: string | null;
                            readonly status: {
                                readonly kind: MediaAssetFileStatusKind | null;
                            } | null;
                        }> | null;
                        readonly state: {
                            readonly kind: MediaAssetStateKind | null;
                        } | null;
                        readonly topLevelMediaType: {
                            readonly kind: TopLevelMediaKind | null;
                        } | null;
                    } | null;
                } & ({
                    readonly __typename: "ClaimRequest";
                    readonly id: string;
                    readonly created: unknown | null;
                    readonly expired: unknown | null;
                    readonly description: string | null;
                    readonly item: ({
                        readonly __typename: "Person";
                        readonly id?: string;
                    } & ({
                        readonly __typename: "Person";
                        readonly id: string;
                    } | {
                        /*This will never be '% other', but we need some
                        value in case none of the concrete values match.*/
                        readonly __typename: "%other";
                    })) | null;
                } | {
                    readonly revision: string | null;
                    readonly status: string | null;
                    readonly audioMediaAsset: {
                        readonly id: string;
                        readonly files: ReadonlyArray<{
                            readonly id: string;
                            readonly name: string | null;
                            readonly heightInPixels: number | null;
                            readonly widthInPixels: number | null;
                            readonly path: string | null;
                            readonly status: {
                                readonly kind: MediaAssetFileStatusKind | null;
                            } | null;
                        }> | null;
                        readonly state: {
                            readonly kind: MediaAssetStateKind | null;
                        } | null;
                        readonly topLevelMediaType: {
                            readonly kind: TopLevelMediaKind | null;
                        } | null;
                    } | null;
                    readonly metaData: {
                        readonly full_name: string | null;
                        readonly title: string | null;
                        readonly company_name: string | null;
                        readonly company_url: string | null;
                        readonly term_agreement: boolean | null;
                    } | null;
                    readonly previews: {
                        readonly count: number | null;
                        readonly edges: ReadonlyArray<{
                            readonly cursor: string | null;
                            readonly preview: {
                                readonly link: string | null;
                                readonly mediaAsset: {
                                    readonly id: string;
                                    readonly files: ReadonlyArray<{
                                        readonly id: string;
                                        readonly name: string | null;
                                        readonly path: string | null;
                                    }> | null;
                                    readonly topLevelMediaType: {
                                        readonly kind: TopLevelMediaKind | null;
                                        readonly label: string | null;
                                    } | null;
                                } | null;
                                readonly kind: PreviewKind | null;
                            } | null;
                        }> | null;
                    } | null;
                    readonly sample: {
                        readonly id: string;
                        readonly files: ReadonlyArray<{
                            readonly id: string;
                            readonly name: string | null;
                            readonly heightInPixels: number | null;
                            readonly widthInPixels: number | null;
                            readonly path: string | null;
                            readonly status: {
                                readonly kind: MediaAssetFileStatusKind | null;
                            } | null;
                        }> | null;
                        readonly state: {
                            readonly kind: MediaAssetStateKind | null;
                        } | null;
                        readonly topLevelMediaType: {
                            readonly kind: TopLevelMediaKind | null;
                        } | null;
                    } | null;
                } | {
                    /*This will never be '% other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                })> | null;
                readonly isEditable: boolean | null;
                readonly defaultLocation: {
                    readonly id: string | null;
                    readonly label: string | null;
                } | null;
                readonly locations: ReadonlyArray<{
                    readonly id: string | null;
                    readonly label: string | null;
                }> | null;
                readonly entities: ReadonlyArray<{
                    readonly __typename: string;
                    readonly name: string | null;
                    readonly id: string;
                    readonly revision: string | null;
                    readonly hasPublicPage: boolean | null;
                    readonly landingPageUrl: string | null;
                    readonly description: string | null;
                    readonly websiteUrl: string | null;
                    readonly isEditable: boolean | null;
                    readonly previews: {
                        readonly edges: ReadonlyArray<{
                            readonly preview: {
                                readonly link: string | null;
                                readonly mediaAsset: {
                                    readonly id: string;
                                    readonly files: ReadonlyArray<{
                                        readonly name: string | null;
                                        readonly path: string | null;
                                    }> | null;
                                    readonly topLevelMediaType: {
                                        readonly kind: TopLevelMediaKind | null;
                                    } | null;
                                } | null;
                                readonly kind: PreviewKind | null;
                            } | null;
                        }> | null;
                    } | null;
                }> | null;
                readonly experience: {
                    readonly edges: ReadonlyArray<{
                        readonly position: {
                            readonly id: string;
                            readonly entity: {
                                readonly __typename: string;
                                readonly name: string | null;
                                readonly description: string | null;
                                readonly websiteUrl: string | null;
                                readonly landingPageUrl: string | null;
                                readonly id: string;
                                readonly hasPublicPage: boolean | null;
                                readonly isEditable: boolean | null;
                                readonly previews: {
                                    readonly edges: ReadonlyArray<{
                                        readonly preview: {
                                            readonly link: string | null;
                                            readonly mediaAsset: {
                                                readonly files: ReadonlyArray<{
                                                    readonly name: string | null;
                                                    readonly path: string | null;
                                                }> | null;
                                                readonly topLevelMediaType: {
                                                    readonly kind: TopLevelMediaKind | null;
                                                } | null;
                                            } | null;
                                            readonly kind: PreviewKind | null;
                                        } | null;
                                    }> | null;
                                } | null;
                            } | null;
                        } | null;
                    }> | null;
                } | null;
                readonly inspirations: ReadonlyArray<{
                    readonly id: string;
                    readonly inspirer: ({
                        readonly __typename: "Entity";
                        readonly id?: string;
                        readonly name?: string | null;
                        readonly landingPageUrl?: string | null;
                        readonly websiteUrl?: string | null;
                        readonly hasPublicPage?: boolean | null;
                        readonly isEditable?: boolean | null;
                        readonly previews?: {
                            readonly edges: ReadonlyArray<{
                                readonly preview: {
                                    readonly link: string | null;
                                    readonly mediaAsset: {
                                        readonly id: string;
                                        readonly files: ReadonlyArray<{
                                            readonly name: string | null;
                                            readonly path: string | null;
                                        }> | null;
                                        readonly topLevelMediaType: {
                                            readonly kind: TopLevelMediaKind | null;
                                        } | null;
                                    } | null;
                                    readonly kind: PreviewKind | null;
                                } | null;
                            }> | null;
                        } | null;
                    } & ({
                        readonly __typename: "Entity";
                        readonly id: string;
                        readonly name: string | null;
                        readonly landingPageUrl: string | null;
                        readonly websiteUrl: string | null;
                        readonly hasPublicPage: boolean | null;
                        readonly isEditable: boolean | null;
                        readonly previews: {
                            readonly edges: ReadonlyArray<{
                                readonly preview: {
                                    readonly link: string | null;
                                    readonly mediaAsset: {
                                        readonly id: string;
                                        readonly files: ReadonlyArray<{
                                            readonly name: string | null;
                                            readonly path: string | null;
                                        }> | null;
                                        readonly topLevelMediaType: {
                                            readonly kind: TopLevelMediaKind | null;
                                        } | null;
                                    } | null;
                                    readonly kind: PreviewKind | null;
                                } | null;
                            }> | null;
                        } | null;
                    } | {
                        /*This will never be '% other', but we need some
                        value in case none of the concrete values match.*/
                        readonly __typename: "%other";
                    })) | null;
                }> | null;
                readonly investmentsBy: ReadonlyArray<{
                    readonly id: string | null;
                    readonly entity: {
                        readonly __typename: string;
                        readonly id: string;
                        readonly name: string | null;
                        readonly landingPageUrl: string | null;
                        readonly websiteUrl: string | null;
                        readonly hasPublicPage: boolean | null;
                        readonly isEditable: boolean | null;
                        readonly previews: {
                            readonly edges: ReadonlyArray<{
                                readonly preview: {
                                    readonly link: string | null;
                                    readonly mediaAsset: {
                                        readonly id: string;
                                        readonly files: ReadonlyArray<{
                                            readonly name: string | null;
                                            readonly path: string | null;
                                        }> | null;
                                        readonly topLevelMediaType: {
                                            readonly kind: TopLevelMediaKind | null;
                                        } | null;
                                    } | null;
                                    readonly kind: PreviewKind | null;
                                } | null;
                            }> | null;
                        } | null;
                    } | null;
                    readonly investee: {
                        readonly __typename: string;
                        readonly name: string | null;
                        readonly id: string;
                        readonly landingPageUrl: string | null;
                        readonly websiteUrl: string | null;
                        readonly hasPublicPage: boolean | null;
                        readonly isEditable: boolean | null;
                        readonly previews: {
                            readonly edges: ReadonlyArray<{
                                readonly preview: {
                                    readonly link: string | null;
                                    readonly mediaAsset: {
                                        readonly id: string;
                                        readonly files: ReadonlyArray<{
                                            readonly name: string | null;
                                            readonly path: string | null;
                                        }> | null;
                                        readonly topLevelMediaType: {
                                            readonly kind: TopLevelMediaKind | null;
                                        } | null;
                                    } | null;
                                    readonly kind: PreviewKind | null;
                                } | null;
                            }> | null;
                        } | null;
                    } | null;
                }> | null;
                readonly follows: ReadonlyArray<{
                    readonly __typename: "Entity";
                    readonly name?: string | null;
                    readonly id?: string;
                    readonly landingPageUrl?: string | null;
                    readonly previews?: {
                        readonly edges: ReadonlyArray<{
                            readonly preview: {
                                readonly link: string | null;
                                readonly mediaAsset: {
                                    readonly id: string;
                                    readonly files: ReadonlyArray<{
                                        readonly name: string | null;
                                        readonly path: string | null;
                                    }> | null;
                                    readonly topLevelMediaType: {
                                        readonly kind: TopLevelMediaKind | null;
                                    } | null;
                                } | null;
                                readonly kind: PreviewKind | null;
                            } | null;
                        }> | null;
                    } | null;
                    readonly description?: string | null;
                    readonly fullName?: string | null;
                    readonly hasPublicPage?: boolean | null;
                    readonly greeting?: string | null;
                } & ({
                    readonly __typename: "Entity";
                    readonly name: string | null;
                    readonly id: string;
                    readonly landingPageUrl: string | null;
                    readonly previews: {
                        readonly edges: ReadonlyArray<{
                            readonly preview: {
                                readonly link: string | null;
                                readonly mediaAsset: {
                                    readonly id: string;
                                    readonly files: ReadonlyArray<{
                                        readonly name: string | null;
                                        readonly path: string | null;
                                    }> | null;
                                    readonly topLevelMediaType: {
                                        readonly kind: TopLevelMediaKind | null;
                                    } | null;
                                } | null;
                                readonly kind: PreviewKind | null;
                            } | null;
                        }> | null;
                    } | null;
                } | {
                    readonly description: string | null;
                    readonly fullName: string | null;
                    readonly hasPublicPage: boolean | null;
                    readonly greeting: string | null;
                } | {
                    /*This will never be '% other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                })> | null;
                readonly mediaAbout: {
                    readonly edges: ReadonlyArray<{
                        readonly story: ({
                            readonly __typename: string;
                            readonly id?: string;
                            readonly link?: string | null;
                            readonly previews?: {
                                readonly edges: ReadonlyArray<{
                                    readonly preview: {
                                        readonly link: string | null;
                                        readonly kind: PreviewKind | null;
                                        readonly mediaAsset: {
                                            readonly files: ReadonlyArray<{
                                                readonly name: string | null;
                                                readonly path: string | null;
                                            }> | null;
                                            readonly topLevelMediaType: {
                                                readonly kind: TopLevelMediaKind | null;
                                            } | null;
                                            readonly id: string;
                                        } | null;
                                    } | null;
                                }> | null;
                            } | null;
                            readonly subTitle?: string | null;
                            readonly title?: string | null;
                            readonly description?: string | null;
                            readonly landingPageUrl?: string | null;
                            readonly content?: string | null;
                            readonly revision?: string | null;
                            readonly publisher?: string | null;
                            readonly publishedDate?: unknown | null;
                        } & ({
                            readonly __typename: "RadivisionPost";
                            readonly id: string;
                            readonly link: string | null;
                            readonly previews: {
                                readonly edges: ReadonlyArray<{
                                    readonly preview: {
                                        readonly link: string | null;
                                        readonly kind: PreviewKind | null;
                                        readonly mediaAsset: {
                                            readonly files: ReadonlyArray<{
                                                readonly name: string | null;
                                                readonly path: string | null;
                                            }> | null;
                                            readonly topLevelMediaType: {
                                                readonly kind: TopLevelMediaKind | null;
                                            } | null;
                                            readonly id: string;
                                        } | null;
                                    } | null;
                                }> | null;
                            } | null;
                            readonly subTitle: string | null;
                            readonly title: string | null;
                            readonly description: string | null;
                            readonly landingPageUrl: string | null;
                            readonly content: string | null;
                            readonly revision: string | null;
                        } | {
                            readonly __typename: "ArticleStory";
                            readonly publisher: string | null;
                            readonly publishedDate: unknown | null;
                        } | {
                            /*This will never be '% other', but we need some
                            value in case none of the concrete values match.*/
                            readonly __typename: "%other";
                        })) | null;
                    }> | null;
                } | null;
                readonly previews: {
                    readonly edges: ReadonlyArray<{
                        readonly preview: {
                            readonly link: string | null;
                            readonly mediaAsset: {
                                readonly id: string;
                                readonly files: ReadonlyArray<{
                                    readonly name: string | null;
                                    readonly path: string | null;
                                }> | null;
                                readonly topLevelMediaType: {
                                    readonly kind: TopLevelMediaKind | null;
                                } | null;
                            } | null;
                            readonly kind: PreviewKind | null;
                        } | null;
                    }> | null;
                } | null;
                readonly northStar: {
                    readonly __typename: string;
                    readonly description: string | null;
                    readonly id: string;
                    readonly questions: ReadonlyArray<{
                        readonly __typename: string;
                        readonly content?: string | null;
                        readonly id?: string;
                        readonly response?: {
                            readonly content: string | null;
                            readonly id: string;
                        } | null;
                    } & ({
                        readonly __typename: "TextQuestion";
                        readonly content: string | null;
                        readonly id: string;
                        readonly response: {
                            readonly content: string | null;
                            readonly id: string;
                        } | null;
                    } | {
                        /*This will never be '% other', but we need some
                        value in case none of the concrete values match.*/
                        readonly __typename: "%other";
                    })> | null;
                } | null;
                readonly questionnaires: {
                    readonly count: number | null;
                    readonly edges: ReadonlyArray<{
                        readonly cursor: string | null;
                        readonly node: {
                            readonly id: string;
                            readonly title: string | null;
                            readonly questions: ReadonlyArray<{
                                readonly __typename: string;
                                readonly id?: string;
                                readonly title?: string | null;
                                readonly response?: {
                                    readonly id: string;
                                    readonly choices: ReadonlyArray<{
                                        readonly acceptsText: boolean | null;
                                        readonly id: string;
                                    }> | null;
                                    readonly content?: string | null;
                                } | null;
                                readonly content?: string | null;
                            } & ({
                                readonly __typename: "MultipleChoiceQuestion";
                                readonly id: string;
                                readonly title: string | null;
                                readonly response: {
                                    readonly id: string;
                                    readonly choices: ReadonlyArray<{
                                        readonly acceptsText: boolean | null;
                                        readonly id: string;
                                    }> | null;
                                    readonly content?: string | null;
                                } | null;
                            } | {
                                readonly __typename: "TextQuestion";
                                readonly content: string | null;
                            } | {
                                /*This will never be '% other', but we need some
                                value in case none of the concrete values match.*/
                                readonly __typename: "%other";
                            })> | null;
                        } | null;
                    }> | null;
                } | null;
                readonly responsibility: string | null;
                readonly socialMediaCredentials: ReadonlyArray<{
                    readonly credential: string | null;
                    readonly platform: {
                        readonly kind: SocialMediaPlatformKind | null;
                    };
                }> | null;
                readonly websiteUrl: string | null;
            } | null;
        } | null;
    } | null;
    readonly " $refType": htmlPage_htmlDocument$ref;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasPublicPage",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v1/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v12 = [
  (v11/*: any*/)
],
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "topLevelMediaType",
  "storageKey": null,
  "args": null,
  "concreteType": "TopLevelMediaType",
  "plural": false,
  "selections": (v12/*: any*/)
},
v14 = [
  (v1/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "files",
    "storageKey": null,
    "args": null,
    "concreteType": "MediaAssetFile",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v9/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "heightInPixels",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "widthInPixels",
        "args": null,
        "storageKey": null
      },
      (v10/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "status",
        "storageKey": null,
        "args": null,
        "concreteType": "MediaAssetFileStatus",
        "plural": false,
        "selections": (v12/*: any*/)
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "state",
    "storageKey": null,
    "args": null,
    "concreteType": "MediaAssetState",
    "plural": false,
    "selections": (v12/*: any*/)
  },
  (v13/*: any*/)
],
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isEditable",
  "args": null,
  "storageKey": null
},
v21 = [
  (v1/*: any*/),
  (v19/*: any*/)
],
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "websiteUrl",
  "args": null,
  "storageKey": null
},
v23 = [
  {
    "kind": "Literal",
    "name": "kinds",
    "value": [
      "SQUARE",
      "LOGO"
    ]
  }
],
v24 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/)
  ]
},
v25 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "PreviewEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "preview",
        "storageKey": null,
        "args": null,
        "concreteType": "Preview",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "mediaAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "MediaAsset",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v24/*: any*/),
              (v13/*: any*/)
            ]
          },
          (v11/*: any*/)
        ]
      }
    ]
  }
],
v26 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "previews",
  "storageKey": "previews(kinds:[\"SQUARE\",\"LOGO\"])",
  "args": (v23/*: any*/),
  "concreteType": "PreviewsConnection",
  "plural": false,
  "selections": (v25/*: any*/)
},
v27 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "PreviewEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "preview",
        "storageKey": null,
        "args": null,
        "concreteType": "Preview",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "mediaAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "MediaAsset",
            "plural": false,
            "selections": [
              (v24/*: any*/),
              (v13/*: any*/)
            ]
          },
          (v11/*: any*/)
        ]
      }
    ]
  }
],
v28 = [
  (v2/*: any*/),
  (v1/*: any*/),
  (v9/*: any*/),
  (v6/*: any*/),
  (v22/*: any*/),
  (v5/*: any*/),
  (v20/*: any*/),
  (v26/*: any*/)
],
v29 = [
  {
    "kind": "Literal",
    "name": "kinds",
    "value": [
      "SQUARE"
    ]
  }
],
v30 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
},
v31 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v32 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "previews",
  "storageKey": "previews(kinds:[\"SQUARE\"])",
  "args": (v29/*: any*/),
  "concreteType": "PreviewsConnection",
  "plural": false,
  "selections": (v25/*: any*/)
},
v33 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publisher",
  "args": null,
  "storageKey": null
},
v34 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedDate",
  "args": null,
  "storageKey": null
},
v35 = [
  (v1/*: any*/),
  (v18/*: any*/),
  (v33/*: any*/),
  (v34/*: any*/),
  (v32/*: any*/),
  (v30/*: any*/),
  (v15/*: any*/)
],
v36 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "response",
  "storageKey": null,
  "args": null,
  "concreteType": "TextQuestionResponse",
  "plural": false,
  "selections": [
    (v31/*: any*/),
    (v1/*: any*/)
  ]
};
return {
  "kind": "Fragment",
  "name": "htmlPage_htmlDocument",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "alias",
      "type": "String!"
    },
    {
      "kind": "RootArgument",
      "name": "userIdentityId",
      "type": "ID"
    },
    {
      "kind": "RootArgument",
      "name": "queryParameters",
      "type": "[String!]"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "urlRouteByAlias",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "alias",
          "variableName": "alias"
        },
        {
          "kind": "Variable",
          "name": "queryParameters",
          "variableName": "queryParameters"
        },
        {
          "kind": "Variable",
          "name": "userIdentityId",
          "variableName": "userIdentityId"
        }
      ],
      "concreteType": "HtmlDocumentPayload",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hasErrors",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "errors",
          "storageKey": null,
          "args": null,
          "concreteType": "Error",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "location",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "type",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "HtmlDocument",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "panels",
              "storageKey": null,
              "args": null,
              "concreteType": "HtmlDocumentPanel",
              "plural": true,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "htmlPagePanels_panels",
                  "args": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "user",
              "storageKey": null,
              "args": null,
              "concreteType": "Person",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "emailAddresses",
                  "args": null,
                  "storageKey": null
                },
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v1/*: any*/),
                (v7/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "requests",
                  "storageKey": null,
                  "args": null,
                  "concreteType": null,
                  "plural": true,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "type": "ClaimRequest",
                      "selections": [
                        (v2/*: any*/),
                        (v1/*: any*/),
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "expired",
                          "args": null,
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "item",
                          "storageKey": null,
                          "args": null,
                          "concreteType": null,
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "type": "Person",
                              "selections": (v8/*: any*/)
                            },
                            {
                              "kind": "InlineFragment",
                              "type": "Entity",
                              "selections": (v8/*: any*/)
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "InlineFragment",
                      "type": "UGCRequest",
                      "selections": [
                        (v2/*: any*/),
                        (v1/*: any*/),
                        (v0/*: any*/),
                        (v7/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "status",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "audioMediaAsset",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "MediaAsset",
                          "plural": false,
                          "selections": (v14/*: any*/)
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "metaData",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "MetaData",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "full_name",
                              "args": null,
                              "storageKey": null
                            },
                            (v15/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "company_name",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "company_url",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "term_agreement",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "previews",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PreviewsConnection",
                          "plural": false,
                          "selections": [
                            (v16/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "PreviewEdge",
                              "plural": true,
                              "selections": [
                                (v17/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "preview",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "Preview",
                                  "plural": false,
                                  "selections": [
                                    (v18/*: any*/),
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "mediaAsset",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "MediaAsset",
                                      "plural": false,
                                      "selections": [
                                        (v1/*: any*/),
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "files",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "MediaAssetFile",
                                          "plural": true,
                                          "selections": [
                                            (v1/*: any*/),
                                            (v9/*: any*/),
                                            (v10/*: any*/)
                                          ]
                                        },
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "topLevelMediaType",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "TopLevelMediaType",
                                          "plural": false,
                                          "selections": [
                                            (v11/*: any*/),
                                            (v19/*: any*/)
                                          ]
                                        }
                                      ]
                                    },
                                    (v11/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "sample",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "MediaAsset",
                          "plural": false,
                          "selections": (v14/*: any*/)
                        }
                      ]
                    }
                  ]
                },
                (v20/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "defaultLocation",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "GeographicalLocation",
                  "plural": false,
                  "selections": (v21/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "locations",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "GeographicalLocation",
                  "plural": true,
                  "selections": (v21/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "entities",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Entity",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    (v9/*: any*/),
                    (v1/*: any*/),
                    (v7/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v3/*: any*/),
                    (v22/*: any*/),
                    (v20/*: any*/),
                    (v26/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "experience",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PositionsConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PositionEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "position",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Position",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "entity",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "Entity",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/),
                                (v9/*: any*/),
                                (v3/*: any*/),
                                (v22/*: any*/),
                                (v6/*: any*/),
                                (v1/*: any*/),
                                (v5/*: any*/),
                                (v20/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "previews",
                                  "storageKey": "previews(kinds:[\"SQUARE\",\"LOGO\"])",
                                  "args": (v23/*: any*/),
                                  "concreteType": "PreviewsConnection",
                                  "plural": false,
                                  "selections": (v27/*: any*/)
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "inspirations",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Inspiration",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "inspirer",
                      "storageKey": null,
                      "args": null,
                      "concreteType": null,
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "type": "Entity",
                          "selections": (v28/*: any*/)
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "investmentsBy",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Investment",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "entity",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Entity",
                      "plural": false,
                      "selections": (v28/*: any*/)
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "investee",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Entity",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v9/*: any*/),
                        (v1/*: any*/),
                        (v6/*: any*/),
                        (v22/*: any*/),
                        (v5/*: any*/),
                        (v20/*: any*/),
                        (v26/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "follows",
                  "storageKey": null,
                  "args": null,
                  "concreteType": null,
                  "plural": true,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "type": "Entity",
                      "selections": [
                        (v2/*: any*/),
                        (v9/*: any*/),
                        (v1/*: any*/),
                        (v6/*: any*/),
                        (v26/*: any*/)
                      ]
                    },
                    {
                      "kind": "InlineFragment",
                      "type": "Person",
                      "selections": [
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "greeting",
                          "args": null,
                          "storageKey": null
                        },
                        (v6/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "previews",
                          "storageKey": "previews(kinds:[\"SQUARE\"])",
                          "args": (v29/*: any*/),
                          "concreteType": "PreviewsConnection",
                          "plural": false,
                          "selections": (v27/*: any*/)
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "mediaAbout",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StoriesConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "StoryEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "story",
                          "storageKey": null,
                          "args": null,
                          "concreteType": null,
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "type": "RadivisionPost",
                              "selections": [
                                (v1/*: any*/),
                                (v18/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "previews",
                                  "storageKey": "previews(kinds:[\"SQUARE\"])",
                                  "args": (v29/*: any*/),
                                  "concreteType": "PreviewsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "edges",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "PreviewEdge",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "preview",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "Preview",
                                          "plural": false,
                                          "selections": [
                                            (v18/*: any*/),
                                            (v11/*: any*/),
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "mediaAsset",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "MediaAsset",
                                              "plural": false,
                                              "selections": [
                                                (v24/*: any*/),
                                                (v13/*: any*/),
                                                (v1/*: any*/)
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v30/*: any*/),
                                (v15/*: any*/),
                                (v3/*: any*/),
                                (v6/*: any*/),
                                (v31/*: any*/),
                                (v7/*: any*/)
                              ]
                            },
                            {
                              "kind": "InlineFragment",
                              "type": "OnlineCourseStory",
                              "selections": [
                                (v1/*: any*/),
                                (v18/*: any*/),
                                (v32/*: any*/),
                                (v30/*: any*/),
                                (v15/*: any*/)
                              ]
                            },
                            {
                              "kind": "InlineFragment",
                              "type": "ArticleStory",
                              "selections": [
                                (v3/*: any*/),
                                (v1/*: any*/),
                                (v18/*: any*/),
                                (v33/*: any*/),
                                (v34/*: any*/),
                                (v32/*: any*/),
                                (v7/*: any*/),
                                (v30/*: any*/),
                                (v15/*: any*/)
                              ]
                            },
                            {
                              "kind": "InlineFragment",
                              "type": "BookStory",
                              "selections": (v35/*: any*/)
                            },
                            {
                              "kind": "InlineFragment",
                              "type": "NewsletterStory",
                              "selections": [
                                (v1/*: any*/),
                                (v3/*: any*/),
                                (v18/*: any*/),
                                (v33/*: any*/),
                                (v34/*: any*/),
                                (v32/*: any*/),
                                (v30/*: any*/),
                                (v15/*: any*/)
                              ]
                            },
                            {
                              "kind": "InlineFragment",
                              "type": "PodcastStory",
                              "selections": (v35/*: any*/)
                            },
                            {
                              "kind": "InlineFragment",
                              "type": "OriginalContentStory",
                              "selections": [
                                (v1/*: any*/),
                                (v32/*: any*/),
                                (v30/*: any*/),
                                (v15/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "previews",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PreviewsConnection",
                  "plural": false,
                  "selections": (v25/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "northStar",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Questionnaire",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "questions",
                      "storageKey": null,
                      "args": null,
                      "concreteType": null,
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "type": "TextQuestion",
                          "selections": [
                            (v31/*: any*/),
                            (v1/*: any*/),
                            (v36/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "questionnaires",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "QuestionnairesConnection",
                  "plural": false,
                  "selections": [
                    (v16/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "QuestionnaireEdge",
                      "plural": true,
                      "selections": [
                        (v17/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Questionnaire",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v15/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "questions",
                              "storageKey": null,
                              "args": null,
                              "concreteType": null,
                              "plural": true,
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "type": "MultipleChoiceQuestion",
                                  "selections": [
                                    (v1/*: any*/),
                                    (v15/*: any*/),
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "response",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "MultipleChoiceQuestionResponse",
                                      "plural": false,
                                      "selections": [
                                        (v1/*: any*/),
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "choices",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "MultipleChoiceQuestionChoice",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "ScalarField",
                                              "alias": null,
                                              "name": "acceptsText",
                                              "args": null,
                                              "storageKey": null
                                            },
                                            (v1/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  "kind": "InlineFragment",
                                  "type": "TextQuestion",
                                  "selections": [
                                    (v1/*: any*/),
                                    (v31/*: any*/),
                                    (v36/*: any*/),
                                    (v15/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "responsibility",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "socialMediaCredentials",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SocialMediaCredential",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "credential",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "platform",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "SocialMediaPlatform",
                      "plural": false,
                      "selections": (v12/*: any*/)
                    }
                  ]
                },
                (v22/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '1b27dafa19ce86c0eb8eb1ddfa1005f3';
export default node;
