/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-02-27
 * @description a reference to all components which can be installed.
 */

import { AddItemPage } from "./inner-pages/content-management/add-item/add-item-page";
import { ArticleStoryPageContainer } from "./inner-pages/content-management/article-page";
import { BookStoryPageContainer } from "./inner-pages/content-management/book-page";
import { CompanyReportContainer } from "./inner-pages/content-management/cognito-components/company-report-page";
import { GroupPageContainer } from "./inner-pages/content-management/cognito-components/group";
import { PoolGroupPageContainer } from "./inner-pages/content-management/cognito-components/pool-groups";
import { PoolItemPage } from "./inner-pages/content-management/cognito-components/pool-item";
import { UsersReportContainer } from "./inner-pages/content-management/cognito-components/users-report";
import Dashboard from "./inner-pages/content-management/dashboard/dashboard";
import { DynamicListPageContainer } from "./inner-pages/content-management/dynamic-list-page";
import { EntityPageContainer } from "./inner-pages/content-management/entity-page";
import { FixedListPageContainer } from "./inner-pages/content-management/fixed-list-page";
import { ListCollectionPageContainer } from "./inner-pages/content-management/list-collection-page";
import { ComponentPageContainer } from "./inner-pages/content-management/manage-component/manage-component";
import { HtmlDocumentPageContainer } from "./inner-pages/content-management/manage-html-page/html-document-page";
import { UrlRoutePageContainer } from "./inner-pages/content-management/manage-route-page/manage-route-page";
import { MediaAssetFilePageContainer } from "./inner-pages/content-management/media-asset/media-asset-file-page";
import { MediaAssetPageContainer } from "./inner-pages/content-management/media-asset/media-asset-page";
import { NewsLetterPageContainer } from "./inner-pages/content-management/newsletter-page";
import { OnlineCoursePageContainer } from "./inner-pages/content-management/online-course-page";
import { OriginalContentPageContainer } from "./inner-pages/content-management/original-content-page";
import { PersonPageContainer } from "./inner-pages/content-management/person-page";
import { PodcastPageContainer } from "./inner-pages/content-management/podcast-page";
import { PosterPageContainer } from "./inner-pages/content-management/poster-page";
import { QuestionnairePageContainer } from "./inner-pages/content-management/questionnaires-page/question-panel";
import newUsersPage from "./inner-pages/content-management/reports/new-users-page";
import { NavigationBar } from "./inner-pages/content-management/shared/navigation-bar";
import { SuggestionPageContainer } from "./inner-pages/content-management/suggestion-page";
import { SupportIssuesPageContainer } from "./inner-pages/content-management/support-issues-page";
import { TagPageContainer } from "./inner-pages/content-management/tag-page";
import { PitchUserRequestPageContainer } from "./inner-pages/content-management/pitch-user-request";
import { VersioningPage } from "./inner-pages/content-management/version-control/versioning-page";
import { EventPageContainer } from "./inner-pages/content-management/event-page";
import { CommentPageContainer } from "./inner-pages/content-management/comment-moderation/list-view";
import { HeroReplaceComponent } from "./inner-pages/content-management/comment-moderation/hero-replace-component";
import { UserRequestPageContainer } from "./inner-pages/content-management/user-request-page";
import { MarketplaceContainer } from "./inner-pages/content-management/marketplace-page";
import { MarketEntryPageContainer } from "./inner-pages/content-management/market-entry-page";

const urlParams = new URLSearchParams(window.location.search);

/**
 * The collection of all components which can be instantiated
 *
 * @type {Array<RelayContainer<*>>}
 */
export const MAIN_COMPONENTS: {
  [index: string]: any;
} = {
  NavigationBar: {
    component: NavigationBar,
  },
  InitialPage: {
    component: Dashboard,
  },
  AddItemPage: {
    component: AddItemPage,
  },
  
  MarketEntryPage: {
    component: MarketEntryPageContainer,
  },
  ArticlePage: {
    component: ArticleStoryPageContainer,
  },
  BookPage: {
    component: BookStoryPageContainer,
  },
  ManageRoutes: {
    component: UrlRoutePageContainer,
  },
  NewsletterPage: {
    component: NewsLetterPageContainer,
  },
  PersonPage: {
    component: PersonPageContainer,
  },
  QuestionPage: {
    component: QuestionnairePageContainer,
  },
  PodcastPage: {
    component: PodcastPageContainer,
  },
  EntityPage: {
    component: EntityPageContainer,
  },
  DynamicListPage: {
    component: DynamicListPageContainer,
  },
  CompanyReportPage: {
    component: CompanyReportContainer,
  },
  FixedListPage: {
    component: FixedListPageContainer,
  },
  ManageHTML: {
    component: HtmlDocumentPageContainer,
  },
  OriginalContentPage: {
    component: OriginalContentPageContainer,
  },
  OnlineCoursePage: {
    component: OnlineCoursePageContainer,
  },
  ComponentPage: {
    component: ComponentPageContainer,
  },
  PosterPage: {
    component: PosterPageContainer,
  },
  MediaAssetPage: {
    component: MediaAssetPageContainer,
  },
  MediaAssetFilePage: {
    component: MediaAssetFilePageContainer,
  },
  TagPage: {
    component: TagPageContainer,
  },
  SupportIssuePage: {
    component: SupportIssuesPageContainer,
  },
  SuggestionPage: {
    component: SuggestionPageContainer,
  },
  ListCollectionPage: {
    component: ListCollectionPageContainer,
  },
  PoolItemPage: {
    component: PoolItemPage,
  },
  PoolGroupPage: {
    component: PoolGroupPageContainer,
  },
  PoolGroupUsersPage: {
    component: GroupPageContainer,
  },
  IdentityPoolUsersPage: {
    component: UsersReportContainer,
  },
  VersioningControl: {
    component: VersioningPage,
  },
  UserReportPage: {
    component: newUsersPage,
  },
  UserRequestPageContainer: {
    component: urlParams.get("reportType")==="user"
      ? UserRequestPageContainer
      : PitchUserRequestPageContainer,
  },
  EventPage: {
    component: EventPageContainer,
  },
  EventCommentContainer: {
    component: CommentPageContainer,
  },

  HeroReplaceComponent: {
    component: HeroReplaceComponent,
  },

  MarketPlaceContainer:{
    component: MarketplaceContainer,
  }
};
