/**
 * @author Ahmed Samer
 * @date    2020-05-10
 * @filename  common
 * @LastModifiedBy Ahmed Samer
 * @Copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

// import $ from "jquery";
import URIJS from "urijs";
import isEqual from "lodash.isequal";
import groupBy from "lodash.groupby";


export const COMMON = {
  URIJS,
  isEqual,
  groupBy
};
