/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-10
 * @description Implementation of Article utilities to be used in the app.
 * @filename podcast.ts
 */
import { CreatePodcastStoryInput, CreateStoryInput, UpdatePodcastStoryInput, UpdateStoryInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { PodcastPageFormInput } from "../component-configuration/cms-pages";
import { CREATE_PODCAST_GRAPHQL_MUTATION } from "../relay/mutations/create-podcast-story";
import { UPDATE_PODCAST_STORY_GRAPHQL_MUTATION } from "../relay/mutations/update-podcast";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { Story } from "./cms-operation/story";
import { commitMutation } from "./relay";

/**
 *
 *
 * @export
 * @class Podcast
 */
export class Podcast {
  /**
   *
   *
   * @static
   * @param {PodcastPageFormInput} podcastInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof Podcast
   */
  static addPodcast(
    podcastInput: PodcastPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert podcast input to  CreatePodcastStoryInput
    let createPodcastStoryInput: CreatePodcastStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: CreateStoryInput;
    let filteredStoryInput: any;
    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[addPodcast] provided input ", JSON.stringify(podcastInput));

    if (
      podcastInput.link === undefined ||
      podcastInput.link === null ||
      podcastInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("1A2iCoewkki1x2BUlyGORA"));
    } else {
      storyInput = Story.createStoryInput(podcastInput);

      // filteredStoryInput = Object.assign({}, storyInput, {
      //   publishedDate: undefined
      // });

      // initialize create article graphQl input
      createPodcastStoryInput = {
        ...storyInput,
        link: encodeURI(podcastInput.link.trim()),
        durationInSeconds: podcastInput.durationInSeconds
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_PODCAST_GRAPHQL_MUTATION,
        variables: {
          input: createPodcastStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createPodcastStory.hasErrors === false
          ? Promise.resolve(mutationResults.createPodcastStory.story)
          : Promise.reject(mutationResults.createPodcastStory.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @param {PodcastPageFormInput} podcastInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof Podcast
   */
  static updatePodcast(
    podcastInput: PodcastPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let updatePodcastStoryInput: UpdatePodcastStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: UpdateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[updatePodcast] provided input ", JSON.stringify(podcastInput));

    if (
      podcastInput.link === undefined ||
      podcastInput.link === null ||
      podcastInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("qRLjjKOjOkyULFZjSRvLnA"));
    } else {
      storyInput = Story.updateStoryInput(podcastInput);

      // initialize create article graphQl input
      updatePodcastStoryInput = {
        ...storyInput,
        link: encodeURI(podcastInput.link.trim()),
        durationInSeconds: podcastInput.durationInSeconds
      };

      // initialize mutation configuration
      config = {
        mutation: UPDATE_PODCAST_STORY_GRAPHQL_MUTATION,
        variables: {
          input: updatePodcastStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updatePodcastStory.hasErrors === false
          ? Promise.resolve(mutationResults.updatePodcastStory.story)
          : Promise.reject(mutationResults.updatePodcastStory.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {PodcastPageFormInput} podcastInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof Podcast
   */
  static deletePodcast(
    podcastInput: PodcastPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
