/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-25
 * @description Implementation of the CookieConsent component.
 * @filename cookie-consent.tsx
 */
import React from "react";
import { Router } from "../../utilities/router";
import { RdvButton } from "./rdv-button";

/**
 * The state of the CookieConsent component
 *
 * @interface CookieConsentState
 */
export interface CookieConsentState {
  /**
   * The scroll offset to close the cookie consent form.
   *
   * @type {number}
   */
  autoCloseScrollOffset?: number;

  /**
   * The height of the consent div.
   *
   * @type {number}
   */
  consentHeight: number;

  /**
   * A boolean used to hide the consent if the user visited the website before.
   *
   * @type {boolean}
   */
  hideConsent: boolean;

  /**
   * The scroll position
   *
   * @type {number}
   */
  initialScrollPosition?: number;
}

/**
 * A React component that renders a cookie consent notice
 *
 * @export
 * @class Divider
 * @extends {React.Component<CookieConsentState>}
 */
export class CookieConsent extends React.Component<{}, CookieConsentState> {
  /**
   * A reference to the cookie consent form.
   *
   * @type {React.RefObject<HTMLDivElement>}
   */
  cookieRef: React.RefObject<HTMLDivElement>;

  /**
   * Constructor
   *
   * @param {CookieConsentProps} props The props.
   */
  constructor(props: {}) {
    super(props);
    this.state = {
      consentHeight: 0,
      hideConsent: false
    };
    this.cookieRef = React.createRef();
    if (localStorage.getItem("previouslyOpened") === null) {
      localStorage.setItem("previouslyOpened", "false");
    }
  }

  /**
   * A method invoked when the close consent button is selected.
   */
  closeConsent = () => {
    window.removeEventListener("scroll", this.onScroll);
    localStorage.setItem("previouslyOpened", "true");
    // update state to reflect changes
    this.setState({
      hideConsent: true
    });
  }

  /**
   * Invoked by the React framework immediately after a component is mounted.
   */
  componentDidMount(): void {
    // if the user has opened  the website before and agreed to use of cookies -> hide consent
    if (localStorage.getItem("previouslyOpened") === "true") {
      // update state to reflect changes
      this.setState({
        hideConsent: true,
        initialScrollPosition: window.scrollY
      });
    } else {
      this.setState({
        autoCloseScrollOffset: screen.height * 0.3,
        initialScrollPosition: window.scrollY
      });
      this.onScroll = this.onScroll.bind(this);
      window.addEventListener("scroll", this.onScroll);
    }
    // update state to get height of consent container to correctly position it at the bottom
    this.setState({
      consentHeight: this.cookieRef.current.clientHeight
    });
  }
  /**
   * Invoked by the React framework just before a component is unmounted.
   */
  componentWillUnmount(): void {
    window.removeEventListener("scroll", this.onScroll);
  }

  /**
   * Invoked when the window scrolls.
   */
  onScroll(): void {
    if (
      Math.abs(window.scrollY - this.state.initialScrollPosition) >
      this.state.autoCloseScrollOffset
    ) {
      this.closeConsent();
    }
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return !this.state.hideConsent ? (
      <div
        className="cookie-consent-container"
        style={{ top: `calc(100% -${this.state.consentHeight}px)` }}
        ref={this.cookieRef}
      >
        <div className="position-relative">
          <svg className="ginger" version="1.1" viewBox="0 0 24 24">
            <title />
            <g id="Gingerbread">
              <g>
                <path
                  d="M5.5,8.5c0,0.62-1,0.62-1,1.25    s1,0.62,1,1.25s-1,0.63-1,1.25s1,0.63,1,1.25"
                  className="ginger-inner"
                />
                <path
                  d="M18.5,8.5c0,0.62,1,0.62,1,1.25    s-1,0.62-1,1.25s1,0.63,1,1.25s-1,0.63-1,1.25"
                  className="ginger-inner"
                />
                <path
                  d="M20.86,8.5H14.8    c1.948-1.544,2.275-4.374,0.732-6.322S11.158-0.097,9.21,1.446S6.935,5.82,8.478,7.768C8.693,8.039,8.939,8.285,9.21,8.5H3.14    c-1.319-0.032-2.452,0.932-2.63,2.24c-0.144,1.373,0.853,2.603,2.226,2.746C2.824,13.496,2.912,13.5,3,13.5h2.9    c0.878,0.003,1.587,0.718,1.584,1.596C7.482,15.49,7.335,15.869,7.07,16.16l-2.78,3H4.24l-0.11,0.13L4,19.5l0,0    c-0.861,1.072-0.69,2.639,0.382,3.501c1.016,0.816,2.488,0.711,3.378-0.241l2.33-2.62c0.928-1.052,2.533-1.153,3.585-0.225    c0.08,0.07,0.155,0.145,0.225,0.225l2.33,2.62c0.931,1.013,2.506,1.079,3.518,0.148c0.967-0.889,1.078-2.377,0.252-3.398l0,0    l-0.12-0.14l-0.11-0.13h-0.05l-2.78-3c-0.636-0.606-0.66-1.612-0.055-2.248c0.315-0.331,0.758-0.51,1.215-0.492H21    c1.381,0.002,2.502-1.116,2.504-2.496c0-0.088-0.004-0.176-0.014-0.264C23.312,9.432,22.179,8.468,20.86,8.5z"
                  className="ginger-inner"
                />
                <circle cx="10" cy="4" r="0.5" className="ginger-inner" />
                <circle cx="14" cy="4" r="0.5" className="ginger-inner" />
                <path
                  d="M10,6.5c0.828,1.105,2.395,1.328,3.5,0.5    c0.19-0.142,0.358-0.31,0.5-0.5"
                  className="ginger-inner"
                />
                <circle cx="12" cy="11" r="0.5" className="ginger-inner" />
                <circle cx="12" cy="15" r="0.5" className="ginger-inner" />
                <path
                  d="M8.47,22c-0.44-0.44,0.29-1.12-0.15-1.56    s-1.15,0.27-1.59-0.18s0.26-1.15-0.18-1.59s-1.15,0.26-1.59-0.18"
                  className="ginger-inner"
                />
                <path
                  d="M19,18.5c-0.44,0.44-1.15-0.27-1.59,0.17    s0.27,1.15-0.18,1.59s-1.15-0.26-1.59,0.18S16,21.53,15.53,22"
                  className="ginger-inner"
                />
              </g>
            </g>
          </svg>
          <p>
            We use cookies to ensure that you get the best experience on our
            website. If you continue browsing we'll assume you're happy with it!
          </p>
          <a
            href="#"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
              event.preventDefault();
              Router.to("privacy-policy", undefined, "cookies-section");
            }}
          >
            Find out more!
          </a>
          <RdvButton
            text="got it!"
            onClick={() => {
              this.closeConsent();
            }}
          />
          <svg
            id="Layer_1"
            className="close-icon"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 72 72"
            onClick={() => {
              this.closeConsent();
            }}
          >
            <title>close-sm</title>
            <path
              className="cls-1"
              d="M46.77,36l23-23A7.62,7.62,0,0,0,59,2.24l-23,23-23-23A7.62,7.62,0,0,0,2.23,13l23,23-23,23A7.62,7.62,0,0,0,13,69.78l23-23,23,23A7.62,7.62,0,0,0,69.77,59Z"
            />
          </svg>
        </div>
      </div>
    ) : null;
  }
}
