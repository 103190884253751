/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ContentModerationStateKind = "APPROVED" | "BANNED" | "TO_BE_APPROVED" | "%future added value";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type UpdateNewsletterStoryInput = {
    readonly authors?: ReadonlyArray<string> | null;
    readonly badges?: ReadonlyArray<string | null> | null;
    readonly clientMutationId: string;
    readonly cost?: string | null;
    readonly description?: string | null;
    readonly entities?: ReadonlyArray<string | null> | null;
    readonly force?: boolean | null;
    readonly id: string;
    readonly isActive?: boolean | null;
    readonly label?: string | null;
    readonly link?: string | null;
    readonly moderation?: ContentModerationStateKind | null;
    readonly people?: ReadonlyArray<CreateEntityPersonInput> | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly publishedDate?: unknown | null;
    readonly publisher?: string | null;
    readonly revision?: string | null;
    readonly subTitle?: string | null;
    readonly summary?: string | null;
    readonly tags?: ReadonlyArray<string | null> | null;
    readonly timeToReadInSeconds?: number | null;
    readonly title?: string | null;
};
export type CreateEntityPersonInput = {
    readonly entity?: string | null;
    readonly person?: string | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type updateNewsletterStoryMutationVariables = {
    readonly input: UpdateNewsletterStoryInput;
};
export type updateNewsletterStoryMutationResponse = {
    readonly updateNewsletterStory: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly story: ({
            readonly __typename: string;
            readonly id?: string;
            readonly link?: string | null;
            readonly timeToReadInSeconds?: number | null;
            readonly publisher?: string | null;
            readonly publishedDate?: unknown | null;
            readonly description?: string | null;
            readonly created?: unknown | null;
            readonly label?: string | null;
            readonly cost?: string | null;
            readonly isDeleted?: boolean | null;
            readonly revision?: string | null;
            readonly isNew?: boolean | null;
            readonly tags?: ReadonlyArray<{
                readonly id: string;
                readonly label: string | null;
            }> | null;
            readonly previews?: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly id: string;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
            readonly subTitle?: string | null;
            readonly title?: string | null;
        } & ({
            readonly __typename: "NewsletterStory";
            readonly id: string;
            readonly link: string | null;
            readonly timeToReadInSeconds: number | null;
            readonly publisher: string | null;
            readonly publishedDate: unknown | null;
            readonly description: string | null;
            readonly created: unknown | null;
            readonly label: string | null;
            readonly cost: string | null;
            readonly isDeleted: boolean | null;
            readonly revision: string | null;
            readonly isNew: boolean | null;
            readonly tags: ReadonlyArray<{
                readonly id: string;
                readonly label: string | null;
            }> | null;
            readonly previews: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly id: string;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
            readonly subTitle: string | null;
            readonly title: string | null;
        } | {
            /*This will never be '% other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        })) | null;
    } | null;
};
export type updateNewsletterStoryMutation = {
    readonly response: updateNewsletterStoryMutationResponse;
    readonly variables: updateNewsletterStoryMutationVariables;
};



/*
mutation updateNewsletterStoryMutation(
  $input: UpdateNewsletterStoryInput!
) {
  updateNewsletterStory(input: $input) {
    hasErrors
    errors {
      id
      location
    }
    story {
      __typename
      ... on NewsletterStory {
        id
        link
        timeToReadInSeconds
        publisher
        publishedDate
        description
        created
        label
        cost
        isDeleted
        revision
        isNew
        tags {
          id
          label
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                  id
                }
              }
            }
          }
        }
        subTitle
        title
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateNewsletterStoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timeToReadInSeconds",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publisher",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedDate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cost",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDeleted",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isNew",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": null,
  "args": null,
  "concreteType": "Tag",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v12/*: any*/)
  ]
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "path",
      "args": null,
      "storageKey": null
    }
  ]
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateNewsletterStoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNewsletterStory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateStoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "story",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "NewsletterStory",
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "previews",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewsConnection",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewEdge",
                        "plural": true,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "preview",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Preview",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v20/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mediaAsset",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "MediaAsset",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v21/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v20/*: any*/),
                                      (v12/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v22/*: any*/),
                  (v23/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateNewsletterStoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNewsletterStory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateStoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "story",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "NewsletterStory",
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "previews",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewsConnection",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewEdge",
                        "plural": true,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "preview",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Preview",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v20/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mediaAsset",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "MediaAsset",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v21/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v20/*: any*/),
                                      (v12/*: any*/),
                                      (v3/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v22/*: any*/),
                  (v23/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateNewsletterStoryMutation",
    "id": null,
    "text": "mutation updateNewsletterStoryMutation(\n  $input: UpdateNewsletterStoryInput!\n) {\n  updateNewsletterStory(input: $input) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    story {\n      __typename\n      ... on NewsletterStory {\n        id\n        link\n        timeToReadInSeconds\n        publisher\n        publishedDate\n        description\n        created\n        label\n        cost\n        isDeleted\n        revision\n        isNew\n        tags {\n          id\n          label\n        }\n        previews {\n          count\n          edges {\n            cursor\n            preview {\n              link\n              kind\n              mediaAsset {\n                id\n                files {\n                  id\n                  name\n                  path\n                }\n                topLevelMediaType {\n                  kind\n                  label\n                  id\n                }\n              }\n            }\n          }\n        }\n        subTitle\n        title\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '8cbf3906215a52a44b1085768a8fb00e';
export default node;
