/**
 * @author Linkon Islam
 * @date   2019-01-10
 * @description Modal class component
 * @filename Modal.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Modal from "./modal/modal";
import { CMSSearchComponent } from "./shared/cms-search-component";
import { SearchHit } from "@radivision/graphql";

/**
 *
 *
 * @export
 * @interface ModalProps
 */
export interface ModalProps {
  /**
   *
   *
   * @type {string}
   * @memberof ModalProps
   */
  title: string;
  /**
   *
   *
   * @type {Function}
   * @memberof ModalProps
   */
  handleSearchResults: Function;

  /**
   *
   *
   * @type {{ field: ""; values: string[] }[]}
   * @memberof ModalProps
   */
  filters: { field: string; values: string[] }[];

  /**
   *
   *
   * @memberof ModalProps
   */
  close(): void;
}

interface ModalState {
  show: string;
}

class SearchModal extends React.Component<ModalProps, ModalState> {
  suggestNomineeRef: any;
  /**
   * Creates an instance of SearchModal.
   * @param {*} props
   * @memberof SearchModal
   */
  constructor(props: any) {
    super(props);

    this.suggestNomineeRef = React.createRef();

    this.state = {
      show: "true"
    };
  }

  /**
   *
   *
   * @returns
   * @memberof SearchModal
   */
  render() {
    return (
      <div className="">
        <Modal
          title={this.props.title}
          show={this.state.show}
          close={
            this.props.close
              ? this.props.close
              : () => {
                  this.setState({ show: "false" });
                }
          }
        >
          <CMSSearchComponent
            ref={this.suggestNomineeRef}
            placeholder="Search For Media items"
            searchComponentOpened={true}
            filters={this.props.filters}
            searchAction={(suggest: SearchHit) => {
              this.setState({ show: "false" });
              if (this.props && this.props.handleSearchResults)
                this.props.handleSearchResults(suggest);
            }}
          />
        </Modal>
      </div>
    );
  }
}

export default SearchModal;
