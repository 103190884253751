import * as React from "react";
import Choices from "./choices";
import {
  CreateQuestionInput,
  CreateMultipleChoiceQuestionChoiceInput,
  QuestionKind,
  MultipleChoiceQuestion as graphqlMultipleChoiceQuestion,
  PreviewKind
} from "@radivision/graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNewUniqueId } from "@radivision/common";
import { ManagePreviewPanel } from "../../manage-preview/manage-preview-panel";
import { PreviewItem } from "../../../../../component-configuration/cms-pages";

/**
 *
 *
 * @interface MultiQuestionProps
 */
interface MultiQuestionProps {
  /**
   *
   *
   * @type {graphqlMultipleChoiceQuestion}
   * @memberof MultiQuestionProps
   */
  input?: graphqlMultipleChoiceQuestion;

  /**
   *
   *
   * @type {number}
   * @memberof MultiQuestionProps
   */
  qId: string;

  /**
   *
   *
   * @memberof MultiQuestionProps
   */
  handleChange?: void;

  /**
   *
   *
   * @param {number} id
   * @memberof MultiQuestionProps
   */
  removeClick(id: string): void;
}

interface MultipleQuestionState {
  /**
   *
   *
   * @type {number}
   * @memberof MultipleQuestionState
   */
  count: number;
  /**
   *
   *
   * @type {any[]}
   * @memberof MultipleQuestionState
   */
  multipleChoices: any[];
  /**
   *
   *
   * @type {string}
   * @memberof MultipleQuestionState
   */
  title: string;
  /**
   *
   *
   * @type {string}
   * @memberof MultipleQuestionState
   */
  subtitle: string;
  /**
   *
   *
   * @type {boolean}
   * @memberof MultipleQuestionState
   */
  maxchoice: boolean;
  /**
   *
   *
   * @type {boolean}
   * @memberof MultipleQuestionState
   */
  minchoice: boolean;
  /**
   *
   *
   * @type {number}
   * @memberof MultipleQuestionState
   */
  maximumNumberOfChoices: number;
  /**
   *
   *
   * @type {number}
   * @memberof MultipleQuestionState
   */
  minimumNumberOfChoices: number;

  /**
   *
   *
   * @type {({
   *     id?: number;
   *     previewKind?: PreviewKind;
   *     previewSource?: "link" | "mediaAsset";
   *     previewDetails?: string;
   *   }[])}
   * @memberof MultipleQuestionState
   */
  previewList?: PreviewItem[];
}

export default class MultipleChoose extends React.Component<
  MultiQuestionProps,
  MultipleQuestionState
> {
  /**
   *
   *
   * @type {*}
   * @memberof QuestionPanel
   */
  previewRef: any;

  /**
   *
   *
   * @type {Map <number,Choices>}
   * @memberof MultipleChoose
   */
  choicesNodes: Map<string, Choices>;

  /**
   * Creates an instance of MultipleChoose.
   * @param {MultiQuestionProps} props
   * @memberof MultipleChoose
   */
  constructor(props: MultiQuestionProps) {
    super(props);

    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSubTitleChange = this.handleSubTitleChange.bind(this);
    this.handleMaxChoiceChange = this.handleMaxChoiceChange.bind(this);
    this.handleMinChoiceChange = this.handleMinChoiceChange.bind(this);
    this.handleMaxValueChange = this.handleMaxValueChange.bind(this);
    this.handleMinValueChange = this.handleMinValueChange.bind(this);

    this.choicesNodes = new Map();
    this.previewRef = React.createRef();

    this.state = this.handleComponentInitialize(this.props.input);
  }

  /**
   *
   *
   * @param {graphqlMultipleChoiceQuestion} input
   * @returns {MultipleQuestionState}
   * @memberof MultipleChoose
   */
  handleComponentInitialize(
    input: graphqlMultipleChoiceQuestion
  ): MultipleQuestionState {
    let state: MultipleQuestionState = {
      count: Math.random() * 20,
      multipleChoices: [],
      title: "",
      subtitle: "",
      maxchoice: false,
      minchoice: false,
      maximumNumberOfChoices: 0,
      minimumNumberOfChoices: 0,
      previewList: []
    };

    if (input !== null && input !== undefined) {
      if (input.title) {
        state.title = input.title;
      }

      if (input.maximumNumberOfChoices) {
        state.maximumNumberOfChoices = input.maximumNumberOfChoices;
      }

      if (input.minimumNumberOfChoices) {
        state.minimumNumberOfChoices = input.minimumNumberOfChoices;
      }

      if (input.choices) {
        input.choices.map(item => {
          let nodeId = getNewUniqueId();

          const choice = (
            <Choices
              ref={choice => this.choicesNodes.set(nodeId, choice)}
              key={nodeId}
              input={item}
              choiceId={nodeId}
              removeChoiceClick={this.removeChoiceClick}
            />
          );

          state.multipleChoices.push({ id: nodeId, content: choice });
        });
      }

      if (
        input.previews !== null &&
        input.previews !== undefined &&
        input.previews.count > 0
      ) {
        state.previewList = input.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${
                      preview.preview.mediaAsset.files[0].name
                    }`
                  }
                };
                break;

              default:
                break;
            }
            
            return item;
          }
        );
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof MultipleChoose
   */
  addMoreChoice = () => {
    let multipleChoices = this.state.multipleChoices;
    let nodeId = getNewUniqueId();

    const choice = (
      <Choices
        ref={choice => this.choicesNodes.set(nodeId, choice)}
        key={nodeId}
        choiceId={nodeId}
        removeChoiceClick={this.removeChoiceClick}
      />
    );

    multipleChoices.push({ id: nodeId, content: choice });

    this.setState({
      multipleChoices
    });
  };

  /**
   *
   *
   * @memberof MultipleChoose
   */
  removeChoiceClick = (id: any) => {
    // console.log(" remove call for multiple Choices id : ", id);

    let myQuestion = this.state.multipleChoices.filter(choice => {
      return choice.id !== id;
    });

    this.choicesNodes.delete(id);

    this.setState({
      multipleChoices: myQuestion
    });
  };

  /**
   *
   *
   * @returns
   * @memberof MultipleChoose
   */
  render() {
    return (
      <div>
        <label>Multiple Question</label>

        <a
          className="btn add-btn"
          href="#"
          onClick={e => {
            e.preventDefault();
            this.props.removeClick(this.props.qId);
          }}
        >
          <FontAwesomeIcon icon="times-circle" />
        </a>

        <div className="input-field">
          <label>Title</label>
          <input
            placeholder="your title"
            id="title"
            value={this.state.title}
            type="text"
            className="validate form-control"
            onChange={this.handleTitleChange}
          />
        </div>
        <div className="input-field">
          <label>Sub title</label>
          <input
            placeholder="your subtitle"
            id="subtitle"
            type="text"
            value={this.state.subtitle}
            className="validate form-control"
            onChange={this.handleSubTitleChange}
          />
        </div>
        <div className="input-field col">
          <input type="checkbox" onChange={this.handleMaxChoiceChange} />
          <label>
            <span>Max Choices</span>
          </label>

          <input
            type="number"
            className="validate form-control"
            value={this.state.maximumNumberOfChoices}
            onChange={this.handleMaxValueChange}
            placeholder="0"
          />
          <p>
            <label>
              <input type="checkbox" onChange={this.handleMinChoiceChange} />
              <span>Min Choices</span>
            </label>
          </p>
          <input
            type="number"
            className="validate form-control"
            value={this.state.minimumNumberOfChoices}
            onChange={this.handleMinValueChange}
            placeholder="0"
          />
        </div>
        <div>
          {this.state.multipleChoices.length ? (
            this.state.multipleChoices.map((choice, index) => {
              return <div key={index}>{choice.content}</div>;
            })
          ) : (
            <p style={{ display: "none" }}>nothing to show</p>
          )}
          <a className="btn add-btn" onClick={this.addMoreChoice}>
            <FontAwesomeIcon icon="plus-circle" />
          </a>
        </div>

        {/* preview section */}
        <div className="Page--Preview">
          <ManagePreviewPanel
            previewsList={this.state.previewList}
            ref={this.previewRef}
          />
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof MultipleChoose
   */
  handleTitleChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    let title = this.state.title;

    changeEvent.preventDefault();

    title = changeEvent.currentTarget.value;

    this.setState({
      title
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof MultipleChoose
   */
  handleSubTitleChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();

    this.setState({
      subtitle: changeEvent.currentTarget.value
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof MultipleChoose
   */
  handleMaxChoiceChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();
    let value = changeEvent.currentTarget.value === "checked" ? true : false;
    this.setState({
      maxchoice: value
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof MultipleChoose
   */
  handleMinChoiceChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();
    let value = changeEvent.currentTarget.value === "checked" ? true : false;

    this.setState({
      minchoice: value
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof MultipleChoose
   */
  handleMaxValueChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();

    this.setState({
      maximumNumberOfChoices: Number(changeEvent.currentTarget.value)
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof MultipleChoose
   */
  handleMinValueChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();

    this.setState({
      minimumNumberOfChoices: Number(changeEvent.currentTarget.value)
    });
  }

  /**
   *
   *
   * @returns {CreateQuestionInput}
   * @memberof MultipleChoose
   */
  extractQuestion(): CreateQuestionInput {
    let input: MultipleQuestionState = this.state;
    let question: CreateQuestionInput;
    let choices: CreateMultipleChoiceQuestionChoiceInput[];

    choices = this.state.multipleChoices.map((item, index) => {
      return this.choicesNodes.get(item.id).extractChoiceInput();
    });

    question = {
      maximumNumberOfChoices: String(input.maximumNumberOfChoices),
      minimumNumberOfChoices: String(input.minimumNumberOfChoices),
      choices: choices,
      title: input.title,
      subTitle: input.subtitle,
      questionType: QuestionKind.MUTLIPLE_CHOICE,
      previews: this.previewRef.current.extractPreviews()
    };
    return question;
  }
}
