/**
 * @author Ahmed Samer
 * @date   2020-08-31
 * @description
 * @filename index.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { ApiClient } from "../../../../authentication/api-client";
import { ArraysHelper } from "../../../../utilities/arrays";
import { Loader } from "../../../page/loader";

import List from "react-list-select";
import { FloatingMenu } from "../floating-button/floating-menu";

const SELECTED: Map<number, string> = new Map();

/**
 *
 *
 * @export
 * @param {CommentEntity[]} comments
 * @returns
 */
class CommentPage extends React.Component<
  { comments: CommentEntity[]; event: string },
  { selected: Map<number, string>; comments: CommentEntity[]; loading: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected: new Map(),
      comments: [...this.props.comments],
      loading: false,
    };

    this.updateCommentView = this.updateCommentView.bind(this);
    this.updateSelectionMap = this.updateSelectionMap.bind(this);
  }

  /**
   *
   *
   * @param {number} itemIndex
   * @memberof CommentPage
   */
  updateSelectionMap(itemIndex: number[]): void {
    SELECTED.clear();
    itemIndex.map((index) => {
      SELECTED.set(index-1, this.props.comments[index-1].id);
    });

    console.log(SELECTED);
  }

  /**
   *
   *
   * @returns
   * @memberof CommentPage
   */
  render() {
    const ITEMS =
      this.state.comments && this.state.comments.length
        ? this.state.comments.map((comment, index) => {
            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar
                    alt={
                      comment.meta && comment.meta.authorName
                        ? comment.meta.authorName
                        : "Guest"
                    }
                    src={
                      comment.meta && comment.meta.avatarUrl
                        ? comment.meta.avatarUrl
                        : ""
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    comment.meta && comment.meta.authorName
                      ? comment.meta.authorName
                      : "Guest"
                  }
                  secondary={comment.text}
                />
              </ListItem>
            );
          })
        : [];

    return (
      <div className="dark-bg">
        <div className="AddItemPage container">
          <div className="context-menu">
            <List
              items={ITEMS}
              multiple={true}
              onChange={this.updateSelectionMap}
            />
          </div>
        </div>
        <Loader isActive={this.state.loading} />

        <FloatingMenu
          menuItems={{
            saveable: () => {
              console.log("saveable");
            },
            other: { name: "refresh", action: this.updateCommentView },
          }}
        />
      </div>
    );
  }

  /**
   *
   *
   * @memberof VideoPanel
   */
  updateCommentView = (): Promise<any> => {
    this.setState({ loading: true });
    return ApiClient.get(
      `${process.env.LIVE_COMMENTS_ENDPOINT}?recordId=${this.props.event}`
    ).then((res) => {
      if (res) {
        const COMMENTS: CommentEntity[] = ArraysHelper.unionBy(
          [...this.state.comments],
          res.comments,
          "id"
        ) as CommentEntity[];

        this.setState({ comments: COMMENTS, loading: false });
      }
    });
  };
}

/**
 *
 *
 * @export
 * @interface CommentEntity
 */
export interface CommentEntity {
  meta?: {
    /**
     * commenter name
     *
     * @type {string}
     * @memberof CommentEntity
     */
    authorName?: string;

    /**
     *
     *
     * @type {string}
     */
    authorId?: string;

    /**
     *
     *
     * @type {string}
     * @memberof CommentEntity
     */
    avatarUrl?: string;
  };
  /**
   *
   *
   * @type {string}
   * @memberof CommentEntity
   */
  id: string;

  /**
   *
   *
   * @type {string}
   * @memberof CommentEntity
   */
  text: string;

  /**
   *
   *
   * @type {string}
   * @memberof CommentEntity
   */
  created: number;
}

export class CommentPageContainer extends React.Component<
  { event: string },
  { comments: CommentEntity[] }
> {
  constructor(props: any) {
    super(props);
    this.updateCommentView = this.updateCommentView.bind(this);

    this.state = {
      comments: [],
    };
  }
  componentDidMount() {
    this.updateCommentView();
  }

  /**
   *
   *
   * @memberof VideoPanel
   */
  updateCommentView = (): Promise<any> => {
    return ApiClient.get(
      `${process.env.LIVE_COMMENTS_ENDPOINT}?recordId=${this.props.event}`
    ).then((res) => {
      if (res) {
        const COMMENTS: CommentEntity[] = ArraysHelper.unionBy(
          [...this.state.comments],
          res.comments,
          "id"
        ) as CommentEntity[];

        this.setState({ comments: COMMENTS });
      }
    });
  };

  render() {
    return (
      <div>
        {this.state.comments && this.state.comments.length ? (
          <CommentPage
            comments={this.state.comments}
            event={this.props.event}
          />
        ) : (
          <Loader isActive={true} />
        )}
      </div>
    );
  }
}
