/**
 * @author Linkon Islam
 * @date   2019-01-08
 * @description Navigation bar component
 * @filename NavigationBar.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
const LOGO = require("../../../../../static/rv_logo.png");
import { Navigator } from "../../../../utilities/cms-operation/navigation";
import { CMSSearchComponent } from "./cms-search-component";

interface NavigationBarProps {}

interface NavigationBarState {
  /**
   *
   *
   * @type {boolean}
   * @memberof NavigationBarState
   */
  toggleSearch: boolean;
}
/**
 *
 *
 * @export
 * @class NavigationBar
 * @extends {React.Component}
 */
export class NavigationBar extends React.Component<
  NavigationBarProps,
  NavigationBarState
> {
  /**
   *Creates an instance of NavigationBar.
   * @param {NavSearchProps} props
   * @memberof NavigationBar
   */
  constructor(props: NavigationBarProps) {
    super(props);

    this.state = {
      toggleSearch: false,
    };
  }

  /**
   *
   *
   * @returns
   * @memberof NavigationBar
   */
  render() {
    return (
      <div>
        <nav
          className={
            "main-navbar navbar navbar-expand-md navbar-dark" +
            (this.state.toggleSearch ? "search-toggled " : "")
          }
          id="top-nav"
        >
          <div className="navbar-brand nav-logo">
            <img src={LOGO} alt="logo" onClick={Navigator.NavigateToCMSHome} />
            <img
              src="https://content.radivision.com/images/0a/us/0aUSTIfTQZu456apuXFTbw.svg"
              alt="tagline"
            />
          </div>
          <button
            className={
              this.state.toggleSearch
                ? "btn search-btn  btn-close"
                : "btn search-btn"
            }
            onClick={() => {
              this.setState({ toggleSearch: !this.state.toggleSearch }, () => {
                if (this.state.toggleSearch) {
                  const NAV = document.getElementById("search-input-nav");
                  if (NAV) {
                    NAV.focus();
                  }
                }
              });
            }}
            type="button"
          >
            {this.state.toggleSearch ? (
              <svg
                id="Layer_1"
                className="close"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 72 72"
              >
                <title>close-sm</title>
                <path
                  className="cls-1"
                  d="M46.77,36l23-23A7.62,7.62,0,0,0,59,2.24l-23,23-23-23A7.62,7.62,0,0,0,2.23,13l23,23-23,23A7.62,7.62,0,0,0,13,69.78l23-23,23,23A7.62,7.62,0,0,0,69.77,59Z"
                />
              </svg>
            ) : (
              <svg
                id="search"
                data-name="search"
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                viewBox="0 0 72 72"
              >
                <title>search</title>
                <path d="M31.08,0c1.35.23,2.73.37,4.06.71,11.59,3,19,10.29,21.74,21.92,2.1,8.93-.07,17.1-5.75,24.33-.07.09-.15.17-.22.27s0,.08-.07.17c.14.16.28.34.45.5L71.51,68.12a5.6,5.6,0,0,0,.49.36v.29L68.77,72h-.29a2.69,2.69,0,0,0-.31-.44L47.8,51.19a4.74,4.74,0,0,0-.39-.36c-.14.09-.28.16-.4.25a27.49,27.49,0,0,1-14.28,6.24C22.56,58.53,14,55.33,7.08,47.7A28,28,0,0,1,.32,33.29C.2,32.56.11,31.81,0,31.08V26.44c.23-1.35.38-2.73.72-4.05Q5.19,5.2,22.38.72C23.71.37,25.09.23,26.44,0ZM52.75,28.77a24,24,0,1,0-24.24,24A24,24,0,0,0,52.75,28.77Z" />
              </svg>
            )}
          </button>
          <form
            className="form-inline search-block"
            onSubmit={(event) => {
              //needed to prevent enter key press from submitting form
              event.preventDefault();
            }}
          >
            <div
              className={
                this.state.toggleSearch
                  ? "search-form search-form-shown"
                  : " search-form"
              }
            >
              <CMSSearchComponent
                placeholder="Search Radivision"
                toggleSearch={() => {
                  this.setState({ toggleSearch: !this.state.toggleSearch });
                }}
                searchComponentOpened={this.state.toggleSearch}
                id="search-input-nav"
              />
            </div>
            <div
              className="search-overley"
              onClick={() =>
                this.setState({ toggleSearch: !this.state.toggleSearch })
              }
            />
          </form>
        </nav>
      </div>
    );
  }
}
