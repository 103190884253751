/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type EntityActivityKind = "EDUCATIONAL" | "GOVERNMENT_AGENCY" | "NON_PROFIT" | "PARTNERSHIP" | "PRIVATELY_HELD" | "PUBLIC_COMPANY" | "SELF_EMPLOYED" | "SELF_OWNED" | "%future added value";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type SocialMediaPlatformKind = "ANGEL_LIST" | "FACEBOOK" | "INSTAGRAM" | "LINKED_IN" | "MEDIUM" | "OTHER" | "PINTEREST" | "REDDIT" | "SNAPCHAT" | "TUMBLR" | "TWITTER" | "WEIBO" | "WHATS_APP" | "YOU_TUBE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type CreateEntityInput = {
    readonly activities?: ReadonlyArray<EntityActivityKind> | null;
    readonly badges?: ReadonlyArray<string> | null;
    readonly clientMutationId: string;
    readonly contact?: CreateContactInput | null;
    readonly description?: string | null;
    readonly founded?: unknown | null;
    readonly fundingRound?: string | null;
    readonly hasPortfolio?: boolean | null;
    readonly hasPublicPage?: boolean | null;
    readonly industry?: string | null;
    readonly investmentsBy?: ReadonlyArray<CreateInvestmentInput> | null;
    readonly investmentsIn?: ReadonlyArray<CreateInvestmentInput> | null;
    readonly isFundraising?: boolean | null;
    readonly locations?: ReadonlyArray<CreateEntityLocationInput> | null;
    readonly mediaAbout?: ReadonlyArray<string> | null;
    readonly people?: ReadonlyArray<string | null> | null;
    readonly name?: string | null;
    readonly noOfEmployees?: CreateRangeInput | null;
    readonly noOfPortfolioCompanies?: CreateRangeInput | null;
    readonly northStar?: ReadonlyArray<CreateQuestionResponseInput> | null;
    readonly portfolio?: ReadonlyArray<string> | null;
    readonly portfolioEntities?: string | null;
    readonly portfolioMarketCap?: string | null;
    readonly portfolioPeople?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly questionsResponses?: ReadonlyArray<CreateQuestionResponseInput> | null;
    readonly socialMediaCredentials?: ReadonlyArray<CreateUpdateSocialMediaCredentialInput> | null;
    readonly socialMediaProfiles?: ReadonlyArray<CreateUpdateSocialMediaProfileInput> | null;
    readonly tagLine?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly websiteUrl?: string | null;
    readonly opportunities?: OpportunitiesInput | null;
};
export type CreateContactInput = {
    readonly email?: string | null;
    readonly location?: CreateGeographicalLocationInput | null;
    readonly name?: string | null;
    readonly person?: string | null;
    readonly phone?: string | null;
};
export type CreateGeographicalLocationInput = {
    readonly address?: string | null;
    readonly isDefault?: boolean | null;
    readonly label?: string | null;
    readonly openLocationCode?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreateInvestmentInput = {
    readonly amount?: string | null;
    readonly currency?: string | null;
    readonly link?: string | null;
    readonly deckUrl?: string | null;
    readonly email?: string | null;
    readonly entity?: string | null;
    readonly fundingRound?: string | null;
    readonly investee?: string | null;
    readonly investmentDate?: unknown | null;
    readonly investmentEndDate?: unknown | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreateEntityLocationInput = {
    readonly activities?: ReadonlyArray<EntityActivityKind> | null;
    readonly badges?: ReadonlyArray<string> | null;
    readonly contact?: CreateContactInput | null;
    readonly description?: string | null;
    readonly isHeadquarters?: boolean | null;
    readonly label?: string | null;
    readonly location?: CreateGeographicalLocationInput | null;
    readonly name?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type CreateRangeInput = {
    readonly maximum?: number | null;
    readonly minimum?: number | null;
    readonly isGreaterThan?: boolean | null;
    readonly isLessThan?: boolean | null;
};
export type CreateQuestionResponseInput = {
    readonly choices?: ReadonlyArray<string> | null;
    readonly content?: string | null;
    readonly entityId?: string | null;
    readonly question: string;
    readonly questionnaire: string;
};
export type CreateUpdateSocialMediaCredentialInput = {
    readonly credentials: string;
    readonly platform: SocialMediaPlatformKind;
};
export type CreateUpdateSocialMediaProfileInput = {
    readonly platform: SocialMediaPlatformKind;
    readonly url: string;
};
export type OpportunitiesInput = {
    readonly productAndServices?: OpportunitiesItemInput | null;
    readonly collaboration?: OpportunitiesItemInput | null;
    readonly careers?: OpportunitiesItemInput | null;
};
export type OpportunitiesItemInput = {
    readonly description?: string | null;
    readonly link?: string | null;
    readonly email?: string | null;
};
export type createEntityMutationVariables = {
    readonly input: CreateEntityInput;
};
export type createEntityMutationResponse = {
    readonly createEntity: {
        readonly clientMutationId: string;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly entity: {
            readonly __typename: string;
            readonly id: string;
            readonly name: string | null;
            readonly revision: string | null;
            readonly previews: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly id: string;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
        } | null;
        readonly hasErrors: boolean | null;
    } | null;
};
export type createEntityMutation = {
    readonly response: createEntityMutationResponse;
    readonly variables: createEntityMutationVariables;
};



/*
mutation createEntityMutation(
  $input: CreateEntityInput!
) {
  createEntity(input: $input) {
    clientMutationId
    errors {
      id
      location
    }
    entity {
      __typename
      id
      name
      revision
      previews {
        count
        edges {
          cursor
          preview {
            link
            kind
            mediaAsset {
              id
              files {
                id
                name
                path
              }
              topLevelMediaType {
                kind
                label
                id
              }
            }
          }
        }
      }
    }
    hasErrors
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateEntityInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "path",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createEntityMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createEntity",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateEntityPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "entity",
            "storageKey": null,
            "args": null,
            "concreteType": "Entity",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "previews",
                "storageKey": null,
                "args": null,
                "concreteType": "PreviewsConnection",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewEdge",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "preview",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Preview",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaAsset",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaAsset",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v12/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "topLevelMediaType",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TopLevelMediaType",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v13/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v14/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createEntityMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createEntity",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateEntityPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "entity",
            "storageKey": null,
            "args": null,
            "concreteType": "Entity",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "previews",
                "storageKey": null,
                "args": null,
                "concreteType": "PreviewsConnection",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewEdge",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "preview",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Preview",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaAsset",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaAsset",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v12/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "topLevelMediaType",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TopLevelMediaType",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v13/*: any*/),
                                  (v3/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v14/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "createEntityMutation",
    "id": null,
    "text": "mutation createEntityMutation(\n  $input: CreateEntityInput!\n) {\n  createEntity(input: $input) {\n    clientMutationId\n    errors {\n      id\n      location\n    }\n    entity {\n      __typename\n      id\n      name\n      revision\n      previews {\n        count\n        edges {\n          cursor\n          preview {\n            link\n            kind\n            mediaAsset {\n              id\n              files {\n                id\n                name\n                path\n              }\n              topLevelMediaType {\n                kind\n                label\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    hasErrors\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '39e48ac4518665bcd9a1ea0021d05d36';
export default node;
