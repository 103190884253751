/**
 * @author Maria Osama
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-06-25 11:18:20
 * @description Implementation of the request-password-reset-modal React component
 * @filename request-password-reset-modal.tsx
 */

import React from "react";
import { RdvButton } from "../../page-framework/rdv-button";
import { AccountVerificationModal } from "./account-verification-modal";
import { ConfirmPasswordResetModal } from "./confirm-password-reset-modal";
import { CreateAccountModal } from "./create-account-modal";

/**
 * The props of the PasswordResetModal component.
 *
 * @interface
 */
interface RequestPasswordResetModalProps {
  /**
   * A function that changes the current modal.
   *
   * @type {(modal: any, props?: {}):void}
   */
  changeModal: (modal?: any, props?: {}) => void;

  /**
   * Closes the parent modal.
   *
   * @type {():void}
   */
  closeModal: () => void;

  /**
   * An existing email.
   *
   * @type {string}
   */
  email?: string;

  /**
   * The ID of the PasswordRecovery class
   *
   * @type {string}
   */
  id: string;

  /**
   * Navigates to the technical support pages and closes the modal.
   *
   * @type {():void}
   */
  navigateToTechnicalSupport: () => void;

  /**
   * A function that returns a promise to resend the accoutn verification email.
   *
   * @type {function(email: string):Promise<any>}
   */
  resendAccountVerificationEMail: (email: string) => Promise<any>;

  /**
   * A function that returns a promise to start the process of resetting the password for an account.
   *
   * @type {function(email: string):Promise<*>}
   */
  resetPassword: (email: string) => Promise<any>;

  /**
   * A function that sets the title of the modal.
   *
   * @type {function(title:string):void}
   * @memberof CreateAccountProps
   */
  setTitle: (title: string) => void;
}

/**
 * The state of the PasswordResetModal component.
 *
 * @interface
 */
interface RequestPasswordResetModalState {
  /**
   * The email address of the user
   *
   * @type {string}
   */
  email: string;

  /**
   * A current error.
   *
   * @type {AWS.AWSError}
   */
  error: {
    code: string;
    message: string;
  };

  /**
   * A flag which is true if there is an error.
   *
   * @type {boolean}
   */
  hasError: boolean;
}

/**
 * A React component that renders the request password reset modal.
 *
 * @extends {React.Component< RequestPasswordResetModalProps,  RequestPasswordResetModalState>}
 */
export class RequestPasswordResetModal extends React.Component<
  RequestPasswordResetModalProps,
  RequestPasswordResetModalState
> {
  /**
   * Constructor
   *
   * @param {RequestPasswordResetModalProps} props The props of the component.
   */
  constructor(props: RequestPasswordResetModalProps) {
    super(props);
    this.state = {
      email: this.props.email,
      error: {
        code: null,
        message: null,
      },
      hasError: false,
    };
    this.navigateToTechnicalSupport = this.navigateToTechnicalSupport.bind(
      this
    );
    this.navigateToCreateAccount = this.navigateToCreateAccount.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
  }

  /**
   * Invoked by the React framework after the component output has been rendered to the DOM
   */
  componentDidMount() {
    this.props.setTitle("Request Password Reset");
    this.setState({
      error: null,
      hasError: false,
    });
  }

  /**
   * Changes the current modal to the create account modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  navigateToCreateAccount(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();
    this.props.changeModal(CreateAccountModal);
  }

  /**
   * Navigates to the technical support page and closes the modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  navigateToTechnicalSupport(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.props.navigateToTechnicalSupport();
  }

  /**
   * Changes the state's email property as user input changes.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The change event on the password input field.
   */
  onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({
      email: event.target.value,
    });
  }

  /**
   * Invoked when the submit button is clicked.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} e The click event
   */
  onSubmit(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();

    // attempt to validate provided email address before
    if (
      this.state.email.length <= 0 ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      const emailValidationError: {
        code: string;
        message: string;
      } = {
        code: "InvalidEmailException",
        message:
          " The email address that you've entered is invalid. Please try again",
      };

      this.setState({
        error: emailValidationError,
        hasError: true,
      });
    } else {
      this.props
        .resetPassword(this.state.email)
        .then(
          (result: CognitoIdentityServiceProvider.GetUserResponse): void => {
            this.props.changeModal(ConfirmPasswordResetModal, {
              email: this.state.email,
            });
          }
        )
        .catch((e: AWSError): void => {
          const awsError: {
            code: string;
            message: string;
          } = {
            code: e.code,
            message: e.message,
          };

          this.setState({
            error: awsError,
            hasError: true,
          });
        });
    }
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    const footer: React.ReactNode = (
      <div id="requestPasswordResetFooter">
        <h4> Don't have an account?</h4>
        <a href="">Create one here</a>
      </div>
    );
    return (
      <div id="requestPasswordReset">
        <h1>Reset Your Password</h1>
        <h3>Please enter the Email associated with your account.</h3>
        <form>
          <label className="form-group has-float-label">
            <input
              type="email"
              className="form-control input-lg mt-4"
              id="forgetPassword-email"
              placeholder="Enter your email"
              required={true}
              onChange={this.onEmailChange}
              value={this.state.email}
            />
            <span>
              Your Email<span className="required-star">*</span>
            </span>
          </label>
          {this.state.hasError ? this.renderErrorMessage() : null}
          <div className="form-row align-items-center text-center">
            <RdvButton type="submit" text="submit" onClick={this.onSubmit} />
          </div>
        </form>
      </div>
    );
  }

  /**
   * Navigates the modal to the create account modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  renderErrorMessage(): JSX.Element {
    let element: JSX.Element;

    if (this.state.hasError) {
      switch (this.state.error.code) {
        case "InvalidParameterException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The email address supplied is invalid. Please{" "}
                <a href="#" onClick={this.navigateToTechnicalSupport}>
                  contact Radivision's technical support
                </a>{" "}
                or retry your request with a valid email address.
              </h6>
            </div>
          );
          break;
        case "InvalidEmailException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>{this.state.error.message}</h6>
            </div>
          );

          break;
        case "UserNotFoundException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The email address supplied is unknown or has not been verified.
                Please{" "}
                <a href="#" onClick={this.verifyAccount}>
                  verify the account
                </a>{" "}
                ,{" "}
                <a href="#" onClick={this.navigateToCreateAccount}>
                  create an account
                </a>{" "}
                or{" "}
                <a href="#" onClick={this.navigateToTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;

        default:
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                An unexpected error has occurred. Please{" "}
                <a href="#" onClick={this.navigateToTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
      }
    }
    return element;
  }

  /**
   * Returns a promise to verify the account.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  verifyAccount(e: React.MouseEvent<HTMLAnchorElement>): Promise<any> {
    e.preventDefault();
    return this.props
      .resendAccountVerificationEMail(this.state.email)
      .then(
        (): Promise<void> => {
          this.props.changeModal(AccountVerificationModal, {
            email: this.state.email,
          });
          return Promise.resolve();
        }
      )
      .catch((e: AWSError): void => {
        this.setState({
          error: e,
          hasError: true,
        });
      });
  }
}
