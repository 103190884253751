/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreateUrlRouteInput = {
    readonly alias?: string | null;
    readonly clientMutationId: string;
    readonly rules?: ReadonlyArray<CreateUrlRouteRuleInput | null> | null;
    readonly label?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreateUrlRouteRuleInput = {
    readonly cognitoIdentityPool?: string | null;
    readonly cognitoUserPoolGroups?: ReadonlyArray<string> | null;
    readonly description?: string | null;
    readonly htmlDocument?: string | null;
    readonly label?: string | null;
    readonly queryParameters?: ReadonlyArray<CreateQueryParameterRuleInput> | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreateQueryParameterRuleInput = {
    readonly name?: string | null;
    readonly values?: ReadonlyArray<string> | null;
};
export type createUrlRouteMutationVariables = {
    readonly input: CreateUrlRouteInput;
};
export type createUrlRouteMutationResponse = {
    readonly createUrlRoute: {
        readonly clientMutationId: string | null;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly urlRoute: {
            readonly id: string;
            readonly alias: string | null;
            readonly description: string | null;
            readonly label: string | null;
            readonly revision: string | null;
            readonly rules: ReadonlyArray<{
                readonly cognitoIdentityPool: string | null;
                readonly cognitoUserPoolGroups: ReadonlyArray<string> | null;
                readonly description: string | null;
                readonly htmlDocument: {
                    readonly id: string;
                } | null;
                readonly id: string;
                readonly queryParameters: ReadonlyArray<{
                    readonly name: string | null;
                    readonly values: ReadonlyArray<string> | null;
                }> | null;
            }> | null;
        } | null;
    } | null;
};
export type createUrlRouteMutation = {
    readonly response: createUrlRouteMutationResponse;
    readonly variables: createUrlRouteMutationVariables;
};



/*
mutation createUrlRouteMutation(
  $input: CreateUrlRouteInput!
) {
  createUrlRoute(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    urlRoute {
      id
      alias
      description
      label
      revision
      rules {
        cognitoIdentityPool
        cognitoUserPoolGroups
        description
        htmlDocument {
          id
        }
        id
        queryParameters {
          name
          values
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUrlRouteInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUrlRoute",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateUrlRoutePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "urlRoute",
        "storageKey": null,
        "args": null,
        "concreteType": "UrlRoute",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "alias",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "label",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "rules",
            "storageKey": null,
            "args": null,
            "concreteType": "UrlRouteRule",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cognitoIdentityPool",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cognitoUserPoolGroups",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "htmlDocument",
                "storageKey": null,
                "args": null,
                "concreteType": "HtmlDocument",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ]
              },
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "queryParameters",
                "storageKey": null,
                "args": null,
                "concreteType": "QueryParameterRule",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "values",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createUrlRouteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "createUrlRouteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "createUrlRouteMutation",
    "id": null,
    "text": "mutation createUrlRouteMutation(\n  $input: CreateUrlRouteInput!\n) {\n  createUrlRoute(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    urlRoute {\n      id\n      alias\n      description\n      label\n      revision\n      rules {\n        cognitoIdentityPool\n        cognitoUserPoolGroups\n        description\n        htmlDocument {\n          id\n        }\n        id\n        queryParameters {\n          name\n          values\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3fcc15bec54b17097b3c3a0bcdfcfe36';
export default node;
