/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-11
 * @description Implementation of constants related to Errors
 * @filename errors-constants.ts
 */
export const ERROR_MESSAGES: {
  [index: string]: any;
} = {
  INVALID_PASSWORD_MESSAGE: `Your password must have 
                                8 characters long, one uppercase, one lowercase character,
                                one number & one special character :^$*.[]{}()?-"!@#%&/\,><':;|_~\``,
  INVALID_PASSWORD_REENTER_MESSAGE: `Those passwords didn't match. Try again.`,
  RE_SIGN_IN_MESSAGE: `Your session has expired - please sign in again`
};
