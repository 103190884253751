/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-01-21
 * @description Implementation of image handler utilities to be used in the app.
 * @filename imageHelper.ts
 */
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { ENVIRONMENT } from "../relay/relay-environment";
import { MediaAsset } from "./media-asset";
import { S3Helper } from "./s3";

/**
 *
 * @export
 * @interface CreateMediaAssetFileInput
 */
export interface MediaAssetFileFunctionInput {
  /**
   *
   *
   * @type {string}
   * @memberof CreateMediaAssetFileInput
   */
  attribution: string;
  /**
   *
   *
   * @type {string}
   * @memberof CreateMediaAssetFileInput
   */
  description: string;
  /**
   *
   *
   * @type {string}
   * @memberof CreateMediaAssetFileInput
   */
  fileName: string;
  /**
   *
   *
   * @type {string}
   * @memberof CreateMediaAssetFileInput
   */
  title: string;

  /**
   *
   *
   * @type {number}
   * @memberof MediaAssetFileFunctionInput
   */
  fileSize: number;

  /**
   *
   *
   * @type {string}
   * @memberof MediaAssetFileFunctionInput
   */
  format: string;

  /**
   * The top level media type of the media asset
   *
   * @type {TopLevelMediaKind}
   * @memberof MediaAssetFileFunctionInput
   */
  topLevelMediaType?: TopLevelMediaKind;
}

/**
 *
 *
 * @export
 * @class ImageUnloader
 */
export class ImageUpload {
  /**
   * Function to upload image binary content to s3 bucket
   *
   * @static
   * @param {{ fileBinaryContent: Blob;
   *     fileSize: number;
   *     fileName: string;
   *     mimeType: string;}} staticFileInformation
   * @returns Returns a promise containing a upload results
   * @memberof imageHelper
   */
  static uploadImageToS3(staticFileInformation: {
    fileBinaryContent: Blob;
    fileSize: number;
    fileName: string;
    mimeType: string;
  }): Promise<SendData> {
    let promise: Promise<SendData>;
    // // console.log("[uploadPersonProfileImage]", staticFileInformation);
    // attempt to validate image
    // size      : we validate size to prevent uploading corrupted files
    // mime-type : we validate mime-type to prevent uploading
    //             binary data without magic headers
    if (
      staticFileInformation.fileBinaryContent.size <= 0 ||
      staticFileInformation.mimeType.length < 1
    ) {
      promise = Promise.reject(
        new Error(
          "[Y6fFw9NGaUmoyEotoGfBJQ] invalid image provided [mime-type/size]"
        )
      );
    } else {
      // create a promise to handle s3 upload providing needed file data
      // file Binary content
      // generated file name
      // file mime-type
      promise = S3Helper.uploadToS3(
        staticFileInformation.fileBinaryContent,
        staticFileInformation.fileName,
        {
          ContentType: staticFileInformation.mimeType,
        }
      );
    }
    return promise;
  }

  /**
   *
   * @static
   * @param {MediaAssetFileFunctionInput} inputParameters
   * @returns {Promise<any>}
   * @memberof ImageHelper
   */
  static createMediaAssetFile(
    inputParameters: MediaAssetFileFunctionInput
  ): Promise<any> {
    let mediaAsset: MediaAsset;
    mediaAsset = new MediaAsset();

    return mediaAsset.createMediaAssetFromFile(ENVIRONMENT, inputParameters);
  }
}
