/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-05-24
 * @description Implementation of the GraphQL mutation to add a tag.
 * @filename change-hero-to-video-panel.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an podcast.
 *
 * @type {GraphQLTaggedNode}
 */
export const CHANGE_HERO_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation changeHeroToVideoPanelMutation($input: RemoveHeroComponentInput!) {
    changeHeroToVideoPanel(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
    }
  }
`;
