/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MediaAssetStateKind = "AVAILABLE" | "CREATED" | "DELETED" | "PROCESSING" | "%future added value";
export type UpdateMediaAssetInput = {
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly durationInMilliseconds?: number | null;
    readonly files?: ReadonlyArray<string | null> | null;
    readonly force?: boolean | null;
    readonly id: string;
    readonly label?: string | null;
    readonly revision?: string | null;
    readonly state?: MediaAssetStateKind | null;
    readonly tags?: ReadonlyArray<string | null> | null;
};
export type updateMediaAssetMutationVariables = {
    readonly input: UpdateMediaAssetInput;
};
export type updateMediaAssetMutationResponse = {
    readonly updateMediaAsset: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly mediaAsset: {
            readonly id: string;
            readonly description: string | null;
            readonly files: ReadonlyArray<{
                readonly id: string;
            }> | null;
        } | null;
    } | null;
};
export type updateMediaAssetMutation = {
    readonly response: updateMediaAssetMutationResponse;
    readonly variables: updateMediaAssetMutationVariables;
};



/*
mutation updateMediaAssetMutation(
  $input: UpdateMediaAssetInput!
) {
  updateMediaAsset(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    mediaAsset {
      id
      description
      files {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateMediaAssetInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMediaAsset",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateMediaAssetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mediaAsset",
        "storageKey": null,
        "args": null,
        "concreteType": "MediaAsset",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "files",
            "storageKey": null,
            "args": null,
            "concreteType": "MediaAssetFile",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateMediaAssetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateMediaAssetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateMediaAssetMutation",
    "id": null,
    "text": "mutation updateMediaAssetMutation(\n  $input: UpdateMediaAssetInput!\n) {\n  updateMediaAsset(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    mediaAsset {\n      id\n      description\n      files {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'a59b933297fed3ab172f0e55816220ba';
export default node;
