/**
 * @author Ahmed Samer
 * @date   2020-02-11
 * @description page to list user pool group
 * @filename ga-panel.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Grid } from "@material-ui/core";

const optionsRadial = {
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },

      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -20,
          show: true,
          color: "#888",
          fontSize: "13px"
        },
        value: {
          formatter: function(val) {
            return val;
          },
          color: "#111",
          fontSize: "30px",
          show: true
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#ABE5A1"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  stroke: {
    lineCap: "round"
  },
  labels: ["Percent"]
};
export interface GAPanelProps {
  /**
   *
   *
   * @type {{ value: string[] }[]}
   * @memberof GAPanelProps
   */
  pages: { value: string[] }[];

  /**
   *
   *
   * @type {{ name: string; percent: number }[]}
   * @memberof GAPanelProps
   */
  devices?: { mobiles: number; desktop: number; values: any[] };
}
/**
 *
 *
 * @export
 * @class GAPanel
 * @extends {Component<{}, {}>}
 */
export class GAPanel extends Component<GAPanelProps, {}> {
  render() {
    return (
      <Grid container spacing={2}>
        <Grid item lg={6} md={8} sm={6} xs={12}>
          <h3>Radivision Analytics</h3>
          <aside className="QueryReport-meta">
            <ul className="InlineDefinitionList">
              <li className="InlineDefinitionList-item">
                <span className="InlineDefinitionList-itemName">
                  Results showing:
                </span>
                <span className="InlineDefinitionList-itemValue">
                  {this.props.pages ? this.props.pages.length : 7}
                </span>
              </li>
              <li className="InlineDefinitionList-item">
                <span className="InlineDefinitionList-itemName">
                  Total results found:
                </span>
                <span className="InlineDefinitionList-itemValue">1743</span>
              </li>
              <li className="InlineDefinitionList-item">
                <span className="InlineDefinitionList-itemName">
                  Contains sampled data:
                </span>
                <span className="InlineDefinitionList-itemValue">No</span>
              </li>
            </ul>
          </aside>
          <div className="table-wrapper-scroll-y table-scrollbar">
            <table className="table table-bordered table-striped mb-0">
              <thead>
                <tr>
                  <th>Page</th>
                  <th>Views</th>
                  <th>Avg. TIME</th>
                </tr>
              </thead>
              <tbody>
                {this.props.pages ? (
                  this.props.pages.map(row => {
                    return (
                      <tr>
                        {row.value.map(item => {
                          return (
                            <td>
                              {item.indexOf(".") !== -1
                                ? new Date(Number(item) * 1000)
                                    .toISOString()
                                    .substr(11, 8)
                                : item}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Grid>
        <Grid item lg={6} md={8} sm={6} xs={12}>
          <Grid container>
            <Grid item lg={6} md={8} sm={6} xs={12}>
              <div className="report-cart">
                <Chart
                  options={optionsRadial}
                  series={[
                    this.props.devices
                      ? Math.round(
                          (this.props.devices.desktop /
                            (this.props.devices.mobiles +
                              this.props.devices.desktop)) *
                            100
                        )
                      : 50
                  ]}
                  type="radialBar"
                  width="280"
                />
                <img src="https://img.icons8.com/pastel-glyph/64/000000/monitor.png"></img>
                <span className="span-x"> Desktop </span>
                <span className="span-light">
                  {" "}
                  {this.props.devices ? this.props.devices.desktop : "-"}{" "}
                </span>
              </div>
            </Grid>
            <Grid item lg={6} md={8} sm={6} xs={12}>
              <div className="report-cart">
                <Chart
                  options={optionsRadial}
                  series={[
                    this.props.devices
                      ? Math.round(
                          (this.props.devices.mobiles /
                            (this.props.devices.desktop +
                              this.props.devices.mobiles)) *
                            100
                        )
                      : 50
                  ]}
                  type="radialBar"
                  width="280"
                />
                <img src="https://img.icons8.com/windows/64/000000/google-mobile.png"></img>
                <span className="span-x"> mobile </span>
                <span className="span-light">
                  {" "}
                  {this.props.devices ? this.props.devices.mobiles : "-"}{" "}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
