/**
 * @author Ahmed Samer
 * @copyright Copyright 2019 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-8-19
 * @description Implementation of the GraphQL mutation to remove user from cognito group.
 * @filename add-user-from-cognito-group.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update person.
 *
 * @type {GraphQLTaggedNode}
 */
export const ADD_USER_FROM_COGNITO_GROUP_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation addUserToCognitoGroupMutation($input: AddRemoveUserToGroupInput!) {
    addUserToCognitoGroup(input: $input) {
      clientMutationId
      errors {
        id
        location
      }
      hasErrors
    }
  }
`;
