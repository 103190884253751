/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MediaAssetFileStatusKind = "AVAILABLE" | "CREATED" | "DELETED" | "ERROR" | "PROCESSING" | "%future added value";
export type MediaAssetStateKind = "AVAILABLE" | "CREATED" | "DELETED" | "PROCESSING" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type mediaAssetPageQueryVariables = {
    readonly id: string;
};
export type mediaAssetPageQueryResponse = {
    readonly mediaAsset: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly mediaAsset: {
            readonly __typename: string;
            readonly id: string;
            readonly revision: string | null;
            readonly description: string | null;
            readonly durationInMilliseconds: number | null;
            readonly label: string | null;
            readonly files: ReadonlyArray<{
                readonly id: string;
                readonly name: string | null;
                readonly heightInPixels: number | null;
                readonly widthInPixels: number | null;
                readonly path: string | null;
                readonly status: {
                    readonly kind: MediaAssetFileStatusKind | null;
                } | null;
            }> | null;
            readonly state: {
                readonly kind: MediaAssetStateKind | null;
            } | null;
            readonly topLevelMediaType: {
                readonly kind: TopLevelMediaKind | null;
            } | null;
        } | null;
    } | null;
};
export type mediaAssetPageQuery = {
    readonly response: mediaAssetPageQueryResponse;
    readonly variables: mediaAssetPageQueryVariables;
};



/*
query mediaAssetPageQuery(
  $id: ID!
) {
  mediaAsset(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    mediaAsset {
      __typename
      id
      revision
      description
      durationInMilliseconds
      label
      files {
        id
        name
        heightInPixels
        widthInPixels
        path
        status {
          kind
          id
        }
      }
      state {
        kind
        id
      }
      topLevelMediaType {
        kind
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "durationInMilliseconds",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "heightInPixels",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "widthInPixels",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v15 = [
  (v14/*: any*/)
],
v16 = [
  (v14/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "mediaAssetPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mediaAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "MediaAssetPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "mediaAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "MediaAsset",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "files",
                "storageKey": null,
                "args": null,
                "concreteType": "MediaAssetFile",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MediaAssetFileStatus",
                    "plural": false,
                    "selections": (v15/*: any*/)
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "state",
                "storageKey": null,
                "args": null,
                "concreteType": "MediaAssetState",
                "plural": false,
                "selections": (v15/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "topLevelMediaType",
                "storageKey": null,
                "args": null,
                "concreteType": "TopLevelMediaType",
                "plural": false,
                "selections": (v15/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "mediaAssetPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mediaAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "MediaAssetPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "mediaAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "MediaAsset",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "files",
                "storageKey": null,
                "args": null,
                "concreteType": "MediaAssetFile",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MediaAssetFileStatus",
                    "plural": false,
                    "selections": (v16/*: any*/)
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "state",
                "storageKey": null,
                "args": null,
                "concreteType": "MediaAssetState",
                "plural": false,
                "selections": (v16/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "topLevelMediaType",
                "storageKey": null,
                "args": null,
                "concreteType": "TopLevelMediaType",
                "plural": false,
                "selections": (v16/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "mediaAssetPageQuery",
    "id": null,
    "text": "query mediaAssetPageQuery(\n  $id: ID!\n) {\n  mediaAsset(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    mediaAsset {\n      __typename\n      id\n      revision\n      description\n      durationInMilliseconds\n      label\n      files {\n        id\n        name\n        heightInPixels\n        widthInPixels\n        path\n        status {\n          kind\n          id\n        }\n      }\n      state {\n        kind\n        id\n      }\n      topLevelMediaType {\n        kind\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '309bf9cbb3a9526e275c10eb0cd02723';
export default node;
