/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to add an newsletter story.
 * @filename create-newsletter-story
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an newsletter.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_NEWSLETTER_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createNewsletterStoryMutation($input: CreateNewsletterStoryInput!) {
    createNewsletterStory(input: $input) {
      clientMutationId
      story {
        ... on NewsletterStory {
          id
          title
        }
      }
      hasErrors
    }
  }
`;
