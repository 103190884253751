/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-03
 * @description Implementation of MarketplaceEntry utilities to be used in the app.
 * @filename article.ts
 */
import { CreateStoryInput, UpdateStoryInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { ArticlePageFormInput } from "../component-configuration/cms-pages";
import { ArticlePageState } from "../react-components/inner-pages/content-management/article-page";
import { CREATE_MARKET_STORY_GRAPHQL_MUTATION } from "../relay/mutations/create-market-story";
import { UPDATE_MARKET_ENTRY_MUTATION } from "../relay/mutations/update-market-entry";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { Story } from "./cms-operation/story";
import { commitMutation } from "./relay";

export class MarketplaceEntry {
  /**
   * Returns a promise to add new article to database.
   *
   * @param {ArticlePageState} articleInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addMarketplaceEntry(
    articleInput: ArticlePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateArticleStoryInput
    let createArticleStoryInput: any;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: CreateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      articleInput.link === undefined ||
      articleInput.link === null ||
      articleInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("PwYWd96YD0KZ8lb8ucuEqA"));
    } else {
      storyInput = Story.createStoryInput(articleInput);

      // initialize create article graphQl input
      createArticleStoryInput = {
        ...storyInput,
        promoCode:
          articleInput?.promoCode && articleInput?.promoCode.length > 0
            ? articleInput.promoCode.trim()
            : undefined,

        expires:
          articleInput?.expires?.length > 0
            ? new Date(
                isNaN(Number(articleInput?.expires?.trim()))
                  ? articleInput?.expires?.trim()
                  : Number(articleInput?.expires?.trim())
              ).toISOString()
            : undefined,
        offerType:
          articleInput?.offerType && articleInput?.offerType.length > 0
            ? articleInput.offerType.trim()
            : undefined,
        peopleEntities: articleInput.peopleEntities,
        isPromoted: articleInput.featured,
        limited: articleInput.isLimited,
        link: encodeURI(articleInput.link.trim()),
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_MARKET_STORY_GRAPHQL_MUTATION,
        variables: {
          input: createArticleStoryInput,
        },
      };
      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        // console.log(mutationResults);
        return mutationResults.createMarketEntryStory.hasErrors === false
          ? Promise.resolve(mutationResults.createMarketEntryStory.story)
          : Promise.reject(mutationResults.createMarketEntryStory.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof MarketplaceEntry
   */
  static updateMarketplaceEntry(
    articleInput: ArticlePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let updateArticleStoryInput: any;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: UpdateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      articleInput.link === undefined ||
      articleInput.link === null ||
      articleInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("11MsufFPsEqmmoLIxFjgcg"));
    } else {
      storyInput = Story.updateStoryInput(articleInput);

      // initialize create article graphQl input
      updateArticleStoryInput = {
        ...storyInput,
        promoCode:
          articleInput?.promoCode && articleInput?.promoCode.length > 0
            ? articleInput.promoCode.trim()
            : undefined,

        expires:
          articleInput?.expires?.length > 0
            ? new Date(
                isNaN(Number(articleInput?.expires?.trim()))
                  ? articleInput?.expires?.trim()
                  : Number(articleInput?.expires?.trim())
              ).toISOString()
            : undefined,
        offerType:
          articleInput?.offerType && articleInput?.offerType.length > 0
            ? articleInput.offerType.trim()
            : undefined,
        isPromoted: articleInput.featured,
        limited: articleInput.isLimited,
        peopleEntities: articleInput.peopleEntities,
        link: encodeURI(articleInput.link.trim()),
      };

      // initialize mutation configuration
      config = {
        mutation: UPDATE_MARKET_ENTRY_MUTATION,
        variables: {
          input: updateArticleStoryInput,
        },
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateMarketEntryStory.hasErrors === false
          ? Promise.resolve(mutationResults.updateMarketEntryStory.story)
          : Promise.reject(mutationResults.updateMarketEntryStory.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof MarketplaceEntry
   */
  static deleteMarketplaceEntry(
    articleInput: ArticlePageState,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
