/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Initial page component
 * @filename InitialPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Publisher from "./publisher";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";

import {
  Entity as GraphEntity,
  PreviewKind,
  GRAPHQL_TYPE_ENTITY,
  TopLevelMediaKind,
  SocialMediaPlatformKind,
  GRAPHQL_TYPE_PERSON
} from "@radivision/graphql";
import ModalConductor from "./modal/modal-conductor";
import { Validator } from "../../../utilities/cms-operation/validator";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import { Entity } from "../../../utilities/entity";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Loader } from "../../page/loader";
import {
  EntityPageFormInput,
  FormGroupInput,
  PreviewItem
} from "../../../component-configuration/cms-pages";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { ImageHelper } from "../../../utilities/image-helper";
import ManageMediaPanel from "./media/manage-media-panel";



/**
 *
 */
 const SEARCH_FILTERS_ENTITIES: { field: string; values: string[] }[] = [
  {
    field: "label",
    values: [

      GRAPHQL_TYPE_PERSON,
    ],
  },
];

/**
 *
 *
 * @export
 * @interface EntityPageProps
 */
export interface EntityPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof PodcastPageProps
   */
  entity?: { id: string };

  /**
   *
   *
   * @type {PodcastStory}
   * @memberof PodcastPageProps
   */
  graphEntity?: GraphEntity;
}

/**
 *
 *
 * @interface EntityPageState
 */
interface EntityPageState {
  /**
   *
   *
   * @type {EntityPageFormInput}
   * @memberof EntityPageState
   */
  entityPageInput: EntityPageFormInput;

  /**
   *
   *
   * @type {FormGroupInput}
   * @memberof EntityPageState
   */
  formGroupInput: FormGroupInput;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof EntityPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof EntityPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {boolean}
   * @memberof EntityPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class EntityPage
 * @extends {React.Component<EntityPageProps, EntityPageState>}
 */
export class EntityPage extends React.Component<
  EntityPageProps,
  EntityPageState
> {
  /**
   * References to the publisher component in the EntityPage.
   *
   * @type {*}
   * @memberof EntityPage
   */
  publisherDetailsRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof EntityPage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the EntityPage.
   *
   * @type {*}
   * @memberof EntityPage
   */
  tagsRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof EntityPage
   */
  mediaAboutRef: any;



  peopleRef:any;

  /**
   *Creates an instance of EntityPage.
   * @param {EntityPageProps} props
   * @memberof EntityPage
   */
  constructor(props: EntityPageProps) {
    super(props);

    //... bind needed function to class

    this.handleNameChanges = this.handleNameChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleDescriptionChanges = this.handleDescriptionChanges.bind(this);
    this.handleWebsiteChanges = this.handleWebsiteChanges.bind(this);
    this.handleInstagramIdChanges = this.handleInstagramIdChanges.bind(this);
    this.handleFacebookIdChanges = this.handleFacebookIdChanges.bind(this);
    this.handleTwitterIdChanges = this.handleTwitterIdChanges.bind(this);
    this.handleLinkedInIdChanges = this.handleLinkedInIdChanges.bind(this);
    this.handleAddMediaAboutChanges = this.handleAddMediaAboutChanges.bind(
      this
    );

    //... initialize required components references

    this.publisherDetailsRef = React.createRef();
    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();
    this.mediaAboutRef = React.createRef();
    this.peopleRef = React.createRef();

    //... initial component state
    this.state = this.handleComponentStateInitialize(this.props.graphEntity);
  }

  /**
   *
   *
   * @param {PodcastStory} entityProps
   * @returns {entityPageState}
   * @memberof PodcastPage
   */
  handleComponentStateInitialize(entityProps: GraphEntity): EntityPageState {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let entityPageInput: EntityPageFormInput = {
      id: undefined,
      name: "",
      publisherName: "",
      publishedDate: currentTime,
      isDeleted: false,
      isNew: false,
      socialMediaProfiles: {
        FacebookID: "",
        InstagramID: "",
        LinkedInID: "",
        TwitterID: ""
      },
      mediaAbout: []
    };

    let state: EntityPageState = {
      modal,
      entityPageInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    // validate provided label

    if (entityProps !== null && entityProps !== undefined) {
      if (entityProps.id) state.entityPageInput.id = entityProps.id;

      if (entityProps.name) state.entityPageInput.name = entityProps.name;

      if (entityProps.websiteUrl)
        state.entityPageInput.websiteUrl = entityProps.websiteUrl;

      if (entityProps.description)
        state.entityPageInput.description = entityProps.description;

      if (entityProps.isDeleted)
        state.entityPageInput.isDeleted = entityProps.isDeleted;

      if (entityProps.revision)
        state.entityPageInput.revision = entityProps.revision;

      // .............................
      // initialize form group inputs
      // .............................

      if (
        entityProps.socialMediaCredentials &&
        entityProps.socialMediaCredentials.length > 0
      ) {
        entityProps.socialMediaCredentials.map((credentials, index) => {
          switch (credentials.platform.kind) {
            case SocialMediaPlatformKind.FACEBOOK:
              state.entityPageInput.socialMediaProfiles.FacebookID =
                credentials.credential !== null &&
                credentials.credential !== undefined
                  ? credentials.credential
                  : "";
              break;

            case SocialMediaPlatformKind.TWITTER:
              state.entityPageInput.socialMediaProfiles.TwitterID =
                credentials.credential !== null &&
                credentials.credential !== undefined
                  ? credentials.credential
                  : "";
              break;

            case SocialMediaPlatformKind.INSTAGRAM:
              state.entityPageInput.socialMediaProfiles.InstagramID =
                credentials.credential !== null &&
                credentials.credential !== undefined
                  ? credentials.credential
                  : "";
              break;

            case SocialMediaPlatformKind.LINKED_IN:
              state.entityPageInput.socialMediaProfiles.LinkedInID =
                credentials.credential !== null &&
                credentials.credential !== undefined
                  ? credentials.credential
                  : "";
              break;

            default:
              break;
          }
        });
      }

      if (
        entityProps.tags !== null &&
        entityProps.tags !== undefined &&
        entityProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = entityProps.tags.map(
          tag => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        entityProps.previews !== null &&
        entityProps.previews !== undefined &&
        entityProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = entityProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`
                  }
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }

      if (
        entityProps.mediaAbout !== null &&
        entityProps.mediaAbout !== undefined &&
        entityProps.mediaAbout.count > 0
      ) {
        state.formGroupInput.mediaItemProps = entityProps.mediaAbout.edges.map(
          (mediaItem: any) => {
            let mediaAboutItem: CarouselItem = {
              id: mediaItem.story.id,
              title: mediaItem.story.title,
              publishDate: mediaItem.story.publishDate,
              publisher: mediaItem.story.publisher,
              previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: mediaItem.story.previews,
                  previewKind: PreviewKind.SQUARE,
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  type: mediaItem.story.__typename
                },
                desiredDimensions: {
                  containerWidthRatio: 6 / 12,
                  numberOfItems: 6
                },
                isSquareImage: true,
                revision: mediaItem.story.revision
              }),
              type: mediaItem.story.__typename
            };

            return mediaAboutItem;
          }
        );
      }

      if (
        entityProps.people !== null &&
        entityProps.people !== undefined &&
        entityProps.people.count > 0
      ) {
        state.formGroupInput.people = entityProps.people.edges.map(
          (mediaItem: any) => {
            let mediaAboutItem: CarouselItem = {
              id: mediaItem.person.id,
              title: mediaItem.person.title,
              previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: mediaItem.person.previews,
                  previewKind: PreviewKind.HEADSHOT,
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  type: mediaItem.person.__typename
                },
                desiredDimensions: {
                  containerWidthRatio: 6 / 12,
                  numberOfItems: 6
                },
                isSquareImage: true,
                revision: mediaItem.person.revision
              }),
              type: mediaItem.person.__typename
            };

            return mediaAboutItem;
          }
        );
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @returns
   * @memberof EntityPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <div className="container">
              <h2 className="form-page-title">Entity Page</h2>
              <div className="Required">
                <p>*Required</p>
              </div>
              {/* Article Info */}
              <div className="Page--Info">
                <div className="Page--Info-Input">
                  <div className="Page-Field">
                    <label className="Page-Require">Name</label>
                    <input
                      type="text"
                      name="name"
                      className={`form-control ${
                        this.state.errorMessage.get("NAME") ? "is-invalid" : ""
                      }`}
                      value={this.state.entityPageInput.name}
                      onChange={this.handleNameChanges}
                    />
                    <span className="invalid-feedback">
                      {this.state.errorMessage.get("NAME")}
                    </span>
                  </div>
                  <div className="Page--Time">
                    <div className="Page-Field">
                      <label>Created</label>
                      <input
                        className="readonly form-control"
                        id="party"
                        type="datetime"
                        name="created"
                        value={currentTime}
                        readOnly={true}
                      />
                    </div>
                    <div className="Page-Field">
                      <label>Last Modified</label>
                      <input
                        className="readonly form-control"
                        id="party"
                        type="datetime"
                        name="lastmodified"
                        value={currentTime}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Publisher Section */}
              <div className="Page--Publisher">
                <label>Owner</label>
                <Publisher
                  error={{
                    haveError: this.state.errorMessage.get("PUBLISHER")
                      ? true
                      : false,
                    message: this.state.errorMessage.get("PUBLISHER")
                  }}
                  ref={this.publisherDetailsRef}
                />
              </div>

              <div className="Page-Field">
                <label>Description</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.entityPageInput.description}
                  onChange={this.handleDescriptionChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>

              <div className="Page-Field">
                <label>Website</label>
                <input
                  type="text"
                  name="website"
                  className={`form-control ${
                    this.state.errorMessage.get("WEBSITE") ? "is-invalid" : ""
                  }`}
                  value={this.state.entityPageInput.websiteUrl}
                  onChange={this.handleWebsiteChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("WEBSITE")}
                </span>
              </div>

              {/* created and modified time */}
              {/* Story Status */}
              <div className="Page--Status">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="isdeleted"
                    checked={this.state.entityPageInput.isDeleted}
                    readOnly
                    className="custom-control-input"
                  />
                  <label className="custom-control-label">Is Deleted</label>
                </div>
              </div>
              <div className="Page--Time">
                <div className="Page-Field">
                  <label>Facebook ID</label>
                  <input
                    id="party"
                    type="text"
                    name="fb"
                    value={
                      this.state.entityPageInput.socialMediaProfiles.FacebookID
                    }
                    onChange={this.handleFacebookIdChanges}
                    className="form-control"
                  />
                </div>
                <div className="Page-Field">
                  <label>Instagram ID</label>
                  <input
                    id="party"
                    type="text"
                    name="instra"
                    value={
                      this.state.entityPageInput.socialMediaProfiles.InstagramID
                    }
                    className="form-control"
                    onChange={this.handleInstagramIdChanges}
                  />
                </div>
                <div className="Page-Field">
                  <label>LinkedIn ID</label>
                  <input
                    id="party"
                    type="text"
                    name="link"
                    value={
                      this.state.entityPageInput.socialMediaProfiles.LinkedInID
                    }
                    onChange={this.handleLinkedInIdChanges}
                    className="form-control"
                  />
                </div>
                <div className="Page-Field">
                  <label>Twitter ID</label>
                  <input
                    id="party"
                    type="text"
                    name="tw"
                    value={
                      this.state.entityPageInput.socialMediaProfiles.TwitterID
                    }
                    onChange={this.handleTwitterIdChanges}
                    className="form-control"
                  />
                </div>
              </div>

              {/* tag section */}
              <div className="Page--Tag">
                <Tag
                  ref={this.tagsRef}
                  tagsList={this.state.formGroupInput.tagsProps}
                />
              </div>
              {/* preview section */}
              <div className="Page--Preview">
                <ManagePreviewPanel
                  ref={this.previewPanelRef}
                  previewsList={this.state.formGroupInput.previewsProps}
                />
              </div>
            </div>

            <div className="Page-Field">
              <label>
                Media About
                <br />
              </label>
              <div className="Page--Badge">
                <ManageMediaPanel
                  ref={this.mediaAboutRef}
                  mediaItems={this.state.formGroupInput.mediaItemProps}
                />
              </div>
            </div>


            <div className="Page-Field">
              <label>
                People
                <br />
              </label>
              <div className="Page--Badge">
                <ManageMediaPanel
                  ref={this.peopleRef}
                  mediaItems={this.state.formGroupInput.people}
                  filter={SEARCH_FILTERS_ENTITIES}

                />
              </div>
            </div>
          </div>

          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let entityPageInput = this.state.entityPageInput;
                  entityPageInput.isDeleted = true;
                  this.setState({ entityPageInput });
                },
                unDeleteAction: () => {
                  let entityPageInput = this.state.entityPageInput;
                  entityPageInput.isDeleted = false;
                  this.setState({ entityPageInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {CarouselItem} suggest
   * @memberof PersonPage
   */
  handleAddMediaAboutChanges(suggestion: CarouselItem): void {
    // ... define need variables
    let entityPageInput: EntityPageFormInput = this.state.entityPageInput;
    // // console\.log(suggestion);

    // ... validate if suggestion is valid media item
    if (suggestion !== undefined && suggestion !== null) {
      if (suggestion.id) {
        entityPageInput.mediaAbout.push(suggestion.id);
      }
    }

    this.resetModalState();
    this.setState({ entityPageInput });
  }

  /**
   * Function Responsible for handling changes in entity is deleted state
   *
   * @memberof EntityPage
   */
  handleIsDeletedChanges(event: any): void {
    let isDeleted: boolean = event.target.checked ? true : false;
    let entityPageInput: EntityPageFormInput = this.state.entityPageInput;

    // // console\.log("[handleIsDeletedChanges] is article deleted", isDeleted);
    entityPageInput.isDeleted = isDeleted;
    this.setState({ entityPageInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleFacebookIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let entityPageInput: EntityPageFormInput = this.state.entityPageInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleFacebookIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    entityPageInput.socialMediaProfiles.FacebookID = eventValue;
    this.setState({ entityPageInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleTwitterIdChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let entityPageInput: EntityPageFormInput = this.state.entityPageInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleFacebookIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    entityPageInput.socialMediaProfiles.TwitterID = eventValue;
    this.setState({ entityPageInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleInstagramIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let entityPageInput: EntityPageFormInput = this.state.entityPageInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleFacebookIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    entityPageInput.socialMediaProfiles.InstagramID = eventValue;
    this.setState({ entityPageInput });
  }
  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleLinkedInIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let entityPageInput: EntityPageFormInput = this.state.entityPageInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleFacebookIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    entityPageInput.socialMediaProfiles.LinkedInID = eventValue;
    this.setState({ entityPageInput });
  }

  /**
   * Function Responsible for handling changes in article label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof EntityPage
   */
  handleDescriptionChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let entityPageInput = this.state.entityPageInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleNameChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    entityPageInput.description = eventValue;
    this.setState({ entityPageInput });
  }

  /**
   * Function Responsible for handling changes in article label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof EntityPage
   */
  handleWebsiteChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let entityPageInput = this.state.entityPageInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleNameChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    entityPageInput.websiteUrl = eventValue;
    this.setState({ entityPageInput });
  }

  /**
   * Function Responsible for handling changes in article label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof EntityPage
   */
  handleNameChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let entityPageInput = this.state.entityPageInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleNameChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    entityPageInput.name = eventValue;
    this.setState({ entityPageInput });
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   * Function Responsible for handling submitting new article
   *
   * @memberof EntityPage
   */
  handleSaveAction(): void {
    let entityPageState: EntityPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      entityPageState.entityPageInput.tags = this.tagsRef.current.extractTagsString();
      // extract publisher name
      entityPageState.entityPageInput.publisherName = this.publisherDetailsRef.current.extractPublisherDetails();
      // extract media  about
      entityPageState.entityPageInput.mediaAbout = this.mediaAboutRef.current.extractMediaItem();
      // extract previews list
      entityPageState.entityPageInput.previews = this.previewPanelRef.current.extractPreviews();

      entityPageState.entityPageInput.people = this.peopleRef.current.extractMediaItem();

      // validate errors
      errorMessage = Validator.validateCreateEntityInput(
        entityPageState.entityPageInput
      );

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console\.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to utility
        if (
          this.props.graphEntity !== null &&
          this.props.graphEntity !== undefined
        ) {
          internalPromise =
            entityPageState.entityPageInput.isDeleted === true
              ? Entity.deleteEntity(entityPageState.entityPageInput)
              : Entity.updateEntity(entityPageState.entityPageInput);
          internalPromise = internalPromise.then((results: any) => {
            // ... handle mutation success
            // console\.log(results);

            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["ENTITY"].message.confirmation.update;
            modal.modalBody = `${
              CMS_PAGE_CONSTANT["ENTITY"].message.confirmation.update
            }${results && results.id ? `,Entity-id : ${results.id}` : ""}`;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Entity.addEntity(
            entityPageState.entityPageInput
          ).then((results: any) => {
            // ... handle mutation success
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${CMS_PAGE_CONSTANT["ENTITY"].message.confirmation.add},Entity-id : ${results.id}`;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["ENTITY"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  revertPageInput(input?: GraphEntity): void {
    //... reset children state
    this.tagsRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.publisherDetailsRef.current.resetComponent();

    let state: EntityPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.graphEntity
    );
    this.setState(state);
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof EntityPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class EntityPageContainer extends React.Component<
  EntityPageProps,
  EntityPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let entityId: string;

    // console\.log("[EntityPageContainer] provided props", this.props);

    if (this.props.entity === null || this.props.entity === undefined) {
      node = <EntityPage />;
    } else {
      entityId = this.props.entity.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query entityPageQuery($id: ID!) {
              entity(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                entity {
                  __typename
                  id
                  name
                  revision
                  landingPageUrl
                  hasPublicPage
                  description
                  founded
                  industry
                  fundingRound
                  people {
                    count
                    pageInfo {
                      hasNextPage
                    }
                    edges {
                      role
                      person {
                        __typename
                        id
                        fullName
                        responsibility
                        description
                        landingPageUrl
                        previews {
                          count
                          edges {
                            cursor
                            preview {
                              link
                              kind
                              mediaAsset {
                                id
                                files {
                                  id
                                  name
                                  path
                                }
                                topLevelMediaType {
                                  kind
                                  label
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  noOfEmployees {
                    maximum
                    minimum
                    isGreaterThan
                    isLessThan
                  }
                  noOfPortfolioCompanies {
                    maximum
                    minimum
                    isGreaterThan
                    isLessThan
                  }
                  isFundraising
                  locations {
                    id
                    label
                  }
                  headquarters {
                    location {
                      label
                    }
                  }
                  hasPortfolio
                  portfolioMarketCap
                  mediaAbout {
                    count
                    edges {
                      cursor
                      story {
                        __typename
                        ... on RadivisionPost {
                          id
                          link
                          previews {
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                          subTitle
                          title
                          description
                          landingPageUrl
                        }
                        ... on OnlineCourseStory {
                          id
                          link
                          cost
                          publisher
                          publishedDate
                          timeRequiredInSeconds
                          description
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                                kind
                              }
                            }
                          }
                          subTitle
                          title
                        }
                        ... on RadivisionPost {
                          id
                          content
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                                kind
                              }
                            }
                          }
                          title
                        }
                        ... on ArticleStory {
                          id
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                                kind
                              }
                            }
                          }
                          title
                        }
                        ... on BookStory {
                          id
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                                kind
                              }
                            }
                          }
                          title
                        }
                        ... on NewsletterStory {
                          id
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                                kind
                              }
                            }
                          }
                          title
                        }
                        ... on PodcastStory {
                          id
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                                kind
                              }
                            }
                          }
                          title
                        }
                        ... on OriginalContentStory {
                          id
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                                kind
                              }
                            }
                          }
                          title
                        }
                      }
                    }
                  }
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        kind
                        mediaAsset {
                          id
                          files {
                            id
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                            label
                          }
                        }
                      }
                    }
                  }
                  socialMediaCredentials {
                    credential
                    platform {
                      kind
                    }
                  }
                  tags {
                    id
                    label
                  }
                  websiteUrl
                }
              }
            }
          `}
          variables={{
            id: entityId
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.entity.hasErrors) {
                return (
                  <div>
                    <p>unexpected error contact Radivision Technical team.</p>{" "}
                  </div>
                );
              } else if (
                props.entity.entity.__typename !== GRAPHQL_TYPE_ENTITY
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Entity id, {entityId} belongs to type{" "}
                      {props.entity.entity.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.entity.entity) {
                let pageProps: EntityPageProps = {
                  ...this.props,
                  graphEntity: props.entity.entity
                };
                return <EntityPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
