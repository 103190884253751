/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type SearchFacetInput = {
    readonly buckets?: ReadonlyArray<string> | null;
    readonly field: string;
    readonly size?: number | null;
    readonly sort?: string | null;
};
export type marketplacePageQueryVariables = {
    readonly cursor?: string | null;
    readonly facets?: ReadonlyArray<SearchFacetInput> | null;
    readonly filterQuery?: string | null;
    readonly order?: string | null;
};
export type marketplacePageQueryResponse = {
    readonly marketplace: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly facets: ReadonlyArray<{
            readonly buckets: ReadonlyArray<{
                readonly count: number | null;
                readonly value: string | null;
            }> | null;
            readonly name: string | null;
        }> | null;
        readonly marketplace: {
            readonly featured: ReadonlyArray<{
                readonly id: string;
            } | null> | null;
            readonly limited: ReadonlyArray<{
                readonly id: string;
            } | null> | null;
            readonly market: ReadonlyArray<{
                readonly id: string;
                readonly title: string | null;
                readonly link: string | null;
                readonly description: string | null;
                readonly state: string | null;
                readonly offerType: string | null;
                readonly promoCode: string | null;
                readonly expires: unknown | null;
                readonly peopleEntities: ReadonlyArray<{
                    readonly person: {
                        readonly landingPageUrl: string | null;
                    } | null;
                    readonly entity: {
                        readonly landingPageUrl: string | null;
                    } | null;
                }> | null;
                readonly previews: {
                    readonly edges: ReadonlyArray<{
                        readonly preview: {
                            readonly link: string | null;
                            readonly kind: PreviewKind | null;
                            readonly mediaAsset: {
                                readonly files: ReadonlyArray<{
                                    readonly name: string | null;
                                    readonly path: string | null;
                                }> | null;
                                readonly topLevelMediaType: {
                                    readonly kind: TopLevelMediaKind | null;
                                } | null;
                            } | null;
                        } | null;
                    }> | null;
                } | null;
            }> | null;
        } | null;
    } | null;
};
export type marketplacePageQuery = {
    readonly response: marketplacePageQueryResponse;
    readonly variables: marketplacePageQueryVariables;
};



/*
query marketplacePageQuery(
  $cursor: String
  $facets: [SearchFacetInput!]
  $filterQuery: String
  $order: String
) {
  marketplace(cursor: $cursor, facets: $facets, filterQuery: $filterQuery, order: $order) {
    hasErrors
    errors {
      id
      location
    }
    facets {
      buckets {
        count
        value
      }
      name
    }
    marketplace {
      featured {
        id
      }
      limited {
        id
      }
      market {
        id
        title
        link
        description
        state
        offerType
        promoCode
        expires
        peopleEntities {
          person {
            landingPageUrl
            id
          }
          entity {
            landingPageUrl
            id
          }
        }
        previews {
          edges {
            preview {
              link
              kind
              mediaAsset {
                files {
                  name
                  path
                  id
                }
                topLevelMediaType {
                  kind
                  id
                }
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "facets",
    "type": "[SearchFacetInput!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filterQuery",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "order",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cursor",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "facets",
    "variableName": "facets"
  },
  {
    "kind": "Variable",
    "name": "filterQuery",
    "variableName": "filterQuery"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "facets",
  "storageKey": null,
  "args": null,
  "concreteType": "SearchFacet",
  "plural": true,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "buckets",
      "storageKey": null,
      "args": null,
      "concreteType": "SearchFacetBucket",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        }
      ]
    },
    (v5/*: any*/)
  ]
},
v7 = [
  (v3/*: any*/)
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "featured",
  "storageKey": null,
  "args": null,
  "concreteType": "MarketEntryStory",
  "plural": true,
  "selections": (v7/*: any*/)
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "limited",
  "storageKey": null,
  "args": null,
  "concreteType": "MarketEntryStory",
  "plural": true,
  "selections": (v7/*: any*/)
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "offerType",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "promoCode",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expires",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageUrl",
  "args": null,
  "storageKey": null
},
v18 = [
  (v17/*: any*/)
],
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v21 = [
  (v17/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "marketplacePageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "marketplace",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "MarketPlaceListPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketplace",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketPlaceListEdge",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "market",
                "storageKey": null,
                "args": null,
                "concreteType": "MarketEntryStory",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "peopleEntities",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EntityPerson",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "person",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Person",
                        "plural": false,
                        "selections": (v18/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "entity",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Entity",
                        "plural": false,
                        "selections": (v18/*: any*/)
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "previews",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "preview",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Preview",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v19/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mediaAsset",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "MediaAsset",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "files",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "MediaAssetFile",
                                    "plural": true,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v20/*: any*/)
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v19/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "marketplacePageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "marketplace",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "MarketPlaceListPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketplace",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketPlaceListEdge",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "market",
                "storageKey": null,
                "args": null,
                "concreteType": "MarketEntryStory",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "peopleEntities",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EntityPerson",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "person",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Person",
                        "plural": false,
                        "selections": (v21/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "entity",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Entity",
                        "plural": false,
                        "selections": (v21/*: any*/)
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "previews",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "preview",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Preview",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v19/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mediaAsset",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "MediaAsset",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "files",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "MediaAssetFile",
                                    "plural": true,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v20/*: any*/),
                                      (v3/*: any*/)
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v19/*: any*/),
                                      (v3/*: any*/)
                                    ]
                                  },
                                  (v3/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "marketplacePageQuery",
    "id": null,
    "text": "query marketplacePageQuery(\n  $cursor: String\n  $facets: [SearchFacetInput!]\n  $filterQuery: String\n  $order: String\n) {\n  marketplace(cursor: $cursor, facets: $facets, filterQuery: $filterQuery, order: $order) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    facets {\n      buckets {\n        count\n        value\n      }\n      name\n    }\n    marketplace {\n      featured {\n        id\n      }\n      limited {\n        id\n      }\n      market {\n        id\n        title\n        link\n        description\n        state\n        offerType\n        promoCode\n        expires\n        peopleEntities {\n          person {\n            landingPageUrl\n            id\n          }\n          entity {\n            landingPageUrl\n            id\n          }\n        }\n        previews {\n          edges {\n            preview {\n              link\n              kind\n              mediaAsset {\n                files {\n                  name\n                  path\n                  id\n                }\n                topLevelMediaType {\n                  kind\n                  id\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '77631e0be439d8191b002a6195d97cf2';
export default node;
