import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import {
  GRAPHQL_TYPE_SUPPORT_ISSUE,
  SupportIssue as graphSupportIssue
} from "@radivision/graphql";
import { Loader } from "../../page/loader";
import { FloatingMenu } from "./floating-button/floating-menu";
import ModalConductor from "./modal/modal-conductor";
import { SupportIssuesPageInput } from "../../../component-configuration/cms-pages";
import { SupportIssue } from "../../../utilities/cms-operation/support-issues";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";

/**
 *
 *
 * @export
 * @interface SupportPageProps
 */
export interface SupportPageProps {
  /**
   *
   *
   * @type {string}
   * @memberof SupportPageProps
   */
  string?: string;

  /**
   *
   *
   * @type {string}
   * @memberof SupportPageProps
   */
  supportIssue?: graphSupportIssue;
}

/**
 *
 *
 * @interface SupportPageState
 */
interface SupportPageState {
  /**
   *
   *
   * @type {SupportIssuesPageInput}
   * @memberof SupportPageState
   */
  formInput: SupportIssuesPageInput;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof ManageHTMLDocumentPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof ManageHTMLDocumentPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {boolean}
   * @memberof SupportPageState
   */
  isLoading: boolean;
}

export default class SupportIssuesPage extends React.Component<
  SupportPageProps,
  SupportPageState
> {
  /**
   *Creates an instance of SupportPage.
   * @param {SupportPageProps} props
   * @memberof SupportPage
   */
  constructor(props: SupportPageProps) {
    super(props);

    this.handleDescriptionChanges = this.handleDescriptionChanges.bind(this);
    this.handleTypeChanges = this.handleTypeChanges.bind(this);
    this.handleNameChanges = this.handleNameChanges.bind(this);
    this.handleEmailChanges = this.handleEmailChanges.bind(this);
    this.handleBrowserChanges = this.handleBrowserChanges.bind(this);
    this.deleteSupportIssue = this.deleteSupportIssue.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    this.state = this.handleComponentStateInitialize(this.props.supportIssue);
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @param {graphSupportIssue} input
   * @memberof SupportPage
   */
  handleComponentStateInitialize(input: graphSupportIssue): SupportPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: SupportIssuesPageInput = {
      description: "",
      type: undefined,
      browserAndDeviceType: "",
      name: "",
      email: "",
      created: ""
    };

    let state: SupportPageState = {
      modal,
      formInput,
      isLoading: false,
      errorMessage: new Map()
    };

    if (input !== undefined && input !== null) {
      if (input.email) {
        state.formInput.email = String(input.email);
      }

      if (input.created) {
        state.formInput.created = new Date(input.created).toDateString();
      }

      if (input.name) {
        state.formInput.name = input.name;
      }

      if (input.id) {
        state.formInput.id = input.id;
      }

      if (input.description) {
        state.formInput.description = input.description;
      }

      if (input.browserAndDeviceType) {
        state.formInput.browserAndDeviceType = input.browserAndDeviceType;
      }

      if (input.revision) {
        state.formInput.revision = input.revision;
      }

      // if (input.type) {
      //   state.formInput.type = input.;
      // }
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof SupportPage
   */
  render() {
    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Support Issue</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label>Description</label>
                  <textarea
                    className={`readonly form-control ${
                      this.state.errorMessage.get("DESCRIPTION")
                        ? "is-invalid"
                        : ""
                    }`}
                    name=""
                    id=""
                    cols={30}
                    rows={10}
                    value={this.state.formInput.description}
                    readOnly={true}
                  />
                </div>
                <div className="Page-Field">
                  <label >Type</label>
                  <input
                    className={`readonly form-control ${
                      this.state.errorMessage.get("TYPE") ? "is-invalid" : ""
                    }`}
                    type="text"
                    name="type"
                    // value={this.state.formInput.type}
                    readOnly={true}
                  />
                </div>
                <div className="Page-Field">
                  <label >Browser & Platform</label>
                  <input
                    className={`readonly form-control ${
                      this.state.errorMessage.get("Browser") ? "is-invalid" : ""
                    }`}
                    type="text"
                    name="browser"
                    value={this.state.formInput.browserAndDeviceType}
                    readOnly={true}
                  />
                </div>
                <div className="Page-Field">
                  <label>Name</label>
                  <input
                    className={`readonly form-control ${
                      this.state.errorMessage.get("NAME") ? "is-invalid" : ""
                    }`}
                    type="text"
                    name="name"
                    value={this.state.formInput.name}
                    readOnly={true}
                  />
                </div>
                <div className="Page-Field">
                  <label>Email</label>
                  <div className="page-inline">
                    <div className="page-pic">pic</div>
                    <input
                      className={`readonly form-control ${
                        this.state.errorMessage.get("EMAIL") ? "is-invalid" : ""
                      }`}
                      type="email"
                      name="email"
                      value={this.state.formInput.email}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className={`readonly form-control ${
                    this.state.errorMessage.get("DATE") ? "is-invalid" : ""
                  }`}
                  id="party"
                  type="datetime"
                  name="created"
                  value={this.state.formInput.created}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className={`readonly form-control ${
                    this.state.errorMessage.get("DATE") ? "is-invalid" : ""
                  }`}
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={this.state.formInput.created}
                  readOnly={true}
                />
              </div>
            </div>

            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  name="isdeleted"
                  checked={this.state.formInput.isDeleted}
                  readOnly={true}
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>
            <Loader isActive={this.state.isLoading} />
            <ModalConductor
              currentModal={this.state.modal.modalName}
              ConfirmModalProps={{
                title: this.state.modal.modalTile,
                body: this.state.modal.modalBody,
                closeAction: this.state.modal.modalCloseAction
              }}
              close={() => {}}
            />
            <FloatingMenu
              menuItems={{
                revertible: this.revertPageInput,
                saveable: this.deleteSupportIssue
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @memberof ManageHTML
   */
  revertPageInput(input?: graphSupportIssue) {
    let state: SupportPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.supportIssue
    );
    this.setState(state);
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLTextAreaElement>} changeEvent
   * @memberof SupportPage
   */
  handleDescriptionChanges(
    changeEvent: React.FormEvent<HTMLTextAreaElement>
  ): void {
    // ... define need variables
    let formInput: SupportIssuesPageInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.description = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof SupportPage
   */
  handleNameChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: SupportIssuesPageInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.name = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof SupportPage
   */
  handleEmailChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: SupportIssuesPageInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.email = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof SupportPage
   */
  handleTypeChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: SupportIssuesPageInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    // type = SupportIssueType<eventValue>;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof SupportPage
   */
  handleBrowserChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: SupportIssuesPageInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.browserAndDeviceType = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @memberof ManageHTML
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof SupportPage
   */
  deleteSupportIssue() {
    let articleInput: SupportPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console\.log("[deleteSupportIssue] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (this.props.string !== null && this.props.string !== undefined) {
          internalPromise = SupportIssue.deleteIssue(
            articleInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody = CMS_PAGE_CONSTANT["SUPPORT"].message.confirmation.update;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Promise.resolve();
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["SUPPORT"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof ArticlePage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 *
 *
 * @export
 * @class HtmlDocumentPageContainer
 * @extends {React.Component<HtmlDocumentPageProps, HtmlDocumentPageState>}
 */
export class SupportIssuesPageContainer extends React.Component<
  SupportPageProps,
  SupportPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let supportIssueId: string;

    // console\.log("[SupportPageContainer] provided props", this.props);

    if (this.props.string === null || this.props.string === undefined) {
      node = <SupportIssuesPage />;
    } else {
      supportIssueId = this.props.string;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query supportIssuesPageQuery($id: ID!) {
              supportIssue(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                issue {
                  __typename
                  browserAndDeviceType
                  created
                  description
                  email
                  id
                  isDeleted
                  name
                  person {
                    id
                  }
                  revision
                  lastModified
                }
              }
            }
          `}
          variables={{
            id: supportIssueId
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.supportIssue.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.supportIssue.issue.__typename !==
                GRAPHQL_TYPE_SUPPORT_ISSUE
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Support-Issue id, {supportIssueId} belongs to type{" "}
                      {props.supportIssue.issue.__typename}, Need more help?
                      contact Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.supportIssue.issue) {
                let pageProps: SupportPageProps = {
                  ...this.props,
                  supportIssue: props.supportIssue.issue
                };
                return <SupportIssuesPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
