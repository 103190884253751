/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UpdateHtmlDocumentInput = {
    readonly description?: string | null;
    readonly clientMutationId: string;
    readonly force?: boolean | null;
    readonly label?: string | null;
    readonly id: string;
    readonly revision?: string | null;
    readonly panels?: ReadonlyArray<CreateHtmlDocumentPanelInput> | null;
    readonly tags?: ReadonlyArray<string | null> | null;
};
export type CreateHtmlDocumentPanelInput = {
    readonly component?: string | null;
    readonly label?: string | null;
    readonly properties?: ReadonlyArray<CreateComponentPropertyValueInput> | null;
};
export type CreateComponentPropertyValueInput = {
    readonly article?: string | null;
    readonly boolean?: boolean | null;
    readonly id?: string | null;
    readonly entity?: string | null;
    readonly poster?: string | null;
    readonly originalContent?: string | null;
    readonly book?: string | null;
    readonly fixedList?: string | null;
    readonly list?: string | null;
    readonly float?: number | null;
    readonly int?: number | null;
    readonly objectId?: string | null;
    readonly queryParameterName?: string | null;
    readonly string?: string | null;
    readonly json?: string | null;
};
export type updateHtmlDocumentMutationVariables = {
    readonly input: UpdateHtmlDocumentInput;
};
export type updateHtmlDocumentMutationResponse = {
    readonly updateHtmlDocument: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly htmlDocument: {
            readonly __typename: string;
            readonly id: string;
            readonly label: string | null;
            readonly description: string | null;
            readonly panels: ReadonlyArray<{
                readonly id: string;
                readonly component: {
                    readonly id: string;
                    readonly key: string | null;
                } | null;
            }> | null;
        } | null;
    } | null;
};
export type updateHtmlDocumentMutation = {
    readonly response: updateHtmlDocumentMutationResponse;
    readonly variables: updateHtmlDocumentMutationVariables;
};



/*
mutation updateHtmlDocumentMutation(
  $input: UpdateHtmlDocumentInput!
) {
  updateHtmlDocument(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    htmlDocument {
      __typename
      id
      label
      description
      panels {
        id
        component {
          id
          key
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateHtmlDocumentInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateHtmlDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateHtmlDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "htmlDocument",
        "storageKey": null,
        "args": null,
        "concreteType": "HtmlDocument",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "label",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "panels",
            "storageKey": null,
            "args": null,
            "concreteType": "HtmlDocumentPanel",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "component",
                "storageKey": null,
                "args": null,
                "concreteType": "Component",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "key",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateHtmlDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateHtmlDocumentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateHtmlDocumentMutation",
    "id": null,
    "text": "mutation updateHtmlDocumentMutation(\n  $input: UpdateHtmlDocumentInput!\n) {\n  updateHtmlDocument(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    htmlDocument {\n      __typename\n      id\n      label\n      description\n      panels {\n        id\n        component {\n          id\n          key\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '9bee909487798f36d137ded5cb16edce';
export default node;
