/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-11
 * @description Implementation of the general constants.
 * @filename general-constants.ts
 */

/**
 * Modals ids
 *
 * @constant
 * @type {string}
 */
export const ACTION_MODAL_ID: string = "oc2Vz0WdYUKklEQ0HFRGbg";

/**
 *
 * @constant
 * @type {string}
 */
export const CUSTOM_IMAGE_SELECT: string = `hW9S/eFUdEKgigXGzNmQoA`;

/**
 *
 * @constant
 * @type {string}
 */
export const ADD_IMAGE_LINK: string = `PNc38LO7iEq2SCRW5dhFJQ`;

/**
 *
 * @constant
 * @type {string}
 */
export const ACCEPT_IMAGE_FORMATS: string = `.png, .jpeg, .jpg, .svg`;

/**
 *
 * @constant
 * @type {string}
 */
export const ACCEPT_DOCUMENT_FORMATS: string = `.pdf, .png, .jpeg, .jpg`;

/**
 *
 * @constant
 * @type {string}
 */
export const ACCEPT_VIDEO_FORMATS: string = `.mp4, .mov, .mpeg, .mpeg2, .avi, .flv`;

/**
 *
 * @constant
 * @type {string}
 */
export const ACCEPT_AUDIO_FORMATS: string = `.mp3, .wav, .aiff`;

/**
 *
 * @constant
 * @type {string}
 */
export const ACCOUNT_CONTROL_MODAL_ID: string = `lIY7fmPSBkicYiyptUVmQw`;

/**
 * The ID and relative path of the Google logo file.
 *
 * @constant
 * @type {string}
 */
export const GOOGLE_LOGO_IMAGE_NAME: string = `b2/oj/B2ojktQlR1q24csneV7V7A.png`;

/**
 * The ID and relative path of the radivision footer logo file.

 * @constant
 * @type {string}
 */
export const RADIVISION_LOGO_IMAGE_NAME: string = `fb/wy/fbwYG1PGTRSpEYoVlsMBTQ.png`;
