/**
 * @author Linkon Islam
 * @date   2019-01-18
 * @description Manage html page component
 * @filename ManageHTML.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  HtmlDocumentPanel,
  CreateHtmlDocumentPanelInput
} from "@radivision/graphql";

/**
 *
 */
const property: string[] = [
  "boolean",
  "float",
  "int",
  "objectId",
  "queryParameterName",
  "string"
];
/**
 *
 *
 * @interface HtmlPropertyProps
 */
interface HtmlPropertyProps {
  /**
   *
   *
   * @type {HtmlDocumentPanel}
   * @memberof HtmlPropertyProps
   */
  input?: HtmlDocumentPanel;

  /**
   *
   *
   * @type {string}
   * @memberof HtmlPropertyProps
   */
  propertyId: string;

  /**
   *
   *
   * @param {number} id
   * @returns {*}
   * @memberof HtmlPropertyProps
   */
  removeClick(id: string): any;
}

/**
 *
 *
 * @export
 * @interface HtmlPropertyState
 */
export interface HtmlPropertyState {
  /**
   *
   *
   * @type {string}
   * @memberof HtmlPropertyState
   */
  component: string;

  /**
   *
   *
   * @type {string}
   * @memberof HtmlPropertyState
   */
  label?: string;

  /**
   *
   *
   * @type {string}
   * @memberof HtmlPropertyState
   */
  propertyKind?: string;

  /**
   *
   *
   * @type {string}
   * @memberof HtmlPropertyState
   */
  propertyValue?: string;
}

/**
 *
 *
 * @export
 * @param {HtmlPropertyProps} { propertyId, undefined, removeClick }
 * @returns
 */
export class HtmlProperty extends React.Component<
  HtmlPropertyProps,
  HtmlPropertyState
> {
  /**
   * Creates an instance of ManageHTML.
   * @param {HtmlDocumentPageProps} props
   * @memberof ManageHTML
   */
  constructor(props: HtmlPropertyProps) {
    super(props);

    //... bind needed function to class

    this.handleComponentIdChanges = this.handleComponentIdChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.extractPanelInput = this.extractPanelInput.bind(this);
    this.handlePropertyStringChange = this.handlePropertyStringChange.bind(
      this
    );
    this.handleSelect = this.handleSelect.bind(this);

    //... initial component state
    this.state = this.handleComponentStateInitialize(this.props.input);
  }

  /**
   *
   *
   * @returns {HtmlPropertyState}
   * @memberof HtmlProperty
   */
  handleComponentStateInitialize(input: HtmlDocumentPanel): HtmlPropertyState {
    let state: HtmlPropertyState = {
      component: "",
      label: ""
    };

    if (input !== undefined && input !== null) {
      if (input.component) {
        state.component = input.component.id;
      }

      if (input.label) {
        state.label = input.label;
      }

      // if (input.properties) {
      //   state.properties = input.properties.map(property => {
      //     return {
      //       id: "",
      //       ...property
      //     };
      //   });
      // }
    }

    return state;
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof HtmlProperty
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let label: string = this.state.label;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    label = eventValue;
    this.setState({ label });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof HtmlProperty
   */
  handleComponentIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let component: string = this.state.component;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    component = eventValue;
    this.setState({ component });
  }

  /**
   *
   *
   * @returns
   * @memberof HtmlProperty
   */
  render() {
    return (
      <div
        className="container"
        style={{ border: "1px solid", margin: "20px", padding: "15px" }}
      >
        <label>Html Panels </label>
        <a
          className="btn add-btn"
          onClick={() => {
            this.props.removeClick(this.props.propertyId);
          }}
        >
          <FontAwesomeIcon icon="times-circle" />
          close
        </a>
        <div className="row">
          <div className="input-field col m4">
            <label>Choose an option</label>
            <select className="form-control" onChange={undefined}>
              <option disabled={true} hidden={true} value="">
                --
              </option>
              <option value="none">---Choose a component--</option>
              <option value="Boolean">Loading...</option>
            </select>
          </div>
          <div className="col m3">
            <div className="input-field">
              <label>Component ID</label>
              <input
                id="ID"
                type="text"
                className="validate form-control"
                value={this.state.component}
                placeholder="component id"
                onChange={this.handleComponentIdChanges}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col m3">
            <div className="input-field">
              <label>Label</label>
              <input
                id="LABEL"
                type="text"
                className="validate form-control"
                value={this.state.label}
                placeholder="Panel label"
                onChange={this.handleLabelChanges}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              value="isVisible"
              onChange={undefined}
            />
            <label className="custom-control-label">
              <span>Is Visible (Boolean)</span>
            </label>
          </div>
          <div className="input-field col-4">
            <select className="form-control" onChange={undefined}>
              <option disabled={true} hidden={true} value="">
                --
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
        </div>
        <div className="row  mt-3">
          <div className="ManagePreviewAdd--select">
            <select
              name="type"
              id=""
              onChange={this.handleSelect}
              value={this.state.propertyKind}
              className="form-control mb-3"
            >
              {property.map((key: any, index) => {
                return (
                  <option key={index} value={key.toString()}>
                    {key.toString()}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="input-field col-4">
            <input
              id="search"
              type="search"
              className="form-control"
              required={true}
              value={this.state.propertyValue}
              onChange={this.handlePropertyStringChange}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @memberof ManagePreviewAdd
   */
  handleSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    // ... define need variables
    let propertyKind: string = this.state.propertyKind;
    let eventValue: string = event.target.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    event.preventDefault();

    propertyKind = eventValue;

    this.setState({ propertyKind });
  };

  /**
   *
   *
   * @returns {CreateHtmlDocumentPanelInput}
   * @memberof HtmlProperty
   */
  extractPanelInput(): CreateHtmlDocumentPanelInput {
    let panelInput: CreateHtmlDocumentPanelInput;

    panelInput = {
      component: this.state.component,
      label: this.state.label
    };

    if (this.state.propertyKind) {
      panelInput.properties = [
        {
          id: this.state.component,
          [this.state.propertyKind]: this.state.propertyValue
        }
      ];
    }

    return panelInput;
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof HtmlProperty
   */
  handlePropertyStringChange(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let propertyValue: string = this.state.propertyValue;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();

    propertyValue = eventValue;

    this.setState({ propertyValue });
  }
}
