/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to add an book story.
 * @filename create-book-story
 */
 import { graphql, GraphQLTaggedNode } from "react-relay";

 /**
  * The GraphQL mutation to add an book.
  *
  * @type {GraphQLTaggedNode}
  */
 export const RESPOND_TO_MARKET_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
   mutation respondToMarketRequestMutation($input: AcceptDeclineClaimRequestInput!) {
    respondToMarketRequest(input: $input) {
       clientMutationId
       hasErrors
       errors {
         id
         location
       }
       userRequest {
         __typename
       }
     }
   }
 `;