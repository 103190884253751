/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-07-01
 * @description Implementation of the GraphQL mutation to add a new url route.
 * @filename delete-suggestion.ts
 */

import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an podcast.
 *
 * @type {GraphQLTaggedNode}
 */
export const DELETE_SUGGESTION_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation deleteSuggestionMutation($input: DeleteSuggestionInput!) {
    deleteSuggestion(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      suggestion {
        id
        revision
      }
    }
  }
`;
