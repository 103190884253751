/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-05
 * @description Implementation of validation utilities to be used in the app.
 * @filename validation.ts
 */

import { ArticlePageFormInput, BookPageFormInput, ComponentPageFromInput, EntityPageFormInput, ListPageFromInput, MediaAssetPageFormInput, OnlineCoursePageFormInput, OriginalContentPageFormInput, PodcastPageFormInput, PosterPageFormInput, TagPageFormInput, UrlPageFormInput } from "../../component-configuration/cms-pages";
import { isValidUrl } from "../general";

/**
 *
 *
 * @class Validator
 */
export class Validator {
  /**
   *
   *
   * @static
   * @param {TagPageFormInput} input
   * @returns
   * @memberof Validator
   */
  static validateTagInput(input: TagPageFormInput) {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    if (
      input.label === null ||
      input.label === undefined ||
      input.label.length < 1
    ) {
      ERROR_MESSAGES.set("LABEL", "Please enter valid label string");
    }

    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {UrlPageFormInput} input
   * @returns {Map<string, string>}
   * @memberof Validator
   */
  static validateUrlRouteInput(input: UrlPageFormInput): Map<string, string> {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    if (
      input.alias === null ||
      input.alias === undefined ||
      input.alias.length < 1
    ) {
      ERROR_MESSAGES.set("ALIAS", "Please enter valid alias string");
    }

    if (
      input.rules !== null &&
      input.rules !== undefined &&
      input.rules.length < 1
    ) {
      ERROR_MESSAGES.set("RULES", "Please enter valid rules array");
    }
    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {ComponentPageFromInput} input
   * @returns {Map<string, string>}
   * @memberof Validator
   */
  static validateMediaAssetInput(
    input: MediaAssetPageFormInput
  ): Map<string, string> {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    if (
      input.tags !== null &&
      input.tags !== undefined &&
      input.tags.length < 1
    ) {
      ERROR_MESSAGES.set("TAGS", "Please enter valid tags array");
    }

    if (
      input.files !== null &&
      input.files !== undefined &&
      input.files.length < 1
    ) {
      ERROR_MESSAGES.set("FILES", "Please enter valid files array");
    }

    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {ComponentPageFromInput} input
   * @returns {Map<string, string>}
   * @memberof Validator
   */
  static validateComponentInput(
    input: ComponentPageFromInput
  ): Map<string, string> {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    if (
      input.name === null ||
      input.name === undefined ||
      input.name.length < 1
    ) {
      ERROR_MESSAGES.set("NAME", "Please enter valid entity name");
    }

    if (
      input.propertyList !== null &&
      input.propertyList !== undefined &&
      input.propertyList.length < 1
    ) {
      ERROR_MESSAGES.set("PROPERTY", "Please enter valid propertyList");
    }

    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {(BookPageState  | ArticlePageState)} storyInput
   * @returns
   * @memberof Validator
   */
  static validateCreateStoryInput(
    storyInput:
      | BookPageFormInput
      | ArticlePageFormInput
      | PodcastPageFormInput
      | OriginalContentPageFormInput
      | OnlineCoursePageFormInput
  ): Map<string, string> {
    const ERROR_MESSAGES: Map<string, string> = new Map();
    const currentTime: number = new Date().getTime();

    // ... validate each form input and push any error to error array

    if (
      "cost" in storyInput &&
      (Number(storyInput.cost) < 0 ||
        storyInput.cost === undefined ||
        storyInput.cost === null)
    ) {
      ERROR_MESSAGES.set("COST", "Please enter valid cost");
    }

    // validate against provided url if its a valid url

    if ("link" in storyInput) {
      if (!isValidUrl(storyInput.link)) {
        ERROR_MESSAGES.set("LINK", "Please enter valid link");
      }
    }

    // validate provided time to read book
    if ("timeToRead" in storyInput && storyInput.timeToRead < 0) {
      ERROR_MESSAGES.set("TIME", "Please enter valid time to read");
    }

    // validate provided time to read book
    if ("durationInSeconds" in storyInput && storyInput.durationInSeconds < 0) {
      ERROR_MESSAGES.set("TIME", "Please enter valid link");
    }

    // validate provided publish date
    if (currentTime < new Date(storyInput.publishedDate).getTime()) {
      ERROR_MESSAGES.set("DATE", "Please enter valid publish date");
    }

    // validate provided book title
    if (
      storyInput.title === null ||
      storyInput.title === undefined ||
      storyInput.title.length < 1
    ) {
      ERROR_MESSAGES.set("TITLE", "Please enter valid title");
    }

    if (
      storyInput.previews !== null &&
      storyInput.previews !== undefined &&
      storyInput.previews.length < 1
    ) {
      ERROR_MESSAGES.set("PREVIEWS", "Please enter provide a valid previews");
    }

    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {*} input
   * @returns {Map<string,string>}
   * @memberof Validator
   */
  static validateCreateEntityInput(
    input: EntityPageFormInput
  ): Map<string, string> {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    // validate provided book title
    if (
      input.name === null ||
      input.name === undefined ||
      input.name.length < 1
    ) {
      ERROR_MESSAGES.set("NAME", "Please enter valid entity name");
    }

    if (
      input.previews !== null &&
      input.previews !== undefined &&
      input.previews.length < 1
    ) {
      ERROR_MESSAGES.set("PREVIEWS", "Please enter valid previews");
    }

    if (
      input.websiteUrl !== undefined &&
      input.websiteUrl !== null &&
      input.websiteUrl.length < 1
    ) {
      if (!isValidUrl(input.websiteUrl)) {
        ERROR_MESSAGES.set("WEBSITE", "Please enter valid author name");
      }
    }

    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {*} input
   * @returns {Map<string,string>}
   * @memberof Validator
   */
  static validateListInput(input: ListPageFromInput): Map<string, string> {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    // if (
    //   input.title === undefined ||
    //   input.title === null ||
    //   input.title.length < 1
    // ) {
    //   ERROR_MESSAGES.set("TITLE", "Please enter valid title");
    // }

    if (
      input.previews !== null &&
      input.previews !== undefined &&
      input.previews.length < 1
    ) {
      ERROR_MESSAGES.set("PREVIEWS", "Please enter valid previews");
    }

    if (
      input.items !== null &&
      input.items !== undefined &&
      input.items.length < 1
    ) {
      ERROR_MESSAGES.set("ITEMS", "Please enter valid items array");
    }

    if (
      input.badges !== null &&
      input.badges !== undefined &&
      input.badges.length < 1
    ) {
      ERROR_MESSAGES.set("BADGES", "Please enter valid badges array");
    }

    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {*} input
   * @returns
   * @memberof Validator
   */
  static validateCreatePersonInput(input: any) {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    if (
      input.fullName === undefined ||
      input.fullName === null ||
      input.fullName.length < 1
    ) {
      ERROR_MESSAGES.set("NAME", "Please enter valid name");
    }

    if (
      input.badges !== null &&
      input.badges !== undefined &&
      input.badges.length < 1
    ) {
      ERROR_MESSAGES.set("BADGES", "Please enter valid badges array");
    }

    if (
      input.entities !== null &&
      input.entities !== undefined &&
      input.entities.length < 1
    ) {
      ERROR_MESSAGES.set("ENTITIES", "Please enter valid entity array");
    }

    if (
      input.activities !== null &&
      input.activities !== undefined &&
      input.activities.length < 1
    ) {
      ERROR_MESSAGES.set("ACTIVITIES", "Please enter valid activity array");
    }

    if (
      input.follows !== null &&
      input.follows !== undefined &&
      input.follows.length < 1
    ) {
      ERROR_MESSAGES.set("FOLLOWS", "Please enter valid follow array");
    }

    if (
      input.previews !== null &&
      input.previews !== undefined &&
      input.previews.length < 1
    ) {
      ERROR_MESSAGES.set("PREVIEWS", "Please enter valid preview array");
    }

    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {*} input
   * @returns
   * @memberof Validator
   */
  static validateHtmlDocumentInput(input: any) {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {*} input
   * @memberof Validator
   */
  static validateQuestionnaireInput(input: any) {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    if (
      input.title === undefined ||
      input.title === null ||
      input.title.length < 1
    ) {
      ERROR_MESSAGES.set("TITLE", "Please enter valid title");
    }

    if (
      input.subTitle !== undefined &&
      input.subTitle !== null &&
      input.subTitle.length <= 0
    ) {
      ERROR_MESSAGES.set("SUBTITLE", "Please enter valid subtitle");
    }

    if (
      input.questions === undefined ||
      input.questions === null ||
      input.questions.length < 1
    ) {
      ERROR_MESSAGES.set("QUESTIONS", "Please enter question array");
    }
    return ERROR_MESSAGES;
  }

  /**
   *
   *
   * @static
   * @param {PosterPageFormInput} input
   * @memberof Validator
   */
  static validatePosterInput(input: PosterPageFormInput): Map<string, string> {
    const ERROR_MESSAGES: Map<string, string> = new Map();

    if (
      input.title === undefined ||
      input.title === null ||
      input.title.length < 1
    ) {
      ERROR_MESSAGES.set("TITLE", "Please enter valid title");
    }

    if (
      input.previews !== null &&
      input.previews !== undefined &&
      input.previews.length < 1
    ) {
      ERROR_MESSAGES.set("PREVIEWS", "Please enter valid previews");
    }

    if (
      input.tags !== null &&
      input.tags !== undefined &&
      input.tags.length < 1
    ) {
      ERROR_MESSAGES.set("TAGS", "Please enter valid tags array");
    }

    return ERROR_MESSAGES;
  }
}
