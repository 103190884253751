/**
 * @author Ahmed Samer
 * @date   2019-11-08
 * @description versioning-page class
 * @filename versioning-page.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import * as React from "react";
import { fetchQuery } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { ImageHelper } from "../../../../utilities/image-helper";
import {
  PreviewKind,
  TopLevelMediaKind,
  GRAPHQL_TYPE_PERSON
} from "@radivision/graphql";
import { FloatingMenu } from "../floating-button/floating-menu";
import { CardProps } from "../version-control/card-configuration";
import { VersionCard } from "../version-control/versioning-card";

/**
 *
 *
 * @interface ItemPageState
 */
interface UserReportPageState {
  /**
   *
   *
   * @type {{ title: string; icon: string; alias: string }[]}
   * @memberof ItemPageState
   */
  items: { record: any; props: CardProps; versions: any[] }[];

  /**
   *
   *
   * @type {React.ReactNode}
   * @memberof UserReportPageState
   */
  pageContent: React.ReactNode;
}

/**
 *
 *
 * @export
 * @class UserReportPage
 * @extends {React.Component<any, ItemPageState>}
 */
class UserReportPage extends React.Component<any, UserReportPageState> {
  /**
   * Creates an instance of UserReportPage.
   * @param {*} props
   * @memberof UserReportPage
   */
  constructor(props: any) {
    super(props);

    //... bind needed function to class
    this.setDefaultSlide = this.setDefaultSlide.bind(this);

  

    this.state = {
      items: [],

      pageContent: (
        <div  className={"fullSize"}>
          {" "}
        </div>
      )
    };
  }

  

  /**
   *
   *
   * @param {[]} items
   * @memberof UserReportPage
   */
  setDefaultSlide() {
    this.setState({
      pageContent: (
        <div>
          <div className="AddItemPage container">
            <h2 className="form-page-title">USERS REPORT</h2>
          </div>
          <div className="AddItemPage container">
            {this.state.items.map((item, index) => {
              return <VersionCard key={index} {...item.props} />;
            })}
          </div>
        </div>
      )
    });
  }
  /**
   *
   *
   * @returns
   * @memberof UserReportPage
   */
  render() {
    return (
      <div className="dark-bg">
        {this.state.pageContent}

        <FloatingMenu
          menuItems={{
            backAction: this.setDefaultSlide
          }}
        />
      </div>
    );
  }
}

export default UserReportPage;
