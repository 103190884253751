/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type AddRemoveUserToGroupInput = {
    readonly clientMutationId: string;
    readonly groupName: string;
    readonly userName: string;
};
export type addUserToCognitoGroupMutationVariables = {
    readonly input: AddRemoveUserToGroupInput;
};
export type addUserToCognitoGroupMutationResponse = {
    readonly addUserToCognitoGroup: {
        readonly clientMutationId: string | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly hasErrors: boolean | null;
    } | null;
};
export type addUserToCognitoGroupMutation = {
    readonly response: addUserToCognitoGroupMutationResponse;
    readonly variables: addUserToCognitoGroupMutationVariables;
};



/*
mutation addUserToCognitoGroupMutation(
  $input: AddRemoveUserToGroupInput!
) {
  addUserToCognitoGroup(input: $input) {
    clientMutationId
    errors {
      id
      location
    }
    hasErrors
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddRemoveUserToGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addUserToCognitoGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddRemoveGroupUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "addUserToCognitoGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "addUserToCognitoGroupMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "addUserToCognitoGroupMutation",
    "id": null,
    "text": "mutation addUserToCognitoGroupMutation(\n  $input: AddRemoveUserToGroupInput!\n) {\n  addUserToCognitoGroup(input: $input) {\n    clientMutationId\n    errors {\n      id\n      location\n    }\n    hasErrors\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3580281981d75026a933e2812382a591';
export default node;
