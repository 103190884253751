/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-05-24
 * @description Implementation of the GraphQL mutation to add a tag.
 * @filename update-tag.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an podcast.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_TAG_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateTagMutation($input: UpdateTagInput!) {
    updateTag(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      tag {
        __typename
        id
        description
        label
        isDeleted
        isGraphQlType
        revision
      }
    }
  }
`;
