/**
 * @author Ahmed Samer
 * @date   2020-02-11
 * @description page to list user pool group
 * @filename ga-panel.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import React, { Component } from "react";
import { ReportChart } from "./report-chart";
import { FloatingMenu } from "../floating-button/floating-menu";
import { GAPanel } from "./ga-panel";
import { Grid } from "@material-ui/core";
import { Typography } from "./typography";
import Widget from "./widget/widget";
import { fetchQuery } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { DASHBOARD_REPORT_QUERY } from "../../../../relay/queries/dashboard-report-query";

/**
 *
 *
 * @class Dashboard
 * @extends {Component<{}, { options: any; series: any }>}
 */

class Dashboard extends Component<
  {},
  { options: any; series: any; GA?: any[] }
> {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            },
            autoSelected: "zoom"
          }
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "March",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sep",
            "Nov",
            "Dec"
          ]
        }
      },
      series: [
        {
          name: "Person Report",
          data: [30, 40, 45, 50, 49, 60, 70, 91, 20, 10, 50, 12]
        }
      ]
    };
  }

  /**
   *
   *
   * @memberof Dashboard
   */
  componentDidMount() {
    let promise: Promise<any> = fetchQuery(
      ENVIRONMENT,
      DASHBOARD_REPORT_QUERY,
      {}
    );

    // attempt to redirect user to twitter to sign in.
    promise = promise.then((response: any) => {
      //  // console.log("[authenticateTwitterUser] response : ", response)
      if (!response.dashboardReport.hasErrors) {
        const GA: any[] = [];

        GA[0] = [
          ...response.dashboardReport.node.googleAnalytics[0].value.map(
            (item: string) => {
              return {
                value: item.split(",")
              };
            }
          )
        ];
        GA[1] = {
          values: [
            ...response.dashboardReport.node.googleAnalytics[1].value.map(
              (item: string) => {
                return {
                  ...item.split(",")
                };
              }
            )
          ]
        };
        GA[1].desktop = 0;
        GA[1].mobiles = 0;
        GA[1].values
          .filter((item: any) => {
            return item[0] === "desktop";
          })
          .forEach(element => {
            GA[1].desktop = GA[1].desktop + Number(element[2]);
          });


        GA[1].values
          .filter((item: any) => {
            return item[0] === "mobile";
          })
          .forEach(element => {
            GA[1].mobiles = GA[1].mobiles + Number(element[2]);
          });

        this.setState({
          GA
        });
      }
    });

    promise = promise.catch(err => {
      console.error(err);
    });

    return promise;
  }

  /**
   *
   *
   * @returns
   * @memberof Dashboard
   */
  render() {
    return (
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="title-container">
            <h1 className="display-5">Dashboard</h1>
          </div>
          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Widget
                title="Visits Today"
                upperTitle
                bodyClass={"fullHeightBody"}
                className="dashboard-card"
              >
                <div className={"visitsNumberContainer"}>
                  <Typography size="xl" weight="medium">
                    12, 678
                  </Typography>
                </div>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography color="text" colorBrightness="secondary">
                      Registrations
                    </Typography>
                    <Typography size="md">860</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="text" colorBrightness="secondary">
                      Sign Out
                    </Typography>
                    <Typography size="md">32</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="text" colorBrightness="secondary">
                      Rate
                    </Typography>
                    <Typography size="md">3.25%</Typography>
                  </Grid>
                </Grid>
              </Widget>
            </Grid>

            <Grid item lg={3} md={8} sm={6} xs={12}>
              <Widget
                title="App Performance"
                upperTitle
                className={"dashboard-card"}
                bodyClass={"fullHeightBody"}
              >
                <div className={"performanceLegendWrapper"}>
                  <div className={"legendElement"}>
                    <Typography
                      color="text"
                      colorBrightness="secondary"
                      className={"legendElementText"}
                    >
                      Integration
                    </Typography>
                  </div>
                  <div className={"legendElement"}>
                    <Typography
                      color="text"
                      colorBrightness="secondary"
                      className={"legendElementText"}
                    >
                      SDK
                    </Typography>
                  </div>
                </div>
                <div className={"progressSection"}>
                  <Typography
                    size="md"
                    color="text"
                    colorBrightness="secondary"
                    className={"progressSectionTitle"}
                  >
                    Integration
                  </Typography>
                </div>
                <div>
                  <Typography
                    size="md"
                    color="text"
                    colorBrightness="secondary"
                    className={"progressSectionTitle"}
                  >
                    SDK
                  </Typography>
                </div>
              </Widget>
            </Grid>

            <Grid item xs={12}>
              <GAPanel
                pages={this.state.GA ? this.state.GA[0] : undefined}
                devices={this.state.GA ? this.state.GA[1] : undefined}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportChart
                options={this.state.options}
                series={this.state.series}
                type="line"
              />
            </Grid>
          </Grid>
          <FloatingMenu menuItems={{ initialPage: { removeAction: null } }} />
        </div>
      </div>
    );
  }
}

export default Dashboard;
