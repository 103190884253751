/**
 * @author Linkon Islam
 * @date   2019-01-14
 * @description Manage html page component
 * @filename ManageHTML.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import {
  HtmlDocument as graphHtmlDocument,
  GRAPHQL_TYPE_ACTIVITY,
  GRAPHQL_TYPE_HTML_DOCUMENT,
  CreateHtmlDocumentPanelInput
} from "@radivision/graphql";
import { HtmlProperty } from "./html-property";
import { FloatingMenu } from "../floating-button/floating-menu";

import ModalConductor from "../modal/modal-conductor";
import { Validator } from "../../../../utilities/cms-operation/validator";
import { HtmlDocument } from "../../../../utilities/cms-operation/html-document";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  HtmlDocumentPageFormInput,
  FormGroupInput
} from "../../../../component-configuration/cms-pages";
import { getNewUniqueId } from "@radivision/common";
import { Loader } from "../../../page/loader";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";

export interface HtmlDocumentPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof posterPageProps
   */
  string?: string;

  /**
   *
   *
   * @type {string}
   * @memberof posterPageProps
   */
  graphHtmlDocument?: graphHtmlDocument;
}

/**
 *
 *
 * @interface ManageHtmlDocumentPageState
 */
export interface HtmlDocumentPageState {
  /**
   *
   *
   * @type {HtmlDocumentPageFormInput}
   * @memberof ManageHtmlDocumentPageState
   */
  formInput: HtmlDocumentPageFormInput;

  /**
   *
   *
   * @type {FormGroupInput}
   * @memberof ManageHtmlDocumentPageState
   */
  formGroupInput: FormGroupInput;

  /**
   *
   *
   * @type {React.ReactNode[]}
   * @memberof ManageHtmlDocumentPageState
   */
  htmlPanels: any[];

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof ManageHTMLDocumentPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof ManageHTMLDocumentPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {boolean}
   * @memberof ManageHtmlDocumentPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class ManageHTML
 * @extends {React.Component<HtmlDocumentPageProps, ManageHtmlDocumentPageState>}
 */
export class HtmlDocumentPage extends React.Component<
  HtmlDocumentPageProps,
  HtmlDocumentPageState
> {
  /**
   *
   *
   * @type {*}
   * @memberof QuestionPanel
   */
  propertyNodes: Map<string, HtmlProperty>;

  /**
   * Creates an instance of ManageHTML.
   * @param {HtmlDocumentPageProps} props
   * @memberof ManageHTML
   */
  constructor(props: HtmlDocumentPageProps) {
    super(props);

    //... bind needed function to class

    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    this.propertyNodes = new Map();

    //... initial component state
    this.state = this.handleComponentStateInitialize(
      this.props.graphHtmlDocument
    );
  }

  /**
   *
   *
   * @returns {HtmlDocumentPageState}
   * @memberof ManageHTML
   */
  handleComponentStateInitialize(
    graphHtmlPage: graphHtmlDocument
  ): HtmlDocumentPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: HtmlDocumentPageFormInput = {
      id: undefined,
      label: "",
      description: "",
      isDeleted: false,
      panels: []
    };

    let state: HtmlDocumentPageState = {
      formInput,
      formGroupInput: {},
      modal,
      htmlPanels: [],
      errorMessage: new Map(),
      isLoading: false
    };

    if (graphHtmlPage !== null && graphHtmlPage !== undefined) {
      // ..
      if (graphHtmlPage.id) {
        state.formInput.id = graphHtmlPage.id;
      }

      if (graphHtmlPage.label) {
        state.formInput.label = graphHtmlPage.label;
      }

      if (graphHtmlPage.description) {
        state.formInput.description = graphHtmlPage.description;
      }

      if (graphHtmlPage.isDeleted) {
        state.formInput.isDeleted = graphHtmlPage.isDeleted;
      }

      if (graphHtmlPage.revision) {
        state.formInput.revision = graphHtmlPage.revision;
      }

      if (graphHtmlPage.panels) {
        graphHtmlPage.panels.map(panel => {
          let nodeId = getNewUniqueId();

          const properties: React.ReactNode = (
            <HtmlProperty
              ref={node => this.propertyNodes.set(nodeId, node)}
              input={panel}
              key={nodeId}
              propertyId={nodeId}
              removeClick={this.handleRemoveProperty}
            />
          );

          state.htmlPanels.push({ id: nodeId, content: properties });
        });
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof ManageHTML
   */
  handleChange = (e: any) => {
    // console.log(e.target.value);
  };

  /**
   *
   *
   * @memberof ManageHTML
   */
  addMoreProperty = () => {
    let htmlPanels = this.state.htmlPanels;
    let nodeId = getNewUniqueId();

    // console.log("added!");
    const properties: React.ReactNode = (
      <HtmlProperty
        ref={node => this.propertyNodes.set(nodeId, node)}
        key={nodeId}
        propertyId={nodeId}
        removeClick={this.handleRemoveProperty}
      />
    );

    htmlPanels.push({ id: nodeId, content: properties });

    this.setState({
      htmlPanels
    });
  };

  /**
   *
   *
   * @memberof ManageHTML
   */
  handleRemoveProperty = (id: any) => {
    // console.log(" remove call for property id : ", id);

    let htmlPanels = this.state.htmlPanels;

    let filteredSlots = htmlPanels.filter((slot, index) => {
      return slot.id !== id;
    });

    this.propertyNodes.delete(id);

    this.setState({
      htmlPanels: filteredSlots
    });
  };

  /**
   *
   *
   * @returns
   * @memberof ManageHTML
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Manage HTML</h2>
            <div className="row">
              <div className="input-field col m8">
                <label>
                  Label<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="html page label"
                  id="name"
                  type="text"
                  className="validate form-control"
                  value={this.state.formInput.label}
                  onChange={this.handleLabelChanges}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-field col m4">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="input-field col m4">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="container">
              <div className="custom-control custom-checkbox mt-3">
                <input
                  type="checkbox"
                  name="new"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">
                  <span>Is Deleted</span>
                </label>
              </div>
            </div>
            <div>
              {this.state.htmlPanels.length ? (
                this.state.htmlPanels.map((property, index) => {
                  return <div key={index}>{property.content}</div>;
                })
              ) : (
                <p style={{ display: "none" }}>nothing to show</p>
              )}
              <a className="btn add-btn" onClick={this.addMoreProperty}>
                <FontAwesomeIcon icon="plus-circle" /> add
              </a>
            </div>
          </div>
          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
        </div>
        <Loader isActive={this.state.isLoading} />
      </div>
    );
  }

  /**
   *
   *
   * @memberof ManageHTML
   */
  revertPageInput(input?: graphHtmlDocument) {
    let state: HtmlDocumentPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null
        ? input
        : this.props.graphHtmlDocument
    );
    this.setState(state);
    this.propertyNodes.clear();
  }

  /**
   * Function Responsible for handling submitting new tag
   *
   * @memberof posterPage
   */
  handleSaveAction(): void {
    let htmlDocumentInput: HtmlDocumentPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();
    let panelsInput: CreateHtmlDocumentPanelInput[] = [];

    promise = promise.then(() => {
      this.toggleLoader();

      for (const propertyRef of this.propertyNodes.values()) {
        if (propertyRef) {
          panelsInput.push(propertyRef.extractPanelInput());
        }
      }

      htmlDocumentInput.formInput.panels = panelsInput.length
        ? panelsInput
        : undefined;

      // validate errors
      errorMessage = Validator.validateHtmlDocumentInput(
        htmlDocumentInput.formInput
      );

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (this.props.string !== null && this.props.string !== undefined) {
          internalPromise = HtmlDocument.updateHtmlDocument(
            htmlDocumentInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["HTML_DOCUMENT"].message.confirmation.update;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = HtmlDocument.addHtmlDocument(
            htmlDocumentInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["HTML_DOCUMENT"].message.confirmation.add;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["HTML_DOCUMENT"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof posterPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }

  /**
   *
   *
   * @memberof ManageHTML
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   * Function Responsible for handling changes in article is deleted state
   *
   * @memberof ManageHTML
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: HtmlDocumentPageFormInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    // // console.log("[handleIsDeletedChanges] is article deleted", isDeleted);
    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof ManageHTML
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: HtmlDocumentPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof ManageHTML
   */
  handleDescriptionChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: HtmlDocumentPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.description = eventValue;
    this.setState({ formInput });
  }
}

/**
 *
 *
 * @export
 * @class HtmlDocumentPageContainer
 * @extends {React.Component<HtmlDocumentPageProps, HtmlDocumentPageState>}
 */
export class HtmlDocumentPageContainer extends React.Component<
  HtmlDocumentPageProps,
  HtmlDocumentPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let htmlPageId: string;

    // console.log("[HtmlDocumentPageContainer] provided props", this.props);

    if (this.props.string === null || this.props.string === undefined) {
      node = <HtmlDocumentPage />;
    } else {
      htmlPageId = this.props.string;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query htmlDocumentPageQuery($id: ID!) {
              htmlDocument(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                node {
                  __typename
                  id
                  revision
                  label
                  description
                  panels {
                    id
                    label
                    component {
                      id
                    }
                    properties {
                      name
                      article {
                        id
                      }
                      book {
                        id
                      }
                      boolean
                      dynamicList {
                        id
                      }
                      entity {
                        id
                      }
                      fixedList {
                        id
                      }
                      id
                      int
                      json
                      listCollection {
                        id
                      }
                      name
                      newsletter {
                        id
                      }
                      number
                      onlineCourse {
                        id
                      }
                      originalContent {
                        id
                      }
                      person {
                        id
                      }
                      podcast {
                        id
                      }
                      poster {
                        id
                      }
                      string
                    }
                  }
                }
              }
            }
          `}
          variables={{
            id: htmlPageId
          }}
          render={({ error, props }) => {
            if (error) {
              // console.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console.log(props);
              if (props.htmlDocument.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.htmlDocument.node.__typename !==
                GRAPHQL_TYPE_HTML_DOCUMENT
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Html-document id, {htmlPageId} belongs to type{" "}
                      {props.htmlDocument.node.__typename}, Need more help?
                      contact Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.htmlDocument.node) {
                let pageProps: HtmlDocumentPageProps = {
                  ...this.props,
                  graphHtmlDocument: props.htmlDocument.node
                };
                return <HtmlDocumentPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
