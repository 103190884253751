/**
 * @author Ahmed Samer
 * @date   2022-04-18
 * @description
 * @filename
 * @copyright Copyright 2022 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import Flippy, { FrontSide, BackSide } from "react-flippy";
import * as React from "react";
import {
  UserRequest,
  PreviewKind,
  TopLevelMediaKind,
  UserRequestStatusKind,
  PitchVideoStory,
  PitchRequest,
} from "@radivision/graphql";
import { FloatingMenu } from "./floating-button/floating-menu";
import ModalConductor from "./modal/modal-conductor";
import { Loader } from "../../page/loader";
import { QueryRenderer, graphql, GraphQLTaggedNode } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import { ImageHelper } from "../../../utilities/image-helper";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import sortArray from "sort-array";
import { getCleanUuid, getClientMutationId } from "../../../utilities/general";
import { commitMutation } from "../../../utilities/relay";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../../relay/relay-environment";
import SwitchListSecondary from "./pitch-user-request-setting";
import { RESPOND_TO_PITCH_GRAPHQL_MUTATION } from "../../../relay/mutations/respond-to-pitch-request";
import { RESPOND_TO_MARKET_GRAPHQL_MUTATION } from "../../../relay/mutations/respond-to-market-request";

/**
 *
 * @export
 * @interface UserRequestPageProps
 */
export interface UserRequestPageProps {
  /**
   *
   *
   * @type {GraphQlPerson}
   * @memberof UserRequestPageProps
   */
  request?: UserRequest[];
}

/**
 *
 *
 * @interface UserRequestPageState
 */
export interface UserRequestPageState {
  /**
   *
   *
   * @type {string[]}
   * @memberof UserRequestPageState
   */
  itemImages: string[];

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof OriginalContentPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof OriginalContentPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {boolean}
   * @memberof UserRequestPageState
   */
  isLoading: boolean;

  /**
   *
   *
   * @type {UserRequest[]}
   * @memberof UserRequestPageState
   */
  requestView?: UserRequest[];
}

/**
 *
 *
 * @export
 * @class UserRequestPage
 * @extends {React.Component<UserRequestPageProps, UserRequestPageState>}
 */
export class MarketplacePage extends React.Component<
  UserRequestPageProps,
  UserRequestPageState
> {
  /**
   *Creates an instance of UserRequestPage.
   * @param {UserRequestPageProps} props
   * @memberof UserRequestPage
   */
  constructor(props: UserRequestPageProps) {
    super(props);

    // ... bind needed function to class

    this.resetModalState = this.resetModalState.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    // ... initialize required components references

    // ... initial component state
    this.state = this.handleComponentStateInitialize(this.props.request);
  }

  /**
   *
   *
   * @param {GraphQlPerson} personProps
   * @returns {UserRequestPageState}
   * @memberof UserRequestPage
   */
  handleComponentStateInitialize(
    personProps: UserRequest[]
  ): UserRequestPageState {
    // ... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState,
    };

    let itemImages: string[] = [];
    const requestView: UserRequest[] = [];
    if (personProps && personProps.length > 0) {
      personProps.forEach((request: UserRequest | any) => {
        const ITEM: PitchVideoStory = request;

        if (ITEM && ITEM.previews) {
          let img: string = "";
          const AVAILABLE_RES: OptimizedImageUrls = ImageHelper.fetchOptimizedImageUrl(
            {
              imageType: "PREVIEW",
              preview: {
                content: ITEM.previews,
                previewKind: [
                  PreviewKind.LOGO,
                  PreviewKind.HEADSHOT,
                  PreviewKind.SQUARE,
                  PreviewKind.CAROUSEL,
                ],
                topLevelMedia: TopLevelMediaKind.IMAGE,
              },
              desiredDimensions: {
                containerWidthRatio: 0.5,
                numberOfItems: 4,
              },
              revision: undefined,
            }
          );
          if (AVAILABLE_RES) {
            img = AVAILABLE_RES.requestedResolutionUrl;
          }
          itemImages.push(img);
        }
        requestView.push(request);
      });
    }

    let state: UserRequestPageState = {
      modal,
      itemImages,
      requestView,

      errorMessage: new Map(),
      isLoading: false,
    };

    return state;
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @memberof UserRequestPage
   */
  filterRequest = (targetFilter: string): void => {
    let requestView: UserRequest[] = this.state.requestView;
    const TODAY = new Date().getTime();
    switch (targetFilter) {
      case "SORT":
        requestView = sortArray([...this.props.request], {
          by: "expired",
          order: "desc",
        });
        break;
      case "EXPIRED":
        requestView = this.props.request.filter((request) => {
          return TODAY > new Date(request.expired).getTime();
        });
        break;
      case "ACCEPTED/DECLINED":
        requestView = this.props.request.filter((request) => {
          return (
            request.status === UserRequestStatusKind.APPROVED ||
            request.status === UserRequestStatusKind.DECLINED
          );
        });
        break;
      default:
        break;
    }

    this.setState({ requestView, isLoading: false });
  };

  /**
   *
   *
   * @returns
   * @memberof UserRequestPage
   */
  render() {
    const TODAY: number = new Date().getTime();

    return (
      <div className="dark-bg">
        <div className="container p-1">
          <SwitchListSecondary
            listItem={[
              { name: "SORT BY DATE", action: this.filterRequest },
              {
                name: "FILTER ACCEPTED",
                action: this.filterRequest,
              },
              {
                name: "FILTER DECLINED",
                action: this.filterRequest,
              },
              { name: "FILTER EXPIRED", action: this.filterRequest },
            ]}
          />
          <div className="user-form">
            <h2 className="form-page-title text-center">
              Marketplace Requests ({this.state.requestView.length})
            </h2>

            {this.props.request && this.props.request.length ? (
              <div
                className="row six-by-one-grid align-items-center text-center"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {this.state.requestView.map(
                  (
                    request: UserRequest & PitchRequest & any,
                    index: number
                  ) => {
                    return (
                      <Flippy
                        key={getCleanUuid()}
                        flipOnHover={true} // default false
                        flipOnClick={false} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        // ref={r => (this.flippy = r)} // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{
                          width: "350px",
                          height: "450px",
                          margin: "10px",
                        }} /// these are optional style, it is not necessary
                      >
                        <FrontSide
                          style={{
                            backgroundColor: "#eef3ff",
                          }}
                        >
                          <h5>{`${request.kind || "Market Entry"}`}</h5>
                          <hr />
                          <div className="row align">
                            <div className="col-center-block">
                              <div className="img-container d-inline-block">
                                <img
                                  className="claimer-image"
                                  src={this.state.itemImages[index]}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <p>
                            {/* <span> created : {request.created}</span> */}
                            <label>
                              <img src="https://img.icons8.com/wired/64/000000/expired.png" />
                            </label>
                            <span
                              className={`${
                                TODAY < new Date(request.expires).getTime()
                                  ? ""
                                  : "text-danger font-weight-bold"
                              }`}
                            >
                              {request.expires
                                ? new Date(request.expires).toDateString()
                                : "∞"}
                            </span>
                            <p>
                              {request?.title?.substr(0, 100) ||
                                request?.description?.substr(0, 100) ||
                                `No description`}
                            </p>
                          </p>
                        </FrontSide>
                        <BackSide style={{ backgroundColor: "#5890ff" }}>
                          <div className="row align col-center-block">
                            <img src="https://tijaratuna.com/wp-content/uploads/2020/11/%D8%B4%D8%B1%D8%AD-%D9%85%D8%A7%D8%B1%D9%83%D8%AA-%D8%A8%D9%84%D8%B3-Marketplace-630x300.png" />
                          </div>
                          <div className="row align">
                            <div className="col-md-4 col-center-block">
                              <a
                                href=""
                                className="rad-icon"
                                onClick={(e) => {
                                  e.preventDefault();

                                  if (
                                    request.peopleEntities &&
                                    request.peopleEntities.length
                                  ) {
                                    window.open(
                                      request.peopleEntities[0]?.person?.landingPageUrl.replace(
                                        "https://cms.",
                                        "https://"
                                      )
                                    );
                                  }
                                }}
                              >
                                <img
                                  alt="claimer profile"
                                  src="https://img.icons8.com/dotty/80/000000/edit-user-female.png"
                                />
                              </a>
                            </div>
                            <div className="col-md-4 col-center-block">
                              <a
                                href=""
                                className="rad-icon"
                                onClick={(e) => {
                                  e.preventDefault();

                                  if (
                                    request.peopleEntities &&
                                    request.peopleEntities.length
                                  ) {
                                    window.open(
                                      request.peopleEntities[0]?.entity?.landingPageUrl.replace(
                                        "https://cms.",
                                        "https://"
                                      )
                                    );
                                  }
                                }}
                              >
                                <img
                                  alt="profile"
                                  src="https://img.icons8.com/dotty/36/000000/landlord.png"
                                />
                              </a>
                            </div>
                            <div className="col-md-4 col-center-block">
                              <a
                                className="rad-icon"
                                onClick={(e) => {
                                  e.preventDefault();

                                  window.location.assign(
                                    `/cms-market-entry-page/${request?.id}`
                                  );
                                }}
                                
                              >
                                <img
                                  alt="Docs"
                                  src="https://react.semantic-ui.com/logo.png"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="row align">
                            <div className="col-md-6 col-center-block">
                              <button
                                type="button"
                                className="btn btn-radivision accept"
                                onClick={() => {
                                  this.handleSaveAction("ACCEPT", request.id);
                                }}
                              >
                                Accept
                              </button>
                            </div>
                            <div className="col-md-6 col-center-block">
                              <button
                                type="button"
                                className="btn btn-radivision dark"
                                onClick={() => {
                                  this.handleSaveAction("DECLINE", request.id);
                                }}
                              >
                                Decline
                              </button>
                            </div>
                          </div>
                        </BackSide>
                      </Flippy>
                    );

                    // <li>{JSON.stringify(request, null, 4)}</li>;
                  }
                )}
              </div>
            ) : null}
          </div>

          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction,
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              saveable: this.handleSaveAction,
            }}
          />
        </div>
        <Loader isActive={this.state.isLoading} />
      </div>
    );
  }

  /**
   *
   *
   * @memberof PodcastPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof UserRequestPage
   */
  handleSaveAction(response: "ACCEPT" | "DECLINE", id: string): Promise<any> {
    let promise: Promise<any> = Promise.resolve();
    let modal = this.state.modal;

    // initialize mutation configuration
    const config: { mutation: GraphQLTaggedNode; variables: {} } = {
      mutation: RESPOND_TO_MARKET_GRAPHQL_MUTATION,
      variables: {
        input: { response, id, clientMutationId: getClientMutationId() },
      },
    };

    this.toggleLoader(true);

    promise = commitMutation(RELAY_ENVIRONMENT, config);
    //
    promise = promise.then((mutationResults: any) => {
      return mutationResults.respondToMarketRequest.hasErrors === false
        ? Promise.resolve(mutationResults.respondToMarketRequest.userRequest)
        : Promise.reject(mutationResults.respondToMarketRequest.errors);
    });

    promise = promise.then((res) => {
      // ... handle mutation success
      // console.log(results);
      modal.modalName = "ConfirmModal";
      modal.modalBody = "Request has been updated successfully";
      modal.modalTile = "Action Complete";
    });

    promise = promise.catch((err) => {
      // ... handle mutation success
      // console.log(results);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `${JSON.stringify(err)}`;
      modal.modalTile = "Action failed";
    });

    promise = promise.finally(() => {
      this.setState({
        modal,
        isLoading: false,
      });
    });

    return promise;
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof UserRequestPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class MarketplaceContainer extends React.Component<
  UserRequestPageProps,
  UserRequestPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let personId: string;

    node = (
      <QueryRenderer
        environment={ENVIRONMENT}
        query={graphql`
          query marketplacePageQuery(
            $cursor: String
            $facets: [SearchFacetInput!]
            $filterQuery: String
            $order: String
          ) {
            marketplace(
              cursor: $cursor
              facets: $facets
              filterQuery: $filterQuery
              order: $order
            ) {
              hasErrors
              errors {
                id
                location
              }
              facets {
                buckets {
                  count
                  value
                }
                name
              }
              marketplace {
                featured {
                  id
                }
                limited {
                  id
                }
                market {
                  id
                  title
                  link
                  description
                  state
                  offerType
                  promoCode
                  expires
                  peopleEntities {
                    person {
                      landingPageUrl
                    }
                    entity {
                      landingPageUrl
                    }
                  }
                  previews {
                    edges {
                      preview {
                        link
                        kind
                        mediaAsset {
                          files {
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        variables={{
          filterQuery:"(and responsibility:'CREATED')"
        }}
        render={({ error, props }) => {
          if (error) {
            // console\.log(error);
            return <div>{error.message}</div>;
          } else if (props) {
            // console\.log(props);
            if (props.marketplace.hasErrors) {
              return (
                <div>
                  <p>unexpected error contact Radivision Technical team.</p>{" "}
                </div>
              );
            } else if (props.marketplace.marketplace) {
              const request = props.marketplace?.marketplace?.market.filter(
                (item) => {
                  return item.state === "CREATED";
                }
              );
              let pageProps: UserRequestPageProps = {
                ...this.props,
                request,
              };
              return <MarketplacePage {...pageProps} />;
            }
          }
          return <Loader isActive={true} />;
        }}
      />
    );

    return node;
  }
}
