/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-17
 * @description Implementation of html page utilities to be used in the app.
 * @filename html-document.ts
 */

import { CreateHtmlDocumentInput, CreateHtmlDocumentPanelInput, UpdateHtmlDocumentInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { HtmlDocumentPageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_HTML_DOCUMENT_GRAPHQL_MUTATION } from "../../relay/mutations/create-html-document";
import { UPDATE_HTML_DOCUMENT_GRAPHQL_MUTATION } from "../../relay/mutations/update-html-document";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

export class HtmlDocument {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ManageHtmlState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addHtmlDocument(
    htmlPageInput: HtmlDocumentPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePersonInput
    let createHtmlPageInput: CreateHtmlDocumentInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let panelInput: CreateHtmlDocumentPanelInput[];
    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[addHtmlDocument] provided input ", JSON.stringify(htmlPageInput));

    if (
      htmlPageInput.panels !== undefined &&
      htmlPageInput.panels !== null &&
      htmlPageInput.panels.length > 0
    ) {
      panelInput = [];

      htmlPageInput.panels.map(item => {
        if (item !== undefined && item !== null) {
          panelInput.push(item);
        }
      });
    }

    if (
      htmlPageInput.label === undefined ||
      htmlPageInput.label === null ||
      htmlPageInput.label.length < 1
    ) {
      promise = Promise.reject(new Error("XMHN6ycIFEulUG5U5jB2RQ"));
    } else {
      // initialize create person graphQl input
      createHtmlPageInput = {
        clientMutationId: getClientMutationId(),
        label:
          htmlPageInput.label !== undefined &&
          htmlPageInput.label !== null &&
          htmlPageInput.label.length > 0
            ? htmlPageInput.label.trim()
            : undefined,
        panels:
          htmlPageInput.panels !== undefined &&
          htmlPageInput.panels !== null &&
          htmlPageInput.panels.length > 0
            ? htmlPageInput.panels
            : undefined,
        tags:
          htmlPageInput.tags !== undefined &&
          htmlPageInput.tags !== null &&
          htmlPageInput.tags.length > 0
            ? htmlPageInput.tags
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_HTML_DOCUMENT_GRAPHQL_MUTATION,
        variables: {
          input: createHtmlPageInput
        }
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createHtmlDocument.hasErrors === false
          ? Promise.resolve(mutationResults.createHtmlDocument.htmlDocument)
          : Promise.reject(mutationResults.createHtmlDocument.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof HtmlPage
   */
  static updateHtmlDocument(
    htmlPageInput: HtmlDocumentPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePersonInput
    let updateHtmlPageInput: UpdateHtmlDocumentInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[updateHtmlDocument] provided input ", JSON.stringify(htmlPageInput));

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      htmlPageInput.id === undefined ||
      htmlPageInput.id === null ||
      htmlPageInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("CtpngXQ4B0qLZYts3hJ1PQ"));
    } else {
      updateHtmlPageInput = {
        clientMutationId: getClientMutationId(),
        revision: htmlPageInput.revision,
        id: htmlPageInput.id,
        description:
          htmlPageInput.description !== undefined &&
          htmlPageInput.description !== null &&
          htmlPageInput.description.length > 0
            ? htmlPageInput.description.trim()
            : undefined,
        label:
          htmlPageInput.label !== undefined &&
          htmlPageInput.label !== null &&
          htmlPageInput.label.length > 0
            ? htmlPageInput.label.trim()
            : undefined,
        panels:
          htmlPageInput.panels !== undefined &&
          htmlPageInput.panels !== null &&
          htmlPageInput.panels.length > 0
            ? htmlPageInput.panels
            : undefined,
        tags:
          htmlPageInput.tags !== undefined &&
          htmlPageInput.tags !== null &&
          htmlPageInput.tags.length > 0
            ? htmlPageInput.tags
            : undefined
      };

      // console.log("[updateHtmlDocument] mutation input ", JSON.stringify(updateHtmlPageInput));

      // initialize mutation configuration
      config = {
        mutation: UPDATE_HTML_DOCUMENT_GRAPHQL_MUTATION,
        variables: {
          input: updateHtmlPageInput
        }
      };

      //
      promise = commitMutation(environment, config);

      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateHtmlDocument.hasErrors === false
          ? Promise.resolve(mutationResults.updateHtmlDocument.HtmlDocument)
          : Promise.reject(mutationResults.updateHtmlDocument.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof HtmlPage
   */
  static deleteHtmlPage(): Promise<any> {
    return undefined;
  }
}
