/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-13
 * @description Implementation of component utilities to be used in the app.
 * @filename component.ts
 */

import { CreateComponentInput, UpdateComponentInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { ComponentPageFromInput } from "../../component-configuration/cms-pages";
import { CREATE_COMPONENT_GRAPHQL_MUTATION } from "../../relay/mutations/create-component";
import { UPDATE_COMPONENT_GRAPHQL_MUTATION } from "../../relay/mutations/update-component";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class Component
 */
export class Component {
  /**
   * Returns a promise to add new book to database.
   *
   * @param {AddToPersonInput} componentInput The update to add to the person.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add book mutation.
   */
  static addComponent(
    componentInput: ComponentPageFromInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert Book input to  CreateBookStoryInput
    let createBookStoryInput: CreateComponentInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      componentInput.name === undefined ||
      componentInput.name === null ||
      componentInput.name.length < 1
    ) {
      promise = Promise.reject(new Error("w7OnWZyfWEWd8OJzJKu97Q"));
    } else {
      // initialize create book graphQl input
      createBookStoryInput = {
        clientMutationId: getClientMutationId(),
        description:
          componentInput.description !== undefined &&
          componentInput.description !== null &&
          componentInput.description.length > 0
            ? componentInput.description.trim()
            : undefined,
        key:
          componentInput.name !== undefined &&
          componentInput.name !== null &&
          componentInput.name.length > 0
            ? componentInput.name.trim()
            : undefined,
        properties: undefined,
        label:
          componentInput.label !== undefined &&
          componentInput.label !== null &&
          componentInput.label.length > 0
            ? componentInput.label.trim()
            : undefined,
        tags: componentInput.tags.length > 0 ? componentInput.tags : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_COMPONENT_GRAPHQL_MUTATION,
        variables: {
          input: createBookStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.createComponent.hasErrors === false
          ? Promise.resolve(mutationResults.createComponent.component)
          : Promise.reject(mutationResults.createComponent.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Book
   */
  static updateComponent(
    componentInput: ComponentPageFromInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let updateBookStoryInput: UpdateComponentInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      componentInput.id === undefined ||
      componentInput.id === null ||
      componentInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("Zi2o6TA3cUG8lQeDfN7VkQ"));
    } else {
      // initialize create book graphQl input
      updateBookStoryInput = {
        id: componentInput.id,
        revision: componentInput.revision,
        clientMutationId: getClientMutationId(),
        label:
          componentInput.label !== undefined &&
          componentInput.label !== null &&
          componentInput.label.length > 0
            ? componentInput.label.trim()
            : undefined,
        description:
          componentInput.description !== undefined &&
          componentInput.description !== null &&
          componentInput.description.length > 0
            ? componentInput.description.trim()
            : undefined,
        key:
          componentInput.name !== undefined &&
          componentInput.name !== null &&
          componentInput.name.length > 0
            ? componentInput.name.trim()
            : undefined,
        tags: componentInput.tags.length > 0 ? componentInput.tags : undefined,
        properties: undefined
      };

      // initialize mutation configuration
      config = {
        mutation: UPDATE_COMPONENT_GRAPHQL_MUTATION,
        variables: {
          input: updateBookStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateComponent.hasErrors === false
          ? Promise.resolve(mutationResults.updateComponent.component)
          : Promise.reject(mutationResults.updateComponent.errors);
      });
    }

    return promise;
  }

  static deleteBook(
    componentInput: ComponentPageFromInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
