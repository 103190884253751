/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-10
 * @description Implementation of the message modal.
 * @filename message-modal.tsx
 */
import React from "react";
import { RdvButton } from "../../page-framework/rdv-button";

/**
 * The static properties of the MessageModal class
 *
 * @interface
 */
interface MessageModalProps {
  /**
   * The label of the button.
   *
   * @type {string}
   */
  buttonLabel?: string;

  /**
   * A flag which is true if the modal has a close button
   *
   * @type {boolean}
   */
  hasCloseButton?: boolean;

  /**
   * A function that closes the modal.
   *
   * @type {function():void}
   */
  closeModal: () => void;

  /**
   * The ID of the class
   *
   * @type {string}
   */
  id: string;

  /**
   * A flag which is true if the Modal Header has a Radivision logo.
   *
   * @type {boolean}
   */
  logo?: boolean;

  /**
   * The message.
   *
   * @type {string}
   */
  message: string;

  /**
   * The sub-title of the message.
   *
   * @type {string}
   */
  messageSubTitle?: string;

  /**
   * The tile of the message.
   *
   * @type {string}
   */
  messageTitle?: string;

  /**
   * The title of the modal
   *
   * @type {string}
   */
  title?: string;

  /**
   *
   *
   * @type {Function}
   * @memberof MessageModalProps
   */
  btnAction?: Function;

  /**
   * the signed up user email
   *
   * @type {string}
   * @memberof MessageModalProps
   */
  userEmail?: string;

  /**
   * the signed up user password
   *
   * @type {string}
   * @memberof MessageModalProps
   */
  userPassword?: string;
}

/**
 *  A React component that renders the error modal.
 *
 * @export
 * @class MessageModal
 * @extends {React.Component}
 */
export class MessageModal extends React.Component<MessageModalProps> {
  loginTimer: ReturnType<typeof setTimeout>;
  /**
   * Constructor.
   *
   * @param {MessageModalProps} props The props of the component.
   */
  constructor(props: MessageModalProps) {
    super(props);
    this.close = this.close.bind(this);
    this.login = this.login.bind(this);
  }

  /**
   * Closes the modal.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} e The event supplied when the mouse is clicked.
   */
  close(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    this.props.closeModal();
    if (this.props.btnAction) {
      this.props.btnAction();
    }
  }

  /**
   * Invoked when the component finished mounting
   */
  componentDidMount() {
    this.loginTimer = setTimeout(() => {
      this.login();
    }, 3000);
  }

  /**
   * Invked when the component will be destroyed
   */
  componentWillMount() {
    clearTimeout(this.loginTimer);
  }

  /**
   * to login the user after regestration and verfication
   */
  login() {
    if (this.props.userEmail && this.props.userPassword) {
      return import("../../../authentication/cognito-client").then((module) => {
        module.CognitoClient.loginWithEmailAndPassword(
          this.props.userEmail,
          this.props.userPassword
        ).then(() => {
          location.reload();
        });
      });
    }
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return (
      <div className="message-modal text-center">
        {this.props.messageTitle ? <h2>{this.props.messageTitle}</h2> : ""}
        {this.props.messageSubTitle ? (
          <h4>{this.props.messageSubTitle}</h4>
        ) : (
          ""
        )}
        <p className="message">{this.props.message}</p>
        {this.props.hasCloseButton ? (
          <RdvButton
            text={this.props.buttonLabel ? this.props.buttonLabel : "Close"}
            onClick={this.close}
          />
        ) : null}
      </div>
    );
  }
}
