/**
 * @author Ahmed Samer
 * @date   2020-08-31
 * @description
 * @filename index.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { GraphQLTaggedNode } from "react-relay";
import { CHANGE_HERO_GRAPHQL_MUTATION } from "../../../../relay/mutations/change-hero-to-video-panel";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { getClientMutationId } from "../../../../utilities/general";
import { commitMutation } from "../../../../utilities/relay";
import { RdvButton } from "../../../page-framework/rdv-button";
import { Loader } from "../../../page/loader";

export interface HeroReplaceComponentState {
  /**
   *
   *
   * @type {boolean}
   * @memberof HeroReplaceComponentState
   */
  loading: boolean;

  /**
   *
   *
   * @type {?string}
   * @memberof HeroReplaceComponentState
   */
  eventId?: string;

  /**
   *
   *
   * @type {string}
   * @memberof HeroReplaceComponentState
   */
  errorMessage?: string;
}

/**
 *
 *
 * @returns
 */
export class HeroReplaceComponent extends React.Component<
  {},
  HeroReplaceComponentState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  /**
   *
   *
   * @returns
   * @memberof HeroReplaceComponent
   */
  render() {
    return (
      <div
        className="dark-bg"
        style={{
          backgroundImage: `url("${require("../../../../../static/magic.jpg")}")`,
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Event Id</label>
                <input
                  type="text"
                  name="Label"
                  className={`form-control ${
                    this.state.errorMessage && this.state.errorMessage.length
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={(event) => {
                    if (event.currentTarget.value) {
                      this.setState({ eventId: event.currentTarget.value });
                    }
                  }}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage}
                </span>
              </div>
            </div>
            <RdvButton
              type={"submit"}
              isOutline={false}
              text="CHANGE HERO TO VIDEO PANEL"
              disabled={this.state.loading}
              onClick={(event) => {
                this.setState({ loading: true });
                let promise = Promise.resolve();
                promise = promise.then(() => {
                  if (!this.state.eventId || this.state.eventId.length < 20) {
                    throw new Error("INVALID EVENT ID");
                  }

                  const config: {
                    mutation: GraphQLTaggedNode;
                    variables: {};
                  } = {
                    mutation: CHANGE_HERO_GRAPHQL_MUTATION,
                    variables: {
                      input: {
                        clientMutationId: getClientMutationId(),
                        eventId: this.state.eventId,
                        type: "HERO_TO_VIDEO",
                      },
                    },
                  };

                  return commitMutation(ENVIRONMENT, config);
                });

                promise = promise.then((mutationResults: any) => {
                  this.setState({ loading: false });
                  return mutationResults.changeHeroToVideoPanel.hasErrors ===
                    false
                    ? Promise.resolve(mutationResults.changeHeroToVideoPanel)
                    : Promise.reject(
                        mutationResults.changeHeroToVideoPanel.errors
                      );
                });

                promise = promise.catch((err) => {
                  this.setState({
                    errorMessage: err.message,
                    loading: false,
                  });
                });
              }}
            />
            <RdvButton
              type={"submit"}
              isOutline={false}
              extraClassName="float-right btn-lg upload"
              text="REVERT BACK TO HERO"
              disabled={this.state.loading}
              onClick={() => {
                this.setState({ loading: true });
                const config: {
                  mutation: GraphQLTaggedNode;
                  variables: {};
                } = {
                  mutation: CHANGE_HERO_GRAPHQL_MUTATION,
                  variables: {
                    input: {
                      clientMutationId: getClientMutationId(),
                      eventId: this.state.eventId,
                      type: "VIDEO_TO_HERO",
                    },
                  },
                };
                let promise = commitMutation(ENVIRONMENT, config);

                promise = promise.then((mutationResults: any) => {
                  this.setState({ loading: false });
                  return mutationResults.changeHeroToVideoPanel.hasErrors ===
                    false
                    ? Promise.resolve(mutationResults.changeHeroToVideoPanel)
                    : Promise.reject(
                        mutationResults.changeHeroToVideoPanel.errors
                      );
                });
              }}
            />
          </div>
        </div>

        <div
          className="moving-clouds"
          style={{
            backgroundImage: `url("${require("../../../../../static/clouds.png")}")`,
          }}
        />
        <Loader isActive={this.state.loading} />
      </div>
    );
  }
}
