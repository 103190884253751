/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type listCollectionPageQueryVariables = {
    readonly id: string;
};
export type listCollectionPageQueryResponse = {
    readonly listCollection: {
        readonly errors: ReadonlyArray<{
            readonly created: unknown | null;
            readonly id: string;
            readonly location: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly hasErrors: boolean | null;
        readonly listCollection: {
            readonly __typename: string;
            readonly description: string | null;
            readonly id: string;
            readonly label: string | null;
            readonly revision: string | null;
            readonly name: string | null;
            readonly subTitle: string | null;
            readonly title: string | null;
            readonly lists: ReadonlyArray<{
                readonly __typename: string;
                readonly description?: string | null;
                readonly id?: string;
                readonly label?: string | null;
                readonly name?: string | null;
                readonly previews?: {
                    readonly count: number | null;
                    readonly edges: ReadonlyArray<{
                        readonly cursor: string | null;
                        readonly preview: {
                            readonly link: string | null;
                            readonly kind: PreviewKind | null;
                            readonly mediaAsset: {
                                readonly id: string;
                                readonly files: ReadonlyArray<{
                                    readonly name: string | null;
                                    readonly path: string | null;
                                    readonly id?: string;
                                }> | null;
                                readonly topLevelMediaType: {
                                    readonly kind: TopLevelMediaKind | null;
                                    readonly label: string | null;
                                } | null;
                            } | null;
                        } | null;
                    }> | null;
                } | null;
                readonly subTitle?: string | null;
                readonly title?: string | null;
            } & ({
                readonly __typename: "FixedList";
                readonly description: string | null;
                readonly id: string;
                readonly label: string | null;
                readonly name: string | null;
                readonly previews: {
                    readonly count: number | null;
                    readonly edges: ReadonlyArray<{
                        readonly cursor: string | null;
                        readonly preview: {
                            readonly link: string | null;
                            readonly kind: PreviewKind | null;
                            readonly mediaAsset: {
                                readonly id: string;
                                readonly files: ReadonlyArray<{
                                    readonly name: string | null;
                                    readonly path: string | null;
                                    readonly id?: string;
                                }> | null;
                                readonly topLevelMediaType: {
                                    readonly kind: TopLevelMediaKind | null;
                                    readonly label: string | null;
                                } | null;
                            } | null;
                        } | null;
                    }> | null;
                } | null;
                readonly subTitle: string | null;
                readonly title: string | null;
            } | {
                /*This will never be '% other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            })> | null;
        } | null;
    } | null;
};
export type listCollectionPageQuery = {
    readonly response: listCollectionPageQueryResponse;
    readonly variables: listCollectionPageQueryVariables;
};



/*
query listCollectionPageQuery(
  $id: ID!
) {
  listCollection(id: $id) {
    errors {
      created
      id
      location
      type
    }
    hasErrors
    listCollection {
      __typename
      description
      id
      label
      revision
      name
      subTitle
      title
      lists {
        __typename
        ... on FixedList {
          description
          id
          label
          name
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  id
                  files {
                    name
                    path
                    id
                  }
                  topLevelMediaType {
                    kind
                    label
                    id
                  }
                }
              }
            }
          }
          subTitle
          title
        }
        ... on DynamicList {
          description
          id
          label
          name
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                    id
                  }
                  id
                }
              }
            }
          }
          subTitle
          title
        }
        ... on Node {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "created",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "topLevelMediaType",
  "storageKey": null,
  "args": null,
  "concreteType": "TopLevelMediaType",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v7/*: any*/)
  ]
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v9/*: any*/),
    (v16/*: any*/)
  ]
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "topLevelMediaType",
  "storageKey": null,
  "args": null,
  "concreteType": "TopLevelMediaType",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v7/*: any*/),
    (v2/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "listCollectionPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "listCollection",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ListCollectionPayload",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "listCollection",
            "storageKey": null,
            "args": null,
            "concreteType": "ListCollection",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lists",
                "storageKey": null,
                "args": null,
                "concreteType": null,
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "type": "FixedList",
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "previews",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewsConnection",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PreviewEdge",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "preview",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Preview",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "mediaAsset",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "MediaAsset",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "files",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "MediaAssetFile",
                                        "plural": true,
                                        "selections": [
                                          (v9/*: any*/),
                                          (v16/*: any*/)
                                        ]
                                      },
                                      (v17/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  },
                  {
                    "kind": "InlineFragment",
                    "type": "DynamicList",
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "previews",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewsConnection",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PreviewEdge",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "preview",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Preview",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "mediaAsset",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "MediaAsset",
                                    "plural": false,
                                    "selections": [
                                      (v18/*: any*/),
                                      (v17/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "listCollectionPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "listCollection",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ListCollectionPayload",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "listCollection",
            "storageKey": null,
            "args": null,
            "concreteType": "ListCollection",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lists",
                "storageKey": null,
                "args": null,
                "concreteType": null,
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "type": "FixedList",
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "previews",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewsConnection",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PreviewEdge",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "preview",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Preview",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "mediaAsset",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "MediaAsset",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "files",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "MediaAssetFile",
                                        "plural": true,
                                        "selections": [
                                          (v9/*: any*/),
                                          (v16/*: any*/),
                                          (v2/*: any*/)
                                        ]
                                      },
                                      (v19/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  },
                  {
                    "kind": "InlineFragment",
                    "type": "DynamicList",
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "previews",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewsConnection",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PreviewEdge",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "preview",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Preview",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "mediaAsset",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "MediaAsset",
                                    "plural": false,
                                    "selections": [
                                      (v18/*: any*/),
                                      (v19/*: any*/),
                                      (v2/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "listCollectionPageQuery",
    "id": null,
    "text": "query listCollectionPageQuery(\n  $id: ID!\n) {\n  listCollection(id: $id) {\n    errors {\n      created\n      id\n      location\n      type\n    }\n    hasErrors\n    listCollection {\n      __typename\n      description\n      id\n      label\n      revision\n      name\n      subTitle\n      title\n      lists {\n        __typename\n        ... on FixedList {\n          description\n          id\n          label\n          name\n          previews {\n            count\n            edges {\n              cursor\n              preview {\n                link\n                kind\n                mediaAsset {\n                  id\n                  files {\n                    name\n                    path\n                    id\n                  }\n                  topLevelMediaType {\n                    kind\n                    label\n                    id\n                  }\n                }\n              }\n            }\n          }\n          subTitle\n          title\n        }\n        ... on DynamicList {\n          description\n          id\n          label\n          name\n          previews {\n            count\n            edges {\n              cursor\n              preview {\n                link\n                kind\n                mediaAsset {\n                  files {\n                    id\n                    name\n                    path\n                  }\n                  topLevelMediaType {\n                    kind\n                    label\n                    id\n                  }\n                  id\n                }\n              }\n            }\n          }\n          subTitle\n          title\n        }\n        ... on Node {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '7c166f3db168ffb1127175239f756e6b';
export default node;
