/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-26
 * @description Implementation of the GraphQL mutation to update questionnaire
 * @filename update-questionnaire.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an Questionnaire.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_QUESTIONNAIRE_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateQuestionnaireMutation($input: UpdateQuestionnaireInput!) {
    updateQuestionnaire(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      questionnaire {
        id
        title
      }
    }
  }
`;
