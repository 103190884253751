import * as React from "react";
import ModeratorView from "./moderator-view";
import { ContentModerationStateKind } from "@radivision/graphql";

/**
 *
 *
 * @interface IModeratorDetails
 */
interface IModeratorDetails {
  moderatorDetails: any[];
  actualAction: ContentModerationStateKind;
}
export default class ModerationPanel extends React.Component<
  {},
  IModeratorDetails
  > {
  /**
   * Creates an instance of ModerationPanel.
   * @param {{}} props
   * @memberof ModerationPanel
   */
  constructor(props: {}) {
    super(props);
    this.state = {
      actualAction: ContentModerationStateKind.TO_BE_APPROVED,

      moderatorDetails: [
        { moderatorId: 1, roleName: "Producer", roleAction: "" },
        { moderatorId: 2, roleName: "AssistantDirector", roleAction: "" },
        { moderatorId: 3, roleName: "Director", roleAction: "" }
      ]
    };

    this.resetComponent = this.resetComponent.bind(this);
  }

  /**
   *
   *
   * @memberof ModerationPanel
   */
  changeAction = (id: number, action: string) => {
    // // console.log("id :", id + "Action :", action);
    let newActualAction: ContentModerationStateKind;

    let moderatorArray = [...this.state.moderatorDetails];

    moderatorArray.forEach(element => {
      if (element.moderatorId === id) {
        // // console.log(element);
        element.roleAction = action;
        //  // console.log("Updated : ", element);
      }
    });
    this.setState({
      moderatorDetails: moderatorArray
    });
    // // console.log("Array in State Now : ", this.state.moderatorDetails);

    // Updating the actual.....
    moderatorArray = [...this.state.moderatorDetails];
    // // console.log("New Actual Action : ", newActualAction);
    moderatorArray.forEach(element => {
      if (element.roleAction === "approve") {
        newActualAction = ContentModerationStateKind.APPROVED;
        // // console.log("New Actual Action : ", newActualAction);
      } else if (element.roleAction === "banned") {
        newActualAction = ContentModerationStateKind.BANNED;
        // // console.log("New Actual Action : ", newActualAction);
      } else {
        newActualAction = ContentModerationStateKind.TO_BE_APPROVED;
        // // console.log("New Actual Action : ", newActualAction);
      }
    });

    this.setState({
      actualAction: newActualAction
    });
  };

  /**
   *
   *
   * @memberof ModerationPanel
   */
  extractModerationState(): ContentModerationStateKind {
    let moderationPanel: ContentModerationStateKind;
    moderationPanel = this.state.actualAction;

    return moderationPanel;
  }

  /**
   *
   *
   * @returns
   * @memberof ModerationPanel
   */
  render() {
    return (
      <div className="container__">
        {this.state.moderatorDetails.map((moderator, index) => {
          return (
            <ModeratorView
              moderatorId={moderator.moderatorId}
              roleName={moderator.roleName}
              roleAction={moderator.roleAction}
              changeAction={this.changeAction}
              key={index}
            />
          );
        })}

        
        {this.state.actualAction ===
          ContentModerationStateKind.TO_BE_APPROVED ? (
            <div>
              <label>Actual</label>
              <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  id="approve"
                  name="actualactiongroup"
                  type="radio"
                  value="tobeapproved"
                  readOnly={true}
                  checked={true}
                />
                <label className="custom-control-label">
                  To be approved
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  id="approve"
                  name="actualactiongroup"
                  type="radio"
                  readOnly={true}
                  value="approved"
                />
                <label className="custom-control-label">
                  Approved
              </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  id="banned"
                  name="actualactiongroup"
                  type="radio"
                  readOnly={true}
                  value="banneded"
                />
                <label className="custom-control-label">
                  Blocked
                </label>
              </div>
            </div>
          ) : this.state.actualAction ===
            ContentModerationStateKind.APPROVED ? (
              <div>
                <label>Actual</label>
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="approve"
                    name="actualactiongroup"
                    type="radio"
                    readOnly={true}
                    value="tobeapproved"
                  />
                  <label className="custom-control-label">
                    To be approved
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="approve"
                    name="actualactiongroup"
                    type="radio"
                    readOnly={true}
                    value="approved"
                    checked={true}
                  />
                  <label className="custom-control-label">
                    Approved
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="banned"
                    name="actualactiongroup"
                    type="radio"
                    readOnly={true}
                    value="banneded"
                  />
                  <label className="custom-control-label">
                    Blocked
                  </label>
                </div>
              </div>
            ) : (
              <div>
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="approve"
                    name="actualactiongroup"
                    type="radio"
                    readOnly={true}
                    value="tobeapproved"
                  />
                  <label className="custom-control-label">
                    To be approved
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="approve"
                    name="actualactiongroup"
                    type="radio"
                    readOnly={true}
                    value="approved"
                  />
                  <label className="custom-control-label">
                    Approved
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <label className="custom-control-label">
                    <input
                      className="custom-control-input"
                      id="banned"
                      name="actualactiongroup"
                      type="radio"
                      readOnly={true}
                      value="banneded"
                      checked={true}
                    />
                    Blocked
                  </label>
                </div>
              </div>
            )}
      </div>
    );
  }

  /**
   *
   *
   * @memberof ModerationPanel
   */
  resetComponent() {
    this.setState({
      moderatorDetails: [
        { moderatorId: 1, roleName: "Producer", roleAction: "" },
        { moderatorId: 2, roleName: "AssistantDirector", roleAction: "" },
        { moderatorId: 3, roleName: "Director", roleAction: "" }
      ],
      actualAction: ContentModerationStateKind.TO_BE_APPROVED
    });
  }
}
