import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

/**
 *
 *
 * @interface FloatingProps
 */
interface FloatingProps {
  /**
   *
   *
   * @type {string}
   * @memberof IFloatingProps
   */
  label: string;
  /**
   *
   *
   * @type {IconProp}
   * @memberof IFloatingProps
   */
  icon: IconProp;
  /**
   *
   *
   * @type {Function}
   * @memberof IFloatingProps
   */
  action?: Function;
}

/**
 *
 *
 * @interface IFloatingProps
 */
interface FloatingMenuItemState {
  /**
   *
   *
   * @type {string}
   * @memberof FloatingMenuItemState
   */
  iconClassName: string;
}

/**
 *
 *
 * @export
 * @class FloatingMenuItem
 * @extends {React.Component<IFloatingProps>}
 */
export default class FloatingMenuItem extends React.Component<
  FloatingProps,
  FloatingMenuItemState
  > {
  /**
   *Creates an instance of FloatingMenuItem.
   * @param {IFloatingProps} props
   * @memberof FloatingMenuItem
   */
  constructor(props: FloatingProps) {
    super(props);

    let iconClassName: string;

    if (this.props.label === "Import") {
      iconClassName = `icons-purple`;
    } else if (this.props.label === "Revert") {
      iconClassName = `icons-blue`;
    } else {
      iconClassName = `icons-black`;
    }

    this.state = {
      iconClassName
    };
  }

  /**
   *
   *
   * @memberof FloatingMenuItem
   */
  handleClick = () => {
    const x = this.props.action;
    if (x !== undefined && x !== null) {
      x();
    }
  };

  /**
   *
   *
   * @returns
   * @memberof FloatingMenuItem
   */
  render() {
    return (
      <div
        onClick={() => {
          this.handleClick();
        }}
        className="floating-menu--item"
      >
        <div className="floating-menu-icon">
          <div className={this.state.iconClassName}>
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
        </div>
        <label>{this.props.label}</label>
      </div>
    );
  }
}
