/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Initial page component
 * @filename InitialPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Publisher from "./publisher";
import AuthorPanel from "./author/author-panel";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import ModerationPanel from "./moderation/moderation-panel";
import {
  PodcastStory,
  GRAPHQL_TYPE_PODCAST_STORY,
  PreviewKind,
  ApiObjectKindType,
} from "@radivision/graphql";

import { Validator } from "../../../utilities/cms-operation/validator";
import ModalConductor from "./modal/modal-conductor";
import { Podcast } from "../../../utilities/podcast";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import {
  FormGroupInput,
  PodcastPageFormInput,
  PreviewItem,
} from "../../../component-configuration/cms-pages";
import { Story } from "../../../utilities/cms-operation/story";
import { Crawler } from "../../../utilities/crawler";
import { isValidUrl } from "../../../utilities/general";
import { RdvButton } from "../../page-framework/rdv-button";

/**
 * The props of the article page component.
 *
 * @export
 * @interface PodcastPageProps
 */
export interface PodcastPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof PodcastPageProps
   */
  podcast?: { id: string };

  /**
   *
   *
   * @type {PodcastStory}
   * @memberof PodcastPageProps
   */
  podcastStory?: PodcastStory;
}

/**
 * The state of the article page component.
 *
 * @interface PodcastPageState
 */
export interface PodcastPageState {
  /**
   *
   *
   * @type {FormGroupInput}
   * @memberof PodcastPageState
   */
  formGroupInput: FormGroupInput;

  /**
   *
   *
   * @type {PodcastPageFormInput}
   * @memberof PodcastPageState
   */
  podcastFromInput: PodcastPageFormInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof PodcastPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof PodcastPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof PodcastPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class PodcastPage
 * @extends {React.Component<PodcastPageProps, PodcastPageState>}
 */
export class PodcastPage extends React.Component<
  PodcastPageProps,
  PodcastPageState
> {
  /**
   * References to the Author component in the PodcastPage.
   *
   * @type {*}
   * @memberof PodcastPage
   */
  authorPanelRef: any;

  /**
   * References to the moderation component in the PodcastPage.
   *
   * @type {*}
   * @memberof PodcastPage
   */
  moderationPanelRef: any;

  /**
   * References to the publisher component in the PodcastPage.
   *
   * @type {*}
   * @memberof PodcastPage
   */
  publisherDetailsRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof PodcastPage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the PodcastPage.
   *
   * @type {*}
   * @memberof PodcastPage
   */
  tagsRef: any;

  /**
   * Creates an instance of PodcastPage.
   * @param {PodcastPageProps} props
   * @memberof PodcastPage
   */
  constructor(props: PodcastPageProps) {
    super(props);

    //... bind needed function to class

    this.handleLinkChanges = this.handleLinkChanges.bind(this);
    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handlePublishDateChanges = this.handlePublishDateChanges.bind(this);
    this.handleDurationChanges = this.handleDurationChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleIsNewChanges = this.handleIsNewChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.handleAutoFill = this.handleAutoFill.bind(this);

    //... initialize required components references

    this.authorPanelRef = React.createRef();
    this.moderationPanelRef = React.createRef();
    this.publisherDetailsRef = React.createRef();
    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();

    //... initial component state
    this.state = this.handleComponentStateInitialize(this.props.podcastStory);
  }

  /**
   *
   *
   * @param {PodcastStory} podcastProps
   * @returns {PodcastPageState}
   * @memberof PodcastPage
   */
  handleComponentStateInitialize(podcastProps: PodcastStory): PodcastPageState {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState,
    };

    let podcastFromInput: PodcastPageFormInput = {
      id: undefined,
      label: "",
      link: "",
      title: "",
      subTitle: "",
      summary: "",
      publisherName: "",
      publishedDate: currentTime,
      isDeleted: false,
      isNew: false,
      durationInSeconds: 10,
      people: undefined,
    };

    let state: PodcastPageState = {
      modal,
      podcastFromInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {},
    };

    if (podcastProps !== null && podcastProps !== undefined) {
      // validate provided label

      if (podcastProps.id) {
        state.podcastFromInput.id = podcastProps.id;
      }

      if (podcastProps.label) {
        state.podcastFromInput.label = podcastProps.label;
      }

      // validate provided link
      if (podcastProps.link) {
        state.podcastFromInput.link = podcastProps.link;
      }

      if (podcastProps.title) {
        state.podcastFromInput.title = podcastProps.title;
      }

      if (podcastProps.subTitle) {
        state.podcastFromInput.subTitle = podcastProps.subTitle;
      }

      if (podcastProps.description) {
        state.podcastFromInput.summary = podcastProps.description;
      }

      if (podcastProps.isDeleted) {
        state.podcastFromInput.isDeleted = podcastProps.isDeleted;
      }

      if (podcastProps.isNew) {
        state.podcastFromInput.isNew = podcastProps.isNew;
      }

      if (podcastProps.durationInSeconds) {
        state.podcastFromInput.durationInSeconds =
          podcastProps.durationInSeconds;
      }

      if (podcastProps.revision) {
        state.podcastFromInput.revision = podcastProps.revision;
      }
      // ............
      if (podcastProps.publishedDate) {
        state.podcastFromInput.publishedDate = podcastProps.publishedDate;
      }

      // initialize form group inputs

      //...............
      if (podcastProps.publisher) {
        state.formGroupInput.publisherProps = {
          name: podcastProps.publisher,
          // type :""
        };
      }

      if (
        podcastProps.tags !== null &&
        podcastProps.tags !== undefined &&
        podcastProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = podcastProps.tags.map(
          (tag) => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        podcastProps.previews !== null &&
        podcastProps.previews !== undefined &&
        podcastProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = podcastProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset",
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link,
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`,
                  },
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @returns
   * @memberof PodcastPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Podcast Story</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Link URL</label>
                  <input
                    type="text"
                    name="Link URL"
                    className={`form-control ${
                      this.state.errorMessage.get("LINK") ? "is-invalid" : ""
                    }`}
                    value={this.state.podcastFromInput.link}
                    onChange={this.handleLinkChanges}
                  />
                  <RdvButton
                    extraClassName="auto-fill"
                    text="auto fill"
                    onClick={this.handleAutoFill}
                    disabled={
                      this.state?.podcastFromInput?.link &&
                      !this.state.isLoading
                        ? false
                        : true
                    }
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("LINK")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.podcastFromInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    name="Subtitle"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.podcastFromInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>
            {/* Author Section */}
            <div className="Page--Author">
              <label htmlFor="">Authors</label>
              <AuthorPanel ref={this.authorPanelRef} />
            </div>
            {/* Publisher Section */}
            <div className="Page--Publisher">
              <label>Publisher</label>
              <Publisher
                error={{
                  haveError: this.state.errorMessage.get("PUBLISHER")
                    ? true
                    : false,
                  message: this.state.errorMessage.get("PUBLISHER"),
                }}
                publisher={this.state.formGroupInput.publisherProps}
                ref={this.publisherDetailsRef}
              />
            </div>
            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Published</label>
                <input
                  id="party"
                  type="datetime"
                  name="published"
                  value={this.state.podcastFromInput.publishedDate}
                  onChange={this.handlePublishDateChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>Duration (Seconds)</label>
                <input
                  id="party"
                  type="number"
                  name="timetoread"
                  value={this.state.podcastFromInput.durationInSeconds}
                  onChange={this.handleDurationChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.podcastFromInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Summary</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.podcastFromInput.summary}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="isdeleted"
                  checked={this.state.podcastFromInput.isDeleted}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="new"
                  value={this.state.podcastFromInput.isNew ? "true" : "false"}
                  onClick={this.handleIsNewChanges}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">New</label>
              </div>
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <ManagePreviewPanel
                ref={this.previewPanelRef}
                previewsList={this.state.formGroupInput.previewsProps}
              />
            </div>
            {/* moderation section */}
            <div className="Page--Moderation">
              <ModerationPanel ref={this.moderationPanelRef} />
            </div>
          </div>
          <Loader isActive={this.state.isLoading} />
          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction,
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let podcastFromInput = this.state.podcastFromInput;
                  podcastFromInput.isDeleted = true;
                  this.setState({ podcastFromInput });
                },
                unDeleteAction: () => {
                  let podcastFromInput = this.state.podcastFromInput;
                  podcastFromInput.isDeleted = false;
                  this.setState({ podcastFromInput });
                },
              },
              saveable: this.handleSaveAction,
            }}
          />
        </div>
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in article link state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PodcastPage
   */
  handleLinkChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();

    // // console\.log("[handleLinkChanges] provided value : ", eventValue);\
    // ... assign value if valid
    podcastFromInput.link = eventValue;
    this.setState({ podcastFromInput });
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @memberof PodcastPage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    podcastFromInput.title = eventValue;
    this.setState({ podcastFromInput });
  }

  /**
   * Function Responsible for handling changes in article sub-title state
   *
   * @memberof PodcastPage
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    podcastFromInput.subTitle = eventValue;
    this.setState({ podcastFromInput });
  }

  /**
   * Function Responsible for handling changes in article Publish Date state
   *
   * @memberof PodcastPage
   */
  handlePublishDateChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    podcastFromInput.publishedDate = eventValue;
    this.setState({ podcastFromInput });
  }

  /**
   * Function Responsible for handling changes in article time to read state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PodcastPage
   */
  handleDurationChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;
    let eventValue: number = Number(changeEvent.currentTarget.value);
    // // console\.log("[handleTimeToReadChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    podcastFromInput.durationInSeconds = eventValue;
    this.setState({ podcastFromInput });
  }

  /**
   * Function Responsible for handling changes in article summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PodcastPage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    podcastFromInput.summary = eventValue;
    this.setState({ podcastFromInput });
  }

  /**
   *
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e
   * @memberof AddArticleTab
   */
  handleAutoFill(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const LINK = this.state?.podcastFromInput?.link;

    if (!isValidUrl(LINK)) {
      console.log({ LINK });
      const modal = this.state.modal;
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Invalid Link auto fill failed`;
      modal.modalTile = "Action Failed";
      this.setState({ modal });

      return;
    }
    this.setState({ isLoading: true });

    return Crawler.fetch(LINK, ApiObjectKindType.PODCAST)
      .then((article: PodcastStory) => {
        if (article !== null && article !== undefined) {
          this.setState({ isLoading: false });
          const podcastFromInput = this.state.podcastFromInput;
          const formGroupInput = this.state.formGroupInput;
          podcastFromInput.link = article?.link || podcastFromInput.link;
          podcastFromInput.title = article?.title || podcastFromInput.title;
          podcastFromInput.durationInSeconds =
            article?.durationInSeconds || podcastFromInput.durationInSeconds;

          podcastFromInput.summary =
            article?.description || podcastFromInput.summary;
          podcastFromInput.publishedDate =
            article?.publishedDate || podcastFromInput.publishedDate;
            
          if (article?.subTitle) {
            this.publisherDetailsRef.current.addCustomPublisher(
              article.subTitle
            );
          }

          if (article?.previews) {
            this.previewPanelRef.current.addCustomPreviewItem({
              previewInfo: article?.previews?.edges[0]?.preview?.link,
              previewKind: PreviewKind.SQUARE,
            });
          }
          console.log({ article });

          this.setState({
            podcastFromInput,
            formGroupInput,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Function Responsible for handling changes in article label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PodcastPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    podcastFromInput.label = eventValue;
    this.setState({ podcastFromInput });
  }

  /**
   * Function Responsible for handling changes in article is deleted state
   *
   * @memberof PodcastPage
   */
  handleIsDeletedChanges(event: any): void {
    let isDeleted: boolean = event.target.checked ? true : false;
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;

    // // console\.log("[handleIsDeletedChanges] is article deleted", isDeleted);
    podcastFromInput.isDeleted = isDeleted;
    this.setState({ podcastFromInput });
  }

  /**
   * Function Responsible for handling changes in article is new state
   *
   * @memberof PodcastPage
   */
  handleIsNewChanges(event: any): void {
    let isNew: boolean = event.target.checked ? true : false;
    let podcastFromInput: PodcastPageFormInput = this.state.podcastFromInput;

    // // console\.log("[handleIsNewChanges] is article new ", isNew);
    podcastFromInput.isNew = isNew;
    this.setState({ podcastFromInput });
  }

  /**
   * Function Responsible for handling submitting new article
   *
   * @memberof PodcastPage
   */
  handleSaveAction(): void {
    let podcastPageState: PodcastPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract moderation state
      podcastPageState.podcastFromInput.moderation = this.moderationPanelRef.current.extractModerationState();
      // extract tags state
      podcastPageState.podcastFromInput.tags = this.tagsRef.current.extractTagsString();
      // extract publisher name
      podcastPageState.podcastFromInput.publisherName = this.publisherDetailsRef.current.extractPublisherDetails();
      // extract previews list
      podcastPageState.podcastFromInput.previews = this.previewPanelRef.current.extractPreviews();

      // validate errors
      errorMessage = Validator.validateCreateStoryInput(
        podcastPageState.podcastFromInput
      );

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console\.log("[handleAddArticle] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (this.props.podcast !== null && this.props.podcast !== undefined) {
          internalPromise =
            podcastPageState.podcastFromInput.isDeleted === true
              ? Story.deleteStory(podcastPageState.podcastFromInput)
              : Podcast.updatePodcast(podcastPageState.podcastFromInput);

          internalPromise = internalPromise.then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${
              CMS_PAGE_CONSTANT["PODCAST"].message.confirmation.update
            }${results && results.id ? `,Podcast-id : ${results.id}` : ""}`;
            modal.modalTile = "Action Complete";
          });
        } else {
          internalPromise = Podcast.addPodcast(
            podcastPageState.podcastFromInput
          ).then((results: any) => {
            // ... handle mutation success
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${CMS_PAGE_CONSTANT["PODCAST"].message.confirmation.add},Podcast-id : ${results.id}`;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["PODCAST"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch((err) => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false,
      });
    });
  }

  /**
   *
   *
   * @memberof PodcastPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof PodcastPage
   */
  revertPageInput(input?: PodcastStory): void {
    let state: PodcastPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.podcastStory
    );
    this.setState(state);

    //... reset children state
    this.tagsRef.current.resetComponent();
    this.authorPanelRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.moderationPanelRef.current.resetComponent();
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof PodcastPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class PodcastPageContainer extends React.Component<
  PodcastPageProps,
  PodcastPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let podcastId: string;

    // console\.log("[PodcastPageContainer] provided props", this.props);

    if (this.props.podcast === null || this.props.podcast === undefined) {
      node = <PodcastPage />;
    } else {
      podcastId = this.props.podcast.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query podcastPageQuery($id: ID!) {
              story(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                node {
                  __typename
                  ... on PodcastStory {
                    id
                    link
                    publisher
                    durationInSeconds
                    publishedDate
                    description
                    created
                    label
                    isDeleted
                    isNew
                    revision
                    tags {
                      id
                      label
                    }
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                              label
                            }
                          }
                        }
                      }
                    }
                    subTitle
                    title
                  }
                }
              }
            }
          `}
          variables={{
            id: podcastId,
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.story.hasErrors) {
                return (
                  <div>
                    <p>unexpected error contact Radivision Technical team.</p>{" "}
                  </div>
                );
              } else if (
                props.story.node.__typename !== GRAPHQL_TYPE_PODCAST_STORY
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Podcast id, {podcastId} belongs to type{" "}
                      {props.story.node.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.story.node) {
                let pageProps: PodcastPageProps = {
                  ...this.props,
                  podcastStory: props.story.node,
                };
                return <PodcastPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
