/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type htmlDocumentPageQueryVariables = {
    readonly id: string;
};
export type htmlDocumentPageQueryResponse = {
    readonly htmlDocument: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly node: {
            readonly __typename: string;
            readonly id: string;
            readonly revision: string | null;
            readonly label: string | null;
            readonly description: string | null;
            readonly panels: ReadonlyArray<{
                readonly id: string;
                readonly label: string | null;
                readonly component: {
                    readonly id: string;
                } | null;
                readonly properties: ReadonlyArray<{
                    readonly name: string | null;
                    readonly article: {
                        readonly id: string;
                    } | null;
                    readonly book: {
                        readonly id: string;
                    } | null;
                    readonly boolean: boolean | null;
                    readonly dynamicList: {
                        readonly id: string;
                    } | null;
                    readonly entity: {
                        readonly id: string;
                    } | null;
                    readonly fixedList: {
                        readonly id: string;
                    } | null;
                    readonly id: string | null;
                    readonly int: number | null;
                    readonly json: string | null;
                    readonly listCollection: {
                        readonly id: string;
                    } | null;
                    readonly newsletter: {
                        readonly id: string;
                    } | null;
                    readonly number: number | null;
                    readonly onlineCourse: {
                        readonly id: string;
                    } | null;
                    readonly originalContent: {
                        readonly id: string;
                    } | null;
                    readonly person: {
                        readonly id: string;
                    } | null;
                    readonly podcast: {
                        readonly id: string;
                    } | null;
                    readonly poster: {
                        readonly id: string;
                    } | null;
                    readonly string: string | null;
                } | null> | null;
            }> | null;
        } | null;
    } | null;
};
export type htmlDocumentPageQuery = {
    readonly response: htmlDocumentPageQueryResponse;
    readonly variables: htmlDocumentPageQueryVariables;
};



/*
query htmlDocumentPageQuery(
  $id: ID!
) {
  htmlDocument(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    node {
      __typename
      id
      revision
      label
      description
      panels {
        id
        label
        component {
          id
        }
        properties {
          name
          article {
            id
          }
          book {
            id
          }
          boolean
          dynamicList {
            id
          }
          entity {
            id
          }
          fixedList {
            id
          }
          id
          int
          json
          listCollection {
            id
          }
          newsletter {
            id
          }
          number
          onlineCourse {
            id
          }
          originalContent {
            id
          }
          person {
            id
          }
          podcast {
            id
          }
          poster {
            id
          }
          string
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "htmlDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "HtmlDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "HtmlDocument",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "panels",
            "storageKey": null,
            "args": null,
            "concreteType": "HtmlDocumentPanel",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "component",
                "storageKey": null,
                "args": null,
                "concreteType": "Component",
                "plural": false,
                "selections": (v3/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "properties",
                "storageKey": null,
                "args": null,
                "concreteType": "ComponentPropertyValue",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "article",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ArticleStory",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "book",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BookStory",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "boolean",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "dynamicList",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DynamicList",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "entity",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Entity",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "fixedList",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FixedList",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "int",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "json",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "listCollection",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ListCollection",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "newsletter",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NewsletterStory",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "number",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "onlineCourse",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OnlineCourseStory",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "originalContent",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OriginalContentStory",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "person",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Person",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "podcast",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PodcastStory",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "poster",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Poster",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "string",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "htmlDocumentPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "htmlDocumentPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "htmlDocumentPageQuery",
    "id": null,
    "text": "query htmlDocumentPageQuery(\n  $id: ID!\n) {\n  htmlDocument(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    node {\n      __typename\n      id\n      revision\n      label\n      description\n      panels {\n        id\n        label\n        component {\n          id\n        }\n        properties {\n          name\n          article {\n            id\n          }\n          book {\n            id\n          }\n          boolean\n          dynamicList {\n            id\n          }\n          entity {\n            id\n          }\n          fixedList {\n            id\n          }\n          id\n          int\n          json\n          listCollection {\n            id\n          }\n          newsletter {\n            id\n          }\n          number\n          onlineCourse {\n            id\n          }\n          originalContent {\n            id\n          }\n          person {\n            id\n          }\n          podcast {\n            id\n          }\n          poster {\n            id\n          }\n          string\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd8dd0a58aea661cb1800ae45c4349371';
export default node;
