/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-18
 * @description Implementation of validation utilities to be used in the app.
 * @filename navigation.ts
 */

export class Navigator {
  /**
   *
   *
   * @static
   * @memberof Navigator
   */
  static navigateToCMSHome() {
    window.location.assign("/cms-home");
  }

  /**
   *
   *
   * @static
   * @memberof Navigator
   */
  static navigateToAddItemsPage() {
    window.location.assign("/cms-add-item");
  }
}
