/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @description Implementation of the Component react component and declaration of its related interfaces.
 * @Date: 2018-03-19 02:23:06
 * @filename dynamic-component.tsx
 */
import { Component as GraphQlComponent } from "@radivision/graphql/lib/ts/graphql/component";
import { ComponentPropertyValue } from "@radivision/graphql/lib/ts/graphql/component-property-value";
import React from "react";
import { createFragmentContainer as _createFragmentContainer, graphql } from "react-relay";
import { RelayProps } from "../../relay/relay-props";
import { StructureUtilities } from "../../utilities/structure-utilities";
import { MAIN_COMPONENTS } from "../components-map";

/**
 * The props of the DynamicComponent component.
 *
 * @extends {RelayProps}
 * @interface DynamicComponentProps
 */
export interface DynamicComponentProps extends RelayProps {
  /**
   * The dynamic component.
   *
   * @type {ComponentWithData}
   */
  component: GraphQlComponent;

  /**
   * properties that react component needs.
   *
   * @type {ComponentPropertyValue[]}
   * @memberof DynamicComponentProps
   */
  properties?: ComponentPropertyValue[];
}

/**
 * The state of the DynamicComponent class.
 *
 * @interface DynamicComponentState
 */
interface DynamicComponentState {
  /**
   *
   * @type {{
   *     [index: string]: any;
   *   }}
   * @memberof DynamicComponentState
   */
  componentsMap: {
    [index: string]: any;
  };
}

/**
 *  A React Component that renders a Component.
 *
 * @export
 * @class Component
 * @extends {React.Component<DynamicComponentProps, DynamicComponentState>}
 */
class DynamicComponent extends React.Component<
  DynamicComponentProps,
  DynamicComponentState
> {
  constructor(props: DynamicComponentProps) {
    super(props);

    this.state = {
      componentsMap: MAIN_COMPONENTS,
    };
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactElement<any> {
    if (this.state.componentsMap[this.props.component.key]) {
      const CONTROLTAGNAME = this.state.componentsMap[this.props.component.key]
        .component;
      return (
        <CONTROLTAGNAME
          {...StructureUtilities.getComponentUtilities(
            this.props.component.properties,
            this.props.properties
          )}
          {...this.state.componentsMap[this.props.component.key].props}
          relay={this.props.relay}
        />
      );
    }
    // TODO
    return <div />;
  }
}

/**
 * Returns a relay fragment container created from the panel and a GraphQL fragment.
 */
export default _createFragmentContainer(DynamicComponent, {
  component: graphql`
    fragment dynamicComponent_component on Component {
      key
      properties {
        kind
        isRequired
        isQueryParameterName
        name
      }
    }
  `,
});
