import { graphql, GraphQLTaggedNode } from "react-relay";

export const UPDATE_MARKET_ENTRY_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateMarketEntryMutation($input: UpdateMarketEntryStoryInput!) {
    updateMarketEntryStory(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      story {
        ... on MarketEntryStory {
          __typename
          id
          revision
          landingPageUrl
          offerType
          description
          promoCode
          subTitle
          title
          label
          link
          tags {
            id
            label
          }
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                  }
                }
              }
            }
          }
          revision
          title
        }
      }
    }
  }
`;
