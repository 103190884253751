/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-19
 * @description Implementation of the GraphQL mutation to update a dynamic-list.
 * @filename update-dynamic-list
 */

import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update dynamic-list.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_DYNAMIC_LIST_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateDynamicListMutation($input: UpdateDynamicListInput!) {
    updateDynamicList(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      dynamicList {
        __typename
        id
        title
      }
    }
  }
`;
