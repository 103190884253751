/**
 * @author Ahmed Samer
 * @Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-03-19 08:22:46
 */
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import React from "react";
import {
  createRefetchContainer as _createRefetchContainer,
  graphql,
} from "react-relay";
import { REACT_USER_CONTEXT } from "../../contexts/react-user-context";
import { RelayProps } from "../../relay/relay-props";
import { LocalStorageKeys } from "../../utilities/local-storage-keys";
import { Router } from "../../utilities/router";
import { CookieConsent } from "../page-framework/cookie-consent";
import HtmlPagePanels from "./html-page-panel";
import LoginPage from "./log-in-page";
const isEqual = require("lodash.isequal");

/**
 * The props of the HtmlPage component.
 *
 * @export
 * @extends {RelayProps}
 * @interface HtmlPageProps
 */
interface HtmlPageProps extends RelayProps {
  /**
   * The alias of the page.
   *
   * @type {string}
   */
  alias?: string;

  /**
   * The set of query parameters for the page.
   *
   * @type {Object}
   */
  queryParameters?: {};

  /**
   * the payload of the current page to be rendered.
   *
   * @type {Payload<HtmlPage>}
   * @memberof HtmlPageProps
   */
  htmlDocument: any;
}

/**
 * The state of the  class.
 *
 * @interface HtmlPageState
 */
interface HtmlPageState {
  /**
   * The current alias.
   *
   * @type {string}
   */
  alias?: string;

  /**
   * the Current queryParameters
   *
   * @type {string[]}
   * @memberof AppState
   */
  queryParameters?: string[];

  /**
   * the logged-in user details
   *
   * @type {Person}
   * @memberof HtmlPageState
   */
  user: Person;
}

/**
 * A React control the renders an HTML page created from a given HTML document. The page
 * is recreated whenever the fragment (or query parameters since they reload the page)
 * changes.
 *
 * @export
 * @class Page
 * @extends {React.Component<HtmlPageProps, HtmlPageState>}
 */
class HtmlPage extends React.Component<HtmlPageProps, HtmlPageState> {
  /**
   * Constructor
   *
   * @param {HtmlPageProps} props The component props.
   */
  constructor(props: HtmlPageProps) {
    super(props);
    // this.onHashChange = this.onHashChange.bind(this);
    this.state = {
      user:
        this.props.htmlDocument &&
        this.props.htmlDocument.urlRouteByAlias &&
        this.props.htmlDocument.urlRouteByAlias.node
          ? this.props.htmlDocument.urlRouteByAlias.node.user
          : null,
    };

    this.updateUser = this.updateUser.bind(this);

    if (this.state.user && this.state.user.id) {
      localStorage.setItem(
        LocalStorageKeys.KEY_LOCAL_STORAGE_USER_ID,
        this.state.user.id
      );
    }
  }

  /**
   * Invoked by the React framework immediately after updating occurs
   */
  componentDidUpdate(): void {
    // window.addEventListener("hashchange", this.onHashChange, false);
    Router.scrollTo();
  }

  // /**
  //  * A method invoked when the page fragment changes.
  //  *
  //  * @param {HashChangeEvent} event The event fired when the hash changes.
  //  */
  // onHashChange(event: HashChangeEvent): void {
  //   const alias: string = Router.getAlias();
  //   const queryParameters: string[] = Router.getQueryParameters();
  //   let change: boolean;
  //   const userIdentity: {
  //     userIdentityId: string;
  //     provider: "linkedin" | "twitter";
  //   } = User.getUserIdentity();
  //   const userIdentityId: string = userIdentity.userIdentityId;

  //   if (alias !== this.state.alias) {
  //     change = true;
  //   } else {
  //     change = false;
  //     for (const qp of queryParameters) {
  //       if (this.state.queryParameters.indexOf(qp) < 0) {
  //         change = true;
  //         break;
  //       }
  //     }
  //   }

  //   if (change) {
  //     NProgress.configure({ showSpinner: false });
  //     NProgress.start();

  //     if (CognitoClient.isAnonymous) {
  //       queryParameters.push("public=true");
  //     } else {
  //       queryParameters.push("public=false");
  //     }

  //     this.setState(() => {
  //       return {
  //         alias,
  //         queryParameters
  //       };
  //     });

  //     this.props.relay.refetch(
  //       {
  //         alias,
  //         userIdentityId,
  //         queryParameters
  //       },
  //       null,
  //       () => {
  //         NProgress.done();
  //         if (
  //           Router.getLocation() === undefined ||
  //           Router.getLocation() === null
  //         ) {
  //           animateScroll.scrollToTop();
  //         }
  //       },
  //       { force: true } // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
  //     );
  //   } else {
  //     Router.scrollTo();
  //   }
  // }

  /**
   * update current user details
   *
   * @param {Person} user
   * @memberof HtmlPage
   */
  updateUser(user: Person): Person {
    // // console.log("[updateUser] time ", Date.now());
    // attempt to validate provided user to prevent any needless update to our user state

    if (!isEqual(user, this.state.user)) {
      // // console.log("[updateUser] updated user " , user);
      this.setState({ user });
    }
    return user;
  }

  /**
   *
   *
   * @returns {boolean}
   * @memberof App
   */
  authorizedToAccess(): boolean {
    let isAuth: boolean = false;
    isAuth =
      localStorage.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_USER_ID) !== null;

    console.log(isAuth);

    return isAuth;
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    // // console.log(this.props);

    if (
      !this.authorizedToAccess() ||
      this.props.htmlDocument.urlRouteByAlias.hasErrors
    ) {
      // window.location.href = "#ERROR";
      return <LoginPage />;
    }

    return (
      <div>
        <CookieConsent />
        {/* remove scroll to top on cms pages */}
        <REACT_USER_CONTEXT.Provider
          value={{
            user: this.state.user,
            updateUser: this.updateUser,
          }}
        >
          <HtmlPagePanels
            panels={this.props.htmlDocument.urlRouteByAlias.node.panels}
          />
        </REACT_USER_CONTEXT.Provider>
      </div>
    );
  }
}

/**
 * A relay function to create a re-fetch container.
 */
export default _createRefetchContainer(
  HtmlPage,
  {
    htmlDocument: graphql`
      fragment htmlPage_htmlDocument on Query {
        urlRouteByAlias(
          alias: $alias
          userIdentityId: $userIdentityId
          queryParameters: $queryParameters
        ) {
          hasErrors
          errors {
            created
            id
            location
            type
          }
          node {
            id
            panels {
              ...htmlPagePanels_panels
            }
            user {
              __typename
              description
              emailAddresses
              fullName
              hasPublicPage
              landingPageUrl
              id
              revision
              requests {
                ... on ClaimRequest {
                  __typename
                  id
                  created
                  expired
                  description
                  item {
                    ... on Person {
                      __typename
                      id
                    }
                    ... on Entity {
                      __typename
                      id
                    }
                  }
                }
                ... on UGCRequest {
                  __typename
                  id
                  created
                  revision
                  status
                  audioMediaAsset {
                    id
                    files {
                      id
                      name
                      heightInPixels
                      widthInPixels
                      path
                      status {
                        kind
                      }
                    }
                    state {
                      kind
                    }
                    topLevelMediaType {
                      kind
                    }
                  }
                  metaData {
                    full_name
                    title
                    company_name
                    company_url
                    term_agreement
                  }
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        mediaAsset {
                          id
                          files {
                            id
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                            label
                          }
                        }
                        kind
                      }
                    }
                  }
                  sample {
                    id
                    files {
                      id
                      name
                      heightInPixels
                      widthInPixels
                      path
                      status {
                        kind
                      }
                    }
                    state {
                      kind
                    }
                    topLevelMediaType {
                      kind
                    }
                  }
                }
              }
              isEditable
              defaultLocation {
                id
                label
              }
              locations {
                id
                label
              }
              entities {
                __typename
                name
                id
                revision
                hasPublicPage
                landingPageUrl
                description
                websiteUrl
                isEditable
                previews(kinds: [SQUARE, LOGO]) {
                  edges {
                    preview {
                      link
                      mediaAsset {
                        id
                        files {
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                        }
                      }
                      kind
                    }
                  }
                }
              }
              experience {
                edges {
                  position {
                    id
                    entity {
                      __typename
                      name
                      description
                      websiteUrl
                      landingPageUrl
                      id
                      hasPublicPage
                      isEditable
                      previews(kinds: [SQUARE, LOGO]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                    }
                  }
                }
              }
              inspirations {
                id
                inspirer {
                  ... on Entity {
                    __typename
                    id
                    name
                    landingPageUrl
                    websiteUrl
                    hasPublicPage
                    isEditable
                    previews(kinds: [SQUARE, LOGO]) {
                      edges {
                        preview {
                          link
                          mediaAsset {
                            id
                            files {
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          kind
                        }
                      }
                    }
                  }
                }
              }
              investmentsBy {
                id
                entity {
                  ... on Entity {
                    __typename
                    id
                    name
                    landingPageUrl
                    websiteUrl
                    hasPublicPage
                    isEditable
                    previews(kinds: [SQUARE, LOGO]) {
                      edges {
                        preview {
                          link
                          mediaAsset {
                            id
                            files {
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          kind
                        }
                      }
                    }
                  }
                }
                investee {
                  ... on Entity {
                    __typename
                    name
                    id
                    landingPageUrl
                    websiteUrl
                    hasPublicPage
                    isEditable
                    previews(kinds: [SQUARE, LOGO]) {
                      edges {
                        preview {
                          link
                          mediaAsset {
                            id
                            files {
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          kind
                        }
                      }
                    }
                  }
                }
              }
              follows {
                ... on Entity {
                  __typename
                  name
                  id
                  landingPageUrl
                  previews(kinds: [SQUARE, LOGO]) {
                    edges {
                      preview {
                        link
                        mediaAsset {
                          id
                          files {
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                        kind
                      }
                    }
                  }
                }
                ... on Person {
                  __typename
                  description
                  fullName
                  hasPublicPage
                  id
                  greeting
                  landingPageUrl
                  previews(kinds: [SQUARE]) {
                    edges {
                      preview {
                        link
                        mediaAsset {
                          files {
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                        kind
                      }
                    }
                  }
                }
              }
              mediaAbout {
                edges {
                  story {
                    __typename
                    ... on RadivisionPost {
                      id
                      link
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            kind
                            mediaAsset {
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                          }
                        }
                      }
                      subTitle
                      title
                      description
                      landingPageUrl
                    }
                    ... on OnlineCourseStory {
                      id
                      link
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                    ... on RadivisionPost {
                      id
                      content
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      revision
                      title
                    }
                    ... on ArticleStory {
                      description
                      id
                      link
                      publisher
                      publishedDate
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      revision
                      subTitle
                      title
                    }
                    ... on BookStory {
                      id
                      link
                      publisher
                      publishedDate
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                    ... on NewsletterStory {
                      id
                      description
                      link
                      publisher
                      publishedDate
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                    ... on PodcastStory {
                      id
                      link
                      publisher
                      publishedDate
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                    ... on OriginalContentStory {
                      id
                      previews(kinds: [SQUARE]) {
                        edges {
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                              }
                            }
                            kind
                          }
                        }
                      }
                      subTitle
                      title
                    }
                  }
                }
              }
              previews {
                edges {
                  preview {
                    link
                    mediaAsset {
                      id
                      files {
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                      }
                    }
                    kind
                  }
                }
              }
              northStar {
                __typename
                description
                id
                questions {
                  __typename
                  ... on TextQuestion {
                    content
                    id
                    response {
                      content
                      id
                    }
                  }
                }
              }
              questionnaires {
                count
                edges {
                  cursor
                  node {
                    id
                    title
                    questions {
                      __typename
                      ... on MultipleChoiceQuestion {
                        id
                        title
                        response {
                          id
                          choices {
                            acceptsText
                            id
                          }
                        }
                      }
                      ... on TextQuestion {
                        id
                        content
                        response {
                          content
                          id
                        }
                        title
                      }
                    }
                  }
                }
              }
              responsibility
              socialMediaCredentials {
                credential
                platform {
                  kind
                }
              }
              websiteUrl
            }
          }
        }
      }
    `,
  },
  graphql`
    query htmlPageRefetchQuery(
      $alias: String!
      $userIdentityId: ID
      $queryParameters: [String!]
    ) {
      ...htmlPage_htmlDocument
    }
  `
);
