/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Fixed list page component
 * @filename FixedListPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import ManageBadge from "./badge/manage-badge-panel";
import ListPanel from "./list-item-panel/list-item-panel";
import {
  FixedList as GraphqlFixedList,
  GRAPHQL_TYPE_FIXED_LIST,
  GRAPHQL_TYPE_ARTICLE_STORY,
  GRAPHQL_TYPE_BOOK_STORY,
  GRAPHQL_TYPE_NEWSLETTER_STORY,
  GRAPHQL_TYPE_PODCAST_STORY,
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  GRAPHQL_TYPE_ONLINE_COURSE_STORY,
  GRAPHQL_TYPE_ENTITY,
  GRAPHQL_TYPE_PERSON,
  GRAPHQL_TYPE_POSTER,
  GRAPHQL_TYPE_MEDIA_ASSET,
  GRAPHQL_TYPE_MEDIA_ASSET_FILE,
  PreviewKind,
} from "@radivision/graphql";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Validator } from "../../../utilities/cms-operation/validator";
import { FixedList } from "../../../utilities/fixed-list";
import ModalConductor from "./modal/modal-conductor";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import {
  FormGroupInput,
  ListPageFromInput,
  PreviewItem,
} from "../../../component-configuration/cms-pages";
import { QueryRenderer, graphql, fetchQuery } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { getCarouselItem } from "../../../utilities/general";
const UNION_BY = require("lodash.unionby");
/**
 *
 */
const SEARCH_FILTERS: { field: string; values: string[] }[] = [
  {
    field: "label",
    values: [
      GRAPHQL_TYPE_ARTICLE_STORY,
      GRAPHQL_TYPE_BOOK_STORY,
      GRAPHQL_TYPE_NEWSLETTER_STORY,
      GRAPHQL_TYPE_PODCAST_STORY,
      GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
      GRAPHQL_TYPE_ONLINE_COURSE_STORY,
      GRAPHQL_TYPE_ENTITY,
      GRAPHQL_TYPE_PERSON,
      GRAPHQL_TYPE_POSTER,
      GRAPHQL_TYPE_MEDIA_ASSET,
      GRAPHQL_TYPE_MEDIA_ASSET_FILE,
    ],
  },
];

/**
 *
 *
 */
const PAGE_SIZE = 200;

/**
 *
 *
 */
const LIST_QUERY = graphql`
  query fixedListPageQuery($id: ID!, $after: String) {
    list(id: $id) {
      hasErrors
      errors {
        id
        location
      }
      list {
        __typename
        ... on FixedList {
          id
          title
          subTitle
          description
          label
          isDeleted
          revision
          name
          items(after: $after, first: 200) {
            count
            edges {
              cursor
              listItem {
                index
                list {
                  ... on FixedList {
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              heightInPixels
                              widthInPixels
                              path
                              status {
                                kind
                              }
                            }
                            state {
                              kind
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          kind
                          link
                        }
                      }
                    }
                    name
                    label
                  }
                }
                item {
                  ... on OriginalContentStory {
                    __typename
                    description
                    durationInSeconds
                    externalId
                    id
                    mediaItem {
                      id
                      files {
                        id
                        heightInPixels
                        widthInPixels
                        name
                        path
                      }
                    }
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          mediaAsset {
                            id
                            files {
                              id
                              heightInPixels
                              widthInPixels
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          link
                          kind
                        }
                      }
                    }
                    subTitle
                    title
                  }
                  ... on ArticleStory {
                    __typename
                    description
                    id
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          mediaAsset {
                            id
                            files {
                              id
                              heightInPixels
                              widthInPixels
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          link
                          kind
                        }
                      }
                    }
                    subTitle
                    title
                  }
                  ... on BookStory {
                    __typename
                    description
                    id
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          mediaAsset {
                            id
                            files {
                              id
                              heightInPixels
                              widthInPixels
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          link
                          kind
                        }
                      }
                    }
                    subTitle
                    title
                  }
                  ... on NewsletterStory {
                    __typename
                    description
                    id
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          mediaAsset {
                            id
                            files {
                              id
                              heightInPixels
                              widthInPixels
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          link
                          kind
                        }
                      }
                    }
                    subTitle
                    title
                  }
                  ... on PodcastStory {
                    __typename
                    description
                    id
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          mediaAsset {
                            id
                            files {
                              id
                              heightInPixels
                              widthInPixels
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          link
                          kind
                        }
                      }
                    }
                    subTitle
                    title
                  }
                  ... on OnlineCourseStory {
                    __typename
                    description
                    id
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          mediaAsset {
                            id
                            files {
                              id
                              heightInPixels
                              widthInPixels
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                          link
                          kind
                        }
                      }
                    }
                    subTitle
                    title
                  }
                  ... on Poster {
                    __typename
                    description
                    id
                    label
                    isNew
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              heightInPixels
                              widthInPixels
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                        }
                      }
                    }
                    subTitle
                    title
                  }
                  ... on Person {
                    __typename
                    fullName
                    id
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              heightInPixels
                              widthInPixels
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                        }
                      }
                    }
                    landingPageUrl
                  }
                  ... on Entity {
                    __typename
                    name
                    id
                    landingPageUrl
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              heightInPixels
                              widthInPixels
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on Event {
                    __typename
                    name
                    id
                    url
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              heightInPixels
                              widthInPixels
                              path
                            }
                            topLevelMediaType {
                              kind
                            }
                          }
                        }
                      }
                    }
                    summary
                    description
                    status
                    end
                    start
                    externalId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * the props of page
 *
 * @export
 * @interface FixedListPageProps
 */
export interface FixedListPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof FixedListPageProps
   */
  fixedList?: { id: string };

  /**
   *
   *
   * @type {string}
   * @memberof FixedListPageProps
   */
  graphFixedList?: GraphqlFixedList;
}

/**
 * the state of page
 *
 * @interface FixedListPageState
 */
export interface FixedListPageState {
  /**
   *
   *
   * @type {ListPosterPageFormInput}
   * @memberof posterPageState
   */
  formInput: ListPageFromInput;

  /**
   *
   *
   * @type {*}
   * @memberof BookPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof posterPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof posterPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof posterPageState
   */
  isLoading: boolean;

  /**
   *
   *
   * @type {number}
   * @memberof FixedListPageState
   */
  itemPaging?: number;

  /**
   *
   *
   * @type {boolean}
   * @memberof FixedListPageState
   */
  paginating: boolean;
}

interface queryInput {
  /**
   * string representing person or entity id
   *
   * @type {string}
   * @memberof PostQueryInput
   */
  id: string;
  /**
   *
   *
   * @type {string}
   * @memberof PostQueryInput
   */
  after?: string;
  /**
   *
   *
   * @type {string}
   * @memberof PostQueryInput
   */
  before?: string;

  /**
   *
   *
   * @type {number}
   * @memberof PostQueryInput
   */
  first?: number;

  /**
   *
   *
   * @type {number}
   * @memberof PostQueryInput
   */
  last?: number;
}
/**
 *
 *
 * @export
 * @class FixedListPage
 * @extends {React.Component<FixedListPageProps, FixedListPageState>}
 */
export class FixedListPage extends React.Component<
  FixedListPageProps,
  FixedListPageState
> {
  /**
   * References to the Badges component in the FixedListPage.
   *
   * @type {*}
   * @memberof FixedListPage
   */
  badgePanelRef: any;

  /**
   * References to the Lists component in the FixedListPage.
   *
   * @type {*}
   * @memberof FixedListPage
   */
  itemPanelRef: any;
  /**
   * References to the Previews component in the FixedListPage.
   *
   * @type {*}
   * @memberof FixedListPage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the FixedListPage.
   *
   * @type {*}
   * @memberof FixedListPage
   */
  tagsRef: any;

  /**
   *Creates an instance of FixedListPage.
   * @param {FixedListPageProps} props
   * @memberof FixedListPage
   */
  constructor(props: FixedListPageProps) {
    super(props);

    //... bind needed function to class

    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleNameChanges = this.handleNameChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.paginateItems = this.paginateItems.bind(this);

    // ... initialize required components references

    this.badgePanelRef = React.createRef();
    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();
    this.itemPanelRef = React.createRef();

    //... initial component state

    this.state = this.handleComponentStateInitialize(this.props.graphFixedList);
  }

  /**
   *
   *
   * @memberof FixedListPage
   */
  componentDidMount() {
    if (this.state.formGroupInput && this.state.formGroupInput.mediaItemProps) {

      this.toggleLoader();

      const recursiveCall = (index) => {
        const id = this.state.formGroupInput.mediaItemProps[
          this.state.formGroupInput.mediaItemProps.length - 1
        ].id;
        return this.paginateItems(id).then((itemsCount) => {
          if (itemsCount > 0) {
            return Promise.resolve(recursiveCall(itemsCount));
          }
          return Promise.resolve();
        });
      };

      recursiveCall(0).then(() => {
        this.toggleLoader(false);
      });

      return;
    }
  }

  /**
   *
   *
   * @memberof FixedListPage
   */
  componentDidUpdate() {
    let promise: Promise<any>;

    if (this.state.formGroupInput && this.state.formGroupInput.mediaItemProps) {
      if (this.state.itemPaging === PAGE_SIZE && !this.state.paginating) {
        this.setState({ paginating: true });

        promise = this.paginateItems(
          this.state.formGroupInput.mediaItemProps[
            this.state.formGroupInput.mediaItemProps.length - 1
          ].id
        );

        promise = promise.then((itemsCount: number) => {
          this.setState({ itemPaging: itemsCount, paginating: true });
        });
      } else {
        promise = Promise.resolve();
      }
    }
    return promise;
  }

  /**
   *
   *
   * @param {GraphqlFixedList} fixedListProps
   * @returns {posterPageState}
   * @memberof posterPage
   */
  handleComponentStateInitialize(
    fixedListProps: GraphqlFixedList
  ): FixedListPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState,
    };

    let formInput: ListPageFromInput = {
      id: undefined,
      title: "",
      label: "",
      subTitle: "",
      summary: "",
    };

    let state: FixedListPageState = {
      modal,
      formInput,
      isLoading: false,
      paginating: false,
      errorMessage: new Map(),
      formGroupInput: {},
    };

    if (fixedListProps !== null && fixedListProps !== undefined) {
      if (fixedListProps.id) {
        state.formInput.id = fixedListProps.id;
      }

      if (fixedListProps.label) {
        state.formInput.label = fixedListProps.label;
      }

      if (fixedListProps.title) {
        state.formInput.title = fixedListProps.title;
      }
      if (fixedListProps.name) {
        state.formInput.name = fixedListProps.name;
      }

      if (fixedListProps.subTitle) {
        state.formInput.subTitle = fixedListProps.subTitle;
      }

      if (fixedListProps.description) {
        state.formInput.summary = fixedListProps.description;
      }

      if (fixedListProps.isDeleted) {
        state.formInput.isDeleted = fixedListProps.isDeleted;
      }

      if (fixedListProps.revision) {
        state.formInput.revision = fixedListProps.revision;
      }

      // ........................
      // initialize form group inputs
      // ...........................

      if (
        fixedListProps.tags !== null &&
        fixedListProps.tags !== undefined &&
        fixedListProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = fixedListProps.tags.map(
          (tag) => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        fixedListProps.previews !== null &&
        fixedListProps.previews !== undefined &&
        fixedListProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = fixedListProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset",
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link,
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`,
                  },
                };
                break;

              default:
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }

      if (
        fixedListProps.items !== null &&
        fixedListProps.items !== undefined &&
        fixedListProps.items.count > 0
      ) {
        state.formGroupInput.mediaItemProps = fixedListProps.items.edges.map(
          (listItem: any) => {
            let mediaAboutItem: CarouselItem = getCarouselItem(
              listItem.listItem.item,
              fixedListProps,
              0,
              {
                containerWidthRatio: 6 / 12,
                numberOfItems: 6,
              },
              PreviewKind.SQUARE
            );

            return mediaAboutItem;
          }
        );
      }
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof FixedListPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Fixed List</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Name</label>
                  <input
                    type="text"
                    name="Name"
                    className={`form-control ${
                      this.state.errorMessage.get("Name") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.name}
                    onChange={this.handleNameChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    name="Subtitle"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.formInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>

            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="form-control readonly"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="form-control readonly"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.formInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Summary</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.summary}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="isdeleted"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>
            <div className="Page--Badge">
              <label>Badges</label>
              <ManageBadge ref={this.badgePanelRef} />
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <ManagePreviewPanel
                ref={this.previewPanelRef}
                previewsList={this.state.formGroupInput.previewsProps}
              />
            </div>
            <div className="Page--Lists">
              <label>List Items</label>
              <div className="Page--Badge">
                <div className="Page--Lists">
                  <label>Items</label>
                  <ListPanel
                    items={this.state.formGroupInput.mediaItemProps}
                    ref={this.itemPanelRef}
                    readonly={false}
                    filter={SEARCH_FILTERS}
                  />
                </div>
              </div>
            </div>
          </div>
          <Loader isActive={this.state.isLoading} />
          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction,
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              cloneable: () => {},
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                },
              },
              saveable: this.handleSaveAction,
            }}
          />
        </div>
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @memberof FixedListPage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.title = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  sub-title state
   *
   * @memberof FixedListPage
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.subTitle = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof FixedListPage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.summary = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof FixedListPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof FixedListPage
   */
  handleNameChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.name = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  is deleted state
   *
   * @memberof FixedListPage
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: ListPageFromInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    // // console\.log("[handleIsDeletedChanges] is poster deleted", isDeleted);
    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling submitting new
   *
   * @memberof FixedListPage
   */
  handleSaveAction(): void {
    let listInput: FixedListPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      listInput.formInput.tags = this.tagsRef.current.extractTagsString();
      // extract previews list
      listInput.formInput.previews = this.previewPanelRef.current.extractPreviews();
      // extract item re-order
      listInput.formInput.items = this.itemPanelRef.current.extractListItems();

      // validate errors
      errorMessage = Validator.validateListInput(listInput.formInput);

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (
          this.props.fixedList !== null &&
          this.props.fixedList !== undefined
        ) {
          internalPromise = FixedList.updateFixedList(listInput.formInput).then(
            (results: any) => {
              // ... handle mutation success
              modal.modalName = "ConfirmModal";
              modal.modalBody = `${CMS_PAGE_CONSTANT["FIXED_LIST"].message.confirmation.update}, list-id : ${results.id}`;
              modal.modalTile = "Action Complete";
              this.revertPageInput(results);
            }
          );
        } else {
          internalPromise = FixedList.addFixedList(listInput.formInput).then(
            (results: any) => {
              // ... handle mutation success
              // console\.log(results);
              modal.modalName = "ConfirmModal";
              modal.modalBody = `${CMS_PAGE_CONSTANT["FIXED_LIST"].message.confirmation.add}, list-id : ${results.id}`;
              modal.modalTile = "Action Complete";
              this.revertPageInput();
            }
          );
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["FIXED_LIST"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch((err) => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false,
      });
    });
  }

  /**
   *
   *
   * @memberof FixedListPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof ArticlePage
   */
  revertPageInput(input?: GraphqlFixedList): void {
    let state: FixedListPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.graphFixedList
    );
    this.setState(state);

    //... reset children state
    this.tagsRef.current.resetComponent();
    this.itemPanelRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof ArticlePage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }

  /**
   *
   *
   * @param {string} lastItemId
   * @memberof FixedListPage
   */
  paginateItems(lastItemId: string) {
    let promise: Promise<any> = Promise.resolve();
    let input: queryInput = {
      id: this.props.fixedList.id,
      after: lastItemId,
    };
    let formGroupInput = this.state.formGroupInput;

    promise = promise.then(() => {

      if (input.id === null || input.id === undefined) {
        throw new Error("invalid list id");
      }

      if (input.after === null || input.after === undefined) {
        throw new Error("invalid filters input");
      }

      return fetchQuery(ENVIRONMENT, LIST_QUERY, input);
    });

    promise = promise.then((queryRes) => {
      return queryRes.list.hasErrors === false
        ? Promise.resolve(queryRes.list.list)
        : Promise.reject(queryRes.list.errors);
    });

    promise = promise.then((list: GraphqlFixedList) => {
      formGroupInput.mediaItemProps = UNION_BY(
        formGroupInput.mediaItemProps,
        formGroupInput.mediaItemProps.concat(
          list.items.edges.map((listItem: any) => {
            let mediaAboutItem: CarouselItem = getCarouselItem(
              listItem.listItem.item,
              list,
              0,
              {
                containerWidthRatio: 6 / 12,
                numberOfItems: 6,
              },
              PreviewKind.SQUARE
            );
            return mediaAboutItem;
          })
        ),
        "id"
      );
      return Promise.resolve(list.items.edges.length);
    });

    promise = promise.catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });

    promise = promise.finally(() => {
    //  this.toggleLoader(false);
      this.setState({ formGroupInput });
    });

    return promise;
  }
}

/**
 *
 *
 * @export
 * @class FixedListPageContainer
 * @extends {React.Component<FixedListPageProps, FixedListPageState>}
 */
export class FixedListPageContainer extends React.Component<
  FixedListPageProps,
  FixedListPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let listId: string;

    // console\.log("[FixedListPageContainer] provided props", this.props);

    if (this.props.fixedList === null || this.props.fixedList === undefined) {
      node = <FixedListPage />;
    } else {
      listId = this.props.fixedList.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={LIST_QUERY}
          variables={{
            id: listId,
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.list.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.list.list.__typename !== GRAPHQL_TYPE_FIXED_LIST
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid List id, {listId} belongs to type{" "}
                      {props.list.list.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.list.list) {
                let pageProps: FixedListPageProps = {
                  ...this.props,
                  graphFixedList: props.list.list,
                };
                return <FixedListPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
