/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-08-05
 * @description Implementation of dynamicList utilities to be used in the app.
 * @filename list-collection.ts
 */

import { CreateListCollectionInput, UpdateListCollectionInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { ListCollectionPageFromInput } from "../component-configuration/cms-pages";
import { CREATE_LIST_COLLECTION_GRAPHQL_MUTATION } from "../relay/mutations/create-list-collection";
import { UPDATE_LIST_COLLECTION_GRAPHQL_MUTATION } from "../relay/mutations/update-list-collection";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { getClientMutationId } from "./general";
import { commitMutation } from "./relay";

/**
 *
 *
 * @export
 * @class FixedList
 */
export class ListCollection {
  /**
   * Returns a promise to add new dynamicList to database.
   *
   * @param {ListPageFromInput} listCollectionInput create new fixedList input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new dynamicList mutation .
   */
  static addListCollection(
    listCollectionInput: ListCollectionPageFromInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert dynamicList input to  CreateListCollectionInput
    let createListCollectionInput: CreateListCollectionInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    // initialize create dynamicList graphQl input
    createListCollectionInput = {
      clientMutationId: getClientMutationId(),
      previews: listCollectionInput.previews,
      description:
        listCollectionInput.summary !== undefined &&
        listCollectionInput.summary !== null &&
        listCollectionInput.summary.length > 0
          ? listCollectionInput.summary.trim()
          : undefined,
      tags:
        listCollectionInput.tags && listCollectionInput.tags.length > 0
          ? listCollectionInput.tags
          : undefined,
      label:
        listCollectionInput.label !== undefined &&
        listCollectionInput.label !== null &&
        listCollectionInput.label.length > 0
          ? listCollectionInput.label.trim()
          : undefined,
      title:
        listCollectionInput.title !== undefined &&
        listCollectionInput.title !== null &&
        listCollectionInput.title.length > 0
          ? listCollectionInput.title.trim()
          : undefined,
      name:
        listCollectionInput.name !== undefined &&
        listCollectionInput.name !== null &&
        listCollectionInput.name.length > 0
          ? listCollectionInput.name.trim()
          : undefined,
      subTitle:
        listCollectionInput.subTitle !== undefined &&
        listCollectionInput.subTitle !== null &&
        listCollectionInput.subTitle.length > 0
          ? listCollectionInput.subTitle.trim()
          : undefined,
      lists:
        listCollectionInput.lists && listCollectionInput.lists.length > 0
          ? listCollectionInput.lists
          : undefined
    };

    // initialize mutation configuration
    config = {
      mutation: CREATE_LIST_COLLECTION_GRAPHQL_MUTATION,
      variables: {
        input: createListCollectionInput
      }
    };

    // attempt to commit acutal graph mutation
    promise = commitMutation(environment, config);

    promise = promise.then((mutationResults: any) => {
      return mutationResults.createListCollection.hasErrors === false
        ? Promise.resolve(mutationResults.createListCollection.listCollection)
        : Promise.reject(mutationResults.createListCollection.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {ListPageFromInput} listCollectionInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof FixedList
   */
  static updateListCollection(
    listCollectionInput: ListCollectionPageFromInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // // ... convert dynamicList input to  CreatelistCollectionInput
    let updateListCollectionInput: UpdateListCollectionInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log(

    // // initialize create dynamicList graphQl input
    updateListCollectionInput = {
      id: listCollectionInput.id,
      revision: listCollectionInput.revision,
      clientMutationId: getClientMutationId(),
      previews: listCollectionInput.previews,
      description:
        listCollectionInput.summary !== undefined &&
        listCollectionInput.summary !== null &&
        listCollectionInput.summary.length > 0
          ? listCollectionInput.summary.trim()
          : undefined,
      tags:
        listCollectionInput.tags && listCollectionInput.tags.length > 0
          ? listCollectionInput.tags
          : undefined,
      label:
        listCollectionInput.label !== undefined &&
        listCollectionInput.label !== null &&
        listCollectionInput.label.length > 0
          ? listCollectionInput.label.trim()
          : undefined,
      title:
        listCollectionInput.title !== undefined &&
        listCollectionInput.title !== null &&
        listCollectionInput.title.length > 0
          ? listCollectionInput.title.trim()
          : undefined,
      name:
        listCollectionInput.name !== undefined &&
        listCollectionInput.name !== null &&
        listCollectionInput.name.length > 0
          ? listCollectionInput.name.trim()
          : undefined,
      subTitle:
        listCollectionInput.subTitle !== undefined &&
        listCollectionInput.subTitle !== null &&
        listCollectionInput.subTitle.length > 0
          ? listCollectionInput.subTitle.trim()
          : undefined,
      lists:
        listCollectionInput.lists && listCollectionInput.lists.length > 0
          ? listCollectionInput.lists
          : undefined
    };

    // initialize mutation configuration
    config = {
      mutation: UPDATE_LIST_COLLECTION_GRAPHQL_MUTATION,
      variables: {
        input: updateListCollectionInput
      }
    };
    // attempt to commit acutal graph mutation
    promise = commitMutation(environment, config);

    promise = promise.then((mutationResults: any) => {
      return mutationResults.updateListCollection.hasErrors === false
        ? Promise.resolve(mutationResults.updateListCollection.listCollection)
        : Promise.reject(mutationResults.updateListCollection.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {ListPageFromInput} listCollectionInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof FixedList
   */
  static deleteFixedList(
    listCollectionInput: ListCollectionPageFromInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return;
  }
}
