/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-02-12
 * @description Implementation of the GraphQL query to retrieve the social media feed data.
 * @filename dashboard-query.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the current entity posts.
 *
 * @type {GraphQLTaggedNode}
 */
export const DASHBOARD_REPORT_QUERY: GraphQLTaggedNode = graphql`
  query dashboardReportQuery {
    dashboardReport {
      errors {
        id
        location
      }
      hasErrors
      node {
        googleAnalytics {
          columns
          value
          title
        }
      }
    }
  }
`;
