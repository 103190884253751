/**
 * @author Linkon Islam
 * @date   2019-01-18
 * @description person page component
 * @filename personPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import {
  Person as GraphQlPerson,
  GRAPHQL_TYPE_PERSON,
  PreviewKind,
  SocialMediaPlatformKind,
  TopLevelMediaKind,
  Entity,
  GRAPHQL_TYPE_ENTITY,
} from "@radivision/graphql";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Validator } from "../../../utilities/cms-operation/validator";
import { Person } from "../../../utilities/cms-operation/person";
import ModalConductor from "./modal/modal-conductor";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import {
  FormGroupInput,
  PersonPageFormInput,
  PreviewItem,
} from "../../../component-configuration/cms-pages";
import ManageMediaPanel from "./media/manage-media-panel";
import { ImageHelper } from "../../../utilities/image-helper";

/**
 *
 */
 const SEARCH_FILTERS_ENTITIES: { field: string; values: string[] }[] = [
  {
    field: "label",
    values: [

      GRAPHQL_TYPE_ENTITY,
    ],
  },
];

/**
 *
 * @export
 * @interface PersonPageProps
 */
export interface PersonPageProps {
  /**
   *
   * @type {string}
   * @memberof PersonPageProps
   */
  person?: { id?: string };

  /**
   *
   *
   * @type {GraphQlPerson}
   * @memberof PersonPageProps
   */
  personGraphql?: GraphQlPerson;
}

/**
 *
 *
 * @interface PersonPageState
 */
export interface PersonPageState {
  /**
   *
   *
   * @type {FormGroupInput}
   * @memberof PersonPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   *
   * @type {PersonPageFormInput}
   * @memberof PersonPageState
   */
  formInput: PersonPageFormInput;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof OriginalContentPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof OriginalContentPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {boolean}
   * @memberof PersonPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class PersonPage
 * @extends {React.Component<PersonPageProps, PersonPageState>}
 */
export class PersonPage extends React.Component<
  PersonPageProps,
  PersonPageState
> {
  /**
   * References to the preview component in the OriginalContentPage.
   *
   * @type {*}
   * @memberof OriginalContentPage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the OriginalContentPage.
   *
   * @type {*}
   * @memberof OriginalContentPage
   */
  tagsRef: any;

  /**
   * Ref to the search component
   *
   * @type {React.RefObject<any>}
   */
  searchComponentRef: React.RefObject<any>;

  /**
   *
   *
   * @type {*}
   * @memberof PersonPage
   */
  mediaAboutRef: any;


   /**
   *
   *
   * @type {*}
   * @memberof PersonPage
   */
    entityRef: any;

  /**
   *Creates an instance of PersonPage.
   * @param {PersonPageProps} props
   * @memberof PersonPage
   */
  constructor(props: PersonPageProps) {
    super(props);

    //... bind needed function to class
    this.handleFacebookIdChanges = this.handleFacebookIdChanges.bind(this);
    this.handleUserPoolIdChanges = this.handleUserPoolIdChanges.bind(this);
    this.handleTwitterIdChanges = this.handleTwitterIdChanges.bind(this);
    this.handleLinkedInIdChanges = this.handleLinkedInIdChanges.bind(this);
    this.handleInstagramIdChanges = this.handleInstagramIdChanges.bind(this);
    this.handleNameChanges = this.handleNameChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleGoogleUserIdChanges = this.handleGoogleUserIdChanges.bind(this);
    this.handleFacebookUserIdChanges = this.handleFacebookUserIdChanges.bind(
      this
    );
    this.handleContentModerationChange = this.handleContentModerationChange.bind(
      this
    );
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.handleAddMediaAboutChanges = this.handleAddMediaAboutChanges.bind(
      this
    );

    this.handleResponsibilityChanges = this.handleResponsibilityChanges.bind(
      this
    );

    this.handleLocationChanges = this.handleLocationChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    //... initialize required components references

    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();
    this.searchComponentRef = React.createRef();
    this.mediaAboutRef = React.createRef();
    this.entityRef   = React.createRef();
    //... initial component state
    this.state = this.handleComponentStateInitialize(this.props.personGraphql);
  }

  /**
   *
   *
   * @param {GraphQlPerson} personProps
   * @returns {PersonPageState}
   * @memberof PersonPage
   */
  handleComponentStateInitialize(personProps: GraphQlPerson): PersonPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState,
    };

    let formInput: PersonPageFormInput = {
      id: undefined,
      cognitoUserIDs: {
        FacebookUserPoolID: "",
        GoogleUserPoolID: "",
        UserPoolID: "",
      },
      fullName: "",
      isDeleted: false,
      socialMediaProfiles: {
        FacebookID: "",
        InstagramID: "",
        LinkedInID: "",
        TwitterID: "",
      },
      responsibility: "",
      location: "",
      contentModerationRole: undefined,
      mediaAbout: [],
    };

    let state: PersonPageState = {
      modal,
      errorMessage: new Map(),
      isLoading: false,
      formInput,
      formGroupInput: {
        previewsProps: [],
        tagsProps: [],
      },
    };

    if (personProps !== null && personProps !== undefined) {
      //  .. initialize person id
      if (personProps.id) {
        state.formInput.id = personProps.id;
      }

      //  .. initialize name input
      if (personProps.fullName) {
        state.formInput.fullName = personProps.fullName;
      }

      //  .. initialize responsibility input

      if (personProps.responsibility) {
        state.formInput.responsibility = personProps.responsibility;
      }

      //  .. initialize is deleted input

      if (personProps.isDeleted) {
        state.formInput.isDeleted = personProps.isDeleted;
      }

      if (personProps.revision) {
        state.formInput.revision = personProps.revision;
      }

      // .. initialize person social media credentials

      if (
        personProps.socialMediaCredentials &&
        personProps.socialMediaCredentials.length > 0
      ) {
        personProps.socialMediaCredentials.map((credentials, index) => {
          switch (credentials.platform.kind) {
            case SocialMediaPlatformKind.FACEBOOK:
              state.formInput.socialMediaProfiles.FacebookID =
                credentials.credential !== null &&
                credentials.credential !== undefined
                  ? credentials.credential
                  : "";
              break;

            case SocialMediaPlatformKind.TWITTER:
              state.formInput.socialMediaProfiles.TwitterID =
                credentials.credential !== null &&
                credentials.credential !== undefined
                  ? credentials.credential
                  : "";
              break;

            case SocialMediaPlatformKind.INSTAGRAM:
              state.formInput.socialMediaProfiles.InstagramID =
                credentials.credential !== null &&
                credentials.credential !== undefined
                  ? credentials.credential
                  : "";
              break;

            case SocialMediaPlatformKind.LINKED_IN:
              state.formInput.socialMediaProfiles.LinkedInID =
                credentials.credential !== null &&
                credentials.credential !== undefined
                  ? credentials.credential
                  : "";
              break;
          }
        });
      }

      // initialize form group inputs

      if (
        personProps.tags !== null &&
        personProps.tags !== undefined &&
        personProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = personProps.tags.map(
          (tag) => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        personProps.previews !== null &&
        personProps.previews !== undefined &&
        personProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = personProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset",
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link,
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`,
                  },
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }

      if (
        personProps.mediaAbout !== null &&
        personProps.mediaAbout !== undefined &&
        personProps.mediaAbout.count > 0
      ) {
        state.formGroupInput.mediaItemProps = personProps.mediaAbout.edges.map(
          (mediaItem: any) => {
            let mediaAboutItem: CarouselItem = {
              id: mediaItem.story.id,
              title: mediaItem.story.title,
              publishDate: mediaItem.story.publishDate,
              publisher: mediaItem.story.publisher,
              previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: mediaItem.story.previews,
                  previewKind: PreviewKind.SQUARE,
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  type: mediaItem.story.__typename,
                },
                desiredDimensions: {
                  containerWidthRatio: 6 / 12,
                  numberOfItems: 6,
                },
                isSquareImage: true,
                revision: mediaItem.story.revision,
              }),
              type: mediaItem.story.__typename,
            };

            return mediaAboutItem;
          }
        );
      }

      if (
        personProps.entities !== null &&
        personProps.entities !== undefined &&
        personProps.entities.length > 0
      ) {
        state.formGroupInput.entities = personProps?.entities?.map(
          (entity: Entity) => {
            let mediaAboutItem: CarouselItem = {
              id: entity.id,
              title: entity.name,
              previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: entity.previews,
                  previewKind: PreviewKind.LOGO || PreviewKind.SQUARE,
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  type: entity.__typename,
                },
                desiredDimensions: {
                  containerWidthRatio: 6 / 12,
                  numberOfItems: 6,
                },
                isSquareImage: true,
                revision: entity.revision,
              }),
              type: entity.__typename,
            };

            return mediaAboutItem;
          }
        );
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @returns
   * @memberof PersonPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Person</h2>
            <div className="Required">
              <p>*Required</p>
            </div>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={this.state.formInput.fullName}
                    onChange={this.handleNameChanges}
                    className="form-control"
                  />
                </div>

                <div className="Page-Field">
                  <label>Responsibility</label>
                  <input
                    type="text"
                    name="responsibility"
                    value={this.state.formInput.responsibility}
                    onChange={this.handleResponsibilityChanges}
                    className="form-control"
                  />
                </div>

                <div className="Page-Field">
                  <label>Location</label>
                  <input
                    type="text"
                    name="location"
                    value={this.state.formInput.location}
                    onChange={this.handleLocationChanges}
                    className="form-control"
                  />
                </div>

                <div className="Page--Time">
                  <div className="Page-Field">
                    <label>Created</label>
                    <input
                      className="readonly form-control"
                      id="party"
                      type="datetime"
                      name="created"
                      value={currentTime}
                      readOnly={true}
                    />
                  </div>
                  <div className="Page-Field">
                    <label>Last Modified</label>
                    <input
                      className="readonly form-control"
                      id="party"
                      type="datetime"
                      name="lastmodified"
                      value={currentTime}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Publisher Section */}
            <div className="Page--Publisher">
              <label>Content Management Role</label>
              <input type="checkbox" />
              <select
                name="role"
                id=""
                onChange={this.handleContentModerationChange}
                className="form-control"
              >
                <option value="Operator">Operator</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
            {/* created and modified time */}

            <div className="Page--Time">
              <div className="Page-Field">
                <label>AWS Cognito User Pool ID</label>
                <input
                  id="party"
                  type="text"
                  name="cognito"
                  value={this.state.formInput.cognitoUserIDs.UserPoolID}
                  onChange={this.handleUserPoolIdChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>AWS Cognito Facebook User Pool ID</label>
                <input
                  id="party"
                  type="text"
                  name="fbpool"
                  value={this.state.formInput.cognitoUserIDs.FacebookUserPoolID}
                  onChange={this.handleFacebookUserIdChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>AWS Cognito Google User Pool ID</label>
                <input
                  id="party"
                  type="text"
                  name="gpool"
                  value={this.state.formInput.cognitoUserIDs.GoogleUserPoolID}
                  onChange={this.handleGoogleUserIdChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>Facebook ID</label>
                <input
                  id="party"
                  type="text"
                  name="fb"
                  value={this.state.formInput.socialMediaProfiles.FacebookID}
                  onChange={this.handleFacebookIdChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>Instagram ID</label>
                <input
                  id="party"
                  type="text"
                  name="instra"
                  value={this.state.formInput.socialMediaProfiles.InstagramID}
                  className="form-control"
                  onChange={this.handleInstagramIdChanges}
                />
              </div>
              <div className="Page-Field">
                <label>LinkedIn ID</label>
                <input
                  id="party"
                  type="text"
                  name="link"
                  value={this.state.formInput.socialMediaProfiles.LinkedInID}
                  onChange={this.handleLinkedInIdChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>Twitter ID</label>
                <input
                  id="party"
                  type="text"
                  name="tw"
                  value={this.state.formInput.socialMediaProfiles.TwitterID}
                  onChange={this.handleTwitterIdChanges}
                  className="form-control"
                />
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="isdeleted"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>

            <div className="Page-Field">
              <label>
                Media About
                <br />
              </label>
              <div className="Page--Badge">
                <ManageMediaPanel
                  ref={this.mediaAboutRef}
                  mediaItems={this.state.formGroupInput.mediaItemProps}
                />
              </div>
            </div>

            <div className="Page-Field">
              <label>
                Companies
                <br />
              </label>
              <div className="Page--Badge">
                <ManageMediaPanel
                  ref={this.entityRef}
                  mediaItems={this.state?.formGroupInput?.entities}
                  filter={SEARCH_FILTERS_ENTITIES}
                />
              </div>
            </div>
            {/* tag section */}
            <div className="Page-Field">
              <label>Tags</label>

              <div className="Page--Tag">
                <Tag
                  ref={this.tagsRef}
                  tagsList={this.state.formGroupInput.tagsProps}
                />
              </div>
            </div>

            <div className="Page-Field">
              {/* preview section */}
              <label>Previews</label>

              <div className="Page--Preview">
                <ManagePreviewPanel
                  ref={this.previewPanelRef}
                  previewsList={this.state.formGroupInput.previewsProps}
                />
              </div>
            </div>
          </div>
          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction,
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                },
              },
              saveable: this.handleSaveAction,
            }}
          />
        </div>
        <Loader isActive={this.state.isLoading} />
      </div>
    );
  }

  /**
   *
   *
   * @memberof PodcastPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof PersonPage
   */
  revertPageInput(input?: GraphQlPerson): void {
    let state: PersonPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.personGraphql
    );

    this.setState(state);

    this.tagsRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.mediaAboutRef.current.resetComponent();
    this.entityRef.current.resetComponent();

  }

  /**
   * Function Responsible for handling changes in article summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleNameChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleNameChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.fullName = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleResponsibilityChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleResponsibilityChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.responsibility = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleLocationChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleResponsibilityChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.location = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {*} changeEvent
   * @memberof PersonPage
   */
  handleContentModerationChange(changeEvent: any) {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let role: string = changeEvent.target.value;
    // // console\.log("[handleNameChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();

    formInput.contentModerationRole = role;
    // ... assign value if valid
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleUserPoolIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleUserPoolIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.cognitoUserIDs.UserPoolID = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleFacebookUserIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleFacebookUserIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.cognitoUserIDs.FacebookUserPoolID = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleGoogleUserIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleGoogleUserIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.cognitoUserIDs.GoogleUserPoolID = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleFacebookIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleFacebookIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.socialMediaProfiles.FacebookID = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleInstagramIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleInstagramIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.socialMediaProfiles.InstagramID = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleLinkedInIdChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleLinkedInIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.socialMediaProfiles.LinkedInID = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof PersonPage
   */
  handleTwitterIdChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTwitterIdChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.socialMediaProfiles.TwitterID = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article is deleted state
   *
   * @memberof PersonPage
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: PersonPageFormInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    // // console\.log("[handleIsDeletedChanges] is article deleted", isDeleted);

    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {CarouselItem} suggest
   * @memberof PersonPage
   */
  handleAddMediaAboutChanges(suggestion: CarouselItem): void {
    // ... define need variables
    let formInput: PersonPageFormInput = this.state.formInput;
    // // console\.log(suggestion);

    // ... validate if suggestion is valid media item
    if (suggestion !== undefined && suggestion !== null) {
      if (suggestion.id) {
        formInput.mediaAbout.push(suggestion.id);
      }
    }

    this.resetModalState();
    this.setState({ formInput });
  }

  /**
   *
   *
   * @memberof PersonPage
   */
  handleSaveAction(): Promise<any> {
    let personInput: PersonPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      personInput.formInput.tags = this.tagsRef.current.extractTagsString();
      // extract previews list
      personInput.formInput.previews = this.previewPanelRef.current.extractPreviews();
      // extract media about
      personInput.formInput.mediaAbout = this.mediaAboutRef.current.extractMediaItem();

      personInput.formInput.entities = this.entityRef.current.extractMediaItem();

      // validate errors
      errorMessage = Validator.validateCreatePersonInput(personInput.formInput);

      // console\.log("[handleAddPerson] errorMessage ", errorMessage);
      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      if (errorMessage.size < 1) {
        // console\.log("[handleAddArticle] errorMessage ", errorMessage);

        // ... provide validated input to  utility
        if (
          this.props.personGraphql !== null &&
          this.props.personGraphql !== undefined
        ) {
          internalPromise =
            personInput.formInput.isDeleted === true
              ? Person.deletePerson(personInput.formInput)
              : Person.updatePerson(personInput.formInput);
          internalPromise = internalPromise.then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${
              CMS_PAGE_CONSTANT["PERSON"].message.confirmation.update
            }${results && results.id ? `,person : ${results.id}` : ""}`;

            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Person.addPerson(personInput.formInput).then(
            (results: any) => {
              // ... handle mutation success
              // console\.log(results);
              modal.modalName = "ConfirmModal";

              modal.modalBody = `${CMS_PAGE_CONSTANT["PERSON"].message.confirmation.add},person : ${results.id}`;

              modal.modalTile = "Action Complete";
              this.revertPageInput(results);
            }
          );
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["PERSON"].message.error
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }

      return internalPromise;
    });

    promise = promise.catch((err) => {
      console.error(err);

      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false,
      });
    });

    return promise;
  }
  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof PersonPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class PersonPageContainer extends React.Component<
  PersonPageProps,
  PersonPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let personId: string;

    // console\.log("[PersonPageContainer] provided props", this.props);

    if (this.props.person === null || this.props.person === undefined) {
      node = <PersonPage />;
    } else {
      personId = this.props.person.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query personPageQuery($id: ID!) {
              person(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                person {
                  entities {
                    __typename
                    name
                    id
                    revision
                    hasPublicPage
                    tagLine
                    description
                    websiteUrl
                    landingPageUrl
                    isEditable
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                              label
                            }
                          }
                          kind
                        }
                      }
                    }
                  }
                  __typename
                  description
                  emailAddresses
                  fullName
                  hasPublicPage
                  landingPageUrl
                  id
                  greeting
                  revision
                  defaultLocation {
                    id
                    label
                  }
                  locations {
                    id
                    area
                    country
                    address
                    label
                  }
                  mediaAbout {
                    count
                    edges {
                      cursor
                      story {
                        __typename
                        ... on RadivisionPost {
                          id
                          link
                          previews {
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                          subTitle
                          title
                          description
                          landingPageUrl
                        }
                        ... on OnlineCourseStory {
                          id
                          title
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  id
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on RadivisionPost {
                          id
                          title
                          content
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  id
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on ArticleStory {
                          id
                          title
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  id
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on BookStory {
                          id
                          title
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  id
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on NewsletterStory {
                          id
                          title
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  id
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on PodcastStory {
                          id
                          title
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  id
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on OriginalContentStory {
                          id
                          title
                          previews {
                            count
                            edges {
                              cursor
                              preview {
                                link
                                kind
                                mediaAsset {
                                  id
                                  files {
                                    id
                                    name
                                    path
                                  }
                                  topLevelMediaType {
                                    kind
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        kind
                        mediaAsset {
                          id
                          files {
                            id
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                            label
                          }
                        }
                      }
                    }
                  }
                  responsibility
                  socialMediaCredentials {
                    credential
                    platform {
                      kind
                    }
                  }
                  websiteUrl
                  tags {
                    id
                    label
                  }
                }
              }
            }
          `}
          variables={{
            id: personId,
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.person.hasErrors) {
                return (
                  <div>
                    <p>unexpected error contact Radivision Technical team.</p>{" "}
                  </div>
                );
              } else if (
                props.person.person.__typename !== GRAPHQL_TYPE_PERSON
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid person id, {personId} belongs to type{" "}
                      {props.person.person.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.person.person) {
                let pageProps: PersonPageProps = {
                  ...this.props,
                  personGraphql: props.person.person,
                };
                return <PersonPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }
    return node;
  }
}
