/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Initial page component
 * @filename InitialPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Publisher from "./publisher";
import AuthorPanel from "./author/author-panel";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import ModerationPanel from "./moderation/moderation-panel";
import {
  NewsletterStory,
  PreviewKind,
  GRAPHQL_TYPE_NEWSLETTER_STORY
} from "@radivision/graphql";

import { Validator } from "../../../utilities/cms-operation/validator";
import ModalConductor from "./modal/modal-conductor";
import { Newsletter } from "../../../utilities/newsletter";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import {
  FormGroupInput,
  NewsLetterPageFormInput,
  PreviewItem
} from "../../../component-configuration/cms-pages";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";

/**
 *
 *
 * @export
 * @interface NewsletterPageProps
 */
export interface NewsletterPageProps {
  /**
   *
   *
   * @type {{ id: string }}
   * @memberof NewsletterPageProps
   */
  newsletter?: { id: string };
  /**
   *
   *
   * @type {NewsletterStory}
   * @memberof NewsletterPageProps
   */
  newsletterStory?: NewsletterStory;
}

/**
 * The state of the Newsletter page component.
 *
 * @interface NewsletterPageState
 */
export interface NewsletterPageState {
  /**
   *
   *
   * @type {FormGroupInput}
   * @memberof NewsletterPageState
   */
  formGroupInput: FormGroupInput;

  /**
   *
   *
   * @type {NewsLetterPageFormInput}
   * @memberof NewsletterPageState
   */
  newsletterFormInput: NewsLetterPageFormInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof NewsletterPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof NewsletterPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof NewsletterPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class NewsletterPage
 * @extends {React.Component<NewsletterPageProps, NewsletterPageState>}
 */
export class NewsletterPage extends React.Component<
  NewsletterPageProps,
  NewsletterPageState
> {
  /**
   * References to the Author component in the NewsletterPage.
   *
   * @type {*}
   * @memberof NewsletterPage
   */
  authorPanelRef: any;

  /**
   * References to the moderation component in the NewsletterPage.
   *
   * @type {*}
   * @memberof NewsletterPage
   */
  moderationPanelRef: any;

  /**
   * References to the publisher component in the NewsletterPage.
   *
   * @type {*}
   * @memberof NewsletterPage
   */
  publisherDetailsRef: any;

  /**
   * References to the preview component in the NewsletterPage.
   *
   * @type {*}
   * @memberof NewsletterPage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the NewsletterPage.
   *
   * @type {*}
   * @memberof NewsletterPage
   */
  tagsRef: any;

  /**
   *Creates an instance of NewsletterPage.
   * @param {NewsletterPageProps} props
   * @memberof NewsletterPage
   */
  constructor(props: NewsletterPageProps) {
    super(props);

    //... bind needed function to class

    this.handleCostChanges = this.handleCostChanges.bind(this);
    this.handleLinkChanges = this.handleLinkChanges.bind(this);
    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handlePublishDateChanges = this.handlePublishDateChanges.bind(this);
    this.handleTimeToReadChanges = this.handleTimeToReadChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleIsNewChanges = this.handleIsNewChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    //... initialize required components references

    this.authorPanelRef = React.createRef();
    this.moderationPanelRef = React.createRef();
    this.publisherDetailsRef = React.createRef();
    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();

    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    this.state = this.handleComponentStateInitialize(
      this.props.newsletterStory
    );
  }

  /**
   *
   *
   * @param {ArticleStory} newsLetterProps
   * @returns {ArticlePageState}
   * @memberof ArticlePage
   */
  handleComponentStateInitialize(
    newsLetterProps: NewsletterStory
  ): NewsletterPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let newsletterFormInput: NewsLetterPageFormInput = {
      id: undefined,
      label: "",
      link: "",
      title: "",
      subTitle: "",
      summary: "",
      publisherName: "",
      publishedDate: "",
      isDeleted: false,
      isNew: false,
      timeToReadInSeconds: 10,
      cost: ""
    };

    let state: NewsletterPageState = {
      modal,
      newsletterFormInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    if (newsLetterProps !== null && newsLetterProps !== undefined) {
      // validate provided label

      if (newsLetterProps.id) {
        state.newsletterFormInput.id = newsLetterProps.id;
      }

      if (newsLetterProps.label) {
        state.newsletterFormInput.label = newsLetterProps.label;
      }

      // validate provided link
      if (newsLetterProps.link) {
        state.newsletterFormInput.link = newsLetterProps.link;
      }

      if (newsLetterProps.title) {
        state.newsletterFormInput.title = newsLetterProps.title;
      }

      if (newsLetterProps.subTitle) {
        state.newsletterFormInput.subTitle = newsLetterProps.subTitle;
      }

      if (newsLetterProps.description) {
        state.newsletterFormInput.summary = newsLetterProps.description;
      }

      if (newsLetterProps.isDeleted) {
        state.newsletterFormInput.isDeleted = newsLetterProps.isDeleted;
      }

      if (newsLetterProps.isNew) {
        state.newsletterFormInput.isNew = newsLetterProps.isNew;
      }

      if (newsLetterProps.timeToReadInSeconds) {
        state.newsletterFormInput.timeToReadInSeconds =
          newsLetterProps.timeToReadInSeconds;
      }

      if (newsLetterProps.cost) {
        state.newsletterFormInput.cost = newsLetterProps.cost;
      }

      if (newsLetterProps.publisher) {
        state.newsletterFormInput.publisherName = newsLetterProps.publisher;
      }

      if (newsLetterProps.publishedDate) {
        state.newsletterFormInput.publishedDate = newsLetterProps.publishedDate;
      }

      if (newsLetterProps.revision) {
        state.newsletterFormInput.revision = newsLetterProps.revision;
      }

      // ............

      // initialize form group inputs

      //...............
      if (newsLetterProps.publisher) {
        state.formGroupInput.publisherProps = {
          name: newsLetterProps.publisher
          // type :""
        };
      }

      if (
        newsLetterProps.tags !== null &&
        newsLetterProps.tags !== undefined &&
        newsLetterProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = newsLetterProps.tags.map(
          tag => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        newsLetterProps.previews !== null &&
        newsLetterProps.previews !== undefined &&
        newsLetterProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = newsLetterProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${
                      preview.preview.mediaAsset.files[0].name
                    }`
                  }
                };
                break;

              default:
                break;
            }
            
            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @returns
   * @memberof NewsletterPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Newsletter Story</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Link URL</label>
                  <input
                    type="text"
                    name="Link URL"
                    className={`form-control ${
                      this.state.errorMessage.get("LINK") ? "is-invalid" : ""
                    }`}
                    value={this.state.newsletterFormInput.link}
                    onChange={this.handleLinkChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("LINK")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.newsletterFormInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    name="Subtitle"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.newsletterFormInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>
            {/* Author Section */}
            <div className="Page--Author">
              <label htmlFor="">Authors</label>
              <AuthorPanel ref={this.authorPanelRef} />
            </div>
            {/* Publisher Section */}
            <div className="Page--Publisher">
              <label>Publisher</label>
              <Publisher
                error={{
                  haveError: this.state.errorMessage.get("PUBLISHER")
                    ? true
                    : false,
                  message: this.state.errorMessage.get("PUBLISHER")
                }}
                publisher={this.state.formGroupInput.publisherProps}
                ref={this.publisherDetailsRef}
              />
            </div>
            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Time to Read(Seconds)</label>
                <input
                  id="party"
                  type="number"
                  name="timetoread"
                  className={`form-control ${
                    this.state.errorMessage.get("TIME") ? "is-invalid" : ""
                  }`}
                  value={this.state.newsletterFormInput.timeToReadInSeconds}
                  onChange={this.handleTimeToReadChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("TIME")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Cost</label>
                <input
                  type="number"
                  name="cost"
                  className={`form-control ${
                    this.state.errorMessage.get("COST") ? "is-invalid" : ""
                  }`}
                  value={this.state.newsletterFormInput.cost}
                  onChange={this.handleCostChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("COST")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.newsletterFormInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Summary</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.newsletterFormInput.summary}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="isdeleted"
                  checked={this.state.newsletterFormInput.isDeleted}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="new"
                  value={
                    this.state.newsletterFormInput.isNew ? "true" : "false"
                  }
                  onClick={this.handleIsNewChanges}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">New</label>
              </div>
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <ManagePreviewPanel
                ref={this.previewPanelRef}
                previewsList={this.state.formGroupInput.previewsProps}
              />
            </div>
            {/* moderation section */}
            <div className="Page--Moderation">
              <ModerationPanel ref={this.moderationPanelRef} />
            </div>
          </div>
          <Loader isActive={this.state.isLoading} />
          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let newsletterFormInput = this.state.newsletterFormInput;
                  newsletterFormInput.isDeleted = true;
                  this.setState({ newsletterFormInput });
                },
                unDeleteAction: () => {
                  let newsletterFormInput = this.state.newsletterFormInput;
                  newsletterFormInput.isDeleted = false;
                  this.setState({ newsletterFormInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
        </div>
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in article link state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof NewsletterPage
   */
  handleLinkChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();

    // // console\.log("[handleLinkChanges] provided value : ", eventValue);\
    // ... assign value if valid
    newsletterFormInput.link = eventValue;
    this.setState({ newsletterFormInput });
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @memberof NewsletterPage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    newsletterFormInput.title = eventValue;
    this.setState({ newsletterFormInput });
  }

  /**
   * Function Responsible for handling changes in article sub-title state
   *
   * @memberof NewsletterPage
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    newsletterFormInput.subTitle = eventValue;
    this.setState({ newsletterFormInput });
  }

  /**
   * Function Responsible for handling changes in article Publish Date state
   *
   * @memberof NewsletterPage
   */
  handlePublishDateChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    newsletterFormInput.publishedDate = eventValue;
    this.setState({ newsletterFormInput });
  }

  /**
   * Function Responsible for handling changes in article time to read state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof NewsletterPage
   */
  handleTimeToReadChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;
    let eventValue: number = Number(changeEvent.currentTarget.value);
    // // console\.log("[handleTimeToReadChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    newsletterFormInput.timeToReadInSeconds = eventValue;
    this.setState({ newsletterFormInput });
  }

  /**
   * Function Responsible for handling changes in article summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof NewsletterPage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    newsletterFormInput.summary = eventValue;
    this.setState({ newsletterFormInput });
  }

  /**
   * Function Responsible for handling changes in article label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof NewsletterPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    newsletterFormInput.label = eventValue;
    this.setState({ newsletterFormInput });
  }

  /**
   * Function Responsible for handling changes in article is deleted state
   *
   * @memberof NewsletterPage
   */
  handleIsDeletedChanges(event: any): void {
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;

    let isDeleted: boolean = event.target.checked ? true : false;
    newsletterFormInput.isDeleted = isDeleted;
    // // console\.log("[handleIsDeletedChanges] is article deleted", isDeleted);

    this.setState({ newsletterFormInput });
  }

  /**
   * Function Responsible for handling changes in article is new state
   *
   * @memberof NewsletterPage
   */
  handleIsNewChanges(event: any): void {
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;

    let isNew: boolean = event.target.checked ? true : false;
    newsletterFormInput.isNew = isNew;

    // // console\.log("[handleIsNewChanges] is article new ", isNew);
    this.setState({ newsletterFormInput });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof NewsletterPage
   */
  handleCostChanges(changeEvent: React.FormEvent<HTMLInputElement>) {
    // ... define need variables
    let newsletterFormInput: NewsLetterPageFormInput = this.state
      .newsletterFormInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleCostChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    newsletterFormInput.cost = eventValue;
    this.setState({ newsletterFormInput });
  }
  /**
   *
   *
   * @memberof NewsletterPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   * Function Responsible for handling submitting new article
   *
   * @memberof NewsletterPage
   */
  handleSaveAction(): void {
    let articleInput: NewsletterPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract moderation state
      articleInput.newsletterFormInput.moderation = this.moderationPanelRef.current.extractModerationState();
      // extract tags state
      articleInput.newsletterFormInput.tags = this.tagsRef.current.extractTagsString();
      // extract publisher name
      articleInput.newsletterFormInput.publisherName = this.publisherDetailsRef.current.extractPublisherDetails();
      // extract previews list
      articleInput.newsletterFormInput.previews = this.previewPanelRef.current.extractPreviews();

      // validate errors
      errorMessage = Validator.validateCreateStoryInput(
        articleInput.newsletterFormInput
      );

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console\.log("[handleAddArticle] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (
          this.props.newsletter !== null &&
          this.props.newsletter !== undefined
        ) {
          internalPromise = Newsletter.updateNewsletter(
            articleInput.newsletterFormInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["NEWSLETTER"].message.confirmation.update;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Newsletter.addNewsletter(
            articleInput.newsletterFormInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${
              CMS_PAGE_CONSTANT["NEWSLETTER"].message.confirmation.add
            } 
              Newsletter id : ${results.id}`;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["NEWSLETTER"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @memberof NewsletterPage
   */
  revertPageInput(input?: NewsletterStory): void {
    let state: NewsletterPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.newsletterStory
    );
    this.setState(state);
    //... reset children state

    this.tagsRef.current.resetComponent();
    this.authorPanelRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.publisherDetailsRef.current.resetComponent();
    this.moderationPanelRef.current.resetComponent();
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof NewsletterPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class NewsLetterPageContainer extends React.Component<
  NewsletterPageProps,
  NewsletterPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let newsletterId: string;

    // console\.log("[NewsLetterPageContainer] provided props", this.props);

    if (this.props.newsletter === null || this.props.newsletter === undefined) {
      node = <NewsletterPage />;
    } else {
      newsletterId = this.props.newsletter.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query newsletterPageQuery($id: ID!) {
              story(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                node {
                  __typename
                  ... on NewsletterStory {
                    id
                    link
                    timeToReadInSeconds
                    publisher
                    publishedDate
                    description
                    created
                    label
                    cost
                    isDeleted
                    revision
                    isNew
                    tags {
                      id
                      label
                    }
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                              label
                            }
                          }
                        }
                      }
                    }
                    subTitle
                    title
                  }
                }
              }
            }
          `}
          variables={{
            id: newsletterId
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.story.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.story.node.__typename !== GRAPHQL_TYPE_NEWSLETTER_STORY
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Newsletter id, {newsletterId} belongs to type{" "}
                      {props.story.node.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.story.node) {
                let pageProps: NewsletterPageProps = {
                  ...this.props,
                  newsletterStory: props.story.node
                };
                return <NewsletterPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
