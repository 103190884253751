/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-03
 * @description Implementation of Article utilities to be used in the app.
 * @filename article.ts
 */
import { CreateArticleStoryInput, CreateStoryInput, UpdateArticleStoryInput, UpdateStoryInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { ArticlePageFormInput } from "../component-configuration/cms-pages";
import { ArticlePageState } from "../react-components/inner-pages/content-management/article-page";
import { CREATE_ARTICLE_GRAPHQL_MUTATION } from "../relay/mutations/create-article-story";
import { UPDATE_ARTICLE_STORY_GRAPHQL_MUTATION } from "../relay/mutations/update-article-story";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { Story } from "./cms-operation/story";
import { commitMutation } from "./relay";

export class Article {
  /**
   * Returns a promise to add new article to database.
   *
   * @param {ArticlePageState} articleInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addArticle(
    articleInput: ArticlePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateArticleStoryInput
    let createArticleStoryInput: CreateArticleStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: CreateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      articleInput.link === undefined ||
      articleInput.link === null ||
      articleInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("PwYWd96YD0KZ8lb8ucuEqA"));
    } else {
      storyInput = Story.createStoryInput(articleInput);
      // initialize create article graphQl input
      createArticleStoryInput = {
        ...storyInput,
        link: encodeURI(articleInput.link.trim()),
        timeToReadInSeconds: articleInput.timeToRead
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_ARTICLE_GRAPHQL_MUTATION,
        variables: {
          input: createArticleStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        // console.log(mutationResults);
        return mutationResults.createArticleStory.hasErrors === false
          ? Promise.resolve(mutationResults.createArticleStory.story)
          : Promise.reject(mutationResults.createArticleStory.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updateArticle(
    articleInput: ArticlePageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let updateArticleStoryInput: UpdateArticleStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: UpdateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      articleInput.link === undefined ||
      articleInput.link === null ||
      articleInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("11MsufFPsEqmmoLIxFjgcg"));
    } else {
      storyInput = Story.updateStoryInput(articleInput);

      // initialize create article graphQl input
      updateArticleStoryInput = {
        ...storyInput,
        link: encodeURI(articleInput.link.trim()),
        timeToReadInSeconds: articleInput.timeToRead
      };

      // console.log("[updateArticle] mutation input ", JSON.stringify(updateArticleStoryInput));

      // initialize mutation configuration
      config = {
        mutation: UPDATE_ARTICLE_STORY_GRAPHQL_MUTATION,
        variables: {
          input: updateArticleStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateArticleStory.hasErrors === false
          ? Promise.resolve(mutationResults.updateArticleStory.story)
          : Promise.reject(mutationResults.updateArticleStory.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteArticle(
    articleInput: ArticlePageState,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
