/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ComponentPropertyKind = "ARTICLE_STORY" | "BOOK_STORY" | "BOOLEAN" | "DYNAMIC_LIST" | "ENTITY" | "FIXED_LIST" | "LIST" | "NEWSLETTER_STORY" | "NUMBER" | "ONLINE_COURSE" | "ORIGINAL_CONTENT" | "PERSON" | "PODCAST" | "QUERY_PARAMETER" | "STORY" | "STRING" | "USER" | "%future added value";
export type UpdateComponentInput = {
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly force?: boolean | null;
    readonly id: string;
    readonly key: string;
    readonly label?: string | null;
    readonly properties?: ReadonlyArray<CreateUpdateComponentPropertyInput | null> | null;
    readonly revision?: string | null;
    readonly tags?: ReadonlyArray<string | null> | null;
};
export type CreateUpdateComponentPropertyInput = {
    readonly description?: string | null;
    readonly id?: string | null;
    readonly isRequired?: boolean | null;
    readonly kind?: ComponentPropertyKind | null;
    readonly name?: string | null;
};
export type updateComponentMutationVariables = {
    readonly input: UpdateComponentInput;
};
export type updateComponentMutationResponse = {
    readonly updateComponent: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly component: {
            readonly __typename: string;
            readonly key: string | null;
            readonly description: string | null;
            readonly id: string;
            readonly revision: string | null;
        } | null;
    } | null;
};
export type updateComponentMutation = {
    readonly response: updateComponentMutationResponse;
    readonly variables: updateComponentMutationVariables;
};



/*
mutation updateComponentMutation(
  $input: UpdateComponentInput!
) {
  updateComponent(input: $input) {
    hasErrors
    errors {
      id
      location
    }
    component {
      __typename
      key
      description
      id
      revision
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateComponentInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateComponent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateComponentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "component",
        "storageKey": null,
        "args": null,
        "concreteType": "Component",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateComponentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateComponentMutation",
    "id": null,
    "text": "mutation updateComponentMutation(\n  $input: UpdateComponentInput!\n) {\n  updateComponent(input: $input) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    component {\n      __typename\n      key\n      description\n      id\n      revision\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '5022c50f3739d9eca6bd0c5a202f2a5a';
export default node;
