/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-11
 * @description Implementation of poster utilities to be used in the app.
 * @filename poster.ts
 */

import { CreatePosterInput, UpdatePosterInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { PosterPageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_POSTER_GRAPHQL_MUTATION } from "../../relay/mutations/create-poster";
import { UPDATE_POSTER_GRAPHQL_MUTATION } from "../../relay/mutations/update-poster";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

export class Poster {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ArticlePageState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addPoster(
    posterInput: PosterPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePosterInput
    let createPosterInput: CreatePosterInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[addPoster] provided input ", JSON.stringify(posterInput));

    if (
      posterInput.title === undefined ||
      posterInput.title === null ||
      posterInput.title.length < 1
    ) {
      promise = Promise.reject(new Error("87jYxtNqNU2gvDR1gUrDpA"));
    } else {
      // initialize create person graphQl input
      createPosterInput = {
        clientMutationId: getClientMutationId(),
        title: posterInput.title.trim(),
        previews: posterInput.previews,
        actionDescription:
          posterInput.actionDescription !== undefined &&
          posterInput.actionDescription !== null &&
          posterInput.actionDescription.length > 0
            ? posterInput.actionDescription.trim()
            : undefined,
        subTitle:
          posterInput.subTitle !== undefined &&
          posterInput.subTitle !== null &&
          posterInput.subTitle.length > 0
            ? posterInput.subTitle.trim()
            : undefined,
        actionTitle:
          posterInput.actionTitle !== undefined &&
          posterInput.actionTitle !== null &&
          posterInput.actionTitle.length > 0
            ? posterInput.actionTitle.trim()
            : undefined,
        description:
          posterInput.description !== undefined &&
          posterInput.description !== null &&
          posterInput.description.length > 0
            ? posterInput.description.trim()
            : undefined,
        label:
          posterInput.label !== undefined &&
          posterInput.label !== null &&
          posterInput.label.length > 0
            ? posterInput.label.trim()
            : undefined,
        tags:
          posterInput.tags !== undefined &&
          posterInput.tags !== null &&
          posterInput.tags.length > 0
            ? posterInput.tags
            : undefined,
        isActive: true
      };

      // console.log(

      // initialize mutation configuration
      config = {
        mutation: CREATE_POSTER_GRAPHQL_MUTATION,
        variables: {
          input: createPosterInput
        }
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createPoster.hasErrors === false
          ? Promise.resolve(mutationResults.createPoster.poster)
          : Promise.reject(mutationResults.createPoster.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updatePoster(
    posterInput: PosterPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  UpdatePosterInput
    let updatePosterInput: UpdatePosterInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[updatePoster] provided input ", JSON.stringify(posterInput));
    if (
      posterInput.id === undefined ||
      posterInput.id === null ||
      posterInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("eMszDKeETUaquAKM8fh"));
    } else {
      // initialize create person graphQl input
      updatePosterInput = {
        clientMutationId: getClientMutationId(),
        id: posterInput.id,
        revision: posterInput.revision,
        title:
          posterInput.title !== undefined &&
          posterInput.title !== null &&
          posterInput.title.length > 0
            ? posterInput.title.trim()
            : undefined,
        subTitle:
          posterInput.subTitle !== undefined &&
          posterInput.subTitle !== null &&
          posterInput.subTitle.length > 0
            ? posterInput.subTitle.trim()
            : undefined,
        actionDescription:
          posterInput.actionDescription !== undefined &&
          posterInput.actionDescription !== null &&
          posterInput.actionDescription.length > 0
            ? posterInput.actionDescription.trim()
            : undefined,
        actionTitle:
          posterInput.actionTitle !== undefined &&
          posterInput.actionTitle !== null &&
          posterInput.actionTitle.length > 0
            ? posterInput.actionTitle.trim()
            : undefined,
        description:
          posterInput.description !== undefined &&
          posterInput.description !== null &&
          posterInput.description.length > 0
            ? posterInput.description.trim()
            : undefined,
        label:
          posterInput.label !== undefined &&
          posterInput.label !== null &&
          posterInput.label.length > 0
            ? posterInput.label.trim()
            : undefined,
        previews: posterInput.previews,
        tags:
          posterInput.tags !== undefined &&
          posterInput.tags !== null &&
          posterInput.tags.length > 0
            ? posterInput.tags
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: UPDATE_POSTER_GRAPHQL_MUTATION,
        variables: {
          input: updatePosterInput
        }
      };

      // attempt to commit update person mutation
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.updatePoster.hasErrors === false
          ? Promise.resolve(mutationResults.updatePoster.poster)
          : Promise.reject(mutationResults.updatePoster.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deletePerson(
    posterInput: PosterPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
