/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-07
 * @description Implementation of original content utilities to be used in the app.
 * @filename original-content.ts
 */

import { CreateOriginalContentStoryInput, CreateStoryInput, UpdateOriginalContentStoryInput, UpdateStoryInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { OriginalContentPageFormInput } from "../component-configuration/cms-pages";
import { CREATE_ORIGINAL_CONTENT_STORY_GRAPHQL_MUTATION } from "../relay/mutations/create-original-content-story";
import { UPDATE_ORIGINAL_CONTENT_STORY_GRAPHQL_MUTATION } from "../relay/mutations/update-original-content-story";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { Story } from "./cms-operation/story";
import { commitMutation } from "./relay";

export class OriginalContent {
  /**
   * Returns a promise to add new article to database.
   *
   * @param {ArticlePageState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addOriginalContent(
    originalContentInput: OriginalContentPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateOriginalContentStoryInput
    let createOriginalContentStoryInput: CreateOriginalContentStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: CreateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      originalContentInput.title === undefined ||
      originalContentInput.title === null ||
      originalContentInput.title.length < 1
    ) {
      promise = Promise.reject(new Error("7UAo1gKgTUWVNZcx2nQMYg"));
    } else {
      // attempt to create story input
      storyInput = Story.createStoryInput(originalContentInput);

      // cause we don't need content moderation
      delete storyInput.moderation;
      // initialize create article graphQl input
      createOriginalContentStoryInput = {
        ...storyInput,
        durationInSeconds: Number(originalContentInput.durationInSeconds),
        externalId: originalContentInput.embeddedCode
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_ORIGINAL_CONTENT_STORY_GRAPHQL_MUTATION,
        variables: {
          input: createOriginalContentStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createOriginalContentStory.hasErrors === false
          ? Promise.resolve(mutationResults.createOriginalContentStory.story)
          : Promise.reject(mutationResults.createOriginalContentStory.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updateOriginalContent(
    originalContentInput: OriginalContentPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateOriginalContentStoryInput
    let updateOriginalContentStoryInput: UpdateOriginalContentStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: UpdateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    storyInput = Story.updateStoryInput(originalContentInput);

    // initialize create article graphQl input
    updateOriginalContentStoryInput = {
      ...storyInput,
      externalId:
        originalContentInput.embeddedCode.length > 0
          ? originalContentInput.embeddedCode.trim()
          : undefined,
      durationInSeconds: Number(originalContentInput.durationInSeconds)
    };

    // initialize mutation configuration
    config = {
      mutation: UPDATE_ORIGINAL_CONTENT_STORY_GRAPHQL_MUTATION,
      variables: {
        input: updateOriginalContentStoryInput
      }
    };

    //
    promise = commitMutation(environment, config);
    //
    promise = promise.then((mutationResults: any) => {
      return mutationResults.updateOriginalContentStory.hasErrors === false
        ? Promise.resolve(mutationResults.updateOriginalContentStory.story)
        : Promise.reject(mutationResults.updateOriginalContentStory.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteOriginalContent(
    originalContentInput: OriginalContentPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
