import * as React from "react";
import { DEFAULTS } from "../../../../utilities/defaults";
import ModalConductor from "../modal/modal-conductor";
import { mapTypeToSearch } from "../../../../utilities/search";
import { CarouselItem } from "../../../../component-configuration/carousel-item";
import ProgressiveImage from "react-progressive-image";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";
import {
  GRAPHQL_TYPE_ARTICLE_STORY,
  GRAPHQL_TYPE_BOOK_STORY,
  GRAPHQL_TYPE_NEWSLETTER_STORY,
  GRAPHQL_TYPE_PODCAST_STORY,
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  GRAPHQL_TYPE_ONLINE_COURSE_STORY,
} from "@radivision/graphql";

const NO_IMAGE = require("../../../../../static/no-img.jpg");

/**
 *
 */
const MEDIA_ITEM_DEFAULT_IMAGE: { src: string; placeholder: string }[] = [
  {
    src: `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_ArticleStory_LOGO"]}?wip=300`,
    placeholder: `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_ArticleStory_LOGO"]}?wip=10`,
  },
  {
    src: `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_BookStory_LOGO"]}?wip=300`,
    placeholder: `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_BookStory_LOGO"]}?wip=10`,
  },
  {
    src: `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_NewsletterStory_LOGO"]}?wip=300`,
    placeholder: `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_NewsletterStory_LOGO"]}?wip=10`,
  },
  {
    src: `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_OnlineCourseStory_LOGO"]}?wip=300`,
    placeholder: `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_OnlineCourseStory_LOGO"]}?wip=10`,
  },
];

/**
 *
 */
const SEARCH_FILTERS: { field: string; values: string[] }[] = [
  {
    field: "label",
    values: [
      GRAPHQL_TYPE_ARTICLE_STORY,
      GRAPHQL_TYPE_BOOK_STORY,
      GRAPHQL_TYPE_NEWSLETTER_STORY,
      GRAPHQL_TYPE_PODCAST_STORY,
      GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
      GRAPHQL_TYPE_ONLINE_COURSE_STORY,
    ],
  },
];

/**
 *
 *
 * @interface ManageBadgePanelState
 */
interface ManageMediaPanelProps {
  /**
   *
   *
   * @type {any[]}
   * @memberof ManageMediaPanelProps
   */
  mediaItems?: CarouselItem[];

  filter?: { field: string; values: string[] }[];
}

/**
 *
 *
 * @interface ManageBadgePanelState
 */
interface ManageMediaPanelState {
  /**
   *
   *
   * @type {{ id: number; type: string; source: string }[]}
   * @memberof ManageMediaPanelState
   */
  mediaItems: CarouselItem[];

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof ManageMediaPanelState
   */
  modalName: "ConfirmModal" | "SearchModal" | "NONE";

  /**
   *
   *
   * @type {boolean}
   * @memberof ManageMediaPanelState
   */
  showModal: boolean;
}

/**
 *
 *
 * @export
 * @class ManageMediaPanel
 * @extends {React.Component<{}, ManageBadgePanelState>}
 */
export default class ManageMediaPanel extends React.Component<
  ManageMediaPanelProps,
  ManageMediaPanelState
> {
  /**
   *Creates an instance of ManageMediaPanel.
   * @param {ManageMediaPanelProps} props
   * @memberof ManageBadgePanel
   */
  constructor(props: ManageMediaPanelProps) {
    super(props);

    this.ModalClose = this.ModalClose.bind(this);
    this.openSearchModal = this.openSearchModal.bind(this);
    this.addMediaItem = this.addMediaItem.bind(this);
    this.removeMediaItem = this.removeMediaItem.bind(this);
    this.extractMediaItem = this.extractMediaItem.bind(this);
    this.resetComponent = this.resetComponent.bind(this);
    this.state = this.initializeComponentState(this.props);
  }

  /**
   *
   *
   * @param {ManageMediaPanelProps} props
   * @returns {ManageMediaPanelState}
   * @memberof ManageMediaPanel
   */
  initializeComponentState(
    props: ManageMediaPanelProps
  ): ManageMediaPanelState {
    let state: ManageMediaPanelState = {
      mediaItems: [],
      showModal: false,
      modalName: "NONE",
    };

    if (props !== undefined && props !== null) {
      if (
        props.mediaItems !== undefined &&
        props.mediaItems !== null &&
        props.mediaItems.length > 0
      ) {
        state.mediaItems = props.mediaItems;
      }
    }
    return state;
  }

  /**
   *
   *
   * @memberof ManageBadgePanel
   */
  ModalClose() {
    this.setState({
      showModal: false,
    });
  }

  /**
   *
   *
   * @memberof ManageBadgePanel
   */
  openSearchModal() {
    this.setState({
      showModal: true,
      modalName: "SearchModal",
    });
  }

  /**
   *
   *
   * @param {number} id
   * @memberof ManageBadgePanel
   */
  removeMediaItem(id: string) {
    const updatedMediaItems = this.state.mediaItems.filter(
      (badge: CarouselItem) => {
        return badge.id !== id;
      }
    );

    this.setState({
      mediaItems: updatedMediaItems,
    });
  }

  /**
   *
   *
   * @param {number} id
   * @param {string} url
   * @param {*} imageSource
   * @memberof ManageBadgePanel
   */
  addMediaItem(suggestion: CarouselItem) {
    let mediaItems = this.state.mediaItems;

    // ... validate if suggestion is valid media item
    if (suggestion !== undefined && suggestion !== null) {
      if (suggestion.id) {
        mediaItems.push(suggestion);
      }
    }
    this.setState({
      mediaItems,
      modalName: "NONE",
      showModal: false,
    });
  }

  /**
   *
   *
   * @memberof ManageMediaPanel
   */
  resetComponent() {
    let state: ManageMediaPanelState = this.initializeComponentState(
      this.props
    );
    this.setState(state);
  }

  /**
   *
   *
   * @returns
   * @memberof ManageMediaPanel
   */
  render() {
    return (
      <div className="container">
        <div
          className="row justify-content-md-center"
          style={
            this.state.mediaItems && this.state.mediaItems.length > 4
              ? {
                  overflowY: "scroll",
                  maxHeight: 680,
                }
              : { overflow: "hidden" }
          }
        >
          {this.state.mediaItems.map((item, index) => {
            let itemIndex: number = Math.floor(
              Math.random() * MEDIA_ITEM_DEFAULT_IMAGE.length
            );

            return (
              <div
                key={index}
                className="col-lg-3 text-center"
                style={{
                  background: "#dcdee0",
                  borderRadius: "10%",
                  margin: "12px",
                }}
                draggable
                onDragStart={(e) => this.handleDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => this.handleDrop(e, index)}
              >
                <div>
                  <p>{item.id}</p>
                  <ProgressiveImage
                    src={
                      item.previewImageUrl
                        ? item.previewImageUrl.requestedResolutionUrl
                        : MEDIA_ITEM_DEFAULT_IMAGE[itemIndex].src
                    }
                    placeholder={
                      MEDIA_ITEM_DEFAULT_IMAGE[itemIndex].placeholder
                    }
                  >
                    {(src: any, _loading: any, srcSetData: any) => (
                      <img
                        alt={item.title}
                        onError={(targetImage: any) => {
                          targetImage.onerror = null;
                          targetImage.src = NO_IMAGE;
                        }}
                        style={{
                          width: "50%",
                          borderRadius: "50%",
                          margin: "10px",
                        }}
                        src={src}
                      />
                    )}
                  </ProgressiveImage>
                </div>
                <div
                  className="row justify-content-md-center"
                  style={{ cursor: "pointer" }}
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    this.handleOnItemClick(e, index);
                  }}
                >
                  <p> {item.title ? item.title.substr(0, 40) : ""} </p>
                </div>
                <div className="row justify-content-md-center">
                  <button
                    onClick={() => this.removeMediaItem(item.id)}
                    className="btn btn-danger btn-sm mt-3"
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
          {this.state.showModal ? (
            <ModalConductor
              currentModal={this.state.modalName}
              SearchModalProps={{
                filters: this.props?.filter || SEARCH_FILTERS,
                handleSearchResults: this.addMediaItem,
              }}
              close={() => {
                this.setState({ modalName: "NONE", showModal: false });
              }}
            />
          ) : (
            <div />
          )}
        </div>

        <div className="row justify-content-md-center">
          <button
            className="btn btn-info btn-sm mt-3"
            style={{ height: "30px" }}
            onClick={this.openSearchModal}
          >
            Add
          </button>
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} index
   * @memberof ManageMediaPanel
   */
  handleDrop(e: any, dropIndex: number) {
    const draggedOverItem = this.state.mediaItems[dropIndex];
    const draggedItemId = e.dataTransfer.getData("text");

    console.log({ draggedOverItem, draggedItemId });
    // if the item is dragged over itself, ignore
    if (!draggedOverItem || !draggedItemId) {
      return;
    }

    const draggedItem = this.state.mediaItems.find((item) => {
      return item.id === draggedItemId;
    });

    // filter out the currently dragged item
    const items = this.state.mediaItems.filter(
      (item) => item.id !== draggedItem.id
    );

    // add the dragged item after the dragged over item
    items.splice(dropIndex, 0, draggedItem);

    this.setState({ mediaItems: items });

    // let mediaItems = this.state.mediaItems;
    // const draggedItemId = e.dataTransfer.getData("text");

    // if (draggedItemId < mediaItems.length && dropIndex < mediaItems.length) {
    //   const temp = mediaItems[dropIndex];

    //   mediaItems[dropIndex] = mediaItems[draggedItemId];
    //   mediaItems[draggedItemId] = temp;
    // }
    // this.setState({ mediaItems });
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} order
   * @memberof ManageMediaPanel
   */
  handleDragStart(e: any, order: any) {
    const dragedCard = e.target.parentNode.parentNode.parentNode;
    const draggedItemId = this.state?.mediaItems[order]?.id;
    console.log(draggedItemId);
    e.dataTransfer.effectAllowed = "move";
    // e.dataTransfer.setData("text/html", dragedCard);
    e.dataTransfer.setData("text/plain", draggedItemId);
    // e.dataTransfer.setDragImage(dragedCard, 20, 20);
  }

  /**
   *
   *
   * @memberof ListItemPanel
   */
  handleOnItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    let pageAlias: string;
    let itemType: string;

    event.preventDefault();
    itemType = mapTypeToSearch(this.state.mediaItems[index].type).toUpperCase();
    if (itemType) {
      pageAlias = CMS_PAGE_CONSTANT[itemType].alias;
      window.open(`/${pageAlias}/${this.state.mediaItems[index].id}`);
    }
  };

  /**
   *
   *
   * @returns {string[]}
   * @memberof ManageMediaPanel
   */
  extractMediaItem(): string[] {
    let mediaItemsInput: CarouselItem[] = this.state.mediaItems;
    let mediaItem: string[] = mediaItemsInput.map((item) => {
      return item.id;
    });

    return mediaItem.length > 0 ? mediaItem : undefined;
  }
}
