/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-05
 * @description Implementation of Book utilities to be used in the app.
 * @filename article.ts
 */
import { CreateUpdatePreviewInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { commitMutation } from "../relay";
import { getClientMutationId } from "../general";
import { CREATE_EVENT_GRAPHQL_MUTATION } from "../../relay/mutations/create-event";
import { UPDATE_EVENT_GRAPHQL_MUTATION } from "../../relay/mutations/update-event";

export class Event {
  /**
   * Returns a promise to add new book to database.
   *
   * @param {AddToPersonInput} BookInput The update to add to the person.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add book mutation.
   */
  static addEvent(
    bookInput: any,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert Book input to  CreateBookStoryInput
    let createBookStoryInput: any;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let previewsInput: CreateUpdatePreviewInput[];
    if (
      bookInput.previews !== undefined &&
      bookInput.previews !== null &&
      bookInput.previews.length > 0
    ) {
      // handle preview inputs
      // handle preview inputs
      previewsInput = [];
      for (const PREVIEW of bookInput.previews) {
        previewsInput.push({
          kind: PREVIEW.kind,
          link: PREVIEW.link,
          mediaAsset: PREVIEW.mediaAsset,
        });
      }
    }
    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      bookInput.title === undefined ||
      bookInput.title === null ||
      bookInput.title.length < 1
    ) {
      promise = Promise.reject(new Error("789"));
    } else {
      // initialize create book graphQl input
      createBookStoryInput = {
        clientMutationId: getClientMutationId(),
        status:
        bookInput.eventState && bookInput.eventState.length > 0
          ? bookInput.eventState.trim()
          : undefined,
        name:
          bookInput.title && bookInput.title.length > 0
            ? bookInput.title.trim()
            : undefined,
        end:
          bookInput.ended && bookInput.ended.length > 0
            ? bookInput.ended.trim()
            : undefined,
        start:
          bookInput.started && bookInput.started.length > 0
            ? bookInput.started.trim()
            : undefined,
        description:
          bookInput.description && bookInput.description.length > 0
            ? bookInput.description.trim()
            : undefined,
        summary:
          bookInput.subTitle && bookInput.subTitle.length > 0
            ? bookInput.subTitle.trim()
            : undefined,
        externalId:
          bookInput.eventId && bookInput.eventId.length > 0
            ? bookInput.eventId.trim()
            : undefined,
        tags:
          bookInput.tags && bookInput.tags.length > 0
            ? bookInput.tags
            : undefined,
        previews: previewsInput,
        entities:
          bookInput.entities && bookInput.entities.length
            ? bookInput.entities
            : undefined,
        peopleEntities:
          bookInput.peopleEntities && bookInput.peopleEntities.length
            ? bookInput.peopleEntities
            : undefined,
        url:
          bookInput.link && bookInput.link.length > 0
            ? bookInput.link.trim()
            : undefined,
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_EVENT_GRAPHQL_MUTATION,
        variables: {
          input: createBookStoryInput,
        },
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.createEvent.hasErrors === false
          ? Promise.resolve(mutationResults.createEvent.event)
          : Promise.reject(mutationResults.createEvent.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Book
   */
  static updateEvent(
    bookInput: any,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert Book input to  CreateBookStoryInput
    let createBookStoryInput: any;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let previewsInput: CreateUpdatePreviewInput[];
    if (
      bookInput.previews !== undefined &&
      bookInput.previews !== null &&
      bookInput.previews.length > 0
    ) {
      // handle preview inputs
      // handle preview inputs
      previewsInput = [];
      for (const PREVIEW of bookInput.previews) {
        previewsInput.push({
          kind: PREVIEW.kind,
          link: PREVIEW.link,
          mediaAsset: PREVIEW.mediaAsset,
        });
      }
    }
    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      bookInput.id === undefined ||
      bookInput.id === null ||
      bookInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("n1esugtENkK3xhTDsc1izQ"));
    } else {
      // initialize create book graphQl input
      createBookStoryInput = {
        clientMutationId: getClientMutationId(),
        id: bookInput.id,
        status:
          bookInput.eventState && bookInput.eventState.length > 0
            ? bookInput.eventState.trim()
            : undefined,
        name:
          bookInput.title && bookInput.title.length > 0
            ? bookInput.title.trim()
            : undefined,
        end:
          bookInput.ended && bookInput.ended.length > 0
            ? bookInput.ended.trim()
            : undefined,
        start:
          bookInput.started && bookInput.started.length > 0
            ? bookInput.started.trim()
            : undefined,
        description:
          bookInput.description && bookInput.description.length > 0
            ? bookInput.description.trim()
            : undefined,
        summary:
          bookInput.subTitle && bookInput.subTitle.length > 0
            ? bookInput.subTitle.trim()
            : undefined,
        revision:
          bookInput.revision && bookInput.revision.length > 0
            ? bookInput.revision.trim()
            : undefined,
        externalId:
          bookInput.eventId && bookInput.eventId.length > 0
            ? bookInput.eventId.trim()
            : undefined,
        tags:
          bookInput.tags && bookInput.tags.length > 0
            ? bookInput.tags
            : undefined,
        previews: previewsInput,
        entities:
          bookInput.entities && bookInput.entities.length
            ? bookInput.entities
            : undefined,
        peopleEntities:
          bookInput.peopleEntities && bookInput.peopleEntities.length
            ? bookInput.peopleEntities
            : undefined,
        url:
          bookInput.link && bookInput.link.length > 0
            ? bookInput.link.trim()
            : undefined,
      };

      console.log({ bookInput, createBookStoryInput });

      // initialize mutation configuration
      config = {
        mutation: UPDATE_EVENT_GRAPHQL_MUTATION,
        variables: {
          input: createBookStoryInput,
        },
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateEvent.hasErrors === false
          ? Promise.resolve(mutationResults.updateEvent.event)
          : Promise.reject(mutationResults.updateEvent.errors);
      });
    }

    return promise;
  }
}
