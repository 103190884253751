/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to update fields in entity.
 * @filename update-entity
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update entity.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_ENTITY_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateEntityMutation($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      entity {
        description
        founded
        hasPublicPage
        industry
        fundingRound
        noOfEmployees {
          maximum
          minimum
          isGreaterThan
          isLessThan
        }
        noOfPortfolioCompanies {
          maximum
          minimum
          isGreaterThan
          isLessThan
        }
        id
        isEditable
        isFundraising
        landingPageUrl
        locations {
          id
          label
        }
        headquarters {
          location {
            label
          }
        }
        mediaAbout {
          count
          edges {
            cursor
            story {
              __typename
              ... on RadivisionPost {
                id
                link
                previews {
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
                landingPageUrl
              }
              ... on OnlineCourseStory {
                id
                link
                publisher
                publishedDate
                timeRequiredInSeconds
                previews {
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
              }
              ... on RadivisionPost {
                id
                content
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                revision
                title
              }
              ... on ArticleStory {
                description
                id
                link
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                revision
                subTitle
                title
              }
              ... on BookStory {
                id
                link
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
              }
              ... on NewsletterStory {
                id
                description
                link
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
              }
              ... on PodcastStory {
                id
                link
                publisher
                publishedDate
                durationInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
              }
              ... on OriginalContentStory {
                id
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
              }
            }
          }
        }
        name
        hasPortfolio
        portfolioMarketCap
        portfolioEntities {
          id
        }
        portfolioPeople {
          id
        }
        portfolio {
          count
          pageInfo {
            hasNextPage
          }
          edges {
            edges {
              __typename
              id
              name
              landingPageUrl
              previews(kinds: [SQUARE, LOGO]) {
                edges {
                  preview {
                    link
                    mediaAsset {
                      id
                      files {
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                      }
                    }
                    kind
                  }
                }
              }
              tags {
                label
              }
            }
          }
        }
        people {
          count
          pageInfo {
            hasNextPage
          }
          edges {
            
            person {
              id
              fullName
              responsibility
              description
              landingPageUrl
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
        northStar {
          __typename
          description
          id
          questions {
            __typename
            ... on TextQuestion {
              content
              id
              response {
                content
                id
              }
            }
          }
        }
        tagLine
        socialMediaCredentials {
          credential
          platform {
            kind
          }
        }
        socialMediaProfiles {
          id
          link
          platform {
            # id
            kind
          }
        }
        revision
        websiteUrl
      }
    }
  }
`;
