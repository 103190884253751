import * as React from "react";
import ConfirmModal from "../confirm-modal";
import SearchModal from "../search-modal";

/**
 *
 *
 * @interface ModalConductorProps
 */
interface ModalConductorProps {
  currentModal: "ConfirmModal" | "SearchModal" | "NONE";

  ConfirmModalProps?: {
    /**
     *
     *
     * @type {string}
     */
    title: string;

    /**
     *
     *
     * @type {string}
     */
    body: string;

    /**
     *
     *
     * @type {Function}
     */
    closeAction: Function;
  };

  SearchModalProps?: {
    /**
     *
     *
     * @type {Function}
     */
    handleSearchResults: Function;

    /**
     *
     *
     * @type {{ field: ""; values: string[] }[]}
     * @memberof ModalProps
     */
    filters: { field: string; values: string[] }[];
  };

  close(): void;
}

/**
 *
 *
 * @param {ModalConductorProps} props
 * @returns
 */
function ModalConductor(props: ModalConductorProps): React.ReactElement {
  let modal: React.ReactElement;

  switch (props.currentModal) {
    case "ConfirmModal":
      modal = (
        <ConfirmModal
          body={props.ConfirmModalProps.body}
          title={props.ConfirmModalProps.title}
          close={props.ConfirmModalProps.closeAction}
        />
      );
      break;
    case "SearchModal":
      modal = (
        <SearchModal
          title="Search"
          close={props.close}
          filters={props.SearchModalProps.filters}
          handleSearchResults={props.SearchModalProps.handleSearchResults}
        />
      );
      break;

    default:
      modal = null;
      break;
  }
  return modal;
}

export default ModalConductor;
