/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ClauseOperatorKind = "AND" | "OR" | "%future added value";
export type UpdateDynamicListInput = {
    readonly badges?: ReadonlyArray<string | null> | null;
    readonly clientMutationId: string;
    readonly defaultOrder?: ReadonlyArray<CreateOrderExpressionInput> | null;
    readonly description?: string | null;
    readonly filter: CreateFilterExpressionInput;
    readonly force?: boolean | null;
    readonly id: string;
    readonly label?: string | null;
    readonly name?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly questionnaire?: string | null;
    readonly reviews?: ReadonlyArray<CreateReviewInput> | null;
    readonly revision?: string | null;
    readonly shared?: number | null;
    readonly subTitle?: string | null;
    readonly tags?: ReadonlyArray<string | null> | null;
    readonly title?: string | null;
};
export type CreateOrderExpressionInput = {
    readonly attribute?: string | null;
    readonly descending?: boolean | null;
};
export type CreateFilterExpressionInput = {
    readonly expression?: string | null;
    readonly tags?: CreateTagsClauseInput | null;
};
export type CreateTagsClauseInput = {
    readonly clauses?: ReadonlyArray<CreateTagsClauseInput> | null;
    readonly operator?: ClauseOperatorKind | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type CreateReviewInput = {
    readonly content?: string | null;
    readonly helpful?: number | null;
    readonly notHelpful?: number | null;
    readonly rating?: number | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type updateDynamicListMutationVariables = {
    readonly input: UpdateDynamicListInput;
};
export type updateDynamicListMutationResponse = {
    readonly updateDynamicList: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly dynamicList: {
            readonly __typename: string;
            readonly id: string;
            readonly title: string | null;
        } | null;
    } | null;
};
export type updateDynamicListMutation = {
    readonly response: updateDynamicListMutationResponse;
    readonly variables: updateDynamicListMutationVariables;
};



/*
mutation updateDynamicListMutation(
  $input: UpdateDynamicListInput!
) {
  updateDynamicList(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    dynamicList {
      __typename
      id
      title
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDynamicListInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateDynamicList",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateDynamicListPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dynamicList",
        "storageKey": null,
        "args": null,
        "concreteType": "DynamicList",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateDynamicListMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateDynamicListMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateDynamicListMutation",
    "id": null,
    "text": "mutation updateDynamicListMutation(\n  $input: UpdateDynamicListInput!\n) {\n  updateDynamicList(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    dynamicList {\n      __typename\n      id\n      title\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3a4e03ea0fbba4f716ed7e20db789c41';
export default node;
