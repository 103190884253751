/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-11
 * @description Implementation of Article utilities to be used in the app.
 * @filename entity.ts
 */

import {
  AddToEntityInput,
  CreateEntityInput,
  CreateUpdatePreviewInput,
  CreateUpdateSocialMediaProfileInput,
  SocialMediaPlatformKind,
  UpdateEntityInput,
} from "@radivision/graphql";
import { CreateUpdateSocialMediaCredentialInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-update-social-media-credential-input";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { EntityPageFormInput } from "../component-configuration/cms-pages";
import { ADD_TO_ENTITY_GRAPHQL_MUTATION } from "../relay/mutations/add-to-entity";
import { CREATE_ENTITY_GRAPHQL_MUTATION } from "../relay/mutations/create-entity";
import { DELETE_ENTITY_GRAPHQL_MUTATION } from "../relay/mutations/delete-entity";
import { UPDATE_ENTITY_GRAPHQL_MUTATION } from "../relay/mutations/update-entity";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { getClientMutationId } from "./general";
import { commitMutation } from "./relay";

/**
 *
 *
 * @export
 * @class Entity
 */
export class Entity {
  /**
   *
   *
   * @static
   * @param {*} entityInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static addEntity(
    entityInput: EntityPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateArticleStoryInput
    let createEntityInput: CreateEntityInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let previewsInput: CreateUpdatePreviewInput[];
    const SOCIAL_MEDIA_PROFILE_INPUT: any = Entity.handleSocialMediaCredentialsInput(
      entityInput
    );

    if (
      entityInput.previews !== undefined &&
      entityInput.previews !== null &&
      entityInput.previews.length > 0
    ) {
      // handle preview inputs
      // handle preview inputs
      previewsInput = [];
      for (const PREVIEW of entityInput.previews) {
        previewsInput.push({
          kind: PREVIEW.kind,
          link: PREVIEW.link,
          mediaAsset: PREVIEW.mediaAsset,
        });
      }
    }

    // console.log("[addEntity] provided input ", JSON.stringify(entityInput));

    createEntityInput = {
      clientMutationId: getClientMutationId(),
      name:
        entityInput.name && entityInput.name.length > 0
          ? entityInput.name.trim()
          : undefined,
      description:
        entityInput.description && entityInput.description.length > 0
          ? entityInput.description.trim()
          : undefined,
      socialMediaCredentials:
        SOCIAL_MEDIA_PROFILE_INPUT &&
        SOCIAL_MEDIA_PROFILE_INPUT.cred &&
        SOCIAL_MEDIA_PROFILE_INPUT.cred.length
          ? SOCIAL_MEDIA_PROFILE_INPUT.cred
          : undefined,
      socialMediaProfiles:
        SOCIAL_MEDIA_PROFILE_INPUT &&
        SOCIAL_MEDIA_PROFILE_INPUT.profile &&
        SOCIAL_MEDIA_PROFILE_INPUT.profile.length
          ? SOCIAL_MEDIA_PROFILE_INPUT.profile
          : undefined,

      tags:
        entityInput.tags && entityInput.tags.length > 0
          ? entityInput.tags
          : undefined,
      mediaAbout:
        entityInput.mediaAbout && entityInput.mediaAbout.length
          ? entityInput.mediaAbout
          : undefined,
      previews: previewsInput,
      websiteUrl:
        entityInput.websiteUrl && entityInput.websiteUrl.length > 0
          ? entityInput.websiteUrl.trim()
          : undefined,
      portfolioEntities:
        entityInput.portfolioEntities &&
        entityInput.portfolioEntities.length > 0
          ? entityInput.portfolioEntities.trim()
          : undefined,
      portfolioPeople:
        entityInput.portfolioPeople && entityInput.portfolioPeople.length > 0
          ? entityInput.portfolioPeople.trim()
          : undefined,
    };

    // initialize mutation configuration
    config = {
      mutation: CREATE_ENTITY_GRAPHQL_MUTATION,
      variables: {
        input: createEntityInput,
      },
    };
    //
    promise = commitMutation(environment, config);

    promise = promise.then((mutationResults: any) => {
      // console.log(mutationResults);

      return mutationResults.createEntity.hasErrors === false
        ? Promise.resolve(mutationResults.createEntity.entity)
        : Promise.reject(mutationResults.createEntity.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static updateEntity(
    entityInput: EntityPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    const updateEntityInput: UpdateEntityInput = Entity.generateAddToUpdateEntityInput(
      entityInput
    );
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (updateEntityInput === undefined || updateEntityInput === null) {
      promise = Promise.reject(new Error("qRLjjKOjOkyULFZjSRvLnA"));
    }
    // initialize mutation configuration
    config = {
      mutation: UPDATE_ENTITY_GRAPHQL_MUTATION,
      variables: {
        input: updateEntityInput,
      },
    };

    //
    promise = commitMutation(environment, config);
    //
    promise = promise.then((mutationResults: any) => {
      return mutationResults.updateEntity.hasErrors === false
        ? Promise.resolve(mutationResults.updateEntity.entity)
        : Promise.reject(mutationResults.updateEntity.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static addToEntity(
    entityInput: EntityPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    const addToEntityInput: UpdateEntityInput = Entity.generateAddToUpdateEntityInput(
      entityInput
    );
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (addToEntityInput === undefined || addToEntityInput === null) {
      promise = Promise.reject(new Error("rXjGf87P4UiAjfiE8LFDCQ"));
    }

    // initialize mutation configuration
    config = {
      mutation: ADD_TO_ENTITY_GRAPHQL_MUTATION,
      variables: {
        input: addToEntityInput,
      },
    };

    //
    promise = commitMutation(environment, config);
    //
    promise = promise.then((mutationResults: any) => {
      return mutationResults.addToEntity.hasErrors === false
        ? Promise.resolve(mutationResults.addToEntity.entity)
        : Promise.reject(mutationResults.addToEntity.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static deleteEntity(
    entityInput: EntityPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (
      entityInput === undefined ||
      entityInput === null ||
      entityInput.id === undefined
    ) {
      promise = Promise.reject(new Error("rT5GYiG4RECcoCERNRyNgw"));
    }

    const input = {
      id: entityInput.id,
      revision: entityInput.revision,
      clientMutationId: getClientMutationId(),
    };

    config = {
      mutation: DELETE_ENTITY_GRAPHQL_MUTATION,
      variables: {
        input,
      },
    };

    //
    promise = commitMutation(environment, config);
    //
    promise = promise.then((mutationResults: any) => {
      return mutationResults.deleteEntity.hasErrors === false
        ? Promise.resolve(mutationResults.deleteEntity.entity)
        : Promise.reject(mutationResults.deleteEntity.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {PersonPageFormInput} personInput
   * @returns {CreateUpdateSocialMediaCredentialInput[]}
   * @memberof Person
   */
  static handleSocialMediaCredentialsInput(
    personInput: EntityPageFormInput
  ): any {
    let socialMediaCredentialsInput: CreateUpdateSocialMediaCredentialInput[];
    let socialMediaProfilesInput: CreateUpdateSocialMediaProfileInput[];
    if (
      personInput.socialMediaProfiles !== undefined &&
      personInput.socialMediaProfiles !== null
    ) {
      socialMediaCredentialsInput = [];
      socialMediaProfilesInput = [];

      if (
        personInput.socialMediaProfiles.FacebookID !== undefined &&
        personInput.socialMediaProfiles.FacebookID !== null &&
        personInput.socialMediaProfiles.FacebookID.length > 1
      ) {
        socialMediaCredentialsInput.push({
          platform: SocialMediaPlatformKind.FACEBOOK,
          credentials: personInput.socialMediaProfiles.FacebookID.trim(),
        });
        socialMediaProfilesInput.push({
          platform: SocialMediaPlatformKind.FACEBOOK,
          url: `${"https://www.facebook.com/"}${personInput.socialMediaProfiles.FacebookID.trim()}`,
        });
      }

      if (
        personInput.socialMediaProfiles.InstagramID !== undefined &&
        personInput.socialMediaProfiles.InstagramID !== null &&
        personInput.socialMediaProfiles.InstagramID.length > 1
      ) {
        socialMediaCredentialsInput.push({
          platform: SocialMediaPlatformKind.INSTAGRAM,
          credentials: personInput.socialMediaProfiles.InstagramID.trim(),
        });
        socialMediaProfilesInput.push({
          platform: SocialMediaPlatformKind.INSTAGRAM,
          url: `${"https://www.instagram.com/"}${personInput.socialMediaProfiles.InstagramID.trim()}`,
        });
      }

      if (
        personInput.socialMediaProfiles.LinkedInID !== undefined &&
        personInput.socialMediaProfiles.LinkedInID !== null &&
        personInput.socialMediaProfiles.LinkedInID.length > 1
      ) {
        socialMediaCredentialsInput.push({
          platform: SocialMediaPlatformKind.LINKED_IN,
          credentials: personInput.socialMediaProfiles.LinkedInID.trim(),
        });
        socialMediaProfilesInput.push({
          platform: SocialMediaPlatformKind.LINKED_IN,
          url: `${"https://www.linkedin.com/company/"}${personInput.socialMediaProfiles.LinkedInID.trim()}`,
        });
      }

      if (
        personInput.socialMediaProfiles.TwitterID !== undefined &&
        personInput.socialMediaProfiles.TwitterID !== null &&
        personInput.socialMediaProfiles.TwitterID.length > 1
      ) {
        socialMediaCredentialsInput.push({
          platform: SocialMediaPlatformKind.TWITTER,
          credentials: personInput.socialMediaProfiles.TwitterID.trim(),
        });
        socialMediaProfilesInput.push({
          platform: SocialMediaPlatformKind.TWITTER,
          url: `${"https://www.twitter.com/"}${personInput.socialMediaProfiles.TwitterID.trim()}`,
        });
      }
    }

    return {
      cred: socialMediaCredentialsInput,
      profile: socialMediaProfilesInput,
    };
  }

  /**
   *
   *
   * @static
   * @param {EntityPageFormInput} entityInput
   * @returns {(UpdateEntityInput | AddToEntityInput)}
   * @memberof Entity
   */
  static generateAddToUpdateEntityInput(
    entityInput: EntityPageFormInput
  ): UpdateEntityInput | AddToEntityInput {
    let updateEntityInput: UpdateEntityInput | AddToEntityInput;

    let previewsInput: CreateUpdatePreviewInput[];
    const SOCIAL_MEDIA_PROFILE_INPUT: any = Entity.handleSocialMediaCredentialsInput(
      entityInput
    );

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[updateEntity] provided input ", JSON.stringify(entityInput));

    if (
      entityInput.id !== undefined ||
      entityInput.id !== null ||
      entityInput.id.length > 0
    ) {
      if (
        entityInput.previews !== undefined &&
        entityInput.previews !== null &&
        entityInput.previews.length > 0
      ) {
        previewsInput = [];
        for (const PREVIEW of entityInput.previews) {
          previewsInput.push({
            kind: PREVIEW.kind,
            link: PREVIEW.link,
            mediaAsset: PREVIEW.mediaAsset,
          });
        }
      }

      // initialize create article graphQl input
      updateEntityInput = {
        clientMutationId: getClientMutationId(),
        id: entityInput.id,
        revision:
          entityInput.revision && entityInput.revision.length > 0
            ? entityInput.revision.trim()
            : undefined,
        name:
          entityInput.name && entityInput.name.length > 0
            ? entityInput.name.trim()
            : undefined,
        description:
          entityInput.description && entityInput.description.length > 0
            ? entityInput.description.trim()
            : undefined,
        industry:
          entityInput.industry !== undefined && entityInput.industry !== null
            ? entityInput.industry
            : undefined,
        founded:
          entityInput.founded !== undefined && entityInput.founded !== null
            ? entityInput.founded
            : undefined,
        fundingRound:
          entityInput.fundingRound && entityInput.fundingRound.length > 0
            ? entityInput.fundingRound.trim()
            : undefined,
        noOfEmployees: entityInput.noOfEmployees
          ? entityInput.noOfEmployees
          : undefined,
        portfolioMarketCap: entityInput.portfolioMarketCap
          ? entityInput.portfolioMarketCap
          : undefined,
        noOfPortfolioCompanies: entityInput.noOfPortfolioCompanies
          ? entityInput.noOfPortfolioCompanies
          : undefined,
        isFundraising: entityInput.isFundraising
          ? entityInput.isFundraising
          : undefined,
        socialMediaCredentials:
          SOCIAL_MEDIA_PROFILE_INPUT &&
          SOCIAL_MEDIA_PROFILE_INPUT.cred &&
          SOCIAL_MEDIA_PROFILE_INPUT.cred.length
            ? SOCIAL_MEDIA_PROFILE_INPUT.cred
            : undefined,
        socialMediaProfiles:
          SOCIAL_MEDIA_PROFILE_INPUT &&
          SOCIAL_MEDIA_PROFILE_INPUT.profile &&
          SOCIAL_MEDIA_PROFILE_INPUT.profile.length
            ? SOCIAL_MEDIA_PROFILE_INPUT.profile
            : undefined,
        tags:
          entityInput.tags && entityInput.tags.length > 0
            ? entityInput.tags
            : undefined,

        northStar:
          entityInput.northStar && entityInput.northStar.length > 0
            ? entityInput.northStar
            : undefined,
        mediaAbout:
          entityInput.mediaAbout && entityInput.mediaAbout.length
            ? entityInput.mediaAbout
            : undefined,
        websiteUrl:
          entityInput.websiteUrl && entityInput.websiteUrl.length > 0
            ? entityInput.websiteUrl.trim()
            : undefined,
        force: entityInput.force,
        locations:
          entityInput.locations && entityInput.locations.length > 0
            ? entityInput.locations
            : undefined,
        people:
          entityInput.people && entityInput.people.length > 0
            ? entityInput.people
            : undefined,
        portfolioEntities:
          entityInput.portfolioEntities &&
          entityInput.portfolioEntities.length > 0
            ? entityInput.portfolioEntities.trim()
            : undefined,
        portfolioPeople:
          entityInput.portfolioPeople && entityInput.portfolioPeople.length > 0
            ? entityInput.portfolioPeople.trim()
            : undefined,
        previews: previewsInput,
      };
    }

    if ((updateEntityInput as UpdateEntityInput).founded) {
      // validate provided publish date
      const currentTime: number = new Date().getTime();
      const founded = new Date(
        (updateEntityInput as UpdateEntityInput).founded
      ).getTime();

      if (currentTime < founded) {
        throw new Error("Please enter valid publish date");
      }
    }
    return updateEntityInput;
  }
}
