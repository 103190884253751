/**
 * @author Ahmed Samer
 * @date   2019-08-18
 * @description page to list user  group
 * @filename identity-pool-users
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { Loader } from "../../../page/loader";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { FloatingMenu } from "../floating-button/floating-menu";
import { CSVLink } from "react-csv";

/**
 *
 */
const DOWNLOAD_SVG = require("../../../../../static/download.png");

const DataTable = require("react-data-components").DataTable;

/**
 *
 *
 * @interface cognitoUser
 */
interface cognitoUser {
  /**
   *
   *
   * @type {string}
   * @memberof cognitoUser
   */
  email: string;

  /**
   *
   *
   * @type {string}
   * @memberof cognitoUser
   */
  name: string;

  /**
   *
   *
   * @type {string}
   * @memberof cognitoUser
   */
  status: string;

  /**
   *
   *
   * @type {string}
   * @memberof cognitoUser
   */
  createDate: string;

  /**
   *
   *
   * @type {string}
   * @memberof cognitoUser
   */
  lastModifiedDate: string;
}

/**
 *
 *
 * @export
 * @interface GroupPageProps
 */
export interface UsersReportProps {
  /**
   *
   *
   * @type {cognitoUser[]}
   * @memberof UsersReportProps
   */
  usersReport: { unconfirmed?: cognitoUser[]; confirmed: any[] };
}

/**
 *
 *
 * @interface GroupPageState
 */
interface UsersReportState {
  /**
   *
   *
   * @type {any []}
   * @memberof GroupPageState
   */
  items: any[];

  /**
   *
   *
   * @type {boolean}
   * @memberof GroupPageState
   */
  isLoading: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof GroupPageState
   */
  selected?: string;

  /**
   *
   *
   * @type {boolean}
   * @memberof GroupPageState
   */
  addModal: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof GroupPageState
   */
  email?: string;

  /**
   *
   *
   * @type {any[]}
   * @memberof GroupPageState
   */
  headers: any[];
}

/**
 *
 *
 * @export
 * @class GroupPage
 * @extends {React.Component<UsersReportProps, UsersReportState>}
 */
export class UsersReport extends React.Component<
  UsersReportProps,
  UsersReportState
> {
  /**
   * Creates an instance of AddItemPage.
   * @param {*} props
   * @memberof AddItemPage
   */
  constructor(props: UsersReportProps) {
    super(props);

    this.closeModalAction = this.closeModalAction.bind(this);
    this.state = this.handleComponentStateInitialize(this.props);
  }

  /**
   *
   *
   * @param {GroupPageProps} props
   * @returns {GroupPageState}
   * @memberof GroupUsersPage
   */
  handleComponentStateInitialize(props: UsersReportProps): UsersReportState {

    const options: any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    };

    const HEADERS = [

      {
        title: "id",
        prop: "id",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        title: "Account State",
        prop: "status",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        title: "Full-Name",
        prop: "name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        title: "Email",
        prop: "email",
        options: {
          filter: false,
          sort: false,
        },
      },

      {
        title: "Sign up Date",
        prop: "createDate",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        title: "Last login Date",
        prop: "lastLogin",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        title: "Last Activity Date",
        prop: "lastModified",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        title: "Owner of a company",
        prop: "hasOwnedCompany",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        title: "LinkedIn profile",
        prop: "linkedInProfile",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        title: "Profile Link",
        prop: "profileLink",
        options: {
          filter: false,
          sort: false,
        },
      },
    ];

    let state: UsersReportState = {
      headers: HEADERS,
      items: [],
      addModal: false,
      isLoading: false,
    };

    if (props.usersReport) {
      if (props.usersReport.confirmed) {
        const registeredItem = props.usersReport.confirmed.map((item) => {
          const LI = item.socialMediaProfiles && item.socialMediaProfiles.length ?
            item.socialMediaProfiles.filter((profile) => {
              return profile?.platform?.kind === "LINKED_IN";
            }) : [];

          return {
            id: item.id,
            name: item.fullName,
            email: item.emailAddresses[0],
            lastLogin: item.lastLogin ? new Date(item.lastLogin || item.lastModified).toLocaleDateString('en-US', options) : 0,
            lastModified: item.lastModified ? new Date(item.lastModified).toLocaleDateString('en-US', options) : 0,
            status: "confirmed",
            type: "username & pw",
            linkedInProfile: LI && LI.length ? LI[0].link : null,
            hasOwnedCompany: item.entities && item.entities.length ? "YES" : "NO",
            createDate: item.created ? new Date(item.created).toLocaleDateString('en-US', options) : 0,
            profileLink:
              item.landingPageUrl ||
              `https://radivision.com/person/${item.id}` ||
              "unavailable",
          };
        });

        state.items = [...registeredItem];
      }
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof GroupUsersPage
   */
  render() {
    return (
      <div className="dark-bg">
        <div className="AddItemPage container">
          <div className="row">
            <div className="col-md-10">
              <h1 className="text-center">
                Users List
              </h1>
            </div>
            <div className="col-md-2">
              <CSVLink
                data={this.state.items}
                filename={"RAD-USERS-LIST.csv"}
                className="btn"
                target="_blank"
              >
                <img src={"https://content.radivision.com/images/00/11/5b2fe3dddadc97.png"} />
              </CSVLink>
            </div>
          </div>

          <div className="table_wrapper">
            <DataTable
              className="table table-bordered"
              buildRowOptions={this.buildRowOptions}
              keys="id"
              columns={this.state.headers}
              initialData={this.state.items}
              initialPageLength={100}
              initialSortBy={{ prop: "createDate", order: "descending" }}
              pageLengthOptions={[100, 500, 2000]}
            />
          </div>
        </div>
        <Loader isActive={this.state.isLoading} />
        <FloatingMenu menuItems={{}} />
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in email state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof GroupUsersPage
   */
  handleEmailChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let email: string = this.state.email;
    let eventValue: string = changeEvent.currentTarget.value;

    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    email = eventValue;
    this.setState({ email });
  }

  /**
   *
   *
   * @memberof GroupUsersPage
   */
  closeModalAction() {
    this.setState({ addModal: false });
  }

  getInitialState = () => {
    return { selected: "" };
  };

  selectRow = (row: any) => { };

  buildRowOptions = (row: any) => {
    return {
      onClick: this.selectRow.bind(this, row),
      className: this.state.selected === row.id ? "active" : null,
    };
  };
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class UsersReportContainer extends React.Component<
  UsersReportProps,
  UsersReportState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;

    node = (
      <QueryRenderer
        environment={ENVIRONMENT}
        query={graphql`
          query usersReportQuery {
            usersReport {
              errors {
                id
                location
              }
              hasErrors
              usersReport {
                confirmed {
                  fullName
                  id
                  created
                  landingPageUrl
                  emailAddresses
                  lastModified
                  lastLogin
                  entities {
                    __typename
                    id
                    name
                  }
                  socialMediaProfiles {
                    id
                    link
                    platform {
                      id
                      kind
                    }
                  }
                  
                }
                unconfirmed {
                  name
                  email
                  status
                  createDate
                  lastModifiedDate
                }
              }
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            if (props.usersReport.hasErrors) {
              return (
                <div>
                  <p>
                    unexpected error : {JSON.stringify(props.errors)}, contact
                    Radivision Technical team.
                  </p>{" "}
                </div>
              );
            } else if (props.usersReport.usersReport) {
              let pageProps: UsersReportProps = {
                ...this.props,
                usersReport: props.usersReport.usersReport,
              };
              return <UsersReport {...pageProps} />;
            }
          }
          return <Loader isActive={true} />;
        }}
      />
    );

    return node;
  }
}
