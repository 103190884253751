/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-09-11
 * @description Implementation of AccountControl react component
 * @filename account-control.tsx
 */
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import React from "react";
import { Router } from "../../../utilities/router";
import { Modal } from "../modal";
import { AccountVerificationModal } from "./account-verification-modal";
import { MessageModal } from "./message-modal";

const isEqual = require("lodash.isequal");

/**
 * The props of AccountControl Component.
 *
 * @interface AccountControlProps
 */
interface AccountControlProps {
  /**
   * The active modal
   *
   * @type {any}
   * @memberof AccountControlProps
   */
  activeModal: any;
  /**
   * The id of the active modal to be rendered.
   *
   * @type {string}
   * @memberof AccountControlProps
   */
  modalId: string;

  /**
   * props to be sent to the modal.
   *
   * @type {*}
   * @memberof AccountControlProps
   */
  modalProps?: any;

  /**
   *
   *
   * @type {string}
   * @memberof AccountControlProps
   */
  title?: string;
}

/**
 * The state of the AccountControl Component.
 *
 * @interface AccountControlState
 */
interface AccountControlState {
  /**
   * The currently rendered modal.
   *
   * @type {*}
   * @memberof AccountControlState
   */
  activeModal: any;

  /**
   * The ID of the current active Modal.
   *
   * @type {string}
   * @memberof AccountControlState
   */
  activeModalId: string;

  /**
   * Additional props associated with the active modal.
   *
   * @type {Object}
   * @memberof AccountControlState
   */
  activeModalProps?: {};

  /**
   * A flag which is true if the modal is visible.
   *
   * @type {boolean}
   */
  isModalVisible: boolean;
  /**
   * The title of the modal.
   *
   * @type {string}
   */
  title?: string;

  /**
   * the cognito user to be used in the modals.
   *
   * @type {AmazonCognitoIdentity.CognitoUser}
   * @memberof AccountControlState
   */
  user: AmazonCognitoIdentity.CognitoUser;
}

/**
 * A Regular expression used to validate the number of numbers in the password.
 *
 * @type {RegExp}
 */
const PASSWORD_NUMBERS: RegExp = /([0-9])/g;

/**
 * A Regular expression used to validate the number of lower-case characters in the password.
 *
 * @type {RegExp}
 */
const PASSWORD_LOWERCASE_LETTERS: RegExp = /[a-z]/g;

/**
 * A Regular expression used to validate the number of special characters in the password.
 *
 * @type {RegExp}
 */
const PASSWORD_SPECIAL: RegExp = /([!@#$%^&*])/g;

/**
 * A Regular expression used to validate the number of upper-case characters in the password.
 *
 * @type {RegExp}
 */
const PASSWORD_UPPERCASE_LETTERS: RegExp = /([A-Z])/g;
/**
 * The React component responsible for controlling the active modals related to account.
 *
 * @export
 * @class AccountControl
 * @template AccountControlProps
 * @template AccountControlState
 */
export class AccountControl extends React.Component<
  AccountControlProps,
  AccountControlState
> {
  /**
   * A reference to the parent modal.
   *
   * @type {React.RefObject<Modal>}
   */
  modalRef: React.RefObject<Modal>;

  /**
   * Constructor.
   *
   * @param {AccountControlProps} props The props of the component.
   */
  constructor(props: AccountControlProps) {
    super(props);
    this.changeModal = this.changeModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.confirmForgotPassword = this.confirmForgotPassword.bind(this);
    this.displayPasswordResetConfirmationModal = this.displayPasswordResetConfirmationModal.bind(
      this
    );
    this.modalRef = React.createRef();
    this.navigateToTechnicalSupport = this.navigateToTechnicalSupport.bind(
      this
    );
    this.resetPassword = this.resetPassword.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.signUpUser = this.signUpUser.bind(this);
    this.verifyAccountFromVerificationCode = this.verifyAccountFromVerificationCode.bind(
      this
    );

    // avoid to set component state with provided props
    this.state = {
      activeModal: null,
      activeModalId: null,
      isModalVisible: true,
      user: null,
    };
  }

  /**
   * Changes the active modal.
   *
   * @param {React.Component} modal The new modal to be active.
   *
   * @memberof AccountControl
   */
  changeModal(modal: any = null, props: {} = null): void {
    this.setState((state: AccountControlState) => {
      return {
        activeModal: modal,
        activeModalProps: props,
      };
    });
  }

  /**
   * Closes the modal.
   */
  closeModal(): void {
    this.modalRef.current.close();
  }

  /**
   * Changes the state's active modal to render the correct modal when clicked in the navbar
   *
   * @param {AccountControlProps} nextModalProps the props received from parent controller
   */
  componentWillReceiveProps(nextModalProps: AccountControlProps): void {
    if (nextModalProps.activeModal !== this.state.activeModal) {
      this.setState(() => {
        return {
          activeModal: nextModalProps.activeModal,
        };
      });
    }
  }

  /**
   *
   *
   * @param {AccountControlProps} nextModalProps
   * @param {AccountControlState} nextModalState
   * @returns {boolean}
   * @memberof AccountControl
   */
  shouldComponentUpdate(
    nextModalProps: AccountControlProps,
    nextModalState: AccountControlState
  ): boolean {
    const shouldUpdate: boolean =
      !isEqual(nextModalState, this.state) ||
      !isEqual(nextModalProps, this.props);
    // // console.log("[AccountControl-shouldComponentUpdate] ",shouldUpdate);
    return shouldUpdate;
  }

  /**
   * Returns a promise to confirm a changed password.
   *
   * @param {string} email The email address of the account whose password is to be reset.
   *
   * @param {string} confirmationCode The code to reset the password.
   *
   * @param {string} password The new password.
   *
   * @returns {Promise<CognitoIdentityServiceProvider.ConfirmForgotPasswordResponse>} A promise to confirm the change to a password.
   */
  confirmForgotPassword(
    email: string,
    confirmationCode: string,
    password: string
  ): Promise<any> {
    return import("../../../authentication/cognito-client").then((module) => {
      return module.CognitoClient.confirmForgotPassword(
        email,
        confirmationCode,
        password
      );
    });
  }

  /**
   * Displays a modal with the password reset confirmation message.
   */
  displayPasswordResetConfirmationModal(): void {
    this.changeModal(MessageModal, {
      title: "Password Reset Complete",
      hasCloseButton: true,
      message: "Your password has been reset.",
    });
  }

  /**
   * Navigates to the technical support page and closes the modal.
   */
  navigateToTechnicalSupport() {
    Router.to("support", undefined, "contact-us");
    this.closeModal();
  }

  /**
   * Navigates to the terms and conditions page and closes the modal.
   */
  navigateToTermsAndConditions() {
    Router.to("legal", undefined, "terms-conditions");
    this.closeModal();
  }

  /**
   * Returns a ReactElement containing the rendered component.
   *
   * @returns {React.ReactElement<*>} The ReactElement containing the rendered component.
   */
  render(): React.ReactElement<any> {
    const ACTIVEMODAL =
      this.state.activeModal === null
        ? this.props.activeModal
        : this.state.activeModal;
    let availableProps;

    if (this.state.activeModalProps === null) {
      availableProps = {
        closeModal: this.closeModal,
        confirmForgotPassword: this.confirmForgotPassword,
        displayPasswordResetConfirmationModal: this
          .displayPasswordResetConfirmationModal,
        navigateToTechnicalSupport: this.navigateToTechnicalSupport,
        navigateToTermsAndConditions: this.navigateToTermsAndConditions,
        resetPassword: this.resetPassword,
        signUpUser: this.signUpUser,
        changeModal: this.changeModal,
        verifyAccountFromVerificationCode: this
          .verifyAccountFromVerificationCode,
        validatePassword: this.validatePassword,
        resendAccountVerificationEMail: this.resendAccountVerificationEMail,
        setTitle: this.setTitle,
      };
    } else {
      availableProps = Object.assign({}, this.state.activeModalProps, {
        closeModal: this.closeModal,
        confirmForgotPassword: this.confirmForgotPassword,
        displayPasswordResetConfirmationModal: this
          .displayPasswordResetConfirmationModal,
        navigateToTechnicalSupport: this.navigateToTechnicalSupport,
        navigateToTermsAndConditions: this.navigateToTermsAndConditions,
        resetPassword: this.resetPassword,
        signUpUser: this.signUpUser,
        changeModal: this.changeModal,
        verifyAccountFromVerificationCode: this
          .verifyAccountFromVerificationCode,
        validatePassword: this.validatePassword,
        resendAccountVerificationEMail: this.resendAccountVerificationEMail,
        setTitle: this.setTitle,
      });
    }
    if (this.props.modalProps !== undefined && this.props.modalProps !== null) {
      availableProps = {
        ...availableProps,
        ...this.props.modalProps,
      };
    }
    return (
      <Modal
        id={this.props.modalId}
        ref={this.modalRef}
        logo={false}
        title={this.props.title ? this.props.title : this.state.title}
      >
        <ACTIVEMODAL {...availableProps} />
      </Modal>
    );
  }

  validatePassword(password: string = ""): boolean {
    let lowercase: number;
    let numbers: number;
    let result: RegExpMatchArray;
    let special: number;
    let uppercase: number;

    result = password.match(PASSWORD_LOWERCASE_LETTERS);
    lowercase = result === null ? 0 : result.length;
    result = password.match(PASSWORD_NUMBERS);
    numbers = result === null ? 0 : result.length;
    result = password.match(PASSWORD_UPPERCASE_LETTERS);
    uppercase = result === null ? 0 : result.length;
    result = password.match(PASSWORD_SPECIAL);
    special = result === null ? 0 : result.length;
    return (
      password.length === 0 ||
      (lowercase > 0 &&
        uppercase > 0 &&
        numbers > 0 &&
        special > 0 &&
        lowercase + uppercase + numbers + special > 7)
    );
  }

  /**
   * Returns a promise to reset the password for a given email.
   *
   * @param {string} email The email to reset the password.
   *
   * @returns {Promise<*>} The promise to reset the password for a given email.
   */
  resetPassword(email: string): Promise<any> {
    return import("../../../authentication/cognito-client").then((module) => {
      return module.CognitoClient.forgotAccountPassword(email);
    });
  }

  /**
   * Returns a promise to resend the account verification email to the user.
   *
   * @param {string} email The email to resend the verification code.
   *
   * @returns {Promise<*>} The promise to resend the account verification code to the user.
   */
  resendAccountVerificationEMail(email: string): Promise<any> {
    return import("../../../authentication/cognito-client").then((module) => {
      return module.CognitoClient.resendAccountConfirmationCode(email);
    });
  }

  /**
   * Sets the title of the dialog title.
   *
   * @param {string} title The title of the dialog
   */
  setTitle(title: string = ""): void {
    this.setState({
      title,
    });
  }

  /**
   * Returns a promise to register the user using the email and password and an optional name.
   *
   * @param {string} email The email.
   *
   * @param {string} password A verified password.
   *
   * @param {string} name The name of the user.
   *
   * @returns {Promise<*>} The promise to register the user using the email and password.
   */
  signUpUser(auth: {
    email?: string;
    password?: string;
    provider?: "facebook" | "google" | "linkedin" | "twitter";
    authObject?: any;
    name?: string;
  }): Promise<any> {
    let promise: Promise<any> = import(
      "../../../authentication/cognito-client"
    );

    if (auth.provider !== undefined && auth.provider !== null) {
      switch (auth.provider) {
        case "facebook":
          promise = promise.then((module) => {
            return module.CognitoClient.loginWithFacebookAccount(
              auth.authObject
            );
          });
          break;
        case "google":
          promise = promise.then((module) => {
            return module.CognitoClient.loginWithGoogleAccount(auth.authObject);
          });
          break;
        case "linkedin":
          promise = promise.then((module) => {
            return module.CognitoClient.loginWithLinkedInAccount(
              auth.authObject
            );
          });
          break;
        case "twitter":
          promise = promise.then((module) => {
            return module.CognitoClient.loginWithTwitterAccount(
              auth.authObject
            );
          });
          break;

        default:
      }
    } else {
      promise = promise.then((module) => {
        return module.CognitoClient.createAccountUsingEmailAndPassword(
          auth.email,
          auth.password,
          auth.name
        ).then(() => {
          if (auth.provider === undefined || auth.provider === null) {
            this.changeModal(AccountVerificationModal, {
              email: auth.email,
              userPassword: auth.password,
            });
          } else {
            window.location.reload();
          }
        });
      });
    }
    return promise;
  }

  /**
   * Returns a promise to verify a user account using an account verification code.
   *
   * @param {string} email The email address of the account to be verified.
   *
   * @param {string} verificationCode An account verification code.
   *
   * @returns {Promise<CognitoIdentityServiceProvider.ConfirmSignUpResponse>} The promise to verify a user account using an account
   * verification code.
   */
  verifyAccountFromVerificationCode(
    email: string,
    verificationCode: string
  ): Promise<any> {
    return import("../../../authentication/cognito-client").then((module) => {
      return module.CognitoClient.verifyAccountCreationFromVerificationCode(
        email,
        verificationCode
      );
    });
  }
}
