/**
 * @author Ahmed Samerss
 * @date   2019-11-08
 * @description versioning-page class
 * @filename versioning-page.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import * as React from "react";
import { VersionCard } from "./versioning-card";
import { CardProps } from "./card-configuration";
import { fetchQuery } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { VERSIONING_QUERY } from "../../../../relay/queries/versioning-query";
import { ImageHelper } from "../../../../utilities/image-helper";
import { PreviewKind, TopLevelMediaKind } from "@radivision/graphql";
import { Diff } from "./version-view-page";
import { FloatingMenu } from "../floating-button/floating-menu";

/**
 *
 *
 * @interface ItemPageState
 */
interface VersioningPageState {
  /**
   *
   *
   * @type {{ title: string; icon: string; alias: string }[]}
   * @memberof ItemPageState
   */
  items: { record: any; props: CardProps; versions: any[] }[];

  /**
   *
   *
   * @type {React.ReactNode}
   * @memberof VersioningPageState
   */
  pageContent: React.ReactNode;
}

/**
 *
 *
 * @export
 * @class AddItemPage
 * @extends {React.Component<any, ItemPageState>}
 */
export class VersioningPage extends React.Component<any, VersioningPageState> {
  /**
   * Creates an instance of AddItemPage.
   * @param {*} props
   * @memberof AddItemPage
   */
  constructor(props: any) {
    super(props);

    //... bind needed function to class
    this.showVersionDiff = this.showVersionDiff.bind(this);
    this.setDefaultSlide = this.setDefaultSlide.bind(this);

 

    this.state = {
      items: [],

      pageContent: (
        <div  className={"fullSize"}>
          {" "}
        </div>
      )
    };
  }

  /**
   *
   *
   * @param {*} event
   * @memberof VersionCard
   */
  showVersionDiff(event: any, index: number) {
    // // console.log(index);
    this.setState({
      pageContent: (
        <Diff
          source={JSON.stringify(this.state.items[index].record, null, 4)}
          updates={JSON.stringify(this.state.items[index].versions[0], null, 4)}
        />
      )
    });
  }

  /**
   *
   *
   * @returns
   * @memberof VersioningPage
   */
  componentDidMount() {
    return fetchQuery(ENVIRONMENT, VERSIONING_QUERY, {}).then(
      (queryResults: any) => {
        let items: { record: any; props: CardProps; versions: any[] }[] = this
          .state.items;
        for (const VERSION of queryResults.versioning.version) {
          if (VERSION !== undefined && VERSION !== null) {
            const ORIGINAL_RECORD = VERSION.item;
            const RECORD_VERSIONS: any[] = VERSION.versions.versionList;

            let imageUrl = ImageHelper.fetchOptimizedImageUrl({
              imageType: "PREVIEW",
              preview: {
                content: ORIGINAL_RECORD.previews,
                previewKind: [
                  PreviewKind.CAROUSEL,
                  PreviewKind.SQUARE,
                  PreviewKind.HEADSHOT,
                  ,
                  PreviewKind.LOGO
                ],
                topLevelMedia: TopLevelMediaKind.IMAGE
              },
              desiredDimensions: {
                containerWidthRatio: 1,
                numberOfItems: 3
              },
              revision: ORIGINAL_RECORD.revision
            }).requestedResolutionUrl;
            // ... ADD VALIDATION LAYER
            items.push({
              record: ORIGINAL_RECORD,
              versions: RECORD_VERSIONS,
              props: {
                body: {
                  index: items.length,
                  date: ORIGINAL_RECORD.created
                    ? new Date(ORIGINAL_RECORD.created).toDateString()
                    : "",
                  text: `# of versions : ${RECORD_VERSIONS.length}`,
                  title:
                    ORIGINAL_RECORD.title ||
                    ORIGINAL_RECORD.name ||
                    ORIGINAL_RECORD.fullName,
                  clickAction: this.showVersionDiff
                },
                header: {
                  // imageUrl: "https://source.unsplash.com/user/erondu/600x400",
                  imageUrl,
                  title: ORIGINAL_RECORD.__typename
                }
              }
            });
          }
        }
        this.setState({ items }, () => {
          this.setDefaultSlide();
        });
      }
    );
  }

  /**
   *
   *
   * @param {[]} items
   * @memberof VersioningPage
   */
  setDefaultSlide() {
    this.setState({
      pageContent: (
        <div className="AddItemPage container">
          {this.state.items.map((item, index) => {
            return <VersionCard key={index} {...item.props} />;
          })}
        </div>
      )
    });
  }
  /**
   *
   *
   * @returns
   * @memberof AddItemPage
   */
  render() {
    return (
      <div className="dark-bg">
        {this.state.pageContent}

        <FloatingMenu
          menuItems={{
            saveable: () => {},
            backAction: this.setDefaultSlide
          }}
        />
      </div>
    );
  }
}

export default VersioningPage;
