/**
 * @author Linkon Islam
 * @date   2019-06-16
 * @description media asset component
 * @filename media-asset-page.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Tag from "../tag/tag";
import { MediaAsset } from "../../../../utilities/cms-operation/media-asset";
import {
  ContentModerationStateKind,
  MediaAsset as graphMediaAsset,
  GRAPHQL_TYPE_MEDIA_ASSET,
  MediaAssetStateKind,
  TopLevelMediaKind
} from "@radivision/graphql";
import { Validator } from "../../../../utilities/cms-operation/validator";
import ModalConductor from "../modal/modal-conductor";
import { FloatingMenu } from "../floating-button/floating-menu";
import { Loader } from "../../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import {
  FormGroupInput,
  MediaAssetPageFormInput
} from "../../../../component-configuration/cms-pages";
import ListPanel from "../list-item-panel/list-item-panel";
import { ImageHelper } from "../../../../utilities/image-helper";
import { OptimizedImageUrls } from "../../../../component-configuration/optimized-image";

/**
 *
 * The props of the article page component.
 * @export
 * @interface MediaAssetPageProps
 */
export interface MediaAssetPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof MediaAssetPageProps
   */
  string?: string;
  /**
   *
   *
   * @type {string}
   * @memberof MediaAssetPageProps
   */
  graphMediaAsset?: graphMediaAsset;
}

/**
 * The state of the article page component.
 * @export
 * @interface
 */

export interface MediaAssetPageState {
  /**
   *
   *
   * @type {MediaAssetPageFormInput}
   * @memberof MediaAssetPageState
   */
  formInput: MediaAssetPageFormInput;

  /**
   *
   *
   * @type {*}
   * @memberof BookPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof MediaAssetPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {ContentModerationStateKind}
   * @memberof MediaAssetPageState
   */
  moderation?: ContentModerationStateKind;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof MediaAssetPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof MediaAssetPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class MediaAssetPage
 * @extends {React.Component<MediaAssetPageProps, MediaAssetPageState>}
 */
export class MediaAssetPage extends React.Component<
  MediaAssetPageProps,
  MediaAssetPageState
> {
  /**
   * References to the Tags component in the MediaAssetPage.
   *
   * @type {*}
   * @memberof MediaAssetPage
   */
  tagsRef: any;

  /**
   * References to the Files component in the MediaAssetPage.
   *
   * @type {*}
   * @memberof MediaAssetPage
   */
  filesRef: any;

  /**
   * Creates an instance of MediaAssetPage.
   * @param {MediaAssetPageProps} props
   * @memberof MediaAssetPage
   */
  constructor(props: MediaAssetPageProps) {
    super(props);

    //... bind needed function to class

    this.handleDurationChanges = this.handleDurationChanges.bind(this);
    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleTopMediaLevelChanges = this.handleTopMediaLevelChanges.bind(
      this
    );
    this.handleStateChanges = this.handleStateChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    //... initialize required components references
    this.tagsRef = React.createRef();
    this.filesRef = React.createRef();

    /// handle provided props in case of edit already added article
    this.state = this.handleComponentStateInitialize(
      this.props.graphMediaAsset
    );
  }

  /**
   *
   *
   * @memberof MediaAssetPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @param {ArticleStory} mediaAssetProps
   * @returns {MediaAssetPageState}
   * @memberof MediaAssetPage
   */
  handleComponentStateInitialize(
    mediaAssetProps: graphMediaAsset
  ): MediaAssetPageState {
    // console.log(mediaAssetProps);

    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: MediaAssetPageFormInput = {
      id: undefined,
      label: "",
      title: "",
      description: "",
      state: MediaAssetStateKind.AVAILABLE,
      topMediaLevel: TopLevelMediaKind.APPLICATION,
      isDeleted: false
    };

    let state: MediaAssetPageState = {
      modal,
      formInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    if (mediaAssetProps !== null && mediaAssetProps !== undefined) {
      // validate provided label

      if (mediaAssetProps.id) {
        state.formInput.id = mediaAssetProps.id;
      }

      if (mediaAssetProps.revision) {
        state.formInput.revision = mediaAssetProps.revision;
      }

      if (mediaAssetProps.label) {
        state.formInput.label = mediaAssetProps.label;
      }

      if (mediaAssetProps.title) {
        state.formInput.title = mediaAssetProps.title;
      }

      if (mediaAssetProps.description) {
        state.formInput.description = mediaAssetProps.description;
      }

      if (mediaAssetProps.isDeleted) {
        state.formInput.isDeleted = mediaAssetProps.isDeleted;
      }

      if (mediaAssetProps.durationInMilliseconds) {
        state.formInput.durationInMilliseconds = String(
          mediaAssetProps.durationInMilliseconds
        );
      }

      if (mediaAssetProps.state) {
        state.formInput.state = mediaAssetProps.state.kind;
      }

      if (mediaAssetProps.topLevelMediaType) {
        state.formInput.topMediaLevel = mediaAssetProps.topLevelMediaType.kind;
      }

      //........................
      //initialize form group inputs
      //........................

      if (
        mediaAssetProps.tags !== null &&
        mediaAssetProps.tags !== undefined &&
        mediaAssetProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = mediaAssetProps.tags.map(
          tag => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        mediaAssetProps.files !== null &&
        mediaAssetProps.files !== undefined &&
        mediaAssetProps.files.length > 0
      ) {
        let filesList: {
          id: string;
          title: string;
        }[] = mediaAssetProps.files.map((file, index) => {
          let previewImageUrl: OptimizedImageUrls = ImageHelper.fetchOptimizedImageUrl(
            {
              imageType: "MEDIA_ASSET",
              mediaAsset: {
                files: [file],
                type: mediaAssetProps.topLevelMediaType.kind
              },
              desiredDimensions: {
                containerWidthRatio: 4 / 12,
                numberOfItems: 4
              },
              revision: mediaAssetProps.revision
            }
          );

          return { id: String(index), title: file.id, previewImageUrl };
        });
        state.formGroupInput.filesProps = filesList;
        // console.log(state.formGroupInput.filesProps);
      }
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof MediaAssetPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Media Asset</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input" />
              <div className="Page-Field">
                <label>Description</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.description}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Duration (Milliseconds)</label>
                <input
                  id="party"
                  type="number"
                  name="duration"
                  className={`form-control ${
                    this.state.errorMessage.get("TIME") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.durationInMilliseconds}
                  onChange={this.handleDurationChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("TIME")}
                </span>
              </div>
            </div>
            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.formInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>

              <div className="Page-Field">
                <label>State</label>
                <select
                  name="type"
                  id="State"
                  onChange={this.handleStateChanges}
                  value={this.state.formInput.state}
                  className="form-control mb-3"
                >
                  {Object.keys(MediaAssetStateKind).map((key: any, index) => {
                    return (
                      <option key={index} value={key.toString()}>
                        {key.toString()}
                      </option>
                    );
                  })}
                </select>
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("STATE")}
                </span>
              </div>

              <div className="Page-Field">
                <label>Top Media Level</label>
                <select
                  name="type"
                  id="topMediaLevel"
                  onChange={this.handleTopMediaLevelChanges}
                  value={this.state.formInput.topMediaLevel}
                  className="form-control mb-3"
                >
                  {Object.keys(TopLevelMediaKind).map((key: any, index) => {
                    return (
                      <option key={index} value={key.toString()}>
                        {key.toString()}
                      </option>
                    );
                  })}
                </select>
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("topMediaLevel")}
                </span>
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <label>Tags</label>
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>

            <div className="Page--Tag">
              <label>Files </label>
              <ListPanel
                items={this.state.formGroupInput.filesProps}
                ref={this.filesRef}
                readonly={false}
                filter={null}
              />
            </div>
          </div>

          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
        </div>
        <Loader isActive={this.state.isLoading} />
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @memberof MediaAssetPage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: MediaAssetPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.title = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in media Asset summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof MediaAssetPage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: MediaAssetPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.description = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in media Asset label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof MediaAssetPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: MediaAssetPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function responsible for handling changes in media Asset is deleted state
   *
   * @param {*} event
   * @memberof MediaAssetPage
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: MediaAssetPageFormInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    // // console.log("[handleIsDeletedChanges] is article deleted", isDeleted);
    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article time to read state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof ArticlePage
   */
  handleDurationChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: MediaAssetPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleTimeToReadChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.durationInMilliseconds = eventValue;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   * @memberof MediaAssetPage
   */
  handleStateChanges(event: React.ChangeEvent<HTMLSelectElement>): void {
    let formInput: MediaAssetPageFormInput = this.state.formInput;

    let selectedItem: any = event.target.value;

    formInput.state = selectedItem;

    this.setState({ formInput });
  }

  /**
   *
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   * @memberof MediaAssetPage
   */
  handleTopMediaLevelChanges(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    let formInput: MediaAssetPageFormInput = this.state.formInput;

    let selectedItem: any = event.target.value;

    formInput.topMediaLevel = selectedItem;

    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling submitting new article
   *
   * @memberof MediaAssetPage
   */
  handleSaveAction(): Promise<any> {
    let mediaAssetInput: MediaAssetPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      mediaAssetInput.formInput.tags = this.tagsRef.current.extractTagsString();
      mediaAssetInput.formInput.files = this.filesRef.current.extractTagsString();

      // validate input errors
      errorMessage = Validator.validateMediaAssetInput(
        mediaAssetInput.formInput
      );

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (this.props.string !== null && this.props.string !== undefined) {
          internalPromise = MediaAsset.updateMediaAsset(
            mediaAssetInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["MEDIA_ASSET"].message.confirmation.update;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = MediaAsset.addMediaAsset(
            mediaAssetInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["MEDIA_ASSET"].message.confirmation.add;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["MEDIA_ASSET"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });

    return promise;
  }

  /**
   *
   *
   * @memberof MediaAssetPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof MediaAssetPage
   */
  revertPageInput(input?: graphMediaAsset): void {
    let state: MediaAssetPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.graphMediaAsset
    );
    this.setState(state);

    //... reset children state
    this.tagsRef.current.resetComponent();
    this.filesRef.current.resetComponent();
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof MediaAssetPage
   */
  toggleLoader(isLoading: boolean = true): void {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class MediaAssetPageContainer extends React.Component<
  MediaAssetPageProps,
  MediaAssetPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let mediaAssetId: string;

    // console.log("[MediaAssetPageContainer] provided props", this.props);

    if (this.props.string === null || this.props.string === undefined) {
      node = <MediaAssetPage />;
    } else {
      mediaAssetId = this.props.string;
      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query mediaAssetPageQuery($id: ID!) {
              mediaAsset(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                mediaAsset {
                  __typename
                  id
                  revision
                  description
                  durationInMilliseconds
                  label
                  files {
                    id
                    name
                    heightInPixels
                    widthInPixels
                    path
                    status {
                      kind
                    }
                  }
                  state {
                    kind
                  }
                  topLevelMediaType {
                    kind
                  }
                }
              }
            }
          `}
          variables={{
            id: mediaAssetId
          }}
          render={({ error, props }) => {
            if (error) {
              // console.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console.log(props);
              if (props.mediaAsset.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.mediaAsset.mediaAsset.__typename !==
                GRAPHQL_TYPE_MEDIA_ASSET
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid MediaAsset id, {mediaAssetId} belongs to type{" "}
                      {props.mediaAsset.mediaAsset.__typename}, Need more help?
                      contact Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.mediaAsset.mediaAsset) {
                let pageProps: MediaAssetPageProps = {
                  ...this.props,
                  graphMediaAsset: props.mediaAsset.mediaAsset
                };
                return <MediaAssetPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
