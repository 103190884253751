/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreatePosterInput = {
    readonly actionDescription?: string | null;
    readonly actionTitle?: string | null;
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly isActive?: boolean | null;
    readonly label?: string | null;
    readonly publishedDate?: unknown | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly subTitle?: string | null;
    readonly summary?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly title?: string | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type createPosterMutationVariables = {
    readonly input: CreatePosterInput;
};
export type createPosterMutationResponse = {
    readonly createPoster: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly poster: {
            readonly id: string;
            readonly title: string | null;
        } | null;
    } | null;
};
export type createPosterMutation = {
    readonly response: createPosterMutationResponse;
    readonly variables: createPosterMutationVariables;
};



/*
mutation createPosterMutation(
  $input: CreatePosterInput!
) {
  createPoster(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    poster {
      id
      title
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePosterInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createPoster",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdatePosterPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "poster",
        "storageKey": null,
        "args": null,
        "concreteType": "Poster",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createPosterMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "createPosterMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "createPosterMutation",
    "id": null,
    "text": "mutation createPosterMutation(\n  $input: CreatePosterInput!\n) {\n  createPoster(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    poster {\n      id\n      title\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '40c37a5a9d91e513e4632be336a89370';
export default node;
