/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-23
 * @description Implementation of the GraphQL mutation to add a new url route.
 * @filename create-url-route.ts
 */

import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an podcast.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_URL_ROUTE_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createUrlRouteMutation($input: CreateUrlRouteInput!) {
    createUrlRoute(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      urlRoute {
        id
        alias
        description
        label
        revision
        rules {
          cognitoIdentityPool
          cognitoUserPoolGroups
          description
          htmlDocument {
            id
          }
          id
          queryParameters {
            name
            values
          }
        }
      }
    }
  }
`;
