/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Initial page component
 * @filename InitialPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Publisher from "./publisher";
import AuthorPanel from "./author/author-panel";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import ModerationPanel from "./moderation/moderation-panel";

import {
  OnlineCourseStory,
  PreviewKind,
  GRAPHQL_TYPE_ONLINE_COURSE_STORY
} from "@radivision/graphql";
import ModalConductor from "./modal/modal-conductor";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import {
  OnlineCoursePageFormInput,
  FormGroupInput,
  PreviewItem
} from "../../../component-configuration/cms-pages";
import { Validator } from "../../../utilities/cms-operation/validator";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import { OnlineCourse } from "../../../utilities/online-course";

/**
 *
 *
 * @export
 * @interface OnlineCoursePageProps
 */
export interface OnlineCoursePageProps {
  /**
   *
   *
   * @type {string}
   * @memberof OnlineCoursePageProps
   */
  onlineCourse?: { id: string };

  /**
   *
   *
   * @type {OnlineCourseStory}
   * @memberof OnlineCoursePageProps
   */
  onlineCourseStory?: OnlineCourseStory;
}

/**
 *
 *
 * @interface OnlineCoursePageState
 */
interface OnlineCoursePageState {
  /**
   *
   *
   * @type {FormGroupInput}
   * @memberof OnlineCoursePageState
   */
  formGroupInput: FormGroupInput;

  /**
   *
   *
   * @type {OnlineCoursePageFormInput}
   * @memberof OnlineCoursePageState
   */
  onlineCourseInput: OnlineCoursePageFormInput;

  /**
   *
   *
   * @type {string[]}
   * @memberof OnlineCoursePageState
   */
  errorMessage: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof OnlineCoursePageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof OnlineCoursePageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class OnlineCoursePage
 * @extends {React.Component<OnlineCoursePageProps, OnlineCoursePageState>}
 */
export class OnlineCoursePage extends React.Component<
  OnlineCoursePageProps,
  OnlineCoursePageState
> {
  /**
   * References to the Author component in the OnlineCoursePage.
   *
   * @type {*}
   * @memberof OnlineCoursePage
   */
  authorPanelRef: any;

  /**
   * References to the moderation component in the OnlineCoursePage.
   *
   * @type {*}
   * @memberof OnlineCoursePage
   */
  moderationPanelRef: any;

  /**
   * References to the publisher component in the OnlineCoursePage.
   *
   * @type {*}
   * @memberof OnlineCoursePage
   */
  publisherDetailsRef: any;

  /**
   * References to the preview component in the OnlineCoursePage.
   *
   * @type {*}
   * @memberof OnlineCoursePage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the OnlineCoursePage.
   *
   * @type {*}
   * @memberof OnlineCoursePage
   */
  tagsRef: any;

  /**
   *Creates an instance of OnlineCoursePage.
   * @param {OnlineCoursePageProps} props
   * @memberof OnlineCoursePage
   */
  constructor(props: OnlineCoursePageProps) {
    super(props);

    //... bind needed function to class

    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSubTitleChange = this.handleSubTitleChange.bind(this);
    this.handlePublishFrequency = this.handlePublishFrequency.bind(this);
    this.handleTimeToRead = this.handleTimeToRead.bind(this);
    this.handleCost = this.handleCost.bind(this);
    this.handleLabel = this.handleLabel.bind(this);
    this.handleSummary = this.handleSummary.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleIsNewChanges = this.handleIsNewChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);

    // ... initialize required components references

    this.authorPanelRef = React.createRef();
    this.moderationPanelRef = React.createRef();
    this.publisherDetailsRef = React.createRef();
    this.tagsRef = React.createRef();
    this.previewPanelRef = React.createRef();
    // ... initial component state
    this.state = this.handleComponentStateInitialize(
      this.props.onlineCourseStory
    );
  }

  /**
   *
   *
   * @param {ArticleStory} originalContentProps
   * @returns {ArticlePageState}
   * @memberof ArticlePage
   */
  handleComponentStateInitialize(
    originalContentProps: OnlineCourseStory
  ): OnlineCoursePageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let onlineCourseInput: OnlineCoursePageFormInput = {
      id: undefined,
      link: "",
      label: "",
      cost: "",
      title: "",
      subTitle: "",
      summary: "",
      publisherName: "",
      publishedDate: "",
      isDeleted: false,
      isNew: false,
      publishFrequency: "",
      timeRequiredInSeconds: ""
    };

    let state: OnlineCoursePageState = {
      modal,
      onlineCourseInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    if (originalContentProps !== null && originalContentProps !== undefined) {
      // validate provided label

      if (originalContentProps.id) {
        state.onlineCourseInput.id = originalContentProps.id;
      }

      if (originalContentProps.label) {
        state.onlineCourseInput.label = originalContentProps.label;
      }

      if (originalContentProps.link) {
        state.onlineCourseInput.link = originalContentProps.link;
      }

      if (originalContentProps.title) {
        state.onlineCourseInput.title = originalContentProps.title;
      }

      if (originalContentProps.subTitle) {
        state.onlineCourseInput.subTitle = originalContentProps.subTitle;
      }

      if (originalContentProps.description) {
        state.onlineCourseInput.summary = originalContentProps.description;
      }

      if (originalContentProps.isDeleted) {
        state.onlineCourseInput.isDeleted = originalContentProps.isDeleted;
      }

      if (originalContentProps.isNew)
        state.onlineCourseInput.isNew = originalContentProps.isNew;

      if (originalContentProps.publishedDate) {
        state.onlineCourseInput.publishedDate =
          originalContentProps.publishedDate;
      }

      if (originalContentProps.timeRequiredInSeconds) {
        state.onlineCourseInput.timeRequiredInSeconds = String(
          originalContentProps.timeRequiredInSeconds
        );
      }

      if (originalContentProps.revision) {
        state.onlineCourseInput.revision = originalContentProps.revision;
      }

      // ............

      // initialize form group inputs

      //...............
      if (originalContentProps.publisher) {
        state.formGroupInput.publisherProps = {
          name: originalContentProps.publisher
          // type :""
        };
      }

      if (
        originalContentProps.tags !== null &&
        originalContentProps.tags !== undefined &&
        originalContentProps.tags.length > 0
      ) {
        let tagsList: {
          id: string;
          text: string;
        }[] = originalContentProps.tags.map(tag => {
          return { id: tag.id, text: tag.label };
        });
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        originalContentProps.previews !== null &&
        originalContentProps.previews !== undefined &&
        originalContentProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = originalContentProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`
                  }
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @returns
   * @memberof OnlineCoursePage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <div className="container">
              <h2>Online Course</h2>
              {/* Article Info */}
              <div className="Page--Info">
                <div className="Required">
                  <p>*Required</p>
                </div>
                <div className="Page--Info-Input">
                  <div className="Page-Field">
                    <label className="Page-Require">Link URL</label>
                    <input
                      type="text"
                      name="Link URL"
                      value={this.state.onlineCourseInput.link}
                      onChange={this.handleLinkChange}
                    />
                  </div>
                  <div className="Page-Field">
                    <label className="Page-Require">Title</label>
                    <input
                      type="text"
                      name="Title"
                      value={this.state.onlineCourseInput.title}
                      onChange={this.handleTitleChange}
                    />
                  </div>
                  <div className="Page-Field">
                    <label>Subtitle</label>
                    <input
                      type="text"
                      name="Subtitle"
                      value={this.state.onlineCourseInput.subTitle}
                      onChange={this.handleSubTitleChange}
                    />
                  </div>
                </div>
              </div>
              {/* Author Section */}
              <div className="Page--Author">
                <label htmlFor="">Authors</label>
                <AuthorPanel ref={this.authorPanelRef} />
              </div>
              {/* Publisher Section */}
              <div className="Page--Publisher">
                <label>Publisher</label>
                <Publisher
                  error={{
                    haveError: this.state.errorMessage.get("PUBLISHER")
                      ? true
                      : false,
                    message: this.state.errorMessage.get("PUBLISHER")
                  }}
                  ref={this.publisherDetailsRef}
                />
              </div>
              {/* created and modified time */}
              <div className="Page--Time">
                <div className="Page-Field">
                  <label>Publish Frequency</label>
                  <select
                    name="frequency"
                    id=""
                    onChange={this.handlePublishFrequency}
                  >
                    <option value="weekly">weekly</option>
                    <option value="biweekly">biweekly</option>
                    <option value="monthly">monthly</option>
                    <option value="quarterly">quarterly</option>
                  </select>
                </div>
                <div className="Page-Field">
                  <label>Time to Read(Seconds)</label>
                  <input
                    id="party"
                    type="number"
                    name="timetoread"
                    value={this.state.onlineCourseInput.timeRequiredInSeconds}
                    onChange={this.handleTimeToRead}
                  />
                </div>
                <div className="Page-Field">
                  <label>Created</label>
                  <input
                    className="readonly"
                    id="party"
                    type="datetime"
                    name="created"
                    value={currentTime}
                    readOnly={true}
                  />
                </div>
                <div className="Page-Field">
                  <label>Last Modified</label>
                  <input
                    className="readonly"
                    id="party"
                    type="datetime"
                    name="lastmodified"
                    value={currentTime}
                    readOnly={true}
                  />
                </div>
              </div>
              {/* Label & Summary */}
              <div className="Page--Label">
                <div className="Page-Field">
                  <label>Cost</label>
                  <input
                    type="text"
                    name="cost"
                    onChange={this.handleCost}
                    value={this.state.onlineCourseInput.cost}
                  />
                </div>
                <div className="Page-Field">
                  <label>Label</label>
                  <input
                    type="text"
                    name="Label"
                    onChange={this.handleLabel}
                    value={this.state.onlineCourseInput.label}
                  />
                </div>
                <div className="Page-Field">
                  <label>Summary</label>
                  <input
                    type="text"
                    name="Summary"
                    onChange={this.handleSummary}
                    value={this.state.onlineCourseInput.summary}
                  />
                </div>
              </div>
              {/* Story Status */}
              <div className="Page--Status">
                <div className="Page-checkbox">
                  <input
                    type="checkbox"
                    name="isdeleted"
                    checked={this.state.onlineCourseInput.isDeleted}
                    readOnly
                  />
                  <label>Is Deleted</label>
                </div>
                <div className="Page-checkbox">
                  <input
                    type="checkbox"
                    name="new"
                    value={
                      this.state.onlineCourseInput.isNew ? "true" : "false"
                    }
                    onClick={this.handleIsNewChanges}
                    readOnly
                  />
                  <label>New</label>
                </div>
              </div>
              {/* tag section */}
              <div className="Page--Tag">
                <Tag
                  ref={this.tagsRef}
                  tagsList={this.state.formGroupInput.tagsProps}
                />
              </div>
              {/* preview section */}
              <div className="Page--Preview">
                <ManagePreviewPanel
                  ref={this.previewPanelRef}
                  previewsList={this.state.formGroupInput.previewsProps}
                />
              </div>
              {/* moderation section */}
              <div className="Page--Moderation">
                <ModerationPanel ref={this.moderationPanelRef} />
              </div>
            </div>
            <Loader isActive={this.state.isLoading} />

            <ModalConductor
              currentModal={this.state.modal.modalName}
              ConfirmModalProps={{
                title: this.state.modal.modalTile,
                body: this.state.modal.modalBody,
                closeAction: this.state.modal.modalCloseAction
              }}
              close={() => {}}
            />
            <FloatingMenu
              menuItems={{
                revertible: this.revertPageInput,
                deleteAble: {
                  deleteAction: () => {
                    let onlineCourseInput = this.state.onlineCourseInput;
                    onlineCourseInput.isDeleted = true;
                    this.setState({ onlineCourseInput });
                  },
                  unDeleteAction: () => {
                    let onlineCourseInput = this.state.onlineCourseInput;
                    onlineCourseInput.isDeleted = false;
                    this.setState({ onlineCourseInput });
                  }
                },
                saveable: this.handleSaveAction
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof OnlineCoursePage
   */
  handleLinkChange(changeEvent: React.FormEvent<HTMLInputElement>): void {}

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof OnlineCoursePage
   */
  handleTitleChange(changeEvent: React.FormEvent<HTMLInputElement>): void {}

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof OnlineCoursePage
   */
  handleSubTitleChange(changeEvent: React.FormEvent<HTMLInputElement>): void {}

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof OnlineCoursePage
   */
  handlePublishFrequency(changeEvent: any): void {}

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof OnlineCoursePage
   */
  handleTimeToRead(changeEvent: React.FormEvent<HTMLInputElement>): void {}

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof OnlineCoursePage
   */
  handleCost(changeEvent: React.FormEvent<HTMLInputElement>): void {}

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof OnlineCoursePage
   */
  handleLabel(changeEvent: React.FormEvent<HTMLInputElement>): void {}

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof OnlineCoursePage
   */
  handleSummary(changeEvent: React.FormEvent<HTMLInputElement>): void {}

  /**
   *
   *
   * @param {*} Event
   * @memberof OnlineCoursePage
   */
  handleIsDeletedChanges(Event: any): void {}

  /**
   *
   *
   * @param {*} Event
   * @memberof OnlineCoursePage
   */
  handleIsNewChanges(Event: any): void {}

  /**
   *
   *
   * @memberof OnlineCoursePage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof OnlineCoursePage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }

  /**
   * Function Responsible for handling submitting new Original Content
   *
   * @memberof OriginalContentPage
   */
  handleSaveAction(): void {
    let pageState: OnlineCoursePageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract moderation state
      pageState.onlineCourseInput.moderation = this.moderationPanelRef.current.extractModerationState();
      // extract tags state
      pageState.onlineCourseInput.tags = this.tagsRef.current.extractTagsString();
      // extract previews list
      pageState.onlineCourseInput.previews = this.previewPanelRef.current.extractPreviews();

      // validate errors
      errorMessage = Validator.validateCreateStoryInput(
        pageState.onlineCourseInput
      );

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console\.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (
          this.props.onlineCourse !== null &&
          this.props.onlineCourse !== undefined
        ) {
          internalPromise = OnlineCourse.updateOnlineCourse(
            pageState.onlineCourseInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["ONLINE_COURSE"].message.confirmation.update;
            modal.modalTile = "Action Complete";
          });
        } else {
          internalPromise = OnlineCourse.addOnlineCourse(
            pageState.onlineCourseInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["ONLINE_COURSE"].message.confirmation.add;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["ONLINE_COURSE"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @memberof OriginalContentPage
   */
  revertPageInput(input?: OnlineCourseStory): void {
    let state: OnlineCoursePageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null
        ? input
        : this.props.onlineCourseStory
    );
    this.setState(state);

    //... reset children state

    this.tagsRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.publisherDetailsRef.current.resetComponent();
    this.moderationPanelRef.current.resetComponent();
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class OnlineCoursePageContainer extends React.Component<
  OnlineCoursePageProps,
  OnlineCoursePageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let originalContentId: string;

    // console\.log("[OriginalContentPageContainer] provided props", this.props);

    if (
      this.props.onlineCourse === null ||
      this.props.onlineCourse === undefined
    ) {
      node = <OnlineCoursePage />;
    } else {
      originalContentId = this.props.onlineCourse.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query onlineCoursePageQuery($id: ID!) {
              story(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                node {
                  __typename
                  ... on OnlineCourseStory {
                    id
                    publishedDate
                    description
                    created
                    label
                    isDeleted
                    isNew
                    revision
                    tags {
                      id
                      label
                    }
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                              label
                            }
                          }
                        }
                      }
                    }
                    subTitle
                    title
                  }
                }
              }
            }
          `}
          variables={{
            id: originalContentId
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.story.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.story.node.__typename !== GRAPHQL_TYPE_ONLINE_COURSE_STORY
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Online-Course id, {originalContentId} belongs to
                      type {props.story.node.__typename}, Need more help?
                      contact Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.story.node) {
                let pageProps: OnlineCoursePageProps = {
                  ...this.props,
                  onlineCourseStory: props.story.node
                };
                return <OnlineCoursePage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
