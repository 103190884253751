/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-12
 * @description Implementation of the GraphQL mutation to add an book story.
 * @filename create-dynamic-list
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an book.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_FIXED_LIST_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createFixedListMutation($input: CreateFixedListInput!) {
    createFixedList(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      fixedList {
        __typename
        id
        title
        subTitle
        description
        label
        isDeleted
        revision
        name
        items {
          count
          edges {
            cursor
            listItem {
              index
              item {
                ... on OriginalContentStory {
                  __typename
                  description
                  durationInSeconds
                  externalId
                  id
                  mediaItem {
                    id
                    files {
                      id
                      heightInPixels
                      widthInPixels
                      name
                      path
                    }
                  }
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        mediaAsset {
                          id
                          files {
                            id
                            heightInPixels
                            widthInPixels
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                        link
                        kind
                      }
                    }
                  }
                  subTitle
                  title
                }
                ... on ArticleStory {
                  __typename
                  description
                  id
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        mediaAsset {
                          id
                          files {
                            id
                            heightInPixels
                            widthInPixels
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                        link
                        kind
                      }
                    }
                  }
                  subTitle
                  title
                }
                ... on BookStory {
                  __typename
                  description
                  id
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        mediaAsset {
                          id
                          files {
                            id
                            heightInPixels
                            widthInPixels
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                        link
                        kind
                      }
                    }
                  }
                  subTitle
                  title
                }
                ... on NewsletterStory {
                  __typename
                  description
                  id
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        mediaAsset {
                          id
                          files {
                            id
                            heightInPixels
                            widthInPixels
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                        link
                        kind
                      }
                    }
                  }
                  subTitle
                  title
                }
                ... on PodcastStory {
                  __typename
                  description
                  id
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        mediaAsset {
                          id
                          files {
                            id
                            heightInPixels
                            widthInPixels
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                        link
                        kind
                      }
                    }
                  }
                  subTitle
                  title
                }
                ... on OnlineCourseStory {
                  __typename
                  description
                  id
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        mediaAsset {
                          id
                          files {
                            id
                            heightInPixels
                            widthInPixels
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                        link
                        kind
                      }
                    }
                  }
                  subTitle
                  title
                }
                ... on Poster {
                  __typename
                  description
                  id
                  label
                  isNew
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        kind
                        mediaAsset {
                          id
                          files {
                            id
                            name
                            heightInPixels
                            widthInPixels
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                      }
                    }
                  }
                  subTitle
                  title
                }
                ... on Person {
                  __typename
                  fullName
                  id
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        kind
                        mediaAsset {
                          id
                          files {
                            id
                            name
                            heightInPixels
                            widthInPixels
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                      }
                    }
                  }
                  landingPageUrl
                }
                ... on Entity {
                  __typename
                  name
                  id
                  landingPageUrl
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        kind
                        mediaAsset {
                          id
                          files {
                            id
                            name
                            heightInPixels
                            widthInPixels
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
