/**
 * @author Ahmed Samer
 * @date   2019-11-11
 * @description Tag component
 * @filename versioning
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import {
  CardHeaderProps,
  CardBodyProps,
  CardProps
} from "./card-configuration";

/**
 *
 *
 * @class CardHeader
 * @extends {React.Component<CardHeaderProps, {}>}
 */
class CardHeader extends React.Component<CardHeaderProps, {}> {
  render() {
    const { imageUrl, title } = this.props;
    const STYLE = {
      backgroundImage: "url(" + imageUrl + ")"
    };
    return (
      <header style={STYLE} id={imageUrl} className="version-card-header">
        <h4 className="version-card-header--title">{title}</h4>
      </header>
    );
  }
}

/**
 *
 *
 * @class CardBody
 * @extends {React.Component<CardBodyProps, {}>}
 */
class CardBody extends React.Component<CardBodyProps, {}> {
  render() {
    return (
      <div className="version-card-body">
        <p className="date">{this.props.date}</p>
        <h2 className="version-card-body--title">
          {this.props.title ? this.props.title.substr(0, 20) : ""}
        </h2>
        <p className="body-content">
          {this.props.text ? this.props.text.substr(0, 50) : ""}
        </p>

        <button
          className="button button-primary"
          onClick={e => {
            this.props.clickAction(e, this.props.index);
          }}
        >
          <i className="fa fa-chevron-right"></i> Find out more
        </button>
      </div>
    );
  }
}

/**
 *
 *
 * @export
 * @class Card
 * @extends {React.Component<{}, {}>}
 */
export class VersionCard extends React.Component<CardProps, {}> {
  /**
   *Creates an instance of ArticlePage.
   * @param {CardProps} props
   * @memberof VersionCard
   */
  constructor(props: CardProps) {
    super(props);
  }

  /**
   *
   *
   * @returns
   * @memberof VersionCard
   */
  render() {
    const { header, body } = this.props;
    return (
      <article className="version-card">
        <CardHeader {...header} />
        <CardBody {...body} />
      </article>
    );
  }
}
