/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-05-19
 * @description Implementation of the GraphQL mutation to update podcast story.
 * @filename update-podcast-story.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update person.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_PODCAST_STORY_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updatePodcastStoryMutation($input: UpdatePodcastStoryInput!) {
    updatePodcastStory(input: $input) {
      hasErrors
      errors {
        id
        location
      }
      story {
        __typename
        ... on PodcastStory {
          id
          link
          publisher
          # durationInSeconds
          publishedDate
          description
          created
          label
          isDeleted
          isNew
          revision
          tags {
            id
            label
          }
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  id
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                  }
                }
              }
            }
          }
          subTitle
          title
        }
      }
    }
  }
`;
