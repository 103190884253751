/**
 * @author Maria Osama
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-06-26 13:55:36
 * @description Implementation of the CreateAccountModal react component
 * @filename create-account-modal.tsx
 */
import React from "react";
import ProgressiveImage from "react-progressive-image";
import { LinkedInAuthenticator } from "../../../authentication/linkedin-authenticator";
import { TwitterAuthenticator } from "../../../authentication/twitter-authenticator";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { ERROR_MESSAGES } from "../../../constants/errors-constants";
import { GOOGLE_LOGO_IMAGE_NAME } from "../../../constants/general-constants";
import { gaEvent } from "../../../utilities/general";
import { ImageHelper } from "../../../utilities/image-helper";
import { RdvButton } from "../../page-framework/rdv-button";
import { Loader } from "../../page/loader";
import { AccountVerificationModal } from "./account-verification-modal";
import { RequestPasswordResetModal } from "./request-password-reset-modal";
import { SignInModal } from "./sign-in-modal";
import {
  FACEBOOK_ICON_SVG,
  LINKED_IN_ICON_SVG,
  TWITTER_ICON_SVG,
} from "./social-media-icons";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";

/**
 * The props of CreateAccountModal component.
 *
 * @interface
 */
interface CreateAccountModalProps {
  /**
   * A function that changes the current modal.
   *
   * @type {function(modal: any, props:Object):void}
   */
  changeModal: (modal: any, props?: {}) => void;

  /**
   * The ID of the CreateAccount class.
   *
   * @type {string}
   */
  id: string;

  /**
   * A function that navigates to the technical support pages and closes the modal.
   *
   * @type {function():void}
   */
  navigateToTechnicalSupport: () => void;

  /**
   * A function that navigates to the terms and conditions pages and closes the modal.
   *
   * @type {function():void}
   */
  navigateToTermsAndConditions: () => void;

  /**
   * A function that returns a promise to resend the account verification email.
   *
   * @type {function(email: string):Promise<any>}
   */
  resendAccountVerificationEMail: (email: string) => Promise<any>;

  /**
   * A function that sets the title of the modal.
   *
   * @type {function(title:string):void}
   * @memberof CreateAccountProps
   */
  setTitle: (title: string) => void;

  /**
   * A function that signs up the user.
   *
   * @type {function(email:string, password:string, name:string):Promise<*>}
   * @memberof CreateAccountProps
   */
  signUpUser: (auth: {
    email?: string;
    password?: string;
    name?: string;
    provider?: "google" | "facebook" | "linkedin" | "twitter";
    authObject?: any;
  }) => Promise<any>;

  /**
   * A function that returns a flag which identifies if a password is valid.
   *
   * @type {function(password:string):boolean}
   */
  validatePassword: (password: string) => boolean;
}

/**
 * The state of the CreateAccountModal component.
 *
 * @interface
 */
interface CreateAccountModalState {
  /**
   * The email address of the user
   *
   * @type {string}
   */
  email: string;

  /**
   * A current error.
   *
   * @type {AWS.AWSError}
   */
  error: {
    code: string;
    message: string;
  };

  /**
   * The URL of the Google logo.
   *
   * @type {string}
   */
  googleLogoPreview: OptimizedImageUrls;

  /**
   * A flag which is true if there is an error.
   *
   * @type {boolean}
   */
  hasError: boolean;

  /**
   * A text value that indicates if the password if hidden or shown.
   *
   * @type {string}
   */
  hideOrShow: string;

  /**
   * fire the progress overlay when submit button is clicked
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The user's full name.
   *
   * @type {string}
   * @memberof CreateAccountState
   */
  name: string;

  /**
   * The password chosen by user
   *
   * @type {string}
   */
  password: string;

  /**
   * A flag which is true if the password is valid
   *
   * @type {boolean}
   */
  passwordIsValid: boolean;

  /**
   * A flag which is true if the password and duplicate password match.
   *
   * @type {boolean}
   */
  passwordMatch: boolean;

  /**
   * The re-entered password chosen by user
   *
   * @type {string}
   */
  reenteredPassword: string;

  /**
   * The type of the password field which is either "text" or "password"
   *
   * @type {("password"|"text")}
   */
  type: "password" | "text";
}

/**
 *
 * A React component that renders the create account modal.
 *
 * @extends {React.Component}
 */
export class CreateAccountModal extends React.Component<
  CreateAccountModalProps,
  CreateAccountModalState
> {
  passwordInput: React.RefObject<HTMLInputElement>;
  passwordConfirmInput: React.RefObject<HTMLInputElement>;
  /**
   * Constructor.
   *
   * @param {CreateAccountModalProps} props The props of the component.
   */
  constructor(props: CreateAccountModalProps) {
    super(props);

    this.passwordInput = React.createRef();
    this.passwordConfirmInput = React.createRef();

    const googleLogoPreview: OptimizedImageUrls = ImageHelper.fetchOptimizedImageUrl(
      {
        imageType: "MEDIA_ASSET",
        mediaAsset: {
          type: TopLevelMediaKind.IMAGE,
          mediaAssetPath: GOOGLE_LOGO_IMAGE_NAME,
        },
        desiredDimensions: { containerWidthRatio: 9 / 12, numberOfItems: 4 },
        revision: undefined,
      }
    );

    this.changeState = this.changeState.bind(this);
    this.contactTechnicalSupport = this.contactTechnicalSupport.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.navigateToTermsAndConditions = this.navigateToTermsAndConditions.bind(
      this
    );
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onReenterPasswordChange = this.onReenterPasswordChange.bind(this);
    this.recoverPassword = this.recoverPassword.bind(this);
    this.signInWithSocialMedia = this.signInWithSocialMedia.bind(this);
    this.signIn = this.signIn.bind(this);
    this.state = {
      googleLogoPreview,
      email: "",
      error: null,
      hasError: false,
      hideOrShow: "show",
      loading: false,
      name: "",
      password: "",
      passwordIsValid: true,
      passwordMatch: true,
      reenteredPassword: "",
      type: "password",
    };
    this.verifyAccount = this.verifyAccount.bind(this);
  }

  /**
   * Changes the type of password input field from password to text and vice versa
   */
  changeState() {
    const oldState: string = this.state.type;
    const isTextOrHide: boolean = oldState === "password";
    const newState: "text" | "password" = isTextOrHide ? "text" : "password";
    const hideOrShow: "Hide" | "Show" = isTextOrHide ? "Hide" : "Show";

    this.setState({
      hideOrShow,
      type: newState,
    });
  }

  /**
   * Invoked by the React framework after the component output has been rendered to the DOM
   */
  componentDidMount() {
    this.props.setTitle("Create Your Account");
    this.setState({
      error: null,
      hasError: false,
    });
  }

  /**
   * Invokes the technical support and closes the modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  contactTechnicalSupport(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.props.navigateToTechnicalSupport();
  }

  /**
   * Authenticates with third-party identity providers to retrieve an identity token and maps login to AWS credentials.
   *
   * @param {*} authObject The authorization object received from the IDP after sign in.
   */
  handleAuth(authObject: any): void {
    if (authObject.googleId) {
      this.props.signUpUser({
        authObject,
        provider: "google",
      });
    }
    if (authObject.userID) {
      this.props.signUpUser({
        authObject,
        provider: "facebook",
      });
    }
  }

  /**
   * Changes the state's email property as user input changes.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The change event on the email input field.
   */
  handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({
      email: event.target.value,
    });
  }

  /**
   * Changes the state's name property as user input changes.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The change event on the name input field.
   */
  handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({
      name: event.target.value,
    });
  }

  /**
   * Navigates to the terms and conditions page and closes the modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  navigateToTermsAndConditions(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();
    this.props.navigateToTermsAndConditions();
  }

  /**
   * Changes the state's email property as user input changes.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event The change event on the password input field.
   */
  onPasswordChange(event: React.KeyboardEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({
      hasError: false,
      password: this.passwordInput.current.value,
      passwordIsValid: this.props.validatePassword(
        this.passwordInput.current.value
      ),
      passwordMatch:
        this.passwordInput.current.value.length === 0 ||
        this.passwordInput.current.value === this.state.reenteredPassword,
    });
  }

  /**
   * Invoked when the re-entered password changes.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event The event supplied when the re-entered password changes.
   */
  onReenterPasswordChange(event: React.KeyboardEvent<HTMLInputElement>): void {
    event.preventDefault();
    this.setState({
      hasError: false,
      reenteredPassword: this.passwordConfirmInput.current.value,
      passwordMatch:
        this.state.password.length === 0 ||
        this.passwordConfirmInput.current.value === this.state.password,
    });
  }

  /**
   * Signs up user with email and password.
   *
   * @param {React.FormEvent} event The form submit event.
   */
  onSubmit(event: React.FormEvent): Promise<any> {
    let promise: Promise<any>;

    event.preventDefault();
    promise =
      this.state.passwordIsValid && this.state.passwordMatch
        ? Promise.resolve()
        : Promise.reject({ code: "InvalidPasswordException" });
    this.setState({
      hasError: false,
      loading: true,
    });
    return promise
      .then(
        (): Promise<any> => {
          gaEvent("Registration ", "Click", "Register New User", true);
          return this.props
            .signUpUser({
              email: this.state.email,
              password: this.state.password,
              name: this.state.name,
            })
            .then(() => {
              this.setState({ loading: false });
            });
        }
      )
      .catch((e: any): void => {
        this.setState({
          error: e,
          hasError: true,
          loading: false,
        });
      });
  }

  /**
   * A method that changes the current modal to the login modal
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  recoverPassword(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();
    this.props.changeModal(RequestPasswordResetModal, {
      email: this.state.email,
    });
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    const footer: React.ReactNode = (
      <div id="createAccountFooter">
        <h4> Already have an account?</h4>
        <a
          href="#"
          data-toggle="modal"
          data-target="#signIn"
          onClick={this.signIn}
        >
          Sign in
        </a>
      </div>
    );

    return (
      <div>
        <Loader isActive={this.state.loading} />
        <div id="createAccount">
          <h1>Ignite your Entrepreneurial Journey!</h1>
          <p className="mt-4 text-center">
            Radivision's interactive digital TV and social network unlocks the
            fascinating world of entrepreneurship through original video series,
            a connected global community and more.
          </p>
          <p className="font-14">*Required</p>
          <div className="row">
            <div className="col-md-6 infoLogin">
              <form id="create-account-form" onSubmit={this.onSubmit}>
                <label className="form-group has-float-label top">
                  <input
                    type="text"
                    className="form-control"
                    id="createAccountName"
                    placeholder="Your full name"
                    onChange={this.handleNameChange}
                  />
                  <span>Full Name</span>
                </label>
                <label className="form-group has-float-label">
                  <input
                    type="email"
                    className="form-control"
                    id="createAccountEmail"
                    placeholder="Your email"
                    onChange={this.handleEmailChange}
                    required={true}
                  />
                  <span>
                    Email
                    <span className="required-star">*</span>
                  </span>
                </label>
                <label className="form-group has-float-label">
                  <input
                    type={this.state.type}
                    ref={this.passwordInput}
                    className={`form-control ${
                      this.state.passwordIsValid ? "" : "is-invalid"
                    }`}
                    id="createAccountPassword"
                    placeholder="8+ characters: upper, lower, number, & special"
                    onKeyUp={this.onPasswordChange}
                    required={true}
                  />
                  <span>
                    Password
                    <span className="required-star">*</span>
                  </span>
                  {this.state.passwordIsValid ? null : (
                    <div className="invalid-feedback">
                      {ERROR_MESSAGES.INVALID_PASSWORD_MESSAGE}
                    </div>
                  )}
                </label>
                <label className="form-group has-float-label">
                  <input
                    type={this.state.type}
                    className={`form-control ${
                      this.state.passwordMatch ? "" : "is-invalid"
                    }`}
                    id="createAccountConfirmPassword"
                    placeholder="Confirm Password"
                    onKeyUp={this.onReenterPasswordChange}
                    ref={this.passwordConfirmInput}
                    required={true}
                  />
                  <span id="createAccountConfirmPasswordLabel">
                    Confirm Password
                    <span className="required-star">*</span>
                  </span>
                  {this.state.passwordMatch ? (
                    ""
                  ) : (
                    <div className="invalid-feedback">
                      Does not match the password.
                    </div>
                  )}{" "}
                </label>
                <div className="form-group checkSection">
                  <input type="checkbox" value="" />
                  <h5>Would you like to receive our weekly newsletter?</h5>
                </div>
                {this.state.hasError ? this.renderErrorMessage() : null}
                <RdvButton
                  type="submit"
                  form="create-account-form"
                  text="create account"
                />
                <h6>
                  By signing up, I agree to Radivision's{" "}
                  <a href="#" onClick={this.navigateToTermsAndConditions}>
                    Terms of Service and Privacy Policy
                  </a>
                  .
                </h6>
              </form>
            </div>
            <div className="col-md-6 socialmediaLogin">
              <div
                className="social-login-btn"
                onClick={() => {
                  window.location.href = process.env.FB_URI;
                }}
              >
                {FACEBOOK_ICON_SVG}

                <a>
                  <span>
                    <span className="d-none d-lg-inline">Sign up with </span>
                    Facebook
                  </span>
                </a>
              </div>

              <div
                className="social-login-btn"
                onClick={() => {
                  window.location.href = process.env.GOOGLE_URI;
                }}
              >
                <div className="googleIcon">
                  <ProgressiveImage
                    src={
                      this.state.googleLogoPreview.requestedResolutionUrl !==
                        undefined &&
                      this.state.googleLogoPreview.requestedResolutionUrl !==
                        null
                        ? this.state.googleLogoPreview.requestedResolutionUrl
                        : this.state.googleLogoPreview.screenResolutionUrl
                    }
                    placeholder={this.state.googleLogoPreview.placeHolderUrl}
                  >
                    {(src: any, LOADING: any, srcSetData: any) => (
                      <img loading="lazy" alt="Google Logo" src={src} />
                    )}
                  </ProgressiveImage>
                </div>
                <a>
                  <span className="d-none d-lg-inline">Sign up with </span>
                  Google
                </a>
              </div>

              <div
                className="social-login-btn"
                onClick={() => {
                  this.signInWithSocialMedia("linkedin");
                }}
              >
                {LINKED_IN_ICON_SVG}
                <a href="#">
                  <span className="d-none d-lg-inline">Sign up with </span>
                  LinkedIn
                </a>
              </div>

              <div
                className="social-login-btn"
                onClick={() => {
                  this.signInWithSocialMedia("twitter");
                }}
              >
                {TWITTER_ICON_SVG}
                <a>
      <span className="d-none d-lg-inline">Sign up with </span>Twitter
    </a>
              </div>

              <h5>
                We'll never post to any of your accounts without your
                permission.
              </h5>
            </div>
          </div>
        </div>
        <div className="modal-footer">{footer}</div>
      </div>
    );
  }

  /**
   * Returns an element containing an error message determined by the current error.
   *
   * @returns {JSX.Element} The element containing the details of the error.
   */
  renderErrorMessage(): JSX.Element {
    let element: JSX.Element;

    if (this.state.hasError) {
      switch (this.state.error.code) {
        case "UsernameExistsException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The email address has already been registered. Would you like to{" "}
                {/* <a href="#" onClick={this.verifyAccount}>
                  resend your verification email
                </a>{" "}
                or{" "} */}
                <a href="#" onClick={this.recoverPassword}>
                  reset your password
                </a>
                ? For other options please{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;
        case "InvalidPasswordException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The password you entered is invalid or does not match the
                confirm password field. Please check that the password you
                entered meets requirements. For other options please{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;
        case "InvalidParameterException":
          if (this.state.passwordIsValid || this.state.passwordMatch) {
            element = (
              <div className="form-group alert alert-danger">
                <h6>
                  An unexpected error has occurred. Please{" "}
                  <a href="#" onClick={this.contactTechnicalSupport}>
                    contact Radivision's technical support
                  </a>
                  .
                </h6>
              </div>
            );
          }
          break;
        default:
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                An unexpected error has occurred. Please{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
      }
    }
    return element;
  }

  /**
   * A method that changes the current modal to the login modal
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  signIn(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();
    this.props.changeModal(SignInModal);
  }

  /**
   * Opens an authentication window and authenticates user with LinkedIn or Twitter.
   *
   * @param {"linkedin" | "twitter"} provider The provider used for social media sign in.
   */
  signInWithSocialMedia(provider: "linkedin" | "twitter"): void {
    if (provider === "linkedin") {
      LinkedInAuthenticator.requestLinkedInAuthorizationCode();
    } else if (provider === "twitter") {
      TwitterAuthenticator.authenticateTwitterUser();
    }
  }

  /**
   * Returns a promise to verify the account.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  verifyAccount(e: React.MouseEvent<HTMLAnchorElement>): Promise<any> {
    e.preventDefault();
    return this.props
      .resendAccountVerificationEMail(this.state.email)
      .then(
        (): Promise<void> => {
          this.props.changeModal(AccountVerificationModal, {
            email: this.state.email,
          });
          return Promise.resolve();
        }
      )
      .catch((e: { code: string; message: string }): void => {
        this.setState({
          error: e,
          hasError: true,
        });
      });
  }
}
