/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type mediaAssetFilePageQueryVariables = {
    readonly id: string;
};
export type mediaAssetFilePageQueryResponse = {
    readonly mediaAssetFile: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly mediaAssetFile: {
            readonly __typename: string;
            readonly id: string;
            readonly name: string | null;
            readonly revision: string | null;
            readonly widthInPixels: number | null;
            readonly heightInPixels: number | null;
            readonly sizeInBytes: number | null;
            readonly path: string | null;
            readonly copies: ReadonlyArray<{
                readonly id: string;
            }> | null;
        } | null;
    } | null;
};
export type mediaAssetFilePageQuery = {
    readonly response: mediaAssetFilePageQueryResponse;
    readonly variables: mediaAssetFilePageQueryVariables;
};



/*
query mediaAssetFilePageQuery(
  $id: ID!
) {
  mediaAssetFile(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    mediaAssetFile {
      __typename
      id
      name
      revision
      widthInPixels
      heightInPixels
      sizeInBytes
      path
      copies {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mediaAssetFile",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "MediaAssetFilePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mediaAssetFile",
        "storageKey": null,
        "args": null,
        "concreteType": "MediaAssetFile",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "widthInPixels",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "heightInPixels",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sizeInBytes",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "path",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "copies",
            "storageKey": null,
            "args": null,
            "concreteType": "NetworkLocation",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "mediaAssetFilePageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "mediaAssetFilePageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "mediaAssetFilePageQuery",
    "id": null,
    "text": "query mediaAssetFilePageQuery(\n  $id: ID!\n) {\n  mediaAssetFile(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    mediaAssetFile {\n      __typename\n      id\n      name\n      revision\n      widthInPixels\n      heightInPixels\n      sizeInBytes\n      path\n      copies {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4c09ac9e60b08d6e8174b4b30d39c7de';
export default node;
