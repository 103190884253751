/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-7-7
 * @description Implementation of the search component.
 * @filename cms-search-component.tsx
 */
import * as React from "react";
import { CarouselItem } from "../../../../component-configuration/carousel-item";
import { CloudSearchHelper } from "../../../../utilities/cms-operation/cloud-search-helper";
import { mapTypeToSearch } from "../../../../utilities/search";
import ProgressiveImage from "react-progressive-image";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";

/**
 *
 */
const MESSAGES = {
  Idle: "Start typing to see our magic",
  Searching: "Loading",
  Failure: "No search result for your search query",
  Results: ""
};

/**
 *
 */
const NO_IMAGE = require("../../../../../static/search.webp");

/**
 * The props of the Search component.
 *
 * @interface
 */
interface CMSSearchComponentProps {
  /**
   * search input placeholder.
   *
   * @type {string}
   * @memberof SearchProps
   */
  placeholder: string;

  /**
   * the function that toggles the search component.
   *
   * @type { (e: React.MouseEvent) => void}
   * @memberof SearchProps
   */
  toggleSearch?: (e?: React.MouseEvent, state?: string) => void;

  /**
   * indicates when the search is toggled (closed or opened)
   *
   * @type {boolean}
   */
  searchComponentOpened?: boolean;

  /**
   *Handles adding existing entity when a search suggestion is clicked in entity acquisiting modal
   *
   * @type {Function}
   */
  AddExistingEntity?: Function;

  /**
   *Handles select person on click on suggestion panel list items
   *
   * @type {Function}
   */
  searchAction?: Function;

  /**
   *
   *
   * @type {string}
   * @memberof SearchProps
   */
  id?: string;

  /**
   *
   *
   * @type {{ field: ""; values: string[] }[]}
   * @memberof NavSearchProps
   */
  filters?: { field: string; values: string[] }[];
}

/**
 * The state of the Search component.
 *
 * @interface
 */
interface CMSSearchComponentState {
  /**
   *
   *
   * @type {("idle" | "searching" | "failure")}
   * @memberof NavSearchState
   */
  searchState: "Idle" | "Searching" | "Failure" | "Results";

  /**
   * The search results
   *
   * @type {CarouselItem[]}
   */
  searchResult: CarouselItem[];

  /**
   * The search value typed in
   *
   * @type {string}
   */
  searchValue: string;
}

/**
 *  A React component that renders the search component.
 *
 * @export
 * @class SearchComponent
 * @extends {React.Component<SearchProps>}
 */
export class CMSSearchComponent extends React.Component<
  CMSSearchComponentProps,
  CMSSearchComponentState
> {
  /**
   * Ref to the search input
   *
   * @type {React.RefObject<HTMLInputElement>}
   */
  inputRef: React.RefObject<HTMLInputElement>;
  /**
   * Constructor.
   *
   * @param {SearchProps} props The props of the component.
   */
  constructor(props: CMSSearchComponentProps) {
    super(props);
    this.inputRef = React.createRef();

    this.state = {
      searchState: "Idle",
      searchResult: [],
      searchValue: ""
    };
  }

  /**
   * Invoked before the render method is called
   * used to clear the search component
   *
   */
  static getDerivedStateFromProps(
    props: CMSSearchComponentProps,
    state: CMSSearchComponentState
  ): any {
    if (!props.searchComponentOpened) {
      //if the search is closed, clear search value and suggestions
      state.searchResult = [];
      state.searchValue = "";
      state.searchState = "Idle";
      //TODO CONTROLLED INPUT
      // let currentInput: any = document.querySelector(
      //   ".search-form-shown input"
      // );
      // if (currentInput) currentInput.value = "";
    }
    // }
    return null;
  }

  /**
   * Invoked when the user types a keyword for search.
   *
   * @param {React.ChangeEvent} e The event which triggered the function.
   */
  handleSearchValue(e: React.ChangeEvent) {
    let currentTarget: any = e.currentTarget;
    let currentValue: string = currentTarget.value;
    let promise: Promise<any>;

    if (currentValue.length > 2) {
      this.setSearchState("Searching", []);

      promise = CloudSearchHelper.search(currentValue, this.props.filters)
        .then((items: CarouselItem[]) => {
          if (items === undefined || items.length <= 0) {
            throw new Error("Bad Search Results");
          }
          this.setSearchState("Results", items);
        })
        .catch(err => {
          console.error(err);
          this.setSearchState("Failure", []);
        });
    } else {
      promise = Promise.resolve();
    }

    this.setState({ searchValue: currentValue });

    return promise;
  }

  /**
   *
   *
   * @memberof CMSSearchComponent
   */
  setSearchState = (
    state: "Failure" | "Results" | "Searching" | "Idle",
    results: CarouselItem[]
  ) => {
    this.setState({ searchResult: results, searchState: state });
  };

  /**
   * Invoked when the user clicks on a suggestion for the search.
   *
   * @param {React.MouseEvent<HTMLElement>} event The event which triggered the function
   * @param {any} suggestion The suggestion the user clicked on
   */
  handleSearchSuggestionClick = (
    event: React.MouseEvent<HTMLElement>,
    searchItem: any
  ) => {
    this.inputRef.current.classList.remove("suggest");
    //close the search component on click
    //check if the search is initiated from main navbar or one of the panels.
    //because the toggling function is different in each
    if (this.props.toggleSearch) {
      this.props.toggleSearch(event);
    }

    if (this.props.searchAction) {
      this.props.searchAction(searchItem);
    } else {
      let itemType = mapTypeToSearch(searchItem.type).toUpperCase();

      if (itemType) {
        let pageAlias = CMS_PAGE_CONSTANT[itemType].alias;
        window.location.assign(`/${pageAlias}/${searchItem.id}`);
      }

      //after routing, clear search value and suggestions
      this.setSearchState("Idle", []);
    }
  };

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render() {
    return (
      <div>
        <div className="gray-div" />
        <input
          ref={this.inputRef}
          type="text"
          placeholder={`${this.props.placeholder}`}
          aria-label="Search"
          value={this.state.searchValue}
          onChange={event => this.handleSearchValue(event)}
          id={this.props.id}
        />
        <div className="container-fluid">
          <div className="results-container">
            <h2 className="text-center">{MESSAGES[this.state.searchState]}</h2>
            <div className="suggestions">
              <ul
                className="list-group row six-by-one-grid justify-content-md-center"
                style={
                  this.state.searchResult && this.state.searchResult.length > 4
                    ? {
                        overflowY: "scroll",
                        maxHeight: 380
                      }
                    : { overflow: "hidden" }
                }
              >
                {this.state.searchResult.map(
                  (result: CarouselItem, index: number) => {
                    return (
                      <li
                        className="list-group-item text-result col-md-3"
                        key={index}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          this.handleSearchSuggestionClick(event, result);
                        }}
                      >
                        <div className=" row justify-content-md-center">
                          <ProgressiveImage
                            src={
                              result.previewImageUrl &&
                              result.previewImageUrl.requestedResolutionUrl
                                ? result.previewImageUrl.requestedResolutionUrl
                                : NO_IMAGE
                            }
                            placeholder={
                              result.previewImageUrl &&
                              result.previewImageUrl.placeHolderUrl
                                ? result.previewImageUrl.placeHolderUrl
                                : NO_IMAGE
                            }
                          >
                            {(src: any, _loading: any, srcSetData: any) => (
                              <img
                                src={src}
                                className="logo-img"
                                onError={(targetImage: any) => {
                                  targetImage.onerror = null;
                                  targetImage.src = NO_IMAGE;
                                }}
                              />
                            )}
                          </ProgressiveImage>
                        </div>
                        <div className="row justify-content-md-center">
                          <span>
                            <a>{result?.title?.substr(0, 80)}</a>
                          </span>
                        </div>
                        <div className="row justify-content-md-center">
                          <span className="filter-source">
                            {mapTypeToSearch(result?.type).toUpperCase()}
                          </span>
                        </div>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>

            <hr />
          </div>
        </div>
      </div>
    );
  }
}
