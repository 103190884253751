/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Initial page component
 * @filename InitialPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Publisher from "./publisher";
import AuthorPanel from "./author/author-panel";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import ModerationPanel from "./moderation/moderation-panel";
import {
  BookStory,
  GRAPHQL_TYPE_BOOK_STORY,
  PreviewKind
} from "@radivision/graphql";

import { Book } from "../../../utilities/book";
import { Validator } from "../../../utilities/cms-operation/validator";
import ModalConductor from "./modal/modal-conductor";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import { graphql, QueryRenderer } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import {
  BookPageFormInput,
  FormGroupInput,
  PreviewItem
} from "../../../component-configuration/cms-pages";

/**
 *
 *
 * @export
 * @interface BookPageProps
 */
export interface BookPageProps {
  /**
   *
   *
   * @type {string}
   * @memberof BookPageProps
   */
  book?: { id?: string };

  /**
   *
   *
   * @type {BookStory}
   * @memberof BookPageProps
   */
  bookStory?: BookStory;
}

/**
 * The state of the book page component.
 * @export
 * @interface
 */
export interface BookPageState {
  /**
   *
   *
   * @type {*}
   * @memberof BookPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   */
  bookStoryInput: BookPageFormInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof BookPageState
   */
  errorMessage: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof BookPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof BookPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class BookPage
 * @extends {React.Component<BookPageProps, BookPageState>}
 */
export class BookPage extends React.Component<BookPageProps, BookPageState> {
  /**
   * References to the Author component in the BookPage.
   *
   * @type {*}
   * @memberof BookPage
   */
  authorPanelRef: any;

  /**
   * References to the moderation component in the BookPage.
   *
   * @type {*}
   * @memberof BookPage
   */
  moderationPanelRef: any;

  /**
   * References to the publisher component in the BookPage.
   *
   * @type {*}
   * @memberof BookPage
   */
  publisherDetailsRef: any;

  /**
   * References to the previews panel component in the BookPage
   *
   * @type {*}
   * @memberof BookPage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the BookPage.
   *
   * @type {*}
   * @memberof BookPage
   */
  tagsRef: any;

  /**
   *Creates an instance of BookPage.
   * @param {BookPageProps} props
   * @memberof BookPage
   */
  constructor(props: BookPageProps) {
    super(props);

    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    };
    //... bind needed function to class

    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.handleLinkChanges = this.handleLinkChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handlePublishDateChanges = this.handlePublishDateChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handleTimeToReadChanges = this.handleTimeToReadChanges.bind(this);
    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleIsNewChanges = this.handleIsNewChanges.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    // ... initialize required components references

    this.authorPanelRef = React.createRef();
    this.moderationPanelRef = React.createRef();
    this.publisherDetailsRef = React.createRef();
    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();

    //... initial component state
    modal = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    /// handle provided props in case of edit already added article
    this.state = this.handleComponentStateInitialize(this.props.bookStory);
  }

  /**
   *
   *
   * @param {BookStory} bookStoryProps
   * @returns {BookPageState}
   * @memberof BookPage
   */
  handleComponentStateInitialize(bookStoryProps: BookStory): BookPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let bookStoryInput: BookPageFormInput = {
      id: undefined,
      label: "",
      link: "",
      title: "",
      subTitle: "",
      summary: "",
      publisherName: "",
      publishedDate: "",
      isDeleted: false,
      isNew: false,
      timeToRead: 10
    };

    let state: BookPageState = {
      modal,
      bookStoryInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    if (bookStoryProps !== null && bookStoryProps !== undefined) {
      // validate provided label

      if (bookStoryProps.id) {
        state.bookStoryInput.id = bookStoryProps.id;
      }

      if (bookStoryProps.label) {
        state.bookStoryInput.label = bookStoryProps.label;
      }

      // validate provided link
      if (bookStoryProps.link) {
        state.bookStoryInput.link = bookStoryProps.link;
      }

      if (bookStoryProps.title) {
        state.bookStoryInput.title = bookStoryProps.title;
      }

      if (bookStoryProps.subTitle) {
        state.bookStoryInput.subTitle = bookStoryProps.subTitle;
      }

      if (bookStoryProps.description) {
        state.bookStoryInput.summary = bookStoryProps.description;
      }

      if (bookStoryProps.isDeleted) {
        state.bookStoryInput.isDeleted = bookStoryProps.isDeleted;
      }

      if (bookStoryProps.isNew) {
        state.bookStoryInput.isNew = bookStoryProps.isNew;
      }

      if (bookStoryProps.timeToReadInSeconds) {
        state.bookStoryInput.timeToRead = bookStoryProps.timeToReadInSeconds;
      }

      if (bookStoryProps.publisher) {
        state.bookStoryInput.publisherName = bookStoryProps.publisher;
      }

      if (bookStoryProps.publishedDate) {
        state.bookStoryInput.publishedDate = bookStoryProps.publishedDate;
      }

      if (bookStoryProps.revision) {
        state.bookStoryInput.revision = bookStoryProps.revision;
      }

      // ............

      // initialize form group inputs

      //...............
      if (bookStoryProps.publisher) {
        state.formGroupInput.publisherProps = {
          name: bookStoryProps.publisher
          // type :""
        };
      }

      if (
        bookStoryProps.tags !== null &&
        bookStoryProps.tags !== undefined &&
        bookStoryProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = bookStoryProps.tags.map(
          tag => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        bookStoryProps.previews !== null &&
        bookStoryProps.previews !== undefined &&
        bookStoryProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = bookStoryProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`
                  }
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @returns
   * @memberof BookPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Book Story</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Link URL</label>
                  <input
                    type="text"
                    name="Link URL"
                    className={`form-control ${
                      this.state.errorMessage.get("LINK") ? "is-invalid" : ""
                    }`}
                    value={this.state.bookStoryInput.link}
                    onChange={this.handleLinkChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("LINK")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.bookStoryInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    name="Subtitle"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.bookStoryInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>
            {/* Author Section */}
            <div className="Page--Author">
              <label htmlFor="">Authors</label>
              <AuthorPanel ref={this.authorPanelRef} />
            </div>
            {/* Publisher Section */}
            <div className="Page--Publisher">
              <label>Publisher</label>
              <Publisher
                error={{
                  haveError: this.state.errorMessage.get("PUBLISHER")
                    ? true
                    : false,
                  message: this.state.errorMessage.get("PUBLISHER")
                }}
                publisher={this.state.formGroupInput.publisherProps}
                ref={this.publisherDetailsRef}
              />
            </div>
            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Published</label>
                <input
                  id="party"
                  type="datetime"
                  name="published"
                  value={this.state.bookStoryInput.publishedDate}
                  onChange={this.handlePublishDateChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>Time to Read(Seconds)</label>
                <input
                  id="party"
                  type="number"
                  name="timetoread"
                  className={`form-control ${
                    this.state.errorMessage.get("TIME") ? "is-invalid" : ""
                  }`}
                  value={this.state.bookStoryInput.timeToRead}
                  onChange={this.handleTimeToReadChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("TIME")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.bookStoryInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Summary</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.bookStoryInput.summary}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="isdeleted"
                  checked={this.state.bookStoryInput.isDeleted}
                  className="custom-control-input"
                  readOnly
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="new"
                  value={this.state.bookStoryInput.isNew ? "true" : "false"}
                  onClick={this.handleIsNewChanges}
                  className="custom-control-input"
                  readOnly
                />
                <label className="custom-control-label">New</label>
              </div>
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <ManagePreviewPanel
                ref={this.previewPanelRef}
                previewsList={this.state.formGroupInput.previewsProps}
              />
            </div>
            {/* moderation section */}
            <div className="Page--Moderation">
              <ModerationPanel ref={this.moderationPanelRef} />
            </div>
          </div>

          <Loader isActive={this.state.isLoading} />

          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let bookStoryInput = this.state.bookStoryInput;
                  bookStoryInput.isDeleted = true;
                  this.setState({ bookStoryInput });
                },
                unDeleteAction: () => {
                  let bookStoryInput = this.state.bookStoryInput;
                  bookStoryInput.isDeleted = false;
                  this.setState({ bookStoryInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
        </div>
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in book link state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof BookPage
   */
  handleLinkChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let bookStoryInput = this.state.bookStoryInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();

    // // console\.log("[handleLinkChanges] provided value : ", eventValue);\
    // ... assign value if valid
    bookStoryInput.link = eventValue;
    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling changes in book title state
   *
   * @memberof BookPage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let bookStoryInput = this.state.bookStoryInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    bookStoryInput.title = eventValue;
    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling changes in book sub-title state
   *
   * @memberof BookPage
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let bookStoryInput = this.state.bookStoryInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    bookStoryInput.subTitle = eventValue;
    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling changes in book Publish Date state
   *
   * @memberof BookPage
   */
  handlePublishDateChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let bookStoryInput = this.state.bookStoryInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handlePublishDateChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    bookStoryInput.publishedDate = eventValue;
    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling changes in book time to read state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof BookPage
   */
  handleTimeToReadChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let bookStoryInput = this.state.bookStoryInput;
    let eventValue: number = Number(changeEvent.currentTarget.value);
    // // console\.log("[handleTimeToReadChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    bookStoryInput.timeToRead = eventValue;
    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling changes in book summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof BookPage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let bookStoryInput = this.state.bookStoryInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleArticleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    bookStoryInput.summary = eventValue;
    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling changes in book label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof BookPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let bookStoryInput = this.state.bookStoryInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleLabelChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    bookStoryInput.label = eventValue;
    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling changes in book is deleted state
   *
   * @memberof BookPage
   */
  handleIsDeletedChanges(event: any): void {
    let isDeleted: boolean = event.target.checked ? true : false;
    let bookStoryInput = this.state.bookStoryInput;

    bookStoryInput.isDeleted = isDeleted;
    // // console\.log("[handleIsDeletedChanges] is book deleted", isDeleted);

    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling changes in book is new state
   *
   * @memberof BookPage
   */
  handleIsNewChanges(event: any): void {
    let isNew: boolean = event.target.checked ? true : false;
    let bookStoryInput = this.state.bookStoryInput;

    bookStoryInput.isNew = isNew;
    // // console\.log("[handleIsNewChanges] is book new ", isNew);
    this.setState({ bookStoryInput });
  }

  /**
   * Function Responsible for handling submitting new article
   *
   * @memberof ArticlePage
   */
  handleSaveAction(): void {
    let bookPageState: BookPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract moderation state
      bookPageState.bookStoryInput.moderation = this.moderationPanelRef.current.extractModerationState();
      // extract tags state
      bookPageState.bookStoryInput.tags = this.tagsRef.current.extractTagsString();
      // extract publisher name
      bookPageState.bookStoryInput.publisherName = this.publisherDetailsRef.current.extractPublisherDetails();
      // extract previews list
      bookPageState.bookStoryInput.previews = this.previewPanelRef.current.extractPreviews();

      // validate errors
      errorMessage = Validator.validateCreateStoryInput(
        bookPageState.bookStoryInput
      );

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      if (errorMessage.size < 1) {
        // console\.log("[handleAddArticle] errorMessage ", errorMessage);

        // ... provide validated input to  utility
        if (
          this.props.bookStory !== null &&
          this.props.bookStory !== undefined
        ) {
          internalPromise = Book.updateBookStory(
            bookPageState.bookStoryInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${CMS_PAGE_CONSTANT["BOOK"].message.confirmation.update},Book-id : ${results.id}`;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Book.addBook(bookPageState.bookStoryInput).then(
            (results: any) => {
              // ... handle mutation success
              // console\.log(results);
              modal.modalName = "ConfirmModal";
              modal.modalBody = `${CMS_PAGE_CONSTANT["BOOK"].message.confirmation.add},Book-id : ${results.id}`;
              modal.modalTile = "Action Complete";
              this.revertPageInput();
            }
          );
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["BOOK"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }

      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);

      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @memberof BookPage
   */
  revertPageInput(input?: BookStory): void {
    // ... reset component own state
    let state: BookPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.bookStory
    );

    this.setState(state);

    //... reset children state

    this.tagsRef.current.resetComponent();
    this.authorPanelRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.publisherDetailsRef.current.resetComponent();
    this.moderationPanelRef.current.resetComponent();
  }
  /**
   *
   *
   * @memberof BookPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof BookPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class BookStoryPageContainer extends React.Component<
  BookPageProps,
  BookPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let bookId: string;

    // console\.log("[BookStoryPageContainer] provided props", this.props);

    if (this.props.book === null || this.props.book === undefined) {
      node = <BookPage />;
    } else {
      bookId = this.props.book.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query bookPageQuery($id: ID!) {
              story(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                node {
                  __typename
                  ... on BookStory {
                    id
                    link
                    publisher
                    timeToReadInSeconds
                    publishedDate
                    description
                    created
                    label
                    cost
                    isDeleted
                    isNew
                    revision
                    tags {
                      id
                      label
                    }
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                              label
                            }
                          }
                        }
                      }
                    }
                    subTitle
                    title
                  }
                }
              }
            }
          `}
          variables={{
            id: bookId
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.story.hasErrors) {
                return (
                  <div>
                    <p>unexpected error contact Radivision Technical team.</p>{" "}
                  </div>
                );
              } else if (
                props.story.node.__typename !== GRAPHQL_TYPE_BOOK_STORY
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Book id, {bookId} belongs to type{" "}
                      {props.story.node.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.story.node) {
                let pageProps: BookPageProps = {
                  ...this.props,
                  bookStory: props.story.node
                };
                return <BookPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
