/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ClauseOperatorKind = "AND" | "OR" | "%future added value";
export type CreateDynamicListInput = {
    readonly badges?: ReadonlyArray<string> | null;
    readonly clientMutationId: string;
    readonly defaultOrder?: ReadonlyArray<CreateOrderExpressionInput> | null;
    readonly description?: string | null;
    readonly filter: CreateFilterExpressionInput;
    readonly label?: string | null;
    readonly name?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly questionnaire?: string | null;
    readonly reviews?: ReadonlyArray<CreateReviewInput> | null;
    readonly shared?: number | null;
    readonly subTitle?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly title?: string | null;
};
export type CreateOrderExpressionInput = {
    readonly attribute?: string | null;
    readonly descending?: boolean | null;
};
export type CreateFilterExpressionInput = {
    readonly expression?: string | null;
    readonly tags?: CreateTagsClauseInput | null;
};
export type CreateTagsClauseInput = {
    readonly clauses?: ReadonlyArray<CreateTagsClauseInput> | null;
    readonly operator?: ClauseOperatorKind | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type CreateReviewInput = {
    readonly content?: string | null;
    readonly helpful?: number | null;
    readonly notHelpful?: number | null;
    readonly rating?: number | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type createDynamicListMutationVariables = {
    readonly input: CreateDynamicListInput;
};
export type createDynamicListMutationResponse = {
    readonly createDynamicList: {
        readonly clientMutationId: string;
        readonly dynamicList: {
            readonly __typename: string;
            readonly id: string;
            readonly title: string | null;
        } | null;
        readonly hasErrors: boolean | null;
    } | null;
};
export type createDynamicListMutation = {
    readonly response: createDynamicListMutationResponse;
    readonly variables: createDynamicListMutationVariables;
};



/*
mutation createDynamicListMutation(
  $input: CreateDynamicListInput!
) {
  createDynamicList(input: $input) {
    clientMutationId
    dynamicList {
      __typename
      id
      title
    }
    hasErrors
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDynamicListInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createDynamicList",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateDynamicListPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dynamicList",
        "storageKey": null,
        "args": null,
        "concreteType": "DynamicList",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createDynamicListMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "createDynamicListMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "createDynamicListMutation",
    "id": null,
    "text": "mutation createDynamicListMutation(\n  $input: CreateDynamicListInput!\n) {\n  createDynamicList(input: $input) {\n    clientMutationId\n    dynamicList {\n      __typename\n      id\n      title\n    }\n    hasErrors\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'a279ded224de9e1c722d830f43363621';
export default node;
