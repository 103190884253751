/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type posterPageQueryVariables = {
    readonly id: string;
};
export type posterPageQueryResponse = {
    readonly poster: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly poster: {
            readonly __typename: string;
            readonly id: string;
            readonly publishedDate: unknown | null;
            readonly description: string | null;
            readonly actionDescription: string | null;
            readonly actionTitle: string | null;
            readonly created: unknown | null;
            readonly label: string | null;
            readonly isDeleted: boolean | null;
            readonly tags: ReadonlyArray<{
                readonly id: string;
                readonly label: string | null;
            }> | null;
            readonly previews: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly id: string;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
            readonly subTitle: string | null;
            readonly title: string | null;
            readonly revision: string | null;
        } | null;
    } | null;
};
export type posterPageQuery = {
    readonly response: posterPageQueryResponse;
    readonly variables: posterPageQueryVariables;
};



/*
query posterPageQuery(
  $id: ID!
) {
  poster(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    poster {
      __typename
      id
      publishedDate
      description
      actionDescription
      actionTitle
      created
      label
      isDeleted
      tags {
        id
        label
      }
      previews {
        count
        edges {
          cursor
          preview {
            link
            kind
            mediaAsset {
              id
              files {
                id
                name
                path
              }
              topLevelMediaType {
                kind
                label
                id
              }
            }
          }
        }
      }
      subTitle
      title
      revision
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actionDescription",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actionTitle",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDeleted",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": null,
  "args": null,
  "concreteType": "Tag",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v11/*: any*/)
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "path",
      "args": null,
      "storageKey": null
    }
  ]
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "posterPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "poster",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PosterPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "poster",
            "storageKey": null,
            "args": null,
            "concreteType": "Poster",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "previews",
                "storageKey": null,
                "args": null,
                "concreteType": "PreviewsConnection",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewEdge",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "preview",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Preview",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v17/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaAsset",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaAsset",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v18/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "topLevelMediaType",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TopLevelMediaType",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/),
                                  (v11/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "posterPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "poster",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PosterPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "poster",
            "storageKey": null,
            "args": null,
            "concreteType": "Poster",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "previews",
                "storageKey": null,
                "args": null,
                "concreteType": "PreviewsConnection",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewEdge",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "preview",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Preview",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v17/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaAsset",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaAsset",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v18/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "topLevelMediaType",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TopLevelMediaType",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/),
                                  (v11/*: any*/),
                                  (v3/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "posterPageQuery",
    "id": null,
    "text": "query posterPageQuery(\n  $id: ID!\n) {\n  poster(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    poster {\n      __typename\n      id\n      publishedDate\n      description\n      actionDescription\n      actionTitle\n      created\n      label\n      isDeleted\n      tags {\n        id\n        label\n      }\n      previews {\n        count\n        edges {\n          cursor\n          preview {\n            link\n            kind\n            mediaAsset {\n              id\n              files {\n                id\n                name\n                path\n              }\n              topLevelMediaType {\n                kind\n                label\n                id\n              }\n            }\n          }\n        }\n      }\n      subTitle\n      title\n      revision\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '956a8407704ffa49065eee143347ce1f';
export default node;
