/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type groupQueryVariables = {
    readonly name: string;
};
export type groupQueryResponse = {
    readonly ListGroupUsers: {
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly hasErrors: boolean | null;
        readonly groupUser: ReadonlyArray<{
            readonly name: string | null;
            readonly email: string | null;
            readonly username: string;
            readonly userStatus: string | null;
            readonly created: unknown | null;
            readonly lastModified: unknown | null;
        }> | null;
    } | null;
};
export type groupQuery = {
    readonly response: groupQueryResponse;
    readonly variables: groupQueryVariables;
};



/*
query groupQuery(
  $name: String!
) {
  ListGroupUsers(name: $name) {
    errors {
      id
      location
    }
    hasErrors
    groupUser {
      name
      email
      username
      userStatus
      created
      lastModified
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "ListGroupUsers",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "CognitoGroupUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "groupUser",
        "storageKey": null,
        "args": null,
        "concreteType": "CognitoGroupUser",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userStatus",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastModified",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "groupQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "groupQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "groupQuery",
    "id": null,
    "text": "query groupQuery(\n  $name: String!\n) {\n  ListGroupUsers(name: $name) {\n    errors {\n      id\n      location\n    }\n    hasErrors\n    groupUser {\n      name\n      email\n      username\n      userStatus\n      created\n      lastModified\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '52c29268b4b39c105daa194a0314e092';
export default node;
