/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to add an entity story.
 * @filename create-entity.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an entity.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_ENTITY_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createEntityMutation($input: CreateEntityInput!) {
    createEntity(input: $input) {
      clientMutationId
      errors {
        id
        location
      }
      entity {
        __typename
        id
        name
        revision
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
      }
      hasErrors
    }
  }
`;
