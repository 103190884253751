/**
 * @author Ahmed Samer
 * @date   2018-08-19
 * @description Implementation of the defaults.
 * @filename badges.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

export const DEFAULTS: {
  [index: string]: any;
} = {
  DEFAULT_RadivisionPost_CAROUSEL: "08/pl/08plAjZqQbClI6KhdSwKzQ.svg",
  DEFAULT_RadivisionPost_HEADSHOT: "08/pl/08plAjZqQbClI6KhdSwKzQ.svg",
  DEFAULT_RadivisionPost_HERO: "08/pl/08plAjZqQbClI6KhdSwKzQ.svg",
  DEFAULT_RadivisionPost_LOGO: "08/pl/08plAjZqQbClI6KhdSwKzQ.svg",
  DEFAULT_ArticleStory_CAROUSEL: "br/wp/bRwprGJYSG61cJ91zjFxeQ.png",
  DEFAULT_ArticleStory_HEADSHOT: "br/wp/bRwprGJYSG61cJ91zjFxeQ.png",
  DEFAULT_ArticleStory_HERO: "br/wp/bRwprGJYSG61cJ91zjFxeQ.png",
  DEFAULT_ArticleStory_LOGO: "br/wp/bRwprGJYSG61cJ91zjFxeQ.png",
  DEFAULT_RadivisionPost_SQUARE: "ej/zl/eJzLuPW6RiG0v1x24CGzxA.png",
  DEFAULT_ArticleStory_SQUARE: "br/wp/bRwprGJYSG61cJ91zjFxeQ.png",
  DEFAULT_BookStory_CAROUSEL: "ak/ib/AkiB4SlOThWXa8z6Nx6aPg.png",
  DEFAULT_BookStory_HEADSHOT: "ak/ib/AkiB4SlOThWXa8z6Nx6aPg.png",
  DEFAULT_BookStory_HERO: "ak/ib/AkiB4SlOThWXa8z6Nx6aPg.png",
  DEFAULT_BookStory_LOGO: "ak/ib/AkiB4SlOThWXa8z6Nx6aPg.png",
  DEFAULT_BookStory_SQUARE: "ak/ib/AkiB4SlOThWXa8z6Nx6aPg.png",
  DEFAULT_Entity_CAROUSEL: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_HEADSHOT: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_HERO: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_LOGO: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_SQUARE: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_BACKGROUND: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_NewsletterStory_CAROUSEL: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_NewsletterStory_HEADSHOT: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_NewsletterStory_HERO: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_NewsletterStory_LOGO: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_NewsletterStory_SQUARE: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_OnlineCourseStory_CAROUSEL: "qo/zo/qOzOhIp8RRGXDWhiYBQeSw.png",
  DEFAULT_OnlineCourseStory_HEADSHOT: "qo/zo/qOzOhIp8RRGXDWhiYBQeSw.png",
  DEFAULT_OnlineCourseStory_HERO: "qo/zo/qOzOhIp8RRGXDWhiYBQeSw.png",
  DEFAULT_OnlineCourseStory_LOGO: "qo/zo/qOzOhIp8RRGXDWhiYBQeSw.png",
  DEFAULT_OnlineCourseStory_SQUARE: "qo/zo/qOzOhIp8RRGXDWhiYBQeSw.png",
  DEFAULT_Person_CAROUSEL: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_HEADSHOT: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_HERO: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_LOGO: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_SQUARE: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_BACKGROUND: "rs/li/rSlIHwOhRdmFtPRoPrt87w.jpg",
  DEFAULT_PodcastStory_CAROUSEL: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_PodcastStory_HEADSHOT: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_PodcastStory_HERO: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_PodcastStory_LOGO: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_PodcastStory_SQUARE: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_OriginalContentStory_CAROUSEL: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_OriginalContentStory_HEADSHOT: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_OriginalContentStory_HERO: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_OriginalContentStory_LOGO: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_OriginalContentStory_SQUARE: "qt/xk/QTxkwRwYRCcI4dkBSXepiA.png",
  DEFAULT_DynamicList_SQUARE: "00/11/0011kzi5VETx7ku0.png",
  DEFAULT_DynamicList_CAROUSEL: "00/11/0011kzi5VETx7ku0.png",
  DEFAULT_FixedList_SQUARE: "00/11/0011kzi5VETx7ku0.png",
  DEFAULT_FixedList_CAROUSEL: "00/11/0011kzi5VETx7ku0.png"
};
