/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Url route page component
 * @filename manage-route-page.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import RouteProperty from "./route-properties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tag from "../tag/tag";
import { Loader } from "../../../page/loader";
import {
  UrlRoute as GraphUrlRoute,
  GRAPHQL_TYPE_URL_ROUTE,
  CreateUpdateUrlRouteRuleInput,
  UrlRouteRule
} from "@radivision/graphql";
import {
  FormGroupInput,
  UrlPageFormInput
} from "../../../../component-configuration/cms-pages";
import ModalConductor from "../modal/modal-conductor";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";
import { Validator } from "../../../../utilities/cms-operation/validator";
import { FloatingMenu } from "../floating-button/floating-menu";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { UrlRoute } from "../../../../utilities/cms-operation/url-route";
import { getCleanUuid } from "../../../../utilities/general";

export interface ManageUrlRoutePageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof MediaAssetPageProps
   */
  string?: string;

  /**
   *
   *
   * @type {string}
   * @memberof posterPageProps
   */
  graphUrlRoute?: GraphUrlRoute;
}

/**
 *
 *
 * @interface ManageUrlRouteState
 */
interface ManageUrlRouteState {
  /**
   *
   *
   * @type {any []}
   * @memberof ManageUrlRouteState
   */
  routePropertyList: any[];

  /**
   *
   *
   * @type {PosterPageFormInput}
   * @memberof posterPageState
   */
  formInput: UrlPageFormInput;

  /**
   *
   *
   * @type {*}
   * @memberof BookPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof posterPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof posterPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof posterPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class ManageRoutes
 * @extends {React.Component<{}, ManageUrlRouteState>}
 */
export class ManageRoutes extends React.Component<
  ManageUrlRoutePageProps,
  ManageUrlRouteState
> {
  /**
   *
   *
   * @type {*}
   * @memberof QuestionPanel
   */
  propertiesNodes: Map<number, RouteProperty>;

  /**
   * References to the Tags component in the posterPage.
   *
   * @type {*}
   * @memberof posterPage
   */
  tagsRef: any;

  /**
   *Creates an instance of ManageRoutes.
   * @param {{}} props
   * @memberof ManageRoutes
   */
  constructor(props: ManageUrlRoutePageProps) {
    super(props);

    //... bind needed function to class
    this.addMoreProperty = this.addMoreProperty.bind(this);
    this.handleAliasChanges = this.handleAliasChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleDescriptionChanges = this.handleDescriptionChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    //... initialize required components references

    this.tagsRef = React.createRef();
    this.propertiesNodes = new Map();
    /// handle provided props in case of edit already added urlRoute
    this.state = this.handleComponentStateInitialize(this.props.graphUrlRoute);
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @param {posterStory} urlRouteProps
   * @returns {posterPageState}
   * @memberof posterPage
   */
  handleComponentStateInitialize(
    urlRouteProps: GraphUrlRoute
  ): ManageUrlRouteState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: UrlPageFormInput = {
      id: undefined,
      alias: "",
      description: "",
      label: "",
      isDeleted: false,
      rules: undefined
    };

    let state: ManageUrlRouteState = {
      modal,
      routePropertyList: [],
      formInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    if (urlRouteProps !== null && urlRouteProps !== undefined) {
      if (urlRouteProps.id) {
        state.formInput.id = urlRouteProps.id;
      }

      if (urlRouteProps.label) {
        state.formInput.label = urlRouteProps.label;
      }

      if (urlRouteProps.alias) {
        state.formInput.alias = urlRouteProps.alias;
      }

      if (urlRouteProps.description) {
        state.formInput.description = urlRouteProps.description;
      }

      if (urlRouteProps.isDeleted) {
        state.formInput.isDeleted = urlRouteProps.isDeleted;
      }

      if (urlRouteProps.revision) {
        state.formInput.revision = urlRouteProps.revision;
      }

      // ........................
      // initialize form group inputs
      //...........................

      if (
        urlRouteProps.tags !== null &&
        urlRouteProps.tags !== undefined &&
        urlRouteProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = urlRouteProps.tags.map(
          tag => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        urlRouteProps.rules !== null &&
        urlRouteProps.rules !== undefined &&
        urlRouteProps.rules.length > 0
      ) {
        state.routePropertyList = this.handleUrlRouteRulesProps(
          urlRouteProps.rules
        );
      }
    }
    return state;
  }

  /**
   *
   *
   * @param {any[]} rules
   * @returns {any[]}
   * @memberof ManageRoutes
   */
  handleUrlRouteRulesProps(rules: any[]): any[] {
    let routePropertyList: any[] = [];
    let id: string = getCleanUuid();

    rules.map((rule, index) => {
      if (rule) {
        const properties = (
          <RouteProperty
            ref={node =>
              this.propertiesNodes.set(this.propertiesNodes.size, node)
            }
            key={id}
            urlRouteRule={rule}
            propertyId={index}
            removeClick={this.handleRemoveProperty}
          />
        );

        routePropertyList.push({ id, content: properties });
      }
    });

    return routePropertyList;
  }

  /**
   *
   *
   * @memberof ManageRoutes
   */
  addMoreProperty(event: any, urlRouteRule?: UrlRouteRule) {
    let id: string = getCleanUuid();
    let routePropertyList: any[] = this.state.routePropertyList;

    const properties = (
      <RouteProperty
        ref={node => this.propertiesNodes.set(this.propertiesNodes.size, node)}
        key={id}
        urlRouteRule={urlRouteRule}
        propertyId={this.state.routePropertyList.length}
        removeClick={this.handleRemoveProperty}
      />
    );

    routePropertyList.push({ id, content: properties });

    this.setState({
      routePropertyList
    });
  }

  /**
   *
   *
   * @memberof ManageRoutes
   */
  handleRemoveProperty = (id: any) => {
    let routePropertyList: any[] = this.state.routePropertyList;

    routePropertyList = routePropertyList.filter(properties => {
      return properties.content.props.propertyId !== id;
    });

    this.propertiesNodes.delete(id);

    this.setState({
      routePropertyList
    });
  };

  /**
   *
   *
   * @returns
   * @memberof ManageRoutes
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Manage URL Route</h2>

            <div className="row">
              <div className="input-field col m8">
                <label>
                  Alias<span style={{ color: "red" }}>*</span>
                </label>

                <input
                  placeholder="route name"
                  id="name"
                  type="text"
                  className={`form-control ${
                    this.state.errorMessage.get("ALIAS") ? "is-invalid" : ""
                  }`}
                  required={true}
                  value={this.state.formInput.alias}
                  onChange={this.handleAliasChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("ALIAS")}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="input-field col m8">
                <label>Description</label>

                <input
                  placeholder="route description"
                  id="description"
                  type="text"
                  className="validate form-control"
                  value={this.state.formInput.description}
                  onChange={this.handleDescriptionChanges}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-field col m4">
                <label>Created</label>

                <input
                  id="created"
                  type="text"
                  className="validate form-control"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="input-field col m4">
                <label>Last Modified</label>

                <input
                  id="lastmodified"
                  type="text"
                  className="validate form-control"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col mt-3">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>

            {/* tag section */}
            <div className="Page--Tag">
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>

            <div>
              {this.state.routePropertyList.length ? (
                this.state.routePropertyList.map((property, index) => {
                  return <div key={index}>{property.content}</div>;
                })
              ) : (
                <p style={{ display: "none" }}>nothing to show</p>
              )}
              <a className="btn add-btn" onClick={this.addMoreProperty}>
                <FontAwesomeIcon icon="plus-circle" /> Add
              </a>
            </div>
          </div>

          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />

          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
        </div>
        <Loader isActive={this.state.isLoading} />
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @memberof posterPage
   */
  handleAliasChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: UrlPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.alias = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in urlRoute label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof posterPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: UrlPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in urlRoute description state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof posterPage
   */
  handleDescriptionChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: UrlPageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.description = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in urlRoute is deleted state
   *
   * @memberof ArticlePage
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: UrlPageFormInput = this.state.formInput;
    // extract the value of input if is deleted or not
    let isDeleted: boolean = event.target.checked ? true : false;
    // // console.log("[handleIsDeletedChanges] is urlRoute deleted", isDeleted);
    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   *
   *
   * @memberof posterPage
   */
  revertPageInput(input?: GraphUrlRoute): void {
    let state: ManageUrlRouteState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.graphUrlRoute
    );
    this.setState(state);
    //... reset children state
    this.tagsRef.current.resetComponent();
    this.propertiesNodes.clear();
  }

  /**
   *
   *
   * @memberof posterPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof posterPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }

  /**
   * Function Responsible for handling submitting new poster
   *
   * @memberof posterPage
   */
  handleSaveAction(): void {
    let urlRouteInput: ManageUrlRouteState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();
    let urlRouteRules: CreateUpdateUrlRouteRuleInput[] = [];

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      urlRouteInput.formInput.tags = this.tagsRef.current.extractTagsString();

      // extract rules list
      for (const propertyRef of this.propertiesNodes.values()) {
        if (propertyRef) {
          urlRouteRules.push(propertyRef.extractUrlRules());
        }
      }

      urlRouteInput.formInput.rules = urlRouteRules;

      // validate errors
      errorMessage = Validator.validateUrlRouteInput(urlRouteInput.formInput);

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console.log("[handleSaveAction] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (this.props.string !== null && this.props.string !== undefined) {
          internalPromise = UrlRoute.updateUrlRoute(
            urlRouteInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody =
              CMS_PAGE_CONSTANT["URL_ROUTE"].message.confirmation.update;
            modal.modalTile = "Action Complete";
          });
        } else {
          internalPromise = UrlRoute.addUrlRoute(urlRouteInput.formInput).then(
            (results: any) => {
              // ... handle mutation success
              // console.log(results);
              modal.modalName = "ConfirmModal";
              modal.modalBody =
                CMS_PAGE_CONSTANT["URL_ROUTE"].message.confirmation.add;
              modal.modalTile = "Action Complete";
              this.revertPageInput();
            }
          );
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["URL_ROUTE"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }
}

export class UrlRoutePageContainer extends React.Component<
  ManageUrlRoutePageProps,
  ManageUrlRouteState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let urlRouteId: string;

    // console.log("[UrlRoutePageContainer] provided props", this.props);

    if (this.props.string === null || this.props.string === undefined) {
      node = <ManageRoutes />;
    } else {
      urlRouteId = this.props.string;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query manageRoutePageQuery($id: ID!) {
              urlRoute(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                urlRoute {
                  __typename
                  id
                  alias
                  description
                  label
                  revision
                  rules {
                    cognitoIdentityPool
                    cognitoUserPoolGroups
                    description
                    htmlDocument {
                      id
                    }
                    id
                    queryParameters {
                      name
                      values
                    }
                  }
                }
              }
            }
          `}
          variables={{
            id: urlRouteId
          }}
          render={({ error, props }) => {
            if (error) {
              // console.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console.log(props);
              if (props.urlRoute.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.urlRoute.urlRoute.__typename !== GRAPHQL_TYPE_URL_ROUTE
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid URL ROUTE id, {urlRouteId} belongs to type{" "}
                      {props.urlRoute.urlRoute.__typename}, Need more help?
                      contact Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.urlRoute.urlRoute) {
                let pageProps: ManageUrlRoutePageProps = {
                  ...this.props,
                  graphUrlRoute: props.urlRoute.urlRoute
                };
                return <ManageRoutes {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
