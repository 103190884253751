/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreateListCollectionInput = {
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly label?: string | null;
    readonly lists?: ReadonlyArray<string> | null;
    readonly name?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly questionnaire?: string | null;
    readonly reviews?: ReadonlyArray<CreateReviewInput> | null;
    readonly shared?: number | null;
    readonly subTitle?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly title?: string | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type CreateReviewInput = {
    readonly content?: string | null;
    readonly helpful?: number | null;
    readonly notHelpful?: number | null;
    readonly rating?: number | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type createListCollectionMutationVariables = {
    readonly input: CreateListCollectionInput;
};
export type createListCollectionMutationResponse = {
    readonly createListCollection: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly listCollection: {
            readonly __typename: string;
            readonly id: string;
            readonly revision: string | null;
        } | null;
    } | null;
};
export type createListCollectionMutation = {
    readonly response: createListCollectionMutationResponse;
    readonly variables: createListCollectionMutationVariables;
};



/*
mutation createListCollectionMutation(
  $input: CreateListCollectionInput!
) {
  createListCollection(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    listCollection {
      __typename
      id
      revision
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateListCollectionInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createListCollection",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateListCollectionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "listCollection",
        "storageKey": null,
        "args": null,
        "concreteType": "ListCollection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createListCollectionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "createListCollectionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "createListCollectionMutation",
    "id": null,
    "text": "mutation createListCollectionMutation(\n  $input: CreateListCollectionInput!\n) {\n  createListCollection(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    listCollection {\n      __typename\n      id\n      revision\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '48c4fb9f80e160e7223d651479df53c0';
export default node;
