/**
 * @author Ahmed Samer
 * @date   2019-08-18
 * @description page to list user pool group
 * @filename pool-groups.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import SingleItem from "../add-item/single-item";
import { Loader } from "../../../page/loader";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";

/**
 *
 *
 * @export
 * @interface PoolGroupPageProps
 */
export interface PoolGroupPageProps {
  groups: {
    name: string;
    description: string;
    created: string;
    lastModified: string;
  }[]; // to be replaced with group type;
}

/**
 *
 *
 * @interface PoolGroupPageState
 */
interface PoolGroupPageState {
  /**
   *
   *
   * @type {any []}
   * @memberof PoolGroupPageState
   */
  items: { title: string; icon: string; alias: string }[];

  /**
   *
   *
   * @type {boolean}
   * @memberof PoolGroupPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class PoolGroupPage
 * @extends {React.Component<PoolGroupPageProps, PoolGroupPageState>}
 */
export class PoolGroupPage extends React.Component<
  PoolGroupPageProps,
  PoolGroupPageState
> {
  /**
   * Creates an instance of AddItemPage.
   * @param {*} props
   * @memberof AddItemPage
   */
  constructor(props: any) {
    super(props);

    let items: any[];

    if (this.props.groups) {
      items = this.props.groups.map(group => {
        return {
          title: group.name,
          icon: "users",
          alias: `${CMS_PAGE_CONSTANT.COGNITO_POOL_GROUP_USERS.alias}/${group.name}`
        };
      });
    }

    this.state = {
      items: items,
      isLoading: false
    };
  }

  /**
   *
   *
   * @returns
   * @memberof AddItemPage
   */
  render() {
    return (
      <div className="dark-bg">
        <div className="AddItemPage container">
          <div className="row">
            <h1 className="text-center">Cognito Groups</h1>
          </div>
          <div className="row">
            {this.state.items.map((item, index) => {
              return (
                <SingleItem
                  title={item.title}
                  icon={item.icon}
                  pageAlias={item.alias}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class PoolGroupPageContainer extends React.Component<
  PoolGroupPageProps,
  PoolGroupPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;

    node = (
      <QueryRenderer
        environment={ENVIRONMENT}
        query={graphql`
          query poolGroupsQuery {
            ListPoolGroups {
              errors {
                id
                location
              }
              hasErrors
              group {
                name
                description
                created
                lastModified
              }
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            if (props.ListPoolGroups.hasErrors) {
              return (
                <div>
                  <p>
                    unexpected error : {JSON.stringify(props.errors)}, contact
                    Radivision Technical team.
                  </p>{" "}
                </div>
              );
            }
            //  else if (
            //   props.story.node.__typename !== GRAPHQL_TYPE_ARTICLE_STORY
            // ) {
            //   return (
            //     <div>
            //       <h2 className="font-weight-bold text-left">
            //         {" "}
            //         Invalid Article id, {articleId} belongs to type{" "}
            //         {props.story.node.__typename}, Need more help? contact
            //         Radivision Technical team.
            //       </h2>
            //     </div>
            //   );
            // }
            else if (props.ListPoolGroups.group) {
              let pageProps: PoolGroupPageProps = {
                ...this.props,
                groups: props.ListPoolGroups.group
              };
              return <PoolGroupPage {...pageProps} />;
            }
          }
          return <Loader isActive={true} />;
        }}
      />
    );

    return node;
  }
}
