/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to add an article story.
 * @filename create-article-story
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an article.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_ARTICLE_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createArticleStoryMutation($input: CreateArticleStoryInput!) {
    createArticleStory(input: $input) {
      clientMutationId
      story {
        ... on ArticleStory {
          description
          id
          link
          publisher
          publishedDate
          timeToReadInSeconds
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                  }
                }
              }
            }
          }
          revision
          subTitle
          title
        }
      }
      hasErrors
      errors {
        id
        location
      }
    }
  }
`;
