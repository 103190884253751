/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-11-25
 * @description Implementation of the GraphQL mutation to create support issue.
 * @filename create-support-issue.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add a suggestion.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_SUGGESTION_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createSuggestionMutation($input: CreateSuggestionInput!) {
    createSuggestion(input: $input) {
      clientMutationId
      suggestion {
        id
        content
      }
      hasErrors
    }
  }
`;
