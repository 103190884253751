/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-12
 * @description Implementation of the GraphQL mutation create person.
 * @filename create-person.ts
 */

import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an person.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_PERSON_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createPersonMutation($input: CreatePersonInput!) {
    createPerson(input: $input) {
      clientMutationId
      person {
        __typename
        emailAddresses
        fullName
        id
      }
      hasErrors
    }
  }
`;
