/**
 * @author Ahmed Samer
 * @date   2019-08-05
 * @description Fixed list page component
 * @filename list-collection-page.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import ListPanel from "./list-item-panel/list-item-panel";
import {
  ListCollection as GraphqlListCollection,
  FixedList as GraphqlFixedList,
  DynamicList as GraphqlDynamicList,
  PreviewKind,
  TopLevelMediaKind,
  GRAPHQL_TYPE_LIST_COLLECTION,
  GRAPHQL_TYPE_DYNAMIC_LIST,
  GRAPHQL_TYPE_FIXED_LIST
} from "@radivision/graphql";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Validator } from "../../../utilities/cms-operation/validator";
import ModalConductor from "./modal/modal-conductor";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import {
  FormGroupInput,
  ListPageFromInput,
  PreviewItem,
  ListCollectionPageFromInput
} from "../../../component-configuration/cms-pages";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { ImageHelper } from "../../../utilities/image-helper";
import { ListCollection } from "../../../utilities/list-collection";

/**
 *
 */
const SEARCH_FILTERS: { field: string; values: string[] }[] = [
  {
    field: "label",
    values: [GRAPHQL_TYPE_DYNAMIC_LIST, GRAPHQL_TYPE_FIXED_LIST]
  }
];

/**
 * the props of page
 *
 * @export
 * @interface ListCollectionPage
 */
export interface ListCollectionPageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof FixedListPageProps
   */
  listCollection?: { id: string };

  /**
   *
   *
   * @type {string}
   * @memberof FixedListPageProps
   */
  list?: GraphqlListCollection;
}

/**
 * the state of page
 *
 * @interface ListCollectionPageState
 */
export interface ListCollectionPageState {
  /**
   *
   *
   * @type {ListPosterPageFormInput}
   * @memberof posterPageState
   */
  formInput: ListCollectionPageFromInput;

  /**
   *
   *
   * @type {*}
   * @memberof BookPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof posterPageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof posterPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof posterPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class FixedListPage
 * @extends {React.Component<FixedListPageProps, FixedListPageState>}
 */
export class ListCollectionPage extends React.Component<
  ListCollectionPageProps,
  ListCollectionPageState
> {
  /**
   * References to the Lists component in the FixedListPage.
   *
   * @type {*}
   * @memberof FixedListPage
   */
  itemPanelRef: any;
  /**
   * References to the Previews component in the FixedListPage.
   *
   * @type {*}
   * @memberof FixedListPage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the FixedListPage.
   *
   * @type {*}
   * @memberof FixedListPage
   */
  tagsRef: any;

  /**
   *Creates an instance of FixedListPage.
   * @param {ListCollectionPageState} props
   * @memberof FixedListPage
   */
  constructor(props: ListCollectionPageProps) {
    super(props);

    //... bind needed function to class

    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleNameChanges = this.handleNameChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);

    //... initialize required components references

    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();
    this.itemPanelRef = React.createRef();

    //... initial component state

    this.state = this.handleComponentStateInitialize(this.props.list);
  }

  /**
   *
   *
   * @param {GraphqlFixedList} listCollectionProps
   * @returns {posterPageState}
   * @memberof posterPage
   */
  handleComponentStateInitialize(
    listCollectionProps: GraphqlListCollection
  ): ListCollectionPageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState
    };

    let formInput: ListPageFromInput = {
      id: undefined,
      title: "",
      label: "",
      subTitle: "",
      summary: ""
    };

    let state: ListCollectionPageState = {
      modal,
      formInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {}
    };

    if (listCollectionProps !== null && listCollectionProps !== undefined) {
      // ... if id provided

      if (listCollectionProps.id) {
        state.formInput.id = listCollectionProps.id;
      }

      if (listCollectionProps.label) {
        state.formInput.label = listCollectionProps.label;
      }

      if (listCollectionProps.title) {
        state.formInput.title = listCollectionProps.title;
      }
      if (listCollectionProps.name) {
        state.formInput.name = listCollectionProps.name;
      }

      if (listCollectionProps.subTitle) {
        state.formInput.subTitle = listCollectionProps.subTitle;
      }

      if (listCollectionProps.description) {
        state.formInput.summary = listCollectionProps.description;
      }

      if (listCollectionProps.isDeleted) {
        state.formInput.isDeleted = listCollectionProps.isDeleted;
      }

      if (listCollectionProps.revision) {
        state.formInput.revision = listCollectionProps.revision;
      }

      // ........................
      // initialize form group inputs
      //...........................

      if (
        listCollectionProps.tags !== null &&
        listCollectionProps.tags !== undefined &&
        listCollectionProps.tags.length > 0
      ) {
        let tagsList: {
          id: string;
          text: string;
        }[] = listCollectionProps.tags.map(tag => {
          return { id: tag.id, text: tag.label };
        });
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        listCollectionProps.previews !== null &&
        listCollectionProps.previews !== undefined &&
        listCollectionProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = listCollectionProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`
                  }
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }

      if (
        listCollectionProps.lists !== null &&
        listCollectionProps.lists !== undefined &&
        listCollectionProps.lists.length > 0
      ) {
        state.formGroupInput.lists = listCollectionProps.lists.map(
          (list: GraphqlFixedList | GraphqlDynamicList) => {
            let mediaAboutItem: CarouselItem = {
              id: list.id,
              title: list.title,
              type: list.__typename,
              previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: list.previews,
                  previewKind: [PreviewKind.SQUARE, PreviewKind.CAROUSEL],
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  type: list.__typename
                },
                desiredDimensions: {
                  containerWidthRatio: 6 / 12,
                  numberOfItems: 6
                },
                isSquareImage: true,
                revision: list.revision
              })
            };

            return mediaAboutItem;
          }
        );
      }
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof FixedListPage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">List Collection</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Name</label>
                  <input
                    type="text"
                    name="Name"
                    className={`form-control ${
                      this.state.errorMessage.get("Name") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.name}
                    onChange={this.handleNameChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    name="Subtitle"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.formInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>

            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="form-control readonly"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="form-control readonly"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.formInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Summary</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.summary}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="isdeleted"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                  className="custom-control-input"
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <ManagePreviewPanel
                ref={this.previewPanelRef}
                previewsList={this.state.formGroupInput.previewsProps}
              />
            </div>
            <div className="Page--Lists">
              <label>Lists </label>
              <div className="Page--Badge">
                <div className="Page--Lists">
                  <ListPanel
                    items={this.state.formGroupInput.lists}
                    ref={this.itemPanelRef}
                    readonly={false}
                    filter={SEARCH_FILTERS}
                  />
                </div>
              </div>
            </div>
          </div>
          <Loader isActive={this.state.isLoading} />
          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              cloneable: () => {},
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                }
              },
              saveable: this.handleSaveAction
            }}
          />
        </div>
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @memberof FixedListPage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.title = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  sub-title state
   *
   * @memberof FixedListPage
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.subTitle = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof FixedListPage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.summary = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof FixedListPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof FixedListPage
   */
  handleNameChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ListPageFromInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.name = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  is deleted state
   *
   * @memberof FixedListPage
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: ListPageFromInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    // // console\.log("[handleIsDeletedChanges] is poster deleted", isDeleted);
    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling submitting new
   *
   * @memberof FixedListPage
   */
  handleSaveAction(): void {
    let listInput: ListCollectionPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract tags state
      listInput.formInput.tags = this.tagsRef.current.extractTagsString();
      // extract previews list
      listInput.formInput.previews = this.previewPanelRef.current.extractPreviews();
      // extract item re-order
      listInput.formInput.lists = this.itemPanelRef.current.extractLists();

      // validate errors
      errorMessage = Validator.validateListInput(listInput.formInput);

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (
          this.props.listCollection !== null &&
          this.props.listCollection !== undefined
        ) {
          internalPromise = ListCollection.updateListCollection(
            listInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${CMS_PAGE_CONSTANT["LIST_COLLECTION"].message.confirmation.update}, list-id : ${results.id}`;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = ListCollection.addListCollection(
            listInput.formInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${CMS_PAGE_CONSTANT["LIST_COLLECTION"].message.confirmation.add}, list-id : ${results.id}`;
            modal.modalTile = "Action Complete";
            this.revertPageInput();
          });
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["LIST_COLLECTION"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch(err => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false
      });
    });
  }

  /**
   *
   *
   * @memberof FixedListPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof ArticlePage
   */
  revertPageInput(input?: GraphqlListCollection): void {
    let state: ListCollectionPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.list
    );
    this.setState(state);

    //... reset children state
    this.tagsRef.current.resetComponent();
    this.itemPanelRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof ArticlePage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 *
 *
 * @export
 * @class FixedListPageContainer
 * @extends {React.Component<FixedListPageProps, FixedListPageState>}
 */
export class ListCollectionPageContainer extends React.Component<
  ListCollectionPageProps,
  ListCollectionPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let listId: string;

    if (
      this.props.listCollection === null ||
      this.props.listCollection === undefined
    ) {
      node = <ListCollectionPage />;
    } else {
      listId = this.props.listCollection.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query listCollectionPageQuery($id: ID!) {
              listCollection(id: $id) {
                errors {
                  created
                  id
                  location
                  type
                }
                hasErrors
                listCollection {
                  __typename
                  description
                  id
                  label
                  revision
                  name
                  subTitle
                  title
                  lists {
                    __typename
                    ... on FixedList {
                      description
                      id
                      label
                      name
                      previews {
                        count
                        edges {
                          cursor
                          preview {
                            link
                            kind
                            mediaAsset {
                              id
                              files {
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                                label
                              }
                            }
                          }
                        }
                      }
                      subTitle
                      title
                    }
                    ... on DynamicList {
                      description
                      id
                      label
                      name
                      previews {
                        count
                        edges {
                          cursor
                          preview {
                            link
                            kind
                            mediaAsset {
                              files {
                                id
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                                label
                              }
                            }
                          }
                        }
                      }
                      subTitle
                      title
                    }
                  }
                }
              }
            }
          `}
          variables={{
            id: listId
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            } else if (props) {
              if (props.listCollection.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.listCollection.listCollection.__typename !==
                GRAPHQL_TYPE_LIST_COLLECTION
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid List id, {listId} belongs to type{" "}
                      {props.listCollection.listCollection.__typename}, Need
                      more help? contact Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.listCollection.listCollection) {
                let pageProps: ListCollectionPageProps = {
                  ...this.props,
                  list: props.listCollection.listCollection
                };
                return <ListCollectionPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
