/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-18
 * @description Implementation of the GraphQL mutation to create questionnaire
 * @filename create-questionnaire.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an Questionnaire.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_QUESTIONNAIRE_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createQuestionnaireMutation($input: CreateQuestionnaireInput!) {
    createQuestionnaire(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      questionnaire {
        id
        title
      }
    }
  }
`;
