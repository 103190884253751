/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-24
 * @description Implementation of Tag utilities to be used in the app.
 * @filename tag.ts
 */

import { CreateTagInput, UpdateTagInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { TagPageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_TAG_GRAPHQL_MUTATION } from "../../relay/mutations/create-tag";
import { UPDATE_TAG_GRAPHQL_MUTATION } from "../../relay/mutations/update-tag";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class Tag
 */
export class Tag {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ArticlePageState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addTag(
    tagInput: TagPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateTagInput
    let createInput: CreateTagInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      tagInput.label === undefined ||
      tagInput.label === null ||
      tagInput.label.length < 1
    ) {
      promise = Promise.reject(new Error("cXI51AVJrEKaD9p1djKTpQ"));
    } else {
      // initialize create person graphQl input
      createInput = {
        clientMutationId: getClientMutationId(),
        description:
          tagInput.description !== undefined &&
          tagInput.description !== null &&
          tagInput.description.length > 0
            ? tagInput.description.trim()
            : undefined,
        label:
          tagInput.label !== undefined &&
          tagInput.label !== null &&
          tagInput.label.length > 0
            ? tagInput.label.trim()
            : undefined,
        isGraphQlType:
          tagInput.isGraph !== undefined && tagInput.isGraph !== null
            ? tagInput.isGraph
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_TAG_GRAPHQL_MUTATION,
        variables: {
          input: createInput
        }
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createTag.hasErrors === false
          ? Promise.resolve(mutationResults.createTag.tag)
          : Promise.reject(mutationResults.createTag.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updateTag(
    tagInput: TagPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  UpdateTagInput
    let updatePosterInput: UpdateTagInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[updateTag] provided input ", JSON.stringify(tagInput));
    if (
      tagInput.id === undefined ||
      tagInput.id === null ||
      tagInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("6lPriAAJ3kSy0bbl336vLg"));
    } else {
      // initialize create person graphQl input
      updatePosterInput = {
        clientMutationId: getClientMutationId(),
        id: tagInput.id,
        revision: tagInput.revision,
        description:
          tagInput.description !== undefined &&
          tagInput.description !== null &&
          tagInput.description.length > 0
            ? tagInput.description.trim()
            : undefined,
        label:
          tagInput.label !== undefined &&
          tagInput.label !== null &&
          tagInput.label.length > 0
            ? tagInput.label.trim()
            : undefined,
        isGraphQlType:
          tagInput.isGraph !== undefined && tagInput.isGraph !== null
            ? tagInput.isGraph
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: UPDATE_TAG_GRAPHQL_MUTATION,
        variables: {
          input: updatePosterInput
        }
      };

      // attempt to commit update person mutation
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateTag.hasErrors === false
          ? Promise.resolve(mutationResults.updateTag.tag)
          : Promise.reject(mutationResults.updateTag.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteTag(
    tagInput: TagPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
