/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-17
 * @description Implementation of the GraphQL mutation to update a new media asset.
 * @filename update-media-asset
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update a new media asset.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_MEDIA_ASSET_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateMediaAssetMutation($input: UpdateMediaAssetInput!) {
    updateMediaAsset(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      mediaAsset {
        id
        description
        files {
          id
        }
      }
    }
  }
`;
