/**
 * @author Linkon Islam
 * @date   2019-01-15
 * @description Manage Badge Class
 * @filename ManageBadge.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { MediaImageSection } from "../../../modals/media-image-section";
import {
  ImageHelper
} from "../../../../utilities/image-helper";
import { Loader } from "../../../page/loader";
import { ImageUpload ,MediaAssetFileFunctionInput} from "../../../../utilities/image-uploader";

/**
 *
 *
 * @interface ManageBadgeProps
 */
interface ManageBadgeProps {
  /**
   *
   *
   * @type {string}
   * @memberof ManageBadgeProps
   */
  link?: string;
  /**
   * @param {string} type
   * @param {string} source
   * @returns {*}
   * @memberof ManageBadgeProps
   *
   *
   */
  addBadge(type?: string, source?: string): any;
  /**
   *
   *
   * @memberof ManageBadgeProps
   */
  onCancel(): void;
}

interface ManageBadgeState {
  /**
   *
   *
   * @type {boolean}
   * @memberof ManageBadgeState
   */
  showLoader: boolean;
  /**
   *
   *
   * @type {string}
   * @memberof ManageBadgeState
   */
  errorString: string;
}

/**
 *
 *
 * @class ManageBadge
 * @extends {React.Component<ManageBadgeProps, ManageBadgeState>}
 */
class ManageBadge extends React.Component<ManageBadgeProps, ManageBadgeState> {
  /**
   *
   *
   * @type {*}
   * @memberof ManageBadge
   */
  mediaModalRef: any;
  /**
   *Creates an instance of ManageBadge.
   * @param {*} props
   * @memberof ManageBadge
   */
  constructor(props: any) {
    super(props);

    this.mediaModalRef = React.createRef();

    this.saveBadge = this.saveBadge.bind(this);
    this.onSubmitBadge = this.onSubmitBadge.bind(this);

    this.state = {
      showLoader: false,
      errorString: ""
    };
  }

  /**
   *
   *
   * @memberof ManageBadge
   */
  saveBadge() {
    this.mediaModalRef.current.onExtractManipulateImage();
  }

  /**
   * triggers when user finish editing his profile Image
   * @param staticFileInformation object contains manipulated image needed arguments
   */
  onSubmitBadge(
    staticFileInformation: {
      fileBinaryContent: Blob;
      fileSize: number;
      fileName: string;
      mimeType: string;
    },
    onUploadSuccess: Function
  ) {
    let errorString = this.state.errorString;
    let type: "LINK" | "MEDIA_ASSET";
    let source: string;
    let promise: Promise<any>;

    this.setState({
      showLoader: true
    });

    if (staticFileInformation !== null && staticFileInformation !== undefined) {
      // attempt to upload manipulated image to s3 bucket
      promise = ImageUpload.uploadImageToS3(staticFileInformation);
      // attempt to handle upload return data then create media-asset
      promise = promise.then((result: AWS.S3.ManagedUpload.SendData) => {
        let input: MediaAssetFileFunctionInput = {
          attribution: "badge",
          description: `The badge for `,
          fileName: staticFileInformation.fileName,
          title: `badge`,
          fileSize: staticFileInformation.fileSize,
          format: staticFileInformation.mimeType
        };
        return ImageUpload.createMediaAssetFile(input);
      });
      // attempt to update person's previews with updated media asset
      promise = promise.then((mediaAssetId: string): void => {
        type = "MEDIA_ASSET";
        source = mediaAssetId;

        // check if provided function is defined first before actual invoke
        if (this.props.addBadge !== undefined && this.props.addBadge !== null) {
          this.props.addBadge(type, source);
        }
      });

      promise = promise.catch(err => {
        errorString = err;
      });
      promise = promise.finally(() => {
        this.setState({
          errorString,
          showLoader: false
        });
      });
    } else {
      this.props.onCancel();
    }
  }

  /**
   *
   *
   * @returns
   * @memberof ManageBadge
   */
  render() {
    return (
      <div className="ManageBadge">
        <Loader isActive={this.state.showLoader} />
        <MediaImageSection
          ref={this.mediaModalRef}
          onSubmit={this.onSubmitBadge}
          onUploadSuccess={null}
          onCancel={null}
        />
        <div className="invalid-feedback">{this.state.errorString}</div>
        <div className="ManageBadge--action">
          <div className="row">
            <div className="col-sm-12 col-md-6 text-md-left">
              <button
                type="button"
                className="btn btn-radivision outline outline-light"
                onClick={this.props.onCancel}
              >
                Cancel
              </button>
            </div>
            <div className="col-sm-12 col-md-6text-md-right">
              <button
                type="button"
                className="btn btn-radivision add-media-btn"
                onClick={this.saveBadge}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageBadge;
