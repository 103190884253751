import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SwitchListSecondary(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(["wifi"]);
  const { listItem } = props;
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List
      subheader={<ListSubheader>Settings</ListSubheader>}
      className={classes.root}
    >
      {listItem && listItem.length
        ? listItem.map((item,index) => {
            return (
              <ListItem>
                <ListItemIcon>{/* <WifiIcon /> */}</ListItemIcon>
                <ListItemText
                  id={`switch-list-label-${item.name}`}
                  primary={item.name}
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    key={index}
                    onChange={handleToggle(item.name)}
                    checked={checked.indexOf(item.name) !== -1}
                    inputProps={{
                      "aria-labelledby": `switch-list-label-${item.name}`,
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        : null}
    </List>
  );
}
