/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-17
 * @description Implementation of media asset utilities to be used in the app.
 * @filename media-asset.ts
 */

import { CreateMediaAssetInput, UpdateMediaAssetInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { MediaAssetPageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_MEDIA_ASSET_GRAPHQL_MUTATION } from "../../relay/mutations/create-media-asset";
import { UPDATE_MEDIA_ASSET_GRAPHQL_MUTATION } from "../../relay/mutations/update-media-asset";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class MediaAsset
 */
export class MediaAsset {
  /**
   * Returns a promise to add new article to database.
   *
   * @param {ArticlePageState} mediaAssetInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addMediaAsset(
    mediaAssetInput: MediaAssetPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreateMediaAssetInput
    let createMediaInput: CreateMediaAssetInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      mediaAssetInput.topMediaLevel === undefined ||
      mediaAssetInput.topMediaLevel === null
    ) {
      promise = Promise.reject(new Error("rMjEhpL8UkSCghe79lDSQg"));
    } else {
      // initialize create article graphQl input
      createMediaInput = {
        clientMutationId: getClientMutationId(),
        description:
          mediaAssetInput.description !== undefined &&
          mediaAssetInput.description !== null &&
          mediaAssetInput.description.length > 0
            ? mediaAssetInput.description.trim()
            : undefined,
        label:
          mediaAssetInput.label !== undefined &&
          mediaAssetInput.label !== null &&
          mediaAssetInput.label.length > 0
            ? mediaAssetInput.label.trim()
            : undefined,
        tags:
          mediaAssetInput.tags !== undefined &&
          mediaAssetInput.tags !== null &&
          mediaAssetInput.tags.length > 0
            ? mediaAssetInput.tags
            : undefined,

        files:
          mediaAssetInput.files !== undefined &&
          mediaAssetInput.files !== null &&
          mediaAssetInput.files.length > 0
            ? mediaAssetInput.files
            : undefined,
        topLevelMediaType: mediaAssetInput.topMediaLevel
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_MEDIA_ASSET_GRAPHQL_MUTATION,
        variables: {
          input: createMediaInput
        }
      };
      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        // console.log(mutationResults);
        return mutationResults.createMediaAsset.hasErrors === false
          ? Promise.resolve(mutationResults.createMediaAsset.mediaAsset)
          : Promise.reject(mutationResults.createMediaAsset.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updateMediaAsset(
    mediaAssetInput: MediaAssetPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let updateMediaAssetInput: UpdateMediaAssetInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      mediaAssetInput.id === undefined ||
      mediaAssetInput.id === null ||
      mediaAssetInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("AxUsvXknO0yxvMPvzBBYoQ"));
    } else {
      // initialize create article graphQl input
      updateMediaAssetInput = {
        clientMutationId: getClientMutationId(),
        id: mediaAssetInput.id,
        revision: mediaAssetInput.revision,
        description:
          mediaAssetInput.description !== undefined &&
          mediaAssetInput.description !== null &&
          mediaAssetInput.description.length > 0
            ? mediaAssetInput.description.trim()
            : undefined,
        durationInMilliseconds:
          mediaAssetInput.durationInMilliseconds !== undefined &&
          mediaAssetInput.durationInMilliseconds !== null &&
          mediaAssetInput.durationInMilliseconds.length > 0
            ? Number(mediaAssetInput.durationInMilliseconds.trim())
            : undefined,
        label:
          mediaAssetInput.label !== undefined &&
          mediaAssetInput.label !== null &&
          mediaAssetInput.label.length > 0
            ? mediaAssetInput.label.trim()
            : undefined,
        tags:
          mediaAssetInput.tags !== undefined &&
          mediaAssetInput.tags !== null &&
          mediaAssetInput.tags.length > 0
            ? mediaAssetInput.tags
            : undefined,
        files:
          mediaAssetInput.files !== undefined &&
          mediaAssetInput.files !== null &&
          mediaAssetInput.files.length > 0
            ? mediaAssetInput.files
            : undefined,
        state: mediaAssetInput.state
      };

      // console.log("[updateMediaAsset] mutation input ", JSON.stringify(updateMediaAssetInput));

      // initialize mutation configuration
      config = {
        mutation: UPDATE_MEDIA_ASSET_GRAPHQL_MUTATION,
        variables: {
          input: updateMediaAssetInput
        }
      };

      //
      promise = commitMutation(environment, config);

      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateMediaAsset.hasErrors === false
          ? Promise.resolve(mutationResults.updateMediaAsset.mediaAsset)
          : Promise.reject(mutationResults.updateMediaAsset.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteMediaAsset(
    mediaAssetInput: MediaAssetPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    return undefined;
  }
}
