import * as React from "react";
import AuthorAdd from "./author-add";
import AuthorView from "./author-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from"jquery";


/**
 *
 *
 * @interface AuthorPanelProps
 */
interface AuthorPanelProps {
  id?: number;
}

/**
 *
 *
 * @interface AuthorPanelState
 */
interface AuthorPanelState {
  authorList: any[];
  addStatus: boolean;
}

export default class AuthorPanel extends React.Component<
AuthorPanelProps,
AuthorPanelState
> {

  /**
   * Creates an instance of AuthorPanel.
   * @param {AuthorPanelProps} props
   * @memberof AuthorPanel
   */
  constructor(props: AuthorPanelProps) {
    super(props);

    let authorList : any = [];

    // feed the component list with provided props 
    // if (this.props.authorList)

    //... bind needed function to class

    this.resetComponent = this.resetComponent.bind(this);

    this.state = {
      addStatus: true,
      authorList
    };

  }


  /**
   * this is our method for adding an author into our state.
   */
   addAuthor = (author: any) => {
    let authorList = this.state.authorList;

    author.id = authorList.length + 1;

    authorList.push(author);

    this.setState(
      {
        authorList
      },
      () => {
        this.handleAddDiv();
      }
    );
  };


  /**
   * this is our method hide and show add author div
   */
   handleAddDiv = () => {
    if (this.state.addStatus === true) {
      $("#add-author-div").removeClass("add-author-hide");
      $("#add-author-div").addClass("add-author-show");

      this.setState({
        addStatus: false
      });
    } else {
      $("#add-author-div").removeClass("add-author-show");
      $("#add-author-div").addClass("add-author-hide ");
      this.setState({
        addStatus: true
      });
    }
  };
   deleteAuthor = (id: number) => {
    const authorList = this.state.authorList.filter(author => {
      return author.id !== id;
    });
    this.setState({
      authorList
    });
  };

  /**
   *
   *
   * @returns
   * @memberof AuthorPanel
   */
  render() {
    return (
      <div>
        {this.state.authorList.length ? (
          this.state.authorList.map((author, index) => {
            return (
              <AuthorView
                authorId={author.id}
                authorName={author.authorName}
                authorImageUrl={author.imageUrl}
                authorLink={author.link}
                deleteAuthor={this.deleteAuthor}
                key={index}
              />
            );
          })
        ) : (
          <label>
            Please use add 
          </label>
        )}

        <a
          className="add-btn"
          onClick={this.handleAddDiv}
        >
          <FontAwesomeIcon icon="plus-circle" />
        </a>
        <br />
        <div className="add-author-hide" id="add-author-div">
          <AuthorAdd addAuthor={this.addAuthor} />
        </div>
      </div>
    );
  }

  /**
   * 
   *
   * @memberof AuthorPanel
   */
  resetComponent() {
    this.setState({

      authorList: [],
      addStatus:false
    });
  }
}
