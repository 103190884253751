/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-09-10
 * @description Implementation of the confirm-password-modal react component
 * @filename confirm-password-reset-modal.tsx
 */

import React from "react";
import { RdvButton } from "../../page-framework/rdv-button";
import { Loader } from "../../page/loader";

/**
 * The props of the  ConfirmPasswordResetModal component.
 *
 * @interface
 */
interface ConfirmPasswordResetModalProps {
  /**
   * Changes the current modal.
   *
   * @type {(modal: any):void}
   */
  changeModal: (modal?: any) => void;

  /**
   * Closes the parent modal.
   *
   * @type {():void}
   */
  closeModal: () => void;

  /**
   * Returns a promise to complete the process of resetting the password for an account.
   *
   * @type {(email:string, confirmationCode:string, password:string):Promise<CognitoIdentityServiceProvider.ConfirmForgotPasswordResponse>} The promise to complete the process of
   * resetting the password for an account.
   */
  confirmForgotPassword: (
    email: string,
    confirmationCode: string,
    password: string
  ) => Promise<CognitoIdentityServiceProvider.ConfirmForgotPasswordResponse>;

  /**
   * Displays the password reset confirmed modal.
   *
   * @type {():void}
   */
  displayPasswordResetConfirmationModal: () => void;

  /**
   * An existing email.
   *
   * @type {string}
   */
  email?: string;

  /**
   * The ID of the ConfirmPasswordModal class
   *
   * @type {string}
   */
  id: string;

  /**
   * Navigates to the technical support pages and closes the modal.
   *
   * @type {():void}
   */
  navigateToTechnicalSupport: () => void;

  /**
   * A function that sets the title of the modal.
   *
   * @type {function(email: string):Promise<any>}
   */
  setTitle: (title?: string) => void;

  /**
   * Returns a flag which identifies if a password is valid.
   *
   * @param {string} password The password to validate.
   *
   * @returns {boolean} A flag which is true if the password is valid.
   */
  validatePassword: (password: string) => boolean;
}

/**
 * The state of the  ConfirmPasswordResetModal component.
 *
 * @interface
 */
interface ConfirmPasswordResetModalState {
  /**
   * The confirmation code.
   *
   * @type {string}
   */
  confirmationCode: string;

  /**
   * The email address of the user
   *
   * @type {string}
   */
  email: string;

  /**
   * A current error.
   *
   * @type {AWS.AWSError}
   */
  error: {
    code: string;
    message: string;
  };

  /**
   * A flag which is true if there is an error.
   *
   * @type {boolean}
   */
  hasError: boolean;

  /**
   * fire the progress overlay when submit button is clicked
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The new password.
   *
   * @type {boolean}
   */
  password: string;

  /**
   * A flag which is true if the password is valid.
   *
   * @type {boolean}
   */
  passwordIsValid: boolean;

  /**
   * A flag which is true if the password matches the re-entered password.
   *
   * @type {boolean}
   */
  passwordMatch: boolean;

  /**
   * The re-entered password.
   *
   * @type {string}
   */
  reenteredPassword: string;

  /**
   * The type of the password field which is either "text" or "password"
   *
   * @type {("password"|"text")}
   */
  type: "password" | "text";
}

/**
 * A React component that renders the confirm password reset modal.
 *
 * @extends {React.Component<ConfirmPasswordModalProps, ConfirmPasswordModalState>}
 */
export class ConfirmPasswordResetModal extends React.Component<
  ConfirmPasswordResetModalProps,
  ConfirmPasswordResetModalState
> {
  /**
   * Constructor
   *
   * @param { ConfirmPasswordResetModalProps} props The props of the component.
   */
  constructor(props: ConfirmPasswordResetModalProps) {
    super(props);
    this.state = {
      confirmationCode: "",
      email: this.props.email,
      error: null,
      hasError: false,
      loading: false,
      password: "",
      passwordIsValid: true,
      passwordMatch: true,
      reenteredPassword: "",
      type: "password",
    };
    this.navigateToTechnicalSupport = this.navigateToTechnicalSupport.bind(
      this
    );
    this.onCodeChange = this.onCodeChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onReenterPasswordChange = this.onReenterPasswordChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  /**
   * Invoked by the React framework after the component output has been rendered to the DOM
   */
  componentDidMount() {
    this.props.setTitle("Confirm Your Code");
  }
  /**
   * Navigates to the technical support page and closes the modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  navigateToTechnicalSupport(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.props.navigateToTechnicalSupport();
  }

  /**
   * Sets the state with the new verification code values.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e The event.
   * @memberof AccountVerification
   */
  onCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({ confirmationCode: (e.target as any)["value"] });
  }

  /**
   * Changes the state's email property as user input changes.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The change event on the password input field.
   */
  onPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({
      password: event.target.value,
      passwordIsValid: this.props.validatePassword(event.target.value),
      passwordMatch:
        event.target.value.length === 0 ||
        event.target.value === this.state.reenteredPassword,
    });
  }

  /**
   * Invoked when the re-entered password changes.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The event supplied when the re-entered password changes.
   */
  onReenterPasswordChange(event: React.ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    this.setState({
      reenteredPassword: event.target.value,
      passwordMatch:
        this.state.password.length === 0 ||
        event.target.value === this.state.password,
    });
  }

  /**
   * Invoked when the submit button is clicked.
   *
   * @param {React.FormEvent<HTMLFormElement>} e The click event
   */
  onSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    this.setState({ loading: true });
    this.props
      .confirmForgotPassword(
        this.state.email,
        this.state.confirmationCode,
        this.state.password
      )
      .then(
        (
          result: CognitoIdentityServiceProvider.ConfirmForgotPasswordResponse
        ): void => {
          this.props.displayPasswordResetConfirmationModal();
          this.setState({ loading: false });
        }
      )
      .catch((e: {
        code: string;
        message: string;
      }): void => {
        this.setState({
          error: e,
          hasError: true,
          loading: false,
        });
      });
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return (
      <div>
        <Loader isActive={this.state.loading} />
        <div id="confirmPasswordReset">
          <h1>Reset the Password</h1>
          <h5 className="text-center">
            Please check your email for the code to verify your password
          </h5>
          <h5 className="text-center">
            reset request and enter the code and new password below.
          </h5>

          <form id="password-reset-form" onSubmit={this.onSubmit}>
            <label className="form-group has-float-label">
              <input
                className="form-control"
                placeholder="Account Verification Code"
                onChange={this.onCodeChange}
                required={true}
              />
              <span>
                Verification Code
                <span className="required-star">*</span>
              </span>
            </label>
            <label className="form-group has-float-label">
              <input
                type={this.state.type}
                className={`form-control ${
                  this.state.passwordIsValid ? "" : "is-invalid"
                }`}
                id="createAccountPWD"
                placeholder="8+ characters: upper, lower, number, & special"
                onChange={this.onPasswordChange}
                required={true}
              />
              <span>
                New Password
                <span className="required-star">*</span>
              </span>
              {this.state.passwordIsValid ? null : (
                <div className="invalid-feedback">
                  8+ characters: upper, lower, number, & special
                </div>
              )}{" "}
            </label>
            <label className="form-group has-float-label">
              <input
                type={this.state.type}
                className={`form-control ${
                  this.state.passwordMatch ? "" : "is-invalid"
                }`}
                id="createAccountRe-enterPWD"
                placeholder="Password"
                onChange={this.onReenterPasswordChange}
                required={true}
              />
              <span id="createAccountRe-enterPasswordLabel">
                Confirm New Password
                <span className="required-star">*</span>
              </span>
              {this.state.passwordMatch ? null : (
                <div className="invalid-feedback">
                  Does not match the password.
                </div>
              )}{" "}
            </label>
            {this.state.hasError ? this.renderErrorMessage() : null}
            <div className="form-row align-items-center text-center">
              <RdvButton
                type="submit"
                form="password-reset-form"
                text="reset"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  /**
   * Returns an element containing an error message determined by the current error.
   *
   * @returns {JSX.Element} The element containing the details of the error.
   */
  renderErrorMessage(): JSX.Element {
    let element: JSX.Element;

    if (this.state.hasError) {
      switch (this.state.error.code) {
        case "InvalidParameterException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The email address supplied is invalid. Please{" "}
                <a href="#" onClick={this.navigateToTechnicalSupport}>
                  contact Radivision's technical support
                </a>{" "}
                or retry your request with a valid email address.
              </h6>
            </div>
          );
          break;
        default:
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                An unexpected error has occurred. Please{" "}
                <a href="#" onClick={this.navigateToTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
      }
    }
    return element;
  }
}
