/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to add an book story.
 * @filename create-book-story
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an book.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_BOOK_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createBookStoryMutation($input: CreateBookStoryInput!) {
    createBookStory(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      story {
        ... on BookStory {
          id
          link
          publisher
          publishedDate
          cost
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                  }
                }
              }
            }
          }
          revision
          subTitle
          title
          description
        }
      }
    }
  }
`;
