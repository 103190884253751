/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-17
 * @description Implementation of person utilities to be used in the app.
 * @filename person.ts
 */

import {
  CreatePersonInput,
  CreateUpdateGeographicalLocationInput,
  SocialMediaPlatformKind,
  UpdatePersonInput,
} from "@radivision/graphql";
import { CreateUpdateSocialMediaCredentialInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-update-social-media-credential-input";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { PersonPageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_PERSON_GRAPHQL_MUTATION } from "../../relay/mutations/create-person";
import { DELETE_PERSON_GRAPHQL_MUTATION } from "../../relay/mutations/delete-person";
import { UPDATE_PERSON_GRAPHQL_MUTATION } from "../../relay/mutations/update-person";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { CreateUpdateSocialMediaProfileInput } from "../../__generated__/addToEntityMutation.graphql";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

export class Person {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ArticlePageState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addPerson(
    personInput: PersonPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePersonInput
    let createPersonInput: CreatePersonInput;
    const SOCIAL_MEDIA_PROFILE_INPUT: {
      cred: CreateUpdateSocialMediaCredentialInput[];
      profile: any[];
    } = Person.handleSocialMediaCredentialsInput(personInput);
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    let locationInput: CreateUpdateGeographicalLocationInput[];

    if (
      personInput.location !== undefined &&
      personInput.location !== null &&
      personInput.location.length > 0
    ) {
      locationInput = [];
      locationInput.push({ address: personInput.location });
    }

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[addPerson] provided input ", JSON.stringify(personInput));

    if (
      personInput.fullName === undefined ||
      personInput.fullName === null ||
      personInput.fullName.length < 1
    ) {
      promise = Promise.reject(new Error("3O3TnhSRTEGkqYICR5JwOA"));
    } else {
      // initialize create person graphQl input
      createPersonInput = {
        clientMutationId: getClientMutationId(),
        fullName: personInput.fullName.trim(),
        previews: personInput.previews,
        //  contentModerationRole: personInput.contentModerationRole,
        socialMediaCredentials:
          SOCIAL_MEDIA_PROFILE_INPUT &&
          SOCIAL_MEDIA_PROFILE_INPUT.cred &&
          SOCIAL_MEDIA_PROFILE_INPUT.cred.length
            ? SOCIAL_MEDIA_PROFILE_INPUT.cred
            : undefined,
        socialMediaProfiles:
          SOCIAL_MEDIA_PROFILE_INPUT &&
          SOCIAL_MEDIA_PROFILE_INPUT.profile &&
          SOCIAL_MEDIA_PROFILE_INPUT.profile.length
            ? SOCIAL_MEDIA_PROFILE_INPUT.profile
            : undefined,
        responsibility: personInput.responsibility.trim(),
        locations: locationInput,
        tags:
          personInput.tags !== undefined &&
          personInput.tags !== null &&
          personInput.tags.length > 0
            ? personInput.tags
            : undefined,
        mediaAbout:
          personInput.mediaAbout && personInput.mediaAbout.length
            ? personInput.mediaAbout
            : undefined,

        entities:
          personInput.entities && personInput.entities.length
            ? personInput.entities
            : undefined,
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_PERSON_GRAPHQL_MUTATION,
        variables: {
          input: createPersonInput,
        },
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createPerson.hasErrors === false
          ? Promise.resolve(mutationResults.createPerson.person)
          : Promise.reject(mutationResults.createPerson.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static updatePerson(
    personInput: PersonPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePersonInput
    let updatePersonInput: UpdatePersonInput;
    const SOCIAL_MEDIA_PROFILE_INPUT: any = Person.handleSocialMediaCredentialsInput(
      personInput
    );
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let locationInput: CreateUpdateGeographicalLocationInput[];

    if (
      personInput.location !== undefined &&
      personInput.location !== null &&
      personInput.location.length > 0
    ) {
      locationInput = [];
      locationInput.push({ address: personInput.location });
    }

    // validate required parameters in schema and then
    // add only provided optional parameters.
    // console.log("[updatePerson] provided input ", JSON.stringify(personInput));
    if (
      personInput.id === undefined ||
      personInput.id === null ||
      personInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("iqqQZ3z10EyyOgfv50BExg"));
    } else {
      // initialize create person graphQl input
      updatePersonInput = {
        clientMutationId: getClientMutationId(),
        id: personInput.id,
        force: true,
        revision: personInput.revision,
        fullName: personInput.fullName.trim(),
        responsibility: personInput.responsibility.trim(),
        locations: locationInput,
        previews: personInput.previews,
        //  contentModerationRole: personInput.contentModerationRole,
        socialMediaCredentials:
          SOCIAL_MEDIA_PROFILE_INPUT &&
          SOCIAL_MEDIA_PROFILE_INPUT.cred &&
          SOCIAL_MEDIA_PROFILE_INPUT.cred.length
            ? SOCIAL_MEDIA_PROFILE_INPUT.cred
            : undefined,
        socialMediaProfiles:
          SOCIAL_MEDIA_PROFILE_INPUT &&
          SOCIAL_MEDIA_PROFILE_INPUT.profile &&
          SOCIAL_MEDIA_PROFILE_INPUT.profile.length
            ? SOCIAL_MEDIA_PROFILE_INPUT.profile
            : undefined,
        tags:
          personInput.tags !== undefined &&
          personInput.tags !== null &&
          personInput.tags.length > 0
            ? personInput.tags
            : undefined,
        mediaAbout:
          personInput.mediaAbout && personInput.mediaAbout.length
            ? personInput.mediaAbout
            : undefined,
        entities:
          personInput.entities && personInput.entities.length
            ? personInput.entities
            : undefined,
      };

      // initialize mutation configuration
      config = {
        mutation: UPDATE_PERSON_GRAPHQL_MUTATION,
        variables: {
          input: updatePersonInput,
        },
      };

      // attempt to commit update person mutation
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.updatePerson.hasErrors === false
          ? Promise.resolve(mutationResults.updatePerson.person)
          : Promise.reject(mutationResults.updatePerson.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deletePerson(
    personInput: PersonPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (
      personInput === undefined ||
      personInput === null ||
      personInput.id === undefined
    ) {
      promise = Promise.reject(new Error("rT5GYiG4RECcoCERNRyNgw"));
    }

    const input = {
      id: personInput.id,
      revision: personInput.revision,
      clientMutationId: getClientMutationId(),
    };

    config = {
      mutation: DELETE_PERSON_GRAPHQL_MUTATION,
      variables: {
        input,
      },
    };

    //
    promise = commitMutation(environment, config);
    //
    promise = promise.then((mutationResults: any) => {
      return mutationResults.deletePerson.hasErrors === false
        ? Promise.resolve(mutationResults.deletePerson.person)
        : Promise.reject(mutationResults.deletePerson.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {PersonPageFormInput} personInput
   * @returns {CreateUpdateSocialMediaCredentialInput[]}
   * @memberof Person
   */
  static handleSocialMediaCredentialsInput(
    personInput: PersonPageFormInput
  ): any {
    let socialMediaCredentialsInput: CreateUpdateSocialMediaCredentialInput[];
    let socialMediaProfilesInput: CreateUpdateSocialMediaProfileInput[];
    if (
      personInput.socialMediaProfiles !== undefined &&
      personInput.socialMediaProfiles !== null
    ) {
      socialMediaCredentialsInput = [];
      socialMediaProfilesInput = [];

      if (
        personInput.socialMediaProfiles.FacebookID !== undefined &&
        personInput.socialMediaProfiles.FacebookID !== null &&
        personInput.socialMediaProfiles.FacebookID.length > 1
      ) {
        socialMediaCredentialsInput.push({
          platform: SocialMediaPlatformKind.FACEBOOK,
          credentials: personInput.socialMediaProfiles.FacebookID.trim(),
        });
        socialMediaProfilesInput.push({
          platform: SocialMediaPlatformKind.FACEBOOK,
          url: `${"https://www.facebook.com/"}${personInput.socialMediaProfiles.FacebookID.trim()}`,
        });
      }

      if (
        personInput.socialMediaProfiles.InstagramID !== undefined &&
        personInput.socialMediaProfiles.InstagramID !== null &&
        personInput.socialMediaProfiles.InstagramID.length > 1
      ) {
        socialMediaCredentialsInput.push({
          platform: SocialMediaPlatformKind.INSTAGRAM,
          credentials: personInput.socialMediaProfiles.InstagramID.trim(),
        });
        socialMediaProfilesInput.push({
          platform: SocialMediaPlatformKind.INSTAGRAM,
          url: `${"https://www.instagram.com/"}${personInput.socialMediaProfiles.InstagramID.trim()}`,
        });
      }

      if (
        personInput.socialMediaProfiles.LinkedInID !== undefined &&
        personInput.socialMediaProfiles.LinkedInID !== null &&
        personInput.socialMediaProfiles.LinkedInID.length > 1
      ) {
        socialMediaCredentialsInput.push({
          platform: SocialMediaPlatformKind.LINKED_IN,
          credentials: personInput.socialMediaProfiles.LinkedInID.trim(),
        });
        socialMediaProfilesInput.push({
          platform: SocialMediaPlatformKind.LINKED_IN,
          url: `${"https://www.linkedin.com/in/"}${personInput.socialMediaProfiles.LinkedInID.trim()}`,
        });
      }

      if (
        personInput.socialMediaProfiles.TwitterID !== undefined &&
        personInput.socialMediaProfiles.TwitterID !== null &&
        personInput.socialMediaProfiles.TwitterID.length > 1
      ) {
        socialMediaCredentialsInput.push({
          platform: SocialMediaPlatformKind.TWITTER,
          credentials: personInput.socialMediaProfiles.TwitterID.trim(),
        });
        socialMediaProfilesInput.push({
          platform: SocialMediaPlatformKind.TWITTER,
          url: `${"https://www.twitter.com/"}${personInput.socialMediaProfiles.TwitterID.trim()}`,
        });
      }
    }

    return {
      cred: socialMediaCredentialsInput,
      profile: socialMediaProfilesInput,
    };
  }
}
