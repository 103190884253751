/**
 * @author Ahmed Samer
 * @date   2020-06-30
 * @description Initial page component
 * @filename event-page.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import Dropdown, { ReactDropdownProps } from "react-dropdown";
import {
  Entity,
  Event as GraphqlEvent,
  GRAPHQL_TYPE_ENTITY,
  GRAPHQL_TYPE_EVENT,
  PreviewKind,
  TopLevelMediaKind,
} from "@radivision/graphql";

import { Event } from "../../../utilities/cms-operation/event";
import { Validator } from "../../../utilities/cms-operation/validator";
import ModalConductor from "./modal/modal-conductor";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import { graphql, QueryRenderer } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import {
  FormGroupInput,
  PreviewItem,
  EventPageInput,
} from "../../../component-configuration/cms-pages";
import "react-dropdown/style.css";
import ManageMediaPanel from "./media/manage-media-panel";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { ImageHelper } from "../../../utilities/image-helper";

/**
 *
 */
const SEARCH_FILTERS_ENTITIES: { field: string; values: string[] }[] = [
  {
    field: "label",
    values: [GRAPHQL_TYPE_ENTITY],
  },
];

/**
 *
 */
const STATUS_MENU = [
  "draft",
  "live",
  "started",
  "ended",
  "completed",
  "canceled",
];

/**
 *
 *
 * @export
 * @interface EventPageProps
 */
export interface EventPageProps {
  /**
   *
   *
   * @type {string}
   * @memberof EventPageProps
   */
  event?: { id?: string };

  /**
   *
   *
   * @type {BookStory}
   * @memberof EventPageProps
   */
  bookStory?: GraphqlEvent;
}

/**
 * The state of the book page component.
 * @export
 * @interface
 */
export interface EventPageState {
  /**
   *
   *
   * @type {*}
   * @memberof EventPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   */
  eventPageInput: EventPageInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof EventPageState
   */
  errorMessage: Map<string, string>;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof EventPageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof EventPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class EventPage
 * @extends {React.Component<EventPageProps, EventPageState>}
 */
export class EventPage extends React.Component<EventPageProps, EventPageState> {
  /**
   * References to the previews panel component in the EventPage
   *
   * @type {*}
   * @memberof EventPage
   */
  previewPanelRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof PersonPage
   */
  entityRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof PersonPage
   */
  peopleEntityRef: any;

  /**
   *Creates an instance of EventPage.
   * @param {EventPageProps} props
   * @memberof EventPage
   */
  constructor(props: EventPageProps) {
    super(props);

    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    };
    // ... bind needed function to class

    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.handleLinkChanges = this.handleLinkChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.handleDescriptionChanges = this.handleDescriptionChanges.bind(this);
    this.handleEventIdChanges = this.handleEventIdChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleStartTimeChanges = this.handleStartTimeChanges.bind(this);
    this.handleEndTimeChanges = this.handleEndTimeChanges.bind(this);

    // ... initialize required components references

    this.previewPanelRef = React.createRef();
    this.entityRef = React.createRef();
    this.peopleEntityRef = React.createRef();

    // ... initial component state
    modal = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState,
    };

    /// handle provided props in case of edit already added article
    this.state = this.handleComponentStateInitialize(this.props.bookStory);
  }

  /**
   *
   *
   * @param {BookStory} bookStoryProps
   * @returns {EventPageState}
   * @memberof EventPage
   */
  handleComponentStateInitialize(
    bookStoryProps: GraphqlEvent & any
  ): EventPageState {
    // ... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState,
    };

    let eventPageInput: EventPageInput = {
      id: undefined,
      label: "",
      link: "",
      title: "",
      subTitle: "",
      summary: "",
      isDeleted: false,
      eventId: "",
      description: "",
      eventState: STATUS_MENU[0],
    };

    const state: EventPageState = {
      modal,
      eventPageInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {},
    };

    if (bookStoryProps !== null && bookStoryProps !== undefined) {
      // validate provided label

      if (bookStoryProps.id) {
        state.eventPageInput.id = bookStoryProps.id;
      }

      if (bookStoryProps.status) {
        state.eventPageInput.eventState = bookStoryProps.status;
      }

      // validate provided link
      if (bookStoryProps.url) {
        state.eventPageInput.link = bookStoryProps.url;
      }

      if (bookStoryProps.name) {
        state.eventPageInput.title = bookStoryProps.name;
      }

      if (bookStoryProps.summary) {
        state.eventPageInput.subTitle = bookStoryProps.summary;
      }

      if (bookStoryProps.description) {
        state.eventPageInput.description = bookStoryProps.description;
      }

      if (bookStoryProps.isDeleted) {
        state.eventPageInput.isDeleted = bookStoryProps.isDeleted;
      }

      if (bookStoryProps.start) {
        state.eventPageInput.started = bookStoryProps.start;
      }

      if (bookStoryProps.end) {
        state.eventPageInput.ended = bookStoryProps.end;
      }

      if (bookStoryProps.revision) {
        state.eventPageInput.revision = bookStoryProps.revision;
      }

      if (bookStoryProps.mediaExternalId) {
        state.eventPageInput.eventId = bookStoryProps.mediaExternalId;
      }

      if (
        bookStoryProps.entities !== null &&
        bookStoryProps.entities !== undefined &&
        bookStoryProps.entities.length > 0
      ) {
        state.formGroupInput.entities = bookStoryProps?.entities?.map(
          (entity: Entity) => {
            let mediaAboutItem: CarouselItem = {
              id: entity.id,
              title: entity.name,
              previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: entity.previews,
                  previewKind: [
                    PreviewKind.CAROUSEL,
                    PreviewKind.SQUARE,
                    PreviewKind.LOGO,
                  ],
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  type: entity.__typename,
                },
                desiredDimensions: {
                  containerWidthRatio: 6 / 12,
                  numberOfItems: 6,
                },
                isSquareImage: true,
                revision: entity.revision,
              }),
              type: entity.__typename,
            };

            return mediaAboutItem;
          }
        );
      }

      if (
        bookStoryProps.peopleEntities !== null &&
        bookStoryProps.peopleEntities !== undefined &&
        bookStoryProps.peopleEntities.length > 0
      ) {
        state.formGroupInput.peopleEntities = bookStoryProps?.peopleEntities?.map(
          (item: any) => {
            let mediaAboutItem: CarouselItem = {
              id: item.entity.id,
              title: item.entity.name,
              previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: item.entity.previews,
                  previewKind: [
                    PreviewKind.CAROUSEL,
                    PreviewKind.SQUARE,
                    PreviewKind.LOGO,
                  ],
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  type: item.entity.__typename,
                },
                desiredDimensions: {
                  containerWidthRatio: 6 / 12,
                  numberOfItems: 6,
                },
                isSquareImage: true,
                revision: item.entity.revision,
              }),
              type: item.entity.__typename,
            };

            return mediaAboutItem;
          }
        );
      }

      // ............

      // initialize form group inputs
      if (
        bookStoryProps.tags !== null &&
        bookStoryProps.tags !== undefined &&
        bookStoryProps.tags.length > 0
      ) {
        const tagsList: {
          id: string;
          text: string;
        }[] = bookStoryProps.tags.map((tag) => {
          return { id: tag.id, text: tag.label };
        });
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        bookStoryProps.previews !== null &&
        bookStoryProps.previews !== undefined &&
        bookStoryProps.previews.count > 0
      ) {
        const previewList: PreviewItem[] = bookStoryProps.previews.edges.map(
          (preview: any, index: number) => {
            const item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset",
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link,
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`,
                  },
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }
    }

    return state;
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @returns
   * @memberof EventPage
   */
  render() {
    const dateNow = new Date();
    const currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Radivision Events Page</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.eventPageInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Event External Id</label>
                  <input
                    type="text"
                    name="eventId"
                    className={`form-control ${
                      this.state.errorMessage.get("EventId") ? "is-invalid" : ""
                    }`}
                    value={this.state.eventPageInput.eventId}
                    onChange={this.handleEventIdChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("EventId")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Events URL</label>
                  <input
                    type="text"
                    name="Link URL"
                    className={`form-control ${
                      this.state.errorMessage.get("LINK") ? "is-invalid" : ""
                    }`}
                    value={this.state.eventPageInput.link}
                    onChange={this.handleLinkChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("LINK")}
                  </span>
                </div>

                <div className="Page-Field">
                  <label>Event Status</label>
                  <Dropdown
                    options={STATUS_MENU}
                    onChange={this.handleSummaryChanges}
                    value={this.state.eventPageInput.eventState}
                    placeholder="Select an event status"
                  />
                </div>

                <div className="Page-Field">
                  <label>Summary</label>
                  <input
                    type="text"
                    name="Summary"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.eventPageInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>
            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Started</label>
                <input
                  id="party"
                  type="datetime"
                  name="published"
                  value={this.state.eventPageInput.started}
                  onChange={this.handleStartTimeChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>Ended</label>
                <input
                  id="party"
                  type="datetime"
                  name="Ended"
                  value={this.state.eventPageInput.ended}
                  onChange={this.handleEndTimeChanges}
                  className="form-control"
                />
              </div>
              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.eventPageInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Description</label>
                <input
                  type="text"
                  name="Description"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.eventPageInput.description}
                  onChange={this.handleDescriptionChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            {/* tag section */}
            <br />
            <div className="Page-Field">
              <label>
                Participating Companies
                <br />
              </label>
              <div className="Page--Badge">
                <ManageMediaPanel
                  ref={this.entityRef}
                  mediaItems={this.state?.formGroupInput?.entities}
                  filter={SEARCH_FILTERS_ENTITIES}
                />
              </div>
            </div>

            <br />
            <div className="Page-Field">
              <label>
                Brought You By Companies
                <br />
              </label>
              <div className="Page--Badge">
                <ManageMediaPanel
                  ref={this.peopleEntityRef}
                  mediaItems={this.state?.formGroupInput?.peopleEntities}
                  filter={SEARCH_FILTERS_ENTITIES}
                />
              </div>
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <ManagePreviewPanel
                ref={this.previewPanelRef}
                previewsList={this.state.formGroupInput.previewsProps}
              />
            </div>
          </div>

          <Loader isActive={this.state.isLoading} />

          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction,
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  const eventPageInput = this.state.eventPageInput;
                  eventPageInput.isDeleted = true;
                  this.setState({ eventPageInput });
                },
                unDeleteAction: () => {
                  const eventPageInput = this.state.eventPageInput;
                  eventPageInput.isDeleted = false;
                  this.setState({ eventPageInput });
                },
              },
              saveable: this.handleSaveAction,
            }}
          />
        </div>
      </div>
    );
  }

  /**
   * Function Responsible for handling changes in book link state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof EventPage
   */
  handleLinkChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    const eventPageInput = this.state.eventPageInput;
    const eventValue: string = changeEvent.currentTarget.value;
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();

    // // console\.log("[handleLinkChanges] provided value : ", eventValue);\
    // ... assign value if valid
    eventPageInput.link = eventValue;
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book title state
   *
   * @memberof EventPage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    const eventPageInput = this.state.eventPageInput;
    const eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    // ... validate against input errors
    // ... assign value if valid
    eventPageInput.title = eventValue;
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book sub-title state
   *
   * @memberof EventPage
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    const eventPageInput = this.state.eventPageInput;
    const eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    eventPageInput.subTitle = eventValue;
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof EventPage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<ReactDropdownProps>): void {
    // ... define need variables
    const eventPageInput = this.state.eventPageInput;
    const eventValue = changeEvent.value;
    // // console\.log("[handleArticleSummaryChanges] provided value : ", eventValue);
    //... validate against input errors
    // ... assign value if valid
    eventPageInput.eventState = eventValue as string;
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof EventPage
   */
  handleDescriptionChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    const eventPageInput = this.state.eventPageInput;
    const eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleArticleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    eventPageInput.description = eventValue;
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof EventPage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    const eventPageInput = this.state.eventPageInput;
    const eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleLabelChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    // ... validate against input errors
    // ... assign value if valid
    eventPageInput.label = eventValue;
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book is deleted state
   *
   * @memberof EventPage
   */
  handleIsDeletedChanges(event: any): void {
    let isDeleted: boolean = event.target.checked ? true : false;
    const eventPageInput = this.state.eventPageInput;

    eventPageInput.isDeleted = isDeleted;
    // // console\.log("[handleIsDeletedChanges] is book deleted", isDeleted);

    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book is new state
   *
   * @memberof EventPage
   */
  handleEventIdChanges(changeEvent: any): void {
    const eventValue: string = changeEvent.currentTarget.value;
    const eventPageInput = this.state.eventPageInput;

    eventPageInput.eventId = eventValue;
    // // console\.log("[handleIsNewChanges] is book new ", isNew);
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book Publish Date state
   *
   * @memberof BookPage
   */
  handleStartTimeChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    const eventPageInput = this.state.eventPageInput;
    const eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handlePublishDateChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    // ... validate against input errors
    // ... assign value if valid
    eventPageInput.started = eventValue;
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling changes in book Publish Date state
   *
   * @memberof BookPage
   */
  handleEndTimeChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    const eventPageInput = this.state.eventPageInput;
    const eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handlePublishDateChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    // ... validate against input errors
    // ... assign value if valid
    eventPageInput.ended = eventValue;
    this.setState({ eventPageInput });
  }

  /**
   * Function Responsible for handling submitting new article
   *
   * @memberof ArticlePage
   */
  handleSaveAction(): void {
    let bookPageState: EventPageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();

      // extract tags state
      bookPageState.eventPageInput.entities = this.entityRef.current.extractMediaItem();
      bookPageState.eventPageInput.peopleEntities = this.peopleEntityRef?.current
        ?.extractMediaItem()
        ?.map((item) => {
          return { entity: item };
        });

      // extract previews list
      bookPageState.eventPageInput.previews = this.previewPanelRef.current.extractPreviews();

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      if (errorMessage.size < 1) {
        // console\.log("[handleAddArticle] errorMessage ", errorMessage);

        // ... provide validated input to  utility
        if (
          this.props.bookStory !== null &&
          this.props.bookStory !== undefined
        ) {
          internalPromise = Event.updateEvent(
            bookPageState.eventPageInput
          ).then((results: any) => {
            // ... handle mutation success
            // console\.log(results);
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${CMS_PAGE_CONSTANT["EVENT"].message.confirmation.update}, event-id : ${results.id}`;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Event.addEvent(bookPageState.eventPageInput).then(
            (results: any) => {
              // ... handle mutation success
              // console\.log(results);
              modal.modalName = "ConfirmModal";
              modal.modalBody = `${CMS_PAGE_CONSTANT["EVENT"].message.confirmation.add}, Event-id : ${results.id}`;
              modal.modalTile = "Action Complete";
              this.revertPageInput();
            }
          );
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["EVENT"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }

      return internalPromise;
    });

    promise = promise.catch((err) => {
      console.error(err);

      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false,
      });
    });
  }

  /**
   *
   *
   * @memberof EventPage
   */
  revertPageInput(input?: GraphqlEvent): void {
    // ... reset component own state
    let state: EventPageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.bookStory
    );

    this.setState(state);

    // ... reset children state
    this.entityRef.current.resetComponent();
    this.peopleEntityRef.current.resetComponent();

    // this.tagsRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
  }
  /**
   *
   *
   * @memberof EventPage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof EventPage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class EventPageContainer extends React.Component<
  EventPageProps,
  EventPageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let bookId: any;

    console.log("[BookStoryPageContainer] provided props", this.props);

    if (this.props.event === null || this.props.event === undefined) {
      node = <EventPage />;
    } else {
      bookId = this.props.event;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query eventPageQuery($id: ID!) {
              event(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                event {
                  __typename
                  id
                  url
                  summary
                  start
                  end
                  name
                  mediaExternalId
                  description
                  created
                  revision
                  status
                  peopleEntities {
                    person {
                      id
                    }
                    entity {
                      landingPageUrl
                      id
                      name
                      revision
                      previews {
                        count
                        edges {
                          cursor
                          preview {
                            link
                            mediaAsset {
                              id
                              files {
                                id
                                name
                                path
                              }
                              topLevelMediaType {
                                kind
                                label
                              }
                            }
                            kind
                          }
                        }
                      }
                    }
                  }
                  entities {
                    __typename
                    name
                    id
                    revision
                    hasPublicPage
                    tagLine
                    description
                    websiteUrl
                    landingPageUrl
                    isEditable
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                              label
                            }
                          }
                          kind
                        }
                      }
                    }
                  }
                  previews {
                    count
                    edges {
                      cursor
                      preview {
                        link
                        kind
                        mediaAsset {
                          id
                          files {
                            id
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                            label
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          variables={{
            id: bookId,
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.event.hasErrors) {
                return (
                  <div>
                    <p>unexpected error contact Radivision Technical team.</p>{" "}
                  </div>
                );
              } else if (props.event.event.__typename !== GRAPHQL_TYPE_EVENT) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid event id, {bookId} belongs to type{" "}
                      {props.story.node.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.event.event) {
                let pageProps: EventPageProps = {
                  ...this.props,
                  bookStory: props.event.event,
                };
                return <EventPage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
