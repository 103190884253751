/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-18
 * @description Implementation of questionnaire utilities to be used in the app.
 * @filename questionnaire.ts
 */

import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { QuestionPageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_QUESTIONNAIRE_GRAPHQL_MUTATION } from "../../relay/mutations/create-questionnaire";
import { UPDATE_QUESTIONNAIRE_GRAPHQL_MUTATION } from "../../relay/mutations/update-questionnaire";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";
import { UpdateQuestionnaireInput } from "@radivision/graphql/lib/ts/graphql/mutations/update-questionnaire-input";
import { CreateQuestionInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-questionnaire-input";

/**
 *
 *
 * @export
 * @class Questionnaire
 */
export class Questionnaire {
  /**
   * Returns a promise to add new person to database.
   *
   * @param {ManageHtmlState} originalContentInput create new article input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new article mutation .
   */
  static addQuestionnaire(
    questionnaireInput: QuestionPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePersonInput
    let createQuestionnairePageInput: any;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (
      questionnaireInput.title === undefined ||
      questionnaireInput.title === null ||
      questionnaireInput.title.length < 1 ||
      (questionnaireInput.questions === undefined &&
        questionnaireInput.questions === null &&
        questionnaireInput.questions.length < 1)
    ) {
      promise = Promise.reject(new Error("hTl7lxnnXkSoB3G5IUKz6w"));
    } else {
      // initialize create person graphQl input
      createQuestionnairePageInput = {
        clientMutationId: getClientMutationId(),
        description:
          questionnaireInput.description !== undefined &&
          questionnaireInput.description !== null &&
          questionnaireInput.description.length > 0
            ? questionnaireInput.description.trim()
            : undefined,
        previews: questionnaireInput.previews,
        subTitle:
          questionnaireInput.subTitle !== undefined &&
          questionnaireInput.subTitle !== null &&
          questionnaireInput.subTitle.length > 0
            ? questionnaireInput.subTitle.trim()
            : undefined,
        title: questionnaireInput.title.trim(),
        questions: Questionnaire.filterQuestionInput(
          questionnaireInput.questions
        ),
        tags:
          questionnaireInput.tags !== undefined &&
          questionnaireInput.tags !== null &&
          questionnaireInput.tags.length > 0
            ? questionnaireInput.tags
            : undefined
      };

      // initialize mutation configuration
      config = {
        mutation: CREATE_QUESTIONNAIRE_GRAPHQL_MUTATION,
        variables: {
          input: createQuestionnairePageInput
        }
      };

      //
      promise = commitMutation(environment, config);

      promise = promise.then((mutationResults: any) => {
        return mutationResults.createQuestionnaire.hasErrors === false
          ? Promise.resolve(mutationResults.createQuestionnaire.questionnaire)
          : Promise.reject(mutationResults.createQuestionnaire.errors);
      });
    }
    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof HtmlPage
   */
  static updateQuestionnaire(
    questionnaireInput: QuestionPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert article input to  CreatePersonInput
    let updateQuestionnairePageInput: UpdateQuestionnaireInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (
      questionnaireInput.id === undefined &&
      questionnaireInput.id === null &&
      questionnaireInput.id.length < 1
    ) {
      promise = Promise.reject(new Error("87jYxtNqNU2gvDR1gUrDpA"));
    }

    updateQuestionnairePageInput = {
      id: questionnaireInput.id,
      revision: questionnaireInput.revision,
      clientMutationId: getClientMutationId(),
      description:
        questionnaireInput.description !== undefined &&
        questionnaireInput.description !== null &&
        questionnaireInput.description.length > 0
          ? questionnaireInput.description.trim()
          : undefined,
      previews: questionnaireInput.previews,
      subTitle:
        questionnaireInput.subTitle !== undefined &&
        questionnaireInput.subTitle !== null &&
        questionnaireInput.subTitle.length > 0
          ? questionnaireInput.subTitle.trim()
          : undefined,
      title: questionnaireInput.title.trim(),
      questions: Questionnaire.filterQuestionInput(
        questionnaireInput.questions
      ),

      tags:
        questionnaireInput.tags !== undefined &&
        questionnaireInput.tags !== null &&
        questionnaireInput.tags.length > 0
          ? questionnaireInput.tags
          : undefined
    };

    // initialize mutation configuration
    config = {
      mutation: UPDATE_QUESTIONNAIRE_GRAPHQL_MUTATION,
      variables: {
        input: updateQuestionnairePageInput
      }
    };

    //
    promise = commitMutation(environment, config);

    promise = promise.then((mutationResults: any) => {
      return mutationResults.updateQuestionnaire.hasErrors === false
        ? Promise.resolve(mutationResults.updateQuestionnaire.questionnaire)
        : Promise.reject(mutationResults.updateQuestionnaire.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof HtmlPage
   */
  static deleteQuestionnaire(): Promise<any> {
    return undefined;
  }

  /**
   *
   *
   * @private
   * @static
   * @param {CreateQuestionnaireInput[]} questions
   * @memberof Questionnaire
   */
  static filterQuestionInput(
    questions: CreateQuestionInput[]
  ): CreateQuestionInput[] {
    let filtered: CreateQuestionInput[];

    filtered = questions.filter(question => {
      return question !== null && question !== undefined && question.title;
    });

    return filtered;
  }
}
