/**
 * @author Muhammad Omran
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2019-02-04 01:00 p.m
 * @description An Implentation for a React Componenet to fallback if The Application ran into errors
 * @filename error-boundary.tsx
 */
import React from "react";
const LOGO = require("../static/rv_logo.png");
/**
 * The state of the ErrorBoundary Component
 *
 * @interface
 */
interface ErrorBoundaryState {
  /**
   * The state of the Application
   *
   * @type {boolean}
   */
  hasError: boolean;
  /**
   * The URL of the tag-line.
   *
   * @type {string}
   */
  taglineUrl: string;
}

/**
 * A React component that renders the Share Button.
 *
 * @export
 * @class ErrorBoundary
 * @extends {React.Component<any,ErrorBoundaryState>}
 */
export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      taglineUrl: `${process.env.URL_MEDIA_IMAGE}/0a/us/0aUSTIfTQZu456apuXFTbw.svg`
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // render any custom fallback UI
      return (
        <div className="error-msg">
          <div className="rdv-logo">
            <a className="navbar-brand nav-logo" href="#">
              <img src={LOGO} alt="logo" className="logo d-inline-block" />
              <img
                src={this.state.taglineUrl}
                alt="Radivision tagline"
                className="tagline d-none d-inline-block"
              />
            </a>
          </div>
          <div className="error-msg-content">
            <div className="dashed-shadow dashed-shadow-text">
              <span className="shadow">Oops!</span>
            </div>
            <p>
              Something went wrong!!
              <span>
                <a href="/#support:contact-us" target="_blank">
                  Contact technical support
                </a>
              </span>
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
