/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ClaimRequestResponseKind = "ACCEPT" | "DECLINE" | "%future added value";
export type AcceptDeclineClaimRequestInput = {
    readonly clientMutationId: string;
    readonly id?: string | null;
    readonly response: ClaimRequestResponseKind;
};
export type respondToClaimRequestMutationVariables = {
    readonly input: AcceptDeclineClaimRequestInput;
};
export type respondToClaimRequestMutationResponse = {
    readonly respondToClaimRequest: {
        readonly clientMutationId: string | null;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly userRequest: {
            readonly __typename: string;
        } | null;
    } | null;
};
export type respondToClaimRequestMutation = {
    readonly response: respondToClaimRequestMutationResponse;
    readonly variables: respondToClaimRequestMutationVariables;
};



/*
mutation respondToClaimRequestMutation(
  $input: AcceptDeclineClaimRequestInput!
) {
  respondToClaimRequest(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    userRequest {
      __typename
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AcceptDeclineClaimRequestInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "respondToClaimRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "respondToClaimRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUpdateUserRequestPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userRequest",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "respondToClaimRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "respondToClaimRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUpdateUserRequestPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userRequest",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "respondToClaimRequestMutation",
    "id": null,
    "text": "mutation respondToClaimRequestMutation(\n  $input: AcceptDeclineClaimRequestInput!\n) {\n  respondToClaimRequest(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    userRequest {\n      __typename\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b3fdc0502c05681bbf5de6d9b2e1cbd9';
export default node;
