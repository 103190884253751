/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Query paramter page component
 * @filename route-sub-properties.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CreateQueryParameterRuleInput,
  QueryParameterRule
} from "@radivision/graphql";

/**
 *
 *
 * @interface RouteSubPropertyProps
 */
interface RouteSubPropertyProps {
  /**
   *
   *
   * @type {*}
   * @memberof RouteSubPropertyProps
   */
  queryParamter?: QueryParameterRule;

  /**
   *
   *
   * @type {number}
   * @memberof RouteSubPropertyProps
   */
  subPropertyId: string;

  /**
   *
   *
   * @param {number} id
   * @returns {*}
   * @memberof RouteSubPropertyProps
   */
  removeSubClick(id: any): any;
}

/**
 *
 *
 * @interface RouteSubPropertyState
 */
interface RouteSubPropertyState {
  /**
   *
   *
   * @type {number}
   * @memberof RouteSubPropertyProps
   */
  input: CreateQueryParameterRuleInput;
}

/**
 *
 *
 * @export
 * @class RouteSubProperties
 * @extends {React.Component<RouteSubPropertyProps>}
 */
export default class RouteSubProperties extends React.Component<
  RouteSubPropertyProps,
  RouteSubPropertyState
> {
  /**
   * Creates an instance of RouteSubProperties.
   * @param {RouteSubPropertyProps} props
   * @memberof RouteSubProperties
   */
  constructor(props: RouteSubPropertyProps) {
    super(props);

    //... bind needed function to class
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);

    let input: CreateQueryParameterRuleInput = {};

    if (
      this.props.queryParamter !== null &&
      this.props.queryParamter !== undefined
    ) {
      if (this.props.queryParamter.name) {
        input.name = this.props.queryParamter.name;
      }

      if (this.props.queryParamter.values) {
        input.values = this.props.queryParamter.values;
      }
    }

    this.state = { input };
  }

  /**
   * Function Responsible for handling changes in urlRoute label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof posterPage
   */
  handleNameChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let input: CreateQueryParameterRuleInput = this.state.input;
    let eventValue: string = changeEvent.currentTarget.value;

    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    input.name = eventValue;
    this.setState({ input });
  }

  /**
   * Function Responsible for handling changes in urlRoute label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof posterPage
   */
  handleValueChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let input: CreateQueryParameterRuleInput = this.state.input;
    let eventValue: string = changeEvent.currentTarget.value;

    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    input.values = eventValue.split("*");
    this.setState({ input });
  }

  /**
   *
   *
   * @returns
   * @memberof RouteSubProperties
   */
  render() {
    return (
      <div style={{ border: "1px solid" }}>
        <button
          className="btn add-btn"
          onClick={() => {
            this.props.removeSubClick(this.props.subPropertyId);
          }}
        >
          <FontAwesomeIcon icon="times-circle" /> close
        </button>
        <div className="row">
          <div className="input-field col m3">
            <label>
              Query Parameter <span style={{ color: "red" }}>*</span>
            </label>
            <input
              id="query_param"
              type="text"
              value={this.state.input.name}
              className="validate form-control"
              onChange={this.handleNameChange}
            />
          </div>
          <div className="input-field col m3">
            <label>Query Parameter Value</label>
            <input
              id="query_param_value"
              type="text"
              value={this.state.input.values}
              className="validate form-control"
              onChange={this.handleValueChange}
            />
          </div>

          <div className="input-field col m3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                value="any"
                className="custom-control-input"
                onChange={null}
              />
              <label className="custom-control-label">
                <span>Any</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @memberof RouteProperty
   */
  extractQueryParameter(): CreateQueryParameterRuleInput {
    let createUpdateUrl: CreateQueryParameterRuleInput = this.state.input;

    // extract query parameter
    return createUpdateUrl;
  }
}
