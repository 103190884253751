/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-07-08 03:10
 * @description Implementation of relay utilities to be used in the app.
 * @filename relay.ts
 */

import { commitMutation as _commitMutation } from "react-relay";
import { Environment, MutationConfig, OperationDefaults, PayloadError } from "relay-runtime";

/**
 * Returns a promise to commit a given mutation.
 *
 * @param {Environment} environment The relay environment.
 *
 * @param {MutationConfig<OperationDefaults>} config The relay mutation configuration.
 *
 * @returns {Promise<any>} The promise to commit a given mutation.
 */
export function commitMutation(
  environment: Environment,
  config: MutationConfig<OperationDefaults>
): Promise<any> {
  return new Promise(
    (resolve: (response: any) => void, reject: (e: any) => void): void => {
      config.onCompleted = (
        response: any,
        errors: PayloadError[] = null
      ): void => {
        if (errors !== undefined && errors !== null) {
          reject(errors);
        } else {
          for (const KEY in response) {
            if (response[KEY] === undefined || response[KEY] === null) {
              // TODO: needs to be handled
              reject(new Error("unexpected error or un-handle resolver!"));
            } else if (response[KEY].hasErrors) {
              if (
                response[KEY].errors !== undefined &&
                response[KEY].errors !== null
              ) {
                reject(response[KEY].errors);
              }
            }
          }
          resolve(response);
        }
      };
      config.onError = (e: any = null): void => {
        reject(e);
      };
      _commitMutation(environment, config);
    }
  );
}
