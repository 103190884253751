/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-19
 * @description Implementation of the GraphQL query to retrieve the crawl data.
 * @filename crawl-query.tsx
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the current user.
 *
 * @type {GraphQLTaggedNode}
 */
export const CRAWL_QUERY: GraphQLTaggedNode = graphql`
  query crawlQuery($url: String!, $apiObjectKind: CrawlKind) {
    crawl(url: $url, kind: $apiObjectKind) {
      hasErrors
      node {
        ... on ArticleStory {
          description
          link
          subTitle
          title
          publishedDate
          previews {
            edges {
              preview {
                link
              }
            }
          }
        }
        ... on BookStory {
          description
          link
          subTitle
          publishedDate
          title
          previews {
            edges {
              preview {
                link
              }
            }
          }
        }
        ... on Manageable {
          ... on Entity {
            name
            description
            websiteUrl
            landingPageUrl
            previews {
              edges {
                preview {
                  link
                }
              }
            }
          }
        }
        ... on NewsletterStory {
          description
          link
          subTitle
          title
          previews {
            edges {
              preview {
                link
              }
            }
          }
        }
        ... on OnlineCourseStory {
          description
          link
          subTitle
          title
          previews {
            edges {
              preview {
                link
              }
            }
          }
        }
        ... on PodcastStory {
          description
          link
          subTitle
          durationInSeconds
          publishedDate
          title
          previews {
            edges {
              preview {
                link
              }
            }
          }
        }
      }
    }
  }
`;
