/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type manageComponentQueryVariables = {
    readonly id: string;
};
export type manageComponentQueryResponse = {
    readonly component: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly component: {
            readonly __typename: string;
            readonly key: string | null;
            readonly description: string | null;
            readonly id: string;
            readonly revision: string | null;
        } | null;
    } | null;
};
export type manageComponentQuery = {
    readonly response: manageComponentQueryResponse;
    readonly variables: manageComponentQueryVariables;
};



/*
query manageComponentQuery(
  $id: ID!
) {
  component(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    component {
      __typename
      key
      description
      id
      revision
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "component",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ComponentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "component",
        "storageKey": null,
        "args": null,
        "concreteType": "Component",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "manageComponentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "manageComponentQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "manageComponentQuery",
    "id": null,
    "text": "query manageComponentQuery(\n  $id: ID!\n) {\n  component(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    component {\n      __typename\n      key\n      description\n      id\n      revision\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3965a5b428d5075a1271dfbe8ba27068';
export default node;
