import * as React from "react";
import { ManagePreviewPanel } from "../../manage-preview/manage-preview-panel";
import {
  CreateUpdatePreviewInput,
  CreateQuestionInput,
  QuestionKind,
  TextQuestion as graphqlTextQuestion,
  PreviewKind
} from "@radivision/graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PreviewItem } from "../../../../../component-configuration/cms-pages";

/**
 *
 *
 * @interface TextQuestionProps
 */
interface TextQuestionProps {
  /**
   *
   *
   * @type {TextQuestion}
   * @memberof TextQuestionProps
   */
  input?: graphqlTextQuestion;

  /**
   *
   *
   * @type {number}
   * @memberof TextQuestionProps
   */
  qId: string;
  /**
   *
   *
   * @memberof TextQuestionProps
   */
  handleChange?: void;
  /**
   *
   *
   * @param {number} id
   * @memberof TextQuestionProps
   */
  removeClick(id: string): void;
}

/**
 *
 *
 * @interface TextQuestionState
 */
interface TextQuestionState {
  /**
   *
   *
   * @type {string}
   * @memberof TextQuestionState
   */
  title: string;

  /**
   *
   *
   * @type {string}
   * @memberof TextQuestionState
   */
  subTitle: string;

  /**
   *
   *
   * @type {string}
   * @memberof TextQuestionState
   */
  content: string;


  /**
   *
   *
   * @type {({
    *     id?: number;
    *     previewKind?: PreviewKind;
    *     previewSource?: "link" | "mediaAsset";
    *     previewDetails?: string;
    *   }[])}
    * @memberof MultipleQuestionState
    */
   previewList?: PreviewItem[];

  /**
   *
   *
   * @type {[]}
   * @memberof ArticlePageState
   */
  previews?: CreateUpdatePreviewInput[];
}

export default class TextQuestion extends React.Component<
  TextQuestionProps,
  TextQuestionState
> {
  /**
   *
   *
   * @type {*}
   * @memberof QuestionPanel
   */
  previewRef: any;

  /**
   *Creates an instance of TextQuestion.
   * @param {TextQuestionProps} props
   * @memberof TextQuestion
   */
  constructor(props: TextQuestionProps) {
    super(props);

    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSubTitleChange = this.handleSubTitleChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.extractQuestion = this.extractQuestion.bind(this);

    this.previewRef = React.createRef();

    this.state = this.handleComponentInitialize(this.props.input);
  }

  /**
   *
   *
   * @param {graphqlTextQuestion} input
   * @returns {TextQuestionState}
   * @memberof TextQuestion
   */
  handleComponentInitialize(input: graphqlTextQuestion): TextQuestionState {
    let state: TextQuestionState = {
      title: "",
      subTitle: "",
      content: "",
      previewList:[]
    };

    if (input !== null && input !== undefined) {
      if (input.content) {
        state.content = input.content;
      }

      if (input.title) {
        state.title = input.title;
      }

      if (input.subTitle) {
        state.subTitle = input.subTitle;
      }



     if (
        input.previews !== null &&
        input.previews !== undefined &&
        input.previews.count > 0
      ) {
        state.previewList = input.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset"
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${
                      preview.preview.mediaAsset.files[0].name
                    }`
                  }
                };
                break;

              default:
                break;
            }
            
            return item;
          }
        );
      }
      
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof TextQuestion
   */
  render() {
    let { qId, handleChange, removeClick } = this.props;
    return (
      <div>
        <label>Text Question</label>
        <a
          className="btn add-btn"
          href="#"
          onClick={e => {
            e.preventDefault();
            removeClick(qId);
          }}
        >
          <FontAwesomeIcon icon="times-circle" />
        </a>

        <div className="input-field">
          <label>Title</label>

          <input
            placeholder="your title"
            id="title"
            type="text"
            className="validate form-control"
            value={this.state.title}
            onChange={this.handleTitleChange}
          />
        </div>
        <div className="input-field">
          <label>Sub title</label>

          <input
            placeholder="your subtitle"
            id="subtitle"
            type="text"
            className="validate form-control"
            value={this.state.subTitle}
            onChange={this.handleSubTitleChange}
          />
        </div>
        <div className="input-field">
          <label>Content</label>

          <textarea
            id="content"
            className="validate form-control"
            value={this.state.content}
            onChange={this.handleContentChange}
          />
        </div>

        {/* preview section */}
        <div className="Page--Preview">
          <ManagePreviewPanel 
          previewsList={this.state.previewList}
          ref={this.previewRef} />
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof TextQuestion
   */
  handleTitleChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();

    this.setState({
      title: changeEvent.currentTarget.value
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof TextQuestion
   */
  handleSubTitleChange(changeEvent: React.FormEvent<HTMLInputElement>): void {
    changeEvent.preventDefault();

    this.setState({
      subTitle: changeEvent.currentTarget.value
    });
  }

  /**
   *
   *
   * @param {React.FormEvent<HTMLTextAreaElement>} changeEvent
   * @memberof TextQuestion
   */
  handleContentChange(changeEvent: React.FormEvent<HTMLTextAreaElement>): void {
    changeEvent.preventDefault();

    this.setState({
      content: changeEvent.currentTarget.value
    });
  }

  /**
   *
   *
   * @memberof TextQuestion
   */
  extractQuestion(): CreateQuestionInput {
    let input: TextQuestionState = this.state;
    let question: CreateQuestionInput;

    input.previews = this.previewRef.current.extractPreviews();

    question = {
      content: input.content,
      previews: input.previews,
      title: input.title,
      subTitle: input.subTitle,
      questionType: QuestionKind.TEXT
    };
    return question;
  }
}
