/**
 * @author William Alexander Livesley
 * @date    2018-01-30
 * @filename  index.tsx
 * @LastModifiedBy Ahmed Samer
 * @LastModified 2019-02-26
 * @Copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { Loader } from "./react-components/page/loader";
import React from "react";
import ReactDOM from "react-dom";
import { graphql, QueryRenderer } from "react-relay";
import { ERROR_MESSAGES } from "./constants/errors-constants";
import { ACTION_MODAL_ID } from "./constants/general-constants";
import { ACTION_MODAL_CONTEXT } from "./contexts/action-modal-context";
import { CognitoIdpAuthenticator } from "./react-components/authentication/cognito-idp-authenticator";
import {
  ActionModal,
  ActionModalProps,
} from "./react-components/modals/action-modal";
import { Modal } from "./react-components/modals/modal";
import HtmlPage from "./react-components/page/html-page";
import { ReadyState } from "./relay/ready-state";
import { ENVIRONMENT } from "./relay/relay-environment";
import { Router } from "./utilities/router";
import { ErrorBoundary } from "./error-boundary";
import $ from "jquery";
import { LocalStorageKeys } from "./utilities/local-storage-keys";
import { showActionModal } from "./utilities/general";
const LOGO = require("../static/rv_logo.png");
import isEqual from "lodash.isequal";

/**
 *
 *
 * @interface AppState
 */
interface AppState {
  /**
   *
   *
   * @type {ActionModalProps}
   * @memberof AppState
   */
  actionModalProps: ActionModalProps;

  /**
   *
   *
   * @type {React.ReactNode}
   * @memberof AppState
   */
  view: React.ReactNode;
}
/**
 * The start point of the public website.
 */
class App extends React.Component<{}, AppState> {
  /**
   * Constructor
   *
   * @param {*} props The component props.
   */
  constructor(props: any) {
    super(props);

    this.state = {
      actionModalProps: {
        allowCancelButton: false,
        buttonAction: null,
        buttonText: null,
        modalMessageBody: ERROR_MESSAGES.RE_SIGN_IN_MESSAGE,
        modalTitle: "Please sign in again",
      },

      view: <Loader isActive={true} splash={true} />,
    };

    this.showModal = this.showModal.bind(this);
  }

  /**
   * Invoked by the React framework immediately after a component is mounted.
   */
  componentDidMount() {
    Promise.all([
      import("./authentication/cognito-client")
        .then((module) => {
          return module.CognitoClient.getCognitoIdentityCredentials();
        })
        .catch((err) => {
          return Promise.resolve();
        }),
      this.getElementToRender(),
      import("./cms"),
    ]).then((res) => {
      const CREDENTIALS = res[0];
      const NODE = res[1];
      const ACTION_MODAL = (
        <Modal
          id={ACTION_MODAL_ID}
          className="modal-dialog-centered modal-sm"
          title={this.state.actionModalProps.modalTitle}
        >
          <ActionModal {...this.state.actionModalProps} />
        </Modal>
      );

      if (NODE) {
        this.setState({ view: !CREDENTIALS ? ACTION_MODAL : NODE }, () => {
          if (!CREDENTIALS) {
            setTimeout(() => {
              showActionModal();
            }, 200);
          }
        });
      }
    });
  }

  /**
   * Displays a model using given properties.
   *
   * @param {ActionModalProps} modalProps The properties of the modal.
   */
  showModal(modalProps: ActionModalProps) {
    import("./common").then((module) => {
      const IS_EQUAL = module.COMMON.isEqual;
      if (
        !IS_EQUAL(
          modalProps.modalMessageBody,
          this.state.actionModalProps.modalMessageBody
        )
      ) {
        this.setState({ actionModalProps: modalProps });
        $(`#${ACTION_MODAL_ID}`).modal({
          keyboard: false,
          backdrop: "static",
          show: true,
        });
      }
    });
  }

  /**
   *
   *
   * @returns {({
   *     userIdentityId: string;
   *     provider: "linkedin" | "twitter";
   *   })}
   * @memberof App
   */
  getUserIdentity(): {
    userIdentityId: string;
    provider: "linkedin" | "twitter";
  } {
    const userIdentity: {
      userIdentityId: string;
      provider: "linkedin" | "twitter";
    } = {
      userIdentityId: "",
      provider: "twitter",
    };

    userIdentity.userIdentityId = localStorage.getItem(
      LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_TWITTER_ID_CREDENTIALS
    );
    if (
      userIdentity.userIdentityId === undefined ||
      userIdentity.userIdentityId === null
    ) {
      userIdentity.userIdentityId = localStorage.getItem(
        LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_LINKEDIN_ID_CREDENTIALS
      );
      userIdentity.provider = "linkedin";
    }
    // // console.log("[getUserIdentityId] extractedId :", userIdentity.userIdentityId);
    return userIdentity;
  }

  /**
   *Function responsible to handle url query parameters
   *
   * @returns {React.ReactNode}
   * @memberof App
   */
  handleQueryStringUrl(): Promise<React.ReactNode> {
    const userIdentity: {
      userIdentityId: string;
      provider: "linkedin" | "twitter";
    } = this.getUserIdentity();
    const userIdentityId: string = userIdentity.userIdentityId;
    let queryParameters: string[];
    let pageAlias: string;

    const CURRENT_VIEW = this.state.view;
    // attempt to extract query parameters from
    return Promise.all([Router.getQueryParameters(), Router.getAlias()]).then(
      (res) => {
        queryParameters = res[0];
        pageAlias = res[1];

        const node = (
          <QueryRenderer
            environment={ENVIRONMENT}
            query={graphql`
              query tsQuery(
                $alias: String!
                $userIdentityId: ID
                $queryParameters: [String!]
              ) {
                ...htmlPage_htmlDocument
              }
            `}
            variables={{
              userIdentityId,
              queryParameters,
              alias: pageAlias,
            }}
            render={({ error, props }): React.ReactElement<any> => {
              if (error) {
                console.error(error);
                return (
                  <div>
                    {error}
                    {window.location.assign("#ERROR")}
                  </div>
                );
              }
              if (props) {
                return <HtmlPage htmlDocument={props} />;
              }
              return CURRENT_VIEW as React.ReactElement;
            }}
          />
        );

        return Promise.resolve(node);
      }
    );
  }

  /**
   * Function responsible to handle which element should be rendered
   *
   * @memberof App
   */
  getElementToRender(): Promise<React.ReactNode> {
    const idpTokens: string = window.location.hash ? window.location.hash : "";
    let idpParameters: any;
    let isAccessToken: boolean;
    let result: Promise<React.ReactNode>;

    // apply regular expression to determine if access token matches
    isAccessToken = /access_token/i.test(idpTokens);
    if (isAccessToken) {
      idpParameters = {};
      idpTokens
        .replace("#", "")
        .split("&")
        .forEach((value: string): any => {
          const vl = value.split("=");
          idpParameters[vl[0]] = vl[1];
        });
      result = Promise.resolve(
        <CognitoIdpAuthenticator tokens={idpParameters} />
      );
    } else {
      result = this.handleQueryStringUrl();
    }
    return result;
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode}
   * @memberof App
   */
  render(): React.ReactNode {
    // // attempt to fetch which item should be rendered
    return this.state.view;
  }
}

/**
 * The application.
 *
 * @type {(void|Element|React.Component<*, React.ComponentState, *>)}
 */
ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
