/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-17
 * @description Implementation of the GraphQL mutation to add an original content story.
 * @filename create-original-content
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an original content.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_ORIGINAL_CONTENT_STORY_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createOriginalContentStoryMutation(
    $input: CreateOriginalContentStoryInput!
  ) {
    createOriginalContentStory(input: $input) {
      clientMutationId
      story {
        ... on OriginalContentStory {
          id
          title
        }
      }
      hasErrors
    }
  }
`;
