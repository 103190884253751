/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to add an book story.
 * @filename update-event
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to add an book.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_EVENT_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updateEventMutation($input: CreateUpdateEventInput!) {
    updateEvent(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      event {
        __typename
        id
        url
        summary
        description
        created
        revision
        status
        tags {
          id
          label
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;
