/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-12
 * @description Implementation of dynamicList utilities to be used in the app.
 * @filename dynamic-list.ts
 */

import { CreateDynamicListInput, CreateFilterExpressionInput, UpdateDynamicListInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { DynamicListPageFormInput } from "../../component-configuration/cms-pages";
import { CREATE_DYNAMIC_LIST_GRAPHQL_MUTATION } from "../../relay/mutations/create-dynamic-list";
import { UPDATE_DYNAMIC_LIST_GRAPHQL_MUTATION } from "../../relay/mutations/update-dynamic-list";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class DynamicList
 */
export class DynamicList {
  /**
   * Returns a promise to add new dynamicList to database.
   *
   * @param {ArticlePageState} dynamicListInput create new dynamicList input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new dynamicList mutation .
   */
  static addDynamicList(
    dynamicListInput: DynamicListPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert dynamicList input to  CreateDynamicListInput
    let createDynamicListInput: CreateDynamicListInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let filterInput: CreateFilterExpressionInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (dynamicListInput.expression) {
      filterInput = { expression: dynamicListInput.expression.trim() };
    }

    // initialize create dynamicList graphQl input
    createDynamicListInput = {
      clientMutationId: getClientMutationId(),
      previews: dynamicListInput.previews,
      description:
        dynamicListInput.summary.length > 0
          ? dynamicListInput.summary.trim()
          : undefined,
      tags:
        dynamicListInput.tags && dynamicListInput.tags.length > 0
          ? dynamicListInput.tags
          : undefined,
      label:
        dynamicListInput.label.length > 0
          ? dynamicListInput.label.trim()
          : undefined,
      title:
        dynamicListInput.title.length > 0
          ? dynamicListInput.title.trim()
          : undefined,
      // badges: dynamicListInput.badges,
      filter: filterInput,
      subTitle:
        dynamicListInput.subTitle.length > 0
          ? dynamicListInput.subTitle.trim()
          : undefined
    };

    // initialize mutation configuration
    config = {
      mutation: CREATE_DYNAMIC_LIST_GRAPHQL_MUTATION,
      variables: {
        input: createDynamicListInput
      }
    };
    // attempt to commit acutal graph mutation
    promise = commitMutation(environment, config);

    promise = promise.then((mutationResults: any) => {
      return mutationResults.createDynamicList.hasErrors === false
        ? Promise.resolve(mutationResults.createDynamicList.dynamicList)
        : Promise.reject(mutationResults.createDynamicList.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {DynamicListPageFormInput} dynamicListInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof DynamicList
   */
  static updateDynamicList(
    dynamicListInput: DynamicListPageFormInput,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert dynamicList input to  CreateDynamicListInput
    let updateDynamicListInput: UpdateDynamicListInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let filterInput: CreateFilterExpressionInput;

    if (dynamicListInput.expression) {
      filterInput = { expression: dynamicListInput.expression.trim() };
    }
    // validate required parameters in schema and then
    // add only provided optional parameters.

    // initialize create dynamicList graphQl input
    updateDynamicListInput = {
      clientMutationId: getClientMutationId(),
      id: dynamicListInput.id,
      revision: dynamicListInput.revision,
      previews: dynamicListInput.previews,
      description:
        dynamicListInput.summary.length > 0
          ? dynamicListInput.summary.trim()
          : undefined,
      tags:
        dynamicListInput.tags && dynamicListInput.tags.length > 0
          ? dynamicListInput.tags
          : undefined,
      label:
        dynamicListInput.label.length > 0
          ? dynamicListInput.label.trim()
          : undefined,
      title:
        dynamicListInput.title.length > 0
          ? dynamicListInput.title.trim()
          : undefined,
      // badges: dynamicListInput.badges,
      filter: filterInput,
      subTitle:
        dynamicListInput.subTitle.length > 0
          ? dynamicListInput.subTitle.trim()
          : undefined
    };

    // initialize mutation configuration
    config = {
      mutation: UPDATE_DYNAMIC_LIST_GRAPHQL_MUTATION,
      variables: {
        input: updateDynamicListInput
      }
    };
    // attempt to commit acutal graph mutation
    promise = commitMutation(environment, config);

    promise = promise.then((mutationResults: any) => {
      return mutationResults.updateDynamicList.hasErrors === false
        ? Promise.resolve(mutationResults.updateDynamicList.dynamicList)
        : Promise.reject(mutationResults.updateDynamicList.errors);
    });

    return promise;
  }
}
