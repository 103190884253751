/**
 * @author Maria Osama
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-07-01 12:27:45
 * @description Implementation of the accountVerification react component
 * @filename account-verification-modal.tsx
 */
import React from "react";
import { RdvButton } from "../../page-framework/rdv-button";
import { Loader } from "../../page/loader";
import { MessageModal } from "./message-modal";

const ONE_TIME_PASSWORD_SIZE: number = 6;

/**
 * The props of the AccountVerificationModal component.
 *
 * @interface
 */
interface AccountVerificationModalProps {
  /**
   * A function that changes the current modal.
   *
   * @type {function(modal: any, props:Object):void}
   */
  changeModal: (modal: any, props?: {}) => void;

  /**
   * The email address of the user
   *
   * @type {string}
   */
  email: string;

  /**
   * A function that navigates to the technical support pages and closes the modal.
   *
   * @type {function():void}
   */
  navigateToTechnicalSupport: () => void;

  /**
   * A function that returns a promise to resend the account verification email.
   *
   * @type {function(email: string):Promise<any>}
   */
  resendAccountVerificationEMail: (email: string) => Promise<any>;

  /**
   * A function that sets the title of the modal.
   *
   * @type {function(email: string):Promise<any>}
   */
  setTitle: (title?: string) => void;

  /**
   * The function to be called when submitting the account verification code.
   *
   * @type {function():Promise<void>}
   * @memberof AccountVerificationProps
   */
  verifyAccountFromVerificationCode: (
    email: string,
    verificationCode: string
  ) => Promise<void>;

  /**
   *
   *
   * @type {Function}
   * @memberof AccountVerificationModalProps
   */
  onSuccess?: Function;

  /**
   *
   *
   * @type {Function}
   * @memberof AccountVerificationModalProps
   */
  onFailure?: Function;

  /**
   * the signed up user password
   *
   * @type {string}
   * @memberof MessageModalProps
   */
  userPassword?: string;
}

/**
 * The state of the AccountVerificationModal component which displays the modal to collect the account verification code.
 *
 * @interface
 */
interface AccountVerificationModalState {
  /**
   * A current error.
   *
   * @type {AWS.AWSError}
   */
  error?: {
    code: string;
    message: string;
  };

  /**
   * A flag which is true if there is an error.
   *
   * @type {boolean}
   */
  hasError: boolean;

  /**
   * fires the progress overlay when submit button is clicked
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * The value of verification input
   *
   * @type {string}
   * @memberof AccountVerificationState
   */
  verificationCode: string;
}

/**
 *
 * A React component that that renders the Account Verification modal.
 *
 * @extends {React.Component}
 */
export class AccountVerificationModal extends React.Component<
  AccountVerificationModalProps,
  AccountVerificationModalState
> {
  /**
   * Constructor
   *
   * @param {AccountVerificationModalProps} props The props of the component.
   */
  constructor(props: AccountVerificationModalProps) {
    super(props);
    this.contactTechnicalSupport = this.contactTechnicalSupport.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      hasError: false,
      loading: false,
      verificationCode: null,
    };
    this.verifyAccount = this.verifyAccount.bind(this);
  }

  /**
   * Invoked by the React framework after the component output has been rendered to the DOM
   */
  componentDidMount() {
    this.props.setTitle("Verify Your Account");
    const defaultError: {
      code: string;
      message: string;
    } = {
      code: null,
      message: null,
    };
    this.setState({
      error: defaultError,
      hasError: false,
    });
  }

  /**
   * Invokes the technical support and closes the modal.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  contactTechnicalSupport(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.props.navigateToTechnicalSupport();
  }

  /**
   * Set the state with the new verification code values.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e The event.
   * @memberof AccountVerification
   */
  onCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    this.setState({ verificationCode: e.target.value });
  }

  /**
   * Submits the form.
   *
   * @param {React.FormEvent} e The event.
   * @memberof AccountVerification
   */
  onSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.setState({ loading: true });

    // validate provided verification code
    if (this.state.verificationCode.length === ONE_TIME_PASSWORD_SIZE) {
      // console.log(this.props.email);
      this.props
        .verifyAccountFromVerificationCode(
          this.props.email,
          this.state.verificationCode
        )
        .then((): void => {
          this.setState({ loading: false });
          this.props.changeModal(MessageModal, {
            buttonLabel: "Great",
            hasCloseButton: true,
            message: "You have created your Radivision account.",
            messageTitle: "Congratulations!",
            btnAction: this.props.onSuccess,
            userEmail: this.props.email,
            userPassword: this.props.userPassword,
          });
        })
        .catch((e: { code: string; message: string }): void => {
          const awsError: {
            code: string;
            message: string;
          } = {
            code: e.code,
            message: e.message,
          };

          this.setState({
            error: awsError,
            hasError: true,
            loading: false,
          });

          if (this.props.onFailure) {
            this.props.onFailure(e.message);
          }
        });
    } else {
      // TODO handle invalid verification code before actual request
      // // console.log("[onSubmit] invalid verification code, valid to submt request");

      const oneTimePasswordError: {
        code: string;
        message: string;
      } = {
        code: "InvalidOneTimePasswordLength",
        message:
          " The email address that you've entered is invalid. Please try again",
      };

      this.setState({
        error: oneTimePasswordError,
        hasError: true,
        loading: false,
      });
    }
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return (
      <div>
        <Loader isActive={this.state.loading} />
        <div id="accountVerification">
          <h1>Thanks for signing up!</h1>
          <h1>Let's verify your account.</h1>
          <h5>Please check your email for the code to verify your</h5>
          <h5>account and enter the code in the box below.</h5>

          <form id="acc-verify-form" onSubmit={this.onSubmit}>
            <label className="form-group has-float-label">
              <input
                className="form-control"
                placeholder="Account Verification Code"
                onChange={this.onCodeChange}
                required={true}
              />
              <span>Enter the code from the email</span>
            </label>
            {this.state.hasError ? this.renderErrorMessage() : null}
            <RdvButton type="submit" form="acc-verify-form" text="verify" />
          </form>
        </div>
      </div>
    );
  }

  /**
   * Returns an element containing an error message determined by the current error.
   *
   * @returns {JSX.Element} The element containing the details of the error.
   */
  renderErrorMessage(): JSX.Element {
    let element: JSX.Element;

    if (this.state.hasError) {
      switch (this.state.error.code) {
        case "CodeMismatchException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The verification code is invalid. Please{" "}
                <a href="#" onClick={this.verifyAccount}>
                  request a new verification code
                </a>{" "}
                or{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;
        case "ExpiredCodeException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                The verification code has expired. Please{" "}
                <a href="#" onClick={this.verifyAccount}>
                  request a new verification code
                </a>
                or{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;
        case "InvalidParameterException":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                An unexpected error has occurred. Please{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
          break;
        case "InvalidOneTimePasswordLength":
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                Please enter a valid verifiation code , or You can
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
              </h6>
            </div>
          );
          break;
        default:
          element = (
            <div className="form-group alert alert-danger">
              <h6>
                An unexpected error has occurred. Please{" "}
                <a href="#" onClick={this.contactTechnicalSupport}>
                  contact Radivision's technical support
                </a>
                .
              </h6>
            </div>
          );
      }
    }
    return element;
  }

  /**
   * Returns a promise to resend the account verification code.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e The click event
   */
  verifyAccount(e: React.MouseEvent<HTMLAnchorElement>): Promise<any> {
    e.preventDefault();
    this.setState({
      error: null,
      hasError: false,
      verificationCode: null,
    });
    return this.props
      .resendAccountVerificationEMail(this.props.email)
      .catch((e: { code: string; message: string }): void => {
        this.setState({
          error: e,
          hasError: true,
        });
      });
  }
}
