/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UpdateTagInput = {
    readonly clientMutationId: string;
    readonly force?: boolean | null;
    readonly id: string;
    readonly revision?: string | null;
    readonly description?: string | null;
    readonly label: string;
    readonly isGraphQlType?: boolean | null;
};
export type updateTagMutationVariables = {
    readonly input: UpdateTagInput;
};
export type updateTagMutationResponse = {
    readonly updateTag: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly tag: {
            readonly __typename: string;
            readonly id: string;
            readonly description: string | null;
            readonly label: string | null;
            readonly isDeleted: boolean | null;
            readonly isGraphQlType: boolean | null;
            readonly revision: string | null;
        } | null;
    } | null;
};
export type updateTagMutation = {
    readonly response: updateTagMutationResponse;
    readonly variables: updateTagMutationVariables;
};



/*
mutation updateTagMutation(
  $input: UpdateTagInput!
) {
  updateTag(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    tag {
      __typename
      id
      description
      label
      isDeleted
      isGraphQlType
      revision
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTagInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateTag",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateTagPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tag",
        "storageKey": null,
        "args": null,
        "concreteType": "Tag",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "label",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isDeleted",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isGraphQlType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revision",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateTagMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateTagMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateTagMutation",
    "id": null,
    "text": "mutation updateTagMutation(\n  $input: UpdateTagInput!\n) {\n  updateTag(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    tag {\n      __typename\n      id\n      description\n      label\n      isDeleted\n      isGraphQlType\n      revision\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ef0dcf408d61fa425146d92a8617d7c0';
export default node;
