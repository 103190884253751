/**
 * @author Linkon Islam
 * @date   2019-01-10
 * @description Modal class component
 * @filename Modal.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import Modal from "./modal/modal";

/**
 * The props of the ConfirmModal component.
 *
 * @interface ModalProps
 */
interface ModalProps {
  /**
   *
   *
   * @type {string}
   * @memberof ModalProps
   */
  body: string;
  /**
   * String representing the title of modal
   *
   * @type {string}
   * @memberof ModalProps
   */
  title: string;
  /**
   *Function define the action of modal close
   *
   * @memberof ModalProps
   */
  close: Function;
}

/**
 * The state of the ConfirmModal component.
 *
 * @interface ModalState
 */
interface ModalState {
  /**
   *
   *
   * @type {string}
   * @memberof ModalState
   */
  show: string;
}

/**
 *
 *
 * @class ConfirmModal
 * @extends {React.Component<ModalProps, ModalState>}
 */
class ConfirmModal extends React.Component<ModalProps, ModalState> {
  /**
   *Creates an instance of ConfirmModal.
   * @param {*} props
   * @memberof ConfirmModal
   */
  constructor(props: any) {
    super(props);

    this.state = {
      show: "true"
    };
  }

  /**
   *
   *
   * @returns
   * @memberof ConfirmModal
   */
  render() {
    return (
      <div>
        <Modal
          title={`${this.props.title}`}
          show={this.state.show}
          close={this.props.close}
        >
          <h3 style={{ color: "white" }}>{this.props.body} </h3>
          {/* TO DO ADD ACTION Bottoms for oki or yes and no */}
          <button
            type="button"
            className="btn btn-radivision"
            onClick={() => {
              this.setState({ show: "false" });
              if (this.props.close) this.props.close();
            }}
          >
            ok
          </button>
        </Modal>
      </div>
    );
  }
}

export default ConfirmModal;
