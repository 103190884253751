/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type dynamicListPageQueryVariables = {
    readonly id: string;
};
export type dynamicListPageQueryResponse = {
    readonly list: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly list: ({
            readonly __typename: string;
            readonly id?: string;
            readonly description?: string | null;
            readonly label?: string | null;
            readonly revision?: string | null;
            readonly name?: string | null;
            readonly filter?: {
                readonly expression: string | null;
                readonly tags: {
                    readonly clauses: ReadonlyArray<{
                        readonly operator: ReadonlyArray<{
                            readonly description: string | null;
                            readonly label: string | null;
                        }> | null;
                    }> | null;
                    readonly operator: ReadonlyArray<{
                        readonly description: string | null;
                        readonly label: string | null;
                    }> | null;
                    readonly tags: ReadonlyArray<{
                        readonly id: string;
                        readonly label: string | null;
                    }> | null;
                } | null;
            };
            readonly items?: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly listItem: {
                        readonly item: ({
                            readonly __typename: string;
                            readonly id?: string;
                            readonly kind?: string | null;
                            readonly label?: string | null;
                            readonly linkUrl?: string | null;
                            readonly name?: string | null;
                            readonly previewUrl?: string | null;
                            readonly publisher?: string | null;
                            readonly responsibility?: string | null;
                            readonly landingPageUrl?: string | null;
                            readonly subTitle?: string | null;
                            readonly title?: string | null;
                        } & ({
                            readonly __typename: "SearchHit";
                            readonly id: string;
                            readonly kind: string | null;
                            readonly label: string | null;
                            readonly linkUrl: string | null;
                            readonly name: string | null;
                            readonly previewUrl: string | null;
                            readonly publisher: string | null;
                            readonly responsibility: string | null;
                            readonly landingPageUrl: string | null;
                            readonly subTitle: string | null;
                            readonly title: string | null;
                        } | {
                            /*This will never be '% other', but we need some
                            value in case none of the concrete values match.*/
                            readonly __typename: "%other";
                        })) | null;
                    } | null;
                }> | null;
            } | null;
            readonly previews?: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
            readonly subTitle?: string | null;
            readonly title?: string | null;
        } & ({
            readonly __typename: "DynamicList";
            readonly id: string;
            readonly description: string | null;
            readonly label: string | null;
            readonly revision: string | null;
            readonly name: string | null;
            readonly filter: {
                readonly expression: string | null;
                readonly tags: {
                    readonly clauses: ReadonlyArray<{
                        readonly operator: ReadonlyArray<{
                            readonly description: string | null;
                            readonly label: string | null;
                        }> | null;
                    }> | null;
                    readonly operator: ReadonlyArray<{
                        readonly description: string | null;
                        readonly label: string | null;
                    }> | null;
                    readonly tags: ReadonlyArray<{
                        readonly id: string;
                        readonly label: string | null;
                    }> | null;
                } | null;
            };
            readonly items: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly listItem: {
                        readonly item: ({
                            readonly __typename: string;
                            readonly id?: string;
                            readonly kind?: string | null;
                            readonly label?: string | null;
                            readonly linkUrl?: string | null;
                            readonly name?: string | null;
                            readonly previewUrl?: string | null;
                            readonly publisher?: string | null;
                            readonly responsibility?: string | null;
                            readonly landingPageUrl?: string | null;
                            readonly subTitle?: string | null;
                            readonly title?: string | null;
                        } & ({
                            readonly __typename: "SearchHit";
                            readonly id: string;
                            readonly kind: string | null;
                            readonly label: string | null;
                            readonly linkUrl: string | null;
                            readonly name: string | null;
                            readonly previewUrl: string | null;
                            readonly publisher: string | null;
                            readonly responsibility: string | null;
                            readonly landingPageUrl: string | null;
                            readonly subTitle: string | null;
                            readonly title: string | null;
                        } | {
                            /*This will never be '% other', but we need some
                            value in case none of the concrete values match.*/
                            readonly __typename: "%other";
                        })) | null;
                    } | null;
                }> | null;
            } | null;
            readonly previews: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
            readonly subTitle: string | null;
            readonly title: string | null;
        } | {
            /*This will never be '% other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        })) | null;
    } | null;
};
export type dynamicListPageQuery = {
    readonly response: dynamicListPageQueryResponse;
    readonly variables: dynamicListPageQueryVariables;
};



/*
query dynamicListPageQuery(
  $id: ID!
) {
  list(id: $id) {
    hasErrors
    errors {
      id
      location
    }
    list {
      __typename
      ... on DynamicList {
        id
        description
        label
        revision
        name
        filter {
          expression
          tags {
            clauses {
              operator {
                description
                label
                id
              }
            }
            operator {
              description
              label
              id
            }
            tags {
              id
              label
            }
          }
        }
        items {
          count
          edges {
            cursor
            listItem {
              item {
                __typename
                ... on SearchHit {
                  id
                  kind
                  label
                  linkUrl
                  name
                  previewUrl
                  publisher
                  responsibility
                  landingPageUrl
                  subTitle
                  title
                }
                ... on Node {
                  id
                }
                ... on Tag {
                  id
                }
              }
              id
            }
          }
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                  id
                }
                id
              }
            }
          }
        }
        subTitle
        title
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expression",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "operator",
  "storageKey": null,
  "args": null,
  "concreteType": "ClauseOperator",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/)
  ]
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": null,
  "args": null,
  "concreteType": "Tag",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v7/*: any*/)
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "linkUrl",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "previewUrl",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publisher",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "responsibility",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageUrl",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v9/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "path",
      "args": null,
      "storageKey": null
    }
  ]
},
v25 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "operator",
  "storageKey": null,
  "args": null,
  "concreteType": "ClauseOperator",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v3/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dynamicListPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "list",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ListPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "list",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "DynamicList",
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "filter",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FilterExpression",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tags",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TagsClause",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "clauses",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TagsClause",
                            "plural": true,
                            "selections": [
                              (v11/*: any*/)
                            ]
                          },
                          (v11/*: any*/),
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "items",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ListItemsConnection",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ListItemEdge",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "listItem",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ListItem",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "item",
                                "storageKey": null,
                                "args": null,
                                "concreteType": null,
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "type": "SearchHit",
                                    "selections": [
                                      (v3/*: any*/),
                                      (v15/*: any*/),
                                      (v7/*: any*/),
                                      (v16/*: any*/),
                                      (v9/*: any*/),
                                      (v17/*: any*/),
                                      (v18/*: any*/),
                                      (v19/*: any*/),
                                      (v20/*: any*/),
                                      (v21/*: any*/),
                                      (v22/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "previews",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewsConnection",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewEdge",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "preview",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Preview",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v15/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mediaAsset",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "MediaAsset",
                                "plural": false,
                                "selections": [
                                  (v24/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v15/*: any*/),
                                      (v7/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v21/*: any*/),
                  (v22/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "dynamicListPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "list",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ListPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "list",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "DynamicList",
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "filter",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FilterExpression",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tags",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TagsClause",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "clauses",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TagsClause",
                            "plural": true,
                            "selections": [
                              (v25/*: any*/)
                            ]
                          },
                          (v25/*: any*/),
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "items",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ListItemsConnection",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ListItemEdge",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "listItem",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ListItem",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "item",
                                "storageKey": null,
                                "args": null,
                                "concreteType": null,
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "type": "SearchHit",
                                    "selections": [
                                      (v15/*: any*/),
                                      (v7/*: any*/),
                                      (v16/*: any*/),
                                      (v9/*: any*/),
                                      (v17/*: any*/),
                                      (v18/*: any*/),
                                      (v19/*: any*/),
                                      (v20/*: any*/),
                                      (v21/*: any*/),
                                      (v22/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v3/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "previews",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewsConnection",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PreviewEdge",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "preview",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Preview",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v15/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mediaAsset",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "MediaAsset",
                                "plural": false,
                                "selections": [
                                  (v24/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v15/*: any*/),
                                      (v7/*: any*/),
                                      (v3/*: any*/)
                                    ]
                                  },
                                  (v3/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v21/*: any*/),
                  (v22/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "dynamicListPageQuery",
    "id": null,
    "text": "query dynamicListPageQuery(\n  $id: ID!\n) {\n  list(id: $id) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    list {\n      __typename\n      ... on DynamicList {\n        id\n        description\n        label\n        revision\n        name\n        filter {\n          expression\n          tags {\n            clauses {\n              operator {\n                description\n                label\n                id\n              }\n            }\n            operator {\n              description\n              label\n              id\n            }\n            tags {\n              id\n              label\n            }\n          }\n        }\n        items {\n          count\n          edges {\n            cursor\n            listItem {\n              item {\n                __typename\n                ... on SearchHit {\n                  id\n                  kind\n                  label\n                  linkUrl\n                  name\n                  previewUrl\n                  publisher\n                  responsibility\n                  landingPageUrl\n                  subTitle\n                  title\n                }\n                ... on Node {\n                  id\n                }\n                ... on Tag {\n                  id\n                }\n              }\n              id\n            }\n          }\n        }\n        previews {\n          count\n          edges {\n            cursor\n            preview {\n              link\n              kind\n              mediaAsset {\n                files {\n                  id\n                  name\n                  path\n                }\n                topLevelMediaType {\n                  kind\n                  label\n                  id\n                }\n                id\n              }\n            }\n          }\n        }\n        subTitle\n        title\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '85a0b95a84b381f9620a6227c08fc525';
export default node;
