/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreateOriginalContentStoryInput = {
    readonly badges?: ReadonlyArray<string> | null;
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly durationInSeconds?: number | null;
    readonly externalId?: string | null;
    readonly entities?: ReadonlyArray<string> | null;
    readonly label?: string | null;
    readonly mediaAsset?: string | null;
    readonly people?: ReadonlyArray<CreateEntityPersonInput> | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly publishedDate?: unknown | null;
    readonly subTitle?: string | null;
    readonly summary?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly title?: string | null;
};
export type CreateEntityPersonInput = {
    readonly entity?: string | null;
    readonly person?: string | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type createOriginalContentStoryMutationVariables = {
    readonly input: CreateOriginalContentStoryInput;
};
export type createOriginalContentStoryMutationResponse = {
    readonly createOriginalContentStory: {
        readonly clientMutationId: string | null;
        readonly story: ({
            readonly id?: string;
            readonly title?: string | null;
        } & ({
            readonly id: string;
            readonly title: string | null;
        } | {
            /*This will never be '% other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        })) | null;
        readonly hasErrors: boolean | null;
    } | null;
};
export type createOriginalContentStoryMutation = {
    readonly response: createOriginalContentStoryMutationResponse;
    readonly variables: createOriginalContentStoryMutationVariables;
};



/*
mutation createOriginalContentStoryMutation(
  $input: CreateOriginalContentStoryInput!
) {
  createOriginalContentStory(input: $input) {
    clientMutationId
    story {
      __typename
      ... on OriginalContentStory {
        id
        title
      }
      ... on Node {
        id
      }
    }
    hasErrors
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateOriginalContentStoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createOriginalContentStoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createOriginalContentStory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateStoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "story",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "OriginalContentStory",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createOriginalContentStoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createOriginalContentStory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeleteUpdateStoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "story",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "OriginalContentStory",
                "selections": [
                  (v4/*: any*/)
                ]
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "createOriginalContentStoryMutation",
    "id": null,
    "text": "mutation createOriginalContentStoryMutation(\n  $input: CreateOriginalContentStoryInput!\n) {\n  createOriginalContentStory(input: $input) {\n    clientMutationId\n    story {\n      __typename\n      ... on OriginalContentStory {\n        id\n        title\n      }\n      ... on Node {\n        id\n      }\n    }\n    hasErrors\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ee4f75d5e10d4cba911821f2edc200a5';
export default node;
