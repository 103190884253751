/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Url route page component
 * @filename manage-route-page.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import RouteSubProperties from "./route-sub-properties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CreateUpdateUrlRouteRuleInput,
  UrlRouteRule
} from "@radivision/graphql";
import { getNewUniqueId } from "@radivision/common";

/**
 *
 */
const CognitoPoolGroup: string[] = [
  "Admins",
  "Content_Managers",
  "us-east-1_0ZVTyk0q0_Facebook",
  "us-east-1_0ZVTyk0q0_Google"
];

/**
 *
 *
 * @interface RoutePropertyProps
 */
interface RoutePropertyProps {
  /**
   *
   *
   * @type {UrlRouteRule}
   * @memberof RoutePropertyProps
   */
  urlRouteRule?: UrlRouteRule;

  /**
   *
   *
   * @type {number}
   * @memberof RoutePropertyProps
   */
  propertyId: number;

  /**
   *
   *
   * @param {number} id
   * @returns {*}
   * @memberof RoutePropertyProps
   */
  removeClick(id: number): any;
}

/**
 *
 *
 * @interface ManageSubRouterState
 */
interface ManageSubRouterState {
  /**
   *
   *
   * @type {CreateUpdateUrlRouteRuleInput}
   * @memberof RoutePropertyProps
   */
  input: CreateUpdateUrlRouteRuleInput;

  /**
   *
   *
   * @type {number}
   * @memberof ManageSubRouterState
   */
  subPropertyId: any;

  /**
   *
   *
   * @type {any[]}
   * @memberof ManageSubRouterState
   */
  routeSubPropertyList: any[];
}

/**
 *
 *
 * @export
 * @class RouteProperty
 * @extends {React.Component<RoutePropertyProps, ManageSubRoutetState>}
 */
export default class RouteProperty extends React.Component<
  RoutePropertyProps,
  ManageSubRouterState
> {
  /**
   *
   *
   * @type {*}
   * @memberof QuestionPanel
   */
  propertiesNodes: Map<number, RouteSubProperties>;

  /**
   * Creates an instance of RouteProperty.
   * @param {RoutePropertyProps} props
   * @memberof RouteProperty
   */
  constructor(props: RoutePropertyProps) {
    super(props);

    let input: CreateUpdateUrlRouteRuleInput = { tags: undefined };
    let routeSubPropertyList: any[] = [];

    this.handleHtmlDocumentChange = this.handleHtmlDocumentChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

    // .. check if provided a property
    if (
      this.props.urlRouteRule !== undefined &&
      this.props.urlRouteRule !== null
    ) {
      if (this.props.urlRouteRule.cognitoIdentityPool) {
        input.cognitoIdentityPool = this.props.urlRouteRule.cognitoIdentityPool;
      }

      if (this.props.urlRouteRule.cognitoUserPoolGroups) {
        input.cognitoUserPoolGroups = this.props.urlRouteRule.cognitoUserPoolGroups;
      }

      if (this.props.urlRouteRule.description) {
        input.description = this.props.urlRouteRule.description;
      }

      if (this.props.urlRouteRule.description) {
        input.description = this.props.urlRouteRule.description;
      }

      if (this.props.urlRouteRule.label) {
        input.label = this.props.urlRouteRule.label;
      }

      if (this.props.urlRouteRule.htmlDocument) {
        input.htmlDocument = this.props.urlRouteRule.htmlDocument.id;
      }

      if (this.props.urlRouteRule.queryParameters) {
        this.props.urlRouteRule.queryParameters.map(queryParm => {
          let id: string = getNewUniqueId();

          const properties = (
            <RouteSubProperties
              ref={node =>
                this.propertiesNodes.set(this.propertiesNodes.size, node)
              }
              key={id}
              subPropertyId={id}
              queryParamter={queryParm}
              removeSubClick={this.handleSubRemoveProperty}
            />
          );

          routeSubPropertyList.push({ id, content: properties });
        });
      }
    }

    this.propertiesNodes = new Map();

    this.state = {
      input,
      routeSubPropertyList,
      subPropertyId: routeSubPropertyList.length
    };
  }

  /**
   *
   *
   * @memberof RouteProperty
   */
  handleChange = (e: any) => {};

  /**
   *
   *
   * @memberof RouteProperty
   */
  addMoreSubProperty = () => {
    let routeSubPropertyList = this.state.routeSubPropertyList;

    const properties = (
      <RouteSubProperties
        ref={node => this.propertiesNodes.set(this.propertiesNodes.size, node)}
        key={this.state.subPropertyId}
        subPropertyId={this.state.subPropertyId}
        removeSubClick={this.handleSubRemoveProperty}
      />
    );

    routeSubPropertyList.push({
      id: this.state.subPropertyId,
      content: properties
    });

    this.setState({
      routeSubPropertyList,
      subPropertyId: this.state.subPropertyId + 1
    });
  };

  /**
   *
   *
   * @memberof RouteProperty
   */
  handleSubRemoveProperty = (id: any) => {
    let routeSubPropertyList = this.state.routeSubPropertyList;

    routeSubPropertyList = routeSubPropertyList.filter(item => {
      return item.id !== id;
    });

    this.propertiesNodes.delete(id);

    this.setState({
      routeSubPropertyList
    });
  };

  /**
   * Function Responsible for handling changes in urlRoute label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof posterPage
   */
  handleHtmlDocumentChange(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let input: CreateUpdateUrlRouteRuleInput = this.state.input;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    input.htmlDocument = eventValue;
    this.setState({ input });
  }

  /**
   * Function Responsible for handling changes in urlRoute label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof posterPage
   */
  handleDescriptionChange(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let input: CreateUpdateUrlRouteRuleInput = this.state.input;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    input.description = eventValue;
    this.setState({ input });
  }

  /**
   *
   *
   * @returns
   * @memberof RouteProperty
   */
  render() {
    return (
      <div>
        <button
          className="btn add-btn"
          onClick={() => {
            this.props.removeClick(this.props.propertyId);
          }}
        >
          <FontAwesomeIcon icon="times-circle" /> close
        </button>
        <div className="row">
          <div className="input-field col m6">
            <label>
              HTML Document <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              id="search"
              type="search"
              className="inline-input form-control"
              required={true}
              value={this.state.input.htmlDocument}
              onChange={this.handleHtmlDocumentChange}
            />
            <button className=" btn btn-info btn-sm ml-2">
              <FontAwesomeIcon icon="search" />
              search
            </button>
          </div>
        </div>
        <div className="row">
          <div className="input-field col m6">
            <label>Description</label>
            <input
              id="description"
              type="text"
              value={this.state.input.description}
              className="validate inline-input form-control"
              onChange={this.handleDescriptionChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col m6">
            <label>Cognito Identity Pool</label>
            <label>
              <input type="checkbox" value="is_dropdown" onChange={null} />
              <span>
                <select
                  className="browser-default form-control"
                  onChange={null}
                >
                  <option disabled={true} hidden={true} value="">
                    --
                  </option>
                  <option value="none">---select--</option>
                  <option value="Authenticated">Authenticated</option>
                  <option value="String">String</option>
                  <option value="Number">Number</option>
                </select>
              </span>
            </label>
          </div>

          <div className="col m3">
            <p>Cognito User Pool Group</p>
            {CognitoPoolGroup.map((group, index) => {
              return (
                <div className="custom-control custom-checkbox" key={index}>
                  <input
                    type="checkbox"
                    value={group}
                    className="custom-control-input"
                    onChange={null}
                  />
                  <label className="custom-control-label">
                    <span>{group}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          {this.state.routeSubPropertyList.length ? (
            this.state.routeSubPropertyList.map((property, index) => {
              return (
                <div
                  key={index}
                  style={{ border: "1px solid", marginLeft: "5px" }}
                >
                  {property.content}
                </div>
              );
            })
          ) : (
            <p style={{ display: "none" }}>nothing to show</p>
          )}
          <a className="btn add-btn" onClick={this.addMoreSubProperty}>
            <FontAwesomeIcon icon="plus-circle" /> add
          </a>
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @memberof RouteProperty
   */
  extractUrlRules(): CreateUpdateUrlRouteRuleInput {
    let createUpdateUrl: CreateUpdateUrlRouteRuleInput = this.state.input;
    createUpdateUrl.queryParameters = [];
    // extract rules list
    for (const propertyRef of this.propertiesNodes.values()) {
      if (propertyRef) {
        createUpdateUrl.queryParameters.push(
          propertyRef.extractQueryParameter()
        );
      }
    }
    // extract query parameter
    return createUpdateUrl;
  }
}
