/**
 * @author Ahmed Samer
 * @date   2019-11-11
 * @description Tag component
 * @filename versioning
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import React, { PureComponent } from "react";
import ReactDiffViewer from "react-diff-viewer";

export interface DiffProps {
  /**
   *
   *
   * @type {string}
   * @memberof DiffProps
   */
  source: string;

  /**
   *
   *
   * @type {string}
   * @memberof DiffProps
   */
  updates: string;
}

/**
 *
 *
 * @export
 * @class Diff
 * @extends {PureComponent<DiffProps>}
 */
export class Diff extends PureComponent<DiffProps> {
  render = () => {
    const { source, updates } = this.props;
    return (
      <ReactDiffViewer oldValue={source} newValue={updates} splitView={true} />
    );
  };
}
