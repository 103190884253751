/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-05
 * @description Implementation of Book utilities to be used in the app.
 * @filename article.ts
 */
import { CreateBookStoryInput, CreateStoryInput, UpdateBookStoryInput, UpdateStoryInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { CREATE_BOOK_GRAPHQL_MUTATION } from "../relay/mutations/create-book-story";
import { UPDATE_BOOK_STORY_GRAPHQL_MUTATION } from "../relay/mutations/update-book-story";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { Story } from "./cms-operation/story";
import { commitMutation } from "./relay";

export class Book {
  /**
   * Returns a promise to add new book to database.
   *
   * @param {AddToPersonInput} BookInput The update to add to the person.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add book mutation.
   */
  static addBook(
    bookInput: any,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    // ... convert Book input to  CreateBookStoryInput
    let createBookStoryInput: CreateBookStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: CreateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      bookInput.link === undefined ||
      bookInput.link === null ||
      bookInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("w7OnWZyfWEWd8OJzJKu97Q"));
    } else {
      storyInput = Story.createStoryInput(bookInput);

      // initialize create book graphQl input
      createBookStoryInput = {
        ...storyInput,
        link: encodeURI(bookInput.link.trim()),
        timeToReadInSeconds: bookInput.timeToRead
      };

      // console.log("[addBookStory] mutation input ", JSON.stringify(createBookStoryInput));

      // initialize mutation configuration
      config = {
        mutation: CREATE_BOOK_GRAPHQL_MUTATION,
        variables: {
          input: createBookStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.createBookStory.hasErrors === false
          ? Promise.resolve(mutationResults.createBookStory.story)
          : Promise.reject(mutationResults.createBookStory.errors);
      });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Book
   */
  static updateBookStory(
    bookInput: any,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let updateBookStoryInput: UpdateBookStoryInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let storyInput: UpdateStoryInput;

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (
      bookInput.link === undefined ||
      bookInput.link === null ||
      bookInput.link.length < 1
    ) {
      promise = Promise.reject(new Error("Zi2o6TA3cUG8lQeDfN7VkQ"));
    } else {
      storyInput = Story.updateStoryInput(bookInput);

      // initialize create book graphQl input
      updateBookStoryInput = {
        ...storyInput,
        link: encodeURI(bookInput.link.trim()),
        timeToReadInSeconds: bookInput.timeToRead
      };

      // console.log("[updateBookStory] mutation input ", JSON.stringify(updateBookStoryInput));

      // initialize mutation configuration
      config = {
        mutation: UPDATE_BOOK_STORY_GRAPHQL_MUTATION,
        variables: {
          input: updateBookStoryInput
        }
      };
      //
      promise = commitMutation(environment, config);
      //
      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateBookStory.hasErrors === false
          ? Promise.resolve(mutationResults.updateBookStory.story)
          : Promise.reject(mutationResults.updateBookStory.errors);
      });
    }

    return promise;
  }

  static deleteBook(): Promise<any> {
    return undefined;
  }
}
