/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-06
 * @description Implementation of Article utilities to be used in the app.
 * @filename story.ts
 */
import { CreateStoryInput, CreateUpdatePreviewInput, UpdateStoryInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { ArticlePageFormInput, BookPageFormInput, NewsLetterPageFormInput, OnlineCoursePageFormInput, OriginalContentPageFormInput, PodcastPageFormInput } from "../../component-configuration/cms-pages";
import { DELETE_STORY_GRAPHQL_MUTATION } from "../../relay/mutations/delete-story";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class Story
 */
export class Story {
  /**
   *
   *
   * @static
   * @param {(BookPageState | ArticlePageState)} params
   * @memberof Story
   */
  static createStoryInput(
    params:
      | ArticlePageFormInput
      | NewsLetterPageFormInput
      | OnlineCoursePageFormInput
      | PodcastPageFormInput
      | BookPageFormInput
      | OriginalContentPageFormInput
  ): CreateStoryInput {
    let storyInput: CreateStoryInput;
    let previewsInput: CreateUpdatePreviewInput[];

    // validate required parameters in schema and then
    // add only provided optional parameters.

    // handle preview inputs
    if (
      params.previews !== undefined &&
      params.previews !== null &&
      params.previews.length > 0
    ) {
      previewsInput = [];
      for (const PREVIEW of params.previews) {
        previewsInput.push({
          kind: PREVIEW.kind,
          link: PREVIEW.link,
          mediaAsset: PREVIEW.mediaAsset
        });
      }
    }

    // initialize create article graphQl input
    storyInput = {
      clientMutationId: getClientMutationId(),
      label:
        params.label !== undefined &&
        params.label !== null &&
        params.label.length > 0
          ? params.label.trim()
          : undefined,
      subTitle:
        params.subTitle !== undefined &&
        params.subTitle !== null &&
        params.subTitle.length > 0
          ? params.subTitle.trim()
          : undefined,
      title:
        params.title !== undefined &&
        params.title !== null &&
        params.title.length > 0
          ? params.title.trim()
          : undefined,
      tags:
        params.tags !== undefined &&
        params.tags !== null &&
        params.tags.length > 0
          ? params.tags
          : undefined,
      // moderation: params.moderation,
      publishedDate:
        params.publishedDate !== undefined &&
        params.publishedDate !== null &&
        params.publishedDate.length > 0
          ? new Date(
              isNaN(Number(params.publishedDate.trim()))
                ? params.publishedDate.trim()
                : Number(params.publishedDate.trim())
            ).toISOString()
          : undefined,
      description:
        params.summary !== undefined &&
        params.summary !== null &&
        params.summary.length > 0
          ? params.summary.trim()
          : undefined,
      publisher:
        params.publisherName !== undefined &&
        params.publisherName !== null &&
        params.publisherName.length > 0
          ? params.publisherName.trim()
          : undefined,
      previews: previewsInput
    };

    // // console.log("[createStoryInput] story input ", JSON.stringify(storyInput));

    return storyInput;
  }

  /**
   *
   *
   * @static
   * @param {(BookPageState | ArticlePageState)} params
   * @memberof Story
   */
  static updateStoryInput(
    params:
      | ArticlePageFormInput
      | NewsLetterPageFormInput
      | OnlineCoursePageFormInput
      | PodcastPageFormInput
      | BookPageFormInput
      | OriginalContentPageFormInput
  ): UpdateStoryInput {
    let storyInput: UpdateStoryInput;
    let previewsInput: CreateUpdatePreviewInput[];

    // validate required parameters in schema and then
    // add only provided optional parameters.
    if (params.id === undefined || params.id === null) {
      throw new Error("[TypWs9I21UWppVYY8WKqpQ] invalid story id");
    }

    // handle preview inputs
    if (
      params.previews !== undefined &&
      params.previews !== null &&
      params.previews.length > 0
    ) {
      previewsInput = [];
      for (const PREVIEW of params.previews) {
        previewsInput.push({
          kind: PREVIEW.kind,
          link: PREVIEW.link,
          mediaAsset: PREVIEW.mediaAsset
        });
      }
    }

    // initialize create article graphQl input
    storyInput = {
      clientMutationId: getClientMutationId(),
      id: params.id,
      revision:
        params.revision !== undefined &&
        params.revision !== null &&
        params.revision.length > 0
          ? params.revision.trim()
          : undefined,
      label:
        params.label !== undefined &&
        params.label !== null &&
        params.label.length > 0
          ? params.label.trim()
          : undefined,
      subTitle:
        params.subTitle !== undefined &&
        params.subTitle !== null &&
        params.subTitle.length > 0
          ? params.subTitle.trim()
          : undefined,
      title:
        params.title !== undefined &&
        params.title !== null &&
        params.title.length > 0
          ? params.title.trim()
          : undefined,
      tags:
        params.tags !== undefined &&
        params.tags !== null &&
        params.tags.length > 0
          ? params.tags
          : undefined,
      // moderation: params.moderation,
      publishedDate:
        params.publishedDate !== undefined &&
        params.publishedDate !== null &&
        params.publishedDate.length > 0
          ? new Date(params.publishedDate.trim()).toISOString()
          : undefined,
      description:
        params.summary !== undefined &&
        params.summary !== null &&
        params.summary.length > 0
          ? params.summary.trim()
          : undefined,
      publisher:
        params.publisherName !== undefined &&
        params.publisherName !== null &&
        params.publisherName.length > 0
          ? params.publisherName.trim()
          : undefined,
      previews: previewsInput
    };

    return storyInput;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Article
   */
  static deleteStory(
    formInput: any,
    environment: Environment = RELAY_ENVIRONMENT
  ): Promise<any> {
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (
      formInput === undefined ||
      formInput === null ||
      formInput.id === undefined
    ) {
      promise = Promise.reject(new Error("TW1LX9VhOkKQW4bQFbUZHw"));
    }

    const input = {
      id: formInput.id,
      revision: formInput.revision,
      clientMutationId: getClientMutationId()
    };

    config = {
      mutation: DELETE_STORY_GRAPHQL_MUTATION,
      variables: {
        input
      }
    };

    //
    promise = commitMutation(environment, config);
    //
    promise = promise.then((mutationResults: any) => {
      return mutationResults.deleteStory.hasErrors === false
        ? Promise.resolve(mutationResults.deleteStory.story)
        : Promise.reject(mutationResults.deleteStory.errors);
    });

    return promise;
  }
}
