import * as React from "react";
import Modal from "../modal/modal";
import ManageBadge from "./manage-badge";
import { CreateBadgeInput, Badge } from "@radivision/graphql";
const user = require("../../../../../static/user_icon.png");

/**
 *
 *
 * @export
 * @interface ManageBadgePanelProps
 */
export interface ManageBadgePanelProps {
  /**
   *
   *
   * @type {Badge[]}
   * @memberof ManageBadgePanelProps
   */
  badgeList?: Badge[];
}

interface ManageBadgePanelState {
  /**
   *
   *
   * @type {({ id: number; type: "LINK" | "MEDIA_ASSET"; source: string }[])}
   * @memberof ManageBadgePanelState
   */
  badges: { id: string; type: "LINK" | "MEDIA_ASSET"; source: string }[];

  /**
   *
   *
   * @type {boolean}
   * @memberof ManageBadgePanelState
   */
  showModal: boolean;
}

/**
 *
 *
 * @export
 * @class ManageBadgePanel
 * @extends {React.Component<{}, ManageBadgePanelState>}
 */
export default class ManageBadgePanel extends React.Component<
  ManageBadgePanelProps,
  ManageBadgePanelState
> {
  /**
   *Creates an instance of ManageBadgePanel.
   * @param {{}} props
   * @memberof ManageBadgePanel
   */
  constructor(props: ManageBadgePanelProps) {
    super(props);
    let badges: {
      id: string;
      type: "LINK" | "MEDIA_ASSET";
      source: string;
    }[] = [];

    this.ModalClose = this.ModalClose.bind(this);
    this.openBadgeModal = this.openBadgeModal.bind(this);
    this.addBadge = this.addBadge.bind(this);
    this.removeBadge = this.removeBadge.bind(this);
    this.extractBadges = this.extractBadges.bind(this);

    if (
      this.props.badgeList !== undefined &&
      this.props.badgeList !== null &&
      this.props.badgeList.length > 0
    ) {
      this.props.badgeList.map(item => {
        badges.push({
          id: item.id,
          type: item.link ? "LINK" : "MEDIA_ASSET",
          source: item.mediaAsset ? item.mediaAsset.id : item.link
        });
      });
    }
    this.state = {
      badges,
      showModal: false
    };
  }

  /**
   *
   *
   * @memberof ManageBadgePanel
   */
  ModalClose() {
    this.setState({
      showModal: false
    });
  }

  /**
   *
   *
   * @memberof ManageBadgePanel
   */
  openBadgeModal() {
    this.setState({
      showModal: true
    });
  }
  /**
   *
   *
   * @param {number} id
   * @memberof ManageBadgePanel
   */
  removeBadge(id: string) {
    const updatedBadges = this.state.badges.filter(badge => {
      return badge.id !== id;
    });

    this.setState({
      badges: updatedBadges
    });
  }

  /**
   *
   *
   * @param {number} id
   * @param {string} url
   * @param {*} imageSource
   * @memberof ManageBadgePanel
   */
  addBadge(type?: "LINK" | "MEDIA_ASSET", source?: string) {
    let badges = this.state.badges;
    let id: string = String(badges.length + 1);

    if (
      type !== undefined &&
      type !== null &&
      source !== undefined &&
      source !== null
    ) {
      badges.push({ id, type, source });
    }

    this.setState({
      badges,
      showModal: false
    });
  }

  render() {
    return (
      <div className="row">
        {this.state.badges.map((badge, index) => {
          return (
            <div key={index} className="col-sm-2 text-center">
              <img
                src={badge.type === "LINK" ? badge.source : user}
                alt=""
                style={{ width: "100%" }}
              />
              <button
                onClick={() => this.removeBadge(badge.id)}
                className="btn btn-danger btn-sm mt-3"
              >
                delete
              </button>
            </div>
          );
        })}

        <button
          className="btn btn-info btn-sm mt-3"
          style={{ height: "30px" }}
          onClick={this.openBadgeModal}
        >
          Add
        </button>

        {this.state.showModal ? (
          <Modal title="Manage Badge" show="true" close={this.ModalClose}>
            <ManageBadge addBadge={this.addBadge} onCancel={this.ModalClose} />
          </Modal>
        ) : (
          <div />
        )}
      </div>
    );
  }

  /**
   *
   *
   * @memberof ManageBadgePanel
   */
  extractBadges(): CreateBadgeInput[] {
    let badgesInput: CreateBadgeInput[] = [];

    this.state.badges.map(item => {
      let itemData: { link?: string; mediaAsset?: string } = {};

      switch (item.type) {
        case "LINK":
          itemData.mediaAsset = item.source;
          break;

        case "MEDIA_ASSET":
          itemData.mediaAsset = item.source;
          break;

        default:
          break;
      }
      badgesInput.push(itemData);
    });

    return badgesInput.length > 0 ? badgesInput : undefined;
  }
}
