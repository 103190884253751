/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-26
 * @description Implementation of the GraphQL mutation to question panel
 * @filename question-type.tsx
 */

import * as React from "react";
import MultipleChoose from "./multiple/multiple-question";
import TextQuestion from "./text/text-question";
import {
  CreateQuestionInput,
  TextQuestion as graphqlTextQuestion,
  MultipleChoiceQuestion as graphqlMultipleChoiceQuestion,
  GRAPHQL_TYPE_ACTIVITY,
  GRAPHQL_TYPE_TEXT_QUESTION,
  GRAPHQL_TYPE_MULTIPLE_CHOICE_QUESTION
} from "@radivision/graphql";

/**
 *
 *
 * @interface QuestionTypeProps
 */
interface QuestionTypeProps {
  /**
   *
   *
   * @type {(graphqlTextQuestion |  graphqlMultipleChoiceQuestion)}
   * @memberof QuestionTypeProps
   */
  input?: graphqlTextQuestion | graphqlMultipleChoiceQuestion;
  /**
   *
   *
   * @type {number}
   * @memberof QuestionTypeProps
   */
  QID: string;

  /**
   *
   *
   * @memberof QuestionTypeProps
   */
  handleChange?: void;

  /**
   *
   *
   * @param {number} id
   * @memberof QuestionTypeProps
   */
  removeClick(id: string): void;
}

/**
 *
 *
 * @interface QuestionState
 */
interface QuestionState {
  /**
   *
   *
   * @type {string}
   * @memberof QuestionState
   */
  questionType: string;
}

/**
 *
 *
 * @export
 * @class QuestionType
 * @extends {React.Component<QuestionTypeProps, QuestionState>}
 */
export default class QuestionType extends React.Component<
  QuestionTypeProps,
  QuestionState
> {
  /**
   *
   *
   * @type {*}
   * @memberof QuestionType
   */
  textQuestionRef: any;

  /**
   *
   *
   * @type {*}
   * @memberof QuestionType
   */
  multipleQuestionRef: any;
  /**
   * Creates an instance of QuestionType.
   * @param {QuestionTypeProps} props
   * @memberof QuestionType
   */
  constructor(props: QuestionTypeProps) {
    super(props);

    this.extractQuestionInput = this.extractQuestionInput.bind(this);
    let questionType: string = "";

    if (this.props.input) {
      questionType = this.props.input.__typename;
    }

    this.multipleQuestionRef = React.createRef();
    this.textQuestionRef = React.createRef();

    this.state = {
      questionType
    };
  }

  /**
   *
   *
   * @memberof QuestionType
   */
  handleChange = (e: any) => {
    this.setState({
      questionType: e.target.value
    });
  };

  /**
   *
   *
   * @returns
   * @memberof QuestionType
   */
  render() {
    return (
      <div className="container__">
        <div className="row">
          <div className="input-field col s12">
            <select className="form-control" onChange={this.handleChange}>
              <option disabled={true} hidden={true} value="">
                --
              </option>
              <option value="none">---Choose your option--</option>
              <option value={GRAPHQL_TYPE_TEXT_QUESTION}>Text Question</option>
              <option value={GRAPHQL_TYPE_MULTIPLE_CHOICE_QUESTION}>
                Multiple Choice Question
              </option>
            </select>
          </div>
        </div>
        {this.state.questionType === GRAPHQL_TYPE_TEXT_QUESTION ? (
          <TextQuestion
            input={this.props.input}
            ref={this.textQuestionRef}
            qId={this.props.QID}
            removeClick={this.props.removeClick}
          />
        ) : this.state.questionType ===
          GRAPHQL_TYPE_MULTIPLE_CHOICE_QUESTION ? (
          <MultipleChoose
            input={this.props.input}
            ref={this.multipleQuestionRef}
            qId={this.props.QID}
            removeClick={this.props.removeClick}
          />
        ) : (
          <p style={{}}>nothing to show</p>
        )}
      </div>
    );
  }

  /**
   *
   *
   * @returns {CreateQuestionInput[]}
   * @memberof QuestionType
   */
  extractQuestionInput(): CreateQuestionInput {
    let questionInput: CreateQuestionInput;

    switch (this.state.questionType) {
      case GRAPHQL_TYPE_TEXT_QUESTION:
        if (this.textQuestionRef) {
          questionInput = this.textQuestionRef.current.extractQuestion();
        }
        break;

      case GRAPHQL_TYPE_MULTIPLE_CHOICE_QUESTION:
        if (this.multipleQuestionRef) {
          questionInput = this.multipleQuestionRef.current.extractQuestion();
        }
        break;
    }
    return questionInput;
  }
}
