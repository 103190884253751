/* 


*/

import React, { Component } from "react";
import { AccountControl } from "../modals/register-and-login/account-control";
import { SignInModal } from "../modals/register-and-login/sign-in-modal";
import $ from "jquery";

class LoginPage extends Component {
  constructor(props: any) {
    super(props);
    
    setTimeout(()=>{
        const actionModal = document.getElementById("LOGIN_MODAL");
        console.log(actionModal);
        if (actionModal !== undefined && actionModal !== null) {
          $(`#LOGIN_MODAL`).modal({
            keyboard: false,
            backdrop: "static",
            show: true,
          });
        } 
    },200);
    
  
  }

/**
 *
 *
 * @returns
 * @memberof LoginPage
 */
render() {
    // NOTE: I use data-attributes for easier E2E testing
    // but you don't need to target those (any css-selector will work)

    return (
        <div className="Login">
           <AccountControl
           activeModal = {SignInModal}
            modalId="LOGIN_MODAL"
           />
      </div>
    );
  }
}

export default LoginPage;
