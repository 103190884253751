/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-04
 * @description Implementation of the GraphQL mutation to add fields to person.
 * @filename update-person
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to update person.
 *
 * @type {GraphQLTaggedNode}
 */
export const UPDATE_PERSON_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation updatePersonMutation($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      person {
        __typename
        description
        landingPageUrl
        emailAddresses
        fullName
        hasPublicPage
        id
        greeting
        revision
        defaultLocation {
          id
          label
        }
        locations {
          id
          label
        }
        entities {
          __typename
          name
          id
          revision
          hasPublicPage
          isEditable
          landingPageUrl
          description
          previews {
            count
            edges {
              cursor
              preview {
                link
                kind
                mediaAsset {
                  id
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                  }
                }
              }
            }
          }
        }
        experience {
          count
          edges {
            cursor
            position {
              id
              entity {
                __typename
                name
                id
                description
                hasPublicPage
                isEditable
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        inspirations {
          id
          inspirer {
            ... on Entity {
              __typename
              id
              name
              landingPageUrl
              description
              hasPublicPage
              isEditable
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
        investmentsBy {
          id
          entity {
            ... on Entity {
              __typename
              id
              name
              landingPageUrl
              description
              hasPublicPage
              isEditable
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
            }
          }
          investee {
            ... on Entity {
              __typename
              name
              id
              landingPageUrl
              description
              hasPublicPage
              isEditable
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
        follows {
          ... on Entity {
            __typename
            name
            id
            landingPageUrl
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
          }
          ... on Person {
            __typename
            description
            fullName
            hasPublicPage
            id
            greeting
            landingPageUrl
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
          }
        }
        mediaAbout {
          count
          edges {
            cursor
            story {
              __typename
              ... on RadivisionPost {
                id
                link
                previews {
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
                landingPageUrl
              }
              ... on OnlineCourseStory {
                id
                link
                publishedDate
                timeRequiredInSeconds
                publisher
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
              }
              ... on RadivisionPost {
                id
                content
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                revision
                title
              }
              ... on ArticleStory {
                description
                id
                link
                publisher
                timeToReadInSeconds
                publishedDate
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                revision
                subTitle
                title
              }
              ... on BookStory {
                id
                link
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
              }
              ... on NewsletterStory {
                id
                description
                link
                publisher
                timeToReadInSeconds
                publishedDate
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
              }
              ... on PodcastStory {
                id
                link
                publisher
                durationInSeconds
                publishedDate
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
              }
              ... on OriginalContentStory {
                id
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                landingPageUrl
                title
              }
            }
          }
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
        northStar {
          __typename
          description
          id
          questions {
            __typename
            ... on TextQuestion {
              content
              id
              response {
                content
                id
              }
            }
          }
        }
        responsibility
        socialMediaCredentials {
          credential
          platform {
            kind
          }
        }
        # questionnaires {
        #   count
        #   edges {
        #     cursor
        #     node {
        #       id
        #       questions {
        #         __typename
        #         ... on MultipleChoiceQuestion {
        #           id
        #           response {
        #             choices {
        #               id
        #             }
        #           }
        #         }
        #         ... on TextQuestion {
        #           id
        #           response {
        #             id
        #             content
        #           }
        #         }
        #       }
        #     }
        #   }
        # }
        websiteUrl
        socialMediaProfiles {
          id
          link
          platform {
            id
            kind
          }
        }
      }
    }
  }
`;
