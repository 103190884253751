/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-09-04
 * @description Implementation of Routing utilities to be used in the app.
 * @filename router.ts
 */

const querystring = require("querystring");

/**
 * The regular expression used to identify the page part of a hash.
 *
 * @type {RegExp}
 */
const REGEX_FRAGMENT_PAGE: RegExp = /^([^?:]*)(:([^?]*))?([?](.*))?$/i;

/**
 * an interface that declares the url and it's parts
 *
 * @export
 * @interface Url
 */
export interface Url {
  /**
   * the name alias of the page in the html document rule.
   *
   * @type {string}
   * @memberof Url
   */
  alias?: string;

  /**
   * the location of the Element in the Document.
   *
   * @type {string}
   * @memberof Url
   */
  location?: string;

  /**
   * the Query parameters for the html document rule
   *
   * @type {string[]}
   * @memberof Url
   */
  queryParameters?: string[];
}

/**
 * The class responsible for routing and building the Urls in the website.
 *
 * AND HOW DOES IT DO THIS - REALLY IMPORTANT TO DESCRIBE
 *
 * @export
 * @class Router
 */
export class Router {
  /**
   * Updates the current URL based on given parameters. HOW?
   *
   * @static
   * @param {string} alias The alias of the html-document.
   *
   * @param {Array<string>} queryParameters The query parameters for the destination.
   *
   * @param {string} location The location to scroll for in the page.
   */
  public static to(
    alias: string = "cms-home",
    queryParameters: string[] = [],
    location: string = undefined
  ): void {
    let change: boolean = false;
    let newAlias: string;
    let newFragment: string = "";
    let newQP: string[];
    let qpString: string = "";

    import("../common").then((module) => {
      const URIJS = module.COMMON.URIJS;

      return Promise.all([
        Router.getAlias(),
        Router.getQueryParameters(),
        Router.getLocation(),
      ]).then((res) => {
        const currentAlias: string = res[0];
        const currentQP = res[1];
        const currentLocation = res[2];
        if (alias !== null) {
          if (currentAlias !== alias) {
            newAlias = alias;
            change = true;
          }
        } else {
          newAlias = currentAlias;
        }
        if (queryParameters !== undefined && queryParameters !== null) {
          for (const QP of queryParameters) {
            if (currentQP.indexOf(QP) < 0) {
              newQP = queryParameters;
              change = true;
              break;
            }
          }
        } else {
          newQP = currentQP;
        }
        if (change) {
          if (newAlias !== null && newAlias !== undefined) {
            newFragment = `${newFragment}${newAlias}`;
          } else {
            newFragment = `${newFragment}${currentAlias}`;
          }

          if (newQP !== null && newQP !== undefined) {
            for (const QP of newQP) {
              if (QP.length > 1) {
                qpString = `${qpString}&${QP}`;
              }
            }
            if (qpString.length > 1) {
              qpString = qpString
                .split("")
                .slice(1)
                .join("");
              qpString = `?${qpString}`;
            }
          }
          window.location.href = "/";

          window.location.assign(
            `${URIJS(window.location)
              .path(newFragment)
              .query(qpString.length ? qpString : "")
              .hash(location ? `:${location}` : "")}`
          );
        } else if (location) {
          if (currentLocation !== null && currentLocation !== undefined) {
            newFragment = `${URIJS(window.location)
              .fragment()
              .replace(`:${currentLocation}`, `:${location}`)}`;
          } else {
            newFragment = `${URIJS(window.location).hash()}:${location}`;
          }

          window.location.assign(
            `${URIJS(window.location).fragment(newFragment)}`
          );
          Router.scrollTo();
        }
        return Promise.resolve();
      });
    });
  }

  /**
   * Scrolls the page to a location (the in-page location) identified by the hash value (page:location).
   *
   * @param {string} pageLocation The page location to which to scroll. This is null if there is no target.
   */
  public static scrollTo(pageLocation: string = null): void {
    let element: HTMLElement;
    let nbElement: HTMLElement;
    let LOCATION: string = pageLocation;
    if (LOCATION === null || LOCATION === undefined) {
      Router.getLocation().then((location) => {
        LOCATION = location;
        if (
          LOCATION !== undefined &&
          LOCATION !== null &&
          LOCATION.length > 0
        ) {
          element = document.getElementById(decodeURIComponent(LOCATION));
          nbElement = document.getElementById("top-nav");
          if (
            element !== undefined &&
            element !== null &&
            nbElement !== undefined
          ) {
            // import("../common").then((module) => {
            //   module.COMMON.scroller.scrollTo(decodeURIComponent(LOCATION), {
            //     offset: -1 * (nbElement.getBoundingClientRect().height + 50),
            //     smooth: true,
            //   });
            // });
          }
        }
      });
    }
  }
  /**
   * Scrolls the page to a location to top
   *
   * @static
   * @returns {*}
   * @memberof Router
   */
  public static scrollToTop(): void {
    // import("../common").then((module) => {
    //   module.COMMON.scroller.scrollTo("top-nav", {
    //     smooth: true,
    //   });
    // });
  }

  /**
   * Returns the alias from the page URL.
   *
   * @static
   * @returns {string} The alias from the page URL.
   * @memberof Router
   */

  public static getAlias(): Promise<string> {
    let alias: string = "cms-home";

    return import("../common").then((module) => {
      const URIJS = module.COMMON.URIJS;
      const path: string = URIJS(window.location)
        .path()
        .split("/")[1];

      if (path) {
        const endCursor: number = path.indexOf("#");
        alias = path.substr(0, endCursor !== -1 ? endCursor : undefined);
      }
      return Promise.resolve(alias);
    });
  }

  /**
   * Returns the query parameters from the page URL.
   *
   * @static
   * @returns {Array<string>} The query parameters from the page URL.
   * @memberof Router
   */
  public static getQueryParameters(): Promise<string[]> {
    const queryParameters: string[] = [];
    return import("../common").then((module) => {
      const URIJS = module.COMMON.URIJS;
      const QUERY: string = URIJS(encodeURI(window.location.href)).query();
      const path: string[] = URIJS(encodeURI(window.location.href))
        .path()
        .split("/");

      const PASSED_QP = querystring.parse(QUERY);

      if (PASSED_QP) {
        Object.keys(PASSED_QP).forEach((key: string) => {
          queryParameters.push(`${key}=${PASSED_QP[key]}`);
        });
      }
      if (path.length > 2) {
        queryParameters.push(`item_id=${path.pop()}`);
      }
      return Promise.resolve(queryParameters);
    });
  }

  /**
   *
   *
   * @static
   * @returns {{ [index: string]: any }}
   * @memberof Router
   */
  public static getQueryParametersObject(): { [index: string]: any } {
    const queryParameters: { [index: string]: any } = {};

    return queryParameters;
  }

  /**
   * Returns the in-page location (page:location) from the page URL.
   *
   * @static
   * @returns {string} The in-page location from the page URL.
   * @memberof Router
   */
  public static getLocation(): Promise<string> {
    let location: string = null;
    return import("../common").then((module) => {
      const URIJS = module.COMMON.URIJS;
      const fragment: string = URIJS(window.location).fragment();
      let regFragment: RegExpExecArray;

      if (fragment !== undefined && fragment !== null && fragment.length > 0) {
        regFragment = REGEX_FRAGMENT_PAGE.exec(fragment);
        if (regFragment.length > 2) {
          location = regFragment[3];
        }
      }
      return Promise.resolve(location);
    });
  }
}
