/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CrawlKind = "ARTICLE_STORY" | "BOOK_STORY" | "ENTITY" | "NEWSLETTER_STORY" | "ONLINE_COURSE_STORY" | "PERSON" | "PODCAST" | "%future added value";
export type crawlQueryVariables = {
    readonly url: string;
    readonly apiObjectKind?: CrawlKind | null;
};
export type crawlQueryResponse = {
    readonly crawl: {
        readonly hasErrors: boolean | null;
        readonly node: ({
            readonly description?: string | null;
            readonly link?: string | null;
            readonly subTitle?: string | null;
            readonly title?: string | null;
            readonly publishedDate?: unknown | null;
            readonly previews?: {
                readonly edges: ReadonlyArray<{
                    readonly preview: {
                        readonly link: string | null;
                    } | null;
                }> | null;
            } | null;
            readonly name?: string | null;
            readonly websiteUrl?: string | null;
            readonly landingPageUrl?: string | null;
            readonly durationInSeconds?: number | null;
        } & ({
            readonly description: string | null;
            readonly link: string | null;
            readonly subTitle: string | null;
            readonly title: string | null;
            readonly publishedDate: unknown | null;
            readonly previews: {
                readonly edges: ReadonlyArray<{
                    readonly preview: {
                        readonly link: string | null;
                    } | null;
                }> | null;
            } | null;
        } | {
            readonly name?: string | null;
            readonly websiteUrl?: string | null;
            readonly landingPageUrl?: string | null;
        } | {
            readonly durationInSeconds: number | null;
        } | {
            /*This will never be '% other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        })) | null;
    } | null;
};
export type crawlQuery = {
    readonly response: crawlQueryResponse;
    readonly variables: crawlQueryVariables;
};



/*
query crawlQuery(
  $url: String!
  $apiObjectKind: CrawlKind
) {
  crawl(url: $url, kind: $apiObjectKind) {
    hasErrors
    node {
      __typename
      ... on ArticleStory {
        description
        link
        subTitle
        title
        publishedDate
        previews {
          edges {
            preview {
              link
            }
          }
        }
      }
      ... on BookStory {
        description
        link
        subTitle
        publishedDate
        title
        previews {
          edges {
            preview {
              link
            }
          }
        }
      }
      ... on Manageable {
        ... on Entity {
          name
          description
          websiteUrl
          landingPageUrl
          previews {
            edges {
              preview {
                link
              }
            }
          }
        }
      }
      ... on NewsletterStory {
        description
        link
        subTitle
        title
        previews {
          edges {
            preview {
              link
            }
          }
        }
      }
      ... on OnlineCourseStory {
        description
        link
        subTitle
        title
        previews {
          edges {
            preview {
              link
            }
          }
        }
      }
      ... on PodcastStory {
        description
        link
        subTitle
        durationInSeconds
        publishedDate
        title
        previews {
          edges {
            preview {
              link
            }
          }
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "url",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "apiObjectKind",
    "type": "CrawlKind",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "apiObjectKind"
  },
  {
    "kind": "Variable",
    "name": "url",
    "variableName": "url"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "previews",
  "storageKey": null,
  "args": null,
  "concreteType": "PreviewsConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "PreviewEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "preview",
          "storageKey": null,
          "args": null,
          "concreteType": "Preview",
          "plural": false,
          "selections": [
            (v4/*: any*/)
          ]
        }
      ]
    }
  ]
},
v9 = {
  "kind": "InlineFragment",
  "type": "ArticleStory",
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ]
},
v10 = {
  "kind": "InlineFragment",
  "type": "BookStory",
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v7/*: any*/),
    (v6/*: any*/),
    (v8/*: any*/)
  ]
},
v11 = {
  "kind": "InlineFragment",
  "type": "Entity",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "websiteUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "landingPageUrl",
      "args": null,
      "storageKey": null
    },
    (v8/*: any*/)
  ]
},
v12 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/)
],
v13 = {
  "kind": "InlineFragment",
  "type": "NewsletterStory",
  "selections": (v12/*: any*/)
},
v14 = {
  "kind": "InlineFragment",
  "type": "OnlineCourseStory",
  "selections": (v12/*: any*/)
},
v15 = {
  "kind": "InlineFragment",
  "type": "PodcastStory",
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "durationInSeconds",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/),
    (v6/*: any*/),
    (v8/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "crawlQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "crawl",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CrawlResultPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "crawlQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "crawl",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CrawlResultPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "crawlQuery",
    "id": null,
    "text": "query crawlQuery(\n  $url: String!\n  $apiObjectKind: CrawlKind\n) {\n  crawl(url: $url, kind: $apiObjectKind) {\n    hasErrors\n    node {\n      __typename\n      ... on ArticleStory {\n        description\n        link\n        subTitle\n        title\n        publishedDate\n        previews {\n          edges {\n            preview {\n              link\n            }\n          }\n        }\n      }\n      ... on BookStory {\n        description\n        link\n        subTitle\n        publishedDate\n        title\n        previews {\n          edges {\n            preview {\n              link\n            }\n          }\n        }\n      }\n      ... on Manageable {\n        ... on Entity {\n          name\n          description\n          websiteUrl\n          landingPageUrl\n          previews {\n            edges {\n              preview {\n                link\n              }\n            }\n          }\n        }\n      }\n      ... on NewsletterStory {\n        description\n        link\n        subTitle\n        title\n        previews {\n          edges {\n            preview {\n              link\n            }\n          }\n        }\n      }\n      ... on OnlineCourseStory {\n        description\n        link\n        subTitle\n        title\n        previews {\n          edges {\n            preview {\n              link\n            }\n          }\n        }\n      }\n      ... on PodcastStory {\n        description\n        link\n        subTitle\n        durationInSeconds\n        publishedDate\n        title\n        previews {\n          edges {\n            preview {\n              link\n            }\n          }\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '65aba6b90da8124291cf655f4fa72c30';
export default node;
