/**
 * @author Ahmed Samer
 * @date   2019-08-18
 * @description page to list user pool group
 * @filename pool-groups.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import SingleItem from "../add-item/single-item";
import { Loader } from "../../../page/loader";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";

/**
 *
 *
 * @export
 * @interface PoolGroupPageProps
 */
export interface PoolItemPageProps {}

/**
 *
 *
 * @interface PoolGroupPageState
 */
interface PoolItemPageState {
  /**
   *
   *
   * @type {any []}
   * @memberof PoolGroupPageState
   */
  items: { title: string; icon: string; alias: string }[];

  /**
   *
   *
   * @type {boolean}
   * @memberof PoolGroupPageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class PoolGroupPage
 * @extends {React.Component<PoolGroupPageProps, PoolGroupPageState>}
 */
export class PoolItemPage extends React.Component<
  PoolItemPageProps,
  PoolItemPageState
> {
  /**
   * Creates an instance of AddItemPage.
   * @param {*} props
   * @memberof AddItemPage
   */
  constructor(props: any) {
    super(props);

    let items: { title: string; icon: string; alias: string }[] = [];

    // ... push user box
    items.push({
      title: "USERS",
      icon: "cog",
      alias: CMS_PAGE_CONSTANT.COGNITO_POOL_USERS.alias
    });

    // ... push group box
    items.push({
      title: "GROUPS",
      icon: "list",
      alias: CMS_PAGE_CONSTANT.COGNITO_POOL_GROUP.alias
    });

    this.state = {
      items: items,
      isLoading: false
    };
  }

  /**
   *
   *
   * @returns
   * @memberof AddItemPage
   */
  render() {
    return (
      <div className="dark-bg">
        <div className="AddItemPage container">
          {this.state.items.map((item, index) => {
            return (
              <SingleItem
                title={item.title}
                icon={item.icon}
                pageAlias={item.alias}
                key={index}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
