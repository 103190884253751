/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-13
 * @description Implementation of the GraphQL mutation to create component.
 * @filename create-component.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to create person.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_COMPONENT_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createComponentMutation($input: CreateComponentInput!) {
    createComponent(input: $input) {
      hasErrors
      errors {
        id
        location
      }
      component {
        __typename
        key
        description
        id
        revision
      }
    }
  }
`;
