/**
 * @author Ahmed Samer
 * @date   2019-08-18
 * @description page to list user pool group
 * @filename report-chart.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import Chart from "react-apexcharts";
import React, { Component } from "react";

const optionsRadial = {
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },

      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -20,
          show: true,
          color: "#888",
          fontSize: "13px"
        },
        value: {
          formatter: function(val) {
            return val;
          },
          color: "#111",
          fontSize: "30px",
          show: true
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#ABE5A1"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  stroke: {
    lineCap: "round"
  },
  labels: ["Percent"]
};

/**
 *
 *
 * @export
 * @interface ReportChartProps
 */
export interface ReportChartProps {
  options: {
    /**
     *
     *
     * @type {string}
     */
    chart: string;

    /**
     *
     *
     * @type {*}
     */
    xaxis: any;
  };

  /**
   *
   *
   * @type {*}
   * @memberof ReportChartProps
   */
  series: any;

  type:
    | "line"
    | "area"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "rangeBar"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "radar";
}

export class ReportChart extends Component<
  ReportChartProps,
  { options: any; series: any; type?: string }
> {
  constructor(props) {
    super(props);

    // this.state = {
    //   options: {
    //     chart: {
    //       id: "basic-bar"
    //     },
    //     xaxis: {
    //       categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
    //     }
    //   },
    //   series: [
    //     {
    //       name: "series-1",
    //       data: [30, 40, 45, 50, 49, 60, 70, 91]
    //     }
    //   ]
    // };
  }

  /**
   *
   *
   * @returns
   * @memberof ReportChart
   */
  render() {
    return (
      <article className="report-cart">
        <Chart
          options={this.props.options}
          series={this.props.series}
          type={this.props.type}
          width={1200}
        />
      </article>
    );
  }
}
