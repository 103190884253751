/**
 * @author Linkon Islam
 * @date   2019-01-11
 * @description Initial page component
 * @filename InitialPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import * as React from "react";
import AuthorPanel from "./author/author-panel";
import Tag from "./tag/tag";
import { ManagePreviewPanel } from "./manage-preview/manage-preview-panel";
import ModerationPanel from "./moderation/moderation-panel";
import { Article } from "../../../utilities/article";
import {
  ContentModerationStateKind,
  ArticleStory,
  GRAPHQL_TYPE_ARTICLE_STORY,
  ApiObjectKindType,
  PreviewKind,
} from "@radivision/graphql";

import PublisherDetails from "./publisher";
import { Validator } from "../../../utilities/cms-operation/validator";
import ModalConductor from "./modal/modal-conductor";
import { FloatingMenu } from "./floating-button/floating-menu";
import { Loader } from "../../page/loader";
import { CMS_PAGE_CONSTANT } from "../../../utilities/cms-operation/constant";
import { QueryRenderer, graphql } from "react-relay";
import { ENVIRONMENT } from "../../../relay/relay-environment";
import {
  ArticlePageFormInput,
  FormGroupInput,
  PreviewItem,
} from "../../../component-configuration/cms-pages";
import { Story } from "../../../utilities/cms-operation/story";
import { isValidUrl } from "../../../utilities/general";
import { Crawler } from "../../../utilities/crawler";
import { RdvButton } from "../../page-framework/rdv-button";

/**
 *
 * The props of the article page component.
 * @export
 * @interface ArticlePageProps
 */
export interface ArticlePageProps {
  /**
   *
   *
   * @type {{id:string}}
   * @memberof ArticlePageProps
   */
  article?: { id: string };
  /**
   *
   *
   * @type {string}
   * @memberof ArticlePageProps
   */
  articleStory?: ArticleStory;
}

/**
 * The state of the article page component.
 * @export
 * @interface
 */
export interface ArticlePageState {
  /**
   *
   *
   * @type {ArticlePageFormInput}
   * @memberof ArticlePageState
   */
  formInput: ArticlePageFormInput;

  /**
   *
   *
   * @type {*}
   * @memberof BookPageState
   */
  formGroupInput?: FormGroupInput;

  /**
   *
   *
   * @type {{[index:string]:string}}
   * @memberof ArticlePageState
   */
  errorMessage?: Map<string, string>;

  /**
   *
   *
   * @type {ContentModerationStateKind}
   * @memberof ArticlePageState
   */
  moderation?: ContentModerationStateKind;

  /**
   *
   *
   * @type {("ConfirmModal" | "SearchModal" | "NONE")}
   * @memberof ArticlePageState
   */
  modal: {
    modalName: "ConfirmModal" | "SearchModal" | "NONE";
    modalTile: string;
    modalBody: string;
    modalCloseAction: Function;
  };

  /**
   *
   *
   * @type {boolean}
   * @memberof ArticlePageState
   */
  isLoading: boolean;
}

/**
 *
 *
 * @export
 * @class ArticlePage
 * @extends {React.Component<ArticlePageProps, ArticlePageState>}
 */
export class ArticlePage extends React.Component<
  ArticlePageProps,
  ArticlePageState
> {
  /**
   * References to the Author component in the ArticlePage.
   *
   * @type {*}
   * @memberof ArticlePage
   */
  authorPanelRef: any;

  /**
   * References to the moderation component in the ArticlePage.
   *
   * @type {*}
   * @memberof ArticlePage
   */
  moderationPanelRef: any;

  /**
   * References to the publisher component in the ArticlePage.
   *
   * @type {*}
   * @memberof ArticlePage
   */
  publisherDetailsRef: any;

  /**
   * References to the previews panel component in the ArticlePage
   *
   * @type {*}
   * @memberof ArticlePage
   */
  previewPanelRef: any;

  /**
   * References to the Tags component in the ArticlePage.
   *
   * @type {*}
   * @memberof ArticlePage
   */
  tagsRef: any;

  /**
   *Creates an instance of ArticlePage.
   * @param {ArticlePageProps} props
   * @memberof ArticlePage
   */
  constructor(props: ArticlePageProps) {
    super(props);

    //... bind needed function to class
    this.handleLinkChanges = this.handleLinkChanges.bind(this);
    this.handleTitleChanges = this.handleTitleChanges.bind(this);
    this.handleSubtitleChanges = this.handleSubtitleChanges.bind(this);
    this.handlePublishDateChanges = this.handlePublishDateChanges.bind(this);
    this.handleTimeToReadChanges = this.handleTimeToReadChanges.bind(this);
    this.handleLabelChanges = this.handleLabelChanges.bind(this);
    this.handleSummaryChanges = this.handleSummaryChanges.bind(this);
    this.handleIsDeletedChanges = this.handleIsDeletedChanges.bind(this);
    this.handleIsNewChanges = this.handleIsNewChanges.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.handleSaveAction = this.handleSaveAction.bind(this);
    this.revertPageInput = this.revertPageInput.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleAutoFill = this.handleAutoFill.bind(this);
    //... initialize required components references

    this.authorPanelRef = React.createRef();
    this.moderationPanelRef = React.createRef();
    this.publisherDetailsRef = React.createRef();
    this.previewPanelRef = React.createRef();
    this.tagsRef = React.createRef();

    /// handle provided props in case of edit already added article
    this.state = this.handleComponentStateInitialize(this.props.articleStory);
  }

  /**
   *
   *
   * @memberof EntityPage
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   *
   * @param {ArticleStory} articleProps
   * @returns {ArticlePageState}
   * @memberof ArticlePage
   */
  handleComponentStateInitialize(articleProps: ArticleStory): ArticlePageState {
    //... initial component state
    let modal: {
      modalBody: string;
      modalName: "ConfirmModal" | "SearchModal" | "NONE";
      modalTile: string;
      modalCloseAction: Function;
    } = {
      modalBody: "",
      modalName: "NONE",
      modalTile: "",
      modalCloseAction: this.resetModalState,
    };

    let formInput: ArticlePageFormInput = {
      id: undefined,
      label: "",
      link: "",
      title: "",
      subTitle: "",
      summary: "",
      publisherName: "",
      publishedDate: "",
      isDeleted: false,
      isNew: false,
      timeToRead: 10,
    };

    let state: ArticlePageState = {
      modal,
      formInput,
      isLoading: false,
      errorMessage: new Map(),
      formGroupInput: {},
    };

    if (articleProps !== null && articleProps !== undefined) {
      // validate provided label

      if (articleProps.id) {
        state.formInput.id = articleProps.id;
      }

      if (articleProps.label) {
        state.formInput.label = articleProps.label;
      }

      // validate provided link
      if (articleProps.link) {
        state.formInput.link = articleProps.link;
      }

      if (articleProps.title) {
        state.formInput.title = articleProps.title;
      }

      if (articleProps.subTitle) {
        state.formInput.subTitle = articleProps.subTitle;
      }

      if (articleProps.description) {
        state.formInput.summary = articleProps.description;
      }

      if (articleProps.isDeleted) {
        state.formInput.isDeleted = articleProps.isDeleted;
      }

      if (articleProps.isNew) {
        state.formInput.isNew = articleProps.isNew;
      }

      if (articleProps.timeToReadInSeconds) {
        state.formInput.timeToRead = articleProps.timeToReadInSeconds;
      }

      if (articleProps.publishedDate) {
        state.formInput.publishedDate = articleProps.publishedDate;
      }

      if (articleProps.publisher) {
        state.formInput.publisherName = articleProps.publisher;
      }

      if (articleProps.revision) {
        state.formInput.revision = articleProps.revision;
      }

      // ............

      // initialize form group inputs

      //...............
      if (articleProps.publisher) {
        state.formGroupInput.publisherProps = {
          name: articleProps.publisher,
          // type :""
        };
      }

      if (
        articleProps.tags !== null &&
        articleProps.tags !== undefined &&
        articleProps.tags.length > 0
      ) {
        let tagsList: { id: string; text: string }[] = articleProps.tags.map(
          (tag) => {
            return { id: tag.id, text: tag.label };
          }
        );
        state.formGroupInput.tagsProps = tagsList;
      }

      if (
        articleProps.previews !== null &&
        articleProps.previews !== undefined &&
        articleProps.previews.count > 0
      ) {
        let previewList: PreviewItem[] = articleProps.previews.edges.map(
          (preview: any, index: number) => {
            let item: PreviewItem = {
              id: index,
              previewKind: preview.preview.kind,
              previewSource:
                preview.preview.link !== null &&
                preview.preview.link !== undefined
                  ? "link"
                  : "mediaAsset",
            };

            switch (item.previewSource) {
              case "link":
                item.previewDetails = {
                  link: preview.preview.link,
                };
                break;

              case "mediaAsset":
                item.previewDetails = {
                  mediaAsset: {
                    id: preview.preview.mediaAsset.id,
                    file: `${preview.preview.mediaAsset.files[0].path}/${preview.preview.mediaAsset.files[0].name}`,
                  },
                };
                break;

              default:
                break;
            }

            return item;
          }
        );

        state.formGroupInput.previewsProps = previewList;
      }
    }

    return state;
  }

  /**
   *
   *
   * @returns
   * @memberof ArticlePage
   */
  render() {
    let dateNow = new Date();
    let currentTime: string = `${dateNow.toDateString()} ${dateNow.toLocaleTimeString()}`;

    return (
      <div className="dark-bg">
        <div className="container pb-1 pt-1">
          <div className="form-page">
            <h2 className="form-page-title">Article Story</h2>
            {/* Article Info */}
            <div className="Page--Info">
              <div className="Required">
                <p>*Required</p>
              </div>
              <div className="Page--Info-Input">
                <div className="Page-Field">
                  <label className="Page-Require">Link URL</label>
                  <input
                    type="text"
                    name="Link URL"
                    className={`form-control ${
                      this.state.errorMessage.get("LINK") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.link}
                    onChange={this.handleLinkChanges}
                  />
                  <RdvButton
                    extraClassName="auto-fill"
                    text="auto fill"
                    onClick={this.handleAutoFill}
                    isLoading={this.state.isLoading}
                    disabled={
                      this.state?.formInput?.link && !this.state.isLoading
                        ? false
                        : true
                    }
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("LINK")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label className="Page-Require">Title</label>
                  <input
                    type="text"
                    name="Title"
                    className={`form-control ${
                      this.state.errorMessage.get("TITLE") ? "is-invalid" : ""
                    }`}
                    value={this.state.formInput.title}
                    onChange={this.handleTitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("TITLE")}
                  </span>
                </div>
                <div className="Page-Field">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    name="Subtitle"
                    className={`form-control ${
                      this.state.errorMessage.get("SUBTITLE")
                        ? "is-invalid"
                        : ""
                    }`}
                    value={this.state.formInput.subTitle}
                    onChange={this.handleSubtitleChanges}
                  />
                  <span className="invalid-feedback">
                    {this.state.errorMessage.get("SUBTITLE")}
                  </span>
                </div>
              </div>
            </div>
            {/* Author Section */}
            <div className="Page--Author">
              <label htmlFor="">Authors</label>
              <AuthorPanel ref={this.authorPanelRef} />
            </div>
            {/* Publisher Section */}
            <div className="Page--Publisher">
              <label>Publisher</label>
              <PublisherDetails
                error={{
                  haveError: this.state.errorMessage.get("PUBLISHER")
                    ? true
                    : false,
                  message: this.state.errorMessage.get("PUBLISHER"),
                }}
                publisher={this.state.formGroupInput.publisherProps}
                ref={this.publisherDetailsRef}
              />
            </div>
            {/* created and modified time */}
            <div className="Page--Time">
              <div className="Page-Field">
                <label>Published</label>
                <input
                  id="party"
                  type="datetime"
                  name="published"
                  className={`form-control ${
                    this.state.errorMessage.get("DATE") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.publishedDate}
                  onChange={this.handlePublishDateChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("DATE")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Time to Read(Seconds)</label>
                <input
                  id="party"
                  type="number"
                  name="timetoread"
                  className={`form-control ${
                    this.state.errorMessage.get("TIME") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.timeToRead}
                  onChange={this.handleTimeToReadChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("TIME")}
                </span>
              </div>

              <div className="Page-Field">
                <label>Created</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="created"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
              <div className="Page-Field">
                <label>Last Modified</label>
                <input
                  className="readonly form-control"
                  id="party"
                  type="datetime"
                  name="lastmodified"
                  value={currentTime}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Label & Summary */}
            <div className="Page--Label">
              <div className="Page-Field">
                <label>Label</label>
                <input
                  type="text"
                  name="Label"
                  value={this.state.formInput.label}
                  className={`form-control ${
                    this.state.errorMessage.get("LABEL") ? "is-invalid" : ""
                  }`}
                  onChange={this.handleLabelChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("LABEL")}
                </span>
              </div>
              <div className="Page-Field">
                <label>Summary</label>
                <input
                  type="text"
                  name="Summary"
                  className={`form-control ${
                    this.state.errorMessage.get("SUMMARY") ? "is-invalid" : ""
                  }`}
                  value={this.state.formInput.summary}
                  onChange={this.handleSummaryChanges}
                />
                <span className="invalid-feedback">
                  {this.state.errorMessage.get("SUMMARY")}
                </span>
              </div>
            </div>
            {/* Story Status */}
            <div className="Page--Status">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.formInput.isDeleted}
                  readOnly
                />
                <label className="custom-control-label">Is Deleted</label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="new"
                  checked={this.state.formInput.isNew}
                  onClick={this.handleIsNewChanges}
                  readOnly
                />
                <label className="custom-control-label">New</label>
              </div>
            </div>
            {/* tag section */}
            <div className="Page--Tag">
              <Tag
                ref={this.tagsRef}
                tagsList={this.state.formGroupInput.tagsProps}
              />
            </div>
            {/* preview section */}
            <div className="Page--Preview">
              <ManagePreviewPanel
                ref={this.previewPanelRef}
                previewsList={this.state.formGroupInput.previewsProps}
              />
            </div>
            {/* moderation section */}
            <div className="Page--Moderation">
              <ModerationPanel ref={this.moderationPanelRef} />
            </div>
          </div>
          <Loader isActive={this.state.isLoading} />
          <ModalConductor
            currentModal={this.state.modal.modalName}
            ConfirmModalProps={{
              title: this.state.modal.modalTile,
              body: this.state.modal.modalBody,
              closeAction: this.state.modal.modalCloseAction,
            }}
            close={() => {}}
          />
          <FloatingMenu
            menuItems={{
              revertible: this.revertPageInput,
              deleteAble: {
                deleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = true;
                  this.setState({ formInput });
                },
                unDeleteAction: () => {
                  let formInput = this.state.formInput;
                  formInput.isDeleted = false;
                  this.setState({ formInput });
                },
              },
              saveable: this.handleSaveAction,
            }}
          />
        </div>
      </div>
    );
  }

  /**
   *
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e
   * @memberof AddArticleTab
   */
  handleAutoFill(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const LINK = this.state?.formInput?.link;

    if (!isValidUrl(LINK)) {
      console.log({ LINK });
      const modal = this.state.modal;
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Invalid Link auto fill failed`;
      modal.modalTile = "Action Failed";
      this.setState({ modal });

      return;
    }
    this.setState({ isLoading: true });

    return Crawler.fetch(LINK, ApiObjectKindType.ARTICLE_STORY)
      .then((article: ArticleStory) => {
        if (article !== null && article !== undefined) {
          this.setState({ isLoading: false });
          const formInput = this.state.formInput;
          const formGroupInput = this.state.formGroupInput;
          formInput.link = article?.link || formInput.link;
          formInput.title = article?.title || formInput.title;
          formInput.summary = article?.description || formInput.summary;
          formInput.publishedDate =
            article?.publishedDate || formInput.publishedDate;
          if (article?.link) {
            this.publisherDetailsRef.current.addCustomPublisher(
              new URL(article?.link)?.hostname?.replace('www.','')
            );
          }

          if (article?.previews) {
            this.previewPanelRef.current.addCustomPreviewItem({
              previewInfo: article?.previews?.edges[0]?.preview?.link,
              previewKind: PreviewKind.SQUARE,
            });
          }
          console.log({ article });

          this.setState({
            formInput,
            formGroupInput,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Function Responsible for handling changes in article link state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof ArticlePage
   */
  handleLinkChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ArticlePageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();

    // ... assign value if valid
    formInput.link = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in  title state
   *
   * @memberof ArticlePage
   */
  handleTitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ArticlePageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleTitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.title = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article sub-title state
   *
   * @memberof ArticlePage
   */
  handleSubtitleChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ArticlePageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.subTitle = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article Publish Date state
   *
   * @memberof ArticlePage
   */
  handlePublishDateChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: ArticlePageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSubtitleChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.publishedDate = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article time to read state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof ArticlePage
   */
  handleTimeToReadChanges(
    changeEvent: React.FormEvent<HTMLInputElement>
  ): void {
    // ... define need variables
    let formInput: ArticlePageFormInput = this.state.formInput;
    let eventValue: number = Number(changeEvent.currentTarget.value);
    // // console\.log("[handleTimeToReadChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.timeToRead = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article summary state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof ArticlePage
   */
  handleSummaryChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ArticlePageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.summary = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article label state
   *
   * @param {React.FormEvent<HTMLInputElement>} changeEvent
   * @memberof ArticlePage
   */
  handleLabelChanges(changeEvent: React.FormEvent<HTMLInputElement>): void {
    // ... define need variables
    let formInput: ArticlePageFormInput = this.state.formInput;
    let eventValue: string = changeEvent.currentTarget.value;
    // // console\.log("[handleSummaryChanges] provided value : ", eventValue);
    // ... prevent handle default action if it a button or any submit action
    changeEvent.preventDefault();
    //... validate against input errors
    // ... assign value if valid
    formInput.label = eventValue;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article is deleted state
   *
   * @memberof ArticlePage
   */
  handleIsDeletedChanges(event: any): void {
    let formInput: ArticlePageFormInput = this.state.formInput;

    let isDeleted: boolean = event.target.checked ? true : false;

    // // console\.log("[handleIsDeletedChanges] is article deleted", isDeleted);
    formInput.isDeleted = isDeleted;
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling changes in article is new state
   *
   * @memberof ArticlePage
   */
  handleIsNewChanges(event: any): void {
    let formInput: ArticlePageFormInput = this.state.formInput;

    let isNew: boolean = event.target.checked ? true : false;
    formInput.isNew = isNew;
    // // console\.log("[handleIsNewChanges] is article new ", isNew);
    this.setState({ formInput });
  }

  /**
   * Function Responsible for handling submitting new article
   *
   * @memberof ArticlePage
   */
  handleSaveAction(): void {
    let articleInput: ArticlePageState = this.state;
    let modal = this.state.modal;
    let errorMessage: Map<string, string> = this.state.errorMessage;
    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      this.toggleLoader();
      // extract moderation state
      articleInput.moderation = this.moderationPanelRef.current.extractModerationState();
      // extract tags state
      articleInput.formInput.tags = this.tagsRef.current.extractTagsString();
      // extract publisher name
      articleInput.formInput.publisherName = this.publisherDetailsRef.current.extractPublisherDetails();
      // extract previews list
      articleInput.formInput.previews = this.previewPanelRef.current.extractPreviews();

      // validate errors
      errorMessage = Validator.validateCreateStoryInput(articleInput.formInput);

      return Promise.resolve(errorMessage);
    });

    promise = promise.then((errorMessage: Map<string, string>) => {
      let internalPromise: Promise<any>;

      // console\.log("[handleAddArticle] errorMessage ", errorMessage);

      if (errorMessage.size < 1) {
        // ... provide validated input to  utility
        if (this.props.article !== null && this.props.article !== undefined) {
          internalPromise =
            articleInput.formInput.isDeleted === true
              ? Story.deleteStory(articleInput.formInput)
              : Article.updateArticle(articleInput.formInput);

          internalPromise = internalPromise.then((results: any) => {
            // ... handle mutation success
            modal.modalName = "ConfirmModal";
            modal.modalBody = `${
              CMS_PAGE_CONSTANT["ARTICLE"].message.confirmation.update
            }
            ${results && results.id ? `,Article-id : ${results.id}` : ""}
            `;
            modal.modalTile = "Action Complete";
            this.revertPageInput(results);
          });
        } else {
          internalPromise = Article.addArticle(articleInput.formInput).then(
            (results: any) => {
              // ... handle mutation success
              // console\.log(results);
              modal.modalName = "ConfirmModal";
              modal.modalBody = `${CMS_PAGE_CONSTANT["ARTICLE"].message.confirmation.add},Article-id : ${results.id}`;
              modal.modalTile = "Action Complete";
              this.revertPageInput();
            }
          );
        }

        // handle promise chain errors
        internalPromise = internalPromise.catch((err: any) => {
          // ... handle mutation failure
          // console\.log(err);
          modal.modalName = "ConfirmModal";
          modal.modalBody = `${
            CMS_PAGE_CONSTANT["ARTICLE"].message.error.update
          } \nerrors: ${err.message || err[0].message}`;
          modal.modalTile = "Action Failed";
        });
      } else {
        window.scrollTo(0, 0);
        internalPromise = Promise.resolve();
      }
      return internalPromise;
    });

    promise = promise.catch((err) => {
      console.error(err);
      modal.modalName = "ConfirmModal";
      modal.modalBody = `Unexpected error contact Radivision Team`;
      modal.modalTile = "Action Failed";
    });

    // and finally reset page state
    promise = promise.finally(() => {
      this.setState({
        modal,
        errorMessage,
        isLoading: false,
      });
    });
  }

  /**
   *
   *
   * @memberof ArticlePage
   */
  resetModalState(): void {
    let modal = this.state.modal;

    modal.modalName = "NONE";
    modal.modalBody = "";
    modal.modalTile = "";
    this.setState({ modal });
  }

  /**
   *
   *
   * @memberof ArticlePage
   */
  revertPageInput(input?: ArticleStory): void {
    //... reset children state
    this.tagsRef.current.resetComponent();
    this.authorPanelRef.current.resetComponent();
    this.previewPanelRef.current.resetComponent();
    this.publisherDetailsRef.current.resetComponent();
    this.moderationPanelRef.current.resetComponent();

    let state: ArticlePageState = this.handleComponentStateInitialize(
      input !== undefined && input !== null ? input : this.props.articleStory
    );
    this.setState(state);
  }

  /**
   *
   *
   * @param {boolean} isLoading
   * @memberof ArticlePage
   */
  toggleLoader(isLoading: boolean = true) {
    this.setState({ isLoading });
  }
}

/**
 * A React component that renders the CMS Article page.
 *
 * @export
 * @class CompaniesAndOrganizations
 * @extends {React.Component<companiesProps>}
 */
export class ArticleStoryPageContainer extends React.Component<
  ArticlePageProps,
  ArticlePageState
> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let node: React.ReactNode;
    let articleId: string;

    // console\.log("[ArticleStoryPageContainer] provided props", this.props);

    if (this.props.article === null || this.props.article === undefined) {
      node = <ArticlePage />;
    } else {
      articleId = this.props.article.id;

      node = (
        <QueryRenderer
          environment={ENVIRONMENT}
          query={graphql`
            query articlePageQuery($id: ID!) {
              story(id: $id) {
                hasErrors
                errors {
                  id
                  location
                }
                node {
                  __typename
                  ... on ArticleStory {
                    id
                    link
                    timeToReadInSeconds
                    publisher
                    publishedDate
                    description
                    revision
                    created
                    label
                    isDeleted
                    isNew
                    tags {
                      id
                      label
                    }
                    previews {
                      count
                      edges {
                        cursor
                        preview {
                          link
                          kind
                          mediaAsset {
                            id
                            files {
                              id
                              name
                              path
                            }
                            topLevelMediaType {
                              kind
                              label
                            }
                          }
                        }
                      }
                    }
                    subTitle
                    title
                  }
                }
              }
            }
          `}
          variables={{
            id: articleId,
          }}
          render={({ error, props }) => {
            if (error) {
              // console\.log(error);
              return <div>{error.message}</div>;
            } else if (props) {
              // console\.log(props);
              if (props.story.hasErrors) {
                return (
                  <div>
                    <p>
                      unexpected error : {JSON.stringify(props.errors)}, contact
                      Radivision Technical team.
                    </p>{" "}
                  </div>
                );
              } else if (
                props.story.node.__typename !== GRAPHQL_TYPE_ARTICLE_STORY
              ) {
                return (
                  <div>
                    <h2 className="font-weight-bold text-left">
                      {" "}
                      Invalid Article id, {articleId} belongs to type{" "}
                      {props.story.node.__typename}, Need more help? contact
                      Radivision Technical team.
                    </h2>
                  </div>
                );
              } else if (props.story.node) {
                let pageProps: ArticlePageProps = {
                  ...this.props,
                  articleStory: props.story.node,
                };
                return <ArticlePage {...pageProps} />;
              }
            }
            return <Loader isActive={true} />;
          }}
        />
      );
    }

    return node;
  }
}
