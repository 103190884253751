/**
 * @author Linkon Islam
 * @date   2019-01-08
 * @description AddItemPage class
 * @filename AddItemPage.tsx
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import * as React from "react";
import SingleItem from "./single-item";
import { CMS_PAGE_CONSTANT } from "../../../../utilities/cms-operation/constant";

/**
 *
 *
 * @interface ItemPageState
 */
interface ItemPageState {
  /**
   *
   *
   * @type {{ title: string; icon: string; alias: string }[]}
   * @memberof ItemPageState
   */
  items: { title: string; icon: string; alias: string }[];
}

/**
 *
 *
 * @export
 * @class AddItemPage
 * @extends {React.Component<any, ItemPageState>}
 */
export class AddItemPage extends React.Component<any, ItemPageState> {
  /**
   * Creates an instance of AddItemPage.
   * @param {*} props
   * @memberof AddItemPage
   */
  constructor(props: any) {
    super(props);
    this.state = {
      items: [
        {
          title: "Add Article Story",
          icon: "newspaper",
          alias: CMS_PAGE_CONSTANT["ARTICLE"].alias
        },
        {
          title: "Add Book Story",
          icon: "book",
          alias: CMS_PAGE_CONSTANT["BOOK"].alias
        },
        {
          title: "Add Component",
          icon: "project-diagram",
          alias: CMS_PAGE_CONSTANT["COMPONENT"].alias
        },
        {
          title: "Add Dynamic List",
          icon: "list",
          alias: CMS_PAGE_CONSTANT["DYNAMIC_LIST"].alias
        },
        {
          title: "Add Entity",
          icon: "warehouse",
          alias: CMS_PAGE_CONSTANT["ENTITY"].alias
        },
        {
          title: "Add Fixed List",
          icon: "list",
          alias: CMS_PAGE_CONSTANT["FIXED_LIST"].alias
        },
        {
          title: "Add HTML Page",
          icon: "file-code",
          alias: CMS_PAGE_CONSTANT["HTML_DOCUMENT"].alias
        },
        {
          title: "Add List-Collection",
          icon: "layer-group",
          alias: CMS_PAGE_CONSTANT["LIST_COLLECTION"].alias
        },
        {
          title: "Add Newsletter Story",
          icon: "envelope",
          alias: CMS_PAGE_CONSTANT["NEWSLETTER"].alias
        },
        {
          title: "Add Media Asset",
          icon: "biohazard",
          alias: CMS_PAGE_CONSTANT["MEDIA_ASSET"].alias
        },
        {
          title: "Add Media Asset File",
          icon: "clipboard-list",
          alias: CMS_PAGE_CONSTANT["MEDIA_ASSET_FILE"].alias
        },
        {
          title: "Add Original Content",
          icon: "cog",
          alias: CMS_PAGE_CONSTANT["ORIGINAL_CONTENT"].alias
        },
        {
          title: "Add Online Course",
          icon: "compact-disc",
          alias: CMS_PAGE_CONSTANT["ONLINE_COURSE"].alias
        },
        {
          title: "Add Person",
          icon: "user",
          alias: CMS_PAGE_CONSTANT["PERSON"].alias
        },
        {
          title: "Add Podcast Story",
          icon: "podcast",
          alias: CMS_PAGE_CONSTANT["PODCAST"].alias
        },
        {
          title: "Add Poster",
          icon: "clipboard",
          alias: CMS_PAGE_CONSTANT["POSTER"].alias
        },
        {
          title: "Add Questionnaire",
          icon: "question",
          alias: CMS_PAGE_CONSTANT["QUESTIONNAIRE"].alias
        },
        {
          title: "Add Tag",
          icon: "trophy",
          alias: CMS_PAGE_CONSTANT["TAG"].alias
        },
        {
          title: "Add URL Route",
          icon: "code-branch",
          alias: CMS_PAGE_CONSTANT["URL"].alias
        },
        {
          title: "Manage Suggestion",
          icon: "angle-double-down",
          alias: CMS_PAGE_CONSTANT["SUGGEST"].alias
        },
        {
          title: "Manage Support Issues",
          icon: "id-card",
          alias: CMS_PAGE_CONSTANT["SUPPORT"].alias
        },
        {
          title: "Manage Radivision Events",
          icon: "calendar",
          alias: CMS_PAGE_CONSTANT["EVENT"].alias
        }
      ]
    };
  }

  /**
   *
   *
   * @returns
   * @memberof AddItemPage
   */
  render() {
    return (
      <div className="dark-bg">
        <div className="AddItemPage container">
          {this.state.items.map((item, index) => {
            return (
              <SingleItem
                title={item.title}
                icon={item.icon}
                pageAlias={item.alias}
                key={index}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default AddItemPage;
